<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; margin-top: 2em;">Create/Edit NIM List</h4>
            <p style="text-align: left; font-size: 1em; ">Nurse Initiated Medication lists need to be created per facility. Select a facility to create/edit their list.</p>
            <p style="text-align: left; font-size: 1em; "><b>NOTE</b>: Expand each item to enter/edit indication and other notes.</p>
            <div v-if="loadingFacilities">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display:grid; margin-bottom: 1em; align-items: center;">
                <p style="padding-right: 1em; font-size: 1em; text-align: left; font-weight: 500">Select Facility: </p>
                <Dropdown v-model="selectedFacility" :options="facilities" @change="getNIMList()" optionLabel="label" style="width: 20em; height: fit-content" />
            </div>
            <div v-if="selectedFacility" style="display: flex; justify-content: flex-start; align-items: center; margin-bottom: 10px;">
                <p class="blue-button" style="width: fit-content; font-weight: bold; " @click="createNIMItem()">Add New Medication</p>
                <p class="button green-button" style="width: fit-content; padding: 5px 18px; margin: 0; margin-left: 10px; font-weight: bold;" @click="saveNIMList()">Save List</p>
            </div>
            <div v-if="selectedFacility">
                <!-- <div v-if="loadingConfig">
                    <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
                </div> -->
                <!-- v-else  -->
                <table class="data-table" style=" width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- <th style="width: 10em;">Clinical Indication</th> -->
                        <th style="width: 2em;"></th>
                        <th style="min-width: 30em;">Medication</th>
                        <th style="width: 4em;">Dosage</th>
                        <th style="width: 10em;">Dose Unit</th>
                        <th style="width: 10em;">Route</th>
                        <th style="width: 10em;">Frequency</th>
                        <th style="width: 6em;">Max Dose</th>
                        <!-- <th style="width: 2.5em;"></th> -->
                        <!-- <th>Contraindications/Warnings</th> -->
                    </tr>
                    <tbody class="table-data nim-list-table-body" v-for="(item, index) in nimItems" :key="index">
                        <tr :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                            <td  >
                                <div @click="showContent(item, index)" style="height: 100%; display: flex; align-items: center">
                                    <i  v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                                    <i v-else class="pi pi-angle-right"></i>
                                </div>
                                
                            </td>
                            <td style="min-width: 30em;">
                                <div style="display: flex; align-items: center;">
                                    <!-- <Dropdown v-model="item.search_term" ref="med" @change="drugSelect(item.search_term, index)" @keyup="debounceSearchDrugs(item.search_term, index), show()" :loading="item.loadingDrugs" :options="item.drugList" optionLabel="full_name" :editable="true"
                                    style="width: 100%; display: flex" placeholder="Select Medication" /> -->
                                    <Dropdown
                                        id="prescription-med-search"
                                        v-model="item.search_term"
                                        ref="med"
                                        @keyup="algoliaDrugSearch(item.search_term, item)"
                                        @change="drugSelect(item.search_term, index)"
                                        :loading="item.loadingDrugs"
                                        :options="item.drugList"
                                        optionLabel="name"
                                        :editable="true"
                                        style="width: 100%; display: flex" placeholder="Search Medication"
                                    />
                                </div>
                            </td>
                            <td style="width: 4em;"><InputNumber class="border small-input" v-model="item.dose" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" style="border-radius: 6px; width: 99%" /></td>
                            <td style="width: 4em;"><Dropdown style="width: 100%;" id="dose_unit-type" placeholder="Select Dose Unit" :options="item.doseTypes"
                                        v-model="item.dose_unit" /></td>
                            
                            <td style="width: 10em;"><Dropdown style="width: 100%;" id="route-type" placeholder="Select Route" :options="administrationTypes"
                                        v-model="item.route" /></td>
                            <td style="width: 10em;"><Dropdown style="width: 100%;" id="route-type" placeholder="Frequency" :options="frequencyTypes"
                                        v-model="item.frequency" optionLabel="frequency_name" @change="parseDoseTimes(item)" /></td>
                            <td style="width: 6em;"><InputNumber class="border medium-input" v-model="item.max_dose" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" style="border-radius: 6px; width: 85px" /></td>
                            <!-- <td style="width: 1em;"><i class="pi pi-trash" style="cursor: pointer"></i></td> -->
                        </tr>
                        <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
                        <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; width: 66.5vw">
                            <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                                <div class="transfer-notes-container" style="width: 50vw">
                                    <div>
                                        <h4 class="field-title">Indication</h4>
                                        <div class="field-notes" style="width: 100%; height: fit-content;">
                                            <InputText v-model="item.indication" style="width: 100%" />
                                        </div>
                                    </div>
                                    <div style="margin-top: 10px;">
                                        <h4 class="field-title">Contraindication/Instructions</h4>
                                        <Textarea v-tooltip="'Notes/Directions'" style="min-width: 400px; width: 100%; min-height: 15em; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                                            placeholder="Notes/Directions" v-model="item.instructions" />
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- TRANSFER ACTIONS -->
                            <div class="transfer-actions-container" style="width: 66.5vw; align-items: center;">
                                <button class="transfer-action-button red-button" @click="deleteNIMItem(item, index)" >Delete Item</button>
                                <button class="transfer-action-button green-button" @click="saveNIMList()">Save Item</button>
                                <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                            </div>
                        </tr>    
                    </tbody>
                </table>
            </div>
            
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import { sortMedicationList } from '../../services/SortMedicationList';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            nimItems:[
            ],
            nim_list_id:'',
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            facilities:[],
            loadingConfig: false,
            loadingFacilities: false,
            prescribers:[],
            loadingDrugs: false,
            drugList:[],
            stock:'',
            selectedDrug: {},
            drugSearchFilter: 0,
            frequencyTypes:[],
            administrationTypes: [
                'PO',
                'PR',
                'Topical',
                'Subcut',
                'Subling',
                'NG',
                'PEG',
                'IM',
                'IV',
                'Epidural',
                'Inhale/Inhalation',
                'Intraarticular (joint)',
                'Intrathecal (spine)',
                'Intranasal (nose)',
                'Irrigation',
                'NEB',
                'PV',
                'PICC'
            ],
            doseTypes:[]
        }
    },
    methods: {
        algoliaDrugSearch(searchedDrugName, item) {
            var searchName = '';
            // Check if the search term is the full object or just a string
            if (typeof searchedDrugName === 'object') {
                searchName = searchedDrugName.full_name;
            } else {
                searchName = searchedDrugName;
            }

            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }

            // Check if there is brand/generic searching
            // If searching for all meds
                axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                    { params: `typoTolerance=false&query=${searchName}` },
                    { headers })
                .then(res => {
                    console.log('this is the algolia res', res.data.hits);
                    item.drugList = sortMedicationList(res.data.hits);
                    item.drugList.forEach(drug => {
                        item.generic_name = item.generic_name.charAt(0).toUpperCase() + item.generic_name.slice(1);
                        item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    })
                    // state.commit('setDrugNames', res.data.hits);
                })
            
        },
        createNIMItem(){
            this.nimItems.push({
                    search_term:'',
                    indication:'',
                    dose_times:[],
                    drug:{},
                    drugList:[],
                    loadingDrugs: false,
                    dose:0,
                    dose_unit:'',
                    route:'',
                    frequency:'',
                    max_dose:0,
                    instructions:''
                })
        },
        saveNIMItem(item){
            let payload = {
                dose: item.dose,
                dose_times: item.dose_times,
                max_dose: item.max_dose,
                instructions: item.instructions,
                frequency: item.frequency.frequency_name,
                dose_unit: item.dose_unit,
                indication: item.indication,
                route: item.route,
                drug: item.drug.drug_uuid
            }
            this.displaySuccessModal = true;
            this.successMessage = 'Saving NIM item...';
            axios.post(`/nim-list/${this.nim_list_id}/add-item`, { nim_item: payload}, this.$store.state.header).then(res=>{
                console.log('saved nim list', res.data);
                this.successMessage = 'Saved NIM List';
                this.$store.dispatch('getRefreshToken');
            }).catch(err=>{
                this.successMessage = err.response.data;
                this.$store.dispatch('getRefreshToken');
            })

            console.log('this is the payload to save nim item', payload);
        },
        saveNIMList(){
            this.displaySuccessModal = true;
            this.successMessage = 'Saving NIM list...';
            let payload = [];
            this.nimItems.forEach(item=>{
                if((item.drug.drug_uuid || item.drug.alias_id) && item.dose && item.dose_times && item.max_dose && item.frequency && item.dose_unit && item.route){
                    payload.push({
                        dose: parseFloat(item.dose).toFixed(2),
                        dose_times: item.dose_times,
                        max_dose: parseFloat(item.max_dose).toFixed(2),
                        instructions: item.instructions,
                        frequency: item.frequency.frequency_name,
                        dose_unit: item.dose_unit,
                        indication: item.indication,
                        route: item.route,
                        drug: item.drug.drug_uuid ? item.drug.drug_uuid : item.drug.alias_id
                    })
                }
                
            })

            axios.post(`/facility/${this.selectedFacility.value}/nim-list/save`, { nim_items: payload}, this.$store.state.header).then(res=>{
                console.log('saved nim list', res.data);
                this.successMessage = 'Saved NIM List';
                this.$store.dispatch('getRefreshToken');
            }).catch(err=>{
                this.successMessage = err.response.data;
                this.$store.dispatch('getRefreshToken');
            })
        },
        deleteNIMItem(item, index){
            if(item.uuid){
                console.log('removing the existing item by uuid, then splicing it out', item.uuid);
                this.nimItems.splice(index, 1);
                this.nimItems[index].display = false;
                document.getElementById(`expand-${index}`).style.display = 'none';
                this.saveNIMList()
            } else{
                console.log('item not saved, but just deleting from FE list', item);
                this.nimItems.splice(index, 1);
                this.nimItems[index].display = false;
                document.getElementById(`expand-${index}`).style.display = 'none';
            }       
           
        },
        parseDoseTimes(item) {
            console.log('PARSING DOSE TIME');
            if (item.frequency.period == 'Daily') {
                item.dose_times = [
                    {
                        day: '',
                        times: item.frequency.times
                    }
                ]
            } else if (item.frequency.period == 'Weekly') {
                let daysContainingTimes = [];
                item.frequency.days_of_week.forEach(day => {
                    daysContainingTimes.push({
                        day: day,
                        times:item.frequency.times
                    })
                })
                item.dose_times = daysContainingTimes;
            }
        },
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
            }
        },
        getFrequencies() {
            if (this.selectedFacility) {
                axios.post('/prescription/frequencies', {facility_id: this.selectedFacility.value}, this.$store.state.header).then(res => {
                    console.log('THESE ARE THE PRESCRIPTION FREQUENCIES', res.data);
                    this.frequencyTypes = res.data;
                }).catch(err => {
                    this.displaySuccessModal = true;
                    this.successMessage = err.response.data;
                    this.$store.dispatch('getRefreshToken');
                })
            }
        },
        showContent(nimItem, index) {

            if (nimItem.display && (nimItem.display === true)) {
                document.getElementById(`expand-${index}`).style.display = 'none';
                nimItem.display = !nimItem.display;
            } else {
                nimItem.display = true;
                console.log('expanding range for this object');
                document.getElementById(`expand-${index}`).style.display = 'table-row';
            }
        },
        getNIMList() {
            this.loadingConfig = true;
            this.displaySuccessModal = true;
            this.successMessage = 'Retrieving NIM List if available...';
            this.getFrequencies();
            // GET FACILITY NIM LIST
            axios.post(`/facility/${this.selectedFacility.value}/nim-list`, {}, this.$store.state.header).then(res=>{
                console.log('this is the res', res.data);
                this.displaySuccessModal = false;
                this.nimItems = res.data.data.list_items;
                console.log('these are the nim items');
                if(!this.nimItems.length){
                    this.createNIMItem();
                } else{
                    let b = [];
                    b.concat(this.nimItems);
                    console.log('this b is', b);
                    console.log('this nimitem is', typeof this.nimItems);
                    for(let key in this.nimItems){
                        console.log('this is the item loop in objects', this.nimItems[key]);
                        b.push(this.nimItems[key]);
                        console.log('this is the item loop with b', b, b[0]);
                    }
                    // this.nimItems = [this.nimItems[0]];
                    b.forEach(item=>{
                        console.log('looping through bforeach', item);
                        item.drug = item.alias;
                        item.drug.full_name = `(${item.alias.generic_name}) ${item.alias.name}`;
                        item.drugList = [item.alias];
                        item.search_term = item.alias;
                        item.doseTypes = [item.dose_unit];
                        console.log('looping through bforeach', item);
                        this.frequencyTypes.forEach(frequency=>{
                            if(frequency.frequency_name == item.frequency){
                                item.frequency = frequency;
                                this.parseDoseTimes(item);
                            }
                        })
                        this.nimItems = b;
                        // console.log('these are the nim items, going through else - loop', item.drugList);
                    })
                }
                if(res.data.uuid){
                    this.nim_list_id = res.data.data.uuid;
                }
            }).catch(err=>{
                this.successMessage = err.response.data;
                this.$store.dispatch('getRefreshToken');
            })
            
        },
        
        debounceSearchDrugs(searchItem, index) {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs(searchItem, index)
            }, 400); // delay
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchDrugs(searchItem, index) {
            if(searchItem){
                this.nimItems[index].loadingDrugs = true;
                axios.post('/get-drug-aliases', { drug: searchItem, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    res.data.drug_aliases.forEach(item=>{
                        item.full_name = `(${item.generic_name}) ${item.name}`;
                    })
                    
                    this.nimItems[index].drugList = res.data.drug_aliases;

                    this.drugSelect(this.nimItems[index].drugList[0], index);
                    this.nimItems[index].loadingDrugs = false;
                    // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                }).catch(err => {
                    console.log('this is the err', err);
                    this.nimItems[index].loadingDrugs = false;
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
        },
        drugSelect(item, index) {
            this.nimItems[index].loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.nimItems[index].drug.full_item = item;
            this.nimItems[index].drug.drug_uuid = item.alias_id;
            this.nimItems[index].drug.full_name = item.full_name;
            this.nimItems[index].drug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.nimItems[index].drug.strength = `${item.strength}`;
            this.nimItems[index].drug.measurement_unit = `${item.measurement_unit}`;
            this.nimItems[index].drug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.nimItems[index].drug.tags = item.tags;
            this.nimItems[index].loadingDrugs = false;
            console.log('this is the selected drug item', this.nimItems[index].drug);
            this.nimItems[index].doseTypes = [
                this.nimItems[index].drug.form,
                this.nimItems[index].drug.measurement_unit.split('/')[0]
            ]

            if (this.nimItems[index].drug.measurement_unit.split('/')[1] != 'each') {
                this.nimItems[index].doseTypes.push(this.nimItems[index].drug.measurement_unit.split('/')[1]);
            }
        },
        getFacilities(){
            this.loadingFacilities = true;
            axios.post('/get-facilities', {}, this.$store.state.header).then(res=>{
                console.log('these are the facilities', res.data);
                this.facilities = res.data;
                this.loadingFacilities = false;
            }).catch(err=>{
                console.log('this is the error', err.response.data);
                this.successMessage = 'Error: '+err.response.data;
                this.displaySuccessModal = true;
            })
        }
    },
    mounted() {
        this.getFacilities();
        this.getFrequencies();

    },
    computed:{
        filteredPrescribers(){
                return this.prescribers.filter(prescriber=>prescriber.name);
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


.grey-input{
    background-color: #f3f4f4 !important;
    border-radius: 4px;
    input{
        background-color: #f3f4f4;
    }
    
}

.small-input{
    width: 4em;
    input{
        width: 100%;
    }
}

.medium-input{
    width: 6em;
    input{
        width: 100%;
    }
}

.long-input{
    width: 10em;
    input{
        width: 100%;
    }
}
.border{
    border: 1px solid #dadada;
}
.nim-list-table-body{
    td{
        padding: 2px;
    }
}
</style>
