<template>
    <div>
            <div class="patient-form-content-section" style="margin-top: 2em;">
                        <h3 style="text-align: left;">Special Considerations</h3>
                        <div class="considerations-info-form-content"
                            style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; width: 80%">
                            <div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Method</h4>
                                    <div class="special-considerations-form">
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in medication_methods"
                                                :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)"
                                                    :checked="untitled.special_considerations.includes(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Administration</h4>
                                    <div class="special-considerations-form">
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item"
                                                v-for="(item, index) in medication_administration" :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Patient Considerations</h4>
                                    <div class="special-considerations-form">
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in patient_considerations"
                                                :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;">
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Delivery</h4>
                                    <div class="special-considerations-form">
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in medication_delivery"
                                                :key="index">
                                                <input type="checkbox" name="inactive"
                                                    @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500; margin-bottom: 0;">Specific
                                        Instructions/Additional Comments</h4>
                                    <p style="margin-top: 0; text-align: left">Note here if other medication
                                        methods/administration/delivery or patient considerations.</p>
                                    <div class="special-considerations-form">
                                        <textarea name="spec-cons-notes" id="spec-cons-notes" v-model="untitled.special_considerations_notes"
                                            style="max-width: 95%; box-sizing: border-box" cols="60"
                                            rows="10"></textarea>
                                    </div>
                                </div>
                                <button class="blue-button" style="width: fit-content; align-self: flex-end" @click="submitSpecCons()">Save Special Considerations</button>
                            </div>
                            <div>

                            </div>

                        </div>
                    </div>

        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['spec_cons'],
    components: {
        SuccessModal
    },
    data() {
        return {

            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            untitled:{
                special_considerations:[],
                special_consideration_notes:''
            },
            // SPECIAL CONSIDERATION OPTIONS
            medication_methods: [
                'Whole',
                'Quartered',
                'Dissolved',
                'Halved',
                'Capsule opened',
                'Crushed and mixed with...',
            ],
            medication_administration: [
                'Water',
                'Thickened fluids',
            ],
            patient_considerations: [
                'Swallowing difficulty',
                'Cognitive impairment',
                'Resistive to medication',
                'Self administers',
                'Crush medications',
                'Dexterity issues',
                'Nil by mouth',
            ],
            medication_delivery: [
                'Teaspoon - staff guide',
                'Medication cup',
                'Resident/patient hand',
                'PEG administration',
            ],
        }
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        saveSelectedItem(item) {
            console.log('cacheSelectedItem is working', item);
            if (this.untitled.special_considerations.includes(item)) {
                console.log('this item exists in array', this.untitled.special_considerations);
                this.untitled.special_considerations.forEach((alreadySavedItem, index) => {
                    if (item == alreadySavedItem) {
                        this.untitled.special_considerations.splice(index, 1);
                    }
                })
            } else {
                console.log('this item does not exist in array', this.untitled.special_considerations);
                this.untitled.special_considerations.push(item);
            }
        },
        submitSpecCons() {
            this.$store.dispatch('getRefreshToken');
            this.displaySuccessModal = true;
            this.successMessage ='Saving special considerations...';
            let specCons = {
                special_considerations: this.untitled.special_considerations,
                comments: this.untitled.special_considerations_notes
            }
            if (specCons.special_considerations.length || specCons.comments) {
                axios.post(`/patient/${this.$route.params.id}/special-considerations`, specCons, this.$store.state.header).then(res => {
                    console.log('spec cons submitted.', res);
                    this.successMessage ='Special considerations have been successfully updated!';
                }).catch(err=>{
                    console.log('this is the spec cons error', err.response.data);
                    this.successMessage = err.response.data;
                })
            } else {
                console.log('no special cons or notes made, not submitted.')
            }

        },

    },
    mounted() {
        if(this.spec_cons){
                console.log('PATIENT HAS SPECIAL CONSIDERATIONS', this.spec_cons);
                if(this.spec_cons.special_considerations && this.spec_cons.special_considerations.length){
                    this.spec_cons.special_considerations.forEach(item=>{
                        this.untitled.special_considerations.push(item.name.slice(0,1).toUpperCase()+item.name.slice(1));
                    })
                }
                if(this.spec_cons.comments){
                    this.untitled.special_considerations_notes = this.spec_cons.comments
                }
               
            }
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.green-row{
    background-color: $correctbackground !important;
}


.grey-input{
    background-color: #f3f4f4 !important;
    border-radius: 4px;
    input{
        background-color: #f3f4f4;
    }
    
}

.small-input{
    width: 4em;
    input{
        width: 100%;
    }
}

.medium-input{
    width: 6em;
    input{
        width: 100%;
    }
}

.long-input{
    width: 10em;
    input{
        width: 100%;
    }
}
.border{
    border: 1px solid #dadada;
}
</style>
