<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{exceededQty ? 'PBS Maximum Prescription Quantity Exceeded':'Confirm Authority Prescription'}}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr;">
                                <div class="left-form-section-panel">

                                    <div class="modal-text" v-if="!authCode">
                                        <div v-if="!exceededQty">
                                            <p>This is an Authority Prescription Medication.</p>
                                            <p v-if="pbsCode">Click <a :href="'https://www.pbs.gov.au/medicine/item/'+pbsCode.pbs_code" target="_blank">here</a> to view the authority medication requirements.</p>
                                            <p>Click Continue if you would like to proceed.</p>
                                        </div>
                                        <div style="width: 70%; margin: 0 auto" v-else>
                                            <p>Prescription quantity entered exceeds the max allowable quantity of PBS Medication.</p>
                                            <br>
                                            <p >Select Continue if you would like to generate an Authority Number and proceed to get a PBS Phone Approval Code.</p>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="modal-text" style="text-align: left; margin: 0 auto; width: fit-content;" v-else>
                                        <p>Please call 1800 888 333 for the PBS Phone Approval Code.</p>
                                        <div style="margin-top: 10px;">
                                            <p v-if="patient">Patient Name: <b>{{ patient.personal_information.first_name}} {{ patient.personal_information.last_name}}</b></p>
                                            <p v-if="patient">DOB: <b>{{ patient.personal_information.dob}}</b></p>
                                            <p v-if="medicare">Medicare No: <b>{{medicare}}</b></p>
                                            <p>Authority number: <b>{{authCode ? authCode : 'Not generated.'}}</b></p>
                                            <p style="margin-top: 1em" v-if="pbsCode">Click <a :href="'https://www.pbs.gov.au/medicine/item/'+pbsCode.pbs_code" target="_blank">here</a> to view the authority medication requirements.</p>
                                        </div>
                                        <div style="width: 450px; padding: 10px; padding-left: 10px; backgroundColor: #f3f4f4" v-if="instructionList">
                                            <p>Notes/Instructions:</p>
                                            <Textarea v-tooltip="'Notes/Directions'"
                                            style="min-width: 400px; width: 100%; min-height: 160px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                                            placeholder="Notes/Directions" :autoResize="true" v-model="propInstructions" />
                                        </div>
                                        <div style="background-color: #f3f4f4; padding: 10px;">
                                            <div style="display: grid; grid-template-columns: 1fr 1fr; width: fit-content; grid-gap: 20px; margin-top; 20px;">
                                                <div style="width: 150px">
                                                    <p>Quantity (Default: {{pbsCode.max_quantity}}): </p>
                                                    <inputText v-model="maxQuantity" style="border: 1px solid #BDC4C4; border-radius: 6px; width: 100px" />
                                                </div>
                                                <div style="width: 150px">
                                                    <p>Repeats (Default: {{pbsCode.max_repeats}}): </p>
                                                    <inputText v-model="maxRepeats" style="border: 1px solid #BDC4C4; border-radius: 6px;width: 100px" />
                                                </div>
                                            </div>
                                            
                                            <div class="modal-text" style="margin-top: 20px; ">
                                                <p>Enter PBS Phone Approval Code <span style="color: red; font-size: 1em">*</span>:</p>
                                                <inputText v-model="phoneCode" style="border: 1px solid #BDC4C4; border-radius: 6px;" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <!-- @click="!hasAuthCode || (dashboardRenew && !phoneCode) ? generateAuthCode() : enterPhoneCode()" -->
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel</p>
                        <p class="regular-button complete-button" v-if="dashboardRenew"
                        @click="authCode ? enterPhoneCode() : generateAuthCode()"
                        >{{hasAuthCode ? 'Confirm' : 'Continue'}}</p>
                        <p class="regular-button complete-button" v-else 
                        @click="hasAuthCode ?  enterPhoneCode() : generateAuthCode()"
                        >
                        {{hasAuthCode ? 'Confirm' : 'Continue'}}</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
// import axios from 'axios';

export default {
    props: [ "close", 'hasAuthCode', 'patient', 'medicare', 'authCode', 'exceededQty', 'pbsCode', 'instructions', 'dashboardRenew'],
    components: {
        // DualVerify
    },
    data() {
        return {
            generatedCode:'',
            phoneCode: '',
            maxQuantity:0,
            maxRepeats:0,
            instructionList:'',
            propInstructions:'',
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function pbsalert modal');
            this.$emit('revertQtyUpdate', true);
            this.$emit('close', true);
        },
        confirmSelectionCode(){
            this.$emit('confirmCode', this.selectedCode);
        },
        generateAuthCode(){
            console.log('hitting the generateauthcode func');
            this.$emit('passAuthCode', true);
        },
        enterPhoneCode(){
            this.$emit('passPhoneCode', this.phoneCode);
            this.$emit('updatePrescriptionQuantityAndRepeats', {quantity: this.maxQuantity, repeats: this.maxRepeats});
        }
    },
    mounted() {
        this.maxQuantity = this.pbsCode.max_quantity;
        this.maxRepeats = this.pbsCode.max_repeats;
        if(this.instructions){
            this.propInstructions = this.instructions;
        }
    },
    computed: {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
.modal-text{
    p{
        font-size: 1em;
        margin: 0;
    }
}
</style>
