<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{$store.state.company.type == 'warehouse' ?  'Destroy' : 'Dispose'}} Pending Items</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p class="note-header">Reason for {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</p>
                                        <textarea name="notes" id="note-modal" cols="44" rows="16"
                                            :placeholder="$store.state.company.type == 'warehouse' ?  'Enter reason for destruction here...' : 'Enter reason for disposal here...'" v-model="form.submit.notes" style="border-top-right-radius: 0; border-top-left-radius: 0;"></textarea>
                                    </div>
                                    <!-- METHOD -->
                                    <input v-model="form.submit.method" :placeholder="$store.state.company.type == 'warehouse' ?  'Method of Destruction' : 'Method of Disposal'"
                                        style="position: relative; width: 100%; margin-bottom: 5px" />
                                    <input v-model="form.submit.courier_name" placeholder="Courier Name"
                                        style="position: relative; width: 100%; margin-bottom: 5px; background-color: #E5F1FE;" />
                                    <input v-model="form.submit.courier_notes" placeholder="Courier Notes"
                                        style="position: relative; width: 100%; background-color: #E5F1FE;" />
                                </div>

                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel"
                                    style="display: flex; flex-direction: column; justify-content: space-between;">

                                    <div class="right-form-section-drug-container">
                                        <table style="border-collapse: collapse">

                                            <tr v-for="(item, index) in payload.items" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.alias.name }}
                                                        <!-- {{ Number(item.alias.strength).toFixed(1) }}{{
                                                                item.alias.form_description
                                                        }} -->
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>{{ item.quantity }} {{ item.alias.form }}{{ item.quantity > 1 ?
                                                            's' : ''
                                                    }}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p style="color: red; font-weight: bold; font-size: 1em;" v-if="submitAttempted && (!form.submit.notes || !form.submit.method)">Reason and method of {{$store.state.company.type == 'warehouse' ?  'destruction' : 'disposal'}} must be filled in.</p>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red; font-weight: 500; font-size: 1em;">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p  style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p :class="buttonchangeUi()" @click="submitDestroy()">{{$store.state.company.type == 'warehouse' ?  'Destroy' : 'Dispose'}}</p>
                    </slot>
                </div>
            </div>
        </div>
        <!-- :witness="$store.state.currentLocation.state == 'WA'" -->
        <!-- :manual_loop="$store.state.currentLocation.state == 'WA'" :saveContext="saveContext" -->
        <SignatureModal :dual="true" :payload="form.submit" v-if="displaySignatureModal" @isSuccessful="isSuccessful" @close="close"
            endpoint="/destroy-pending"/>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';

export default {
    props: ['transferType', 'payload', 'success','filterStatus'],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            submitAttempted: false,
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: [],
            saveContext:{},
            form: {
                submit: {
                    notes: '',
                    items: [],
                    method:'',
                    courier_name: '',
                    courier_notes: ''
                }
            },
            errorMessage:''
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        isSuccessful(value) {
            if (value) {
                this.$store.dispatch('getDestructionItems', {
                    sort_direction: '',
                    col: '',
                    page: 1,
                    location: this.$store.state.uuid,
                    filters: {
                        status: this.filterStatus,
                        drug: '',
                        patient: '',
                        
                        date: {
                            start: '',
                            end: ''
                        },
                    }
                });
                this.submitAttempted = false;
                this.$emit('success', true);
                this.closeModal();
            }
        },
        buttonchangeUi() {
            if (this.form.submit.notes && this.form.submit.method && this.payload.items?.length > 0) {
                return 'regular-button green-button'
            }
            else {
                return 'regular-button grey-button'
            }
        },
        submitDestroy() {
            this.submitAttempted = true;
            console.log('this is the form to submit', this.form);
            this.payload.items.forEach(item => {
                this.form.submit.items.push(item.uuid);
            })
            if(this.form.submit.notes &&  this.form.submit.method && this.payload.items?.length > 0){
                this.errorMessage = ''
                this.saveContext = {
                    drugs: this.payload.items,
                    location: JSON.parse(localStorage.getItem('currentLocation')).name,
                    patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : 'Imprest/Emergency Stock',
                    courier_name: this.form.submit.courier_name,
                    courier_notes: this.form.submit.courier_notes,
                    supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                    // supplemental2: `Outgoing type: ${this.form.submit.lost_damaged.slice(0, 1).toUpperCase()}${this.form.submit.lost_damaged.slice(1)}`,
                    doubleSig: this.dual,
                    type: 'Pending Destruction'
                }

                this.saveContext.drugs = [];
                this.payload.items.forEach(item=>{
                    this.saveContext.drugs.push({
                        alias: item.alias.name,
                        qty: item.quantity
                    })
                })
                let ids = [];
                this.payload.items.forEach(item=>{
                    ids.push(item.destruction_id);
                })
                this.form.submit.notes = `Reason for ${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}: ${this.form.submit.notes}` + ` -- Method of ${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}: ${this.form.submit.method}. [PENDING DISPOSAL ITEM: ${JSON.stringify(ids)}]`
                this.displaySignatureModal = true;
            } else {
                this.errorMessage = "Notes, CourierName, CourierNotes, Method and Drugs are Required."
            }
            
            this.refinedLocations = [];

            // this.form = {
            //     submit:{
            //         reason: '',
            //         method:'',
            //         notes: '',
            //         witness_role: '',
            //         witness_name: '',
            //         pending: false,
            //         username: '',
            //         password: '',
            //     }
            // }
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: #E5F1FE; //$babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: #E5F1FE; //$babyblue;
    }
}


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: #E5F1FE; //$babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}



.blue-button {
    background-color: #0870DC !important; //$strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: #00AD50; //$complete;
    color: white;
}

.selected-hollow-button {
    background-color: #0870DC; //$strongblue;
    color: white;
}
</style>
