<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <h3 class="form-header">Expired Chart Action - {{  selectedExpiredDrug.alias.name }}</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                
                <div class="modal-body">
                    <div style="border: 1px solid #E04F39; border-radius: 3px; width: fit-content; margin: 0 auto; padding: 0 10px;">
                        <p style="font-size: 1em; margin: 5px; color: #E04F39; font-weight: bold;">WARNING: This is an expired chart, please ensure any drug retrieval or notes are compliant with the intended requirements of the chart.</p>
                    </div>
                    <slot name="body">
                        <form class="action-modal">
                                <div class="form-section-container" style="grid-gap: 24px; width: 100%; padding: 0 25px;" :style="{gridTemplateColumns: '3fr 4fr 2fr'}">
                                    
                                    <div class="left-form-section-panel" style="padding: 0;" >
                                        <h4 style="text-align: left; margin: 0; margin-bottom: 0;">
                                            Directions
                                        </h4>
                                        <div style="width: fit-content;">
                                            <textarea name="notes" id="note-modal" rows="5" cols="100" v-if="selectedExpiredDrug && selectedExpiredDrug.instructions"
                                            placeholder="Enter notes here, such as quantity and units required if applicable..."
                                            style="font-size: 1em; margin-top: 10px; width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #F3F4F4; padding: 8px;"
                                            v-model="instructions" disabled></textarea>
                                        </div>
                                        <h4 style="text-align: left; margin: 0; margin-bottom: 10px;">
                                            View Previous Notes
                                        </h4>

                                        <div style="width: fit-content;">
                                            <Dropdown v-model="selectedPreviousNote" :options="selectedExpiredDrug.last_chart_administrations" optionLabel="administered_at" />
                                            <textarea name="notes" id="note-modal" rows="7" cols="50" v-if="selectedPreviousNote && selectedPreviousNote.note"
                                            placeholder="Enter notes here, such as quantity and units required if applicable..."
                                            style="font-size: 1em; margin-top: 10px; width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 8px;"
                                            v-model="selectedPreviousNote.note" disabled></textarea>
                                        </div>
                                       
                                    </div>
                                    <div class="left-form-section-panel" style="padding: 0;">
                                        <div class="grey-container">
                                            <h4 style="text-align: left; margin: 0; margin-bottom: 10px;">
                                            Write Note
                                        </h4>
                                        <textarea name="notes" id="note-modal" rows="15" cols="30"
                                            placeholder="Enter notes here, such as quantity and units required if applicable..."
                                            style="font-size: 1em; margin-top: 10px; width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: white; padding: 8px;"
                                            v-model="notes"></textarea>
                                        </div>
                                    </div>
                                    <div class="right-form-section-panel" style="padding: 0;">
                                        <div>
                                            <div v-if="selectedExpiredDrug.max_dose" style="display: grid; background-color: #f3f4f4; padding: 5px 10px">
                                                <p style="font-size: 1em; text-align: left; margin: 0;">Max Dose: <span style="font-weight: bold;">{{ selectedExpiredDrug.max_dose }} {{ selectedExpiredDrug.dose_unit }}(s)</span></p>
                                                <p style="font-size: 1em; text-align: left; margin: 0;">Max Times Per Day: <span style="font-weight: bold;">{{ selectedExpiredDrug.max_times_per_day }} {{ selectedExpiredDrug.dose_unit }}(s)</span></p>
                                                <p style="font-size: 1em; text-align: left; margin: 0;">Max Admin Dose: <span style="font-weight: bold;">{{ selectedExpiredDrug.max_admin_dose }} {{ selectedExpiredDrug.dose_unit }}(s)</span></p>
                                            </div>
                                            <div style="margin-bottom: 15px; display: grid; grid-gap: 10px;">
                                                <p style="text-align: left; margin: 0; font-size: 1em; padding: 5px 10px; padding-bottom: 0px">
                                                    Dose Omitted Code (if applicable)
                                                </p>
                                                <Dropdown placeholder="Select DOC Type" v-model="selectedDOC" :options="doseOmittedCodes" optionLabel="label" optionValue="label" class="w-full md:w-14rem"/>
                                            </div>
                                            <div style="display:flex; gap:10px; margin-bottom: 10px" v-if="selectedExpiredDrug.stock_items && selectedExpiredDrug.stock_items.length">
                                                <ToggleButton v-model="drugSafeRetrieval" onLabel="Include Drug Safe Retrieval" offLabel="Include Drug Safe Retrieval" @click="!drugSafeRetrieval ? selectedDrugRetrievalItem = null : ''" />
                                            </div>
                                            <div style="display: grid; grid-gap: 10px;" v-if="drugSafeRetrieval">
                                                <p style="font-size: 1em; text-align: left; margin: 0; padding: 0 10px;">Drug Safe Retrieval</p>
                                                <Dropdown style="width: 20em;" placeholder="Select Drug" v-model="selectedDrugRetrievalItem" :options="stockItems" optionLabel="name" />
                                                <div style="display: flex; align-items: center; gap: 10px;">
                                                    <p style="margin: 0;">Enter Qty Removed:</p>
                                                    <InputNumber
                                                        :minFractionDigits="0"
                                                        :maxFractionDigits="2"
                                                        @focus="$event.target.select()"
                                                        v-tooltip.right="'Prescription Quantity'"
                                                        mode="decimal"
                                                        placeholder="Enter Qty"
                                                        style="border: 1px solid grey; border-radius: 4px; "
                                                        v-model="enteredDose"
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <!-- <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                            <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                            <textarea name="notes" id="note-modal" rows="11"
                                            placeholder=" Enter notes here..."
                                            style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                            v-model="notes"></textarea>
                                        </div> -->
                                    </div>

                                </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex; gap: 10px">
                        <button class="green-button" @click="signAction()">
                           Create Note
                        </button>
                        <button class="black-button" style="margin-left: 10px;" @click="closeModal()">
                           Close
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload" :payload2="payload2" :stock_items="selectedDrugRetrievalItem ? [selectedDrugRetrievalItem] : null"
            @close="closeSignatureModal" :endpoint="drugSafeRetrieval ? '/drug-safe-retrieval':'/administer-expired-chart-note'" :endpoint2="drugSafeRetrieval ? '/administer-expired-chart-note' : ''" 
            :loop="false" :dual="drugSafeRetrieval || (selectedExpiredDrug.alias.tags.includes('S8') || selectedExpiredDrug.alias.tags.includes('s8')) ? true : false"/>
        <SuccessModal v-if="displaySuccessModal" :first_spec_line="displayMessage" @close="closeSuccessModal" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './SignatureModal.vue';
import SuccessModal from './SuccessModal.vue';
// import SingleVerify from '@/components/verification/SingleVerify.vue';

export default {
    props: ['message', 'close', 'loading', 'statusMessage', 'action', 'actionType','selectedExpiredDrug',
     'noAuthRequired', 'adminSubmission','selectedInsulin', 'patientId', 'readings'],
    components: {
        SignatureModal,
        SuccessModal
    },
    data() {
        return {
            payload:'',
            payload2: '',
            mutatedReadings: [],
            selectedIndex: null,
            displaySignatureModal: false,
            displaySuccessModal: false,
            removeFromRegisterPayload: [],
            removedFromRegister: false,
            enteredDose: null,
            selectedCode:'',
            notes:'',
            displayMessage: '',
            drugSafeRetrieval: false,
            previousNotes:[
                {label: '10/03/2024 10:34am', value: 'This is a random note.'},
                {label: '12/03/2024 11:34pm', value: 'This is also a random note.'}
            ],
            selectedPreviousNote: {},
            doseOmittedCodes:[
            {value:  'A',
              label:'Absent'
            },
            {value:  'F',
              label:'Fasting'
            },
            {value:  'H',
              label:'Hospital'
            },
            {value:  'L',
              label:'On Leave'
            },
            {value:  'S',
              label:'Sleeping'
            },
              {value:'S/A',
                  label:'Self Administration'
                },
            {value:  'V',
              label:'Vomiting'
            },
              {value:'A/T',
                  label:'Adjusted Administration'
                },
            {value:  'C',
              label:'Contraindicated'
            },
            {value:  'N',
              label:'Not available'
            },
              {value:'N/R',
                  label:'Not Required'
                },
            {value:  'O',
              label:'Omitted'
            },
            {value:  'R',
              label:'Refused'
            },
            {value:  'W',
              label:'Withheld (clinical)'
            },
            {value:'W/R',
                label:'Withheld (pending results)'
            },
            {value:'P',
                label:'Pending'
            },
            ],
            selectedDOC: '',
            stockItems:[],
            selectedDrugRetrievalItem:null,
            instructions: '',
        }
    },
    methods: {
        closeSuccessModal(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                console.log('closing the modal issuccessful 214');
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            // not technically this.adminSubmission.calendar_item_id but is being passed via prop as such, it is indeed the chart_id
            if(this.selectedDrugRetrievalItem){
                this.payload = {
                    prescriber_uuid:this.selectedExpiredDrug.prescriber_id,
                    stock_item_id: this.selectedDrugRetrievalItem.uuid,
                    quantity:this.enteredDose.toString(),
                    patient_id: this.patientId,
                    notes: `${this.selectedDOC ? 'Dose Omitted Code: '+this.selectedDOC + ' \n' : '' }Drug Retrieved: ${this.selectedDrugRetrievalItem.name} \nQty Retrieved: ${this.enteredDose.toString()} \nDate/Time: ${new Date().toLocaleDateString('en-AU')} ${new Date().toLocaleTimeString('en-AU')} \n Notes: ${this.notes}`
                }
                this.payload2={
                    notes: `${this.selectedDOC ? 'Dose Omitted Code: '+this.selectedDOC + ' \n' : '' }Drug Retrieved: ${this.selectedDrugRetrievalItem.name} \nQty Retrieved: ${this.enteredDose.toString()} \nNotes: ${this.notes}`,
                    chart_id: this.selectedExpiredDrug.uuid,
                    drug_safe_retrieval: null
                }
            } else{
                this.payload = {
                    notes: `${this.selectedDOC ? 'Dose Omitted Code: '+this.selectedDOC + ' \n' : '' }Date/Time: ${new Date().toLocaleDateString('en-AU')} ${new Date().toLocaleTimeString()} \nNotes: ${this.notes}`,
                    chart_id: this.selectedExpiredDrug.uuid,
                    drug_safe_retrieval: null
                }
                this.payload2=null
            }
            // this.notes = this.payload.notes
            console.log('SIGN ACTION CONDITION', this.payload);
            console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function expiredadmin');
            this.$emit('close', true);
        },
    },
    mounted(){
        this.stockItems = this.selectedExpiredDrug.stock_items;
        if(this.stockItems.length){
            this.stockItems.forEach(item=>{
                item.name = item.alias.name
            })
        }
        if(this.previousNotes.length){
            this.selectedPreviousNote = this.selectedExpiredDrug.last_chart_administrations[0];
        }
        if(this.selectedExpiredDrug && this.selectedExpiredDrug.instructions){
            this.instructions = this.selectedExpiredDrug.instructions.split('-------Please write any additional notes below this line-------').join('')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.icon-container{
    width: 282px;
    height: 240px;
    left: 25px;
    background: #94D6D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-circle{
    border-radius: 50%;
    height: 131px;
    width: 131px;
    background: #00B2A2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-input{
    span{
        width: 50%;
        input{
            width:50%;
        }
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.icon-circle-item{
    width: 75px;
}

.doc-grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.grey-container{
    background-color: #f3f4f4;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    p{
        margin-bottom: 10px !important;
        font-size: 15px;
    }
}

.doc-item{
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}

.selected-doc-item{
    background-color: $error;
    color: white;
}

.form-section-container {
    display: grid;
    grid-gap: 10px;
}

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
