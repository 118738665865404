<template>
    <div>
        <div style="display: flex; align-items: center">
            <div class="select-filters-container">

              <p @click="()=>{form.companyList = true; form.createMedication = false; loadDrugList()}" 
                  v-bind:class="[(form.companyList == true && form.createMedication == false) ? 'active-select-filter' : '']" class="select-filter-item">Company Medications List</p>

              <p @click="()=>{form.companyList = false; form.createMedication = false; loadDrugList()}" 
                  v-bind:class="[(form.companyList == false && form.createMedication == false) ? 'active-select-filter' : '']" class="select-filter-item">Non-Company Medications List</p>

              <p @click="()=>{form.companyList = false; form.createMedication = true;}" 
                  v-bind:class="[form.createMedication == true? 'active-select-filter' : '']" class="select-filter-item">Create Custom Medication</p>
              
            </div>
        </div>
        <div v-if="form.createMedication == false" class="drug-register-table table-container" style="width: fit-content; border-top-left-radius: 0; box-shadow: none; padding-left: 0" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
            <div class="filters">
            <div class="text-search-container search-container">
                <input v-model="form.filter.name" placeholder="Medication..." style="position: relative" />
                <div class="drug-search-result-container" v-if='form.filter.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
                <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
                </div>
            </div>
            <div class="select-filters-container">
                </div>
                    <button class="button submit-button" @click="loadDrugList()">Search</button>
                    <div style="display: flex; align-items: center; margin-left: 20px">
                        <div class="select-filters-container">
                            <p @click="()=>{form.filter.tags.includes('s4') ? form.filter.tags.splice(form.filter.tags.indexOf('s4'), 1) : form.filter.tags.push('s4'); loadDrugList()}" 
                        v-bind:class="[form.filter.tags.includes('s4') ? 'active-select-filter' : '']" class="select-filter-item">S4</p>
                        <p @click="()=>{form.filter.tags.includes('s8') ? form.filter.tags.splice(form.filter.tags.indexOf('s8'), 1) : form.filter.tags.push('s8'); loadDrugList()}" 
                            v-bind:class="[form.filter.tags.includes('s8') ? 'active-select-filter' : '']" class="select-filter-item">S8</p>

                        
                    
                    </div>
                    <div style="display: flex; align-items: center; margin-left: 20px" >
                        <p @click="()=>{updateList()}" v-if="changeDrugList.length && $store.state.user.role.permissions.includes('can_do_admin_company')" style="padding: 4px 10px; margin: 0" class="select-filter-item"
                           :class="form.companyList ? 'red-button':'green-button'" >
                            {{form.companyList ? 'Remove': 'Add'}} Items
                        </p>
                    </div>
                </div>
            </div>
            <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
            </div>
            <div style='overflow-y: scroll; margin-top: -2em;' >
                <div class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" v-if='!$store.state.user.role.permissions.includes("can_view_admin_company")' >
                    <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                        You do not have permission to access this feature. Please talk to your admin if you require access.
                    </h3>
                </div>
                <div v-else >
                <table class="data-table" cellspacing="0">
                <!-- LOADING SPINNER -->
                <!-- <div  v-if="!getDrugEntries" >
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div> -->
                <!-- v-else  -->
                <tr class="table-headers" >

                    <th style="width: 150px;" v-if='$store.state.user.role.permissions.includes("can_do_admin_company")'>
                      <h3>{{form.companyList ? 'Remove from list' : 'Add to list'}}</h3>
                    </th>
                    <th>
                      <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                      <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                          <i class="pi pi-sort drug-sort" v-if="form.col =='drug' && form.sort_direction =='asc'" @click="sortBy('drug', 'desc')"></i>
                          <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                      </div>
                    </th>
                    <th style="width: 150px;"> 
                      <h3 @click="sortBy('stock_level', 'desc')" >Form</h3>
                      <div v-if="form.col == 'stock_level'" style="display: inline; cursor: pointer">
                          <i class="pi pi-sort stock_level-sort" v-if="form.col =='stock_level' && form.sort_direction =='asc'" @click="sortBy('stock_level', 'desc')"></i>
                          <i class="pi pi-sort stock_level-sort" v-else @click="sortBy('stock_level', 'asc')"></i>
                      </div>
                    </th>
                    <th style="width: 150px;" v-if="form.companyList && $store.state.company.type == 'warehouse'">
                      <h3>Action</h3>
                    </th>
                </tr>
                
                <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
                    <tr v-if="!$store.state.loadingDrugListData">
                        <td style="padding-left: 30px; margin: 0 auto; align-items: center" v-if='$store.state.user.role.permissions.includes("can_do_admin_company")'>
                            <Checkbox v-model="item.inList" :binary="true" @click="addToList(item.inList, item.alias_id)"/>
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.form}}</td>
                        <td v-if="form.companyList && $store.state.company.type == 'warehouse'">
                          <p :class="!item?.deprecated ? 'red-button' : 'green-button'" style="width: fit-content;display:flex;align-items: center" @click="updateDrugDeprecateStatus(item)">
                            <i v-if="item?.deprecated && depricatedAliasId == item.alias_id && loadingdeprecateDrug" class="pi pi-spin pi-spinner" style="padding:0px; margin-right:3px; height:100%"></i>
                            {{ !item?.deprecated ? 'Deprecate' : 'Restore' }}</p>
                        </td>
                    </tr>
                </tbody>
                </table>
                </div>
                <div v-if="$store.state.reportError">
                <h3 style="color: #E04F39">{{$store.state.reportError}}</h3>
                </div>
                <div v-if="$store.state.loadingDrugListData">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div>
            </div>
            
            <div class="pagination-links">
                <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
                <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
                <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
            </div>
        </div>

        <div v-if="form.createMedication == true">
          <h3 style="margin-top: 50px; text-align: left; padding-left: 20px;">Create Custom Medication</h3>
          <div class="chart-row prescription-chart" style="grid-column-start: 1; grid-column-end: 4; width: 800px; display: grid;">
            <div class="chart-row-item"
                style="width: fit-content; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin: 0 10px; padding: 5px 0; border-radius: 5px; background-color: #f3f4f4">
              <div v-tooltip="'Medication Name'" style="grid-column-start:1; grid-column-end: 3">
                <p style="text-align: left; margin-left: 10px;">
                  Full Name
                </p>
                <InputText style="min-width: 450px; width: 450px;" 
                  v-model="customMedication.concat_name"
                  placeholder="e.g. Salicylic acid 5% in Aqueous Cream" />
              </div>
              <div v-tooltip="'Medication Name'" style="grid-column-start:4; grid-column-end: 5; margin-left: -40px;">
                <p style="text-align: left; margin-left: 10px;">
                      Active Ingredient
                </p>
                <InputText style="min-width: 200px; width: 200px;" 
                  v-model="customMedication.name"
                  placeholder="e.g. Salicylic acid" />
              </div>
              <div v-tooltip="'S8'">
                <p style="text-align: left; margin-left: 10px;">
                    S8
                </p>
                <Dropdown
                    id="s8-type"
                    style="width: 150px"
                    placeholder="Yes or No"
                    :options="yes_or_no"
                    optionValue="value"
                    optionLabel="label"
                    :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription')"
                    v-model="custom_s8"
                />
              </div>
              <div v-tooltip="'Strength'">
                <p style="text-align: left; margin-left: 10px;">
                    Strength
                </p>
                <InputText type="number" style="min-width: 150px; width: 150px;"
                    v-model="customMedication.strength"
                    placeholder="e.g. 9600"
                />
              </div>
              <div v-tooltip="'Form'">
                <p style="text-align: left; margin-left: 10px;">
                    Form
                </p>
                <InputText style="min-width: 150px; width: 150px;"
                    placeholder="e.g. cream"
                    v-model="customMedication.form"
                />
              </div>
              <div v-tooltip="'Quantity'">
                <p style="text-align: left; margin-left: 10px;">
                    Quantity
                </p>
                <InputText style="min-width: 150px; width: 150px;"
                  v-model="customMedication.quantity"
                  placeholder="e.g. 100g"
                />
              </div>
              <div v-tooltip="'Measurement Unit'">
                <p style="text-align: left; margin-left: 10px;">
                    Measurement Unit
                </p>
                <div style="display: flex; align-items: flex-end">
                    <InputText style="min-width: 200px !important; width: 200px !important;"
                        v-model="customMedication.measurement_unit_1"
                        placeholder="e.g. mg or mg/mL"
                    />
                </div>
              </div>
            </div>
            <div style="width: 98%; display: flex; align-items: center; justify-content: flex-end; margin-top: 10px;">
              <div v-if="medicationSaveStatus" style="margin-right: 10px;">
                  <Button class="outline-button p-button-outlined" :loading="medicationSaveStatus.includes('Saving')" :class="medicationSaveStatus == 'Medication saved!' ? 'p-button-success': medicationSaveStatus ? 'p-button-warning':''" v-if="medicationSaveStatus" :label="medicationSaveStatus" />
              </div>
              <button class="blue-button" @click="saveCustomMedication()" v-if="medicationSaveStatus == ''">Save Medication</button>
            </div>
            <div style="width: 95%; height: 2px; background-color: #cdcdcd; margin: 0 auto; margin-top: 10px; margin-bottom: 20px;"></div>
          </div>
        </div>
        <ConfirmationModal v-if="displayDeprecatedConfirmationModal" :actionDescription="`This drug will be deprecated.\nTo restore this drug you will need to click Restore button from list.\nAre you sure you want to proceed?`" :loading="loadingdeprecateDrug" :errorMessage="deprecateDrugErrorMessage" rejectText="No, go back" acceptText="Yes, deprecate it" @accepted="deprecateandrestoreDrugEntry(true)" @close="closeDeprecatedConfirmationModal" />
       </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

export default {
  components: {
    ConfirmationModal
      // QuantityInStock,
      // QuantityUsed
  },
  data(){
    return{
      companyList: true,
      loading:false,
      drugNames: [],
      registerData: [],
      date:[],
      filters:{
        s8:true,
      },
      changeDrugList:[],
      clearForm:{
        companyList: true,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      },
      form:{
        companyList: true,
        createMedication: false,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      },
      custom_s8: false,
      customMedication:{
          concat_name:'',
          name:'',
          form:'',
          strength:0,
          quantity: 0,
          measurement_unit_1:'',
          measurement_unit_2:'',
          tags:[]
      },
      yes_or_no:[
          {label:'Yes',
            value: true},
          {label:'No',
            value: false},
      ],
      medicationSaveStatus:'',
      testData:[
          {
            alias_id:'30200230230203-asd0asda-23232',
            name: "Endone 500 mg",
            measurement_unit: "mg",
            form: 'tablet'
          },
          {
            alias_id:'hfhhf-3jf3-3fj3jf3f3-49494',
            name: "Methadone 40 ml",
            measurement_unit: "ml",
            form: 'vial'
          }
      ],
      displayDeprecatedConfirmationModal: false,
      loadingdeprecateDrug: false,
      deprecateDrugErrorMessage: '',
      depricatedAliasId: ''
    }
  },
  methods:{
    // SORT DATA BY COLUMN AND ORDER
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getDrugList', this.form)
    },
    selectDrugName(name){
      this.form.filter.name = name;
      this.drugNames = [];
    },
    loadDrugList(){
        this.form.page = 1;
        this.changeDrugList = [];
        this.$store.dispatch('getDrugList', this.form);
    },
    //THIS ADDS TO THE LIST PRIOR TO PUSHING TO ENDPOINT
    addToList(bool, id){
        if(bool == true){
            this.changeDrugList.splice(this.changeDrugList.indexOf(id), 1);
        } else{
            this.changeDrugList.push(id);
        }
        console.log('adding to list', bool, id);
    },
    closeDeprecatedConfirmationModal(){
      this.displayDeprecatedConfirmationModal = false
      this.loadingdeprecateDrug = false
      this.deprecateDrugErrorMessage = ''
      this.depricatedAliasId = ''
    },
    // Open Confirmation Modal for Deprecate Drug or call Api for Restore Deprecated Drug
    updateDrugDeprecateStatus(item) {
      this.depricatedAliasId = item?.alias_id
      if (!item?.deprecated) {
        this.displayDeprecatedConfirmationModal = true
      } else {
        this.deprecateandrestoreDrugEntry(false)
      }
    },
    // Deprecated Drug
    deprecateandrestoreDrugEntry(deprecate) {
      this.loadingdeprecateDrug = true
      const payload = {
        alias_id: this.depricatedAliasId,
        deprecate: deprecate
      }
      axios.post('/warehouse/deprecate-company-drug', payload, this.$store.state.header).then((res) => {
        if (res.status == 200) {
          this.loadingdeprecateDrug = false
          this.deprecateDrugErrorMessage = ''
          this.displayDeprecatedConfirmationModal = false
          this.$store.dispatch('getDrugList', this.form);
        }
      }).catch((error) => {
        this.loadingdeprecateDrug = false
        this.deprecateDrugErrorMessage = error
      })
    },
    aliasSearch(alias){
      this.form.filter.name = alias;
      this.$store.dispatch('getDrugList', this.form);
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getDrugList', this.form);
    },
    // THIS ACTUALLY UPDATES THE Medications List WITH CHANGES MADE
    updateList(){
        axios.post(`/drug-list/${this.form.companyList ? 'remove' :'add'}`, {drugs:this.changeDrugList}, this.$store.state.header).then(res=>{
            console.log('updated Medications List', res);
            this.changeDrugList = [];
            this.$store.dispatch('getDrugList', this.form);
        })
    },
    clearFilteredDrugRegister(){
      this.form = {
        companyList: true,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      };
      this.date = [];
      this.$store.dispatch('getDrugList', this.form);
    },
    clearCustomMedForm(){
        this.custom_s8 = false;
        this.customMedication = {
            concat_name:'',
            name:'',
            form:'',
            strength:0,
            quantity: 0,
            measurement_unit_1:'',
            measurement_unit_2:'',
            tags:[]
        }
        this.medicationSaveStatus = '';
    },
    saveCustomMedication(){
      let strengthVal = this.customMedication.strength;

      this.date_added = new Date();
      this.medicationSaveStatus = 'Saving';
      this.customMedication.measurement_unit = `${this.customMedication.measurement_unit_1}`;
      strengthVal = parseFloat(strengthVal).toFixed(5);
      this.customMedication.quantity = parseInt(this.customMedication.quantity);
      if(this.custom_s8){
          this.customMedication.tags.push('s8');
      }

      axios.post('/create-custom-drug', this.customMedication, this.$store.state.header)
        .then(res => {
          this.customMedication.uuid = res.data.uuid;
          this.medicationSaveStatus = 'Medication saved!';
          
          setTimeout(() => {
            this.clearCustomMedForm()
          }, 2000);
        })
        .catch(err => {
          if(err.response) {
            this.medicationSaveStatus = 'Failed to save!';
          
            setTimeout(() => {
              this.medicationSaveStatus = '';
            }, 2000);
          }
        })
    },
    parser(value) {
      if (value === '' || value === null) {
        return '';
      }
      return value.replace(/[^0-9.]/g, '');
    },
    formatter(value) {
      if (value === '' || value === null) {
        return '';
      }
      const parts = value.toString().split('.');
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts[1] ? `${integerPart}.${parts[1]}` : integerPart;
    },
  },
   mounted(){
    this.loadDrugList();
  },
  computed:{
    getDrugEntries(){
      // getStockReport
      return this.$store.getters.getDrugListEntries
    }
  }
}
</script>