<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%; height: 45em;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Resident Search </h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body" style="max-height: 80%">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form v-on:submit.prevent class="action-modal">
                            <div class="drug-register-table table-container"
                                style="padding-top: 5em; width: fit-content; box-shadow: none; margin: 0 auto"
                                v-if='!$store.state.user.role.permissions.includes("can_view_resident")'>
                                <h4
                                    style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                                    You do not have permission to access this feature. Please talk to your admin if you
                                    require access.</h4>
                            </div>
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div v-else class="form-section-container"
                                style="grid-template-columns: 1fr; margin: 0 20px; width: 100%; grid-template-rows: 1fr 10fr;">
                                <div style="display: flex; align-items: center;">
                                    <div v-if="!displayPatientForm" style="width: 40%;">
                                        <input v-on:keyup.enter="searchResident" v-model="searchTerm" placeholder="Enter Resident name or Medicare Number"
                                            style="position: relative; width:100%" />
                                    </div>
                                    
                                    <p v-if="!displayPatientForm" class="submit-button blue-button"
                                        style="margin-left: 10px; margin-right: 10px;"
                                        @click="() => { if (searchTerm) { searchForPatient(searchTerm) } }">Search</p>
                                    <div v-if="!displayPatientForm" style="margin-left: 5px;">
                                        <Checkbox inputId="binary" v-model="includeInactive" :binary="true" />
                                        <label for="binary" style="margin-left: 10px;">Include inactive residents</label>
                                    </div>
                                    <p v-if="!displayPatientForm && $store.state.user.role.permissions.includes('can_do_resident')"
                                        class=" blue-button"
                                        style="margin: 0; padding: 5px; margin-left: 15px; font-weight: bold;"
                                        @click="() => { displayPatientForm = true; displayResults = false; searchResults = '' }">+ Add New
                                        Resident</p>
                                    
                                </div>

                                <div
                                    v-if="displayPatientForm && $store.state.user.role.permissions.includes('can_do_resident')">
                                    <div style="display: grid; grid-gap: 10px;">
                                        <h4 style="text-align: left; margin-bottom: 0px;">Create Resident</h4>
                                        <p style="text-align: left; font-size: 1em; margin: 0;">Medicare Number (expiry
                                            date not required) or DVA Number must be filled in.</p>
                                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">
                                            <div class="form-field wide-text-field">
                                                <label for="first_name">First Name <span
                                                        style="color: red;">*</span></label>
                                                <input type="text" v-model="patientForm.first_name"
                                                    placeholder="Enter First Name">
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Last Name <span
                                                        style="color: red;">*</span></label>
                                                <input type="text" v-model="patientForm.last_name"
                                                    placeholder="Enter Last Name">
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Date of Birth <span
                                                        style="color: red;">*</span></label>
                                                <input type="date" v-model="patientForm.dob"
                                                    placeholder="Enter DOB e.g. 01/01/1901">
                                                <!-- <Calendar v-model="patientForm.dob" placeholder="Date..." dateFormat="dd/mm/yy" /> -->
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Sex <span style="color: red;">*</span></label>
                                                <input type="text" v-model="patientForm.sex" placeholder="Enter Sex">
                                            </div>
                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Medicare Number</label>
                                                <input v-model="regulatory_numbers[0].data"
                                                    placeholder="Enter Medicare No.">
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Medicare Expiry</label>
                                                <input type="date" v-model="regulatory_numbers[0].expiry"
                                                    placeholder="Enter Medicare Expiry e.g. 01/01/2031">
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">DVA Number</label>
                                                <input v-model="regulatory_numbers[1].data"
                                                    placeholder="Enter DVA No.">
                                            </div>
                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Facility</label>
                                                <Dropdown v-model="selectedFacility" :options="facilities"
                                                    optionLabel="label" @change="retrieveSelectedFacilityLocations()"
                                                    :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" />
                                            </div>

                                            <div class="form-field wide-text-field">
                                                <label for="first_name">Location</label>
                                                <div v-if="loadingLocations">
                                                    <i class="pi pi-spin pi-spinner"
                                                        style="font-size: 2rem !important;margin-top: 10px;"></i>
                                                </div>
                                                <TreeSelect v-else v-model="selectedNodeKey" :options="nodes"
                                                    placeholder="Select Item" />
                                            </div>




                                        </div>
                                        <p v-if="createUserError" style="color: red; font-weight: 500; font-size: 1em;">
                                            {{ createUserError }}</p>
                                        <div style="display: flex; justify-content: flex-end">
                                            <p v-if="displayPatientForm && $store.state.user.role.permissions.includes('can_do_resident')"
                                            class="error-button" style="margin: 0; padding: 5px; border-radius: 6px; font-weight: bold; width: 15em;  margin-right: 10px;"
                                            @click="() => { displayPatientForm = false; clearPatientForm() }">Cancel New
                                            Resident</p>
                                            <p @click="submitPatient()"
                                                :class="patientForm.first_name && patientForm.last_name && regulatory_numbers[0].data && regulatory_numbers[0].expiry && patientForm.dob && patientForm.sex ? 'blue-button' : 'grey-button'"
                                                class="blue-button" style="cursor:pointer; width: 15em;">Create Resident
                                            </p>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <div v-if="loadingPatients">
                                    <i class="pi pi-spin pi-spinner"
                                        style="font-size: 2rem !important;margin-top: 2em;"></i>
                                </div>
                                <p v-if="searchError" style="color: red; font-weight: 500; font-size: 1em;">
                                    {{ searchError }}</p>
                                <div v-if="!loadingPatients">
                                    <h4 style="text-align: left; margin: 0; margin-top: 10px;" v-if="searchResults">
                                        Search Results</h4>
                                    <!-- SEARCH RESULTS -->
                                    <div class="patient-search-results-container" v-if="searchResults">
                                
                                        <div class="individual-patient-container" v-for="(item, index) in searchResults"
                                            :key="index" @click="selectPatient(item)">
                                            <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">
                                    </div> -->
                                            <p>
                                                <img style="margin-left: 20px;" onerror="this.onerror=null; this.className='resident-image-default resident-image smaller'" class="resident-image smaller" v-if="item.image_information && item.image_information.url" :src="item.image_information.url" />
                                                <img style="margin-left: 20px;" v-else class="resident-image-default resident-image smaller" >
                                            </p>
                                            <div class="patient-result-info">
                                                <p v-if="item.personal_information"><b>Name:</b>
                                                    {{ item.personal_information.first_name }}
                                                    {{ item.personal_information.last_name }}</p>
                                                <p
                                                    v-if="item.regulatory_information && item.regulatory_information.length">
                                                    <b>Medicare Number:</b> {{
                                                            item.regulatory_information[0].data.slice(0, 4)
                                                    }}
                                                    {{ item.regulatory_information[0].data.slice(4, 9) }}
                                                    {{ item.regulatory_information[0].data.slice(9) }}</p>
                                                <p><b>DOB:</b> {{ item.personal_information &&
                                                        item.personal_information.dob ? item.personal_information.dob : ''
                                                }}
                                                </p>
                                                <p><b>Location:</b> {{ item.physical_location &&
                                                        item.physical_location.location ?
                                                        item.physical_location.location.name : ''
                                                }}</p>
                                            </div>
                                        </div>
                                        <div v-if="!searchResults.length">
                                            <h4>No results found.</h4>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end;">
                    <slot name="footer" style="display: flex;" v-if="!displayPatientForm">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['close', 'patient'],
    components: {
    },
    data() {
        return {
            facilities: '',
            selectedFacility: '',
            locationDirectory: '',
            searchError: '',
            searchTerm: '',
            includeInactive:false,
            loadingPatients: false,
            loadingLocations: false,
            createUserError: '',
            selectedNodeKey: '',
            mappedLocs: '',

            nodes: [],
            displayResults: false,
            displayPatientForm: false,
            patientForm: {
                first_name: '',
                last_name: '',
                dob: '',
                preferred_name: '',
                tag: [],
                sex: '',
            },
            selectedLocation: '',
            regulatory_numbers: [
                {
                    data: '',
                    regulatory_type: 'Medicare Number',
                    expiry: ''
                },
                {
                    data: '',
                    regulatory_type: 'DVA',
                    expiry: ''
                },

            ],
            searchResults: ''
        }
    },
    methods: {
        searchResident(){
            this.searchForPatient(this.searchTerm);

        },
        retrieveSelectedFacilityLocations() {
            this.loadingLocations = true;
            this.locationDirectory = '';
            console.log('this is the selected facility', this.selectedFacility);
            axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
                console.log('these are the facilitys locations', res.data);
                this.nodes = res.data;
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
                this.loadingLocations = false;
            })
        },
        getFacilities() {
            axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
                this.facilities = res.data;
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');
            } else {
                this.$emit('close', true);
            }
        },
        // getLocations(){
        //     axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res=>{
        //         console.log('this is the facility location', res);
        //         this.$store.dispatch('getRefreshToken');
        //         this.nodes = res.data;
        //         this.nodes.forEach(node=>{
        //             node.label = node.name;
        //             if(node.children.length){
        //                 this.getChildLocation(node);
        //             }
        //         })
        //     })
        // },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        // mapLocs(loc){
        //     console.log('this is children are here', loc);
        // },
        searchForPatient(term) {
            this.searchError = '';
            this.displayResults = true;
            this.loadingPatients = true;
            axios.post('/search-patients',
                { page: 1, name: this.searchTerm, location: this.$store.state.currentLocation.uuid, include_inactive: this.includeInactive },
                this.$store.state.header
            ).then(res => {
                console.log('these are the search results', res);
                this.$store.dispatch('getRefreshToken');
                this.searchResults = res.data.patients;
                this.loadingPatients = false;
            }).catch(err => {
                console.log('this is the search error', err.response);
                this.searchError = err.response.data;
                this.loadingPatients = false;
            })
            console.log('this is the search term', term);
        },
        selectPatient(patient) {
            this.$emit('patient', patient);
            console.log('this is the patient selected', patient);
        },
        submitPatient() {
            this.searchError = '';
            if (this.patientForm.dob.split("-").length > 1) {
                this.patientForm.dob = this.patientForm.dob.split("-")[2] + "/" + this.patientForm.dob.split("-")[1] + "/" + this.patientForm.dob.split("-")[0]
            }
            if (this.regulatory_numbers[0].expiry.split("-").length > 1) {
                this.regulatory_numbers[0].expiry = this.regulatory_numbers[0].expiry.split("-")[2] + "/" + this.regulatory_numbers[0].expiry.split("-")[1] + "/" + this.regulatory_numbers[0].expiry.split("-")[0]
            }

            if (this.patientForm.first_name && this.patientForm.last_name && ((this.regulatory_numbers[0].data && this.regulatory_numbers[0].expiry) || this.regulatory_numbers[1].data) && this.patientForm.dob && this.patientForm.sex && this.selectedNodeKey) {

                if (!this.regulatory_numbers[1].data && this.regulatory_numbers[1].regulatory_type == 'DVA') {
                    this.regulatory_numbers.pop();
                }
                if (!this.regulatory_numbers[0].data && this.regulatory_numbers[1].regulatory_type == 'Medicare Number') {
                    this.regulatory_numbers.shift();
                }
                axios.post('/person/bundle', this.patientForm, this.$store.state.header).then(res => {
                    console.log('patient registered.', res);
                    this.$store.dispatch('getRefreshToken');
                    axios.post('/person/' + res.data.uuid + '/regulatory', { regulatory_numbers: this.regulatory_numbers }, this.$store.state.header).then(res1 => {
                        console.log('res1 is', res1);
                        
                    })
                    axios.post('/person/' + res.data.uuid + '/location', { status: 'resident', location: Object.keys(this.selectedNodeKey)[0] }, this.$store.state.header).then((res2) => {
                        console.log('location has been set', res2)
                        this.$emit('patient', {
                            uuid: res.data.uuid,
                            physical_location: {
                                location: res2.data.location
                            },
                            personal_information: {
                                first_name: this.patientForm.first_name,
                                last_name: this.patientForm.last_name,
                                dob: this.patientForm.dob
                            },
                            regulatory_information: [
                                {
                                    regulatory_type: this.regulatory_numbers[0].regulatory_type,
                                    data: this.regulatory_numbers[0].data
                                }
                            ]
                        })
                        this.closeModal();
                    })
                })
                // axios.post('/ihi/get-ihi', 
                // {
                //     first_name: this.patientForm.first_name,
                //     last_name: this.patientForm.last_name,
                //     dob: `${this.patientForm.dob.split('/')[2]}-${this.patientForm.dob.split('/')[1]}-${this.patientForm.dob.split('/')[0]}`,
                //     medicare_card_number: this.regulatory_numbers[0].data.length > 10 ? this.regulatory_numbers[0].data.slice(0,-1) : this.regulatory_numbers[0].data,
                //     medicare_irn: this.regulatory_numbers[0].data.length > 10 ? this.regulatory_numbers[0].data.slice(-1) : '',
                //     sex: this.patientForm.sex
                // },
                // this.$store.state.header).then(res=>{
                //     console.log('this is the ihi submission result', res);
                // })
            } else {
                this.createUserError = "All form fields are required to create a resident."
            }

        },
        clearPatientForm() {
            console.log('clearing patient form');
            this.patientForm = {
                first_name: '',
                last_name: '',
                dob: '',
                preferred_name: '',
                sex: '',
            }
            this.regulatory_numbers = [
                {
                    number: '',
                    regulatory_type: 'Medicare Number',
                    expiry: ''
                },
                {
                    number: '',
                    regulatory_type: 'DVA',
                    expiry: ''
                },
            ]
        }
    },
    mounted() {
        this.getFacilities();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.individual-patient-container {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius:12px;
}

.patient-result-info {
    p {
        font-size: 0.95em;
        margin: 10px;
    }

    text-align: left;
    padding-left: 25px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
</style>
