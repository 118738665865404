<template>
    <div class="modal-mask">
        <div v-if="!directToSign" class="modal-wrapper">
            <div class="modal-container" :style="phone_order ? {width: '20%'}:{width: '30%'}">
                <div class="modal-header">
                    <h3 class="form-header">{{ statusMessage }} </h3>
                    <button @click="loadingDelay ? cancelAction() : closeModal(phone_order ? true : false)" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;" v-if="!phone_order">
                                <div v-if="loadingDelay">
                                    <h3>Contacting Exchange...</h3>
                                    <!-- <h2 style="border-radius: 50px; padding: 5px; background-color: powderblue; border: 1px solid powderblue; margin: 0 auto; width: 45px;">{{first_spec_line}}</h2> -->
                                </div>
                                <div v-else>
                                    <div v-if="(numActions && (actionsCompleted != numActions))">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                    <h4>{{ message ? message : syncChanges ? 'Strong Care has found changes in the CMS database.':'' }}</h4>
                                    <h3 v-if="numActions">{{((actionsCompleted / numActions) * 100).toFixed(1) }}% Completed</h3>
                                    <div v-if="loading">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin: 2em;"></i>
                                    </div>
                                    <div v-if="(adminSchedule && adminSchedule.length) || (calculated_stop_date && !loading)" style="font-size: 1em; text-align: left; border: 2px solid orange; border-radius: 8px; padding: 5px; width: 100%; margin: 0 auto; background-color: rgba(254, 209, 130, 0.6)">
                                        <div v-if="calculated_stop_date && !loading" >
                                            <p style="font-size: 1em; font-weight: bold;">Calculated Stop Date: {{ calculated_stop_date.date }}</p>
                                            <p style="font-size: 1em; font-weight: bold;">Number of Doses: {{ calculated_stop_date.dose_count }}</p>
                                        </div>
                                        <div v-if="adminSchedule && adminSchedule.length">
                                            <p style="font-size: 1em; font-weight: bold">First Dose Commences On: {{ adminSchedule[0].date }}</p>
                                        </div>
                                    </div>
                                    
                                    <div v-if='first_spec_line || second_spec_line || syncChanges'
                                        style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                        <p style="font-size: 1em; text-align: left; " v-if='first_spec_line'>{{ first_spec_line }}</p>
                                        <p style="font-size: 1em; text-align: left; " v-if='second_spec_line'>{{ second_spec_line }}</p>
                                        <p style="font-size: 1em; text-align: left; " v-if="syncChanges">Would you like to proceed to sync the residents in Strong Care with the CMS?</p>
                                    </div>
                                    <div v-if="submitting">
                                        <p style="font-size: 1em;">Submitting...</p>
                                    </div>
                                    <div v-if="errorMessage">
                                        <p style="font-size: 1em; color: red;">{{ errorMessage }}</p>
                                    </div>
                                </div>

                            </div>
                            <div v-else>
                                <div style="margin: 0 auto">
                                    <h3>Select Prescriber</h3>
                                    <p style="font-size: 1em;">Select which prescriber has approved this prescription.</p>
                                    <Dropdown :disabled="action" :options="prescriberOptions" v-model="selectedPrescriber" @change="emitPrescriber()" style="width: 20em;" optionLabel="label" optionValue="value" placeholder="Select Prescriber..." />
                                </div>
                                <div style="margin-top: 5em;" v-if="selectedPrescriber && !action">
                                    <p style="font-size: 1em">Click the Create Prescription button to continue.</p>
                                </div>
                                <div style="margin-top: 5em;" v-if="selectedPrescriber && action">
                                    <p style="font-size: 1em">Click the Sign to complete.</p>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <div v-if="first_spec_line && phone_order" ><p style="color: red; font-size: 1em;">{{ first_spec_line }}</p></div>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px; gap: 10px;"
                    v-if="!loading && !submitting && !errorMessage">
                    
                    <slot name="footer" style="display: flex; gap: 10px;">
                        <p class="grey-button" style="font-size: 1em; margin-right: 1em; cursor: pointer" v-if="action && actionType == 'sign'" @click="closeModal(true)">
                           Go Back
                        </p>
                        <p class="green-button" style="font-size: 1em" v-if="action && actionType == 'sign'" @click="signAction()">
                           {{!noAuthRequired ? 'Sign': 'Complete'}}
                        </p>
                        <div v-else>
                            <button v-if="(first_spec_line == 'Successfully submitted report.' && second_spec_line != 'Click the Complete button to sign off on prescription.') " @click="closeModal(phone_order)">Close</button>
                            <button v-if="second_spec_line == 'Click the Complete button to sign off on prescription.' && !selectedPrescriber" class="blue-button" @click="completeAction()">
                                Complete
                            </button>
                            <button v-if="(phone_order && selectedPrescriber)" class="blue-button" @click="$emit('createPhoneOrder', true)">
                                Create Prescription
                            </button>
                            <button v-if="syncChanges" class="green-button" style="margin-right: 10px;" @click="displayResidentSyncModal = true">
                                Proceed to Sync
                            </button>
                        </div>
                        
                        <button class="red-button" @click="closeModal(phone_order ? true : false)" v-if="(numActions || !action) || (first_spec_line == 'Successfully submitted report.' && second_spec_line != 'Click the Complete button to sign off on prescription.') ">{{ syncChanges ? 'Cancel' : 'Close' }}</button>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content:center; padding: 5px 20px;"
                    v-if="loadingDelay">
                    <slot name="footer" style="display: flex;">
                        <button class="grey-button" @click="cancelAction()">
                            Cancel Process
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal || directToSign" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" :dual="phone_order" />
        <ResidentSyncModal v-if="displayResidentSyncModal" @close="closeSync" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './SignatureModal.vue';
import ResidentSyncModal from './ResidentSyncModal.vue';

export default {
    props: ['message','prescribers', 'close', 'doNotReload', 'phone_order', 'first_spec_line', 'second_spec_line', 'signature_endpoint', 'reloadPageOnClose', 'loading', 'calculated_stop_date',
        'statusMessage', 'payload', 'action', 'actionType', 'loadingDelay', 'noAuthRequired', 'numActions', 'actionsCompleted', 'syncChanges','closeOnRenew', 'displayFirstDose', 'adminSchedule'],
    components: {
        SignatureModal,
        ResidentSyncModal
    },
    data() {
        return {
            displaySignatureModal: false,
            displayResidentSyncModal: false,
            cms_residents: null,
            sr_residents: null,
            submitting: false,
            errorMessage: null,
            selectedPrescriber: null,
            closedSignatureModal: false,
            timeout: null
        }
    },
    methods: {
        emitPrescriber(){
            console.log('emitting prescriber', this.selectedPrescriber);
            this.$emit('updateSelectedPrescriber', this.selectedPrescriber);
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                if (value) {
                    this.displaySignatureModal = false;
                    console.log('testing retrieveallcharts --- refreshlist - successmodal closemodal isSuccessful');
                    this.$emit('successfullySubmitted', true);
                    this.closeModal();
                }
            }, 500); // delay
        },
        signAction() {
            this.displaySignatureModal = true;
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value && !this.directToSign) {
                this.displaySignatureModal = false;
                this.displayResidentSyncModal = false;
                this.closedSignatureModal = true;
            } else {
                // close everything
                this.displaySignatureModal = false;
                this.displayResidentSyncModal = false;
                this.closedSignatureModal = true;
                this.closeModal(this.phone_order ? true : false)
            }
        },
        completeAction(){
            this.submitting = true;
            this.errorMessage = null;
            axios.post(this.signature_endpoint, this.payload, this.$store.state.header).then(res=>{
                console.log('successfully submitted', res.data);
                console.log('testing retrieveallcharts --- refreshlist - successmodal closemodal completeAction');
                this.$emit('successfullySubmitted', true);
                this.submitting = false;
                if(this.signature_endpoint == '/sync-integration' && res.data.length){
                    this.displayResidentSyncModal = true;
                }
            }).catch(err=>{
                console.log(err?.response?.data?.message);
                this.submitting = false;
                this.errorMessage = err;
            })
        },
        closeSync(value){
            if(value == 'reload'){
                this.displayResidentSyncModal = false;
                window.location.reload();
            }
        },
        closeModal(backToEdit) {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                if(backToEdit){
                    console.log('backtoedit from successmodal')
                    this.$emit('backToEdit', true);
                    this.$emit('closeSuccess', true);
                    
                } else{
                    if(this.closeOnRenew){
                        this.$emit('closeSuccess', true);
                        console.log('testing retrieveallcharts --- refreshlist - successmodal closemodal closeonrenew');
                        this.$emit('successfullySubmitted', true);
                    } else{
                        this.$emit('close', true);
                        if(!this.doNotReload){
                            console.log('testing retrieveallcharts --- refreshlist - successmodal closemodal else');
                            this.$emit('successfullySubmitted', true);
                        }
                        
                    }
                    
                }
                
                

            }
        },
    },
    mounted() {
    },
    computed:{
        prescriberOptions(){
            let b = [];
            this.prescribers.forEach(prescriber=>{
                b.push({
                    label: `${prescriber.personal_information.first_name} ${prescriber.personal_information.last_name}`,
                    value: prescriber.uuid
                })
            })
            return b
        },
        directToSign(){
            if(this.phone_order && this.action && this.actionType == 'sign' && !this.closedSignatureModal){
                return true
            } else{
                return false;
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
