<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; ">Sync Residents</h4>
            <p style="text-align: left; font-size: 1em; ">Sync residents for each facility based on a credential.</p>
            <!-- <p style="text-align: left; font-size: 1em; ">Unlinking a prescriber removes their access to the selected facility's resident data.</p> -->
            <div v-if="!companyFacilities">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display: flex; align-items: flex-end; margin-bottom: 1em;">
                <div style="width: fit-content; margin-right: 10px;">
                    <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Select Facility: </p>
                    <Dropdown v-model="selectedFacility" :options="companyFacilities" optionLabel="name" style="min-width: 20em; height: fit-content" />
                </div>
                <div v-if="companyFacilities.length">
                    <button class="blue-button" @click="displayResidentSyncModal = true">Sync Residents</button>
                </div>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"  />
        <ResidentSyncModal @close="close" v-if="displayResidentSyncModal" :selectedFacility="selectedFacility" />
    </div>
</template>

<script>
import axios from 'axios';
import ResidentSyncModal from '@/components/modals/ResidentSyncModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['facilities'],
    components: {
        SuccessModal,
        ResidentSyncModal
    },
    data() {
        return {
            selectedIntegration:null,
            displaySuccessModal: false,
            displayResidentSyncModal: false,
            loadingIntegrationFacilities: false,
            selectedCredential:null,
            companyFacilities: null,
            syncPayload: null,
            linkedFacility:[
                // {
                //     facility_change: null,
                //     facility_name: 'Sunshine Facility',
                //     external_id: '9293292-222221',
                //     integration_label: 'Sun. Fac.'
                // }
            ],
            newCredentialId: '',
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            loadingConfig: false,
            loadingFacilities: false,
            prescribers:[]
        }
    },
    methods: {
        successfullySubmitted(value){
            if(value){
                this.displayDeleteCredentialModal  = false;
                this.selectedCredential = null;
                this.getCompanyCredentials();
               
            }
        },
        close(value){
            if(value){
                this.displaySuccessModal = false;
                this.displayResidentSyncModal = false;
            }
        },
        getCompanyIntegratedFacilities(){
            axios.post('/get-company-integrated-facilities', {}, this.$store.state.header).then(res=>{
                this.companyFacilities = res.data.response.data;
                console.log('these are the integration facilities', res.data);
            })
        },
    },
    mounted() {
        this.getCompanyIntegratedFacilities();
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
