<template>
    <div class="modal-mask">
        <div class="modal-wrapper">

            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Export CSV Data</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p style="text-align: left; font-size: 1em; margin-bottom: 0; font-weight: 500">
                                            Select Columns To Display</p>

                                        <div
                                            style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh; margin-top: 1em;">
                                            <div class="p-field-checkbox" v-for="(item, index) in exportOptions"
                                                :key="index">
                                                <Checkbox :id="item" name="city" :value="item"
                                                    v-model="form.filters.audit_columns" />
                                                <label style="padding-left: 10px;"
                                                    :for="item">{{ item.slice(0, 1).toUpperCase() + item.slice(1) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" @click="exportCSV()">Export CSV</p>
                    </slot>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import axios from 'axios';

export default {
    props: ["close", 'display', 'exportForm'],
    components: {
        // DualVerify
    },
    data() {
        return {
            displaySignatureModal: false,
            exportOptions: ["drug", "person", "location", "role"],
            form: {
                sort_direction: '',
                col: '',
                page: 1,
                filters: {
                    controller: '',
                    action: '',
                    status_code: [],
                    login: '',
                    signature: '',
                    location: '',
                    date: {
                        start: '',
                        end: '',
                    },
                    audits: {
                        drug: '',
                        person: '',
                        location: '',
                        role: '',
                    }
                },
                audit_columns: [
                    // string array ("drug", "person", "location", "role") // toggle optional export columns
                ]
            },
            reference_number: '',
            date_received: '',
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.closeModal();
                this.$emit('isSuccessful', true);
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        exportCSV() {
            let columns = this.form.filters.audit_columns;
            let payload = this.exportForm;
            payload.audit_columns = columns;
            let csv;
            // this.exportForm.audit_columns = this.form.filters.audit_columns;
            axios.post('/export-logs', this.exportForm, this.$store.state.header).then((res) => {

                csv = res.data;

            }).then(() => {
                const anchor = document.createElement('a');
                anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                anchor.target = '_blank';
                anchor.download = `audit_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
                anchor.click();
            })
            console.log('this is the array of options', this.form.filters.audit_columns, this.exportForm);
        }
    },
    mounted() {

    },
    computed: {

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
