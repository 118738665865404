<template>
    <!-- <div style="margin-left: 20px" v-if="patient">
        <div style="display: flex; align-items: center; justify-content: space-between; padding-right: 10px;">
            <h4 style="text-align: left; color: #0870DC; margin-bottom: 0;">Contact Details</h4>
            <router-link style="text-decoration: none; margin-left: 1em; margin-top: 20px;"
                :to="'/patient/' + $route.params.id + '/edit'">
                <p
                    style="font-weight: 500; color: black; margin: 5px; font-size: 1em margin: 0; background-color: #0870DC; color: white; padding: 3px 0; border-radius: 50px;">
                    <i class="pi-pencil pi" v-tooltip="'Edit Patient Info'"
                        style="padding: 1px 7px; font-size: 0.8em !important;"></i>
                </p>
            </router-link>
        </div>

        <div class="three-table-row">
            <div style="min-width: 25em; width: 33%; max-width: 28em; margin-top: 10px;">
                <div class="info-table">
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Preferred Name</p>
                        <p class="general-patient-info-value">{{ patient.personal_information.preferred_name }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Gender</p>
                        <p class="general-patient-info-value">{{ patient.personal_information.sex }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">DOB</p>
                        <p class="general-patient-info-value">{{ patient.personal_information.dob }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Age</p>
                        <p class="general-patient-info-value">{{ patient.personal_information.age }}</p>
                    </div>
                    <div class="general-patient-info" v-if="patient.address">
                        <p class="general-patient-info-key">Address</p>
                        <p class="general-patient-info-value">
                            {{ patient.address.street_address }}, {{ patient.address.suburb }}, {{ patient.address.state }} {{ patient.address.postcode }}
                        </p>
                    </div>
                </div>
            </div>
            <div style="min-width: 25em; width: 33%; max-width: 28em; margin-top: 10px">
                <div class="info-table grey-table">
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Location</p>
                        <p class="general-patient-info-value">{{ patient.physical_location.location.name ?
                                patient.physical_location.location.name : 'N/A'
                        }}</p>
                    </div>
                </div>
            </div>
            <div style="min-width: 25em; width: 33%; max-width: 28em; margin-top: 10px">
                <div class="info-table grey-table" v-if="patient.emergency && patient.emergency.length">
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Next of Kin</p>
                        <p class="general-patient-info-value">{{ patient.emergency[0].first_name }}
                            {{ patient.emergency[0].last_name }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Relationship</p>
                        <p class="general-patient-info-value">{{ patient.emergency[0].relationship }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Phone</p>
                        <p class="general-patient-info-value">{{ patient.emergency[0].phone }}</p>
                    </div>
                    <div class="general-patient-info">
                        <p class="general-patient-info-key">Email</p>
                        <p class="general-patient-info-value">{{ patient.emergency[0].email }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="four-table-row">
            <div style="min-width: 20em;max-width: 28em; margin-top: 10px;" v-if="$store.state.user.patient_admin_active">
                <h4 style="text-align: left; margin: 0; margin-left: 10px; padding-bottom: 10px;color: #0870DC;">Primary
                    General Practitioner's Details</h4>
                <div class="info-table grey-table" v-if="primaryGP">
                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;" v-if="(primaryGP.regulatory_information && primaryGP.regulatory_information.length)">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Prescriber Number
                        </p>
                        <InputText disabled :value="primaryGP.regulatory_information[0].data"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Contact Name
                        </p>
                        <InputText disabled
                            :value="`${primaryGP.personal_information.first_name} ${primaryGP.personal_information.last_name}`"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;" v-if="primaryGP.address">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Address
                        </p>
                        <InputText disabled :value="primaryGP.address.street_address"
                            style="width: 16em; background-color: white; opacity: 1" />
                        <InputText disabled
                            :value="`${primaryGP.address.suburb}, ${primaryGP.address.state} ${primaryGP.address.postcode}`"
                            style="width: 16em; background-color: white; opacity: 1; margin-top: 10px;" />
                    </div>
                    <div v-if="(primaryGP.contact_methods && primaryGP.contact_methods.length)">
                        <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;"
                            v-for="(item, index) in primaryGP.contact_methods" :key="index">
                            <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                                {{ item.name }}
                            </p>
                            <InputText
                                disabled
                                :value="item.data"
                                style="width: 16em; background-color: white; opacity: 1;"
                            />
                        </div>
                    </div>
                </div>
                <div class="info-table grey-table" v-else>
                    <h4>No primary GP linked.</h4>
                </div>
            </div>
            <div style="min-width: 20em;max-width: 28em; margin-top: 10px" v-if="$store.state.user.patient_admin_active">
                <h4 style="text-align: left; margin: 0; margin-left: 10px; padding-bottom: 10px;color: #0870DC;">
                    Pharmacy Details</h4>
                <div class="info-table grey-table" v-if="patient.pharmacy">
                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Pharmacy Name</p>
                        <InputText disabled
                            :value="`${patient.pharmacy.name}`"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;" v-if="patient.pharmacy.contact_methods">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Contact Name</p>
                        <InputText disabled :value="patient.pharmacy.contact_methods[0]"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Phone</p>
                        <InputText disabled :value="patient.pharmacy.phone"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            Email</p>
                        <InputText disabled :value="patient.pharmacy.email"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>

                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">Fax
                        </p>
                        <InputText disabled :value="patient.pharmacy.fax"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>
                </div>
                <div class="info-table grey-table" v-else>
                    <h4>No pharmacy linked.</h4>
                </div>
            </div>
            <div style="min-width: 20em;max-width: 28em; margin-top: 10px">
                <h4 style="text-align: left; margin: 0; margin-left: 10px; padding-bottom: 10px;color: #0870DC;">
                    Entitlement Numbers</h4>
                <div class="info-table grey-table" style="max-height: 18em; overflow: auto">
                    <div style="display: grid; justify-content: flex-start; margin-bottom: 10px;"
                        v-for="(item, index) in patient.regulatory_information" :key="index">
                        <p class="general-patient-info-key" style="margin: 0; text-align: left; font-weight: bold;">
                            {{ item.regulatory_type }}</p>
                        <InputText disabled :value="item.data"
                            style="width: 16em; background-color: white; opacity: 1" />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div v-if="patient">
        <EditPatientComponent @changeStatus="changeStatus" />
    </div>
</template>

<script>
// import axios from 'axios';
import EditPatientComponent from '../EditPatientComponent.vue';

export default {
    props: ['patient'],
    components:{
        EditPatientComponent
    },
    data() {
        return {
            somelocation: '',
            selectedNavigation: 'personal_information',
            gp: '',
            nonGP: '',
            patientUUID: '',
            pharmacySearchInput: '',
            validForm: false,
            prescriberResults: [],
            pharmacyResults: [],
            navigationItems: [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Details',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Entitlement Numbers',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Prescriber Details',
                    link: 'prescriber_details',
                    completed: false,
                },
                {
                    name: 'Allergies & Adverse Drug Reactions',
                    link: 'allergies',
                    completed: false,
                },
                {
                    name: 'Special Considerations',
                    link: 'considerations',
                    completed: false,
                },
                {
                    name: 'Vaccinations and Weights',
                    link: 'vaccinations',
                    completed: false,
                },
                // {
                //     name: 'Diabetes Action Plan',
                //     link:'complex',
                //     completed:false,
                // },
                // {
                //     name: 'Additional Comments',
                //     link:'additional',
                //     completed:false,
                // },
                // {
                //     name: 'New Patient Sign Off',
                //     link:'sign_off',
                //     completed:false,
                // },
            ],
            adrForm: {
                custom: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            },
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                sex: '',
                dob: '',
                tag: [],
            },
            regulatory_numbers: [
            ],
            address: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            facility_location: {
                room_uuid: '',
            },
            kin: {
                first_name: '',
                last_name: '',
                relationship: '',
                phone: '',
                email: ''
            },
            untitled: {
                commencement_date: '',
                expiry_date: '',
                phone: '',
                email: '',
                gp: {
                    prescriber_no: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: true
                },
                pharmacy: {
                    name: '',
                    address: '',
                    contact: '',
                    phone: '',
                    email: '',
                    fax: '',
                    uuid: ''
                },
                prescriber: {
                    prescriber_no: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: false
                },
                prescribers: [],
                adr: [{
                    custom: '',
                    reaction_description: '',
                    reaction_type: '',
                    date: ''
                }
                ],
                special_considerations: [],
                special_considerations_notes: '',
                vaccinations: [
                    {
                        vaccine: '',
                        date: ''
                    }
                ],
                weights: [
                    {
                        weight: '',
                        date: ''
                    }
                ]
            },
            // SPECIAL CONSIDERATION OPTIONS
            medication_methods: [
                'Whole',
                'Quartered',
                'Dissolved',
                'Halved',
                'Capsule Opened',
                'Crushed and Mixed with...',
            ],
            medication_administration: [
                'Water',
                'Thickened Fluids',
            ],
            patient_considerations: [
                'Swallowing Difficulty',
                'Cognitive Impairment',
                'Resistive to Medication',
                'Self Administers',
                'Crush Medications',
                'Dexterity Issues',
                'Nil by Mouth',
            ],
            medication_delivery: [
                'Teaspoon - Staff Guide',
                'Medication Cup',
                'Resident/Patient Hand',
                'PEG Administration',
            ],
            // LOCATION DETAILS
            parentLocation: '',
            locationName: [],
            childLocations: [],
            previewImage: null,
            originalGPID: '',

        }
    },
    methods:{
        changeStatus(value){
            if(value){
                console.log('bubbling the resident status', value);
                this.$emit('changeStatus', value);
            }
            
        },
    },
    computed: {
        
        primaryGP() {
            let primary;
            this.patient.prescribers.forEach(prescriber => {
                if (prescriber.primary_gp) {
                    primary = prescriber;
                }
            })
            return primary;
        }
    },
    mounted() {
        console.log('THIS IT THE PATIENT COMING TO PATIENT INFO', this.patient);
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.three-table-row,
.four-table-row {
    display: flex !important;
    ;
    flex-direction: row;
    flex-wrap: wrap;
}




.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.info-table.grey-table {
    background-color: #f3f4f4;
    min-height: 250px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 8px;
}
</style>