

<template>
  <!-- Generate BCP report -->
  <div style="display: grid; grid-gap: 10px; min-width: 50em;">
    <div v-if="loadingBCPView">
      <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
    </div>
    <div v-else style="display: grid; grid-gap:1em; margin-top: 1em;">
      <p style='font-size: 1em; text-align: left; margin: 0;font-weight: bold'>Select a location and download the BCP report.
      </p>
      <div style="display:flex; gap: 15px; justify-content: space-between;">
        <div style="display: grid; gap:10px" v-if="locationDirectory && locationDirectory.length">
          <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select Location:</p>
          <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Location" :options="locationDirectory"
            optionLabel="name" v-model="selectedFacility" @change="retrieveReports()" />
        </div>
        <button class="green-button" @click="retrieveReports()">
                      Refresh List
        </button>
      </div>

      <div v-if="fetchedReports?.length > 0">
        <div style="display: flex; flex-direction: column; justify-content: space-between;">
          <div style="flex:1">
            <table cellspacing="0" class="data-table">
              <tr class="table-headers">
                <th>Type</th>
                <th>Created At</th>
                <th>Status</th>
                <th width="100em">Download</th>
              </tr>
              <tbody class="table-data" v-for="(report, index) in fetchedReports" :key="index">
                <tr>
                  <td>{{ formatReportType(report.type) }}</td>
                  <td>{{ report.created_at }}</td>
                  <td>

                    <span class="tag" :style="getTagStyle(report.status)">
                                        <p style="color:white;font-weight: 16px;padding: 0 0; margin: 0 0;" >{{ report.status.charAt(0).toUpperCase() + report.status.slice(1) }}</p>
                      </span>
                    
                    
                  
                  </td>
                  <td style="display: flex; justify-content: center;align-content: center;">

                      <i class="pi pi-download" style="padding-top: 12px;" v-if="report.status == 'completed'" @click="retrieveReport(report.uuid)"></i>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination-links">
            <p class="pagination-arrow" v-show="searchPage + 1 > 1" @click="displayPage(searchPage - 1)"> &larr; </p>
            <p>Page {{ searchPage + 1}} of {{ pageCount }}</p>
            <p class="pagination-arrow" v-show="searchPage + 1 < pageCount" @click="displayPage(searchPage + 1)"> &rarr;
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <p>No reports generated for this location.</p>
      </div>



    </div>
  </div>
  <!-- END OF Generate BCP report -->
</template>

<script>
import axios from 'axios';


export default {
  data() {
    return {
      loadingBCPView: false,
      locationDirectory: "",
      selectedFacility: '',
      noResults: false,
      states: "",
      fetchedReports: undefined,
      reports: [{ label: 'Drug Register', value: 'drug_register' }, { label: 'Resident Charts', value: 'residents_charts' }],
      filters: {
        status: ["processing", "failed", "completed"],
        type: ["residents_charts", "drug_register"]
      },
      searchPage: 0,
      pageCount: 0
    }

  },
  methods: {
    formatReportType(currentReport) {
      console.log("currentReport", currentReport)
      console.log("this.reports", this.reports)
      const reportFound = this.reports.find(report => report.value === currentReport);
      return reportFound.label ?? ""
    },
    getTagStyle(status) {
      const colour = status == 'failed' ? '#E04F39' : status == 'processing' ? '#EF9600' : status == 'completed' ? '#00AD50' : ""
      return {
        'background-color': colour 
      };
    },
    retrieveReports() {
      this.loadingBCPView = true;
      let payload = {
        location_id: this.selectedFacility.uuid,
        page: this.searchPage,
        type: ["drug_register", "residents_charts"],
        // status: this.filters.status,
        // type: this.filters.type
      };
      console.log('Generating BCP report for this location:', payload);
      this.displaySuccessModal = true;
      this.successMessage = 'Generating PDF report... This may take some time';
      const endpoint = "/bcp/documents"
      axios.post(endpoint, payload, this.$store.state.header).then(res => {
        console.log(res)
        this.fetchedReports = res.data.data
        this.pageCount =  res.data.meta.last_page
        this.loadingBCPView = false;
      }).catch(err => {
        console.log(err)
        this.successMessage = 'Failed to generated BCP report...';
        this.first_spec_line = 'Error: ' + err;
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data);
      })
    },
    retrieveLocations() {
      this.loadingBCPView = true;
      this.selectedLocations = [];
      axios.post('/get-locations', {}, this.$store.state.header).then(res => {
        console.log('these are the facility locations', res.data);
        this.locationDirectory = res.data.locations;
        this.selectedFacility = this.locationDirectory[0]
        this.retrieveReports();
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
        console.log('err issss', typeof err.response);
        if (err.response && err.response && err.response.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.loginCreateError = err.response
        console.log('err is', err.response, err.response);
        this.$store.dispatch('getRefreshToken');
      })
    },
    retrieveReport(reportUuid) {
      const payload =
      {
        "document_id": reportUuid
      }
      let link = ""
      axios.post('/bcp/document/retrieve', payload, this.$store.state.header).then(res => {
        console.log('these are the facility locations', res.data);
        link = res.data.link;
        window.open(link);
      }).catch(err => {
        console.log('err issss', typeof err.response);
        if (err.response && err.response && err.response.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.loginCreateError = err.response
        console.log('err is', err.response, err.response);
        this.$store.dispatch('getRefreshToken');
      })
    },
    displayPage(page){
      this.searchPage = page;
    },
  },
  mounted() {
    this.retrieveLocations() // will also fetch reports
  },
  computed: {
    getFirstName() {
      return this.responsiblePerson?.person?.personal_information?.first_name || '';
    }
  }
}
</script>

<style scoped lang="scss">

.pi-download {
    color: black;
    background-color: transparent;
    cursor: pointer;
}

.tag {
  pointer-events: none;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 15px;
  justify-self:end; 
  width: 118px;;
  font-weight: 700; 
  font-size: 15px; 
}

</style>
