<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 70%;">
                <div class="modal-header">
                    <h3 class="form-header">PRN Administration</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                                <div class="form-section-container" style="grid-template-columns: 3fr 4fr 4fr; grid-gap: 24px; width: 100%; padding: 0 25px;">

                                <div class="left-form-section-panel" style="padding: 0;" v-if="!selectedPRN.sliding_scale.length">
                                    <div class="icon-container">
                                        <div class="icon-circle">
                                            <img class="icon-circle-item" src="../../assets/icons/medIconWhite.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-form-section-panel" style="padding: 0;" v-else>
                                    <h4 style="text-align: left; margin: 0; margin-bottom: 10px;">
                                        Sliding Scale
                                    </h4>
                                    <div
                                        v-for="(item, index) in selectedPRN.sliding_scale"
                                        :key="index"
                                        style="display: flex; padding: 5px; align-items: center; width: 10em; justify-content: space-between"
                                    >
                                        <p style="font-size: 1em; width: 5em; text-align: right">
                                            {{item.min}} {{item.max ? '-' : '+'}} {{item.max}}: 
                                        </p>
                                        <!-- :style="selectedIndex == index ? {backgroundColor: '#00AD50', color: 'white'}:{}" -->
                                        <p style="font-size: 1em; font-weight: bold; margin-left: 20px; background-color: #f3f4f4; border-radius: 4px; padding: 5px 10px; width: 50px;">
                                            {{item.value}}
                                        </p>
                                    </div>
                                    <div class="small-input" style="margin: 10x; margin-left: 0px; margin-bottom: 15px; width: fit-content">
                                        <p style="text-align: left; margin: 0; font-size: 1em; margin-top: 10px; margin-bottom: 5px;">
                                            Enter Reading:
                                        </p>
                                        <InputNumber
                                            :minFractionDigits="0"
                                            :maxFractionDigits="2"
                                            v-tooltip.right="'Reading'"
                                            mode="decimal"
                                            style="border: 1px solid grey; border-radius: 4px; width: 5em;"
                                            v-model="enteredReading"
                                            @focus="$event.target.select()"
                                        />
                                    </div>
                                    <div class="blue-button" @click="addReading()">
                                        Submit Readings
                                    </div>
                                </div>
                                <div class="left-form-section-panel" style="padding: 0;">
                                    <div class="grey-container">
                                        <p>
                                            <b>Route: </b>{{selectedPRN.route}}
                                        </p>
                                        <p>
                                            {{selectedPRN.instructions.split('-------Please write any additional notes below this line-------').join('')}}
                                        </p>
                                        <p>
                                            <b>Indication:</b> {{selectedPRN.indication ? selectedPRN.indication : 'Not provided.'}}
                                        </p>
                                        <p v-if="(dailydose + enteredDose > selectedPRN.max_dose)" style="color: #E04F39; font-weight: bold;">
                                            <b>Max dose 24hrs:</b> <span v-if="selectedPRN.max_dose">{{selectedPRN.max_dose}} {{selectedPRN.dose_unit}}{{selectedPRN.max_dose > 1 ? 's':''}}</span> <span v-else>Not provided.</span>
                                        </p>
                                        <p v-else>
                                            <b>Max dose 24hrs:</b> <span v-if="selectedPRN.max_dose">{{selectedPRN.max_dose}} {{selectedPRN.dose_unit}}{{selectedPRN.max_dose > 1 ? 's':''}}</span> <span v-else>Not provided.</span>
                                        </p>
                                        <p>
                                            <b>Last Event:</b> <span v-if="selectedPRN.last_chart_administrations.length">{{selectedPRN.last_chart_administrations[0].administered_at}}</span>
                                            <span v-else>Never administered.</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="right-form-section-panel" style="padding: 0;">
                                    <div>
                                        <h3 class="form-header">{{selectedPRN.alias.name}}</h3>
                                        <div style="margin: 10x; margin-left: 0px; margin-bottom: 15px; ">
                                            <div v-if="selectedPRN.stock_items?.length && selectedPRN.stock_items[0]?.name">
                                                <!-- IF SINGLE ITEM -->
                                                <p style="font-size: 1em; text-align: left" v-if="selectedPRN.stock_items.length == 1"><b>Dispensed drug:</b> {{selectedPRN.stock_items[0].alias.name}}</p>
                                                <!-- IF MULTIPLE ITEMS -->
                                                <div v-else style="display: grid; grid-gap: 5px; align-items: start;">
                                                    <p style="font-size: 1em; text-align: left; margin-bottom: 0;"><b>Confirm dispensed drug: </b></p>
                                                    <Dropdown v-model="selected_stock_item" :options="selectedPRN.stock_items" optionLabel="name"
                                                    
                                                    :placeholder="selected_stock_item ? selected_stock_item.alias.name : 'Select drug'" />
                                                </div>
                                            </div>
                                            <div style="display: flex; flex-direction: column; align-items: start">
                                                <p style="text-align: left; margin: 0; margin-top: 20px">
                                                Enter Quantity ({{selectedPRN.dose_unit}}{{selectedPRN.dose > 1 ? 's':''}}) - {{ doseContainerQty }} {{ selectedPRN.container_unit }}{{doseContainerQty > 1 ? 's':''}}
                                                </p>
                                                <InputNumber
                                                    :minFractionDigits="0"
                                                    :maxFractionDigits="2"
                                                    v-tooltip.right="'Prescription Quantity'"
                                                    mode="decimal"
                                                    style="border: 1px solid grey; border-radius: 4px; "
                                                    v-model="enteredDose"
                                                    @focus="$event.target.select()"
                                                    
                                                /> 
                                            </div>
                                                       
                                        </div>
                                        <div v-if="selectedPRN.sliding_scale.length">
                                            <p style="font-size: 1em; text-align: left">
                                                Last reading: <b>{{savedReading ? savedReading.value: readings.length ? readings[0].value :  'No reading taken'}}</b>
                                            </p>
                                            <p style="font-size: 1em; text-align: left">
                                                Time of last reading: {{savedReading ? savedReading.date : readings.length ? readings[0].created_at :  'N/A'}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">
                                            Notes
                                        </p>
                                        <textarea
                                            class="noresize"
                                            name="notes"
                                            id="note-modal"
                                            rows="11"
                                            placeholder="Enter notes here..."
                                            style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                            v-model="notes">
                                        </textarea>
                                    </div> -->
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <SingleVerify
                            @click="()=>{removeDrugFromRegister(selectedPRN)}"
                            v-if="selectedPRN?.stock_items?.length && !selectedPRN.removed"
                            :isPRN="true"
                            @getDrugId="getPRNId"
                            :payload="removeFromRegisterPayload" 
                            endpoint="/drug-safe-retrieval"
                            :drugSafeRemoval="true"
                            :dual="true"
                            :id="selectedPRN?.uuid"
                            :textRemoveButton="true"
                            :notes="notes"
                            :discardAvailable="discardAvailable"
                            :stock_items="selectedPRN?.stock_items"
                        />
                        <button v-else class="green-button" @click="signAction()" :disabled="(dailydose + enteredDose > selectedPRN?.max_dose) || (enteredDose > selectedPRN?.max_admin_dose)" :style="(dailydose + enteredDose > selectedPRN?.max_dose) || (enteredDose > selectedPRN?.max_admin_dose) ? {backgroundColor: 'grey !important'} : {}">
                           Sign
                        </button>
                        <!-- ">{{selectedPRN.last_chart_administrations[selectedPRN.last_chart_administrations.length-1].administered_at -->
                            <!-- selectedPRN.last_chart_administrations.slice(-1).administered_at ?   enteredDose > selectedPRN.max_dose -->
                            <!-- 2 conditions. 1. if dose entered+all doses in the last 24hr exceed max 24hour dose 2. if enetered dose is greater than max administration dose -->
                        <p v-if="( (dailydose + enteredDose > selectedPRN.max_dose) || (enteredDose > selectedPRN.max_admin_dose) )" style="color: #E04F39; font-weight: bold; margin-left: 5px;">
                           Entered dose will exceed max dose limit
                        </p>
                        
                    </slot>
                </div>
            </div>
        </div>
        <!-- Require Dual Signature for any S8 PRN meds -->
        <SignatureModal
            v-if="displaySignatureModal"
            @isSuccessful="isSuccessful"
            :payload="payload"
            @close="closeSignatureModal"
            :dual="(selectedPRN.alias.tags.includes('s8') || selectedPRN.alias.tags.includes('S8')) || selectedPRN.alias.tags.includes('Insulin') || selectedPRN.alias.tags.includes('insulin')  || enteredReading"
            :endpoint="signature_endpoint"
            @getDrugId="getPRNId"
            :noAuthRequired="noAuthRequired"
            :loop="loop"
            :displayReason="true"
        />
        <SuccessModal
            v-if="displaySuccessModal"
            :first_spec_line="displayMessage"
            @close="closeSuccessModal"
        />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';
import SingleVerify from '@/components/verification/SingleVerify.vue';
import SuccessModal from './SuccessModal.vue';
import axios from 'axios';
import { isProxy, toRaw } from 'vue';


export default {
    props: [
        'message',
        'close',
        'loading',
        'statusMessage',
        'action',
        'actionType',
        'noAuthRequired',
        'adminSubmission',
        'loop',
        'selectedPRN',
        'patientId',
        'readings',
        ''
    ],
    components: {
        SignatureModal,
        SingleVerify,
        SuccessModal
    },
    data() {
        return {
            selected_stock_item: null,
            displaySuccessModal: false,
            displayMessage:'',
            payload:'',
            discardAmount:0,
            discardAvailable:false,
            // enteredDose: this.selectedPRN && this.selectedPRN.sliding_scale && !this.selectedPRN.sliding_scale.length ? parseFloat(this.selectedPRN.dose) : 0,
            enteredDose: 0,
            // doseContainerQty:0,
            enteredReading:0,
            savedReading: null,
            selectedIndex: 0,
            signature_endpoint:'/administer-chart',
            displaySignatureModal: false,
            removeFromRegisterPayload: [],
            removedFromRegister: false,
            drug_safe_retrieval:'',
            selectedCode:'',
            notes:'',
            doseOmittedCode:[
            {value:  'A',
              label:'Absent'
            },
            {value:  'F',
              label:'Fasting'
            },
            {value:  'H',
              label:'Hospital'
            },
            {value:  'L',
              label:'On Leave'
            },
            {value:  'S',
              label:'Sleeping'
            },
              {value:'S/A',
                  label:'Self Administration'
                },
            {value:  'V',
              label:'Vomiting'
            },
              {value:'A/T',
                  label:'Adjusted Administration'
                },
            {value:  'C',
              label:'Contraindicated'
            },
            {value:  'N',
              label:'Not available'
            },
              {value:'N/R',
                  label:'Not Required'
                },
            {value:  'O',
              label:'Omitted'
            },
            {value:  'R',
              label:'Refused'
            },
            {value:  'W',
              label:'Withheld (clinical)'
            },
              {value:'W/R',
                  label:'Withheld (pending results)'
                },
            ]
        }
    },
    methods: {
        recommendedSlidingDose() {
            if (this.readings && this.readings.length) {
                let lastReading = this.readings[0].value;
                console.log('this si the last reading', lastReading);
                this.selectedPRN.sliding_scale.forEach((scale, index) => {
                    if (lastReading >= scale.min) {
                        if ((lastReading <= scale.max) || !scale.max) {
                            console.log('this is the scale', scale);
                            this.selectedIndex = index;
                        }
                    }
                })
            }
        },
        addReading() {
            this.$store.dispatch('getRefreshToken');
            this.displaySuccessModal = true;
            this.displayMessage = 'Updating BGL reading.'
            let payload = {
                patient_id:this.patientId,
                unit: 'BGL',
                type: 'insulin',
                value: this.enteredReading.toString()
            }
            console.log('this is the payload for reading', payload);
            axios.post('/create-reading', payload, this.$store.state.header)
            .then(res => {
                console.log(res);
                delete payload.patient_id;
                this.displayMessage = 'Successfully updated reading.';
                res.data.value = this.enteredReading.toString();
                console.log('emitting this create reading res', res.data);
                this.savedReading = {
                    value: this.enteredReading.toString(),
                    date: res.data.created_at,
                    id: res.data.uuid
                };
                this.$emit('addInsulinReading', res.data);
                setTimeout(() => {
                    this.displaySuccessModal = false;
                    this.recommendedSlidingDose();
                }, 1000)
            }).catch(err => {
                this.displayMessage = err.response.data
            })
        },
        closeSuccessModal(value) {
            if (value) {
                this.displaySuccessModal = false;
            }
        },
        removeDrugFromRegister(drug) {
            let discard_amount = parseFloat(Math.ceil(this.doseContainerQty) - this.doseContainerQty).toFixed(2);
            let qty = parseFloat(this.doseContainerQty).toFixed(2);
                
            if(discard_amount > 0){
                console.log("discard amount is greater than 0: " + discard_amount);
            }else{
                console.log("discard amount is not greater than 0: " + discard_amount);
            }

            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id: this.selected_stock_item.uuid,
                    quantity: qty.toString(),
                    patient_id: this.patientId,
                    dose_quantity: this.enteredDose,
                    dose_unit: drug.dose_unit,
                    ...((discard_amount > 0) && { discard_amount: discard_amount.toString() }), // conditionally adds discard_amount to payload
                }
                
            this.discardAvailable = discard_amount > 0 ? discard_amount + " " + drug.container_unit + "/s" : false;
        },
        getPRNId(value) {
            console.log('HIT THE DRUG SAFE RETRIEVAL ID IN SINGLEVERIFY', value);
            this.$store.dispatch('getRefreshToken');
            this.$emit('updatePRN', value);
            this.removedFromRegister = true;
            this.drug_safe_retrieval = value.drug_safe_retrieval;
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                console.log('this is the value from administering prns', value);
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.$emit('completePRN', {administered_at: value.administered_at, id: this.selectedPRN.uuid});
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            let date = new Date();
            // not technically this.adminSubmission.calendar_item_id but is being passed via prop as such, it is indeed the chart_id;
            let notes = `PRN dose administered.

Date: ${date.toLocaleDateString('en-AU')}
Time of administration: ${date.toLocaleTimeString('en-AU')}
Drug name: ${this.selectedPRN.alias.name}
Dose: ${this.enteredDose} ${this.selectedPRN.dose_unit}/s ${(this.selectedPRN.container_unit.toLowerCase() === 'ampoule' && this.selectedPRN.container_unit.toLowerCase() !== this.selectedPRN.dose_unit.toLowerCase()) ? '[' + this.doseContainerQty + ' Ampoule(s)' + ']': ''}
Administration Route: ${this.selectedPRN.route}
${this.enteredReading ? 'Reading : '+this.enteredReading : ''}
`
            this.payload = {
                chart_id:this.adminSubmission.calendar_item_id,
                doc:'',
                notes:notes,
                dose: this.enteredDose.toString(),
                drug_safe_retrieval: this.drug_safe_retrieval ? this.drug_safe_retrieval : this.adminSubmission.drug_safe_retrieval,
                reading: this.savedReading ? this.savedReading.id : this.readings && this.readings.length ? this.readings[0].uuid :''
            }
            console.log('SIGN ACTION CONDITION', this.payload);
            console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    // watch:{
    //     enteredDose(newValue){
    //         let containerConstant = this.selectedPRN?.container_constant ?? 1
    //         let myQty = parseFloat(newValue / containerConstant);

    //         this.doseContainerQty = myQty.toFixed(2);

    //         this.discardAmount = parseFloat(Math.ceil(myQty) - myQty);
            
            
    //     }
    // },
    computed: {
        dailydose() {
            if (this.selectedPRN.last_chart_administrations.length) {
                    let amount_administered = 0
                    console.log('tally up the prn administrations in the last 24 hours');
                    this.selectedPRN.last_chart_administrations.forEach(admin => {
                        amount_administered += parseFloat(admin.quantity);
                    })
                    return amount_administered
                  } else {
                    return 0
                  }
                  
        },
        doseContainerQty(){

            let containerConstant = this.selectedPRN?.container_constant ?? 1
            let myQty = parseFloat(this.enteredDose / containerConstant);

            //this.doseContainerQty = myQty.toFixed(2);

            //this.discardAmount = parseFloat(Math.ceil(myQty) - myQty);

            return myQty.toFixed(2);
        }

    },
    mounted() {
        if (!this.adminSubmission.length) {
            console.log('HITTING THE IF ON MOUNT')
            this.payload = {...this.adminSubmission};
        } else {
            console.log('HITTING THE ELSE ON MOUNT')
            this.payload = this.adminSubmission;
        }
        if(this.selectedPRN.stock_items && this.selectedPRN.stock_items.length){
            
            this.selectedPRN.stock_items.forEach(item=>{
                console.log('got stock items', this.selectedPRN.stock_items)
                item.name = item.alias.name;
            })
            
            
            this.selected_stock_item = this.selectedPRN.stock_items[0];
        }
        this.recommendedSlidingDose()
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.icon-container {
    width: 282px;
    height: 240px;
    left: 25px;
    background: #94D6D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-circle {
    border-radius: 50%;
    height: 131px;
    width: 131px;
    background: #00B2A2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-input {
    span {
        width: 50%;
        input {
            width:50%;
        }
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.icon-circle-item {
    width: 75px;
}

.doc-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.grey-container {
    background-color: #f3f4f4;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    p {
        margin-bottom: 10px !important;
        font-size: 15px;
    }
}

.doc-item {
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}

.selected-doc-item {
    background-color: $error;
    color: white;
}

 

 

  

.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
