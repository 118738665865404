import { createRouter, createWebHistory } from 'vue-router'
import config from "../config"
import Dashboard from '@/views/Dashboard.vue'
import DrugRegister from '@/views/DrugRegister.vue'
import Stock from '@/views/Stock.vue'
import Orders from '@/views/warehouse/Orders.vue'
import Clients from '@/views/client/Clients.vue'
import ClientInfo from '@/views/client/ClientInfo.vue'
import Suppliers from '@/views/supplier/Suppliers.vue'
import SupplierInfo from '@/views/supplier/SupplierInfo.vue'
import Batches from '@/views/warehouse/Batches.vue'
import Invoices from '@/views/warehouse/WarehouseInvoices.vue'
import Patients from '@/views/Patients.vue'
import Resupply from '@/views/Resupply.vue'
import Administration from '@/views/Administration.vue'
import Reports from '@/views/Reports.vue'
import Settings from '@/views/Settings.vue'
import Login from '@/views/Login.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import Onboarding from '@/views/Onboarding.vue'
import PrescriberOnboarding from '@/views/PrescriberOnboarding.vue'
import CompanyOnboarding from '@/views/CompanyOnboarding.vue'
import WarehouseOnboarding from '@/views/WarehouseOnboarding.vue'
// Stock subroutes
import Deliveries from '@/views/stocksub/Deliveries.vue'
import Destructions from '@/views/stocksub/Destructions.vue'
import Returns from '@/views/stocksub/Returns.vue'
// import Outgoing from '@/views/stocksub/Outgoing.vue'
import StockTake from '@/views/stocksub/StockTake.vue'
import Transfers from '@/views/stocksub/Transfers.vue'
// Patient
import PatientGeneral from '@/views/patient/PatientGeneral.vue'
// import PatientGeneralDeprecated from '@/views/patient/PatientGeneralDeprecated.vue'
import NewPatient from '@/views/patient/NewPatient.vue'
import MergePatients from '@/views/patient/MergePatients.vue'
import EditPatient from '@/views/patient/EditPatient.vue'
// AUTH
// import auth from '@/middleware/auth';
// import log from '@/middleware/log';
// Redirect page
import Redirect from '@/views/Redirect.vue'
import FeatureFlags from '@/views/FeatureFlags'

function getConfigName(type) {
  const userType = JSON.parse(localStorage.getItem("user"))?.integration_type;
  const matchedItem = config.find((item) => item.name === userType);
  switch (type) {
    case "Invoices":
      return matchedItem
        ? matchedItem.warehouse_invoices
        : "Warehouse Invoices";
    case "Orders":
      return matchedItem ? matchedItem.orders : "Orders";
    default:
      return "";
  }
}

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
            title: 'Login - Strong Room'
        }
    },
    {
        path: '/reset-password/:id',
        name: 'Password Reset',
        component: PasswordReset,
        meta: {
            guest: true,
            requiresAuth: false,
            title: 'Password Reset - Strong Room'
        }
    },
    {
        path: '/onboarding/:id',
        name: 'Onboarding',
        component: Onboarding,
        meta: {
            guest: true,
            title: 'Onboarding - Strong Room'
        }
    },
    {
        path: '/onboarding/prescriber/:id',
        name: 'Prescriber Onboarding',
        component: PrescriberOnboarding,
        meta: {
            guest: true,
            title: 'Prescriber Onboarding - Strong Room'
        }
    },
    {
        path: '/onboarding/company/:id',
        name: 'Company Onboarding',
        component: CompanyOnboarding,
        meta: {
            guest: true,
            title: 'Company Onboarding - Strong Room'
        }
    },
    {
        path: '/onboarding/company/warehouse/:id',
        name: 'Warehouse Company Onboarding',
        component: WarehouseOnboarding,
        meta: {
            guest: true,
            title: 'Warehouse Company Onboarding - Strong Room'
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta:{
            guest: false,
            requiresAuth: true,
            title: 'Dashboard - Strong Room'
        }
    },
    {
        path: '/drug-register',
        name: 'Drug Register',
        component: DrugRegister,
        meta:{
            requiresAuth: true,
            title: 'Drug Register - Strong Room'
        }
    },
    {
        path: '/resupply',
        name: 'Resupply',
        component: Resupply,
        meta:{
            requiresAuth: true,
            title: 'Resupply List - Strong Room'
        }
    },
    {
        path: '/stock',
        name: 'Stock',
        component: Stock,
        meta:{
            requiresAuth: true,
            title: 'Stock - Strong Room'
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta:{
            requiresAuth: true,
            title: 'Orders - Strong Room'
        }
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        meta:{
            requiresAuth: true,
            title: 'Resident Search - Strong Room'
        }
    },
    // {
    //     path: '/patient/:id/',
    //     name: 'Patient',
    //     component: PatientGeneralDeprecated,
    //     meta:{
    //         requiresAuth: true,
    //         title: 'Resident Information - Strong Room'
    //     }
    // },
    {
        path: '/patient/:id/:tab?',
        name: 'Patient',
        component: PatientGeneral,
        meta:{
            requiresAuth: true,
            title: 'Resident Information - Strong Room'
        }
    },
    {
        path: '/patient/:id/edit',
        name: 'Edit Patient',
        component: EditPatient,
        meta:{
            requiresAuth: true,
            title: 'Edit Resident - Strong Room'
        }
    },
    {
        path: '/patient/:id/merge/:id2',
        name: 'Merge Patients',
        component: MergePatients,
        props:true,
        meta:{
            requiresAuth: true,
            title: 'Merge Residents - Strong Room'
        }
    },
    {
        path: '/patient/new',
        name: 'New Patient',
        component: NewPatient,
        meta:{
            requiresAuth: true,
            title: 'Create New Patient - Strong Room'
        }
        },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
        meta:{
            requiresAuth: true,
            title: 'Administration - Strong Room'
        }
        },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta:{
            requiresAuth: true,
            title: 'Reports - Strong Room'
        }
        },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta:{
            requiresAuth: true,
            title: 'Settings - Strong Room'
        }
        },
    {
        path: '/stock/deliveries',
        name: 'Deliveries',
        component: Deliveries,
        meta:{
            requiresAuth: true,
            title: 'Deliveries - Strong Room'
        }
        },
    {
        path: '/stock/destructions',
        name: 'Destructions',
        component: Destructions,
        meta:{
            requiresAuth: true,
            title: 'Destructions - Strong Room'
        }
        },
    {
        path: '/stock/transfers',
        name: 'Transfers',
        component: Transfers,
        meta:{
            requiresAuth: true,
            title: 'Transfers - Strong Room'
        }
        },
    {
        path: '/stock/stocktake',
        name: 'Stocktake',
        component: StockTake,
        meta:{
            requiresAuth: true,
            title: 'Stock Take - Strong Room'
        }, 
    },
    {
        path: '/stock/invoices',
        name: 'Invoices',
        component: Invoices,
        meta:{
            requiresAuth: true,
            title: 'Warehouse Invoices - Strong Room'
        }, 
    },
    {
        path: '/stock/returns',
        name: 'Returns',
        component: Returns,
        meta:{
            requiresAuth: true,
            title: 'Returns - Strong Room'
        }, 
    },
    {
        path: '/stock/batches',
        name: 'Batches',
        component: Batches,
        meta:{
            requiresAuth: true,
            title: 'Batches - Strong Room'
        }, 
    },
    {
        path: '/msauth',
        name: 'Redirect',
        component: Redirect,
        meta:{
            requiresAuth: true,
            title: 'Redirecting...'
        }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: Clients,
        meta:{
            requiresAuth: true,
            title: 'Clients - Strong Room'
        }
    },
    {
        path: '/client/:uuid',
        name: 'ClientInfo',
        component: ClientInfo,
        meta:{
            requiresAuth: true,
            title: 'Client - Strong Room'
        }
    },
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: Suppliers,
        meta:{
            requiresAuth: true,
            title: 'Suppliers - Strong Room'
        }
    },
    {
        path: '/supplier/:uuid',
        name: 'SupplierInfo',
        component: SupplierInfo,
        meta:{
            requiresAuth: true,
            title: 'Supplier - Strong Room'
        }
    },
    {
        path: '/feature-flags',
        name: 'Feature flags',
        component: FeatureFlags,
        meta:{
            requiresAuth: true,
            title: 'Feature flags'
        }
    }
]

const router = createRouter({ 
    history: createWebHistory(), 
    routes 
})


router.beforeEach((to, from, next) => {
    if (to.name == 'Invoices' || to.name ==  'Orders') {
        document.title = `${getConfigName(to.name)} - Strong Room`
    }else{
        document.title = to.meta.title;
    }
    localStorage.setItem('prev', JSON.stringify(from.path));
    console.log('this is the before each route being used', !('; '+document.cookie).split(`; auth=`).pop().split(';')[0]);
    const userType = JSON.parse(localStorage.getItem('company'))?.type
    if (to.name != 'login' && to.meta.requiresAuth && !('; '+document.cookie).split(`; auth=`).pop().split(';')[0])  next({ path: '/login' })
    else 
      if (userType != "warehouse" && (to.name == 'Invoices' || to.name == 'Orders' || to.name == 'Returns' || to.name == 'Batches' || to.name == 'Clients' || to.name == 'ClientInfo' || to.name == 'Suppliers' || to.name == 'SupplierInfo')) {
       return ;
      }else
    next()
    // console.log(to, from, next);
  })
  

// function checkAuth(to, from, next) 
// {
//     // if (to.name !== 'Login' && !to.meta.requiresAuth) next();
//     // else next("/login");
//     console.log(to, from, next)
// }
// function nextFactory(context, middleware, index) {
//     const subsequentMiddleware = middleware[index];
//     // If no subsequent Middleware exists,
//     // the default `next()` callback is returned.
//     if (!subsequentMiddleware) return context.next;
  
//     return (...parameters) => {
//       // Run the default Vue Router `next()` callback first.
//       context.next(...parameters);
//       // Then run the subsequent Middleware with a new
//       // `nextMiddleware()` callback.
//       const nextMiddleware = nextFactory(context, middleware, index + 1);
//       subsequentMiddleware({ ...context, next: nextMiddleware });
//     };
//   }
  
//   router.beforeEach((to, from, next) => {
//     if (to.meta.middleware) {
//       const middleware = Array.isArray(to.meta.middleware)
//         ? to.meta.middleware
//         : [to.meta.middleware];
  
//       const context = {
//         from,
//         next,
//         router,
//         to,
//       };
//       const nextMiddleware = nextFactory(context, middleware, 1);
  
//       return middleware[0]({ ...context, next: nextMiddleware });
//     }
  
//     return next();
//   });
export default router