<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; margin-top: 2em;">Linked Prescriber List</h4>
            <p style="text-align: left; font-size: 1em; ">Prescribers may be unlinked from a selected facility here, and may be linked again if required.</p>
            <p style="text-align: left; font-size: 1em; ">Unlinking a prescriber removes their access to the selected facility's resident data.</p>
            <div v-if="loadingFacilities">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="margin-bottom: 1em; width: fit-content;">
                <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Select Facility: </p>
                <Dropdown v-model="selectedFacility" :options="facilities" @change="getPrescribers()" optionLabel="label" style="width: 20em; height: fit-content" />
            </div>
            <div v-if="selectedFacility">
                <div v-if="loadingConfig">
                    <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
                </div>
                <table v-else class="data-table" style="width: fit-content">
                    <tr>
                        <th style="width: 20em">Prescriber</th>
                        <th style="width: 10em">Action</th>
                    </tr>
                    <tr v-for="(item,index) in filteredPrescribers" :key="index">
                        <td>{{item.name}}</td>
                        <td><button class="blue-button red-button" @click="unlinkPrescriber(item.uuid)">Unlink</button></td>
                    </tr>
                </table>
            </div>
            
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            facilities:[],
            loadingConfig: false,
            loadingFacilities: false,
            prescribers:[]
        }
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        getPrescribers() {
            this.loadingConfig = true;
            axios.post(`/facility/${this.selectedFacility.value}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = res.data;
                this.loadingConfig = false;
            })
        },
        unlinkPrescriber(id){
            axios.post(`/facility/${this.selectedFacility.value}/prescriber/unlink`, {prescriber_id: id}, this.$store.state.header).then(res=>{
                console.log('succesfully unlinked', res.data);
                this.successMessage = 'Successfully unlinked prescriber from selected facility.';
                this.displaySuccessModal = true;
            }).catch(err=>{
                console.log('this is the error', err.response.data);
                this.successMessage = 'Error: '+err.response.data;
                this.displaySuccessModal = true;
            })
        },
        getFacilities(){
            this.loadingFacilities = true;
            axios.post('/get-facilities', {}, this.$store.state.header).then(res=>{
                console.log('these are the facilities', res.data);
                this.facilities = res.data;
                this.loadingFacilities = false;
            }).catch(err=>{
                console.log('this is the error', err.response.data);
                this.successMessage = 'Error: '+err.response.data;
                this.displaySuccessModal = true;
            })
        }
    },
    mounted() {
        this.getFacilities();
    },
    computed:{
        filteredPrescribers(){
                return this.prescribers.filter(prescriber=>prescriber.name);
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
