<template>
    <div>
        <div class="panel" style="margin-left: 1em">
            <h3 class="panel-header" style="margin-left: 10px;">Merge Residents</h3>
            <div v-if="!loadingResidents" class="panel-content-container patient-form">
                <div class="patient-form-navigation">
                    <!-- <div class="patient-form-image-container">
                        <img :src="previewImage" class="uploading-image" />
                        <div v-if="!previewImage"
                            style="height: 200px; display: flex; border-radius: 8px;
                        flex-direction: column; align-items: center; justify-content: space-around;  background-color: #F3F4F4; margin-bottom: 15px;">
                            <i class="pi pi-user" style="font-size: 5em !important; color: #D8DCDC"></i>
                            <p>Resident photo to display here</p>
                        </div>
                        <label for="patient-image-upload" class="image-file-upload" style="width: 90%; margin: 0 auto;  padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white;
                        background-color: #0870DC"
                            :v-model="previewImage ? 'Change Image' : 'Upload Resident Image'">
                            Upload Resident Image here.
                        </label>
                        <input type="file" id="patient-image-upload" accept="image/jpeg" @change=uploadImage
                            style=" display: none; ">

                    </div> -->

                    <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU" alt=""> -->
                    <div class="patient-form-navigation-items" style="margin-top: 15px;">
                        <div v-for="(item, index) in navigationItems" :key="index" class="patient-form-navigation-item">
                            <div v-if="selectedNavigation == item.link" class="selected-navigation-item"></div>
                            <!-- @click="ihiDetails && selectedNodeKey? selectedNavigation = item.link : ''" -->
                            <!-- :style="(ihiDetails && selectedNodeKey) ? {}:{ opacity: 0.7}" -->
                            <div class="patient-form-navigation-label"
                                :class="[item.completed == true ? 'patient-form-navigation-label-completed' : '']"
                                @click="selectedNavigation = item.link"
                                >
                                <p style="text-align: left">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="patient-form-content">
                    <div class="patient-form-content-section" v-if="selectedNavigation == 'personal_information'">
                        <div class="personal-info-form-content">
                            <h3>Personal Information</h3>
                            <div class="form-fields">
                                <div class="form-field text-field">
                                    <label for="first_name">First Name <span style="color: red">*</span></label>
                                    <Dropdown :editable="true" :options="mergeData.person.first_names" v-model="personalInformation.first_name" />
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Last Name <span style="color: red">*</span></label>
                                    <Dropdown :editable="true" :options="mergeData.person.last_names" v-model="personalInformation.last_name" />
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Preferred Name <span style="color: red">*</span></label>
                                    <Dropdown :editable="true" :options="mergeData.person.preferred_names" v-model="personalInformation.preferred_name" />
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Sex <span style="color: red">*</span></label>
                                    <Dropdown :editable="true" :options="mergeData.person.sexes" v-model="personalInformation.sex" />
                                </div>
                                <div class="form-field date-field">
                                    <label for="first_name">Date of Birth<span style="color: red">*</span></label>
                                    <Dropdown :editable="true" :options="mergeData.person.dobs" v-model="personalInformation.dob" placeholder="dd/mm/yyyy" />
                                </div>
                                
                                <div class="form-field text-field" v-for="(item, index) in personal_regulatory_numbers" 
                                    :key="index">
                                    <label for="first_name">{{ item.regulatory_type }}</label>
                                    <Dropdown v-if="regulatory_collection[item.reference].length > 1" :editable="true" :options="regulatory_collection[item.reference]" v-model="item.data" />
                                    <input v-else type="text" name="first_name" :placeholder="item.data" v-model="item.data">
                                </div>
                            </div>
                        </div>
                         <!-- <div class="personal-info-form-content">
                            <h3 :style="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex ? {color: 'grey'}:{}">IHI Number</h3>
                            <p style="margin: 0; font-size: 0.9em; text-align: left">Fill in the resident's name, sex, DOB, Medicare/DVA Number to retrieve IHI Number.</p>
                            <p style="margin: 0; font-size: 0.9em; text-align: left; color: red; font-weight: bold">IHI Number is required to create prescriptions.</p>
                            <div class="form-fields">
                                
                                <div class="form-field text-field" style="width: fit-content !important">
                                    <label for="first_name">IHI Number <span style="color: red">*</span></label>
                                    <div style="display: flex;">
                                        <Button class="blue-button" style="width: 20em" label="Retrieve IHI Number"
                                    v-if="!regulatory_numbers.ihi_number.number && !inputManualIHI"
                                    :disabled="!personalInformation.first_name || !personalInformation.last_name || !regulatory_numbers.medicare_number || !personalInformation.dob || !personalInformation.sex" @click="retrieveIHINumber()" />
                                    
                                        <InputMask v-if="regulatory_numbers.ihi_number.number && !inputManualIHI" style="background-color: #f3f4f4" mask="9999 9999 9999 9999"
                                        v-model="regulatory_numbers.ihi_number.number" placeholder="9999 9999 9999 9999"/>

                                        <Button :disabled="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex"  v-tooltip="'You need to provide the patient name, date of birth and sex to manually insert IHI number.'"
                                         v-if="!inputManualIHI && !ihiDetails" @click="inputManualIHI = true" class="blue-button"  style="width: 20em; margin-left: 1em;" label="Manually Enter IHI Number"
                                    />
                                        <InputMask v-if="inputManualIHI" style="background-color: #f3f4f4; width: 20em; margin-right: 1em" mask="9999 9999 9999 9999"
                                        v-model="regulatory_numbers.ihi_number.number" placeholder="9999 9999 9999 9999"/>
                                        <Button v-if="inputManualIHI" class="blue-button"  style="width: fit-content; margin-right: 1em;" label="Validate IHI Number"
                                        @disabled="!regulatory_numbers.ihi_number.number" @click="retrieveIHINumber()"  />
                                        <Button v-if="inputManualIHI" class="grey-button"  style="width: fit-content; border: none;" label="Cancel"
                                        @click="inputManualIHI = false"  />
                                    </div>
                                    
                                </div>
                                <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                    <label for="first_name">IHI Number Status</label>
                                    <inputText class="grey-input-color" disabled type="text" name="first_name" :value="ihiDetails.ihi_status" />
                                </div>
                                <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                    <label for="first_name">IHI Record Status</label>
                                    <inputText class="grey-input-color" disabled type="text" name="first_name" :value="ihiDetails.ihi_record_status" />
                                </div>
                                
                                <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                    <Button  @click="displayIHIHistoryModal = true"
                                     class="outline-button p-button-outlined" label="View IHI Number History" />
                                </div>
                            </div>
                         </div> -->
                         <div class="personal-info-form-content">
                            <h3 :style="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex ? {color: 'grey'}:{}">Resident Location</h3>
                            <div class="form-field text-field" v-if="mergeData.locations.length">
                                <label for="first_name">{{mergeData.locations.length > 1 ? 'Select':''}} Location <span style="color: red">*</span></label>
                                <Dropdown v-if="mergeData.locations.length > 1" optionLabel="name" :editable="true" :options="mergeData.locations" v-model="selectedNodeKey" />
                                <input v-else type="text" name="first_name" v-model="selectedNodeKey.name" disabled />
                            </div>
                         </div>
                    </div>

                    <div class="patient-form-content-section" v-if="selectedNavigation == 'contact_details'">
                        <div class="contact-info-form-content">
                            <h3>Contact Details</h3>
                            <div class="form-fields">

                                <div class="form-field text-field">
                                    
                                    <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                                    <div class="form-field text-field">
                                        <label for="first_name">Address (Current: <b>{{gaddress.street_address}}</b>) <span style="color: red">*</span></label>
                                        
                                        <Dropdown v-if="mergeData.addresses.length" :editable="true" :options="mergeData.addresses" optionLabel="street_address" v-model="address" />
                                        <vue-google-autocomplete id="contactmap" classname="form-control"
                                            :placeholder="gaddress.street_address" v-on:placechanged="getPersonalAddressData"
                                            :country="$store.state.country">
                                        </vue-google-autocomplete>
                                    </div>
                                </div>

                                <div class="form-field text-field">
                                    <label for="first_name">Phone <span style="color: red">*</span></label>
                                    <!-- <input type="text" name="first_name" v-model="untitled.phone"> -->
                                    <Dropdown v-if="mergeData.contact_methods.phone_numbers.length > 1" :editable="true" :options="mergeData.contact_methods.phone_numbers" v-model="untitled.phone" />
                                    <InputMask v-else style="background-color: #f3f4f4" mask="(99) 9999 9999" 
                                        v-model="untitled.phone" placeholder="(03) 9999 9999"/>
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Email <span style="color: red">*</span></label>
                                    <Dropdown v-if="mergeData.contact_methods.emails.length > 1" :editable="true" :options="mergeData.contact_methods.emails" v-model="untitled.email" />
                                    <input v-else type="text" name="first_name" v-model="untitled.email" />
                                </div>
                            </div>
                        </div>
                        <!-- <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;"
                                @click="setPatientAddress(), addContactMethods()">Add Contact Details</button>
                        </div> -->
                        <div class="contact-info-form-content">
                            <h3>Next of Kin Details</h3>
                            <div class="form-fields">
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Name</label>
                                    <Dropdown v-if="mergeData.kin.first_names.length > 1" :editable="true" :options="mergeData.kin.first_names" v-model="kin.first_name" />
                                    <input v-else type="text" name="first_name" v-model="kin.first_name">
                                    
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Relationship</label>
                                    <Dropdown v-if="mergeData.kin.relationships.length > 1" :editable="true" :options="mergeData.kin.relationships" v-model="kin.relationship" />
                                    <input v-else type="text" name="first_name" v-model="kin.relationship">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Phone</label>
                                    <Dropdown v-if="mergeData.kin.phone_numbers.length > 1" :editable="true" :options="mergeData.kin.phone_numbers" v-model="kin.phone" />
                                    <input v-else type="text" name="first_name" v-model="kin.phone">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Email</label>
                                    <Dropdown v-if="mergeData.kin.emails.length > 1" :editable="true" :options="mergeData.kin.emails" v-model="kin.email" />
                                    <input v-else type="text" name="first_name" v-model="kin.email">
                                </div>
                            </div>
                        </div>
                        <!-- <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;" @click="createKin()">
                                Add Next of Kin</button>
                        </div> -->
                        <div class="contact-info-form-content">
                            <h3>Entitlement & Regulatory Numbers</h3>
                            <div class="form-fields">
                                <div class="form-field text-field" v-for="(item, index) in grouped_regulatory_numbers" 
                                    :key="index">
                                    <label for="first_name">{{ item.regulatory_type }}</label>
                                    <Dropdown v-if="regulatory_collection[item.reference].length > 1" :editable="true" :options="regulatory_collection[item.reference]" v-model="item.data" />
                                    <input v-else type="text" name="first_name" :placeholder="item.data" v-model="item.data">
                                </div>
                            </div>
                        </div>
                        <!-- <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;"
                                @click="editEntitlementNumbers()">Update Entitlement & Regulatory Numbers</button>
                        </div> -->
                    </div>

                    <div class="patient-form-content-section" v-if="selectedNavigation == 'prescriber_details'">
                        <div class="prescriber-info-form-content" style="display: grid; margin-bottom: 2em;">
                            <h3>Primary General Practitioner Details</h3>
                            <p style="font-size: 1em; text-align: left; margin: 0;">Select prescriber to link to
                                resident.</p>
                            <Dropdown v-if="prescribers && prescribers.length" v-model="selectedPrescriber" :options="prescribers" optionLabel="name"
                                placeholder="Select Prescriber" style="width: fit-content; margin-top: 10px; width: 20em" />

                            <!-- <div style="display: flex;">
                                <button class="button blue-button" style="margin-top: 1em;" @click="submitPharmacy()" 
                                v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Link
                                    Prescriber</button>
                            </div> -->
                        </div>
                        <div class="prescriber-info-form-content" style="display: grid">
                            <h3>Pharmacy Details</h3>
                                <p style="font-size: 1em; text-align: left; margin: 0;">Select pharmacy to link to resident.
                            </p>
                            <!-- optionLabel="label" optionValue="value"  -->
                            <Dropdown v-if="pharmacies && pharmacies.length" v-model="selectedPharmacy" :options="pharmacies" optionLabel="name"
                                placeholder="Select Pharmacy" style="width: fit-content; margin-top: 10px; width: 20em" />
                           
                            <!-- <div style="display: flex;">
                                <button class="button blue-button" style="margin-top: 1em;" @click="submitPharmacy()" 
                                v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Link
                                    Pharmacy</button>
                            </div> -->
                        </div>

                    </div>



                    <!-- FORM BUTTONS -->
                    <div style="display:flex; align-items: center; justify-content: flex-end; align-self: flex-end">
                        <!-- <button class="clear-button"
                            v-if="selectedNavigation != 'personal_information' && selectedNavigation != 'vaccinations'"
                            @click="nextFormPage"
                            style="margin: 0; padding: 0 10px; width: 10em; margin-right: 1em; color: white; background-color: #BDC4C4">Skip</button> -->
                        <button class="outline-button" @click="submitForm"
                            v-if="(this.personalInformation.first_name && this.personalInformation.last_name && this.personalInformation.preferred_name && this.personalInformation.sex && this.personalInformation.dob) && ihiDetails"
                            style="margin: 0; padding: 0 10px; width: 10em; margin-right: 1em; background-color: white; color: #0870DC;">Save
                            and exit</button>
                        <Button class="button" @click="nextFormPage" v-if="selectedNavigation != 'prescriber_details'" :disabled="(!selectedNodeKey) && selectedNavigation == 'personal_information'"
                        style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white; margin-right: 10px;" label="Next" />
                        <Button class="button" @click="submitMerge()" v-if="selectedNavigation == 'prescriber_details'" 
                        :disabled="(!selectedNodeKey || !this.selectedPrescriber || !this.selectedPharmacy || !this.personalInformation.first_name || !this.personalInformation.last_name || !this.personalInformation.preferred_name || !this.personalInformation.sex || !this.personalInformation.dob)"
                        style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white; margin-right: 10px;" label="Complete Merge" />
                        <!-- <button class="button" @click="submitForm" v-if="selectedNavigation == 'vaccinations'" -->
                        <!-- <button class="button" v-if="selectedNodeKey" @click="submitForm" 
                            style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white;">Submit</button> -->
                    </div>
                </div>
            </div>
            <div v-else class="panel-content-container patient-form" style="grid-template-columns: 1fr">
                <div>
                    <h3 style="padding-top: 3em !important;">Loading Residents to Merge...</h3>
                    <div style="margin: 0 auto; width: 100%">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                </div>
                
                
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"
            :first_spec_line="errActions ? '' : ((completedActions / totalActions) * 100).toFixed(0) + '% Completed'"
            :second_spec_line="errActions ? errActions : second_spec_line" />
        <IHIHistoryModal v-if="displayIHIHistoryModal" @close="close" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import IHIHistoryModal from '@/components/modals/IHIHistoryModal.vue';


export default {
    components: {
        SuccessModal,
        IHIHistoryModal
    },
    data() {
        return {
            loadingResidents:false,
            inputManualIHI: false,
            loadingLocations: false,
            displaySuccessModal: false,
            displayIHIHistoryModal: false,
            totalActions: 1,
            completedActions: 0,
            errActionsCount: 0,
            errActions: '',
            first_spec_line: '',
            second_spec_line: 'Do not close this modal until this is completed.',
            successMessage: 'Creating Resident...',
            selectedNavigation: 'personal_information',
            gp: '',
            nonGP: '',
            patientUUID: '',
            pharmacySearchInput: '',
            validForm: false,
            selectedNodeKey: '',
            selectedFacility: '',
            facilities: '',
            nodes: [],
            prescriberResults: [],
            pharmacyResults: [],
            navigationItems: [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Details',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Entitlement Numbers',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Prescriber Details',
                    link: 'prescriber_details',
                    completed: false,
                },

            ],
            adrForm: {
                custom: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            },
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                tag: [],
                sex: '',
                dob: '',
            },
            regulatory_numbers: []
            ,
            sex:[
                {sex:'Male'},
                {sex: 'Female'},
                {sex:'Intersex'}
            ],
            dva_statuses:[
                {status:'Gold', value: 'DVA Gold'},
                {status:'White', value: 'DVA White'},
                {status:'Orange', value: 'DVA Orange'},
            ],
            address: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            gaddress:{
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            facility_location: {
                room_uuid: '',
            },
            kin: {
                first_name:'',
                last_name:'',
                relationship: '',
                phone: '',
                email: ''
            },
            untitled: {
                commencement_date: '',
                expiry_date: '',
                phone: '',
                email: '',
                gp: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: true
                },
                pharmacy: {
                    name: '',
                    address: '',
                    contact_name: '',
                    phone: '',
                    email: '',
                    fax: '',
                    uuid: ''
                },
                prescriber: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: false
                },
                prescribers: [],
                adr: [
                    {
                        custom: '',
                        reaction_description: '',
                        reaction_type: '',
                        date: ''
                    }
                ],
                special_considerations: [],
                special_considerations_notes: '',
                vaccinations: [
                    {
                        vaccine: '',
                        date: ''
                    }
                ],
                weights: [
                    {
                        weight: '',
                        date: ''
                    }
                ]
            },
            // LOCATION DETAILS
            parentLocation: '',
            locationName: '',
            childLocations: [],
            previewImage: null,
            pharmacies: '',
            selectedPharmacy: '',
            prescribers: '',
            selectedPrescriber: '',
            ihiDetails:'',
            mergeData:{
                first_person:'',
                second_person:'',
                person:{
                    first_names:[],
                    last_names:[],
                    preferred_names:[],
                    dobs:[],
                    sexes:[],
                },
                contact_methods:{
                    phone_numbers:[],
                    emails:[]
                },
                kin:{
                    first_names:[],
                    last_names:[],
                    relationships: [],
                    phone_numbers: [],
                    emails: []
                },
                addresses:[],
                locations:[]
            },
            regulatory_collection:{
            }
            
        }
    },
    methods: {
        retrieveIHINumber(){
            // this.regulatory_numbers.ihi_number.number = '9393393999221119';
            // this.personalInformation.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            this.displaySuccessModal = true;
            this.totalActions = 1;
            
            let personalPayload = {...this.personalInformation}
            personalPayload.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];

            // let payload = {
            //     "first_name": "Aoul",
            //     "last_name": "Copeland",
            //     "dob": "1961-07-16", 
            //     "sex": "M",
            //     "ihi_number": "8003608333339273"
            // }

            // axios.post('http://localhost:8030/ihi/get-ihi', payload , this.$store.state.header).then(response=>{
            //     console.log('this is the response for validate IHI', response.data);
            // })
            return axios.post('/person/bundle', personalPayload, this.$store.state.header).then(res => {
                this.completedActions += 1;
                // if(this.completedActions == this.totalActions){
                //     window.location.reload(); 
                // }
                console.log('patient registered.');
                this.patientUUID = res.data.uuid;
                
                this.displaySuccessModal = false;
                
            }).then(() => {
                let payload = {
                    type: this.inputManualIHI ? 'ihi' : this.regulatory_numbers.dva_number.number ? 'dva' : this.regulatory_numbers.medicare_number.number ? 'medicare': 'ihi',
                    number: this.inputManualIHI ? this.regulatory_numbers.ihi_number.number.split(' ').join('') : this.regulatory_numbers.dva_number.number ? this.regulatory_numbers.dva_number.number.split(' ').join('') : this.regulatory_numbers.medicare_number.number ? this.regulatory_numbers.medicare_number.number.split(' ').join('') : '',
                    patient_id: this.patientUUID
                }

                axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                    console.log('this is the response for validate IHI', response.data);
                    this.validIHI(response);
                }).catch(err => {
                    console.log('/validate-ihi failed with the following error:', err, err.response);
                    if (err.response && err.response.data && err.response.data.error && err.response.data.error.includes("duplicate")) {
                        console.log('Resolved and different IHI number returned', err.response.data.data);
                        payload = {
                            type: 'ihi',
                            number: err.response.data.data.ihi_number,
                            patient_id: this.patientUUID
                        }
                        axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                            console.log("This is the response for the second validate IHI", response.data);
                            this.validIHI(response);
                        }).catch(err => {
                            this.invalidIHI(err);
                        })
                    }
                    this.invalidIHI(err);
                })
            }).catch(err => {
                this.displaySuccessModal = true;
                this.message = err.response;
                this.first_spec_line = err.response.data;
                this.second_spec_line = '';
            });
        },
        validIHI(response) {
            this.regulatory_numbers.ihi_number.number = response.data.data.ihi_number;
            this.ihiDetails = response.data.data;
            this.submitRegulatoryInfo();
        },
        invalidIHI(err) {
            this.errActions =  err.response.data.data ? err.response.data.data.service_message.reason : 'Please try again later.';
            this.displaySuccessModal = true;
            this.successMessage = err.response.data.error ? err.response.data.error : err.response.data.exception ? 'Error.' : err.response.data;
            this.first_spec_line = '';
            this.second_spec_line = err.response.data.data.service_message.reason;
        },
        saveNonBasicNonIHIDetails(){
            this.displaySuccessModal = true;
            this.totalActions = 1;
            if (this.previewImage) {
                this.totalActions += 1;
            }
            if (Object.keys(this.selectedNodeKey)[0]) {
                this.totalActions += 1;
            }
            if (this.address.street_address) {
                this.totalActions += 1;
            }
            if (this.untitled.phone || this.untitled.email) {
                this.totalActions += 1;
            }
            if (this.kin.first_name) {
                this.totalActions += 1;
            }
            if (this.untitled.prescriber.prescriber_number) {
                this.totalActions += 1;
            }
            if (this.untitled.gp.prescriber_number) {
                this.totalActions += 1;
            }

            this.submitImage();
            this.confirmFacilityLocation();
            this.submitRegulatoryInfo();
            this.setPatientAddress();
            this.addContactMethods();
            this.createKin();
            // BELOW DOES NOT HAVE COMPLETED/TOTAL ACTIONS ACCOUNTED FOR
            this.submitPharmacy();
            this.submitPrescriber();
            this.submitADR();
            this.submitSpecCons();
            this.submitVx();
            this.submitWeights();
        },
        close() {
            this.displaySuccessModal = false;
            this.displayIHIHistoryModal = false;
            if(this.inputManualIHI && this.errActions){
                this.inputManualIHI = false;
                this.errActions = '';
                this.regulatory_numbers.ihi_number = {
                    number: '',
                    regulatory_type: 'IHI Number'
                }
            }
            // window.location.reload();
        },
        // UPLOAD IMAGE
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        nextFormPage() {
            if (this.selectedNavigation == 'personal_information') {
                console.log('hitting the if, personal_information')
                this.selectedNavigation = this.navigationItems[1].link;
            } else 
            {

                switch (this.selectedNavigation) {
                    // case 'prescriber_details':
                    //     this.selectedNavigation = this.navigationItems[4].link;
                    //     break;
                    case 'personal_information':
                        this.selectedNavigation = 'contact_details';
                        break;
                    case 'contact_details':
                        this.selectedNavigation = 'prescriber_details';
                        break;
                    case 'allergies':
                        this.selectedNavigation = this.navigationItems[5].link;
                        break;
                    case 'considerations':
                        this.selectedNavigation = this.navigationItems[6].link;
                        break;
                    case 'vaccinations':
                        this.selectedNavigation = this.navigationItems[7].link;
                        break;
                    case 'complex':
                        break;

                }

            }
        },
        getPharmacies() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/pharmacies`, {}, this.$store.state.header).then(res => {
                console.log('these are the pharmacies', res.data);
                this.pharmacies = res.data;
            })
        },
        getPrescribers() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = res.data;
            })
        },
        // REGISTER PATIENT
        patientRegistration() {
            this.personalInformation.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            this.displaySuccessModal = true;
            this.totalActions = 1;
            if (this.previewImage) {
                this.totalActions += 1;
            }
            if (Object.keys(this.selectedNodeKey)[0]) {
                this.totalActions += 1;
            }
            if (this.address.street_address) {
                this.totalActions += 1;
            }
            if (this.untitled.phone || this.untitled.email) {
                this.totalActions += 1;
            }
            if (this.kin.first_name) {
                this.totalActions += 1;
            }
            if (this.untitled.prescriber.prescriber_number) {
                this.totalActions += 1;
            }
            if (this.untitled.gp.prescriber_number) {
                this.totalActions += 1;
            }
            return axios.post('/person/bundle', this.personalInformation, this.$store.state.header).then(res => {
                this.completedActions += 1;
                // if(this.completedActions == this.totalActions){
                //     window.location.reload(); 
                // }
                console.log('patient registered.');
                this.patientUUID = res.data.uuid;
                this.submitImage();
                this.confirmFacilityLocation();
                this.submitRegulatoryInfo();
                this.setPatientAddress();
                this.addContactMethods();
                this.createKin();
                // BELOW DOES NOT HAVE COMPLETED/TOTAL ACTIONS ACCOUNTED FOR
                this.submitPharmacy();
                this.submitPrescriber();
                this.submitADR();
                this.submitSpecCons();
                this.submitVx();
                this.submitWeights();
            })
        },
        getLocations() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        // SUBMIT IMAGE
        submitImage() {
            if (this.previewImage) {
                axios.post(`/patient/${this.patientUUID}/image`, {
                    image: this.previewImage,
                }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    console.log('uploaded patient image', res);
                }).catch(err => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error uploading image'
                    console.log('this is the image upload error', err, err.response.data);
                })
            } else {
                console.log('No image has been uploaded');
            }

        },
        // CONFIRM FACILITY LOCATION
        confirmFacilityLocation() {
            if (Object.keys(this.selectedNodeKey)[0]) {
                axios.post(`/person/${this.patientUUID}/location`, { status: 'resident', location: Object.keys(this.selectedNodeKey)[0] }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    console.log('facility location submitted.', res);
                }).catch(err => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error assigning resident to location.'
                    console.log('this is the link resident error', err, err.response.data);
                })
            } else {
                console.log('no facility location confirmed.');
            }
        },
        // REGULATORY INFO
        submitRegulatoryInfo() {
            let regulatory_numbers = [];
            for (let key in this.regulatory_numbers) {
                console.log('this is the key', key);
                if (this.regulatory_numbers[key].number && this.regulatory_numbers[key].regulatory_type != 'IHI Number') {
                    regulatory_numbers.push({
                        data: this.regulatory_numbers[key].number.split(' ').join(''),
                        regulatory_type: this.regulatory_numbers[key].regulatory_type,
                        expiry: this.regulatory_numbers[key].expiry ? new Date(this.regulatory_numbers[key].expiry).toLocaleDateString() : ''
                    })
                }

            }
            if (regulatory_numbers.length) {
                this.totalActions += 1;
                axios.post(`/person/${this.patientUUID}/regulatory`, { 'regulatory_numbers': regulatory_numbers }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    // if(this.completedActions == this.totalActions){
                    //     window.location.reload(); 
                    // }
                    console.log('regulatory information submitted.', res)
                }).catch(err => {
                    this.completedActions += 1;
                    // if(this.completedActions == this.totalActions){
                    //     window.location.reload(); 
                    // }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error submitting regulatory information.'
                    console.log('this is the reg info error', err, err.response.data);
                })
            } else {
                console.log('no regulatory numbers filled in or submitted.')
            }


        },
        // PATIENT ADDRESS
        setPatientAddress() {
            if (this.address.street_address) {
                axios.post(`/person/${this.patientUUID}/address`, this.address, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    console.log('patient address submitted.', res)
                }).catch(err => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error adding address..'
                    console.log('this is the address error', err, err.response.data);
                })
            } else {
                console.log('no address has been added or submitted');
            }

        },
        // ADD PHONE AND EMAIL CONTACT METHODS
        addContactMethods() {
            let contact_methods = [];
            if (this.untitled.phone) {
                contact_methods.push({
                    name: 'Phone',
                    data: this.untitled.phone,
                    type: 'phone'
                });
            }
            if (this.untitled.email) {
                contact_methods.push({
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                });
            }
            if (contact_methods.length) {
                axios.post(`/person/${this.patientUUID}/contact-method`, { 'contact_methods': contact_methods }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    console.log('contact methods submitted.', res)
                }).catch(err => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error adding contact methods.'
                    console.log('this is the create contact method error', err, err.response.data);
                })
            } else {
                console.log('no contact methods were entered, therefore not submitted.')
            }
        },
        // CREATE KIN
        createKin() {
            if (this.kin.first_name) {
                let names = this.kin.first_name.split(' ');
                this.kin.first_name = names.shift();
                this.kin.last_name = names.join(' ');
                axios.post(`/patient/${this.patientUUID}/kin`, this.kin, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    console.log('patient kin submitted.', res)
                }).catch(err => {
                    this.completedActions += 1;
                    if(this.completedActions == this.totalActions){
                        window.location.reload(); 
                    }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error creating Kin.'
                    console.log('this is the create kin error', err, err.response.data);
                })
            } else {
                console.log('first name of kin not entered, so submission not made for kin')
            }

        },
        // ADD PRESCRIBERS (INCL GP) AND PHARMACY
        submitPrescriber() {
            if (this.selectedPrescriber) {
                axios.post(`/person/${this.patientUUID}/link-prescriber`, { prescriber_id: this.selectedPrescriber.uuid, primary: true }, this.$store.state.header).then(res => {
                    console.log('patient prescriber submitted.', res)
                })
            }

        },
        submitPharmacy() {
            if (this.selectedPharmacy) {
                axios.post(`/person/${this.patientUUID}/link-pharmacy`, { pharmacy_id: this.selectedPharmacy.uuid }, this.$store.state.header).then(res => {
                    console.log('patient pharmacy submitted.', res)
                })
            }

        },
        // SUBMIT ADR ARRAY
        submitADR() {
            if (this.untitled.adr[0].custom != '') {
                axios.post(`/patient/${this.patientUUID}/adr`, {adr: this.untitled.adr }, this.$store.state.header).then(res => {
                    console.log(' ADR submitted.', res)
                })
            } else {
                console.log('no adr to submit')
            }
        },
        // SUBMIT SPECIAL CONSIDERATIONS
        submitSpecCons() {
            let specCons = {
                special_considerations: this.untitled.special_considerations,
                comments: this.untitled.special_considerations_notes
            }
            if (specCons.special_considerations.length || specCons.comments) {
                axios.post(`/patient/${this.patientUUID}/special-considerations`, specCons, this.$store.state.header).then(res => {
                    console.log('spec cons submitted.', res)
                })
            } else {
                console.log('no special cons or notes made, not submitted.')
            }

        },
        // SUBMIT VAX
        submitVx() {
            if (this.untitled.vaccinations[0].vaccine != '') {
                axios.post(`/patient/${this.patientUUID}/vaccinations`, this.untitled.vaccinations, this.$store.state.header).then(res => {
                    console.log('vaccinations submitted.', res)
                })
            } else {
                console.log('no vaccinations recorded')
            }
        },
        // SUBMIT WEIGHTS
        submitWeights() {
            if (this.untitled.weights[0].weight != '') {
                axios.post(`/patient/${this.patientUUID}/weights`, this.untitled.weights, this.$store.state.header).then(res => {
                    console.log('weights submitted.', res)
                })
            } else {
                console.log('no weights recorded')
            }
        },
        // FORM SUBMIT PROCESS
        submitForm() {
            this.patientRegistration();
        },
        selectChildLocation() {
            this.facility_location.room_uuid = Object.keys(this.selectedNodeKey)[0];
        },
        addNewADR() {
            let newEntry = {
                custom: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            };
            this.untitled.adr.unshift(newEntry);
        },
        removeADR(index) {
            this.untitled.adr.splice(index, 1);
        },
        addNewVaccine() {
            let newVaccine = {
                vaccine: '',
                date: ''
            };
            this.untitled.vaccinations.unshift(newVaccine);
        },
        removeVaccine(index) {
            this.untitled.vaccinations.splice(index, 1);
        },
        addNewWeight() {
            let newWeight = {
                weight: '',
                date: ''
            };
            this.untitled.weights.unshift(newWeight);
        },
        removeWeight(index) {
            this.untitled.weights.splice(index, 1);
        },
        saveSelectedItem(item) {
            console.log('cacheSelectedItem is working', item);
            if (this.untitled.special_considerations.includes(item)) {
                console.log('this item exists in array', this.untitled.special_considerations);
                this.untitled.special_considerations.forEach((alreadySavedItem, index) => {
                    if (item == alreadySavedItem) {
                        this.untitled.special_considerations.splice(index, 1);
                    }
                })
            } else {
                console.log('this item does not exist in array', this.untitled.special_considerations);
                this.untitled.special_considerations.push(item);
            }
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.gaddress.state = addressData.administrative_area_level_1;
            this.gaddress.city = addressData.administrative_area_level_2;
            this.gaddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.gaddress.country = addressData.country;
            this.gaddress.postcode = addressData.postal_code;
            this.gaddress.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.gaddress);
        },
        getPatients(){
            this.loadingResidents = true;
            console.log('these are the two patient ids', this.$route.params.id, this.$route.params.mergePerson);
            this.mergeData.first_person = this.$route.params.id;
            this.mergeData.second_person = this.$route.params.id2;

            let firstPatient = {
                patient_id: this.$route.params.id,
                location: this.$store.state.currentLocation.uuid
            };
            let secondPatient = {
                patient_id: this.$route.params.id2,
                location: this.$store.state.currentLocation.uuid
            };
            
            axios.post('/retrieve-patient', firstPatient, this.$store.state.header).then(res=>{
                console.log('first patient personal information', res.data.personal_information);
                this.collectPersonalInformation(res.data.personal_information);
                this.collectRegulatoryInformation(res.data.regulatory_information,1);
                this.collectContactInformation(res.data.contact_methods,1);
                this.collectKinInformation(res.data.emergency, 1);
                this.collectAddressInformation(res.data.address);
                if(res.data.physical_location && res.data.physical_location.location){
                    this.collectLocationInformation(res.data.physical_location.location)
                }
                axios.post('/retrieve-patient', secondPatient, this.$store.state.header).then(res2=>{
                    console.log('second patient personal information', res2.data.personal_information);
                    this.collectPersonalInformation(res2.data.personal_information);
                    this.collectRegulatoryInformation(res2.data.regulatory_information,2);
                    this.collectContactInformation(res2.data.contact_methods,2);
                    this.collectKinInformation(res2.data.emergency, 2);
                    this.collectAddressInformation(res2.data.address);
                    if(res2.data.physical_location && res2.data.physical_location.location){
                        this.collectLocationInformation(res2.data.physical_location.location)
                    }
                    this.loadingResidents = false;
                })
            })
        },
        collectPersonalInformation(personalInfo){
            console.log('collecting this personal information', personalInfo);
            this.mergeData.person.first_names.push(personalInfo.first_name);
            this.mergeData.person.last_names.push(personalInfo.last_name);
            this.mergeData.person.preferred_names.push(personalInfo.preferred_name);
            this.mergeData.person.sexes.push(personalInfo.sex);
            this.mergeData.person.dobs.push(personalInfo.dob);
        },
        collectRegulatoryInformation(regulatoryInfo,position){
            console.log('this is the regulatory info', regulatoryInfo);
            let reg_types = ['RAC ID', 'URN/MRN Number', 'Pharmaceutical Entitlement Number', 'Medicare Number', 'Safety Net Number', 'Safety Net Number 2', 'DVA Number', 'Concession Number'];
            reg_types.forEach(type=>{
                let reg = type.split(' ').join('_');
                if(!this.regulatory_collection[reg]){
                    this.regulatory_collection[reg] = [];
                }
                
            });
            let included_reg = [];
            regulatoryInfo.forEach(ent_number => {
                
                if (reg_types.includes(ent_number.regulatory_type)) {
                    // reference is being created so that the select dropdown for each individual reg number can refer to the relevant array in the regulatory collection object
                    ent_number.reference = ent_number.regulatory_type.split(' ').join('_');
                    this.regulatory_collection[ent_number.regulatory_type.split(' ').join('_')].push(ent_number.data);
                    // this.regulatory_numbers.push(ent_number);
                    
                    // included_reg.push(ent_number.regulatory_type);
                    
                    console.log('this is what is included so far', included_reg);
                }

            })
            if(position == 2){
                this.regulatory_numbers.forEach(number=>{
                    
                    if(this.regulatory_collection[number.reference].length == 1){
                        number.data = this.regulatory_collection[number.reference][0];
                    }
                })
            }
           
            // let not_included = reg_types.filter(x => !included_reg.includes(x));
            // console.log('this is what was not included yet', not_included);
            
        },
        collectContactInformation(contactInfo, position){
            contactInfo.forEach(method=>{
                if(method.name.toLowerCase() == 'phone'){
                    this.mergeData.contact_methods.phone_numbers.push(method.data);
                    console.log('this is the merge phone', method);
                    if(position == 2){
                        if(this.mergeData.contact_methods.phone_numbers[0] == this.mergeData.contact_methods.phone_numbers[1]){
                            this.untitled.phone = this.mergeData.contact_methods.phone_numbers[0];
                        }
                    }
                } else if(method.name.toLowerCase() == 'email'){
                    this.mergeData.contact_methods.emails.push(method.data);
                    console.log('this is the merge email', method);
                    if(position == 2){
                        if(this.mergeData.contact_methods.emails[0] == this.mergeData.contact_methods.emails[1]){
                            this.untitled.email = this.mergeData.contact_methods.emails[0];
                        }
                    }
                }
            })
            if(position == 2){
                if(this.mergeData.contact_methods.phone_numbers.length ==1){
                    this.untitled.phone = this.mergeData.contact_methods.phone_numbers[0]
                }
                if(this.mergeData.contact_methods.emails.length ==1){
                    this.untitled.email = this.mergeData.contact_methods.emails[0]
                }
            }
        },
        collectKinInformation(kinInfo, position){
            if(kinInfo.length){
                this.mergeData.kin.first_names.push(kinInfo[0].first_name);
                this.mergeData.kin.last_names.push(kinInfo[0].last_name);
                this.mergeData.kin.relationships.push(kinInfo[0].relationship);
                this.mergeData.kin.phone_numbers.push(kinInfo[0].phone);
                this.mergeData.kin.emails.push(kinInfo[0].email);
            }
            if(position == 2 && this.mergeData.kin.first_names.length){
                
                if(this.mergeData.kin.first_names.length < 2){
                    console.log('less than 2 kin items', this.mergeData.kin);
                    this.kin.first_name = `${this.mergeData.kin.first_names[0]} ${this.mergeData.kin.last_names[0] ? this.mergeData.kin.last_names[0]: ''}`;
                    this.kin.last_name = this.mergeData.kin.last_names[0];
                    this.kin.relationship = this.mergeData.kin.relationships[0];
                    this.kin.phone = this.mergeData.kin.phone_numbers[0];
                    this.kin.email = this.mergeData.kin.emails[0];
                } else{
                    console.log('else, than 2 kin items but maybe match', this.mergeData.kin);
                    if(this.mergeData.kin.first_names[0] == this.mergeData.kin.first_names[1]){
                        this.kin.first_name = this.mergeData.kin.first_names[0];
                    }
                    if(this.mergeData.kin.last_names[0] == this.mergeData.kin.last_names[1]){
                        this.kin.last_name = this.mergeData.kin.last_names[0];
                    }
                    if(this.mergeData.kin.relationships[0] == this.mergeData.kin.relationships[1]){
                        this.kin.relationship = this.mergeData.kin.relationships[0];
                    }
                    if(this.mergeData.kin.phone_numbers[0] == this.mergeData.kin.phone_numbers[1]){
                        this.kin.phone_number = this.mergeData.kin.phone_numbers[0];
                    }
                    if(this.mergeData.kin.emails[0] == this.mergeData.kin.emails[1]){
                        this.kin.email = this.mergeData.kin.emails[0];
                    }
                }
            }
        },
        collectLocationInformation(locationInfo){
            this.mergeData.locations.push(locationInfo);
            if(this.mergeData.locations.length == 1){
                this.selectedNodeKey = this.mergeData.locations[0];
            } else{
                this.selectedNodeKey = ''
            }
        },
        collectAddressInformation(addressInfo){
            if(addressInfo){
                delete addressInfo.uuid;
                this.mergeData.addresses.push(addressInfo);
            }
            if(addressInfo && addressInfo.length == 1){
                this.address = addressInfo;
            } else{
                this.address = {}
            }
        },
        submitMerge(){
            let regulatoryNumbersWithValues = this.regulatory_numbers.filter(num=>num.data)
            regulatoryNumbersWithValues.forEach(num=>{
                delete num.regulatory;
                delete num.regulatory_type;
            })
            let submitPayload = {
                'first_person': this.mergeData.first_person,
                'second_person': this.mergeData.second_person,
                'merge_data': {
                 'address': this.address && this.address.street_address? this.address : this.mergeData.addresses[0] ? this.mergeData.addresses[0] : this.gaddress.street_address ? this.gaddress : {
                    street_address: '',
                    country: '',
                    postcode: '',
                    suburb: '',
                    city: ''
                },
                 'demographics': this.personalInformation,
                 'location': this.selectedNodeKey.uuid,
                 'primary_prescriber': this.selectedPrescriber.uuid,
                 'pharmacy': this.selectedPharmacy.uuid, 
                 'contact_info': [
                    {
                        name:'Phone',
                        type:'phone',
                        data: this.untitled.phone
                    },
                    {
                        name:'Email',
                        type:'email',
                        data:this.untitled.email
                    }
                 ], 
                 'regulatory_numbers': regulatoryNumbersWithValues
                }
            }
            axios.post('/merge-patients', submitPayload, this.$store.state.header).then(res=>{
                console.log('this is the res', res.data);
                this.$router.push(`/patient/${res.data}/edit`);
            })
            console.log('THIS IS THE SUBMIT PAYLOAD', submitPayload);
        },
    },
    mounted() {
        // this.getLocations();
        this.getPharmacies();
        this.getPrescribers();
        let reg_types = ['RAC ID', 'URN/MRN Number', 'Pharmaceutical Entitlement Number', 'Medicare Number', 'Safety Net Number', 'Safety Net Number 2', 'DVA Number', 'Concession Number'];
        reg_types.forEach(type=>{
            let reg = type.split(' ').join('_');
            this.regulatory_collection[reg] = [];
            this.regulatory_numbers.push(
                {
                    regulatory_type: type,
                    'type':type,
                    reference: reg,
                    expiry: '',
                    data: ''
                }
            )
        });
        this.getPatients()
    },
    computed:{
        grouped_regulatory_numbers(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type != 'Medicare Number' && item.regulatory_type != 'DVA Number')
        },
        personal_regulatory_numbers(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type == 'Medicare Number' || item.regulatory_type == 'DVA Number');
        },

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.selected-navigation-item {
    width: 5px;
    height: 45px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    background-color: $strongblue;
}


.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.text-field {
    width: 20em;
}

.date-field {
    width: 20em;
}

.address-field {
    width: 12em;
}

.special-considerations-form {
    background-color: $grey;
    padding: 10px;
    border-radius: 8px;
}

.spec-cons-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spec-cons-item {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.vac-weight-table {
    max-height: 300px;
    overflow-y: scroll;
}

// .form-control{
//     width: 20em;
// }

.prescriber-info-form-content {
    h3 {
        margin-top: 12px;
    }
}

#monthpicker {
    background-color: #F3F4F4 !important
}
</style>