<template>
    <div class="panel" style="margin-left: 1em;">
        <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin: 1em 1em; margin-left: 0;" >
                 <div style="margin-top: 2em;">
                   <div class="chart-form-container" style="width: fit-content; min-width: 61em" :style="{ border:'3px solid '+propChart.color}">
                    <h4 :style="{ backgroundColor:propChart.color}">{{propChart.chart_type}}</h4>
                    <div class="chart-form-panel-container" style="display: grid; grid-template-columns: 28.43em 1fr">
                        <div class="chart-form">
                        <div class="first-chart-row chart-row" style="height: 60px; width: 28.43em; border-right: 1px solid #cacaca !important">
                            <div class="first-chart-row-item chart-row-item">
                                <p class="active-chart-field" v-tooltip="'Medicine Name'" placeholder="Medicine">{{propChart.drug_name}}</p>
                            </div>
                        </div>
                        <div class="chart-row" style="flex-wrap: wrap; width: 28.43em">
                                
                            <div class="chart-row-item" style="width: fit-content" v-for="(prop, index) in propChart.inputs" :key="index">
                                <p class="active-chart-field" style="min-width: 400px; text-align: left; overflow-y:scroll;" v-tooltip="'Reason'" v-if="prop == 'reason'" placeholder="Reason">{{propChart.reason}}</p>
                                <div class="toggle-switch"  v-if="prop == 'single_multi'" :style="{border: '2px solid'+propChart.color}">
                                    <p class="toggle-switch-item" :style="{color: propChart.color}" 
                                    >{{propChart.tags.includes('single') ? 'Single Dose':'Multi Dose'}}</p>
                                </div>
                                <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Route'" placeholder="Route" v-if="prop == 'route'" >{{propChart.route}}</p>
                                <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Hourly Frequency'" placeholder="Hourly Frequency" v-if="prop == 'hourly_frequency'" >{{propChart.hourly_frequency}}</p>
                                <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Max Dose in 24hrs'" placeholder="Max Dose in 24hrs" v-if="prop == 'max_dose'" >{{propChart.max_dose}} {{propChart.form_description}}</p>

                                <div class="toggle-switch" style="margin: 0 auto;" v-if="prop == 'packed'" :style="{border: '2px solid'+propChart.color, color: propChart.color}">
                                    <p class="toggle-switch-item"
                                    >{{propChart.tags.includes('packed') ? 'Packed' : 'Non-Packed'}}</p>
                                    <!-- <p class="toggle-switch-item" :style="propChart.tags.includes('non-packed') ? { color: 'white', backgroundColor: propChart.color}: {color: propChart.color}" 
                                    >Non-Packed</p> -->
                                </div>
                                <div class="toggle-switch" style="margin: 0 5px" v-if="prop == 'now_or_next_pack'" :style="{border: '2px solid'+propChart.color}">
                                    <p class="toggle-switch-item" style="border-right: 2px solid" :style="propChart.tags.includes('now') ? { color: 'white', backgroundColor: propChart.color}: {color: propChart.color}" 
                                    >Now</p>
                                    <p class="toggle-switch-item" :style="propChart.tags.includes('next-pack') ? { color: 'white', backgroundColor: propChart.color}: {color: propChart.color}" 
                                    >Next Pack</p>
                                </div>

                                <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Frequency'" placeholder="Frequency" v-if="prop == 'frequency'" >{{propChart.frequency}}</p>
                                
                                <p class="active-chart-field" style="min-width: 448px;max-height: 100px; text-align: left; overflow-y:scroll;" v-tooltip="'Indication'" placeholder="Indication" v-if="prop == 'indication'" >{{propChart.indication}}</p>

                                <p class="active-chart-field" v-tooltip="'Prescription Date'" placeholder="Prescription Date" v-if="prop == 'prescription_date'" >{{propChart.prescription.script_date}}</p>
                                
                                <p class="active-chart-field" style="min-width: 10em; text-align: left;" v-tooltip="'Start Date'" placeholder="Start Date" v-if="prop == 'start_date'" >{{propChart.start_date}}</p>
                                <p class="active-chart-field" style="min-width: 10em; text-align: left;" v-tooltip="'Stop Date'" placeholder="Stop Date" v-if="prop == 'stop_date'" >{{propChart.stop_date}}</p>
                                
                                <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Date & Time'" placeholder="Date & Time" v-if="prop == 'datetime'" >{{propChart.start_date}}</p>
                                    <div class="toggle-switch" style="margin: 0 auto; min-width: 400px;" v-if="prop == 'substitution'" :style="{border: '2px solid'+propChart.color}">
                                    <p class="toggle-switch-item" style="width: 100%" :style="{color: propChart.color}" 
                                    >{{propChart.substitution ? 'SUBSTITUTION ALLOWED': 'NO SUBSTITUTION ALLOWED'}}</p>
                                </div>
                            </div>
                            <div class="chart-row-item signature-item" style="width: fit-content; border-right: 1px solid #cacaca !important" v-if="propChart.prescriber" @click="()=>{if(!propChart.signatures.includes('prescriber')){displaySignatureModal = true; prescriberSig = true}}">
                                    <!-- PRESCRIBER SIGNATURES -->
                                    <p class="active-chart-field signature-field" v-if="propChart.prescriber" style="min-width: 390px; text-align: center;" v-tooltip="'Prescriber Signed'" 
                                    placeholder="Prescriber Signed" :style="propChart.signatures.includes('prescriber') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}" >{{propChart.signatures.includes('prescriber') ? 'PRESCRIBER SIGNED' :'PRESCRIBER SIGNATURE REQUIRED'}}</p>
                                    <i class="pi pi-check" :style="propChart.signatures.includes('prescriber') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                    <!-- PRESCRIBER SIGNATURES -->
                            </div>
                            <div class="chart-row-item signature-item" style="width: fit-content" v-if="propChart.sign1" @click="()=>{if(!propChart.signatures.includes('sign1')){displaySignatureModal = true; prescriberSig = true}}">
                                <!-- SIGN1 SIGNATURES -->
                                    <p class="active-chart-field signature-field" v-if="propChart.sign1" style="min-width: 160px; text-align: center;" v-tooltip="'Signature 1'" 
                                    placeholder="Signature 1" :style="propChart.signatures.includes('sign1') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}" >{{propChart.signatures.includes('sign1') ? 'SIGNED' :'SIGN 1 REQUIRED'}}</p>
                                    <i class="pi pi-check" :style="propChart.signatures.includes('sign1') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                    <!-- SIGN1 SIGNATURES -->
                            </div>
                            <div class="chart-row-item signature-item" style="width: fit-content" v-if="propChart.sign2" @click="()=>{if(!propChart.signatures.includes('sign2')){displaySignatureModal = true; prescriberSig = true}}">
                                <!-- SIGN2 SIGNATURES -->
                                    <p class="active-chart-field signature-field" v-if="propChart.sign2" style="min-width: 160px; text-align: center;" v-tooltip="'Signature 2'" 
                                    placeholder="Signature 2" :style="propChart.signatures.includes('sign2') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}">{{propChart.signatures.includes('sign2') ? 'SIGNED' :'SIGN 2 REQUIRED'}} 
                                    </p>
                                    <i class="pi pi-check" :style="propChart.signatures.includes('sign2') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                    <!-- SIGN2 SIGNATURES -->
                            </div>
                        </div>
                        <div class="chart-row" style="flex-wrap: wrap; width: 455px; border-right: 1px solid #cacaca !important; " v-if="propChart && propChart.prescription">
                            <div class="chart-row-item signature-item" style="width: fit-content; padding: 10px; display: flex; justify-content: space-between">
                                <div><p style="padding: 5px 10px; margin: 0;font-weight: 500; background-color:#F3F4F4; letter-spacing: 5px; font-size: 1.1em" >{{propChart.prescription.authority_code}}</p></div>
                                <div style="display: flex;" >
                                    <p style="margin-left: 10px; font-weight: 500; width: 3.68em; padding: 5px 10px; color: white; border-radius: 8px;" :style="{ backgroundColor:propChart.color}" 
                                    v-for="(item, index) in propChart.prescription.tags" :key="index">{{item}}</p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="chart-row" style="flex-wrap: wrap; width: 455px; border-right: 1px solid #cacaca !important">
                            <div class="chart-row-item signature-item" style="width: fit-content;">
                                <h3 style="margin: 0 auto" :style="{ color: propChart.color}">Next Due: {{propChart.next_due}}</h3>
                            </div>
                        </div>
                    </div>
                    <!-- COMMENTS SECTION RIGHT SIDE -->
                    <div class="chart-form">
                        <div class="chart-row-item" >
                            <p class="active-chart-field" style="max-height: 150px; text-align: left; overflow-y:scroll;" v-tooltip="'Comments/Instructions'" placeholder="Comments/Instructions" >{{propChart.comments}}</p>
                        </div>
                        <div class="chart-row-item" style=" height: calc(100% - 60px); display: grid;">
                            <p style="margin: 10px; font-size: 1em; padding: 5px 10px; text-align: left; width: fit-content; border-radius: 8px; background-color: #0870DC !important; cursor: pointer " 
                            class="complete-button" @click="displayNoteModal = true">Add Note <i class="pi pi-plus"></i></p>
                            <div style="max-height: 26.5em; width: 100%; padding: 10px; display: flex; align-items: flex-start; flex-direction: column; overflow-y: scroll " >
                                <div v-for="(note, i) in propChart.notes" :key="i" style="background-color: #F3F4F4;width: 100%; border-radius: 12px; padding: 0 10px; margin-bottom: 10px;">
                                    <p style="text-align: left; font-size: 1em; font-weight: bold;">{{note.timestamp}}</p>
                                    <p style="text-align: left; font-size: 1em;">{{note.note}}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <!-- END COMMENTS SECTION RIGHT SIDE -->
                    </div>
                    
                   </div>

                    
               </div>
               
              </div>
              <!-- ADMINISTRATION CALENDAR -->
              <div  v-show="displayCalendar" class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-left: 0;" >
                 <div >
                   <div class="chart-form-container" style="width: fit-content; width: 61em; padding: 2.5px;" :style="{ border:'3px solid '+propChart.color}">
                       <!-- CALENDAR GRID -->
                       <!-- CALENDAR GRID -->
                       <ExpansionCalendar :attributes="attributes" :chart_type="propChart.chart_type" :chartId="chartId" :drug="propChart.drug_name" />
                  
                    <!-- END OF CALENDAR GRID -->
                    <!-- END OF CALENDAR GRID -->
                   </div>
                 </div>
              </div>
              <ChartExpansionNotesModal v-if="displayNoteModal" @close="close" :chartId="chartId"  />
              <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :prescriber="prescriberSig" :endpoint="'/chart/'+chartId+'/signature'" @close="close" />
    </div>
</template>

<script>
import ExpansionCalendar from '@/components/ExpansionCalendar.vue';
import ChartExpansionNotesModal from '@/components/modals/ChartExpansionNotesModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue'

export default {
    props:['expandedChartDetails', 'chartId'],
    components:{
        ExpansionCalendar,
        ChartExpansionNotesModal,
        SignatureModal
    },
    data(){
        return{
            displaySignatureModal:false,
            displayNoteModal: false,
            displayCalendar:false,
            prescriberSig: false,
            days:['SUN', 'MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT'],
            propChart:{},
            charts:[
            {
                name:'Regular Medicine Order',
                inputs:[
                    'single_multi',
                    'route',
                    'packed',
                    'frequency',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'substitution',
                    'indication',
                ],
                subtext:'',
                color: '#9F5CC0',
                prescriber:true,
                sign1:false,
                sign2: false,
                substitution: true,
            },
            // {
            //     name:'S8 Regular Medicine Order',
            //     inputs:[
            //         'single_multi',
            //         'route',
            //         'packed',
            //         'frequency',
            //         'prescription_date',
            //         'start_date',
            //         'stop_date',
            //         'substitution',
            //         'indication',
            //     ],
            //     subtext:'',
            //     color: '#9F5CC0',
            //     prescriber:true,
            //     sign1:false,
            //     sign2: false,
            //     substitution: true,
            // },
            
            {
                name:'PRN Medicine',
                inputs:[
                    'route',
                    'hourly_frequency',
                    'max_dose',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'packed', 
                    'reason',
                    'substitution',
                ],
                subtext:'',
                color: '#00B2A2',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Phone Order',
                inputs:[
                    'reason',
                    'route',
                    'frequency',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                ],
                subtext:'',
                color: '#2E97DE',
                prescriber:true,
                sign1:true,
                sign2: true,
            },
            {
                name:'Short Term Medicine',
                inputs:[
                    'now_or_next_pack',
                    'route',
                    'frequency',
                    'packed',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'indication',
                ],
                subtext:'(antibiotics)',
                color: '#D6001C',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Once Only Meds (Stat)',
                inputs:[
                    'datetime'
                ],
                subtext:'',
                color: '#8E0018',
                prescriber:true,
                sign1:true,
                sign2: false,
            },
            {
                name:'Intermittent Medicine',
                inputs:[
                    'frequency',
                    'start_date'
                ],
                subtext:'',
                color: '#5B00AA',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Variable Dose Meds',
                inputs:[
                    'frequency',
                    'route',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'packed',
                ],
                subtext:'(not insulin)',
                color: '#00AD50',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Nurse Initiated Meds',
                inputs:[
                    'frequency',
                    'route',
                    'reason',
                    'start_date'
                ],
                subtext:'(non-prescription)',
                color: '#274188',
                prescriber:false,
                sign1:false,
                sign2: false,
                nurse: true,
            },
            {
                name:'Non Prescription Meds',
                inputs:[
                    'frequency',
                    'route',
                    'reason',
                    'start_date',
                ],
                subtext:'(resident initiated)',
                color: '#EF9600',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Nutritional Supplement',
                inputs:[
                    'frequency',
                    'route',
                    'start_date',
                    'stop_date',
                    'packed',
                    'substitution',
                    'reason',
                ],
                subtext:'',
                color: '#E56DB1',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            ],
            attributes:[]
        }
    },
    methods:{
        close(value){
            if(value){
                this.displayNoteModal = false;
                this.displaySignatureModal = false
            }
        },
        assignChartColor(){
                switch(this.propChart.chart_type){
                    case 'Regular Medicine Order':
                        this.propChart.color = '#9F5CC0';
                        this.propChart.inputs = this.charts[0].inputs;
                        this.propChart.prescriber = this.charts[0].prescriber;
                        this.propChart.sign1 = this.charts[0].sign1;
                        this.propChart.sign2 = this.charts[0].sign2;
                        break;
                    case 'Nutritional Supplement':
                        this.propChart.color = '#E56DB1';
                        this.propChart.inputs = this.charts[9].inputs;
                        this.propChart.prescriber = this.charts[9].prescriber;
                        this.propChart.sign1 = this.charts[9].sign1;
                        this.propChart.sign2 = this.charts[9].sign2;
                        break;
                    case 'Non Prescription Meds':
                        this.propChart.color = '#EF9600';
                        this.propChart.inputs = this.charts[8].inputs;
                        this.propChart.prescriber = this.charts[8].prescriber;
                        this.propChart.sign1 = this.charts[8].sign1;
                        this.propChart.sign2 = this.charts[8].sign2;
                        break;
                    case 'Nurse Initiated Meds':
                        this.propChart.color = '#274188';
                        this.propChart.inputs = this.charts[7].inputs;
                        this.propChart.prescriber = this.charts[7].prescriber;
                        this.propChart.sign1 = this.charts[7].sign1;
                        this.propChart.sign2 = this.charts[7].sign2;
                        break;
                    case 'Variable Dose Meds':
                        this.propChart.color = '#00AD50';
                        this.propChart.inputs = this.charts[6].inputs;
                        this.propChart.prescriber = this.charts[6].prescriber;
                        this.propChart.sign1 = this.charts[6].sign1;
                        this.propChart.sign2 = this.charts[6].sign2;
                        break;
                    case 'Intermittent Medicine':
                        this.propChart.color = '#5B00AA';
                        this.propChart.inputs = this.charts[5].inputs;
                        this.propChart.prescriber = this.charts[5].prescriber;
                        this.propChart.sign1 = this.charts[5].sign1;
                        this.propChart.sign2 = this.charts[5].sign2;
                        break;
                    case 'Once Only Meds (Stat)':
                        this.propChart.color = '#8E0018';
                        this.propChart.inputs = this.charts[4].inputs;
                        this.propChart.prescriber = this.charts[4].prescriber;
                        this.propChart.sign1 = this.charts[4].sign1;
                        this.propChart.sign2 = this.charts[4].sign2;
                        break;
                    case 'Short Term Medicine':
                        this.propChart.color = '#D6001C';
                        this.propChart.inputs = this.charts[3].inputs;
                        this.propChart.prescriber = this.charts[3].prescriber;
                        this.propChart.sign1 = this.charts[3].sign1;
                        this.propChart.sign2 = this.charts[3].sign2;
                        break;
                    case 'Phone Order':
                        this.propChart.color = '#2E97DE';
                        this.propChart.inputs = this.charts[2].inputs;
                        this.propChart.prescriber = this.charts[2].prescriber;
                        this.propChart.sign1 = this.charts[2].sign1;
                        this.propChart.sign2 = this.charts[2].sign2;
                        break;
                    case 'PRN Medicine':
                        this.propChart.color = '#00B2A2';
                        this.propChart.inputs = this.charts[1].inputs;
                        this.propChart.prescriber = this.charts[1].prescriber;
                        this.propChart.sign1 = this.charts[1].sign1;
                        this.propChart.sign2 = this.charts[1].sign2;
                        break;
                }
            
        },
        mapCalendarObjects(days){
            console.log('these are thr attributes', days);
          days.forEach((day)=>{
              day.customData = day.custom_fields;
              switch(day.customData.administration.doc){
                  case '':
                      break;
                  case 'N':
                      day.customData.administration.doc = 'Not Available';
                      break;
                  case 'A':
                      day.customData.administration.doc = 'Absent';
                      break;
                  case 'F':
                      day.customData.administration.doc = 'Fasting';
                      break;
                  case 'H':
                      day.customData.administration.doc = 'Hospital';
                      break;
                  case 'L':
                      day.customData.administration.doc = 'On Leave';
                      break;
                  case 'S':
                      day.customData.administration.doc = 'Sleeping';
                      break;
                  case 'S/A':
                      day.customData.administration.doc = 'Self Administering';
                      break;
                  case 'V':
                      day.customData.administration.doc = 'Vomiting';
                      break;
                  case 'A/T':
                      day.customData.administration.doc = 'Adjusted Administration';
                      break;
                  case 'C':
                      day.customData.administration.doc = 'Contraindicated';
                      break;
                  case 'N/R':
                      day.customData.administration.doc = 'Not Required';
                      break;
                  case 'O':
                      day.customData.administration.doc = 'Omitted';
                      break;
                  case 'R':
                      day.customData.administration.doc = 'Refused';
                      break;
                  case 'W':
                      day.customData.administration.doc = 'Withheld (Clinical reason)';
                      break;
                  case 'W/R':
                      day.customData.administration.doc = 'Withheld pending results';
                      break;
              }
              delete day.custom_fields; 
          })
          this.displayCalendar = true;
        },
        isSuccessful(value){
            if(value){
                window.location.reload();
                console.log('refreshing the page')
            }
        }
    },
    mounted(){
        this.propChart = this.expandedChartDetails;
        this.assignChartColor();
        this.attributes = this.expandedChartDetails.administrations[0]
        this.mapCalendarObjects(this.attributes)
    }
}
</script>
