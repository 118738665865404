<template>
    <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.reporting_active' >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-else class="drug-register-table table-container" style="width: 95.5vw; border-top-right-radius: 0; height: fit-content; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 67vh) [third-line] 50px [fourth-line];" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters" style="align-items: flex-end">
          <div>
            <p style="font-size: 1em; text-align: left; margin: 0; padding-left: 15px;">Select a report type</p>
            <div>
              <button @click="()=>{form.filters.antipsychotics = !form.filters.antipsychotics; if(form.filters.antipsychotics){form.filters.polypharmacy = false; dateSingle = null;  date = [] }; retrieveQIData() }" 
                v-bind:class="[form.filters.antipsychotics == true ? 'blue-button' : 'outline-button']" class="regular-button" style="margin-right: 10px; margin-top: 0; padding: 0 30px;">Antipsychotics</button>

                <button @click="()=>{form.filters.polypharmacy = !form.filters.polypharmacy; if(form.filters.polypharmacy){form.filters.antipsychotics = false; dateSingle = null; date = []};  retrieveQIData() }" 
                v-bind:class="[form.filters.polypharmacy == true ? 'blue-button' : 'outline-button']" class="regular-button" style="margin-top: 0;     padding: 0 30px;">Polypharmacy</button>
            </div>
            
          </div>
          <div class="date-search-container search-container">
            <p style="text-align: left; margin: 0; font-size: 1em; padding-left: 15px">Select a date {{ form.filters.antipsychotics ? 'range' : '' }}</p>
            <Calendar v-if="form.filters.antipsychotics" :maxDate="maxDate" v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" @date-select="retrieveQIData()" />
            <Calendar v-else v-model="dateSingle" :maxDate="maxDate" selectionMode="single" placeholder="Date..." dateFormat="dd/mm/yy" @date-select="retrieveQIData()" />
          </div>
        </div>
        <div class="report-container">
          <div class="left-report-column" style="grid-template-rows: 208px 1fr">
              <div  class="instructions-container">
                <div class="instructions-header" style="display: flex; justify-content: space-between; align-items: center;">
                  <p>Instructions</p>
                  <div class="info-icon">
                    <i class="pi pi-info"></i>
                  </div>
                  
                </div>
                <div class="instructions-body" v-if="!form.filters.antipsychotics && !form.filters.polypharmacy">
                  <div class="instructions-content">
                    <p class="instruction-content-header">Select a report type to begin</p>
                    <p>Select one of the buttons located above.</p>
                  </div>
                  <div class="instructions-content">
                    <p class="instruction-content-header">Enter a date and quarter</p>
                    <p>Enter the date range the report is completed within.</p>
                  </div>
                </div>

                <div class="instructions-body" v-else>
                  <div class="instructions-content">
                    <p class="instruction-content-header">Data For Provider Portal Reporting</p>
                    <p>You are required to enter into the My Aged Care provider portal each quarter. Fields in this section located below are locked and not to be edited. 
                      The values in the table displayed below will automatically populate based on your completion of the table on the right.</p>
                  </div>
                </div>
              </div>
              <!-- ANTIPSYCHOTICS -->
              <table class="qi-report-table" v-if="form.filters.antipsychotics">
                <tr>
                  <th>QI Program provider portal reporting requirements</th>
                  <th>Enter Values</th>
                </tr>
                <tr>
                  <td>The collection date for the quarter</td>
                  <td><Calendar class="grey-input-color grey-calendar" disabled  v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients assessed for antipsychotic medications</td>
                  <td><inputText disabled class="grey-input-color" style="border: 1px solid #BDC4C4" :value="patients.length" /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients excluded because they were admitted to hospital for the entire seven-day assessment period</td>
                  <td><inputText disabled class="grey-input-color" style="border: 1px solid #BDC4C4" :value="patients_excluded" /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients who received an antipsychotic medication</td>
                  <td><inputText disabled class="grey-input-color" style="border: 1px solid #BDC4C4" :value="anti_patients" /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients who received an antipsychotic medication for a medically diagnosed condition of psychosis</td>
                  <td><inputText disabled class="grey-input-color" style="border: 1px solid #BDC4C4" :value="assessed_anti_patients" /></td>
                </tr>
              </table>
              <!-- POLYPHARMACY -->
              <table class="qi-report-table" v-if="form.filters.polypharmacy" style="height: 98%">
                <tr>
                  <th>QI Program provider portal reporting requirements</th>
                  <th>Enter Values</th>
                </tr>
                <tr>
                  <td>The collection date for the quarter</td>
                  <td>
                    <Calendar class="grey-input-color grey-calendar" disabled v-if="form.filters.polypharmacy"  v-model="dateSingle" selectionMode="single" placeholder="Date..." dateFormat="dd/mm/yy" />
                    <Calendar class="grey-input-color grey-calendar" disabled  v-else v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
                  </td>
                </tr>
                <tr>
                  <td>Total number of care recipients assessed for polypharmacy</td>
                  <td><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="patients.length" disabled /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients excluded because they were admitted to hospital on the collection date</td>
                  <td><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="patients_excluded" disabled  /></td>
                </tr>
                <tr>
                  <td>Total number of care recipients prescribed nine or more medication based on a review of their medication charts and/or administration records as they are on the collection date</td>
                  <td><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="poly_patients" disabled /></td>
                </tr>
              </table>
          </div>
          <div class="right-report-column" style=" overflow-y: scroll; border: 1px solid #BDC4C4; border-radius: 8px; height: 66vh;" v-if="form.filters.antipsychotics || form.filters.polypharmacy">
           
            <!-- ANTIPSYCHOTICS DATA ENTRY -->
            <table class="qi-report-table qi-data-report-table" style="border: none" v-if="form.filters.antipsychotics" >
              <tr>
                <th style="width: 4em">Ref #</th>
                <th>Care Recipient Name</th>
                <th>Care Recipient Admitted to Hospital?</th>
                <th>Care Recipient Received an Antipsychotic during 7 Day Assessment Period</th>
                <th>Care Recipient Received an Antipsychotic during 7 Day Assessment Period for a Medically Diagnosed Psychosis</th>
              </tr>
              <tbody style="max-height: 400px; overflow-y: scroll">
                <tr v-for="(item, index) in patients" :key="index">
                  <td style="width: 6em">{{ index+1 }}</td>
                  <td><input type="text" class="grey-input-color" v-model="item.full_name" style="width: 12em; border: 1px solid #BDC4C4;" disabled /></td>
                  <td><Dropdown @change="updateAntiReport()" :options="statuses" optionValue="value" optionLabel="label" v-model="item.admitted_to_hospital" class="qi-input" placeholder="Select Yes or No"/></td>
                  <td><Dropdown @change="updateAntiReport()" :options="statuses" optionValue="value" optionLabel="label" v-model="item.received_antipsychotic_during_date_period"  class="qi-input" placeholder="Select Yes or No" /></td>
                  <td><Dropdown @change="updateAntiReport()" :options="statuses" optionValue="value" optionLabel="label" v-model="item.received_antipsychotic_during_assessement"  class="qi-input" placeholder="Select Yes or No" /></td>
                </tr>
              </tbody>
            </table>

              <!-- POLYPHARMACY DATA ENTRY -->
              <table class="qi-report-table qi-data-report-table" style="border: none" v-if="form.filters.polypharmacy" >
                <tr>
                  <th style="width: 6em">Ref #</th>
                  <th>Care Recipient Name</th>
                  <th>Care Recipient Admitted to Hospital?</th>
                  <th>Care Recipient Prescribed 9+ Meds on Collection Date</th>
                </tr>
                <tbody style="max-height: 400px; overflow-y: scroll" v-if="!loading">
                  <tr v-for="(item, index) in patients" :key="index">
                    <td style="width: 6em">{{ index+1 }}</td>
                    <!-- :placeholder="item.person.first_name ? item.person.first_name+' '+item.person.last_name : 'Enter name'" -->
                    <td><input type="text" class="grey-input-color" v-model="item.full_name" style="width: 12em; border: 1px solid #BDC4C4;" disabled /></td>
                    <td><Dropdown @change="updatePolyReport()" class="qi-input" placeholder="Select Yes or No" optionValue="value" optionLabel="label" :options="statuses" v-model="item.admitted_to_hospital" /></td>
                    <td><Dropdown @change="updatePolyReport()" class="qi-input" placeholder="Select Yes or No" optionValue="value" optionLabel="label" :options="statuses" v-model="item.polypharmacy" /></td>
                  </tr>
                </tbody>
              </table>
              <h3 v-if="loading">Loading data...</h3>

          </div>  
          

        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 10px;" v-if="(form.filters.antipsychotics || form.filters.polypharmacy) && (date.length || dateSingle)"> 
          <button class="transfer-action-button regular-button error-button" style="margin-right: 10px;" @click="cancelReport()">Cancel Report</button>
          <button class="transfer-action-button regular-button complete-button" @click="submitReport()" >Submit Report</button>
        </div>
        <SuccessModal @successfullySubmitted="successfullySubmitted"
            @close="close" v-if="displaySuccessModal"
            :statusMessage="'QI Report Submission'" :first_spec_line="displayMessage"  />
    </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import SuccessModal from '../modals/SuccessModal.vue';

export default {
  components: {
    SuccessModal
  },
  data(){
    return{
      displaySuccessModal: false,
      maxDate: new Date(),
      displayMessage: '',
      loading: false,
      statuses:[
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        }
      ],
      patients:[],
      patients_excluded: 0,
      poly_patients: 0,
      anti_patients:0,
      assessed_anti_patients:0,
      drugNames: [],
      registerData: [],
      date:[],
      dateSingle:null,
      toggleGraph:false,
      graphType:'Quantity In Stock',
      lineOrBar: 'area',
      filters:{
        s8:true,
      },
      graphData:[],
      clearForm:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          polypharmacy: false,
          antipsychotics: false,
          date:{
            start:'',
            end:''
          },
        }
      },
      form:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          polypharmacy: false,
          antipsychotics: false,
          date:{
            start:'',
            end:''
          },
        }
      },
      testData:[]
    }
  },
  methods:{
    close(value){
      if(value){
        this.displaySuccessModal = false;
      }
    },
    successfullySubmitted(value){
      if(value){
        this.form = this.clearForm;
        this.displayMessage = 'Successfully submitted report.';
        this.cancelReport();
      }
    },
    retrieveQIData(){
      // clear previous data
      this.patients = []
      this.patients_excluded = 0;
      this.poly_patients = 0
      this.assessed_anti_patients = 0;
      this.anti_patients = 0;
      
      console.log('this is the retrieve QI data function', this.form.filters.polypharmacy, this.form.filters.antipsychotics, this.date)
      if((this.date.length == 2 && this.date[1]) || this.dateSingle){
        this.loading = true;
        
        if((this.form.filters.polypharmacy || this.form.filters.antipsychotics)){
          if(this.form.filters.antipsychotics){
            if(typeof this.date == "string"){
              this.form.filters.date.start = this.date.split("-")[0]
              this.form.filters.date.end = this.date.split("-")[1]
            } else{
              this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
              this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
            }
          } else{
              this.form.filters.date.start = new Date(this.dateSingle).toLocaleDateString('en-AU');
              this.form.filters.date.end = new Date(this.dateSingle).toLocaleDateString('en-AU');
          }
          
          let payload = {
            report_type: this.form.filters.polypharmacy? 'polypharmacy' : 'antipsychotic',
            date: this.form.filters.date
          }
          
          axios.post('/get-qi-report', payload, this.$store.state.header).then(res=>{
            
            console.log('this is the QI report data', res.data);
            this.patients = res.data;
            this.patients.forEach(pat=>{
              pat.full_name = pat.personal_information.first_name + ' '+ pat.personal_information.last_name;
              if(pat.med_total > 8){
                pat.polypharmacy = true;
              } else{
                pat.polypharmacy = false;
              }
              pat.received_antipsychotic_during_assessement = false;
              if(pat.received_antipsychotic_during_date_period){
                pat.received_antipsychotic_during_date_period = true
              } else{
                pat.received_antipsychotic_during_date_period = false
              }
            })
            // this.loadReport();
            if(this.form.filters.polypharmacy){
              this.updatePolyReport();
            } else{
              this.updateAntiReport();
            }
           this.loading = false;
            
          }).catch(err=>{
            console.log('this is the qi report error', err);
            this.loading = false;
          // this.loadReport();
          //   if(this.form.filters.polypharmacy){
          //     this.updatePolyReport();
          //   } else{
          //     this.updateAntiReport();
          //   }
          })
          
        }
      }
      
      
    },
    submitReport(){
      let patientList = [];
      this.patients.forEach(patient=>{
        let p = {...patient};
        if(!p.admitted_to_hospital){
          p.admitted_to_hospital = false;
        }
        delete p.personal_information;
        delete p.full_name;
        delete p.med_total;
        patientList.push(p);
      })

      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }
      }
      if(this.form.filters.antipsychotics){
        
        this.payload = {
          date: this.form.filters.date,
          report_type: 'antipsychotic',
          total_patients: this.patients.length,
          patients_excluded: this.patients_excluded,
          patients_received_antipsychotics: this.anti_patients,
          patients_received_anti_during_assessement: this.assessed_anti_patients,
          poly_patients: 0,
          patients: patientList
        }
        console.log('this is the antipsychotics payload', this.payload);
        this.displayMessage = 'Please click Complete to submit QI report.';
        this.displaySuccessModal = false;
        console.log('this is the polypharmacy payload', this.payload);
      } else if(this.form.filters.polypharmacy){
        this.payload = {
          date: this.form.filters.date,
          report_type: 'polypharmacy',
          total_patients: this.patients.length,
          patients_excluded: this.patients_excluded,
          patients_received_antipsychotics: 0,
          patients_received_anti_during_assessement: 0,
          poly_patients: this.poly_patients,
          patients: patientList
        }
        this.displayMessage = 'Please click Complete to submit QI report.';
        this.displaySuccessModal = false;
        console.log('this is the polypharmacy payload', this.payload);
      }

      axios.post('/submit-qi-report', this.payload, this.$store.state.header).then((res) => { 
        this.displaySuccessModal = true;
        this.displayMessage = "Successfully submitted QI report.";
      })
      
    },
    cancelReport(){
      this.patients = [];
      this.admitted_to_hospital = null,
      this.anti_patients = null,
      this.assessed_anti_patients = null,
      this.poly_patients = null,
      this.patients_excluded = null,
      this.date = []
    },
    updatePolyReport(){
      this.patients_excluded = 0;
      this.poly_patients = 0;
      this.patients.forEach(patient=>{
        if(patient.admitted_to_hospital){
          this.patients_excluded += 1;
        }
        if(patient.polypharmacy){
          if(!patient.admitted_to_hospital){
            this.poly_patients += 1;
          }
        }
      })
    },  
    updateAntiReport(){
      this.patients_excluded = 0;
      this.anti_patients = 0;
      this.assessed_anti_patients = 0;
      
      this.patients.forEach(patient=>{
        if(patient.admitted_to_hospital){
          this.patients_excluded += 1;
        }
        if(patient.received_antipsychotic_during_date_period){
          if(!patient.admitted_to_hospital){
            this.anti_patients += 1;
          }
        }
        if(patient.received_antipsychotic_during_assessement){
          if(!patient.admitted_to_hospital){
            this.assessed_anti_patients +=1;
          }
          
        }
      })
    },
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getStockReportEntries', this.form)
    },
    // loadReport(){
    //   console.log('this is the date', this.date);
      
    //   if(this.date.length){
    //     if(typeof this.date == "string"){
    //       this.form.filters.date.start = this.date.split("-")[0]
    //       this.form.filters.date.end = this.date.split("-")[1]
    //     } else{
    //       this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
    //       this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
    //     }
    //     this.retrieveQIData();
    //   }
    // },
   mounted(){
  },
  computed:{
  }
}
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

  .instructions-container{
    border: 1px solid $strongblue;
    width: 650px;
    height: 208px;
    border-radius: 8px;

    p{
      text-align: left;
      margin: 0;
    }
  }

  .instructions-header{
    background-color: $strongblue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 5px 25px;
    height: 46px;
    p{
      font-size: 1.2em;
      
    }
  }

  .instructions-body{
    padding: 25px;
    p{
      font-size: 1em;
    }
  }

  .instructions-content{
    margin-bottom: 20px;
  }

  .instruction-content-header{
    font-weight: bold;
    padding-bottom: 5px;
  }

  .left-report-column{
    width: 650px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .right-report-column{
    max-width: 995px;
    min-width: 400px;
    flex: 1 1 0px;
  }

  .qi-report-table{
    padding: 0;
    border-radius: 8px;
    border: 1px solid #BDC4C4;
    border-spacing: 0;
    th,td{
      padding: 0;
    }
    th{
      background-color: #F3F4F4;
      border-bottom: 3px solid #BDC4C4;
      height: 60px;
    }
    th:first-of-type{
      border-top-left-radius: 8px;
    }
    th:last-of-type{
      border-top-right-radius: 8px;
    }
    th,td{
      padding: 0 25px;
    }
    td{
      height: 60px;
    }
  }

  .qi-data-report-table{
   
    th{
      font-size: 15px;
      padding: 16.5px 0;
    }
    td{
      padding: 25px 0;
      padding-right: 10px;
      border-bottom: 1px solid #BDC4C4;
    }
    tr{
      td:first-of-type{
        padding-left: 16.5px;
      }
    }
    th:first-of-type{
      padding-left: 15px;
    }
    th:last-of-type{
      padding-right: 26px;
    }
    // td:first-of-type{
    //   padding-left: 26px;
    // }
    // td:last-of-type{
    //   padding-right: 26px;
    // }
    tr:last-of-type{
      td{
        border-bottom: none;
      }
    }
    
  }

  .report-container{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  .grey-calendar{
    border-radius: 8px;
    border: 1px solid #BDC4C4;
    .p-inputtext{
      background-color: #f3f4f4;
    }
  }

  .qi-input{
    width: 13em;
  }



</style>