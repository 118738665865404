<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 70%;">
                <div class="modal-header">
                    <h3 class="form-header">Select Dose Omitted Code</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                                <div class="form-section-container" style="grid-template-columns: 4fr 4fr; grid-gap: 0px">

                                <div class="left-form-section-panel">
                                    <div class="doc-grid-container">
                                        <div v-for="(item, index) in doseOmittedCode" :key="index" 
                                        class="doc-item" :class="selectedCode == item && selectedCode.value != 'P' ? 'selected-doc-item': item.value == 'P' && selectedCode.value == 'P' ? 'pending-selected-doc-item':''" 
                                        @click="selectedCode = item">{{item.value}}<br/><span style="font-size: 12px;">{{item.label}}</span></div>
                                    </div>
                                </div>
                                <div class="right-form-section-panel">
                                    <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                    <textarea
                                        name="notes"
                                        id="note-modal"
                                        class="noresize"
                                        rows="16"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="notes">
                                    </textarea>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button class="green-button" @click="signAction()">
                           {{!noAuthRequired ? 'Sign': 'Complete'}}
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="loop ? '/administer-bulk' : administerType == 'patch' ? non_admin_patch_signature_endpoint : administerType == 'non-admin' ? prn_signature_endpoint : signature_endpoint" 
            :noAuthRequired="noAuthRequired" :bulkAdminister="adminSubmission.bulk_meds ? true: false"  />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';

export default {
    props: ['message', 'close', 'loading', 'statusMessage', 'action', 'actionType', 'noAuthRequired', 'adminSubmission', 'loop', 'adminRound', 'administerType', 'selectedDrugName'],
    components: {
        SignatureModal
    },
    data() {
        return {
            payload:'',
            signature_endpoint:'/administer',
            prn_signature_endpoint:'/administer-chart',
            non_admin_patch_signature_endpoint: '/administer-patch',
            displaySignatureModal: false,
            selectedCode:'',
            notes:'',
            doseOmittedCode:[
            {value:  'A',
              label:'Absent'
            },
            {value:  'F',
              label:'Fasting'
            },
            {value:  'H',
              label:'Hospital'
            },
            {value:  'L',
              label:'On Leave'
            },
            {value:  'S',
              label:'Sleeping'
            },
              {value:'S/A',
                  label:'Self Administration'
                },
            {value:  'V',
              label:'Vomiting'
            },
              {value:'A/T',
                  label:'Adjusted Administration'
                },
            {value:  'C',
              label:'Contraindicated'
            },
            {value:  'N',
              label:'Not available'
            },
              {value:'N/R',
                  label:'Not Required'
                },
            {value:  'O',
              label:'Omitted'
            },
            {value:  'R',
              label:'Refused'
            },
            {value:  'W',
              label:'Withheld (clinical)'
            },
            {value:'W/R',
                label:'Withheld (pending results)'
            },
            {value:'P',
                label:'Pending'
            },
            ],
            numSuccessfullySubmitted:0
        }
    },
    methods: {
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                console.log('receiving the data back to docmodal', value);
                this.$emit('successfullySubmitted', true);
                if(!this.adminSubmission?.bulk_meds){
                    console.log('this is not a array item');
                    if(this.administerType == 'patch'){
                        this.$emit('receiveDOC', {id: this.adminSubmission.chart_id, doc: this.selectedCode, ...value });
                    } else{
                        this.$emit('receiveDOC', {id: this.adminSubmission.calendar_item_id, doc: this.selectedCode, ...value });
                    }
                    
                } else{
                    console.log('this is an array of packed meds', value.index);
                    this.$emit('receiveDOC', value);
                }
                
                this.numSuccessfullySubmitted +=1;
                // if the array of meds has been all completed successfully, close the signature modal
                if(this.adminSubmission?.bulk_meds && this.numSuccessfullySubmitted == this.payload.length){
                    this.displaySignatureModal = false;
                    console.log('closing the signature modal from the docmodal');
                    this.closeModal();
                } else{
                    if(!this.adminSubmission.length){
                        this.displaySignatureModal = false;
                        this.closeModal();
                    }
                }
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            let date = new Date();
            if(!this.loop){
                console.log('SIGN ACTION HITTING IF CONDITION')
                this.payload.doc = this.selectedCode.value;
                
                let notes = `Medication not administered.
DOC Code: ${this.payload.doc} [${this.selectedCode.label}]

Drug name: ${this.selectedDrugName ? this.selectedDrugName : this.adminSubmission?.drug?.alias?.name}
Administration Route: ${this.adminSubmission.drug.route}
Qty: ${this.adminSubmission.drug.dose} ${this.adminSubmission.drug.dose_unit}/s
Date/time: ${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}
Reason/notes:  ${this.notes}`
            this.payload.notes = notes;
            } else{
                console.log('SIGN ACTION CONDITION', this.payload);
                if(this.adminSubmission?.bulk_meds){
                    console.log('SIGN ACTION HITTING ELSE THEN IF CONDITION');
                    if(!this.adminRound){
                        
                        this.adminSubmission.bulk_meds.forEach(load=>{
                            load.doc = this.selectedCode.value;
                            let notes = `Medication not administered.
DOC Code: ${this.selectedCode.value} [${this.selectedCode.label}]
Drug Name: ${load.drug}
Qty: ${load.dose} ${load.dose_unit}
Date/time: ${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}
Reason/notes: ${this.notes}`
                            console.log('GOING THROUGH THIS LOOP ABOVE PAGE 193' )
                            load.note = notes;
                        })
                    } else{
                        this.adminSubmission.bulk_meds.forEach(load=>{
                            load.doc = this.selectedCode.value;
                            let notes = `Medication not administered.
DOC Code: ${load.doc} [${this.selectedCode.label}]

Date/time: ${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}
Reason/notes:  ${this.notes}`
                            load.note = notes;
                        })
                    }
                    this.payload.doc = this.selectedCode.value;
                    
                } else{
                    console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
                    this.payload.forEach(load=>{
                        load.doc = this.selectedCode.value;
                            let notes = `Medication not administered.
DOC Code: ${load.doc} [${this.selectedCode.label}]
Date/time: ${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}
Reason/notes:  ${this.notes}`
                        load.note = notes;
                    })
                }
                
            }
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
        if(!this.adminSubmission.length){
            console.log('HITTING THE IF ON MOUNT')
            this.payload = {...this.adminSubmission};
        } else{
            console.log('HITTING THE ELSE ON MOUNT')
            this.payload = this.adminSubmission;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.doc-grid-container{
    display: grid;
    grid-template-columns: auto auto auto auto !important;
    
    grid-gap: 10px;
}

.doc-item{
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}


.selected-doc-item{
    background-color: $error;
    color: white;
}

.pending-selected-doc-item{
    background-color: $warning;
    color: white;
    border: 2px solid $warningborder;
}

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
