<template>
  <div class="drug-register-table two-row-filter-table table-container" style="width: 95.5vw; border-top-right-radius: 0;"
    :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
    <div class="filters">
      <div class="text-search-container search-container">
        <input v-model="form.filters.audits.drug" placeholder="Medication..." style="position: relative" />
        <div class="drug-search-result-container" v-if='form.filters.audits.drug'
          style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
          <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">
            {{ item }}</p>
        </div>
      </div>
      <div class="date-search-container search-container">
        <input v-model="form.filters.action" placeholder="Action..." style="position: relative" />
      </div>
      <div class="date-search-container search-container">
        <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
      </div>
      <div class="text-search-container search-container">
        <input v-model="form.filters.location" placeholder="Location..." style="position: relative" />
      </div>
      <div class="text-search-container search-container">
        <input v-model="form.filters.login" placeholder="Login..." style="position: relative" />
      </div>
      <div class="text-search-container search-container">
        <input v-model="form.filters.signature" placeholder="Signed by..." style="position: relative" />
      </div>
      <div class="select-filters-container">
        <p @click="() => { filters['200'] = !filters['200']; autoAll() }"
          v-bind:class="[filters['200'] == true ? 'active-select-filter' : '']" class="select-filter-item">200</p>
        <p @click="() => { filters['400'] = !filters['400']; autoAll() }"
          v-bind:class="[filters['400'] == true ? 'active-select-filter' : '']" class="select-filter-item">400</p>
        <p @click="() => { filters['404'] = !filters['404']; autoAll() }"
          v-bind:class="[filters['404'] == true ? 'active-select-filter' : '']" class="select-filter-item">404</p>
        <p @click="() => { filters['500'] = !filters['500']; autoAll() }"
          v-bind:class="[filters['500'] == true ? 'active-select-filter' : '']" class="select-filter-item">500</p>
      </div>
      <button class="button submit-button" @click="loadReport()">Search</button>
      <button class="button clear-button" @click="clearFilteredDrugRegister()" style="margin-right: 2em">Clear</button>
      <!-- downloadStockReport() -->
      <button class="button clear-button" @click="downloadReport()" v-if='$store.state.user.role.permissions.includes("can_export_audit_report") && $store.state.currentLocation.state != "WA"'
        style="margin-right: 2em; background-color: #0870DC; color: white">Download CSV</button>
      <button class="button clear-button" @click="printElem('transaction-table')" v-if='$store.state.user.role.permissions.includes("can_export_audit_report")'
        style="margin-right: 2em; background-color: #0870DC; color: white">Print</button>
        <!-- style="padding: 3px 10px; height: 30px;  border-radius: 8px; border: 2px solid #0870DC; cursor: pointer; margin-bottom: 5px; font-weight: bold;" -->
      <button class="button" @click="form.filters.role_changes = !form.filters.role_changes, loadReport()" v-if='$store.state.user.role.permissions.includes("can_view_audit_report")'
      :class="form.filters.role_changes ? 'blue-button' : 'outline-button'"
      :style="form.filters.role_changes ? {color: 'white'}:{color: '#0870DC'}" style="margin: 0; padding: 0 30px;"
      >Role Creation/ Changes Only</button>
      <button class="button" @click="form.filters.responsible_changes = !form.filters.responsible_changes, loadReport()" v-if='$store.state.user.role.permissions.includes("can_view_audit_report")'
      :class="form.filters.responsible_changes ? 'blue-button' : 'outline-button'"
      :style="form.filters.responsible_changes ? {color: 'white'}:{color: '#0870DC'}" style="margin: 0; padding: 0 30px;margin-left: 30px"
      >Responsible Person</button>
      <div style='margin-right: 20em'>

      </div>
    </div>
    <div class="drug-variances-container" style="display: flex;"
      v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
      <p style="padding: 5px 10px; margin-right: 10px;">Refine your search: </p>
      <p v-for="(item, index) in $store.state.drugEntries.drug_aliases" :key="index" @click="aliasSearch(item)"
        class="drug-variance-option">{{ item }}</p>
      <!-- <p style="padding: 5px 10px; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer" @click="aliasSearch('Endone 10mg')" >Endone 10mg</p> -->
    </div>
    <div style='overflow-y: scroll'>
      <div class="drug-register-table table-container" style="padding-top: 10em;"
        v-if='!$store.state.user.role.permissions.includes("can_view_audit_report")'>
        <h3
          style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
          You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
      </div>
      <div v-else>
        <div v-if="$store.state.loadingAuditData">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 2em;"></i>
        </div>
        <table v-else class="data-table audit-table" id="transaction-table" cellspacing="0">
          <!-- LOADING SPINNER -->

          <tr class="table-headers">
            <th style="width: 3em" class="expand-column"></th>
            <th style="width: 10em">
              <h3 @click="sortBy('date', 'desc')">Date</h3>
              <div v-if="form.col == 'date'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort date-sort" v-if="form.col == 'date' && form.sort_direction == 'asc'"
                  @click="sortBy('date', 'desc')"></i>
                <i class="pi pi-sort date-sort" v-else @click="sortBy('date', 'asc')"></i>
              </div>
            </th>
            <th style="width: 4em">
              <h3 @click="sortBy('status_code', 'desc')">Status</h3>
              <div v-if="form.col == 'status_code'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort status_code-sort" v-if="form.col == 'status_code' && form.sort_direction == 'asc'"
                  @click="sortBy('status_code', 'desc')"></i>
                <i class="pi pi-sort status_code-sort" v-else @click="sortBy('status_code', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3 @click="sortBy('ip', 'desc')">IP</h3>
              <div v-if="form.col == 'ip'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort ip-sort" v-if="form.col == 'ip' && form.sort_direction == 'asc'"
                  @click="sortBy('ip', 'desc')"></i>
                <i class="pi pi-sort ip-sort" v-else @click="sortBy('ip', 'asc')"></i>
              </div>
            </th>

            <th style="width: 10em">
              <h3 @click="sortBy('user_agent', 'desc')">Browser</h3>
              <div v-if="form.col == 'user_agent'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort user_agent-sort" v-if="form.col == 'user_agent' && form.sort_direction == 'asc'"
                  @click="sortBy('user_agent', 'desc')"></i>
                <i class="pi pi-sort user_agent-sort" v-else @click="sortBy('user_agent', 'asc')"></i>
              </div>
            </th>
            <th style="width: 15em">
              <h3 @click="sortBy('action', 'desc')">Action</h3>
              <div v-if="form.col == 'action'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort action-sort" v-if="form.col == 'action' && form.sort_direction == 'asc'"
                  @click="sortBy('action', 'desc')"></i>
                <i class="pi pi-sort action-sort" v-else @click="sortBy('action', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3 @click="sortBy('login', 'desc')">Login</h3>
              <div v-if="form.col == 'login'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  login-sort" v-if="form.col == 'login' && form.sort_direction == 'asc'"
                  @click="sortBy('login', 'desc')"></i>
                <i class="pi pi-sort login-sort" v-else @click="sortBy('login', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3 @click="sortBy('signature', 'desc')">Signed By</h3>
              <div v-if="form.col == 'signature'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  signature-sort" v-if="form.col == 'signature' && form.sort_direction == 'asc'"
                  @click="sortBy('signature', 'desc')"></i>
                <i class="pi pi-sort signature-sort" v-else @click="sortBy('signature', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3 @click="sortBy('location', 'desc')">Location</h3>
              <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  location-sort" v-if="form.col == 'location' && form.sort_direction == 'asc'"
                  @click="sortBy('location', 'desc')"></i>
                <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div>
            </th>
          </tr>

          <tbody class="table-data" v-for="(item, index) in getDrugEntries.logs" :key="index">

            <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
              @click="showContent(item, index)">
              <td style="min-width: 30px;" class="expand-column">
                <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                <i v-else class="pi pi-angle-right"></i>
              </td>
              <td>{{ item.recorded }} </td>
              <td style="font-weight: bold"
                :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">{{ item.status_code }}</td>
              <td>{{ item.ip }}</td>
              <td>{{ item.user_agent.slice(0, 15) }}...</td>
              <td>{{ item.action.split('_').slice(1).join('_') }}</td>
              <td>{{ item.login }}</td>
              <td>{{ item.signatures && item.signatures.length ? item.signatures[0] : '' }} {{ item.signatures.length > 1 ?
                  '+1' : ''
              }}</td>
              <td>{{ item.location ? item.location.name + ', ' + item.location.state : '' }}</td>
            </tr>
            <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content">
              <div class="expanded-transfer-container" style="width: 88vw">
                <div v-if="!item.expandedInfo" style="margin: 0 auto; margin-bottom: 1em;">
                  <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                </div>
                <div class="drug-register-specs-container" v-if="item.expandedInfo">

                  <div class="transfer-type-field">
                    <p class="field-title">Status Code</p>
                    <!-- v-if="item.expandedInfo.transaction_type" -->
                    <p class="field-value">{{ item.status_code }}</p>
                  </div>
                  <div class="script-field">
                    <p class="field-title">Action</p>
                    <p class="field-value">{{ item.action }}</p>
                  </div>
                  <div class="date-entered-field">
                    <p class="field-title">Recorded</p>
                    <p class="field-value">{{ item.recorded }}</p>
                  </div>
                  <div class="to-from-field">
                    <p class="field-title">Location</p>
                    <p class="field-value" v-if="item.location">{{ item.location.name }}{{ item.location.state ? ', ' + item.location.state : '' }}</p>
                    <p v-else></p>
                  </div>
                  <div class="location-contact-field">
                    <p class="field-title">User Agent</p>
                    <div class="field-value-container" style="overflow: scroll">
                      <p class="field-value">{{ item.user_agent }}</p>
                    </div>
                  </div>
                  <div class="fulfilled-by-field">
                    <p class="field-title">Signature 1</p>
                    <p class="field-value" v-if="item.signatures">{{ item.signatures.length ? item.signatures[0] : 'N/A' }}</p>
                    <p v-else></p>
                  </div>
                  <div class="fulfilled-time-field">
                    <p class="field-title">Signature 2</p>
                    <p class="field-value">{{ item.signatures.length ? item.signatures[1] : 'N/A' }}</p>
                  </div>
                </div>

                <div class="transfer-notes-container" style="min-width: 15em" v-if="item.expandedInfo">
                  <p class="field-title">Message</p>
                  <div class="field-notes" style="width: 250px; height: 230px;">
                    <p>{{ item.expandedInfo.message }}</p>
                  </div>
                </div>
                <div class="transfer-notes-container" style="min-width: 33%" v-if="item.expandedInfo && item.expandedInfo.audits && item.expandedInfo.audits.length">
                  <p class="field-title">Audit</p>
                  <div v-if="item.expandedInfo && item.expandedInfo.audits && item.expandedInfo.audits.length"
                    class="field-notes" style="width: 100%; height: 230px;">
                    <pre style="text-align: left">{{ JSON.stringify(item.expandedInfo.audits[0], null, 2) }}</pre>
                  </div>
                </div>
              </div>
              <!-- TRANSFER ACTIONS -->
              <div class="transfer-actions-container" style="width: 88vw" v-if="item.expandedInfo">
                <!-- <button @click="drugEntryAction('Cancel', item.uuid)" class="transfer-action-button error-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Cancel Entry</button> -->
                <!-- <button @click="drugEntryAction('Reject', item.uuid)" class="transfer-action-button error-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Reject Entry</button> -->
                <!-- <button @click="drugEntryAction('Reverse', item.uuid)" class="transfer-action-button error-button" v-show="item.action != 'destruction' && item.action != 'reversal' && item.action != 'adjustment' && !item.expandedInfo.reversed_date && $store.state.user.role.permissions.includes('can_use_drug_register')">Reverse Entry</button> -->
                <!-- <button @click="drugEntryAction('Approve', item.uuid)" class="transfer-action-button complete-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Complete Entry</button> -->
                <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i
                    class="pi pi-times"></i></button>
              </div>
            </tr>
          </tbody>
        </table>

        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <div style="width: 100vw">
          <table class="data-table print-tx-table tx-table" id="transaction-table" cellspacing="0">
            <tr>
              <th style="width: 10em">Date</th>
              <th style="width: 4em">Status</th>
              <th style="width: 10em">IP</th>
              <th style="width: 10em">Browser</th>
              <th style="width: 15em">Action</th>
              <th style="width: 10em">Login</th>
              <th style="width: 10em">Signed By</th>
              <th style="width: 10em">Location</th>
            </tr>
            <tr v-for="(item, index) in getDrugEntries.logs" :key="index">
              <td>{{ item.recorded }} </td>
              <td style="font-weight: bold"
                :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">{{ item.status_code }}</td>
              <td>{{ item.ip }}</td>
              <td>{{ item.user_agent.slice(0, 15) }}...</td>
              <td>{{ item.action.split('_').slice(1).join('_') }}</td>
              <td>{{ item.login }}</td>
              <td>{{ item.signatures && item.signatures.length ? item.signatures[0] : '' }} {{ item.signatures.length > 1 ?
                  '+1' : ''
              }}</td>
              <td>{{ item.location ? item.location.name + ', ' + item.location.state : '' }}</td>
            </tr>
          </table>
        </div>


        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->

        <!-- IF NO DATA DISPLAYED -->
        <div v-if="!$store.state.loadingAuditData && (getDrugEntries.logs && !getDrugEntries.logs.length)">
          <h4>No log entries to display.</h4>
        </div>
      </div>
      <div v-if="$store.state.reportError">
        <h3 style="color: #E04F39">{{ $store.state.reportError }}</h3>
      </div>
    </div>

    <div class="pagination-links">
      <p class="pagination-arrow" v-show="getDrugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr;
      </p>
      <p>Page {{ getDrugEntries.current_page }} of {{ getDrugEntries.page_count }}</p>
      <p class="pagination-arrow" v-show="getDrugEntries.current_page < getDrugEntries.page_count"
        @click="displayPage(getDrugEntries.current_page + 1)"> &rarr; </p>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
// import QuantityInStock from '@/components/graphs/QuantityInStock.vue';
// import QuantityUsed from '@/components/graphs/QuantityUsed.vue';

export default {
  data() {
    return {
      drugNames: [],
      registerData: [],
      date: [],
      filters: {
        '200': true,
        '400': true,
        '500': true,
        '404': true,
      },
      clearForm: {
        sort_direction: 'desc',
        col: '',
        page: 1,
        filters: {
          controller: [],
          action: '',
          status_code: [],
          login: '',
          signature: '',
          location: '',
          date: {
            start: '',
            end: '',
          },
          audits: {
            drug: '',
            person: '',
            location: '',
            role: '',
          }
        }
      },
      form: {
        sort_direction: 'desc',
        col: '',
        page: 1,
        filters: {
          controller: [],
          action: '',
          status_code: [],
          login: '',
          signature: '',
          location: '',
          date: {
            start: '',
            end: '',
          },
          audits: {
            drug: '',
            person: '',
            location: '',
            role: '',
          },
          role_changes: false,
          responsible_changes: false,
        }
      },
      testData: []
    }
  },
  methods: {
    // SORT DATA BY COLUMN AND ORDER
    callGraphData(graphType) {
      if (graphType == 'Quantity In Stock') {
        this.lineOrBar = 'area';
        this.graphData = [{
          name: 'Quantity In Stock',
          data: [{
            x: "2018-09-19T00:00:00.000Z",
            y: 54
          }, {
            x: "2018-09-22T01:30:00.000Z",
            y: 66
          },
          {
            x: "2018-09-24T02:30:00.000Z",
            y: 213
          },
          {
            x: "2018-09-30T03:30:00.000Z",
            y: 99
          }],
        }]
      }
      if (graphType == 'Quantity Used') {
        this.lineOrBar = 'bar';
        this.graphData = [{
          name: 'Quantity Used',
          data: [{
            x: "2018-09-19T00:00:00.000Z",
            y: 200
          }, {
            x: "2018-09-22T01:30:00.000Z",
            y: 500
          },
          {
            x: "2018-09-24T02:30:00.000Z",
            y: 300
          },
          {
            x: "2018-09-30T03:30:00.000Z",
            y: 30
          }],
        }]
      }
    },
    selectAGraph() {
      this.graphType = document.getElementById('select-graph').value;
      console.log('selected graph type', this.graphType);
      this.callGraphData(this.graphType);
    },
    autoAll() {
      this.form.filters.status_code = [];
      for (const [key, value] of Object.entries(this.filters)) {
        if (value == true) {
          this.form.filters.status_code.push(key)
        }
      }
      console.log('this is what is inside the form tags now', this.form.filters.status_code);
    },
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getAuditReportEntries', this.form)
    },
    selectDrugName(name) {
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    // RoleChangesOnly = false 
    loadReport() {
      console.log('this is the date', this.date);
      if (this.form.filters.status_code.length) {
        let codes = [];
         this.form.filters.status_code.forEach(code => {
          code = parseInt(code);
          console.log('THIS IS THE STATUS CODE', code);
          codes.push(code);
        });
        this.form.filters.status_code = codes;
        console.log('THIS IS TOTAL CODES', this.form.filters.status_code);
      }

      // only show audit reports where users change roles
      // if(RoleChangesOnly){
      //   this.form.filters.role_changes = true;
      // }

      if (this.date.length) {
        if (typeof this.date == "string") {
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        this.$store.dispatch('getAuditReportEntries', this.form);
      } else {
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getAuditReportEntries', this.form);
      }
      console.log('this is the report', this.form);
    },
    downloadReport() {
      let csv;

      axios.post('/export-logs', this.form, this.$store.state.header).then((res) => {
          csv = res.data;
      }).then(() => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = `audit_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
          anchor.click();
      })
    },
    aliasSearch(alias) {
      this.form.filters.drug = alias;
      this.$store.dispatch('getAuditReportEntries', this.form);
    },
    displayPage(page) {
      this.form.page = page;
      this.$store.dispatch('getAuditReportEntries', this.form);
    },
    showContent(item, index) {
      console.log('this the selected log', item)
      // let refid=`expand-${index}`;

      if (item.display && (item.display === true)) {
        document.getElementById(`expand-${index}`).style.display = 'none';
        item.display = !item.display;
      } else {
        item.display = true;
        console.log('expanding range for this object');
        document.getElementById(`expand-${index}`).style.display = 'table-row';
        axios.post(`/get-log/${item.uuid}`, {}, this.$store.state.header).then(res => {
          console.log('this is the state function to get a single drug register item', res.data);
          item.expandedInfo = {
            action: res.data.action,
            recorded: res.data.recorded,
            witness: res.data.witness,
            audits: res.data.audits,
            user_agent: res.data.user_agent,
            uuid: res.data.uuid,
            message: res.data.message ? res.data.message : '',
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })


      }
    },
    clearFilteredDrugRegister() {
      this.form = {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters: {
          tags: ['s8'],
          drug: '',
          in_stock: false,
          date: {
            start: '',
            end: ''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getAuditReportEntries', this.form);
    },
    printElem(elem) {
      console.log('this is the eleme', elem);
      window.print()
      // var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
      // mywindow.document.write('</head><body >');
      // mywindow.document.write('<h1>' + document.title  + '</h1>');
      // mywindow.document.write(document.getElementById(elem).innerHTML);
      // mywindow.document.write('</body></html>');

      // mywindow.document.close(); // necessary for IE >= 10
      // mywindow.focus(); // necessary for IE >= 10*/

      // mywindow.print();
      // mywindow.close();

      // return true;
    }

  },
  mounted() {
    this.loadReport();
  },
  computed: {
    getDrugEntries() {
      // getStockReport
      console.log('this is the getAudit report stuff', this.$store.getters.getAuditReport);
      return this.$store.getters.getAuditReport
    },
    getDrugNames() {
      return this.$store.getters.getDrugNames
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
// @media print {
//     .data-table {
//         background-color: white;
//         height: 100%;
//         width: 100%;
//         position: fixed;
//         top: 0;
//         left: 0;
//         margin: 0;
//         // padding: 15px;
//         font-size: 14px;
//         // line-height: 18px;
//     }
//     .expand-column{
//         display: none;
//     }


//     .web-camera-container{
//         display: none;
//     }
// }
</style>