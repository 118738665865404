<template>
  <div class="login-view">
    <div class="login-form">
      <div class="login-logo" style="width: fit-content">
        <img src="@/assets/logos/full_logo.png" alt="" style="height:70px;">
      </div>
      <div class="login-form-details" style="margin-top: 7em">
        <h2>Password Reset</h2>

        <div class="login-form-fields slide-fade">
          <!-- <input placeholder="Location" v-model="loginForm.location" style="position: relative; width: 26em; background-color: #E5F1FE;" :style="[locationError ? {marginBottom: ''}:{marginBottom: '1em'}]" /> -->
          <!-- <p v-if="locationError" class="form-error">{{locationError}}</p> -->
          <div class="form-field wide-text-field" style="width: 80%;">
            <label for="first_name">Password <span style="color: red;">*</span></label>
            <input placeholder="Password" v-model="passwordPayload.password" type="password"
              style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
          </div>
          <div class="form-field wide-text-field" style="width: 80%;">
            <label for="first_name">Confirm Password <span style="color: red;">*</span></label>
            <input placeholder="Password" v-model="passwordPayload.password_confirm" type="password"
              style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
          </div>


          <!-- <input placeholder="Username/email" v-model="loginForm.name" style="position: relative; width: 26em; background-color: #E5F1FE; margin-bottom: 1em" /> -->

          <!-- <input placeholder="Password" v-model="loginForm.password" v-on:keyup.enter="submitLogin()" type="text" style="position: relative; width: 26em; background-color: #E5F1FE; text-security:disc; -webkit-text-security:disc;" /> -->
          <div style="margin-top: 1em;">
            <p @click="submitPasswordReset()" class="blue-button"
              style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; ">
              Submit</p>
          </div>
          <p v-if="passwordError" style="color: red">Warning: {{ passwordError }}</p>
        </div>



      </div>
    </div>
    <div class="login-context">

      <div class="login-logo" style="display: grid; justify-content: flex-end">
        <img src="@/assets/logos/short_logo.png" alt="" style="height:70px;">
      </div>
      <h1 style="margin-top: 3em; ">The AI-Driven Drug Management Platform</h1>

      <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
        specialised treatment plans for patients.</p>
    </div>
    <SuccessModal :loading="loading" @close="close"
            v-if="displaySuccessModal" :statusMessage="'Submitting Registration'"
            :message="displayMessage" :second_spec_line="displayMessage2" />
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue'

export default {
  components: {
    SuccessModal
  },
  data() {
    return {
      displaySuccessModal: false,
      resetStatus : false,
      loading: false,
      displayMessage: '',
      displayMessage2: '',
      passwordError: '',
      passwordPayload: {
        password:'',
        password_confirm:'',
        reset_code:''
      }
    }
  },
  methods: {
    close(value){
      this.displayMessage = ""
      this.displayMessage2 = ""
      if(this.resetStatus){
        this.$router.push('/login');
      } else {
        this.displaySuccessModal = false;
      }
    },
    submitPasswordReset() {
      this.loading = true;
      this.displaySuccessModal = true;
      this.displayMessage = 'Your password is being reset...';
      
      axios.post(`/reset-password/email`, this.passwordPayload).then(res => {
        console.log('this is the password completion', res.data);
        this.resetStatus = true
        this.displayMessage = 'Password successfully reset!';
        this.loading = false;
      }).catch(err => {
        console.log('this is the error with regstration', err.response.data)
        this.loading = false;
        this.displayMessage = 'Sorry, there has been an error with your password reset. These error(s) occoured:'
        this.displayMessage2 = this.errorFormat(err.response.data.errors);
        
      })
    },
    errorFormat(errObject) {
      const errValues = Object.values(errObject)
      const flattenedErrors = errValues.flat()
      const joinedErrors = flattenedErrors.join(' ');
      return joinedErrors
    }
  },
  mounted() {
    this.passwordPayload.reset_code = this.$route.params.id;
    console.log('this is the password payload', this.passwordPayload);
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login-context {
  background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
  color: white;
  padding-top: 8.125em;
  padding-left: 17em;
  padding-right: 8em;
  text-align: left;

  .login-logo {
    justify-content: flex-end;
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  justify-self: flex-start;
}

.login-form {
  padding: 8.125em 4.375em;
  border-bottom-right-radius: 8.25em;
  background-color: white;
  margin-right: -10em;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.login-form-details {

  width: fit-content;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10em;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;

  h2 {
    text-align: left;
  }
}

.login-form-fields {
  display: grid;
  justify-content: center;
}

.form-error {
  margin: 0;
  margin-bottom: 1em;
  color: $error;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
