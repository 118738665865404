<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div
                class="modal-container"
                style="width: 60%; max-width: 1200px; height: 80%; display: flex; flex-direction: column;">
                <div class="modal-header">
                    <h3 class="form-header">MIMS EULA</h3>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <vue-pdf-embed
                                source="/MIMS_EULA.pdf"
                                :width="800"
                                style="display: inline-block; padding-top: 10px;"
                            />
                        </form>
                    </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer" style="display: flex;">
                        <p
                            class="eula-regular-button complete-button"
                            style="margin-right: 10px;"
                            @click="confirm">
                            Confirm
                        </p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    props: [
        'temp_access_token'
    ],
    components: {
        VuePdfEmbed
    },
    data() {
        return {
        }
    },
    methods: {
        cancelAction() {
            this.$emit('close', true);
        },
        // Confirm the MIMS EULA
        confirm() {
            axios.post('/mims/confirm_eula', { confirmation: true, temp_access_token: this.temp_access_token })
            .then(res => {
                console.log('this is the res:', res.data);
            })
            .catch(err => {
                console.log('this is the error:', err.response, err.response.data)
            })
            this.closeModal();
        },
        closeModal() {
            this.$emit('close', true);
        },
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";



.eula-regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
    height: 45px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.action-modal {
    overflow: auto;
}

</style>