<template>
  <div class="drug-register-view view">
    <!-- <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="displayDeliveryModal = true" class="actions-menu-item">New Delivery</button>
    </div> -->
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
      <h3 class="panel-header">Resupply</h3>
      <div class="drug-register-table table-container" style="width: 95vw">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center; height: 50px; justify-content: space-between;">
          <div style="width: 100%;color: rgb(224, 79, 57);border-color: rgb(224, 79, 57);border-style: solid;border-width: 2px;border-radius: 8px;padding: 8px;/* margin-top: 15px; */margin-bottom: 15px;display: flex;"><div style="display: flex; align-items: flex-start; text-align: left;">
            <i class="pi pi-info-circle" style="margin: auto;color: inherit; cursor: inherit;">
            </i>
            <span style="font-size: 16.5px; margin-left: 8px;"> Only live Prescriptions can be Resupplied. Prescriptions with an <span class="mismatch-status-color">Expired</span> status have been ceased, chart reviewed or edited and can not be resupplied.
            </span>
          </div>
        </div>
          <div style="display: flex; align-items: center">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center;">

            <!-- <button class="button submit-button" @click="loadEntries()">Search</button>
            <button class="button clear-button" @click="clearEntriesFilters()">Clear</button> -->
          </div>

          <!-- <div style="display: flex; align-items: center">
              <div class="select-filters-container">

              <p @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter' : 'outline-button']" :style="tags.s8 ? { paddingTop: '8px'}:{paddingTop: '6px'}" style="height: 33px;">S8 Only</p>

            </div>
          </div> -->
          
          <!-- SELECT FILTER SECTION -->
          
          <Dropdown v-model="form.status" :options="statusSelect" @change="updateStatusList" optionLabel="status" optionValue="value" placeholder="Status" />
          

          </div>
          <div style="display: flex; gap: 10px; align-items: center" v-if="this.$store.state.user?.role?.permissions?.includes('can_submit_resupply') && form.status.includes('pending')">
            <button :class="ordersToSign > 0 ? 'black-button' : 'blue-button'" @click="toggleSelectAll(ordersToSign > 0 ? false : true)">{{ ordersToSign > 0 ? 'Unselect All On Current Page' : 'Select All On Current Page'}}</button>
            <button :disabled="ordersToSign == 0" class="blue-button" :class="ordersToSign > 0 ? 'blue-button' : 'grey-button'" @click="submitPendingItems()">Request Selected Items</button>
            <button :disabled="ordersToSign == 0" class="blue-button" :class="ordersToSign > 0 ? 'red-button' : 'grey-button'" @click="removePendingItems()">Remove Selected Items</button>
            
          </div>
          
        </div>
        <div v-if="resupplyData" style="display: block; align-items: start; overflow-y: scroll;margin-top:15px">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else class="data-table" cellspacing="0">
          <tr class="table-headers" >
            <!-- <th style="width: 50px"></th> -->
            <th>
              <h3 @click="sortBy('location', 'desc')">Resident</h3>
              <!-- <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('client', 'desc')">Medication</h3>
              <div v-if="form.col == 'client'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  client-sort" v-if="form.col =='client' && form.sort_direction =='asc'" @click="sortBy('client', 'desc')"></i>
              <i class="pi pi-sort client-sort" v-else @click="sortBy('client', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('completed_by', 'desc')">Rx Expiry</h3>
            </th>
            <th>
              <h3 @click="sortBy('delivery_date', 'desc')">Supply Requested</h3>
              <!-- <div v-if="form.col == 'delivery_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  delivery_date-sort" v-if="form.col =='delivery_date' && form.sort_direction =='asc'" @click="sortBy('delivery_date', 'desc')"></i>
              <i class="pi pi-sort delivery_date-sort" v-else @click="sortBy('delivery_date', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('completed_date', 'desc')">Status</h3>
              <div v-if="form.col == 'completed_date'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  date-sort" v-if="form.col =='completed_date' && form.sort_direction =='asc'" @click="sortBy('completed_date', 'desc')"></i>
                <i class="pi pi-sort date-sort" v-else @click="sortBy('completed_date', 'asc')"></i>
              </div>
            </th>
            <th v-if="form.status != 'requested'" style="width: 60px;">
              <h3 style=" padding-right: 20px;" v-if="this.$store.state.user?.role?.permissions?.includes('can_submit_resupply')">Select</h3>
            </th>
            

          </tr>
          <tbody  class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <!-- @click="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']" -->
            <tr :class="item?.prescription?.deleted_at && form.status !='requested' ? 'mismatch-status-color': ''">
                <!-- <td>
                    <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                    <i v-else class="pi pi-angle-right"></i>
                </td> -->
                <td>{{item.patient.personal_information.first_name}} {{item.patient.personal_information.last_name}}</td>
                <td>{{ item.prescription ? item.prescription.alias.name.slice(0,1).toUpperCase()+item.prescription?.alias.name.slice(1) : 'N/A'}}</td>
                <td>{{ item.prescription?.stop_date ?? 'N/A'}}</td>
                <td>{{ item.prescription?.updated_at ?? 'N/A'}}</td>
                <td><p :class="[item.status == 'requested' && !item.prescription.deleted_at ? 'correct-status-color' : (item.status == 'reversed' || item.status == 'Reversed' || item.prescription.deleted_at ? 'mismatch-status-color' : 'warning-status-color')]">{{ item.prescription.deleted_at ? 'Expired' : item.status ? item.status.slice(0,1).toUpperCase()+item.status.slice(1) :'' }}</p></td>
                <td>
                  <div @click.stop="() => { selectItem(item, index) }" style="cursor: pointer" v-if="item.status == 'pending' && !item?.prescription?.deleted_at && this.$store.state.user?.role?.permissions?.includes('can_submit_resupply')">
                    <div v-if="item.to_sign && (item.to_sign == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>
                  </div>
                  <div v-if="item?.prescription?.deleted_at && item.status == 'pending'">
                    <div>
                      <i class="pi pi-trash" style="cursor: pointer" @click="removeExpiredItem(item)" >
                      </i>
                    </div>
                  </div>
                </td>
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; width: 92.5vw">
                  <div v-if="!item.expandedInfo" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                  <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                   <div class="transfer-notes-container" style="width: 47%; min-width: 30em;">
                     <div>
                       <h4 class="field-title">Reference Number</h4>
                       <div class="field-notes" style="width: 40%; height: fit-content;">
                           <p >{{item.reference_number}}</p>
                       </div>
                     </div>
                     <div>
                       <h4 class="field-title">Notes</h4>
                       <div class="field-notes" style="width: 95%; height: 245px;">
                           <p >{{item.expandedInfo.notes}}</p>
                       </div>
                     </div>
                       
                   </div>
                   <div class="transfer-drugs-container" style="max-width: 50%;">
                       <h4 class="field-title">Medications</h4>
                       <div class="drug-field-tables" style="width: 100%;
                                                                border-radius: 4px;
                                                                height: 300px;">
                        <table style="padding: 0px 5px; border-bottom: 1px solid #E7E9E8">
                            <tr>
                                <th>Name</th>
                                <!-- <th style="width: 80px;">Form</th> -->
                                <th style="width: 100px;">Count</th>
                                <!-- <th>Resident</th>
                                <th>Prescriber</th> -->
                            </tr>
                        </table>
                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                            <table style="padding-top: 0;">
                                <tr v-for="(drug,key) in item.expandedInfo.drugs" :key="key" style="height: 25px">
                                    <td>{{drug.alias.name}}</td>
                                    <!-- <td style="width: 80px;">{{drug.alias.form}}</td> -->
                                    <td style="width: 100px;">{{drug.quantity}}</td>
                                    <!-- <td>Patrick Souzasolopous</td>
                                    <td>Richard Kit</td> -->
                                </tr>
                            </table>
                        </div>
                       </div>
                   </div>
                  </div>
               </div>
                <!-- TRANSFER ACTIONS -->
                <div class="transfer-actions-container" style="width: 92.5vw; align-items: center;">
                    <button @click="signSingleOrder(item.uuid)" class="green-button" v-show="item.status == 'pending'">Sign Invoice</button>
                    <!-- <button @click="transferAction('Reject', item.uuid, item.expandedInfo.drugs)" class="transfer-action-button error-button" v-show="item.status == 'Pending'">Reject Delivery</button> -->
                    <!-- <button @click="transferAction('Reverse', item)" class="transfer-action-button error-button" v-show="item.status == 'completed'">Reverse Delivery</button> -->
                    <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                </div>
            </tr>    
          </tbody>
          
        </table>
        <p v-if="!getDrugEntries.length && !loadingEntries" style="font-size: 1em; font-weight: bold;">No {{ form.status[0] }} items.</p>
        <i v-if="loadingEntries" class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div v-else>
          <h3 v-if="$store.state.deliveryError">{{$store.state.deliveryError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="resupplyData && resupplyData.current_page">
            <p class="pagination-arrow" v-show="resupplyData.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{resupplyData.current_page}} of {{resupplyData.page_count}}</p>
            <p class="pagination-arrow" v-show="resupplyData.current_page < resupplyData.page_count" @click="displayPage( resupplyData.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       <!-- <ActionModal :toAction="toAction" /> -->
       <SignatureModal v-if="displayActionModal" @close="close" @isSuccessful="isSuccessful" :payload=payload :endpoint=endpoint dual="true" :notes=requireNotes />
       <SuccessModal v-if="displaySuccessModal" @close="close" :message="message" />
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import axios from 'axios';

export default {
  components: { 
      SignatureModal,
      SuccessModal
  },
  data(){
    return{
        requireNotes: false,
        endpoint: '',
        loadingError:'',
        displayNewTransferModal: false,
        displayActionModal: false,
        displaySuccessModal: false,
        message:'',
        toAction:{
            uuid:'',
            action: 'Approve',
            drugs:[]
        },
        statusSelect: [
                {status:'Pending', value: ['pending']},
                {status:'Requested', value: ['requested']},
        ],
        tags:{
            s8:false,
            s4: false,
            antimicrobial: false,
        },
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true
      },
      toCache:[],
      date:[],
      clearForm:{
            page:1,
            status: ['pending']
        },
      form:{
            page:1,
            status: ['pending']
        },
      resupplyData:null,
      loadingEntries: false,
      
    }
  },
  methods:{
    toggleSelectAll(select){
      this.getDrugEntries.forEach(entry=>{
        if(entry.status == 'pending' && !entry.prescription.deleted_at){
          if(select){
          entry.to_sign = true;
        } else{
          entry.to_sign = false;
        }
        }
        
      })
    },
    submitPendingItems(){
      this.payload = {
        items:[]
      }
      this.endpoint = '/resupply/submit'
      this.getDrugEntries.forEach(order=>{
        if(order.to_sign && !order.prescription.deleted_at){
          this.payload.items.push(order.uuid);
        }
      })

      this.displaySuccessModal = true;
      this.message = `Requesting item${this.payload.items.length > 1 ? 's':''}...`
      axios.post(this.endpoint, this.payload, this.$store.state.header).then(res=>{
        console.log('this is the confirmation of submission', res.data);
        this.loadEntries();
        this.displaySuccessModal = false;
      }).catch(err => {
        console.log(err.response);
        this.message = `Error submitting resupply request. ${err.response?.data?.message}`;
      })
    },
    removePendingItems(){
      this.payload={
        items:[]
      }
      this.displaySuccessModal = true;
      
      this.getDrugEntries.forEach(order=>{
        if(order.to_sign){
          this.payload.items.push(order.uuid);
        }
      })
      this.message = `Removing item${this.payload.items.length > 1 ? 's':''}...`
      this.endpoint='/resupply/remove'
      axios.post(this.endpoint, this.payload, this.$store.state.header).then(res=>{
        console.log('this is the confirmation of removal', res.data);
        this.loadEntries();
        this.displaySuccessModal = false;
      })
    },
    removeExpiredItem(item){
      this.payload={
        items:[item.uuid]
      }
      this.displaySuccessModal = true;
      
      this.message = `Removing item...`
      this.endpoint='/resupply/remove'
      axios.post(this.endpoint, this.payload, this.$store.state.header).then(res=>{
        console.log('this is the confirmation of removal', res.data);
        this.loadEntries();
        this.displaySuccessModal = false;
      })
    },
    close(value){
        console.log('delivery modal should be closing here, outside if');
        if(value){
            console.log('delivery modal should be closing here');
            this.displayActionModal = false;
            this.displaySuccessModal = false;            
        }
    },
    selectItem(item, index) {
      console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].to_sign) {
        this.getDrugEntries[index].to_sign = false;
      } else {
        this.getDrugEntries[index].to_sign = true;
      }
    },
    isSuccessful(value){
      console.log('this has been', value);
      this.$store.dispatch('getOrders', this.form);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.loadEntries();
    },
    showContent(delivery, index){
        console.log('this the selected log', delivery)
        // let refid=`expand-${index}`;

        if(delivery.display && (delivery.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            delivery.display = !delivery.display;
        } else{
            delivery.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
        }
    },
    loadEntries(){
      console.log('this is the date', this.date);
      this.loadingEntries = true;
      this.resupplyData = null;
      axios.post('/resupply/retrieve-list', this.form, this.$store.state.header).then(res=>{
        console.log('this is the resupply list', res.data);

        this.resupplyData = res.data;
        this.resupplyData.data.forEach(item=>{
          item.status = this.form.status[0]
        })
        this.loadingEntries = false;
        this.$store.dispatch('getRefreshToken');
      })
    },
    displayPage(page){
      this.form.page = page;
      this.loadEntries();
    },
    updateStatusList(){
        this.loadEntries();
    },
  },
   mounted(){
    this.loadEntries();
    this.toCache = [];
  },
  computed:{
    getDrugEntries (){
      return this.resupplyData?.data
    },
    ordersToSign(){
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.to_sign) {
            num += 1;
          }
        })

      }
      return num;
    }
  }
}
</script>
