<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; ">Progress Notes</h4>
            <p style="text-align: left; font-size: 1em; ">Select the CMS category that should be linked to the respective progress note recorded in Strong Care and submitted to the CMS platform.</p>
            <!-- <p style="text-align: left; font-size: 1em; ">Unlinking a prescriber removes their access to the selected facility's resident data.</p> -->
            <div v-if="!companyFacilities">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display: flex; align-items: flex-end; margin-bottom: 1em;">
                <div style="width: fit-content; margin-right: 10px;">
                    <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Select Facility: </p>
                    <Dropdown v-model="selectedFacility" :options="companyFacilities" @change="getTags()" optionLabel="name" style="min-width: 20em; height: fit-content" />
                </div>
            </div>
            <div v-if="loadingProgressNoteCategories">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <div v-if="selectedFacility">
                <table v-if="!loadingProgressNoteCategories">
                    <tr>
                        <th>Strong Care Tags</th>
                        <th>CMS Category</th>
                    </tr>
                    <tr v-for="(item, index) in tagOptions" :key="index">
                        <td>{{item.name.toUpperCase()}}</td>
                        <td><Dropdown v-model="item.linkedCategory" :options="tagCategories" optionLabel="integration_label" style="min-width: 20em; height: fit-content" /></td>
                    </tr>
                </table>
            </div>
            <div v-if="selectedFacility">
                <p style="width: fit-content" class="green-button" @click="saveTags()">Save</p>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"  />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['facilities'],
    components: {
        SuccessModal,
    },
    data() {
        return {
            selectedIntegration:null,
            displaySuccessModal: false,
            loadingIntegrationFacilities: false,
            loadingProgressNoteCategories: false,
            selectedCredential:null,
            companyFacilities: null,
            linkedFacility:[
                // {
                //     facility_change: null,
                //     facility_name: 'Sunshine Facility',
                //     external_id: '9293292-222221',
                //     integration_label: 'Sun. Fac.'
                // }
            ],
            newCredentialId: '',
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            loadingConfig: false,
            loadingFacilities: false,
            tagOptions:null,
            tagCategories:[
                {
                    external_id: "12", // 12
                    integration_label: "Nurse Initiated", // hope general progre. notes
                    tag: "prn" // general or null
                },
                {
                    external_id: "11", // 12
                    integration_label: "Hope PRN", // hope general progre. notes
                    tag: "" // general or null
                },
                {
                    external_id: "32", // 12
                    integration_label: "Effective Note", // hope general progre. notes
                    tag: "" // general or null
                },
                {
                    external_id: "42", // 12
                    integration_label: "DOC Note", // hope general progre. notes
                    tag: "" // general or null
                },
            ]

        }
    },
    methods: {
        successfullySubmitted(value){
            if(value){
                this.displayDeleteCredentialModal  = false;
                this.selectedCredential = null;
                this.getCompanyCredentials();
               
            }
        },
        close(value){
            if(value){
                this.displaySuccessModal = false;
                this.displayResidentSyncModal = false;
            }
        },
        getCompanyIntegratedFacilities(){
            axios.post('/get-company-integrated-facilities', {}, this.$store.state.header).then(res=>{
                this.companyFacilities = res.data.response.data;
                console.log('these are the integration facilities', res.data);
            })
        },
        getTags(){
            let payload = {
                integration_id: this.selectedFacility.integration_id,
                facility_id: this.selectedFacility.facility_id,
                integration_facility_id: this.selectedFacility.integration_facility_id
            }

            this.loadingProgressNoteCategories = true;
            
            axios.post(`/retrieve-progress-note-categories`, payload, this.$store.state.header).then(res=>{
                console.log('these are the progress note categories', res.response);
                this.tagCategories = res.data.response;
                axios.post('/get-progress-note-tags', {type: 'integration_progress_notes'}, this.$store.state.header).then(res1=>{
                    this.loadingProgressNoteCategories = false;
                    this.tagOptions = res1.data.response;
                    this.tagCategories.forEach(category=>{
                        console.log('looping through each category');
                        if(category.tags && category.tags.length){
                            console.log('validated category tag length');
                            this.tagOptions.forEach(tag=>{
                                console.log('looping through each tag');
                                if (category.tags.find(e => e.name === tag.name)) {
                                    tag.linkedCategory = category;
                                    console.log('found matching names', [category, tag]);
                                }
                            })
                        }
                    })
                }).catch(err => {
                    this.displaySuccessModal = true;
                    this.loadingProgressNoteCategories = false;
                    this.successMessage = err.response.data?.error.error ?? err.response.data;
                })
            }).catch(err => {
                this.displaySuccessModal = true;
                this.loadingProgressNoteCategories = false;
                this.successMessage = err.response.data?.error.error ?? err.response.data;
            })
        },
        saveTags(){


            let unlinkPayload = {
                integration_id: this.selectedFacility.integration_id,
                facility_id: this.selectedFacility.facility_id,
                categories:[
                ]
            }

            // grabbing all categories that have tags attached to them,
            // we'll make sure those are cleared out first before storing the new ones
            this.tagCategories.forEach(category=>{
                if(category.tags.length > 0){
                    unlinkPayload.categories.push({
                        external_id: category.uuid
                    })
                }
            })
            
            let payload = {
                integration_id: this.selectedFacility.integration_id,
                facility_id: this.selectedFacility.facility_id,
                categories:[
                ]
            } 

            this.tagOptions.forEach(option=>{
                if(option.linkedCategory && option.linkedCategory.uuid){
                    payload.categories.push({
                        external_id: option.linkedCategory?.uuid,
                        tag_name: option.name
                    })
                }
            })

            if(unlinkPayload?.categories?.length > 0){
                axios.post('/unlink-progress-note-categories', unlinkPayload, this.$store.state.header).then(res => {
                    console.log('unlinked all tags', res.response);
                    axios.post('/save-progress-note-categories', payload, this.$store.state.header).then(res1 => {
                        console.log('saved categories', res1);
                        this.successMessage = "Progress note categories successfully saved!"
                        this.displaySuccessModal = true;
                    }).catch(err => {
                        this.displaySuccessModal = true;
                        this.successMessage = err.response.data?.message ?? err.response.data;
                    })
                }).catch(err => {
                    this.displaySuccessModal = true;
                    this.successMessage = err.response.data?.message ?? err.response.data;
                })
            }else{
                axios.post('/save-progress-note-categories', payload, this.$store.state.header).then(res1 => {
                    console.log('saved categories', res1);
                    this.successMessage = "Progress note categories successfully saved!"
                    this.displaySuccessModal = true;
                }).catch(err => {
                    this.displaySuccessModal = true;
                    this.successMessage = err.response.data?.message ?? err.response.data;
                })
            }
            
            
        }
    },
    mounted() {
        this.getCompanyIntegratedFacilities();
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
