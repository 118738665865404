export default {
    updateTooltip(el, { value, modifiers }) {
        if (typeof value === "string") {
            // we can pass either a string
            el.setAttribute("data-v-tooltip", value);

            switch (Object.keys(modifiers)[0]) {
                case "top":
                    el.style.setProperty(
                        "--v-tooltip-left",
                        "50%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-top",
                        "0%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-translate",
                        "translate(-50%, -110%)"
                    );
                    if (value.displayArrow || modifiers.arrow) {
                        el.style.setProperty(
                            "--v-tooltip-arrow-border-color",
                            "var(--v-tooltip-background-color) transparent transparent transparent"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-top",
                            "calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) + 8px)"
                        );
                    }
                    break;
                case "bottom":
                    el.style.setProperty(
                        "--v-tooltip-left",
                        "50%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-top",
                        "100%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-translate",
                        "translate(-50%, 10%)"
                    );
                    if (value.displayArrow || modifiers.arrow) {
                        el.style.setProperty(
                            "--v-tooltip-arrow-border-color",
                            "transparent transparent var(--v-tooltip-background-color) transparent"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-top",
                            "calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px)"
                        );
                    }
                    break;
                case "left":
                    el.style.setProperty(
                        "--v-tooltip-left",
                        "0%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-top",
                        "50%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-translate",
                        "translate(-110%, -50%)"
                    );
                    if (value.displayArrow || modifiers.arrow) {
                        el.style.setProperty(
                            "--v-tooltip-arrow-border-color",
                            "transparent transparent transparent var(--v-tooltip-background-color)"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-top",
                            "calc(var(--v-tooltip-top)"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-left",
                            "calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) + 1.5px)"
                        );
                    }
                    break;
                case "right":
                    el.style.setProperty(
                        "--v-tooltip-left",
                        "100%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-top",
                        "50%"
                    );
                    el.style.setProperty(
                        "--v-tooltip-translate",
                        "translate(10%, -50%)"
                    );
                    if (value.displayArrow || modifiers.arrow) {
                        el.style.setProperty(
                            "--v-tooltip-arrow-border-color",
                            "transparent var(--v-tooltip-background-color) transparent  transparent"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-top",
                            "calc(var(--v-tooltip-top)"
                        );
                        el.style.setProperty(
                            "--v-tooltip-arrow-left",
                            "calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) - 2px)"
                        );
                    }
                    break;
                default:
                    // Default to right
                    // el.style.setProperty(
                    //     "--v-tooltip-left",
                    //     "100%"
                    // );
                    // el.style.setProperty(
                    //     "--v-tooltip-top",
                    //     "50%"
                    // );
                    // el.style.setProperty(
                    //     "--v-tooltip-translate",
                    //     "translate(10%, -50%)"
                    // );
                    // if (value.displayArrow || modifiers.arrow) {
                    //     el.style.setProperty(
                    //         "--v-tooltip-arrow-border-color",
                    //         "transparent var(--v-tooltip-background-color) transparent  transparent"
                    //     );
                    //     el.style.setProperty(
                    //         "--v-tooltip-arrow-top",
                    //         "calc(var(--v-tooltip-top)"
                    //     );
                    //     el.style.setProperty(
                    //         "--v-tooltip-arrow-left",
                    //         "calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) - 2px)"
                    //     );
                    // }
                }
            }
    },
    // hooks
    mounted(el, { value, dir, modifiers }) {
        // v-tooltips with global prop won't show the tooltip
        // also object notation without text prop won't show neither
        if (typeof value === "object" && !value.global && value.text) {
            el.classList.add("data-v-tooltip");
        } else if (typeof value === "string") {
            el.classList.add("data-v-tooltip");
        }

        // to use functions in Vue's directives which are inside this object, we can't use this, we have to use dir, which is the directive object
        dir.updateTooltip(el, { value, modifiers });
    },
    updated(el, { value, dir, modifiers }) {
        dir.updateTooltip(el, { value, modifiers });
    },
};
