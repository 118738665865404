<template>
  <div class="drug-register-table" style="height: 19em; grid-template-rows: 1fr; box-shadow: none;">

    <div v-if="!loadingCharts" style="display: flex; align-items: start; overflow-y: scroll">
      <div class="drug-register-table " style="padding-top: 10em;"
        v-if='!$store.state.user.role.permissions.includes("can_view_prescription")'>
        <h3
          style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
          You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
      </div>
      <table v-else class="data-table patient-chart-table" cellspacing="0">
        <tr class="table-headers">
          <th height=40px style="display: flex; align-items: center;">
            <h3 @click="sortBy('type', 'desc')" style=" font-size: 1em;">Drug, strength, form & dose</h3>
            <div v-if="col == 'type'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort type-sort" v-if="col == 'type' && sort_direction == 'asc'"
                @click="sortBy('type', 'desc')"></i>
              <i class="pi pi-sort type-sort" v-else @click="sortBy('type', 'asc')"></i>
            </div>

          </th>
          <th style="width: 5em">
            <!-- @click="sortBy('location', 'desc')" -->
            <!-- <h3 style="text-align: center; padding-left: 15px;">Draft</h3> -->
            <!-- <div v-if="col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="col =='location' && sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
          </th>
          <th style="width: 8em">
            <!-- @click="sortBy('created_by', 'desc')" -->
            <h3 style="font-size: 1em;">Prescriber</h3>
            <!-- <div v-if="col == 'created_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  request-by-sort" v-if="col =='created_by' && sort_direction =='asc'" @click="sortBy('created_by', 'desc')"></i>
              <i class="pi pi-sort request-by-sort" v-else @click="sortBy('created_by', 'asc')"></i>
              </div> -->
          </th>
          <th style="width: 8em; text-align: center;">
            <!-- @click="sortBy('completed_by', 'desc')" -->
            <h3 style="font-size: 1em;">Type</h3>
            <!-- <div v-if="col == 'completed_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_by-sort" v-if="col =='completed_by' && sort_direction =='asc'" @click="sortBy('completed_by', 'desc')"></i>
              <i class="pi pi-sort completed_by-sort" v-else @click="sortBy('completed_by', 'asc')"></i>
              </div> -->
          </th>


        </tr>
        <tbody class="table-data" v-for="(item, index) in getUpdatedCharts" :key="index">
          <tr @click="displayContent(item)">
            <!-- (${item.alias.generic_name}) -->
            <td style="border-bottom-left-radius: 8px; border-top-left-radius: 8px; padding-left: 10px;">{{ item.alias &&
                item.alias.name ? `${item.alias.name} x ${item.dose} ${item.dose_unit} -- ${item.frequency_shorthand}` : 'N/A'
            }}</td>
            <td style="width: 3em; font-weight: bold;">
              <p v-if="item.signatures && !item.signatures.length"
                style="border: 1px solid black; border-radius:8px; padding: 3px; text-align: center;">
                {{ item.signatures.length ? '' : 'Draft' }}</p>
            </td>
            <td style="width: 8em; word-break: break-word;" :style="!item?.doctor?.personal_information?.first_name ? {padding: '0 2em'}:{}">{{ item?.doctor?.personal_information?.first_name ? item.doctor.personal_information.first_name[0]+'.' : '--' }}
              {{ item?.doctor?.personal_information?.first_name ?  item.doctor.personal_information.last_name : ''}}</td>
            <td
              style="border-bottom-right-radius: 8px; border-top-right-radius: 8px; color: white; font-weight: bold; padding: 0px 5px;">
              <p style="padding:5px; border-radius: 8px; text-align: center; width: 8em"
                                    :style="{ backgroundColor: item.color }">{{ item.chart_type && item.chart_type != 'Once Only Meds (Stat)' ?  item.chart_type.split('_').join(' ').toUpperCase() :  item.chart_type == 'Once Only Meds (Stat)' ? 'STAT': '' }}</p>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!getUpdatedCharts">
          <tr>
            <td style="padding-left: 10px">Loading prescriptions...</td>
          </tr>
        </tbody>
        <tbody v-if="getUpdatedCharts && !getUpdatedCharts.length">
          <tr>
            <td style="padding-left: 10px">No current prescriptions.</td>
          </tr>
        </tbody>

      </table>
    </div>

    <div v-else>
      <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
    </div>
    <!-- <div class="pagination-links">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
          </div> -->
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
// import axios from 'axios';

export default {
  props: ['charts', 'loadingCharts'],
  components: {
  },
  data() {
    return {
      col: 'type',
      sort_direction: 'desc',
      displayNewTransferModal: false,
      displayActionModal: false,
      toAction: {
        uuid: '',
        action: ''
      },
      transferType: '',
      drugNames: [],
      registerData: [],
      toCache: [],
      date: [],
    }
  },
  methods: {
    close(value) {
      if (value) {
        this.displayActionModal = false;
        this.displayNewTransferModal = false;
      }
    },
    isSuccessful(value) {
      if (value) {
        this.$store.dispatch('getTransfers', this.form);
      }
    },
    toggleNewTransferModal(type) {
      this.transferType = type;
      this.displayNewTransferModal = true;
    },
    transferAction(selectedAction, item) {
      console.log('this is the transfer being actioned', item);
      this.toAction = {
        action: selectedAction,
        uuid: item.uuid,
        'note': item.expandedInfo.notes
      }
      this.displayActionModal = true;
      console.log('this is the action id', this.toAction);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir) {
      this.col = col;
      this.sort_direction = dir;
      this.$store.dispatch('getTransfers', this.form);
    },
    displayContent(presChart) {
      console.log('this the selected chart', presChart);
      this.$emit("retrievePresChart", presChart)
    },
    loadTransfers() {
      console.log('this is the date', this.date);
    },
    displayPage(page) {
      this.form.page = page;
      this.$store.dispatch('getTransfers', this.form);
    },
    clearTransfersFilters() {
      this.form = {
        sort_direction: '',
        col: '',
        page: 1,
        filters: {
          // ,'pending reversal'
          status: ['pending', 'rejected', 'completed'],
          completed_by: '',
          location: '',
          type: [],
          date: {
            start: '',
            end: ''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getTransfers', this.form);
    },
  },
  mounted() {
    this.toCache = [];
    // console.log('these are the chart types', this.$store.getters.getChartTypes);
    // let chartTypes = this.$store.getters.getChartTypes;
    // this.charts.forEach(chartItem=>{
    //   chartTypes.forEach(chartType=>{
    //     if(chartItem.chart.chart_type == chartType.chart_name){
    //       chartItem.chart.color = chartType.color;
    //       chartItem.chart.name = chartType.name;
    //       console.log('this is the color now', chartItem.chart.color);
    //     }
    //   })
    // })
  },
  computed: {
    getDrugEntries() {
      return this.$store.getters.getDrugEntries
    },
    getUpdatedCharts() {
      return this.charts
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.patient-chart-table {

  td {
    // border: none !important;
    padding: 10px 10px;
    padding-left: 0px;
    background-color: #F3F4F4;
    margin-top: 5px;
    border-top: 5px solid white;
    border-bottom: none !important;
    // border-bottom: 5px solid white;
  }
}
</style>
