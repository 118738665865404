<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; ">Get Items</h4>
            <p style="text-align: left; font-size: 1em; ">Get items for each facility based on a credential.</p>
            <!-- <p style="text-align: left; font-size: 1em; ">Unlinking a prescriber removes their access to the selected facility's resident data.</p> -->
            <div v-if="!companyFacilities || loadingGetItems">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display: grid; grid-gap:1em; margin-top: 1em;">
            <div style="display:flex; gap: 15px; justify-content: space-between; align-items:end ">
                <div style="width: fit-content; margin-right: 10px;">
                    <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Select Facility: </p>
                    <Dropdown v-model="selectedFacility" :options="companyFacilities" optionLabel="name" style="min-width: 20em; height: fit-content" />
                </div>
                <div v-if="companyFacilities.length">
                    <button class="blue-button" @click="displayGetItemsModal = true">Get items</button>
                </div>
                <button class="green-button" style="margin-left: auto" @click="retrieveItems()">Refresh List</button>
            </div>
                <!-- Code for Item -->
                <div v-if="fetchedItems?.length > 0">
                  <div style="display: flex; flex-direction: column; justify-content: space-between;">
                    <div style="flex:1">
                      <table cellspacing="0" class="data-table">
                        <tr class="table-headers">
                          <th>Type</th>
                          <th>Synced At</th>
                          <th style="padding-right: 70px;text-align: end;">Status</th>
                        </tr>
                        <tbody class="table-data" v-for="(report, index) in fetchedItems" :key="index">
                          <tr>
                            <td>{{ report.type }}</td>
                            <td>{{ report.created_at }}</td>
                            <td style="text-align: end;">
                              <span class="tag" :style="getTagStyle(report.status)">
                                 <p style="color:white;font-weight: 16px;padding: 0 0; margin: 0 0;" >{{ report.status.charAt(0).toUpperCase() + report.status.slice(1) }}</p>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
          
                    <div class="pagination-links">
                     <p class="pagination-arrow" v-show="searchPage + 1 > 1" @click="displayPage(searchPage - 1)"> &larr; </p>
                     <p>Page {{ searchPage + 1}} of {{ pageCount }}</p>
                     <p class="pagination-arrow" v-show="searchPage + 1 < pageCount" @click="displayPage(searchPage + 1)"> &rarr;
                     </p>
                   </div>
                  </div>
                </div>
                <div v-else>
                  <p>No items synced for this location.</p>
                </div>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"  />
        <GetItemsModal @close="close" v-if="displayGetItemsModal" :selectedFacility="selectedFacility" />
    </div>
</template>

<script>
import axios from 'axios';
import GetItemsModal from '@/components/modals/GetItemsModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['facilities'],
    components: {
        SuccessModal,
        GetItemsModal
    },
    data() {
        return {
            selectedIntegration:null,
            displaySuccessModal: false,
            displayGetItemsModal: false,
            loadingIntegrationFacilities: false,
            selectedCredential:null,
            companyFacilities: null,
            loadingGetItems: false,
            syncPayload: null,
            linkedFacility:[
                // {
                //     facility_change: null,
                //     facility_name: 'Sunshine Facility',
                //     external_id: '9293292-222221',
                //     integration_label: 'Sun. Fac.'
                // }
            ],
            newCredentialId: '',
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            loadingConfig: false,
            loadingFacilities: false,
            prescribers:[],
            fetchedItems: [],
            pageCount:0,
            searchPage: 0,
        }
    },
    methods: {
        successfullySubmitted(value){
            if(value){
                this.displayDeleteCredentialModal  = false;
                this.selectedCredential = null;
                this.getCompanyCredentials();
               
            }
        },
        close(value){
            if(value){
                this.displaySuccessModal = false;
                this.displayGetItemsModal = false;
                this.retrieveItems();
            }
        },
        retrieveItems() {
          this.loadingGetItems = true;
          let payload = {
            location_id: this.$store.state.currentLocation.uuid,
            page: this.searchPage,
            type: [
              "import_drug_items",
              "import_invoices",
              "import_orders",
              "import_clients",
              "import_suppliers",
              "import_batches"
            ]
          };
          console.log('Getting Items for this location:', payload);
          const endpoint = "/bcp/documents"
          axios.post(endpoint, payload, this.$store.state.header).then(res => {
            console.log("res>>", res)
            this.fetchedItems = res.data.data
            this.pageCount = res.data.meta.last_page
            this.loadingGetItems = false;
          }).catch(err => {
            console.log('err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
            }
          })
        },
        getCompanyIntegratedFacilities(){
            axios.post('/get-company-integrated-facilities', {}, this.$store.state.header).then(res=>{
                this.companyFacilities = res.data.response.data;
                console.log('these are the integration facilities', res.data);
                this.selectedFacility = this.companyFacilities[0]
                this.retrieveItems();
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
             if (err.response && err.response && err.response.split(' ').includes('expired')) {
               console.log('yes, it does include expired')
               this.$router.push('/login');
             }
             console.log('err is', err.response, err.response);
             this.$store.dispatch('getRefreshToken');
          })
        },
        getTagStyle(status) {
            const colour = status == 'failed' ? '#E04F39' : status == 'processing' ? '#EF9600' : status == 'completed' ? '#00AD50' : ""
            return {
                'background-color': colour
            };
        },
        displayPage(page){
          this.searchPage = page;
          this.retrieveItems();
        },
    },
    mounted() {
        this.getCompanyIntegratedFacilities();
    },
    computed:{
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/main.scss";

.pi-download {
    color: black;
    background-color: transparent;
    cursor: pointer;
}

.tag {
  pointer-events: none;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 15px;
  justify-self:end; 
  width: 118px;;
  font-weight: 700; 
  font-size: 15px; 
}

</style>
