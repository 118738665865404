<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Administration History</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px; max-height: 60vh; overflow-y: scroll; width: 100%; padding-left: 0;">
                                <table style="border-collapse: collapse" v-if="administrationHistory">
                                    <tr>
                                        <th>Date</th>
                                        <th>Administered By</th>
                                        <th>Qty</th>
                                        <th>Administered</th>
                                        <th v-if="prn">Effective</th>
                                        <th>DOC</th>
                                        <th>Note</th>
                                    </tr>
                                    <tr v-for="(item, index) in administrationHistory.data" :key="index+'history'">
                                        <td>{{item.administered_at ?  `${item.administered_at}`: 'N/A'}}</td>
                                        <td>{{item.administrator && item.administrator.personal_information && item.administrator.personal_information.first_name ? `${item.administrator.personal_information.first_name} ${item.administrator.personal_information.last_name}` : 'N/A'}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>{{item.doc ? 'NO' : 'YES'}} 
                                            <span v-tooltip.bottom="`This administration event was reverted on ${item.deleted_at}`" class="red-tag" v-if="item.deleted_at">
                                                (Reverted {{ item.deleted_at }})
                                                <i style="padding: 0px;" class="pi pi-question-circle"></i>
                                            </span>
                                        </td>
                                        <td v-if="prn">{{ item.effectiveness ? 'Y' :'N' }}</td>
                                        <td>{{item.doc ? item.doc : '--'}}</td>
                                        <td><p class="blue-button" v-if="item.note" 
                                            style="padding: 5px 0px;
                                            width: fit-content;
                                            display: flex;
                                            align-items: center;" @click="modalNotes = item.note, additionalNotes= item.additional_notes, displayNotesModal = true" ><i class="pi pi-align-justify" ></i></p></td>
                                    </tr>
                                </table>
                                <div v-else>
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                                    <h4>Loading...</h4>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" >
                        <div class="pagination-links" style="margin: 0 auto" v-if="administrationHistory && administrationHistory.header">
                            <p class="pagination-arrow" v-show="administrationHistory.header.current_page > 1" @click="displayPage(administrationHistory.header.current_page - 1)"> &larr; </p>
                            <p>Page {{administrationHistory.header.current_page}} of {{administrationHistory.header.page_count}}</p>
                            <p class="pagination-arrow" v-show="administrationHistory.header.current_page < administrationHistory.header.page_count" @click="displayPage( administrationHistory.header.current_page + 1)"> &rarr; </p>
                        </div>
                    </slot>
                </div>
                <AdminHistoryNotesModal v-if="displayNotesModal" :notes="modalNotes" :additionalNotes="additionalNotes" @close="closeNotes" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AdminHistoryNotesModal from './AdminHistoryNotesModal.vue';

export default {
    props: ['admin_history', 'close', 'prescription_id', 'prn'],
    components: {
        AdminHistoryNotesModal,
    },
    data() {
        return {
            administration_history:null,
            displayNotesModal: false,
            modalNotes: '',
            additionalNotes: [],
        }
    },
    methods: {
        closeNotes(value){
            if(value){
                this.displayNotesModal = false,
                this.modalNotes = '';
                // this.additionalNotes = ''
            }
        },  
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        displayPage(page){
            console.log('this is the page', page);
            if(this.prescription_id){
                axios.post(`/prescription/${this.prescription_id}/administrations`, {page: page}, this.$store.state.header).then(res=>{
                    console.log('this is the res for the chart admin history', res);
                    this.administration_history = res.data;
                })
            }
        },
    },
    computed:{
        administrationHistory(){
            if(this.admin_history && !this.administration_history){
                return this.admin_history;
            } else{
                return this.administration_history;
            }
        }
    },
    mounted() {
        console.log('the administration summary is mounted');
        if(this.admin_history){
            this.administration_history = {...this.admin_history}
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

.notes-modal-section {
    // background-color: white;
}

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
