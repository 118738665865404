<template>
    <div class="table-no-filters table-container" style="width: 95.5vw; border-top-right-radius: 0;"
      :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
      <div style='overflow-y: scroll'>
        <div class="table-no-filters table-container" style="padding-top: 10em;"
          v-if='!$store.state.user.role.permissions.includes("can_view_eprescribing_report")'>
          <h3
            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
            You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div>
        <div v-else>
          <div v-if="$store.state.loadingEPrescribingData">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
          </div>
          <table v-else class="data-table eprescribing-table" id="transaction-table" cellspacing="0">
            <!-- LOADING SPINNER -->
  
            <tr class="table-headers">
              <th style="width: 3em" class="expand-column"></th>
              <th style="width: 10em">
                <h3>Action</h3>
              </th>
              <th style="width: 10em">
                <h3>GUID</h3>
              </th>
              <th style="width: 12em">
                <h3>SCID</h3>
              </th>
              <th style="width: 8em">
                <h3>Status Code</h3>
              </th>
              <th style="width: 10em">
                <h3>User</h3>
              </th>
              <th style="width: 10em">
                <h3>Creation Date</h3>
              </th>
              <th style="width: 10em">
                <h3>Acknowledgement Date</h3>
              </th>
            </tr>
  
            <tbody class="table-data" v-for="(item, index) in getDrugEntries.data" :key="index">
              <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
                @click="showContent(item, index)">
                <td style="min-width: 30px;" class="expand-column">
                  <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                  <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>{{ item.action }}</td>
                <td>{{ item.uuid }}</td>
                <td>{{ item.scid }}</td>
                <td style="font-weight: bold" :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">
                  {{ item.status_code }}
                </td>
                <td>{{ item.user }}</td>
                <td>{{ item.creation_date }}</td>
                <td>{{ item.acknowledgement_date }}</td>
              </tr>
              <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content">
                <div class="expanded-transfer-container" style="width: 88vw">
                  <div class="transfer-notes-container" style="min-width: 15em">
                    <p class="field-title">Message</p>
                    <div class="field-notes" style="width: 500px; height: 230px;">
                      <p><pre>{{ item.request_response }}</pre></p>
                    </div>
                  </div>
                </div>
                <!-- ACTIONS -->
                <div class="transfer-actions-container" style="width: 88vw">
                  <!-- CLOSE BUTTON -->
                  <button @click="showContent(item, index)" style="background-color: white; padding: 0;">
                    <i class="pi pi-times"></i>
                  </button>
                </div>
              </tr>
            </tbody>
          </table>
  
          <!-- PRINT AUDIT TABLE -->
          <!-- PRINT AUDIT TABLE -->
          <!-- PRINT AUDIT TABLE -->
          <div style="width: 100vw">
            <table class="data-table print-tx-table tx-table" id="transaction-table" cellspacing="0">
              <tr>
                <th style="width: 10em">
                  <h3>Action</h3>
                </th>
                <th style="width: 10em">
                  <h3>GUID</h3>
                </th>
                <th style="width: 12em">
                  <h3>SCID</h3>
                </th>
                <th style="width: 8em">
                  <h3>Status Code</h3>
                </th>
                <th style="width: 10em">
                  <h3>User</h3>
                </th>
                <th style="width: 10em">
                  <h3>Creation Date</h3>
                </th>
                <th style="width: 10em">
                  <h3>Acknowledgement Date</h3>
                </th>
              </tr>
              <tr v-for="(item, index) in getDrugEntries.data" :key="index">
                <td>{{ item.action }}</td>
                <td>{{ item.uuid }}</td>
                <td>{{ item.scid }}</td>
                <td style="font-weight: bold" :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">
                  {{ item.status_code }}
                </td>
                <td>{{ item.user }}</td>
                <td>{{ item.creation_date }}</td>
                <td>{{ item.acknowledgement_date }}</td>
              </tr>
            </table>
          </div>
          <!-- PRINT EPrescribing TABLE -->
          <!-- PRINT EPrescribing TABLE -->
          <!-- PRINT EPrescribing TABLE -->
  
          <!-- IF NO DATA DISPLAYED -->
          <div v-if="!$store.state.loadingEPrescribingData && (getDrugEntries.data && !getDrugEntries.data.length)">
            <h4>No log entries to display.</h4>
          </div>
        </div>
        <div v-if="$store.state.reportError">
          <h3 style="color: #E04F39">{{ $store.state.reportError }}</h3>
        </div>
      </div>
  
      <div class="pagination-links">
        <p class="pagination-arrow" v-show="getDrugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr;
        </p>
        <p>Page {{ getDrugEntries.current_page }} of {{ getDrugEntries.page_count }}</p>
        <p class="pagination-arrow" v-show="getDrugEntries.current_page < getDrugEntries.page_count"
          @click="displayPage(getDrugEntries.current_page + 1)"> &rarr; </p>
      </div>
    </div>
  </template>
  
  
  <script>
  // @ is an alias to /src
  export default {
    data() {
      return {
        clearForm: {
          sort_direction: 'desc',
          col: '',
          page: 1,
          filters: {
            status_code: [],
            date: {
              start: '',
              end: '',
            }
          }
        },
        form: {
          sort_direction: 'desc',
          col: '',
          page: 1,
        }
      }
    },
    methods: {
      sortBy(col, dir) {
        this.form.col = col;
        this.form.sort_direction = dir;
        this.$store.dispatch('getEPrescribingReportEntries', this.form)
      },
      loadReport() {
        this.$store.dispatch('getEPrescribingReportEntries', this.form);
      },
      displayPage(page) {
        this.form.page = page;
        this.$store.dispatch('getEPrescribingReportEntries', this.form);
      },
      showContent(item, index) {
        if (item.display && (item.display === true)) {
          document.getElementById(`expand-${index}`).style.display = 'none';
          item.display = !item.display;
        } else {
          item.display = true;
          document.getElementById(`expand-${index}`).style.display = 'table-row';
        }
      },
      clearFilteredEPrescribing() {
        this.form = {
          sort_direction: 'desc',
          col: '',
          page: 1,
        };
        this.$store.dispatch('getEPrescribingReportEntries', this.form);
      },
      printElem(elem) {
        console.log('this is the eleme', elem);
        window.print()
      }
    },
    mounted() {
      this.loadReport();
    },
    computed: {
      getDrugEntries() {
        // getStockReport
        console.log('this is the getEPrescribing report stuff', this.$store.getters.getEPrescribingReport);
        return this.$store.getters.getEPrescribingReport;
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "../../assets/css/main.scss";
  </style>