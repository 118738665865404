<template>
    <div
        class="container"
        :class="interactions.length ? `container-highlight` : `container-normal`"
    >
        <!-- INTERACTIONS HEADER -->
        <div
            class="header"
            :class="interactions.length ? `header-highlight` : `header-normal`"
        >
            <span
                v-if="loadingInteractions"
                class="text-flex"
            >
                <i
                    class="pi pi-spin pi-spinner"
                    style="font-size: 1.25rem !important;"
                ></i> Loading Interactions...
            </span>
            <span
                v-else-if="interactions.length"
                class="text-flex"
            >
                {{ currentDrugName && specificInteractions.length ? specificInteractions.length + ' ' + currentDrugName + ' Possible Interaction(s)' : '' }}
                {{ specificInteractions.length && otherInteractions.length ? ' and ' : '' }}
                {{ otherInteractions.length ? otherInteractions.length + ' Other Possible Interaction(s)' : '' }}
                Detected
            </span>
            <span v-else>
                No Interactions Detected
            </span>
        </div>
        <!-- INTERACTIONS CONTENT -->
        <div
            v-if="specificInteractions.length"
            class="interactions-content"
        >
            <div
                v-for="(interaction, index) in specificInteractions"
                :key="index"
                class="interaction"
            >
                <div
                    class="interaction-header"
                >
                    <div class="interaction-item">
                        {{ drugName(interaction.drugid[0]) }}
                    </div>
                    <img
                        :src="interactionIconSrc"
                        class="medium-icon"
                        alt="Interaction Icon"
                    >
                    <div class="interaction-item">
                        {{ drugName(interaction.drugid[1]) }}
                    </div>
                </div>
                <div class="explanation">
                    <p>Interaction Type: <span class="interaction-text">{{ interactionClass(interaction.class) }}</span></p>
                    <p>{{ interaction.explanation }}</p>
                </div>
                <div class="recommendation">
                    <p><b>Details:</b></p>
                    <p>{{ interaction.recommendation }}</p>
                </div>
            </div>
        </div>
        <!-- INTERACTIONS BUTTON -->
        <button
            v-if="interactions.length"
            class="blue-button"
            style="border-radius: 0px !important; width: 100%;"
            @click="displayQPModal = true"
        >
            View Detailed Analysis
        </button>
        <QPModal
            v-if="displayQPModal"
            :patient-id="patientId"
            :drug-uuid="drugUuid"
            @close="closeQP"
        />
    </div>
</template>

<script>
import axios from 'axios';
import QPModal from './QPModal.vue';

export default {
    props: {
        /**
         * The id of the patient
         * @type {String}
         */
        patientId: {
            type: String,
            default: ''
        },
        /**
         * The uuid of the drug
         * @type {String}
         */
        drugUuid: {
            type: String,
            default: null
        },
        /**
         * The drug reference
         * @type {String}
         */
        drugReference: {
            type: String,
            default: ''
        },
        /**
         * The drug company id
         * @type {String}
         */
        drugCompanyId: {
            type: String,
            default: ''
        }
    },
    name: 'QPTextInteractions',
    components: {
        QPModal
    },
    computed: {
        specificInteractions() {
            // Get the interactions which contain the drugReference
            return this.interactions.filter(interaction => {
                return interaction.drugid.includes(this.drugReferenceLocal);
            });
        },
        otherInteractions() {
            // Get the interactios which do not contain the drugReference
            return this.interactions.filter(interaction => {
                return !interaction.drugid.includes(this.drugReferenceLocal);
            });
        },
        currentDrugName() {
            // Get the drug name from the drugReference
            const drug = this.drugs[this.drugReferenceLocal];
            return drug ? drug.name : '';
        }
    },
    data() {
        return {
            interactionIconSrc: require('@/assets/icons/interaction_icon.svg'),
            drugReferenceLocal: '',
            loadingInteractions: false,
            interactionsError: '',
            interactions: [],
            drugs: [],
            displayQPModal: false
        }
    },
    async mounted() {
        // Set local variables to props
        this.drugReferenceLocal = this.drugReference;
        // Get the interactions
        await this.getInteractions();
    },
    watch: {
        // Re-run interactions on drugUuid change
        drugUuid() {
            console.log('drug uuid changed');
            this.getInteractions();
        }
    },
    methods: {
        drugName(reference) {
            // Get the drug name from the drugReference
            const drug = this.drugs[reference];
            return drug ? drug.name : '';
        },
        // Map the interaction code to the name
        interactionClass(interactionClass) {
            switch (interactionClass) {
                case 'D':
                    return 'CONTRAINDICATION';
                case 'C':
                    return 'SEVERE';
                case 'B':
                    return 'MODERATE';
                case 'A':
                    return 'UNDETERMINED';
                default:
                    return 'Unknown';
            }
        },
        // Get interactions for a patient
        async getInteractions() {
            this.loadingInteractions = true;
            this.$store.dispatch('getRefreshToken');
            this.clearInteractions();
            await axios.post('/qualitypharma/patient', {
            patient_id: this.patientId,
            drug_alias: this.drugUuid,
            interaction: true,
            doubleusage: true,
            show_questions: false
        }, this.$store.state.header)
            .then((res) => {
                this.handleInteractionsSuccess(res);
            })
            .catch((err) => {
                this.handleInteractionsError(err);
            });
        },
        // Success case of the interactions response
        handleInteractionsSuccess(res) {
            console.log('The Interactions response is', res.data)
            this.loadingInteractions = false;
            if (res.data.qp_data.analysis["1-3"]) {
                this.interactions = res.data.qp_data.analysis["1-3"].report;
            }
            this.drugs = res.data.qp_data.drugs;
            if (res.data.drug_reference) {
                this.drugReferenceLocal = res.data.drug_reference;
            }
        },
        // Error case of the interactions response
        handleInteractionsError(err) {
            console.log('The Interactions error is', err)
            this.loadingInteractions = false;
            // If the token is expired
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                this.$router.push('/login');
            }
            this.interactionsError = err;
        },
        clearInteractions() {
            this.interactionsError = '',
            this.interactions = [],
            this.drugs = []
        },
        close(value) {
            this.$store.dispatch('getRefreshToken');
            if (value) {
                this.displayQPModal = false;
            }
        },
        closeQP() {
            this.$store.dispatch('getRefreshToken');
            this.displayQPModal = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.container {
    border-radius: 6px;
    overflow: hidden;
}

.container-highlight {
    border: 1px solid $error;
}

.container-normal {
    border: 1px solid $bordergrey;
}

.text-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    margin: 0;
    font-weight: 600;
}

.header-highlight {
    background-color: $error;
    color: white;
}

.header-normal {
    background-color: $grey;
    color: $black;
}

.interactions-content {
    display: flex;
    flex-direction: column;
    background-color: $grey;
    overflow: auto;
    padding: 10px 20px 10px 20px;
    max-height: 250px;
    gap: 15px;
}

.interaction-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.interaction {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.interaction-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex: 1;
    border: 1px solid black;
    border-radius: 6px;
    font-weight: 600;
}

.interaction-text {
    font-weight: 600;
    color: $error;
}

.recommendation {
    text-align: left;
}

.recommendation > p {
    margin: 0;
}

.explanation {
    text-align: left;
}

.explanation > p {
    margin: 0;
}
</style>