<template>
    <div class="login-view">
      <div class="login-form">
        <div class="login-logo" style="width: fit-content">
          <img src="@/assets/logos/full_logo.png" alt="" style="height:70px;">
        </div>
        <div class="login-form-details" style="margin-top: 1em">
          <h2>Strong Room - Warehouse Company Registration</h2>
          <div style="display: flex; flex-wrap: wrap">
            <div class="login-form-fields slide-fade">
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name">Company Name <span style="color: red;">*</span></label>
                <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                  v-model="registerCompany.company_name" placeholder="Enter Company Name *">
              </div>
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name">Facility Name <span style="color: red;">*</span></label>
                <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                  v-model="registerCompany.facility_name" placeholder="Enter Facility Name *">
              </div>
              <div class="form-field wide-text-field" style="width: 95%;">
                <label for="contact_name">Branch State <span style="color: red;">*</span></label>
                <Dropdown v-model="registerCompany.state_id" :options="states" optionValue="id" optionLabel="code"
                  :placeholder="'Select state'" />
              </div>
            </div>
            <div class="login-form-fields slide-fade">
              <!-- CONTACT METHODS -->
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name">Company E-mail<span style="color: red;">*</span></label>
                <input placeholder="Enter Company E-mail" v-model="registerCompany.contact_methods[0].data" type="text"
                  style="position: relative; width: 26em; background-color: #E5F1FE;" />
              </div>
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name">Admin email<span style="color: red;">*</span></label>
                <input placeholder="Enter Admin Email" v-model="registerCompany.emails[0]" type="text"
                  style="position: relative; width: 26em; background-color: #E5F1FE;" />
              </div>
              <div class="form-field wide-text-field" style="width: auto">
                <label for="first_name">Address:<span style="color: red;">*</span></label>
                <div class="form-field text-field" style="width: 100%; margin-top: 0">
                  <vue-google-autocomplete id="contactmap" classname="form-control"
                    :placeholder="'1 Apple Street, Melbourne, VIC 3000'" v-on:placechanged="getCompanyAddressData"
                    :country="$store.state.country">
                  </vue-google-autocomplete>
                </div>
              </div>
              <!--  END OF CONTACT METHODS -->
            </div>
          </div>
          <div style="margin-top: 2em; display: flex; justify-content: center; margin-right: 1em;">
            <p @click="submitRegistration()" class="blue-button"
              style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; ">
              Register</p>
          </div>
  
          <p v-if="registrationError" style="color: red; font-size: 1em;">Error: {{ registrationError }}</p>
  
  
  
          <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"
            :first_spec_line="first_spec_line" :second_spec_line="second_spec_line" />
        </div>
      </div>
      <div class="login-context">
  
        <div class="login-logo" style="display: grid; justify-content: flex-end">
          <img src="@/assets/logos/short_logo.png" alt="" style="height:70px;">
        </div>
        <h1 style="margin-top: 3em; ">The AI-Driven Drug Management Platform</h1>
  
        <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
          specialised treatment plans for patients.</p>
      </div>
    </div>
  </template>
  
  
  <script>
  // @ is an alias to /src
  import axios from 'axios';
  import SuccessModal from '@/components/modals/SuccessModal.vue';
  
  export default {
    components: {
      SuccessModal
    },
    data() {
      return {
        displaySuccessModal: false,
        first_spec_line: '',
        second_spec_line: '',
        successMessage: '',
        registrationError: '',
        company_name: '',
        registerCompany: {
          company_name: '',
          facility_name: '',
          state_id: '',
          address: {
            state: '',
            country: '',
            street_address: '',
            postcode: '',
            suburb: '',
            city: ''
          },
          contact_methods: [
            {
              name: 'Email',
              data: '',
              type: 'email'
            }
          ],
          emails: [
            ''
          ]
        },
        timezones: [],
        states: []
      }
    },
    methods: {
      close(value) {
        if (value) {
          this.displaySuccessModal = false;
          this.$router.push('/login');
        }
      },
      // elF3Gv5HZd5G107m
      getCompanyAddressData(addressData, propertyData, id) {
  
        this.registerCompany.address.state = addressData.administrative_area_level_1;
        this.registerCompany.address.city = addressData.administrative_area_level_2;
        this.registerCompany.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
        this.registerCompany.address.country = addressData.country;
        this.registerCompany.address.postcode = addressData.postal_code;
        this.registerCompany.address.suburb = addressData.locality;
        console.log('this is the address now', addressData, propertyData, this.registerCompany.address);
      },
      submitRegistration() {
        // let methods=[];
        this.registerCompany.contact_methods.forEach((method, index) => {
          if (method.data) {
            this.registerCompany.contact_methods.slice(this.registerCompany.contact_methods[index], 1);
          }
        })
        // console.log('THESE ARE THE METHODS', methods);
        this.displaySuccessModal = true;
        this.successMessage = 'Submitting company registration details...'
        setTimeout(() => {
          //  this.registerCompany.contact_methods = methods;
          axios.post(`/onload-warehouse`, this.registerCompany).then(res => {
            console.log('this is the reg completion', res.data);
            this.successMessage = 'Company has been successfully registered!'
            this.first_spec_line = 'Your admin will receive an email with login details and next steps to complete the setup process.';
          }).catch(err => {
            console.log('this is the error with regstration', err);
          })
        }, 300)
  
      },
      retrieveStates() {
        axios.post('/get-states', {}).then(res => {
          this.states = res.data;
        }).catch(err => {
          console.log('this is the state error', err.response.data);
        })
      },
      getTimezones() {
        axios.post('/get-timezones', {}).then(res => {
          this.timezones = res.data;
        }).catch(err => {
          console.log('err issss', typeof err.response.data);
        })
      },
    },
    mounted() {
      this.getTimezones();
      this.retrieveStates();
    }
  }
  </script>
  
  <style lang="scss">
  @import "../assets/css/main.scss";
  
  .login-view {
    height: 100vh;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .login-context {
    background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
    color: white;
    padding-top: 8.125em;
    padding-left: 17em;
    padding-right: 8em;
    text-align: left;
  
    .login-logo {
      justify-content: flex-end;
    }
  
    h1 {
      font-size: 3.5em;
      font-weight: bold;
    }
  }
  
  .login-logo {
    justify-self: flex-start;
  }
  
  .login-form {
    padding: 8.125em 4.375em;
    border-bottom-right-radius: 8.25em;
    background-color: white;
    margin-right: -10em;
    z-index: 1;
    box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  
  .login-form-details {
  
    width: fit-content;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10em;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-content: center;
  
    h2 {
      text-align: left;
    }
  }
  
  .login-form-fields {
    display: grid;
    justify-content: center;
  }
  
  .form-error {
    margin: 0;
    margin-bottom: 1em;
    color: #E04F39; //$error;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }
  </style>
  