<template>
    <div class="table-no-filters table-container" style="width: 95.5vw; border-top-right-radius: 0; grid-template-rows: 70px 1fr"
      :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
      <div class="filters" style="margin-top: 10px;">
          <div class="text-search-container search-container">
            <input v-model="form.drug" placeholder="Medication..." style="position: relative" />
            <!-- <div class="drug-search-result-container" v-if='form.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
              <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
            </div> -->
            
          </div>
          <div class="tofrom-search-container search-container">
            <input v-model="form.patient" placeholder="Patient..." />
          </div>
            <button class="button submit-button" @click="loadReport()">Search</button>
            <button class="button clear-button" @click="clearFilteredEPrescribing()">Clear</button>
        </div>
      <div style='overflow-y: scroll'>
        <div class="table-container" style="padding-top: 10em;"
          v-if='!$store.state.user.role.permissions.includes("can_view_eprescribing_report")'>
          <h3
            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
            You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div>
        <div v-else>
          <div v-if="$store.state.loadingPrescriptionsData">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
          </div>
          <table v-else class="data-table eprescribing-table" id="transaction-table" cellspacing="0">
            <!-- LOADING SPINNER -->
  
            <tr class="table-headers">
              <th style="width: 3em" class="expand-column"></th>
              <th style="width: 15em">
                <h3>Drug</h3>
              </th>
              <th style="width: 2em">
                <h3>Dose</h3>
              </th>
              <th style="width: 3em">
                <h3>Qty</h3>
              </th>
              <th style="width: 10em">
                <h3>Patient</h3>
              </th>
              <th style="width: 10em">
                <h3>Doctor</h3>
              </th>
              <th style="width: 10em">
                <h3>Created Date</h3>
              </th>
              <th style="width: 10em">
                <h3>Acknowledgement Date</h3>
              </th>
            </tr>
            <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
              <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
                  @click="() => { showContent(item, index); item.checkHeight = true; }">
                  <td style="min-width: 30px;" class="expand-column" :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderLeft:'1.5px solid #0870DC !important', borderBottomLeftRadius:'6px', borderTopLeftRadius:'6px'}:{}">
                      <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                      <i v-else class="pi pi-angle-right"></i>
                  </td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.alias.name }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.dose }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.quantity }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.patient }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.doctor }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{ item.creation_date }}</td>
                  <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderRight:'1.5px solid #0870DC !important', borderBottomRightRadius:'6px', borderTopRightRadius:'6px'}:{}">
                    {{ item.acknowledgement_date }}
                  </td>
              </tr>
              <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content" >
                                <div v-if="item.reviewedPrescription" style="width: fit-content">
                                    <!-- <h3 style="text-align: left; margin-top: 0; margin-bottom: 9px; padding-left: 20px; padding-top: 20px;">Prescription Details
                                    </h3> -->
                                    <div style=" background-color: #f3f4f4; border-radius: 6px; padding: 10px;">
                                        <div class="chart-row prescription-chart" style="flex-wrap: wrap; width: 91vw">

                                            <div class="chart-row-item"
                                                style="width: fit-content; display: flex; gap: 1.5px; flex-wrap: wrap; min-width: 717px; margin-top: 7px;">
                                                <div style="text-align: left; width: 100%; margin: 0 12px; display: flex;">
                                                    <div style="width: 80%">
                                                        <p style="padding-left: 15px;">Medication name</p>
                                                        <div
                                                            style="text-align: left; width: 100%;  padding: 10px; border: 1px solid #dadada; border-radius: 6px; background-color: white;">
                                                            <p class="chart-item">{{ item.reviewedPrescription.alias_name }}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style="text-align: left; margin-left: 10px; padding-left: 15px;">
                                                            Prescribed Quantity
                                                        </p>
                                                        <div
                                                            style="text-align: left; margin-left: 10px;  border: 1px solid #dadada; border-radius: 6px; height: 40px; display: flex; align-items: center; background-color: white">
                                                            <p class="chart-item" :style="{ width: '253px' }"
                                                                style="padding-left: 15px;">
                                                                {{ item.reviewedPrescription.prescribed_quantity ?
                                                                        item.reviewedPrescription.prescribed_quantity : '--' }}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div style="display: grid;" v-for="(value, key) in item.reviewedPrescription"
                                                    :key="key">
                                                    <p style="text-align: left; margin-left: 10px; padding-left: 15px;"
                                                        v-if="key != 'prescribed_quantity'">
                                                        {{ key[0].toUpperCase() + key.split('_').join(' ').slice(1) }}
                                                    </p>
                                                    <div style="text-align: left; margin-left: 10px;  border: 1px solid #dadada; border-radius: 6px; height: 40px; display: flex; align-items: center; background-color: white" :style="key == 'instructions' ? { overflowY:'auto',paddingTop:'20px' } : ''"
                                                        v-if="key != 'prescribed_quantity'">
                                                        <!-- Show dispensing_apn for authorized script records -->
                                                        <p v-if="key == 'authority_prescription_number'" class="chart-item" :style="key == 'generic' ? { width: '530px' } : { width: '253px' }"
                                                            style="padding-left: 15px;">
                                                            {{ value }}
                                                           <!-- {{   prescriptionDetail(key,value, item.flatPrescription) }} -->
                                                        </p>
                                                        <!-- For non-auth records -->
                                                        <p v-else class="chart-item" :style="key == 'generic' ? { width: '530px' } : { width: '253px' }"
                                                            style="padding-left: 15px;">
                                                            {{ value }}
                                                           <!-- {{   prescriptionDetail(key,value, item.flatPrescription) }} -->
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="text-align: left; width: 100%; margin: 0 12px;">
                                                <p style="padding-left: 15px; font-size: 1em; font-weight: 500">Instructions
                                                </p>
                                                <div
                                                    style="text-align: left; width: 100%;  padding: 10px; border: 1px solid #dadada; border-radius: 6px; background-color: white;">
                                                    <p class="chart-item" style="font-size: 1em; font-weight: 500">{{
                                                        item.reviewedPrescription.instructions.split('-------Please write any additional notes below this line------- ').join('--')}}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
              </tr>
            </tbody>
           
          </table>
  
          <!-- PRINT AUDIT TABLE -->
          <!-- PRINT AUDIT TABLE -->
          <!-- PRINT AUDIT TABLE -->
          <div style="width: 100vw">
            <table class="data-table print-tx-table tx-table" id="transaction-table" cellspacing="0">
              <tr>
                <th style="width: 10em">
                  <h3>Action</h3>
                </th>
                <th style="width: 10em">
                  <h3>GUID</h3>
                </th>
                <th style="width: 12em">
                  <h3>SCID</h3>
                </th>
                <th style="width: 8em">
                  <h3>Status Code</h3>
                </th>
                <th style="width: 10em">
                  <h3>User</h3>
                </th>
                <th style="width: 10em">
                  <h3>Creation Date</h3>
                </th>
                <th style="width: 10em">
                  <h3>Acknowledgement Date</h3>
                </th>
              </tr>
              <!-- <tr v-for="(item, index) in getDrugEntries.data" :key="index">
                <td>{{ item.action }}</td>
                <td>{{ item.uuid }}</td>
                <td>{{ item.scid }}</td>
                <td style="font-weight: bold" :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">
                  {{ item.status_code }}
                </td>
                <td>{{ item.user }}</td>
                <td>{{ item.creation_date }}</td>
                <td>{{ item.acknowledgement_date }}</td>
              </tr> -->
            </table>
          </div>
          <!-- PRINT EPrescribing TABLE -->
          <!-- PRINT EPrescribing TABLE -->
          <!-- PRINT EPrescribing TABLE -->
  
          <!-- IF NO DATA DISPLAYED -->
          <!-- <div v-if="!$store.state.loadingPrescriptionsData && (getDrugEntries.data && !getDrugEntries.data.length)">
            <h4>No log entries to display.</h4>
          </div> -->
        </div>
        <div v-if="$store.state.reportError">
          <h3 style="color: #E04F39">{{ $store.state.reportError }}</h3>
        </div>
      </div>
  
      <div class="pagination-links">
        <p class="pagination-arrow" v-show="$store.state.prescriptionsReports.current_page > 1" @click="displayPage(form.page - 1)"> &larr;
        </p>
        <p>Page {{ $store.state.prescriptionsReports.current_page }} of {{ $store.state.prescriptionsReports.page_count }}</p>
        <p class="pagination-arrow" v-show="$store.state.prescriptionsReports.current_page < $store.state.prescriptionsReports.page_count"
          @click="displayPage($store.state.prescriptionsReports.current_page + 1)"> &rarr; </p>
      </div>
    </div>
  </template>
  
  
  <script>
import axios from 'axios';

  // @ is an alias to /src
  export default {
    data() {
      return {
        clearForm: {
          sort_direction: 'desc',
          col: '',
          page: 1,
          filters: {
            status_code: [],
            date: {
              start: '',
              end: '',
            }
          }
        },
        form: {
          sort_direction: 'desc',
          col: 'date',
          page: 1,
          patient:'',
          drug:'',
        }
      }
    },
    methods: {
      sortBy(col, dir) {
        this.form.col = col;
        this.form.sort_direction = dir;
        this.$store.dispatch('getFacilityPrescriptions', this.form)
      },
      loadReport() {
        this.form.page = 1;
        this.$store.dispatch('getFacilityPrescriptions', this.form);
      },
      displayPage(page) {
        this.form.page = page;
        this.$store.dispatch('getFacilityPrescriptions', this.form);
      },
      translatePbsType(pbsType){
          if(pbsType == "A"){
              return "Authority Required";
          }
          if(pbsType == "S"){
              return "Streamlined";
          }
          return "PBS";

      },
      getPrescription(item, uuid){
        axios.post('/get-prescriptions', {prescription_ids: [uuid]}, this.$store.state.header).then(res=>{
          console.log('this is the prescription retrieved', res.data);

          let pbsType = '';

          if(res.data[0]?.pbs_type){
            pbsType = this.translatePbsType(res.data[0].pbs_type)
          }

          item.reviewedPrescription = res.data ? {
              alias_name: res.data[0]?.alias?.name ? res.data[0].alias.name : '',
              alias_generic: res.data[0]?.alias?.generic_name ? res.data[0].alias.generic_name : '',
              // strength: res.data[0].alias.strength,
              doctor: res.data[0]?.doctor ? `${res.data[0].doctor.personal_information.first_name} ${res.data[0].doctor.personal_information.last_name}` : '',
              // alias_tags: res.data[0]?.alias?.tags ? res.data[0].alias.tags : '',
              // chart_tags: res.data[0]?.chart?.tags ? res.data[0].chart.tags : '',
              // reference script dates rather than chart datas as stop_dates in
              // charts tend to be offset by one day into the past
              start_date: res.data[0] && res.data[0].chart ? res.data[0].start_date : '',
              stop_date: res.data[0] && res.data[0].chart ? res.data[0].stop_date : '',
              created_date: res.data[0] && res.data[0].created_at ? res.data[0].created_at : '',
              pbs_type: pbsType,
              pbs_code: res.data[0]?.pbs_code ? res.data[0].pbs_code : '',
              authority_prescription_number: res.data[0]?.authority_prescription_number ? res.data[0].authority_prescription_number : '',
              route: res.data[0] && res.data[0].route ? res.data[0].route  : '',
              chart_type: res.data[0] && res.data[0].chart ? res.data[0].chart.type : '',
              dose: res.data[0] && res.data[0].chart ? res.data[0].chart.dose_amount : '',
              // dose_times: res.data[0] ? res.data[0].dose_times : '',
              frequency: res.data[0] ? res.data[0].frequency : '',
              frequency_shorthand: res.data[0] ? res.data[0].frequency_shorthand : '',
              instructions: res.data[0]?.instructions?.text ? res.data[0].instructions.text : '',
              max_dose: res.data[0] ? res.data[0].max_dose : '',
              interval: res.data[0]?.interval ? res.data[0].interval : '',
              repeats: res.data[0]?.num_repeats_authorised ? res.data[0].num_repeats_authorised : 0,
              repeat_interval: res.data[0]?.repeat_interval ? res.data[0].repeat_interval : 0,
              old_prescription_id: res.data[0] && res.data[0].old_prescription_id ? res.data[0].old_prescription_id : '',
              // chart_uuid: res.data[0] && res.data[0].chart ? res.data[0].chart.uuid : '',
              // alias_uuid: res.data[0] ? res.data[0].alias.alias_id : '',
              // prescription_id: res.data[0] && res.data[0].uuid ? res.data[0].uuid : '',
              brand_sub: res.data[0]?.subtitution_not_permitted ? 'Not permitted' : 'Allowed',
              prescription_number: res.data[0] && res.data[0].prescription_id ? res.data[0].prescription_id : '',
              prescribed_quantity: res.data[0]?.prescribed_quantity ? res.data[0].prescribed_quantity : '',
              notes: res.data[0]?.chart_note.length > 0 ? res.data[0]?.chart_note[res.data[0].chart_note.length -1].text : ''
          } 
              : null
           return {
            data: res.data[0],
            reviewedPrescription: item.reviewedPrescription
           };
        }).then(res => {
          const { uuid } = res.data;
          const pbs = {
            code:res.data.pbs_code,
            apn: res.data.authority_prescription_number
          };
          this.getDispensingAPN(uuid, pbs, res.reviewedPrescription);
        });
        setTimeout(this.flattenPrescription(item),300);
      },
      getDispensingAPN(id, pbs={}, reviewedPrescription) {
        // kraken already sends us this info so there is no need for this
        // if (pbs.code && !pbs.apn) {
        //   // placeholder to notify the user that the APN is on its way
        //   reviewedPrescription.authority_prescription_number = 'Retrieving APN...';
        //   axios.post('/dispensing-apn', { prescription_id: id }, this.$store.state.header).then(res => {
        //     console.log('this is the dispensing apn', res.data.number);
        //     if (res.data.number) reviewedPrescription.authority_prescription_number = res.data.number;
        //     else reviewedPrescription.authority_prescription_number = '';
        //   });
        // }
      },
      showContent(item, index) {
        if (item.display && (item.display === true)) {
          document.getElementById(`expand-${index}`).style.display = 'none';
          item.display = !item.display;
        } else {
          item.display = true;
          document.getElementById(`expand-${index}`).style.display = 'table-row';
          this.getPrescription(item, item.uuid);
        }
      },
      flattenPrescription(item) {
        console.log('flattening prescription')
            if (item.reviewedPrescription) {
                item.flatPrescription =  {
                    chart_type: item.reviewedPrescription.chart_type.split('_').join(' '),
                    generic: item.reviewedPrescription.alias_generic,
                    // strength: item.reviewedPrescription.alias.strength,
                    frequency: item.reviewedPrescription.frequency_shorthand,
                    created_date: item.reviewedPrescription.created_date,
                    route: item.reviewedPrescription.route,
                    start_date: item.reviewedPrescription.start_date,
                    stop_date: item.reviewedPrescription.stop_date,
                    dose: item.reviewedPrescription.dose,
                    interval: item.reviewedPrescription.interval,
                    repeats: item.reviewedPrescription.repeats,
                    repeat_interval: item.reviewedPrescription.repeat_interval,
                    // chart_uuid: item.reviewedPrescription.chart.uuid,
                    max_dose: item.reviewedPrescription.max_dose,
                    // prescription_id: item.reviewedPrescription.prescription_id,
                    // alias_tags: item.reviewedPrescription.alias_tags,
                    prescription_type: item.reviewedPrescription.pbs_code ? 'PBS' : 'Private',
                    brand_sub: item.reviewedPrescription.brand_sub,
                    authority_prescription_no: item.reviewedPrescription.authority_prescription_number,
                    prescribed_quantity: item.reviewedPrescription.prescribed_quantity
                }
            }
        },
        prescriptionDetail(key, value, flatPrescription) {
            console.log("flat")
            console.log(flatPrescription)
            var intervalString = ""

            // Check 1
            if (key == 'chart_type' || key == 'generic') {
                intervalString = value.slice(0, 1).toUpperCase() + value.slice(1)
            } else {
                if (key != 'interval') {
                    intervalString = value
                } else {
                    if (flatPrescription.chart_type != 'stat') {
                        intervalString = value
                    } else {
                        intervalString = "--"
                    }
                }
            }

            // Check 2
            if (flatPrescription.chart_type != 'stat' && key == 'interval') {
                    if(this.reviewedPrescription.frequency == "daily") {
                        intervalString = flatPrescription.interval == 1 ?   flatPrescription.interval + " Day" : flatPrescription.interval + " Days"
                } else if(this.reviewedPrescription.frequency == "weekly") {
                    intervalString = flatPrescription.interval == 1 ?   flatPrescription.interval + " Week" : flatPrescription.interval + " Weeks"
                } else if(this.reviewedPrescription.frequency == "monthly") {
                    intervalString =  flatPrescription.interval == 1 ?  flatPrescription.interval + " Month" : flatPrescription.interval + " Months"
                }
            }

            if(key == 'max_dose' && flatPrescription.max_dose === null) {
                intervalString = ""
            }

            return intervalString
        },
      clearFilteredEPrescribing() {
        this.form = {
          sort_direction: 'desc',
          col: 'date',
          page: 1,
          patient:'',
          drug:'',
        };
        this.$store.dispatch('getFacilityPrescriptions', this.form);
      }
    },
    mounted() {
      this.loadReport();
    },
    computed: {
      getDrugEntries() {
        // getStockReport
        console.log('this is the getEPrescribing report stuff', this.$store.getters.getPrescriptionsReport);
        return this.$store.getters.getPrescriptionsReport;
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "../../assets/css/main.scss";
  </style>