<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 500px">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3 class="form-header">Administration <span
                                    :style="[itemInfo.customData.administration.tags.includes('effective') ? { color: '#00AD50' } : { color: '#EF9600' }]">{{
                                            itemInfo.customData.administration.tags.includes('effective') ? '- Effective' :
                                                itemInfo.customData.administration.tags.includes('not effective') ? '- Not \
                                                                        Effective' : ''
                                    }}</span></h3>
                        </div>

                        <button @click="closeModal" class="red-close-button" style="width: 30px !important">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">

                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; text-align: left;">
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Medicine</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em;">{{ drug }}</p>
                                    </div>
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Date</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em;">{{ new
                                                Date(itemInfo.dates[0].endTime).toDateString()
                                        }}</p>
                                    </div>
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Administration</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em; font-weight: 500;"
                                            :style="[itemInfo.customData.administration.doc ? { color: '#E04F39' } : { color: '#00AD50' }]">
                                            {{ itemInfo.customData.administration.doc ? 'Not Succesful - \
                                                                                        '+ itemInfo.customData.administration.doc : 'Administration Successful'
                                            }}
                                        </p>
                                    </div>
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Dose</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em;">
                                            {{ itemInfo.customData.dose }}</p>
                                    </div>
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Time</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em;">
                                            {{ itemInfo.customData.time.slice(0, 2) }}:{{
                                                    itemInfo.customData.time.slice(2, 4)
                                            }}
                                        </p>
                                    </div>
                                    <div style="margin-bottom: 1.5em;">
                                        <h4 style="color: black; font-size: 1.1em;">Administered By</h4>
                                        <p style="padding: 0px 25px; margin:0; font-size: 1em;">
                                            {{ itemInfo.customData.administration.administered_by }}</p>
                                    </div>
                                </div>
                                <div style="text-align: left;">
                                    <h4 style="color: black; font-size: 1.1em;;">Administration Notes</h4>
                                    <p style="padding: 0px 25px; margin:0; font-size: 1em;">
                                        {{ itemInfo.customData.administration.notes }}</p>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['close', 'endpoint', 'itemInfo', 'drug'],
    components: {
    },
    data() {
        return {
            toSubmit: this.payload,


        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        submitAction() {
            this.verification.signature1 = this.signature1;
            if (this.dual) {
                console.log('running through dual conditional')
                this.verification.signature2 = this.signature2;
            }
            this.toSubmit.verification = this.verification;
            axios.post(`${this.endpoint}`, this.toSubmit);
            this.$emit('isSuccessful', true);
            this.closeModal();
        },
        retrievePatientSummary() {

        },
        verifySig(signatureEntry) {
            if (signatureEntry == 1) {
                axios.post('/signature-token', this.sig1, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken1 = res.data.access_token
                }).then(() => {
                    axios.post('/signature-id', { signature_token: this.sigToken1 }, this.$store.state.header).then(res => {
                        console.log('this is the signature id', res.data);
                        this.signature1 = res.data.uuid;
                        this.success1 = true;
                    });
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });



                // this.$emit('getsignature1', 'm2p3of2j3f0fkkk');
            }
            if (signatureEntry == 2) {
                axios.post('/signature-token', this.sig2, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken2 = res.data.access_token
                }).then(() => {
                    axios.post('/signature-id', { signature_token: this.sigToken2 }, this.$store.state.header).then(res => {
                        console.log('this is the signature id', res.data);
                        this.signature2 = res.data.uuid;
                        this.success2 = true;
                    });
                })



            }
        }
    },
    mounted() {
    }
}
</script>


<style lang="scss">
@import "../../assets/css/main.scss";

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}



.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
