<template>
    <h2>Feature flags</h2>
    <hr>
    <div class="flags" v-if="!$store.state.user.role.permissions.includes('can_do_admin_roles')">
        <p>You do not have permission to access this.</p>
    </div>
    <div v-if="$store.state.user.role.permissions.includes('can_do_admin_roles')" class="flags">
        <div class="flag-grp">
            <InputSwitch class="flag-item" id="import_orders" v-model="import_orders" :trueValue="true" :falseValue="false" @click="toggleFlag('import_orders', !import_orders)"/>
            <label class="flag-item" for="cc_poll_switch">Poll SOs from cartoncloud integration</label>
        </div>
        <div class="flag-grp">
            <InputSwitch class="flag-item" id="import_invoices" v-model="import_invoices" :trueValue="true" :falseValue="false" @click="toggleFlag('import_invoices', !import_invoices)"/>
            <label class="flag-item" for="cc_poll_switch">Poll POs from cartoncloud integration</label>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            import_orders: false,
            import_invoices: false
        };
    },
    methods: {
        toggleFlag(name, value) {
            console.log('this is the flag', name);
            console.log('this is the value set', value);
            this.$store.dispatch('getRefreshToken');
            let flag = name;
            let checked = value;
            // reference feature in store and switch on or off
            if (flag == 'import_invoices') {
                this.$store.commit('setInvoiceCCFlag', checked);
            }
            if (flag == 'import_orders') {
                this.$store.commit('setOrderCCFlag', checked);
            }
        }
    },
    mounted() {
        this.import_invoices = this.$store.getters.getInvoiceCCFlag ?? this.import_invoices;
        this.import_orders = this.$store.getters.getOrderCCFlag ?? this.import_orders;
    }
}
</script>
<style>
.flags {
    display: flex;
    padding: 10px;
    flex-direction: column;
}
.flag-grp {
    display: flex;
    align-items: center;
}
.flag-item {
    margin: 5px;
}
</style>