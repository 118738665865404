<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%; padding: 10px 0;">
                <div class="modal-header">
                    <h3 class="form-header">Time out warning</h3>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div>
                                    <h3>Your session is about to expire.</h3>
                                    <p style="font-size: 1em">You will redirected to the login page in {{$store.state.timeToLogOut.toFixed(0)}} seconds.</p>
                                    
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content:center; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button class="blue-button" @click="extendSession()">
                            Extend Session
                        </button>
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    props: ['message', 'close', 'first_spec_line', 'second_spec_line', 'signature_endpoint', 'reloadPageOnClose', 'time'],
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        extendSession() {
            this.$store.dispatch('getRefreshToken');
            this.closeModal();
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
