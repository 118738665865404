<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 70%;">
                <div class="modal-header">
                    <h3 class="form-header">Initiate Merge Request </h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div>
                                    <p style="font-size: 1em;">Are you sure you wish to proceed to merge these two residents?</p>
                                    <div style="display: flex; margin: 0 auto; justify-content: center">
                                        <div class="merge-patient-card">
                                            <p>{{personalInformation.first_name}} {{personalInformation.last_name}}</p>
                                            <p>{{personalInformation.dob}}</p>
                                            <p>{{personalInformation.sex}}</p>
                                        </div>
                                        <div class="merge-patient-card">
                                            <p>{{mergeSummary.person.personal_information.first_name}} {{mergeSummary.person.personal_information.last_name}}</p>
                                            <p>{{mergeSummary.person.personal_information.dob}}</p>
                                            <p>{{mergeSummary.person.personal_information.sex}}</p>
                                        </div>
                                    </div>
                                    <p style="font-size: 1em;">This process cannot be reversed, a new resident will be generated and the two original residents will be deleted.</p>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;" >
                    <slot name="footer" style="display: flex;">
                        
                        <router-link style="text-decoration: none; margin-left: 1em" :to="{ name: 'Merge Patients', params: { id2: mergeSummary.person.uuid, id: $route.params.id }}" >
                            <button class="blue-button"  @click="signAction()">
                                Proceed to Merge
                            </button>
                        </router-link>
                        <!-- <button class="grey-button"  @click="signAction()">
                            Cancel
                        </button> -->
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    props: [ 'conflictPatients', 'mergeSummary', 'personalInformation'],
    emits:['close'],
    components: {
        
    },
    data() {
        return {
        }
    },
    methods: {
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.merge-patient-card{
    width: 200px;
    background-color: #f3f4f4;
    margin-right: 10px;
    p{
        font-weight: bold;
    }
}

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
