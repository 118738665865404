<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 60% ">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">New Batch</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot> 
                </div>
                <div class="modal-body" style="padding:0px 15px 25px 15px">
                    <slot name="body">
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel" style="min-height: 175px;" >
                                    <div class="adjustleftsidediv">

                                            <div class="select-filters-container" style="width: 100%;height: 35px;background: transparent;border: 1.5px solid #F3F4F4;">
                                              
                                                <p @click="setType('create')"   
                                                    v-bind:class="[type == 'create' ? 'active-select-filter' : '']"
                                                    style="width: 100%;display: flex; justify-content: center; align-items: center;" class="select-filter-item">Create Batch</p>

                                                <p @click="setType('edit')"
                                                    v-bind:class="[ type== 'edit' ? 'active-select-filter' : '']"
                                                    style="width: 100%;display: flex; justify-content: center; align-items: center;" class="select-filter-item">Edit Batch</p>
                                            </div>
                                           <div v-if="type === 'create'">
                                                  <label for="name" class="batch_label">Batch Name</label>
                                                    <input placeholder="Enter Batch Name" id="name" v-model="form.submit.batch_name"
                                                    style="position: relative; width: 100%;background-color:#E5F1FE;border: 1.5px solid #0870DC;"  class="calendar-input-container"/>
                                            </div>

                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <div v-if="type=='edit'">
                                            <label for="name" class="batch_label" v-if="type=='edit'" >Select Batch </label>
                                            <div class="text-search-container search-container calenderdatelabel" style="display: flex;flex-direction: row;">
                                                <div class="calendar-input-container" style="height: auto !important;"> 
                                                  <i class="pi pi-search"></i>
                                                   <Dropdown id="prescription-med-search" v-model="searchedBatch" placeholder="Select Batch"
                                                    ref="batch" @change="batchSelectAndChange()" @keyup="searchBatch(), showBatches()" 
                                                  :loading="loadingBatch" :options="refinedBatches"     optionLabel="batch_name"
                                                  :editable="true"
                                                  style="width: 20em; display: flex;background-color:#E5F1FE;border:none !important"/>
                                               </div>
                                            </div>
                                        </div>
                                           
                                            <div class="date-search-container search-container calenderdatelabel" v-if="type=='edit' ? addingMed : type=='create'"> 
                                                <label for="name" class="batch_label">Batch Expiry Date </label>
                                              <div class="calendar-input-container" id="calenderDate">
                                              <i class="pi pi-calendar"></i>
                                                <Calendar v-model="form.submit.expiry_date" placeholder="Select Expiry Date" dateFormat="dd/mm/yy" class="custom-calendar"/>
                                              </div>
                                            </div>
                                        <div v-if="type=='edit' ? addingMed : type=='create'">
                                            <label for="name" class="batch_label">Batch ID</label>
                                            <input v-model="form.submit.batch_id"  placeholder="Enter Batch ID"
                                            style="position: relative; width: 100%;background-color:#E5F1FE;border: 1.5px solid #0870DC;" class="calendar-input-container"/>
                                            
                                        </div> 
                                    <div v-if="type=='edit' ? addingMed : type=='create'">
                                        <p class="note-header">Note</p>
                                        <textarea name="notes" id="note-modal" rows="14"
                                            placeholder="This is note for a Batch"
                                            style="width: 100%; border-radius: 0px 0px 8px 8px;"
                                            v-model="form.submit.notes"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-form-section-panel" style="position: relative;">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b >Medicare Number:</b> {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <div v-if="patient">
                                        <div style="display: flex; " :style="!selectedPrescriber ? {alignItems: 'flex-end'}:{alignItems: 'center'}">
                                            <div v-if="!selectedPrescriber"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for
                                                    Prescriber</label>
                                                <input v-model="gp" v-on:keyup="gpSearch(gp)"
                                                    placeholder="Enter Prescriber No. or Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="gp"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.regulatory_numbers.length
                                                                ? item.regulatory_numbers[0].data : 'Reference only'
                                                        }} - {{ item.first_name }}
                                                        {{ item.last_name }}</p>
                                                </div>
                                                
                                            </div>
                                            <div v-if="!selectedPrescriber" style="margin-bottom: 2px;">
                                                <p class="blue-button" style="margin: 0; font-size: 1em;" @click="displayReferencePrescriberModal = true">Create prescriber</p>
                                            </div>
                                            <div v-if="selectedPrescriber"
                                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 15px;">
                                                <p style="font-size: 0.9em; text-align: left;"><b>Prescriber:</b>
                                                    {{ selectedPrescriber.first_name }} {{ selectedPrescriber.last_name }}
                                                </p>
                                                <p style="font-size: 0.9em" v-if="selectedPrescriber.prescriber_no"><b>Prescriber Number:
                                                    </b>{{ selectedPrescriber.prescriber_no }}</p>
                                            </div>
                                            <p style="text-align: left;  cursor:pointer; width: fit-content;
                                        border-bottom: 1px solid grey; margin-top: 0; height: fit-content"
                                                v-if="selectedPrescriber.uuid" @click="changePrescriber()">Change
                                                Prescriber</p>
                                        </div>
                                    </div>
                                    <!-- Imprest -->
                                    <div v-if="$store.state.company.type !== 'warehouse' && !imprest_pom">
                                        <p style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Resident Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- MED SELECT DROPDOWN -->
                                    <div class="right-form-section-drug-entry" v-if="($store.state.company.type == 'warehouse' ? $store.state.company.type == 'warehouse' && imprest_pom == '' && (type=='edit' ? addingMed : type=='create') : imprest_pom == 'imprest' && (type=='edit' ? addingMed : type=='create'))">
                                        <div class="rightsidediv">
                                            <div class="text-search-container search-container calenderdatelabel" style="width: 100%;display: flex;flex-direction: row">
                                                <div class="calendar-input-container">
                                                  <i class="pi pi-search"></i>
                                                    <Dropdown
                                                      id="prescription-med-search"
                                                      v-model="selectedInvoice"
                                                      ref="invoiceDropdown"
                                                      @keyup="searchInvoices()"
                                                      :loading="loadingInvoices"
                                                      :options="invoiceList"
                                                      optionLabel="full_name"
                                                      :editable="true"
                                                      style="width: 100%;display: flex;background:#E5F1FE;border:none !important" 
                                                      placeholder="Search Invoice..." 
                                                      @change="showMedDropdown()"
                                                    />
                                               </div>
<!-- 
                                               <p class="submit-button blue-button" style="display:flex; align-items:center;width:120px; justify-content: center;margin: 0px;margin-left:18px;"
                                            @click="() => {  selectInvoice() }">Add</p> -->

                                            </div>
                                            <div class="text-search-container search-container calenderdatelabel" style="width: 100%;display: flex;flex-direction: row;" v-if="!loadingInvoices && showMedDropdownFlag">
                                                <div class="calendar-input-container">
                                                  <i class="pi pi-search"></i>
                                                    <Dropdown
                                                      id="prescription-med-search"
                                                      v-model="selectedMedication"
                                                      ref="drugDropdown"
                                                      @keyup="searchMedication()"
                                                      @change="selectMedication()"
                                                      :loading="loadingMedication"
                                                      :options="medicationList"
                                                      optionLabel="medication"
                                                      :editable="true"
                                                      style="width: 100%;display: flex;background:#E5F1FE;border:none !important" placeholder="Search Medication..."
                                                    />
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="addingMed" style="height: 70%;overflow: auto;">
                                            <table class="selectedItemTable">
                                                        <tr v-for="(selectedItem, index) in selectedItems" :key="index" class="selectedItemname">
                                                                <td style="width: 50%;">
                                                                    {{ selectedItem && selectedItem.medication }}
                                                                </td>
                                                                <td><p style="margin: 0px;">Qty: {{  selectedItem.count == 0 ? selectedItem.count : selectedItem.count || selectedItem.quantity }}</p></td>
                                                                <td @click="removeDrug(index,selectedItem)" class="red-close-button small-button" style="margin: 0px;width: 40px"><i class="pi pi-times" style="padding: 0; padding-top: 3.5px;"></i></td>
                                                        </tr>
                                            </table>
                                    </div>
                                    <div class="modal-footer" style="position: absolute; right:12px; padding: 0px;height: max-content; bottom:0px">
                                        <!-- <div class="modal-footer" style="position: absolute;bottom: 0px;padding: 0px; right:12px; height: max-content;"> -->
                                            <slot name="footer" style="display: flex;">
                                                <p class="regular-button error-button alignButton" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                                                </p>
                                                <p :class="buttonchangeUi()" class="alignButton" @click="submitTransfer()">
                                                    <i class="pi pi-spin pi-spinner" style="padding: 0px;margin-right: 10px;font-size: 1.2rem !important;" v-if="loadingSubmit">
                                                    </i>Confirm Batch</p>
                                            </slot>
                                    </div>
                                     
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
import axios from 'axios';

export default {
    props: ['endpoint1'],
    data() {
        return {
            showMedDropdownFlag: false,
            originalFormData: {} ,
            type:'create',
            date:'',
            selectedItems: [],
            selectedItem: null,
            alreadyexist:[],
            displayReferencePrescriberModal: false,
            addingMed: false,
            imprest_pom: '',
            saveContext: {},
            pom: '',
            patient: '',
            selectedDrug:{},
            gp: '', 
            prescriberResults: [],
            addNew: false,
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            stock: '',
            qty: null,
            loadingBatch:false,
            searchedBatch:'',
            refinedBatches:[],
            batch:'',
            loadingDrugs:false,
            refinedDrugs: [],
            realSubmit: {},
            form: {
                submit: {
                    batch_uuid: '',
                    notes: '',
                    invoice_item_id: [],
                    batch_name:'',
                    batch_id:'',
                    expiry_date:''
                }
            },
            selectedInvoice: null,
            selectedMedication:null,
            itemSelected:false,
            loadingInvoices: false,
            invoiceList: [],
            medicationList:[],
            loadingMedication:false,
            timeout:null,
            loadingSubmit: false
        }
    },
    methods: {
    showMedDropdown() {
            this.showMedDropdownFlag = true;
            this.selectInvoice();
    },
    setType(type) {
        if (type === 'edit' && this.type != type) {
              this.form.submit = { ...this.originalFormData };
              this.form.submit.notes = null;
              this.form.submit.expiry_date = null;
              this.form.submit.batch_id = null;
              this.searchedBatch=null;
              this.selectedInvoice=null;
              this.selectedMedication=null;
              this.selectedItems=[];
              this.itemSelected=false;
              this.addingMed = false;
              this.showMedDropdownFlag = false;
          } else if (type === 'create' && this.type != type) {
              this.form.submit = { ...this.originalFormData };
              this.form.submit.batch_uuid = null;
              this.form.submit.notes = null;
              this.form.submit.invoice_item_id = [];
              this.form.submit.expiry_date = null;
              this.form.submit.batch_id = null;
              this.form.submit.batch_name=null;
              this.selectedInvoice='';
              this.selectedMedication='';
              this.selectedItems=[];
              this.itemSelected='';
              this.addingMed = false;
              this.showMedDropdownFlag = false;
         }
          this.type = type;
          if (Object.keys(this.originalFormData).length === 0) {
              this.originalFormData = { ...this.form.submit };
              console.log(this.originalFormData,"originalformdata");
          }
      },
        showBatches(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingBatch) {
                    clearInterval(interval);
                    this.$refs.batch.show();
                }
            }, 100)
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchInvoices : debounce (function() {
                const payload = {
                sort_direction: 'desc',
                col: 'created_at',
                facility_id: this.$store.state.currentLocation.uuid,
                page: 1,
                filters: {
                    status: ['completed '],
                    party: '',
                    drug: '',
                    date:{
                        start:'',
                        end:''
                    },
                    invoice_number:this.selectedInvoice,
                    show_active:true
                },

            }
            this.loadingInvoices = true;
                axios.post('/warehouse/retrieve-invoices', payload, this.$store.state.header)
                    .then(async response => {
                        response.data.data.forEach(item => {
                            item.full_name = `${item?.invoice_number} - ${item?.supplier_name} (${item?.delivery_date})`;
                        })
                        this.invoiceList = await response.data.data;
                        this.loadingInvoices = false;
                        this.$refs.invoiceDropdown.show();
                    })
                    .catch(error => {
                        console.error('Error fetching invoices', error);
                    })
        },500),

        searchMedication () {
            this.loadingMedication = true
            setTimeout(() => {
                this.$refs.drugDropdown.show();
            this.loadingMedication = false
            }, 500);
        },
        selectMedication () {
           const existItem = this.selectedItems.filter((item)=>{
                return (item?.medication) === this.selectedMedication?.medication

                // return (item?.invoice_item_uuid || item?.invoice_item_id) === this.selectedMedication?.invoice_item_uuid 
            })
            if (existItem?.length > 0) {
                this.errorMessage = "Duplicate medication selected"
                return ;
            }else{
                this.errorMessage = ''
                this.selectedItems.push(this.selectedMedication)
            }
            this.addingMed = true
            this.selectedMedication = null
        },
        selectInvoice(){
                if (this.selectedInvoice) {
                axios.post('/warehouse/retrieve-invoice-details', { invoice_id : this.selectedInvoice?.invoice_id
                }, this.$store.state.header)
                    .then(async response => {
                        const resdata = await response.data?.data
                        // this.selectedItems = []
                        this.selectedMedication = null
                        this.itemSelected = true
                        const existMedication = resdata[0].medications.filter((item)=> { 
                            return !item.exist 
                        })
                        console.log("medications>>",existMedication,resdata[0].medications);
                        this.medicationList = existMedication
                    })
                    .catch(error => {
                        console.error('Error fetching invoices', error);
                    })
            }
        },

        searchBatch : debounce(function() {
                if (this.searchedBatch) {
                this.loadingBatch = true;
                const payload = {
                sort_direction: "desc",
                col: "created_at",
                page: 1,
                filters: {
                    batch_name: this.searchedBatch,
                    created_date: '',
                    drug:'',
                    status: this.status ? [this.status] : [],
                    show_active:true
                        }
                };
                axios.post('/warehouse/retrieve-batches',payload, this.$store.state.header ).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedBatches = res.data.data;
                    if(this.refinedBatches.length){
                        this.$refs.batch.show();
                    }
                    this.loadingBatch = false;
                    
                }).catch(error => {
                    this.loadingBatch = false;
                    });
            }
        },500),
        batchSelectAndChange(){
                this.batchSelect(); 
                this.onBatchChange();
        },
        onBatchChange(){
               if (this.form.submit.batch_uuid) { 
                this.loadingBatch = true;
                this.addingMed = false
                    axios.post('/warehouse/retrieve-batch-details', {
                          batch_uuid: this.searchedBatch.uuid 
                    },this.$store.state.header)
                    .then(response => {
                    this.form.submit.notes = response.data.data[0].notes;
                    this.date = response.data.data[0].expiry_date;
                    if (response.data.data[0].expiry_date) {
                      const date = new Date(response.data.data[0].expiry_date);
                      this.form.submit.expiry_date = date
                    }
                    
                    this.form.submit.batch_id = response.data.data[0].batch_id;
                        if (this.selectedItems && this.selectedItems.length > 0) {
                          const Alldata = response.data.data[0].batch_items.map(batchItem =>  batchItem);
                          this.selectedItems = Alldata
                        } else {
                            this.selectedItems.push(...response.data.data[0].batch_items);
                        }
                    if (this.selectedItems?.length > 0) {
                        this.addingMed = true
                        this.loadingBatch = false;
                    }
                    })
                    .catch(error => {
                    console.error('Error fetching batch details', error);
                    this.loadingBatch = false;
                    });
                }
            },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displayReferencePrescriberModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        isSuccessful(value) {
            console.log('IS SUCCESSFUL IS BEING CALLEDs', value);
                this.form.submit.drugs = [];
                this.imprest_pom = '';
                this.patient = '';
                this.selectedPrescriber = '';
                this.stock = '';
        },
        closeModal() {
            console.log('hitting this closemodal functionsss');
            this.$emit('close', true);
        },
        batchSelect() {
                this.batch = this.searchedBatch.batch_name;
                this.form.submit.batch_uuid = this.searchedBatch.uuid;
        },
        removeDrug(index) {
            // console.log('this is the index', index, this.selectedItems);
            // this.form.submit.drugs.splice(index, 1);
            //  this.selectedItems.splice(index, 1);

            const removedItem = this.selectedItems[index];
            console.log('Removed Item:', removedItem);
            this.selectedItems.splice(index, 1);
            
            // this.medicationList.push({ medication: removedItem.medication, exist: false });


            // const isExist = this.medicationList.some(item => item.invoice_item_id === removedItem.invoice_item_id);
            const isExist = this.medicationList.some(item => item.medication === removedItem.medication);
            console.log(isExist,"isExist")
    
                if (isExist===false) {
                    // this.medicationList.push({ medication: removedItem.medication});
                    this.medicationList.push(removedItem);
                }          
        },
        buttonchangeUi() {
                if (this.type==='edit' && this.form.submit?.batch_id && this.searchedBatch && this.form.submit.expiry_date && this.selectedItems?.length > 0) {
                     return 'regular-button green-button'
                } else if(this.type==='create' && this.form.submit?.batch_id && this.form.submit?.batch_name  && this.form.submit.expiry_date && this.selectedItems?.length > 0) {
                     return 'regular-button green-button'
                }else{
                     return 'regular-button grey-button'
                }
        },
       async submitTransfer() {
              const existdrug = this.selectedItems.map(item => item?.invoice_item_id || item?.invoice_item_uuid);
              this.form.submit.invoice_item_id = existdrug
              if (this.form.submit.expiry_date) {
                  const date = new Date(this.form.submit.expiry_date).toLocaleDateString("en-au");
                  this.form.submit.expiry_date = date
              }
              if (this.type === 'edit') {
                  if (this.searchedBatch  && this.form.submit.expiry_date && this.form.submit?.batch_id && this.form.submit?.invoice_item_id?.length > 0) {
                      this.errorMessage = '';
                      this.loadingSubmit = true
                      console.log(this.form.submit, "form submit");
                      axios.post('/warehouse/create-update-batch', this.form.submit,  this.$store.state.header)
                          .then(async response => {
                              if (response?.data === 200 || response?.status === 200) {
                                  this.$emit('close', true,'success');
                                  this.loadingSubmit = false
                              }
                          })
                          .catch(error => {
                              console.log('Error create-batch', error);
                              this.loadingSubmit = false
                              this.errorMessage = error.response.data || error;
                          });
                  } else {
                      this.errorMessage = 'Select Batch, Drugs, Invoice Number, Date, and Notes are required.';
                  }
              } else if (this.type === 'create') {
                  if (this.form.submit?.batch_name && this.form.submit.expiry_date && this.form.submit?.invoice_item_id?.length > 0 && this.form.submit?.batch_id ) 
                  {
                      this.errorMessage = '';
                      this.loadingSubmit = true
                      console.log(this.form.submit, "form submit");
                      axios.post('/warehouse/create-update-batch', this.form.submit, this.$store.state.header)
                          .then(async response => {
                              if (response?.data === 200 || response?.status === 200) {
                                  this.$emit('close', true,'success');
                                  this.loadingSubmit = false
                              }
                          })
                          .catch(error => {
                              console.log('Error create-batch', error);
                              this.loadingSubmit = false
                              this.errorMessage = error.response.data || error;
                          });
                  }
                   else {
                      this.errorMessage = 'Batch Name, Drugs, Invoice Number, Date, and Notes are required.';
                  }
              }else{
                  this.errorMessage = 'Batch Id, Drugs, Invoice Number, Date and Notes are required.'
              }
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        aliasSelect(){
            this.addingMed=true;
             if (this.selectedDrugs && this.selectedDrugs.length > 0) {
                console.log("Adding selected drugs:", this.selectedDrugs);
                // Example: Adding selected drugs to another array
                this.selectedDrugs.forEach(drug => {
                this.addToDesiredArray(drug);
                this.loadingDrugs=false;
                });
                 this.addToDesiredArray(this.selectedItem);
            } else {
                console.log("No drugs selected.");
            }

        },
        addToDesiredArray(drug) {
            this.desiredArray.push(drug);
        },

        addingAliasToList(item) {
              this.addingMed = true;
              console.log('addingAliasToList function is running', item, this.$store.state.currentLocation.uuid);
              
              const aliasId = item.alias_id.toString(); // Convert alias_id to string for comparison
              const uuid = item.uuid.toString();
              
              if (this.imprest_pom === 'imprest') {
                  console.log('addingAliasToList -- IMPREST');
                  axios.post('/get-stock', { alias_id: aliasId ?? uuid, location_id: this.$store.state.currentLocation.uuid }, this.$store.state.header)
                      .then(res => {
                          const stock = res.data.alias.name;
                          const qtyLeft = res.data.qty_left;
                          
                          if (this.qty) {
                              console.log('this is the quantity if condition', res, this.qty, qtyLeft, this.qty > qtyLeft);
                              this.form.submit.drugs.unshift({
                                  tags: item.tags,
                                  stock: stock,
                                  form: item.form,
                                  drug: aliasId,
                                  qty_left: qtyLeft,
                                  qty: this.qty
                              });
                          }
                          this.$store.dispatch('getRefreshToken');
                          this.qty = null;
                          this.refinedDrugs = [];
                          this.noStock = false;
                          this.selectedDrug = {};
                          this.addingMed = false;
                      })
                      .catch(err => {
                          console.log('err is', err);
                          this.form.submit.drugs.unshift({
                              tags: item.tags,
                              stock: item.name,
                              form: item.form,
                              drug: aliasId,
                              qty: this.qty,
                              qty_left: 0
                          });
                          this.qty = null;
                          this.refinedDrugs = [];
                          this.selectedDrug = {};
                          this.addingMed = false;
                      });
              } else {
                  console.log('addingAliasToList -- ROM');
                  axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header)
                      .then(res => {
                          console.log('this is the quantity receive condition', res, item, this.qty);
                          if (res.data.length > 0) {
                              for (let i = 0; i < res.data.length; i++) {
                                  if (this.qty) {
                                      if (res.data[i].alias.alias_id === aliasId) {
                                          console.log('Alias to select');
                                          if (this.form.submit.drugs.some(drug => drug.drug === aliasId)) {
                                              this.form.submit.drugs.find(drug => drug.drug === aliasId).qty = this.qty;
                                              break;
                                          } else {
                                              this.form.submit.drugs.unshift({
                                                  tags: res.data[i].alias.tags,
                                                  stock: res.data[i].alias.name,
                                                  form: res.data[i].alias.measurement_unit,
                                                  drug: aliasId,
                                                  qty_left: res.data[i].quantity,
                                                  qty: this.qty
                                              });
                                              break;
                                          }
                                      }
                                  }
                              }
                          } else {
                              console.log('No drugs in array, hitting else condition here');
                              if (this.qty) {
                                  this.form.submit.drugs.unshift({
                                      tags: item.tags,
                                      stock: item.name,
                                      form: item.form,
                                      drug: aliasId,
                                      qty: this.qty,
                                      qty_left: 0
                                  });
                              }
                          }
                          this.$store.dispatch('getRefreshToken');
                          this.noStock = false;
                          this.qty = null;
                          this.refinedDrugs = [];
                          this.selectedDrug = {};
                          this.addingMed = false;
                      })
                      .catch(err => {
                          console.log('err is', err);
                          if (err.response?.data === 'No stock has been recorded on this location for that drug alias.') {
                              console.log('Hitting the if condition');
                              if (this.qty) {
                                  this.form.submit.drugs.unshift({
                                      tags: item.tags,
                                      stock: item.name,
                                      form: item.form,
                                      drug: aliasId,
                                      qty: this.qty,
                                      qty_left: 0
                                  });
                              }
                          }
                          this.qty = null;
                          this.refinedDrugs = [];
                          this.selectedDrug = {};
                          this.addingMed = false;
                      });
              }
          },
    },
    mounted() {
       
    },
    watch: {
        date(newValue) {
            if (newValue !== '' && newValue !== null) {
                const date = new Date(newValue);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const formattedDate = `${day}/${month}/${year}`;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.pendingdestruction {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important; 
    padding: 5px 20px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0;
    text-align: center;
    color: white;
    font-weight: bold;
    border:2px solid #0870DC;
    color:#0870DC;
    font-size: 16px;
}
.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}
.batch_label{
    margin-left: 20px;
    font-weight: 600;
    display: flex;
    font-size: 14px;
   
}
.right-form-section-panel {
    padding: 0 20px;
}
.drug-entry{
    background-color: #F3F4F4;
    margin-bottom: 10px;
    padding: 0px 14px;
    border-radius: 8px;
    width:60%;
    max-width: 100%;
    overflow: hidden;
 
}

.calendar-input-container{
    background-color: #E5F1FE ;
    border: 1px solid #0870DC ;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
.calendar-input-container>i{
    color:#0870DC ;
  }
.rightsidediv{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.form-section-container{
    width: 100%;
}
.note-header{
    border-radius: 8px 8px 0px 0px;
}
.selectedItemname{
    background-color:#F3F4F4;
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    min-width: 100%;
    min-height: 35px;
    padding: 8px 15px;
    border-radius: 8px;
    height:max-content;
    word-break: break-word
}
.selectedItemnameReverse{
    background-color:#F3F4F4;
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    border-radius: 8px;
    min-height: 35px;
    padding: 8px 15px;
    height:max-content;
    word-break: break-word
}
input {
    height: auto !important
  }
.selectedItemname>p, .selectedItemnameReverse>p , .selectedItemnameReverse>div>p{
margin: 0px;
}
.selectedItemTable{
    border-collapse: collapse;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.modal-header{
    padding: 0px 35px !important;
    padding-top: 15px !important
}
.adjustleftsidediv{
    display: flex;
    flex-direction: column;
    gap: 10px
}
#prescription-med-search{
    height: -webkit-fill-available !important;
  
}
#prescription-med-search .p-dropdown-item {
    max-width: 100% !important;
}
.alignButton{
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 35px;
}
input::placeholder{
    font-size: medium !important;
}
</style>