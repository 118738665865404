<template>
    <div class="administration-round-list-container">
        <div class="administration-search-filters" v-if="!prnMode && !nimMode && !patchMode">
            <div style="display: flex; gap: 5px; align-items: center;" class="administrations-search">
                <div class="text-search-container search-container" >
                    <InputText v-model="searchTerm" style="width: 15em; background-color: white; opacity: 1" placeholder="Resident name" v-on:keyup.enter="searchForResident(false)"/>
                </div>
                <div>
                    <i class="pi pi-search" style="cursor:pointer" @click="!loadingAdministration && searchForResident(false)"></i>
                </div>
                <div>
                    <i class="pi pi-times" style="cursor:pointer" @click="!loadingAdministration && searchForResident(true)"></i>
                </div>
            </div>
        </div>
        <div class="administration-round-results" ref="scrollContainer" >
            <div v-if="!searchResult">
                <div
                    v-for="(item, index) in patients"
                    :key="index"
                    class="administration-round-item item-spacing"
                    
                    :class="item.selected ? 'selected-round-item':''"
                    @click="item.name ? choosePatient(item, index) : ''"
                >
                    <!-- <div style="display: flex; align-items: center;"> -->
                    <div style="display: flex;">
                        <img class="resident-image smaller admin" onerror="this.onerror=null; this.className='resident-image-default resident-image smaller admin'" v-if="item.image_information && item.image_information.url" :src="item.image_information.url" />
                        <div v-else>
                            <img class="resident-image-default resident-image smaller admin" >
                        </div>
                        <div>
                            <div style="margin-left: 10px;"  v-if="item.name">
                                <h4 style="margin: 0">{{item.name}}</h4>
                                <h4 style="margin: 0; color: #0870DC" v-if="item.location">{{item.location}}</h4>
                                <p  v-if="item.completed" class="green-button" style="margin-left: 10px; width: 8em; margin-bottom: 5px;">
                                    Completed
                                </p>
                                <p v-if="prnMode && item.prns"
                                    style="margin: 0; font-size: 1em; background-color: rgb(0, 178, 162); color: white; padding: 5px 10px; border-radius: 6px;">
                                    <b>{{item.prns.length}}</b> PRNs available
                                </p>
                                <p v-if="nimMode && item.nims"
                                    style="margin: 0; font-size: 1em; background-color: #274188; color: white; padding: 5px 10px; border-radius: 6px;">
                                    <b>{{item.nims.length}}</b> NIMs available</p>
                                <p v-if="patchMode && item.patches"
                                    style="margin: 0; font-size: 1em; background-color: rgb(215, 135, 0); color: white; padding: 5px 10px; border-radius: 6px;">
                                    <b>{{item.patches.length}}</b> Patch{{item.patches.length > 1 ? 'es':''}} available
                                </p>
                                <p v-if="item.has_expired_charts && (!nimMode && !patchMode)" class="red-button" style="margin-top: 3px; width:12em">
                                    Has Expired Meds
                                </p>
                            </div>
                            
                            <h4 style="margin-left: 10px; filter:blur(7px)" v-else>Retrieving name</h4>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="administration-round-item item-spacing selected-round-item">
                    <!-- <div style="display: flex; align-items: center;"> -->
                    <div style="display: flex;">
                        <h4 style="color: #0870DC; padding-right: 10px;">{{searchResult.facility.name}}.{{searchResult.location.name}}</h4>
                        <h4>{{searchResult.name}}</h4>
                    </div>
                    <img :src="searchResult.image" style="width: 120px; border-radius: 8px;"/>
                </div>
            </div>

            <div  v-if="loadingAdministration"  style="margin-top: 1em; padding-bottom: 2em;">
                <p style="margin: 0; font-size: 1em;">Loading residents for administration...</p>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
            </div>
        </div>
        <div v-if="currentPage < page_count">
            <p style="font-size: 1em;">Scroll down to view more residents</p>
            <p><span class="pi pi-chevron-down"></span></p>
        </div>
    </div>
</template>

<script>

export default{
    props:['patients', 'loadingAdministration', 'prnMode', 'nimMode', 'patchMode', 'loadingResidents', 'page_count','currentPage'],
    data(){
        return{
            filters:{
                name:''
            },
            searchTerm:'',
            searchResult:'',
            lazyItems: null,
            loadLazyTimeout: null,
        }
    },
    methods:{
        choosePatient(patient){
            this.$emit('displaySelection', patient.uuid);
            this.$emit('selectPatient', patient);
        },
        clearFilters(){
            this.searchTerm = '';
        },
        updateList(){
            console.log('will send request with this payload', this.filters);
        },
        loadMoreData() {
            console.log('requesting more data');
            this.$emit('requestNextChartRoundPage', {page: this.currentPage+1, patient: this.searchTerm});
        },
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if ((container.scrollTop + container.clientHeight >= container.scrollHeight - 50) && this.currentPage+1 <= this.page_count && !this.loadingResidents) {
                
                if (this.timeout)
                    clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    this.loadMoreData();
                }, 1500); // delay
            }
        },
        searchForResident(reset){
            if (reset) {
                this.searchTerm = ""
                this.$emit('requestCharts', {page: 1, patient: this.searchTerm});
            } else if(this.searchTerm != "") {
                console.log('searching for this resident', this.searchTerm);
                this.$emit('requestCharts', {page: 1, patient: this.searchTerm});
            }
        }
    },
    mounted() {
        // Attach the scroll event listener to the specified element
        this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // Remove the scroll event listener when the component is destroyed
        console.log('unmounting the event listener');
        this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
    }
}
</script>

<style lang="scss">
    .administration-round-list-container{
        background-color: #f3f4f4;
        border-radius: 8px;
        // height: fit-content;
        height: 75vh;
    }
    .administration-search-filters{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        
    }
    .administration-round-results{
        height: 50vh;
        overflow-y: auto;
    }

    .administration-round-item{
        
        padding: 10px 5px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #f3f4f4;
        h4{
            text-align: left;
        }
    }

    .selected-round-item{
        background-color: rgba(8, 112, 220, 0.2);
    }
</style>