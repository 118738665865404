<template>
    <div class="drug-register-view view">
        <div class="panel" style="width: 100%;">
            <div style="display:flex;justify-content:space-between;width: 98%">
             <h3 class="panel-header">Suppliers Profile</h3>
              <div style="background:white;border-radius:8px 8px 0px 0px;width: 180px">
                <h3 class="panel-header" style="padding:10px;text-align:center;font-size:16px;color: #0870dc">Supplier Info</h3>
              </div>
            </div>
            <div class="drug-register-table table-container" style="display: flex;flex-direction: column;width: 98%;overflow: auto;border-top-right-radius: 0px;">
                <h3 class="panel-header" style="color: #0870dc;padding: 0px; margin-bottom: 10px;">{{supplier_name}}</h3>
                <div class="mainleftrightdiv">
                    <div style="display:flex;width: 100%;gap: 20px;height: 100%;">
                    <!-- <div class="clientInfo">
                        <div class="clientImage">
                            <img :src="require('@/assets/images/avatarIcon.png')" alt="img" class="profileInfoImage" />
                        </div>
                        <div class="clientBtn">
                            <Button class="button types-button" type="button" label="Upload Image"
                                icon="pi pi-upload"></Button>
                            <Button class="button types-button" type="button" label="Take New Image"
                                icon="pi pi-camera"></Button>
                        </div>
                        <div class="leftinfoaccordian">
                            <div class="settings-directory" style="height: max-content;">
                                <div v-for="(item, index) in directory" :key="index">
                                    <h4 class="settings-header" :class="item.open ? 'active-settings-header' : ''"
                                        @click="item.open = !item.open">
                                        {{ item.name }}
                                        <span>
                                            <i class="pi pi-chevron-down" v-if="item.open"></i>
                                            <i class="pi pi-chevron-right" v-else></i>
                                        </span>
                                    </h4>
                                </div>

                            </div>
                        </div>
                    </div> -->
        <!-- pharmacy Information -->
                     <div class="clientInfo righsideinfo" v-if="!loading">
                        <div style="margin-top:-25px">
                            <div style="display:flex;justify-content:space-between">
                                <h3 class="panel-header" style="margin-bottom:23px;padding: 0px;margin-left: 10px;">Pharmacy Information</h3>
                            <div style="display:flex">  
                                <!-- <div class="icons">
                                            <i class="pi pi-print" style="font-size:10px !important" ></i>
                                </div>
                                <div class="icons" style="margin-left:10px">
                                            <i class="pi pi-upload" style="font-size:10px !important;color:#0d89ec"></i>
                                </div> -->
                            </div>
                            </div>
                            <div class="detailsleftside">
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:10px">
                                    <label for="client">Pharmacy Name</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="supplier_name" placeholder="Enter a Pharmacy Name..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px">
                                    <!-- City and location data property are one and the same in the FE -->
                                    <label for="client">City</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="location" placeholder="Enter a Location..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px">
                                    <label for="client">Pharmacy Phone </label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="number" placeholder="Enter a Pharmacy Phone ..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                            
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:10px">
                                    <label for="client">Pharmacy Email</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="email" placeholder="Enter a Pharmacy Name..." style="position: relative;cursor:default; width: 100%;" class="input-width" readonly/>
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px">
                                    <label for="client">Address</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="address" placeholder="Enter a Location..." style="position: relative;cursor:default; width: 100%;" class="input-width" readonly />
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px">
                                    <label for="client">Primary Contact </label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="contact" placeholder="Enter a Pharmacy Phone ..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:10px">
                                    <label for="client">Primary Contact Phone</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="primary_phone" placeholder="Enter a Pharmacy Name..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px">
                                    <label for="client">Primary Contact Email</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="primary_email" placeholder="Enter a Location..." style="position: relative;cursor:default; width:100%;" class="input-width" readonly/>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <!-- company details -->
                            <div>
                                <h3 style="text-align: left; margin:0px;margin-left: 10px;">Company Details</h3>
                                <div class="detailsleftside">
                                <div class="text-search-container search-container calenderdatelabel" style="margin-left:10px;margin-top:23px">
                                    <label for="client">Company</label>
                                    <div class="calendar-input-container" style="border:none">
                                        <input id="client" v-model="cmp_name" placeholder="Enter a Compnay Name..." style="position: relative;cursor:default" class="input-width" readonly/>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <!-- billing details -->
                             <div>
                                <h3 style="text-align: left; margin:0px;margin-left: 10px;">Billing Details</h3>
                                <div class="detailsleftside">
                                    <div class="text-search-container search-container calenderdatelabel" style="margin-left:10px;margin-top:23px">
                                        <label for="client">Billing Address</label>
                                        <div class="calendar-input-container" style="border:none">
                                            <input id="client" v-model="billing_add" placeholder="Enter a Compnay Name..." style="position: relative;cursor:default; width:100%;" class="input-width" readonly/>
                                        </div>
                                    </div>
                                    
                                    <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px;margin-top:23px">
                                        <label for="client">Postcode</label>
                                        <div class="calendar-input-container" style="border:none">
                                            <input id="client" v-model="billing_code" placeholder="Enter a Compnay Name..." style="position: relative;cursor:default" class="input-width" readonly/>
                                        </div>
                                    </div>

                                    <div class="text-search-container search-container calenderdatelabel" style="margin-left:20px;margin-top:23px">
                                        <label for="client">State</label>
                                        <div class="calendar-input-container" style="border:none">
                                            <input id="client" v-model="state" placeholder="Enter a State..." style="position: relative;cursor:default" class="input-width" readonly/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                       
                    </div>
                    <div v-if="loading">
                        <i class="pi pi-spin pi-spinner" style="position: fixed; left: 65%; font-size: 3rem !important;margin-top: 4em; "></i>
                    </div>
                   
                    </div>  
                </div>
                 
                
            </div>
            
        </div>
    </div>
</template>


<script>
import axios from 'axios';


export default {
    components: {
    },
    data() {
        return {
            directory:[{open:false,name:'Pharmacy Information'},{open:false,name:'Account Details'},{open:false,name:'Receipt History'},{open:false,name:'Return History'},{open:false,name:'Payment History'}],       
            supplier_name:'-',
            location:'-',
            number:'-',
            email:'-',
            address:'-',
            contact:'-',
            primary_phone:'-',
            primary_email:'-',
            cmp_name:'-',
            billing_add:'-',
            billing_code:'-',
            selectedStatus:null,
            statusOptions:[],
            state:'-',
            loading: false
        }
    },
    methods: {
        async getSupplierData(uuid) {
            this.loading = true;
            try {
                const response = await axios.post(`/warehouse/location/${uuid}`, { drug: '' },this.$store.state.header);
                return response.data.data;
            } catch (error) {
                console.error('Error fetching supplier data:', error);
                throw error;
            }
        },
        async fetchData() {
                const data = await this.getSupplierData(this.$route.params.uuid);
                this.supplier_name = data?.facility?.name || '-';
                this.location =( data?.facility?.address?.city ?? ' ') + ' ' + (data?.facility?.address?.state ?? '')|| '-';
                this.number = data?.facility?.address?.number || '-';
                this.email = data?.facility?.email?.data || '-';
                this.address = (data?.facility?.address?.street_address ?? '') + ' ' + (data?.facility?.address?.suburb ?? '') + ' ' + (data?.facility?.address?.postcode ?? '') + ' ' + (data?.facility?.address?.country ?? '') || '-';
                this.contact = data?.facility?.phone?.name || '-';
                this.primary_phone = data?.facility?.phone?.data || '-';
                this.primary_email = data?.facility?.email?.data || '-';
                this.cmp_name = data?.facility?.name || '-'; 
                this.billing_add = (data?.facility?.address?.street_address ?? '') + ' ' + (data?.facility?.address?.suburb ?? '') + ' ' + (data?.facility?.address?.postcode ?? '') + ' ' + (data?.facility?.address?.country ?? '') || '-';
                this.billing_code = data?.facility?.address?.postcode || '-';
                this.state =  data?.facility?.address?.state || '-';
        },



    },
    async mounted() {
        try {
            await this.fetchData();
        } catch (error) {
            console.error('Error in mounted hook:', error);
        } finally {
            this.loading = false;
        }
       
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>
.clientImage {
    min-height: 335px;
    height: 335px;
    width: 400px;
    background: #F3F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid #c5c5c573;
}

.clientInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.clientBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.clientBtn>button {
    padding: 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.types-button {
    font-weight: 600 !important;
    font-size: 14px !important;
}
/* .input-width{
    width:27.6em;
    max-width: 100%;
} */
.icons{
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    justify-content: di;    
    align-items: center;
    color: #0d89ec;
    border-radius: 100%;
    border: 2px solid #0d89ec;
    cursor:pointer
}

.detailsleftside>.calenderdatelabel>.calendar-input-container{
    width: 100%;
}
.detailsleftside{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px 12px;

}
.righsideinfo {
    width: 100%;
    display: 'flex';
    gap: 55px
}
.mainleftrightdiv{
    display: flex;
    gap: 20px; 
    align-items: flex-start;
    margin-top: 20px;
    height: 100%
}
.leftinfoaccordian{
    width: 100%;
    background-color: #F3F4F4;
    border-radius: 8px;
    height: 100%
}
</style>