<template>
            <div class="active-chart-table-container" style="display: flex; flex-wrap: wrap; margin-left: 0; background-color: white; margin-left: 1em; border-radius: 8px; overflow-y: auto">
                <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-left: 0; width: 100%; border-radius: 8px;" :style="loadingPatient || !patient ? {backgroundColor: '#f3f4f4'}:{}">
                 <div v-if="!loadingPatient && patient && patient.personal_information && patient.regulatory_information" style="width: 100%">
                   <div class="admin-chart-form-container">
                   <div class="admin-chart-form" >
                        <!-- PATIENT SUMMARY HEADER -->
                        <!-- GENERAL INFO -->
                        <div class="general-patient"
                            style="grid-area: initial; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: grid; grid-template-columns: 1fr 4fr; grid-gap: 10px;">
                            <div>
                                <router-link :to="'/patient/' + patient.uuid" style="text-decoration: none; color: #0870DC">
                                    <h2 class="selected-resident-name" style="padding-top: 0px; margin: 0; color: #0870DC; font-size: 1.2em;">
                                        {{ patient.personal_information.first_name }} {{ patient.personal_information.last_name }}
                                    </h2>
                                </router-link>
                                <div>
                                </div>
                                <img
                                    onerror="this.onerror=null; this.className='resident-image-default resident-image large admin'"
                                    class="resident-image large admin"
                                    v-if="patient.image_information && patient.image_information.url"
                                    :src="patient.image_information.url"
                                    alt="Resident Image"
                                    style="margin-bottom: 10px !important"
                                >
                                <img v-else class="resident-image-default resident-image large admin" style="margin-bottom: 10px !important">
                                
                                <div>
                                    <div style="border: 2px solid #E04F39; border-radius: 8px;">
                                        <!-- PATIENT INFO/ADR TAB SELECT -->
                                        <div style="display: flex; justify-content: space-around; align-items: center; border-bottom: 2px solid #E04F39;">
                                            <h4
                                                style="cursor: pointer; width: 100%; margin: 0; padding: 5px 0; width: 50%;"
                                                @click="infoADRTab = 'INFO'"
                                                :style="infoADRTab == 'INFO' ? { backgroundColor: '#E04F39', color: 'white', borderTopLeftRadius: '4px' } : {}"
                                            >
                                                INFO
                                            </h4>
                                            <h4
                                                style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0;"
                                                @click="infoADRTab = 'ADR'"
                                                :style="infoADRTab == 'ADR' ? { backgroundColor: '#E04F39', color: 'white', borderTopRightRadius: '4px' } : {}"
                                            >
                                                ADR
                                            </h4>
                                        </div>
                                        <!-- PATIENT STICKERS -->
                                        <div style="height: 200px; overflow: auto;" v-if="infoADRTab == 'ADR'">
                                            <table style="padding: 0 10px; border-collapse: collapse;" v-if="patient.adr && patient.adr.length">
                                                <tr>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Drug
                                                    </th>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Year
                                                    </th>
                                                </tr>
                                                <tr v-for="(item, index) in patient.adr" :key="index">
                                                    <td style="padding: 0 10px;">
                                                        {{ item.drug ? item.drug.toUpperCase() : item.custom ? item.custom.toUpperCase() : 'Non Spec.'}}
                                                    </td>
                                                    <td style="padding: 0 10px;">
                                                        {{ item.date ? item.date.split(',')[1] : '----' }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- PATIENT INFO -->
                                        <div style="height: 200px; text-align: left; overflow-x: auto; background-color: white !important;border-radius: 8px"
                                            v-if="infoADRTab == 'INFO'">
                                            <div>
                                                 <div class="general-patient-info">
                                                    <p class="general-patient-info-key">DOB</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.dob }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Age</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.age }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Sex</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.sex }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Preferred Name</p>
                                                    <p class="general-patient-info-value">
                                                        {{ patient.personal_information.preferred_name }}</p>
                                                </div>
                                                <div class="general-patient-info" v-for="(item, index) in patient.contact_methods"
                                                    :key="index">
                                                    <p class="general-patient-info-key">{{ item.type }}</p>
                                                    <p class="general-patient-info-value">{{ item.data }}</p>
                                                </div>
                                                <div class="general-patient-info"
                                                    v-for="(item, index) in patient.regulatory_information" :key="index">
                                                    <p class="general-patient-info-key">{{ item.regulatory_type }}</p>
                                                    <p class="general-patient-info-value">{{ item.data }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SPECIAL CONSID.-->
                                    <div>
                                        <div v-if="spec_cons && $store.state.user.patient_admin_active">
                                            <div
                                                v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                                style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 200px;"
                                            >
                                                <p
                                                    class="field-value"
                                                    v-for="(item, index) in uniqueSpecCons" :key="index"
                                                    style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 0px; margin-right: 5px;"
                                                >
                                                    {{item.name.toUpperCase() }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END SPECIAL CONSID.  -->
                                </div>
                            </div>
                            <!-- <hr style="border:none; border-left:2px solid #e8e8e8; height:90%; width:2px; align-self: center"> -->
                            <!-- PATIENT ADMINISTRATION COMPONENTS -->
                        <div>
                            
                           <!-- NON PACKED MEDICATIONS -->
                           <div class="medication-group" v-if="getNonPacked.length">
                                <div class="dark-grey-background medication-group-header" 
                                style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">Non-Drug Safe Patches</h2>
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="non-packed-medication-row" v-for="(drug, i) in getNonPacked" :key="i">
                                        <td v-if="mimsApiKey" style="width: 10em">
                                            <MimsImage
                                                :alias_id="drug.alias ? drug.alias.alias_id : ''"
                                                off_market="true"
                                                width="90px"
                                                height="60px">
                                            </MimsImage>
                                        </td>
                                        <td style="paddng: 0 10px"><p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}
                                        <i> - Last administration --{{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[drug.last_chart_administrations.length -1].administered_at:'Never administered.' }}</i></p>
                                        <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                            <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                            
                                            <div style="display: flex; flex-wrap: wrap">
                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                    <p class="consideration-tag" style="width: fit-content" v-if="tag.toUpperCase() != 'S4' && tag.toUpperCase() != 'S8' && tag.toUpperCase() != 'S4VIC'" >{{tag.toUpperCase()}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 7em;">{{drug.route}}</td>
                                       <td v-if="!drug.completed" style="width: 1em">
                                            
                                            <div><i class="pi pi-check" :id="drug.chart_id+ '-approve'" @click="displayPatchAdminModal = true, selectMedLoad(drug, true), selectedPatch = drug"></i></div>
                                            
                                        </td>
                                        <td v-if="!drug.completed" @click="displayDOCModal = true, selectDOCLoad(drug)"  style="width: 1em">
                                            <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                        </td>
                                        <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; flex-direction: column;">
                                            <div
                                                v-if="!drug.doc"
                                                class="administered"
                                                style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px;">
                                                <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                            </div>
                                            <div
                                                v-if="drug.doc"
                                                class="not-administered" 
                                                style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px; padding: 5px 10px;">
                                                <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                            </div>
                                            <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                            <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                        </td>
                                        <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                            <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                        </td>
                                        <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                            <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                        </td>
                                        <td style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <!-- S8/S4D MEDICATIONS -->
                            <div class="medication-group" v-if="getS8.length && !patient.displayPRN && !patient.displayNIM && !patient.displayPatch">
                                <div class="red-background medication-group-header" 
                                style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">Drug Safe Patches</h2>
                                    <!-- <div style="display: flex; align-items: center" v-if="removeFromSafe.length">
                                        <h4 style="margin: 0;margin-left: 3.3em; color: white;">Remove from safe</h4>
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" @click="mapRemoveFromRegisterPayload()" :payload="removeFromRegisterPayload" 
                                            endpoint="/drug-safe-retrieval" :drugSafeRemoval="true"
                                            :dual="true" :notes="true" />
                                    </div> -->
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="scheduled-medication-row" v-for="(drug, i) in getS8" :key="i">
                                        <td v-if="mimsApiKey" style="width: 10em">
                                            <MimsImage
                                                :alias_id="drug.alias ? drug.alias.alias_id : ''"
                                                off_market="true"
                                                width="90px"
                                                height="60px">
                                            </MimsImage>
                                        </td>
                                        <td style="paddng: 0 10px;"><p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}} <i>({{drug.stock_items && drug.stock_items.length && drug.stock_items[0].quantity > 0 ? 'Stock may be available' : 'No stock available'}})</i>
                                            <i> - Last administration --{{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[drug.last_chart_administrations.length -1].administered_at:'Never administered.' }}</i></p>
                                            <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                            <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                            <div style="display: flex; flex-wrap: wrap">
                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                    <p class="consideration-tag" style="width: fit-content" v-if="tag.toUpperCase() != 'S4' && tag.toUpperCase() != 'S8' && tag.toUpperCase() != 'S4VIC'" >{{tag.toUpperCase()}}</p>
                                                </div>
                                            </div>
                                            <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                        </td>
                                        <td style="font-weight:bold; width: 5em">x {{drug.dose}}</td>
                                        <td style="font-weight:bold; ;" :style="drug.completed ? { width: '5em' } : { width: '10em' }">{{ drug.route }}</td>
                                        <td v-if="!drug.completed && drug.removed"  style="width: 1em">
                                            <div><i class="pi pi-check" :id="drug.chart_id+ '-approve'" @click="displayPatchAdminModal = true, selectMedLoad(drug, true), selectedPatch = drug"></i></div>
                                        </td>
                                        
                                        <!-- <td v-if="!drug.removed && !drug.completed" style="width: 3em">
                                        </td> -->
                                        <td v-if="!drug.removed && !drug.completed" @click="()=>{removeDrugFromRegister(drug)}"  style="width: 1em">
                                            <!-- <div ><i v-tooltip="'Select to remove from safe'" class="pi pi-eject" :style="drug.selectedForRemoval ? {backgroundColor: '#00AD50', color: 'white'}:{}"></i></div> -->
                                            <SingleVerify  v-if="drug.stock_items && drug.stock_items.length && drug.stock_items[0].quantity > 0" 
                                                    @getDrugId="getDrugId" 
                                                    :payload="removeFromRegisterPayload" 
                                                    :stock_items="drug.stock_items"
                                                    endpoint="/drug-safe-retrieval" 
                                                    :drugSafeRemoval="true"
                                                    :dual="true" 
                                                    :notes="true" 
                                                    :id="drug.uuid" />
                                        </td>
                                        <td v-if="!drug.completed" @click="displayDOCModal = true, selectDOCLoad(drug)" style="width: 1em">
                                            <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                        </td>
                                        
                                        <td v-if="(drug.completed || drug.doc) && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; justify-content: end; align-items: center; margin-top: 16px;">
                                            <div v-if="!drug.doc" class="administered" 
                                                style=" display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 6em;
                                                text-align: center;
                                                padding-right: 10px;
                                                border-radius: 6px;">
                                                <!-- <i class="pi pi-check administered"></i>  -->
                                                <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                            </div>
                                            <div v-if="drug.doc" class="not-administered" 
                                                style=" display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 6em;
                                                text-align: center;
                                                padding-right: 10px;
                                                border-radius: 6px;
                                                padding: 5px 10px;">
                                                <!-- <i class="pi pi-times not-administered"></i>  -->
                                                <p style=" text-align: center; white-space: nowrap">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                            </div>
                                            <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                            <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                        </td>
                                        <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                            <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                        </td>
                                        <td v-if="drug.doc && !drug.removed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                                <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                            </td>
                                        <!-- <td style="width:60px" v-if="!drug.removed">
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" @click="submitPrimeSignature(drug, 'approve')" :indices="i" :payload="submitPayload" 
                                            endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                            :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                            'Resident Refused - Please notify prescriber': drug.status == 'W' ?
                                            'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                            :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" notes=true />
                                        </td> -->
                                        <td style="width: 0em"></td>
                                        </tr>
                                    </table>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        </div>
                      
                   </div>
                   
               </div>
                    
               </div>
               <div v-else style="margin: 0 auto; padding-top: 5em; display: flex; align-items: center;">
                    <i class="pi pi-spin pi-spinner" v-if="loadingPatient" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    <!-- <div v-else style="text-align: center;">
                        <h2 style="color: black; margin: 0;" v-if="!loadingAdministration">{{noCharts ? 'No residents are due for administration at this location.' : 'No Location Selected'}}</h2>
                        <p style="font-size: 1.2em; margin: 0;" v-if="!loadingAdministration">Please select {{noCharts ? 'another':'a'}} location to view residents due for administration.</p>
                    </div> -->
                    <div v-if="loadingAdministration">
                        <h3 style="color: black; margin: 0;" >Waiting for patients to be retrieved from location/s.</h3>
                        <p style="font-size: 1.2em; margin: 0;">Please wait...</p>
                    </div>
                    
               </div>
              </div>
              
              <DOCModal v-if="displayDOCModal" @close="close" :adminRound="false" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" :loop="loop" :administerType="'patch'" />
              <!-- @submitIsCompleted="submitIsCompleted"  -->
              <PatchAdminModal :adminSubmission="submitPayload" @done="done" :adminRound="false" @close="close" v-if="displayPatchAdminModal" 
              :selectedPatch="selectedPatch" :patientId="patient && patient.uuid ? patient.uuid : ''"  />
            </div>

</template>

<script>
import axios from 'axios';
import DOCModal from '@/components/modals/DOCModal.vue';
import PatchAdminModal from '@/components/modals/PatchAdminModal.vue';
import MimsImage from '../mims/MimsImage.vue';
import SingleVerify from '@/components/verification/SingleVerify.vue';

export default{
    props:['spec_cons', 'adminChart', 'patient', 'loadingPatient', 'loadingAdministration', 'prns', 'loadingPRNs', 'prnMode', 'hiddenTags'],
    components:{
        DOCModal,
        PatchAdminModal,
        SingleVerify,
        MimsImage
    },
    data(){
        return{
            displayPatchAdminModal: false,
            mimsApiKey: localStorage.getItem('mimsApiKey'),
            loop:false,
            displayDOCModal: false,
            infoADRTab: 'INFO',
            removeFromSafe:[],
            selectedPatch:'',
            // removeFromRegisterPayload is the MAPPED payload of items within the removeFromSafe
            removeFromRegisterPayload:[],
            doseOmittedCode:[
              'A',
              'F',
              'H',
              'L',
              'S',
              'S/A',
              'V',
              'A/T',
              'C',
              'N',
              'N/R',
              'O',
              'R',
              'W',
              'W/R'
            ],
            packedPayload:[],
            submitPayload: {},
            requestedMeds: [],
            redoingAdministration: {}
        }
    },
    methods:{
        resupplyDrug(drug){
            console.log('this is the drug requesting to be resupplied', drug);
            drug.loadingSupply = true;
            
            axios.post('resupply/create', {prescription_id: drug.prescription_id}, this.$store.state.header).then(res=>{
                console.log('this is the res', res.data);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
            }).catch(err=>{
                console.log('this is the resupply error', err);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
                drug.alreadyRequested = true;
            })
        },
        done(value){
            if(value){
                this.selectedPatch.completed = true;
                console.log('this is marked done', this.selectedPatch);
                this.selectedPatch.drug_safe_retrieval = null;
                this.selectedPatch.removed = false;
                if(this.selectedPatch.stock_items && this.selectedPatch.stock_items.length){
                    this.selectedPatch.stock_items.forEach(item=>{
                        if(item.drug_safe_retrieval){
                            this.localStorage.removeItem('DRET'+item.uuid)
                        }
                    })
                }
                

            }
        },
        addEffective(effective, drug){
            this.$emit('submitEffective', {
                effective: effective,
                prn_id: drug.administration_id
            })
        },
        close(value){
            if(value){
                this.displayDOCModal = false;
                this.displayPatchAdminModal = false;
            }
        },
        retrievePRN(){
            this.$emit('retrievePRNs', true);
        },
        selectPRN(drug){
            this.selectedPRN = drug;
            this.selectMedLoad(drug, false);
        },
        getDrugId(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.adminChart.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                }
            }
        },
        updatePRN(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.patient.patches.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                }
            }
        },
        completePatch(value){
            if(value){
                console.log('this is the patch complete', value);
                if(value){
                    this.patient.patches.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.completed = true;
                            chart.administered_at = value.administered_at;
                            this.$emit('completePatch', value);
                        }
                    })
                } 
            }
        },
        receiveDOC(value){
            console.log('THIS IS THE DOC RECEIVED', value);
            if(value){
                this.adminChart.forEach(chart=>{
                    if(chart.uuid == value.id){
                        chart.doc = value.doc.value;
                        chart.completed = true;
                        chart = {
                            latest_administration: value?.response,
                            ...chart
                        };
                        // fallback incase component is destroyed and this.adminChart resets
                        localStorage.setItem(chart.chart_id, value?.response?.uuid);
                    }
                })
            }
        },
        removeDrugFromRegister(drug){
            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id:drug.stock_items[0].uuid,
                    quantity:drug.dose.toString(),
                    patient_id: this.patient.uuid 
                }
            
        },
        selectDOC(drug){
            drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },
        
        submitPrimeSignature(drug, status){
            this.$emit('primeSignature', drug, status);
        },
        submitIsCompleted(value){
            console.log('RECEIVED THIS FROM SUBMIT IS COMPLETED', value);
            this.$emit('isCompleted', value);
            if(value){
                this.adminChart.forEach(chart=>{
                    console.log('this is the chart uuid', chart, chart.calendar_item_id, value.item);
                    if(chart.uuid == value.item){
                        console.log('match this');
                        chart.completed = true;
                        // reference administration id for any redo operation
                        chart = {
                            latest_administration: value?.response,
                            ...chart
                        };
                        // fallback incase component is destroyed and this.adminChart resets
                        localStorage.setItem(chart.chart_id, value?.response?.uuid);
                    }
                })
            }
            // this.removeFromSafe = [];
        },
        selectMedLoad(drug, administered){
            this.loop = false;
            this.submitPayload = {
                administered: administered,
                drug_safe_retrieval: drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                calendar_item_id: drug.uuid,
                chart_id: drug.chart_id,
                doc:'',
                dose: parseFloat(drug.dose).toFixed(2),
                reading: '',
            }
        },
        selectDOCLoad(drug){
            console.log('this si the doc selection func', drug);
            this.loop = false;
            this.submitPayload = {
                "drug":drug,
                "chart_id": drug.uuid,
                "route": drug.route,
                "doc": "",
                "drug_safe_retrieval": drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                "dose": 1,
                "notes": "",
                "effectiveness": null,
                patch_site: null,
            }
        },
        redoAdminChart(chart){
            console.log('CHART', chart);
            chart.doc = '';
            let updateAdminPayload = {
                administration_id: chart.latest_administration?.uuid
            };
            console.log('this is the payload to send to /update-administration-event', updateAdminPayload);
            
            // begin redoing of administration event
            this.redoingAdministration[chart.chart_id] = {
                loading: true,
                error: false
            };

            axios.post("/update-administration-event", updateAdminPayload, this.$store.state.header).then(res => {
                console.log('this is the res from endpoint: /update-administration-event', res);
                // final state for redo: tick and cross buttons restored
                this.redoingAdministration[chart.chart_id] = {
                    loading: false,
                    error: false
                };
                chart.completed = false;
            }).catch(err => {
                console.error('this is the error from endpoint: /update-administration-event', err);
                this.redoingAdministration[chart.chart_id] = {
                    loading: false,
                    error: true
                };
            });
        },
    },
    mounted(){
    },
    computed:{
        uniqueSpecCons(){
            let b = this.spec_cons &&  this.spec_cons.special_considerations ?  this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        },
        getNonPacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>(!item.chart_tags.includes('packed') && (!item.alias.tags.includes('S8') && !item.alias.tags.includes('s8') && (!item.stock_items || !item.stock_items.length )) && item.chart_type != 'insulin'));
            } else{
                return []
            }
            
        },
        getIncompleteNonPacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>(!item.chart_tags.includes('packed') && (!item.alias.tags.includes('S8') && !item.alias.tags.includes('s8') && (!item.stock_items || !item.stock_items.length )) && !item.completed && item.chart_type != 'insulin'));
            } else{
                return []
            }
            
        },
        getIncompleteS8(){
            if(this.adminChart){
                let filtered = this.adminChart.filter(item=>!item.chart_tags.includes('packed') && (item.alias.tags.includes('S8') || item.alias.tags.includes('s8') || item.alias.tags.includes('S11') || item.alias.tags.includes('s11') || (item.stock_items && item.stock_items.length)) && !item.completed);
                return filtered;
            } else{
                return []
            }
        },
        getS8(){
            if(this.adminChart){
                let filtered = this.adminChart.filter(item=>!item.chart_tags.includes('packed') && (item.alias.tags.includes('S8') || item.alias.tags.includes('s8') || item.alias.tags.includes('S11') || item.alias.tags.includes('s11') || (item.stock_items && item.stock_items.length)));
                filtered.forEach(chart=>{
                    // chart.removed = false;
                    chart.selectedForRemoval = false;
                });
                return filtered;
            } else{
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.dummy-icon{
    width: 50px !important;
}
.administered{
    background-color: #00AD50 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}

.not-administered{
    background-color: #E04F39 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}
.medication-group-header{
    h2{
        font-size: 18px;
    }
    span{
        font-size: 18px;
    }
}

.medication-group{
    table{
        td{
            font-size: 16px;
            p{
                margin: 0;
            }
        }
    }
}
.admin-chart-header{
    display: flex;
    padding: 1em;
    padding-top: 0;
}

.drug-image{
    // height: 75px;
    // width: 75px;
    // background-color: white;
    padding-left: 15px;
    img{
        max-height: 100px;
        max-width:120px;
        width: auto;
        height: auto;
    }
}

.administer-button{
    box-sizing: border-box;
    width: 187px;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 6px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.grey-field{
    background-color: #f3f4f4 !important;
    border: none !important;
}

.four-table-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.four-column-entry{
    width: 22%;
    min-width: 120px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.two-column-row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.two-column-entry{
    width: 45%;
    min-width: 200px;
    margin-right: 10px;
}

.medication-group{
    margin-bottom: 10px;
    h2{
        color: white;
        width: fit-content;
    }
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.packed-medication-row{
     background-color: rgba(8, 112, 220, 0.2);  
     td{
         padding: 10px;
     }   
}

.scheduled-medication-row{
     background-color: rgba(224, 79, 57, 0.2);
     td{
         padding: 10px;
     }   
}

.non-packed-medication-row{
     background-color: #F3F4F4;
     td{
         padding: 10px;
     }   
}

.prn-medication-row{
    background-color: #94D6D0;
    td{
        padding: 10px;
    }
}




.pi-check, .pi-times, .pi-sign-out, .pi-lock-open, .pi-eject,.pi-replay{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    color: grey;
    cursor: pointer;
}


</style>