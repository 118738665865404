<template>
  <div class="text-center section">
    <v-calendar v-if="attributes[0] && attributes[0].dates" class="custom-calendar max-w-full" :masks="masks"
      :attributes="attributes" disable-page-swipe is-expanded :from-date="new Date(attributes[0].dates)">
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <div v-for="attr in attributes" :key="attr.key" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.administration && (attr.customData.administration.tags && attr.customData.administration.tags.includes('not effective')) ? 'warning-container' : (attr.customData.administration.doc ? 'danger-container' : 'good-container')"
              style="cursor: pointer">
              <p style="margin: 0"
                :class="attr.customData.administration && attr.customData.administration.doc ? 'danger-time' : 'good-time'"
                @click="displayModal = true, itemInfo = attr">
                {{ attr.customData.time }}</p>
              <p :class="attr.customData.administration && attr.customData.administration.doc ? 'danger-text' : ''"
                v-if="(attr.customData.administration && attr.customData.administration.doc)">
                {{ attr.customData.administration && attr.customData.administration.doc ?
                    attr.customData.administration.doc : ''
                }}
              </p>
              <p v-if="(attr.customData.administration && attr.customData.administration.tags && (attr.customData.administration.tags.includes('effective') || attr.customData.administration.tags.includes('not effective')))"
                :class="attr.customData.administration.tags.includes('effective') ? 'effective-text' : 'not-effective-text'">
                {{ attr.customData.administration.tags.includes('effective') ? 'EFFECTIVE' : 'NOT EFFECTIVE' }}
              </p>
              <div
                v-if="chart_type == 'PRN Medicine' && attr.customData.administration.tags && !attr.customData.administration.tags.includes('effective') && !attr.customData.administration.tags.includes('not effective') && !attr.customData.administration.doc"
                style="padding: 0 3px; background-color: white; border: 2px solid #83C48D; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px">
                <p style="margin: 0; font-size: 1em">Effective?</p>
                <div style="display: flex; justify-content: space-between; padding-bottom: 10px">
                  <span @click="submitEffective('effective', attr, attr.customData.uuid)"
                    style="padding: 5px 10px; background-color: black; color: white; width: 55px; border-radius: 6px; cursor:pointer">YES</span>
                  <span @click="submitEffective('not effective', attr, attr.customData.uuid)"
                    style="padding: 5px 10px; background-color: black; color: white; width: 55px; border-radius: 6px; cursor:pointer">NO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-calendar>
    <ChartExpansionItemModal v-if="displayModal" @close="close" :itemInfo="itemInfo" :drug="drug" />
  </div>
</template>

<script>
import ChartExpansionItemModal from '@/components/modals/ChartExpansionItemModal.vue';
import axios from 'axios';
import '../assets/css/main.scss';

export default {
  props: ['attributes', 'drug', 'chart_type', 'chartId'],
  components: {
    ChartExpansionItemModal
  },
  data() {
    return {
      displayModal: false,
      itemInfo: '',
      masks: {
        weekdays: 'WWW',
      },
    };
  },
  methods: {
    close(value) {
      if (value) {
        this.displayModal = false;
      }
    },
    submitEffective(value, item, id) {
      console.log('this is the value and id', value, id);
      axios.post('/administration/effective', { uuid: id, tag: value }).then(res => {
        console.log('submitted effectiveness tag', res.data)
      }).then(() => {
        item.customData.administration.tags.push(value);
      })
      item.customData.administration.tags.push(value);
    },
    mapCalendarObjects(days) {
      days.forEach((day, index) => {
        day.key = index + 1;
        day.customData = day.custom_fields;
        delete day.custom_fields;
      })
    }
  },
  mounted() {
    //   this.attributes = this.administrations;
    //   this.mapCalendarObjects(this.attributes)
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  $day-border: 1px solid #b8c2cc;
  $day-border-highlight: 1px solid #b8c2cc;
  $day-width: 90px;
  $day-height: 90px;
  $weekday-bg: #F3F4F4;
  $weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;

  .vc-header {
    background-color: #F3F4F4;
    padding: 10px 0;
  }

  .vc-weeks {
    padding: 0;
  }

  .vc-weekday {
    background-color: $weekday-bg;
    border-bottom: $weekday-border;
    border-top: $weekday-border;
    padding: 5px 0;
  }

  .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: $day-height;
    min-width: $day-width;
    background-color: white;

    .weekday-1,
    .weekday-7 {
      background-color: #eff8ff;
    }

    :not(.on-bottom) {
      border-bottom: $day-border;

      .weekday-1 {
        border-bottom: $day-border-highlight;
      }
    }

    :not(.on-right) {
      border-right: $day-border;
    }
  }

  .vc-day-dots {
    margin-bottom: 5px;
  }
}

.leading-tight {
  margin: 5px;
  border-radius: 8px;
  background-color: #83C48D;
  font-weight: 500;

  p {
    padding: 5px;
  }
}

.leading-tight.danger-container {
  border: 2px solid #E04F39; //$error;
  border-radius: 8px;
}

.danger-time {
  background-color: #E04F39; //error;
  padding: 3px 5px;
  color: white;
}

.danger-text {
  background-color: white;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.effective-text {
  background-color: white;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #00AD50; //$correctborder;
  font-weight: bold;
  border: 2px solid #83C48D;
  border-top: none;
}

.not-effective-text {
  background-color: white;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #EF9600; //$warningborder;
  font-weight: bold;
  border: 2px solid #FED182; //$warning;
  border-top: none;
}

.custom-calendar.vc-container {
  border: none;
}

.warning-container {
  background-color: #FED182; //$warning;
  border-color: #FED182; //$warning;
}
</style>