// Calling every order and checking their status for signature restrictions
export function signRestrictionStatus(item, type) {
  let user_id = JSON.parse(localStorage.getItem('user_id'));
  let user = JSON.parse(localStorage.getItem('user'));
    if (!item) return;
    const checkLastSignedStatus = item?.status == "pending" ? "" : item?.status == "partial" ? item?.signature_status[0] : item?.signature_status?.join('-')
    let permission = ''
    let costomMessge = ''
    let lastSignedStatus = false
    if (item?.status == "pending" && item?.has_scheduled_items && !user?.role.permissions.includes('signature_primary') && user?.role.permissions.includes('signature_witness')) {
        lastSignedStatus = true
        permission = 'signature_primary'
    } else if (item?.status == "pending" && !item?.has_scheduled_items && !user?.role.permissions.includes('single_signature_primary')) {
        lastSignedStatus = true
        permission = 'single_signature_primary'
    } else if (item?.has_scheduled_items && (!user?.role.permissions.includes('signature_primary') && !user?.role.permissions.includes('signature_witness'))) {
        lastSignedStatus = true
        permission = 'signature_primary or signature_witness'
    } else if (user?.role.permissions.includes('signature_primary') && !user?.role.permissions.includes('signature_witness') && checkLastSignedStatus == "primary") {
        lastSignedStatus = true
        permission = 'signature_witness'
    } else if (user?.role.permissions.includes('signature_witness') && !user?.role.permissions.includes('signature_primary') && checkLastSignedStatus == "witness") {
        lastSignedStatus = true
        permission = 'signature_primary'
    } else if (item?.signatures && user_id == item?.signatures[0]?.user_id && checkLastSignedStatus == "primary") {
        lastSignedStatus = true
        costomMessge = `You have already signed this ${type} and cannot sign it again.`
    }
    return { status: lastSignedStatus, permission, costomMessge }
}

// Return tooltip messages based on permission restrictions and item selection status for signing.
export function tooltipMessagesDisplay(item, entries, type) {
      const signRestriction = signRestrictionStatus(item, type);
      // Check if there is a restriction
      if (signRestriction.status) {
        return signRestriction.costomMessge 
          ? signRestriction.costomMessge 
          : `You do not have ${signRestriction.permission} permission to sign this ${type}, please contact admin.`;
      }
      // Check the selection status
      const hasPendingSelected = entries.some(entry => entry.status === 'pending' && entry.to_sign);
      const hasPartialSelected = entries.some(entry => entry.status === 'partial' && entry.to_sign);
      // Mixed statuses check
      if (hasPendingSelected && hasPartialSelected) {
        return `Cannot bulk sign ${type}s with mixed statuses. Selecting an ${type} of partial status will automatically unselect ${type}s of pending status.`;
      }
      // If the current item is `pending`, it should check if any `partial` item is selected
      if (item.status === 'pending' && hasPartialSelected) {
        return `Cannot bulk sign ${type}s with mixed statuses. Selecting an ${type} of partial status will automatically unselect ${type}s of pending status.`;
      }
      // If the current item is `partial`, it should check if any `pending` item is selected
      if (item.status === 'partial' && hasPendingSelected) {
        return `Cannot bulk sign ${type}s with mixed statuses. Selecting an ${type} of pending status will automatically unselect ${type}s of partial status.`;
      }
      // No tooltip needed
      return 'Sign';
}

export function checkPermissionAndPassAsProps(item) {
  let user = JSON.parse(localStorage.getItem('user'));
  let status = "";
  let uuid = item?.signatures[0]?.uuid;
  let lastSign = item?.signatures[0]?.user_name;

  if (item?.status === "pending") {
    if (!item?.has_scheduled_items && !user?.role.permissions.includes('single_signature_primary')) {
      status = "no_single_signature_primary";
    } else if (!user?.role.permissions.includes('signature_primary') && user?.role.permissions.includes('signature_witness') && item?.has_scheduled_items) {
      status = "witness";
    } else if (user?.role.permissions.includes('signature_primary') && !user?.role.permissions.includes('signature_witness')) {
      status = "no";
    } else {
      status = ""; // Default case for "pending"
    }
  } else if (item?.status === "partial") {
    status = item?.signature_status[0]; // For partial status, we get the first signature status
  } else {
    status = item?.signature_status.join('-'); // Join signature status array if neither pending nor partial
  }

  return { status, uuid, lastSign };
}
