

<template>
    <div>
        <div>
            <h3 style="text-align: left;margin: 0; margin-top: 2em;">Enter DAA frequency times</h3>
            <p style="text-align: left; font-size: 1em; ">The times for each window will be available during administration.</p>
            <div style="display: flex; flex-direction: column; min-width: 50em; width: 53vh;">
                <p style="font-size: 1em; text-align: left; margin: 0; margin-top: 1em; margin-bottom: 10px; font-weight: 500">Select Facility:</p>
                <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                @change="retrieveSelectedFacilityRoundTimes()"
                :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" style="width: 20em; text-align: left;" />
            </div>
        </div>
        <div style="margin-top: 2em;" v-if="selectedFacility">
            <h3 style="text-align: left">DAA Frequency Times (2400 time format)</h3>
            <div v-for="(item, index) in frequencyOptions" :key="index" style="display: flex; align-items: center;"> 
                <p style="font-size: 1em; font-weight: bold; margin-right: 20px; width: 10em; text-align: left">
                    {{item.option}}
                </p>
                <inputText
                    placeholder="e.g. 0800"
                    :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="item.time"
                    style="width: 12em; border: 1px solid #cdcdcd; background-color: #f3f4f4; margin-right: 20px; "
                />
            </div>
        </div>
        <p v-if="errorMessage" style="font-size: 1em; font-weight: bold; margin-top: 3em; color: red">
            {{errorMessage}}
        </p>
        <p
            class="green-button"
            style="text-align: left; width: fit-content;margin-top: 10px; padding: 5px 25px; font-size: 1em;" @click="saveTemplate()"
            v-if="$store.state.user.role.permissions.includes('can_do_admin_company') && selectedFacility"
        >
            Save
        </p>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['facilities'],
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            loadingConfig: false,
            selectedFacility:'',
            selectedFacilityRounds:[],
            frequencyOptions: [
                {
                    option: 'Morning',
                    period: 'morning',
                    time:''
                },
                {
                    option: 'Lunch',
                    period: 'lunch',
                    time:''
                },
                {
                    option: 'Evening',
                    period: 'evening',
                    time:''
                },
                {
                    option: 'Bedtime',
                    period: 'bedtime',
                    time:''
                }
            ]
        }
        
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        saveTemplate(){
            this.$store.dispatch('getRefreshToken');

            let payload = {
                facility_id: this.selectedFacility.value,
                morning: this.frequencyOptions[0].time,
                lunch: this.frequencyOptions[1].time,
                evening: this.frequencyOptions[2].time,
                bedtime: this.frequencyOptions[3].time
            }
            axios.post('/add-daa-times', payload, this.$store.state.header).then(res=>{
                console.log('saved successfully', res.data);
                this.displaySuccessModal = true;
                this.successMessage = 'Successfully saved daa frequency template';

            }).catch(err=>{
                this.displaySuccessModal = true;
                this.successMessage = err.response.data;
            })
        },
        retrieveSelectedFacilityRoundTimes(){
            this.$store.dispatch('getRefreshToken');
            axios.post('/get-daa-times', {facility_id: this.selectedFacility.value}, this.$store.state.header).then(res=>{
                
                if(res.data){
                    this.frequencyOptions.forEach(templateRound=>{
                        for(const key in res.data){
                            if(templateRound.period == key && res.data[key] !== null){
                                templateRound.time = res.data[key]
                            }
                        }
                    })
                }
            }).catch(err=>{
                console.log('get-round-times error', err.response.data);
            })
        }
        
    },
    mounted() {
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
