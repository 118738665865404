import axios from 'axios';
import { createStore } from 'vuex';
import router from '@/router';
import Gleap from 'gleap';

export const store = createStore({
  state: {
    // lastRefresh:JSON.parse(localStorage.getItem('lastRefresh')),
    subdomain:'',
    view:'',
    quickSearch: '',
    reponsiblePersonError: false,
    reponsiblePersonErrorMessage: '',
    uuid: JSON.parse(localStorage.getItem('currentLocation')) ? JSON.parse(localStorage.getItem('currentLocation')).uuid : '',
    company:JSON.parse(localStorage.getItem('company')),
    userType:JSON.parse(localStorage.getItem('company')) ? JSON.parse(localStorage.getItem('company')).type : '',
    user_id:'',
    prompt_sync: false,
    integration_details:null,
    user:JSON.parse(localStorage.getItem('user')),
    checkedFirebase: false,
    loadingDrugRegisterData: false,
    loadingTransferData: false,
    loadingDeliveriesData: false,
    loadingDestructionsData: false,
    loadingBatchData:false,
    loadingClientData:false,
    // VUE_APP_ALGOLIA_API_KEY: 'd05e07267b8f5ff86172a1e532bcb17b',
    // VUE_APP_ALGOLIA_APP_ID : 'GKE6N3CQDB',
    // VUE_APP_ALGOLIA_DNS : 'GKE6N3CQDB',
    VUE_APP_ALGOLIA_API_KEY: '434f3b61af4310230947157637edc0ba',
    VUE_APP_ALGOLIA_APP_ID : 'H4AK5A0Y5K',
    VUE_APP_ALGOLIA_DNS : 'H4AK5A0Y5K',
    loadingStockTakeData: false,
    loadingAuditData: false,
    loadingIHIData: false,
    loadingEPrescribingData: false,
    loadingPrescriptionsData: false,
    loadingDrugListData: false,
    loadingReportData: false,
    loadingPatientsData: false,
    displayTimeRunnerModal: false,
    timeToLogOut:0,
    locationTypes: [
      "Pharmacy",
      "Hospital",
      "RACF",
      "Supplier",
      "Building",
      "Room",
      "Ward",
      "Wing",
      "Floor",
      "Level",
      "House",
      "Health District",
      "Patient",
      "Drug Safe",
      "Trolley",
      "Car",
      "Ambulance",
      "Drawer",
      "Cabinet",
      "Machine",
      "Generic",
      "Warehouse"
    ],
    countries: [ 

      {name: 'Australia', code: 'AU', callingcode: '+61'}, 
      {name: 'New Zealand', code: 'NZ', callingcode: '+64'}, 
      {name: 'Norway', code: 'NO', callingcode: '+47'}, 
      {name: 'Sweden', code: 'SE',callingcode: '+46'}, 
      {name: 'United Kingdom', code: 'GB',callingcode: '+44'}, 
      {name: 'United States', code: 'US',callingcode: '+1'}, 

    ],
    chartTypes: [
      {
          name:'Regular Medicine Order',
          chart_name:'regular',
          inputs:[
              'dose',
              'route',
              'start_date',
              'schedule',
              'stop_date',
              'frequency',
              'reason',
              'substitution',
              'indication',
              'single_multi',
              'packed',
              'comments',
          ],
          subtext:'',
          color: '#9F5CC0',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
    //   {
    //     name:'S8 Regular Medicine Order',
    //     inputs:[
    //         'single_multi',
    //         'route',
    //         'packed',
    //         'frequency',
    //         'prescription_date',
    //         'start_date',
    //         'stop_date',
    //         'substitution',
    //         'indication',
    //     ],
    //     subtext:'',
    //     color: '#9F5CC0',
    //     prescriber:true,
    //     sign1:false,
    //     sign2: false,
    //     substitution: true,
    // },
      {
          name:'PRN Medicine',
          chart_name:'prn',
          inputs:[
            'indication',
            'frequency',
            'max_24_dose',
            'dose',
            'route',
            'max_dose',
            'substitution',
            'packed',
            'prescription_date',
            'start_date',
            'stop_date',
            'comments',
            'min_admin',
            'max_admin'
          ],
          subtext:'',
          color: '#00B2A2',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
        name: 'Insulin Order',
        chart_name: 'insulin',
        inputs: [
            'dose',
            'route',
            'start_date',
            'stop_date',
            'frequency',
            'schedule',
            // 'reason',
            'substitution',
            'indication',
            'single_multi',
            'packed',
            'comments',
        ],
        subtext: '',
        color: '#F3C400',
        prescriber: true,
        sign1: false,
        sign2: false,
    },
    //   {
    //       name:'Phone Order',
    //       inputs:[
    //           'reason',
    //           'dose',
    //           'route',
    //           'frequency',
    //           'prescription_date',
    //           'start_date',
    //           'stop_date',
    //           'comments',
    //       ],
    //       subtext:'',
    //       color: '#2E97DE',
    //       prescriber:true,
    //       sign1:true,
    //       sign2: true,
    //   },
      {
          name:'Short Term Medicine',
          chart_name:'short_term',
          inputs:[
              'now_or_next_pack',
              'dose',
              'route',
              'schedule',
              'packed',
              'frequency',
              'indication',
              'prescription_date',
              'start_date',
              'stop_date',
              'reason',
              'substitution',
              'comments',

          ],
          subtext:'(antibiotics)',
          color: '#D6001C',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Once Only Meds (Stat)',
          chart_name:'stat',
          inputs:[
              'datetime',
              'dose',
              'route',
              'reason',
              'schedule'
          ],
          subtext:'',
          color: '#8E0018',
          prescriber:true,
          sign1:true,
          sign2: false,
      },
      {
          name:'Intermittent Medicine',
          chart_name:'intermittent',
          inputs:[
              'frequency',
              'start_date',
              'schedule'
          ],
          subtext:'',
          color: '#5B00AA',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Variable Dose Meds',
          chart_name:'variable_dose',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'packed',
              'prescription_date',
              'start_date',
              'stop_date',
              'comments',
          ],
          subtext:'(not insulin)',
          color: '#00AD50',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
    //   {
    //       name:'Nurse Initiated Meds',
    //       inputs:[
    //           'frequency',
    //           'dose',
    //           'route',
    //           'reason',
    //           'start_date',
    //           'comments'
    //       ],
    //       subtext:'(non-prescription)',
    //       color: '#274188',
    //       prescriber:false,
    //       sign1:false,
    //       sign2: false,
    //       nurse: true,
    //   },
      {
          name:'Non Prescription Meds',
          chart_name:'non_prescription',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'reason',
              'start_date',
              'comments',
          ],
          subtext:'(resident initiated)',
          color: '#EF9600',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Nutritional Supplement',
          chart_name:'supplement',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'reason',
              'start_date',
              'stop_date',
              'substitution',
              'packed',
              'comments',
          ],
          subtext:'',
          color: '#E56DB1',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
    ],
    reportError:'',
    stockError:'',
    transferError:'',
    destructionError:'',
    deliveryError:'',
    batchError:'',
    clientError:'',
    registerError:'',
    availableLocations: JSON.parse(localStorage.getItem('locations')),
    currentLocation: JSON.parse(localStorage.getItem('currentLocation')),
    header: {
      headers: {
        'Authorization':`Bearer bubble`,
        'Content-Type': 'application/json',
        'Location': JSON.parse(localStorage.getItem('locations')) ? JSON.parse(localStorage.getItem('locations'))[0]?.uuid : ''
      }
    },
    form:{
    },
    verify:{
      username: '',
      password: ''
    },
    currPage: 1,
    drugEntries: [],
    locationUserData:[],
    stockReports: [],
    auditReports: [],
    ihiReports: [],
    ePrescribingReports: [],
    prescriptionsReports:[],
    reports: [],
    patients: [],
    drugNames: [],
    stockTakeCount: [],
    country: 'au',
    includeInactive: false,
    alerts:null,
    displayAlerts: false,
    displayNotifications: false,
    lastAlertRefresh: null,
    loadingAlerts: false,
    drugRegImprestToggle: false,
    drugRegDestructionToggle: false,
    refreshTimeOut: null,
    // Sync States
    LastSync: JSON.parse(localStorage.getItem('LastSynced')),
    invoicesLastSynced: JSON.parse(localStorage.getItem('LastSynced')) || '--',
    ordersLastSynced: JSON.parse(localStorage.getItem('LastSynced')) || '--',
    clientsLastSynced: JSON.parse(localStorage.getItem('LastSynced')) || '--',
    suppliersLastSynced: JSON.parse(localStorage.getItem('LastSynced')) || '--',
    latestDrugEntriesMutationRequest:'',
    productItemsLastSynced: JSON.parse(localStorage.getItem('LastSynced')) || '--',
    syncInterval: null,
    syncinvoices: null,
    syncorders: null,
    syncclients: null,
    syncsuppliers: null,
    syncstocktakes: null,
    syncInvoiceHourlyInterval: null,
    syncOrdersHourlyInterval: null,
    syncClientsHourlyInterval: null,
    syncSuppliersHourlyInterval: null,
    syncStockTakeHourlyInterval: null,
    dashboardBatchExpend: null ,
    syncStatus:{},
    isSyncPending: false,
    invoiceCCFlag: JSON.parse(localStorage.getItem('invoiceCCFlag')),
    orderCCFlag: JSON.parse(localStorage.getItem('orderCCFlag'))
  },
  getters: {
    getSubdomain: state =>{
      return state.subdomain
    },
    getLastAlertRefresh: state=>{
      return state.lastAlertRefresh
    },
    getQuickSearch: state => {
      return state.quickSearch
    },
    getDrugEntries: state => {
      return state.drugEntries.data
    },
    getLocationUserData: state => {
      return state.locationUserData.data
    },
    getDrugListEntries: state => {
      return state.drugEntries.drugs
    },
    getTransactionsReport: state => {
      return state.reports
    },
    getStockReport: state => {
      return state.stockReports
    },
    getAuditReport: state => {
      return state.auditReports
    },
    getIHIReport: state => {
      return state.ihiReports
    },
    getEPrescribingReport: state => {
      return state.ePrescribingReport
    },
    getPatients: state => {
      return state.patients
    },
    getLocationTypes: state => {
      return state.locationTypes
    },
    getCountries: state => {
      return state.countries
    },
    getChartTypes: state => {
      return state.chartTypes
    },
    getPrescriptionsReport: state=>{
      return state.prescriptionsReports.data
    },
    getavailableLocations: state => {
      return state.availableLocations
    },
    getCurrentLocation: state => {
      return state.currentLocation
    },
    getInvoicesLastSynced: state => {
      // Get the current date and time in the Melbourne time zone
      const timestamp = state.LastSync?.invoicesLastSynced?.time;
       if (timestamp) {
           const date = new Date(timestamp);
           const options = { 
               year: 'numeric', 
               month: '2-digit', 
               day: '2-digit', 
               hour: '2-digit', 
               minute: '2-digit', 
               second: '2-digit', 
               hour12: false 
           };
           return date.toLocaleString('en-AU', options);
       } else {
           return '--';
       }
    },
    getOrdersLastSynced: state => {
      const timestamp = state.LastSync?.ordersLastSynced?.time;
       if (timestamp) {
           const date = new Date(timestamp);
           const options = { 
               year: 'numeric', 
               month: '2-digit', 
               day: '2-digit', 
               hour: '2-digit', 
               minute: '2-digit', 
               second: '2-digit', 
               hour12: false 
           };
           return date.toLocaleString('en-AU', options);
       } else {
           return '--';
       }
    },
    getClientsLastSynced: state => {
      const timestamp = state.LastSync?.clientsLastSynced?.time;
       if (timestamp) {
           const date = new Date(timestamp);
           const options = { 
               year: 'numeric', 
               month: '2-digit', 
               day: '2-digit', 
               hour: '2-digit', 
               minute: '2-digit', 
               second: '2-digit', 
               hour12: false 
           };
           return date.toLocaleString('en-AU', options);
       } else {
           return '--';
       }
    },
    getSuppliersLastSynced: state => {
      const timestamp = state.LastSync?.suppliersLastSynced?.time;
       if (timestamp) {
           const date = new Date(timestamp);
           const options = { 
               year: 'numeric', 
               month: '2-digit', 
               day: '2-digit', 
               hour: '2-digit', 
               minute: '2-digit', 
               second: '2-digit', 
               hour12: false 
           };
           return date.toLocaleString('en-AU', options);
       } else {
           return '--';
       }
    },
    getProductItemsLastSynced: state => {
      const timestamp = state.LastSync?.productItemsLastSynced?.time;
       if (timestamp) {
           const date = new Date(timestamp);
           const options = { 
               year: 'numeric', 
               month: '2-digit', 
               day: '2-digit', 
               hour: '2-digit', 
               minute: '2-digit', 
               second: '2-digit', 
               hour12: false 
           };
           return date.toLocaleString('en-AU', options);
       } else {
           return '--';
       }
    },
    getInvoiceCCFlag: state => {
      return state.invoiceCCFlag;
    },
    getOrderCCFlag: state => {
      return state.orderCCFlag;
    }
  },
  mutations: {
    setdrugRegImprestToggle(state, toggle){
      state.drugRegImprestToggle = toggle
    },
    setdrugRegDestructionToggle(state, toggle){
      state.drugRegDestructionToggle = toggle
    },
    setLoadingAlerts(state, loading){
      state.loadingAlerts = loading
    },
    setLastAlertRefresh(state, time){
      state.lastAlertRefresh = time;
    },
    setSubdomain(state, subdomain){
      state.subdomain = subdomain;
    },
    setNumAlerts(state,num){
      state.numOfAlerts = num;
    },
    setAlerts(state,alerts){
      state.alerts = alerts;
    },
    setAlertAsRead(state,index){
      state.alerts[index].read = true;
    },
    setQuickSearch(state, quickSearch) {
      state.quickSearch = quickSearch;
    },
    setCheckedFirebase(state, check) {
      state.checkedFirebase = check;
    },
    changeView(state, view) {
      localStorage.removeItem('reg');
      state.view = view;
    },
    saveVerification(state,verify) {
      state.verify = verify;
    },
    setPatients(state, patients) {
      state.patients = patients;
    },
    setCurrPage(state, page) {
      state.currPage = page;
    },
    setDrugEntries(state, entries) {
      state.drugEntries = entries;
    },
    setLocationData(state,data){
      state.locationUserData = data
    },
    setReports(state, reports) {
      state.reports = reports;
    },
    setStockReports(state, reports) {
      state.stockReports = reports;
    },
    setAuditReports(state, reports) {
      state.auditReports = reports;
    },
    setIHIReports(state, reports) {
      state.ihiReports = reports;
    },
    setEPrescribingReports(state, reports) {
      state.ePrescribingReports = reports;
    },
    setPrescriptionsReport(state, reports) {
      state.prescriptionsReports = reports;
    },
    setDrugNames(state, names) {
      let drugs = []
      names.forEach(item => {
        drugs.push(item.name)
      })
      state.drugNames = drugs;
    },
    setAuthorization(state) {
      state.header.headers['Authorization'] = `Bearer ${('; '+document.cookie).split(`; auth=`).pop().split(';')[0]}`
    },
    setUserType(state) {
      state.userType = JSON.parse(localStorage.getItem('company')) ? JSON.parse(localStorage.getItem('company')).type : '';
    },
    updateCount(state, countCache) {
      state.stockTakeCount = countCache;
    },
    setUser(state, user) {
      state.user_id = user;
      state.user = user;
    },
    setResponsiblePersonError(state, message){
      state.reponsiblePersonErrorMessage = message.errorMessage;
      state.reponsiblePersonError= message.state;
    },
    setLoading(state, loadStatus) {
      console.log('this is the type of loading', loadStatus);
      switch (loadStatus.type) {
        case 'Drug Register':
          state.loadingDrugRegisterData = loadStatus.state;
          break;
        case 'Stock Take':
          state.loadingStockTakeData = loadStatus.state;
          break;
        case 'Transfers':
          state.loadingTransfersData = loadStatus.state;
          break;
        case 'Deliveries':
          state.loadingDeliveriesData = loadStatus.state;
          break;
        case 'Destructions':
          state.loadingDestructionsData = loadStatus.state;
          break;
        case 'Batch':
          state.loadingBatchData = loadStatus.state;
          break;
        case 'client':
            state.loadingClientData=loadStatus.state;
            break;
        case 'Report':
          state.loadingReportData = loadStatus.state;
          break;
        case 'Audit':
          state.loadingAuditData = loadStatus.state;
          break;
        case 'IHI':
          state.loadingIHIData = loadStatus.state;
          break;
        case 'EPrescribing':
          state.loadingEPrescribingData = loadStatus.state;
          break;
        case 'Prescriptions':
            state.loadingPrescriptionsData = loadStatus.state;
            break;
        case 'Drug List':
          state.loadingDrugListData = loadStatus.state;
          break;
        case 'Patients':
          state.loadingPatientsData = loadStatus.state;
          break;
      }
    },
    setLocation(state, location) {
      state.uuid = location.location;
      state.currentLocation = location;
    },
    setAvailableLocations(state, locations) {
      state.availableLocations = locations;
    },
    setCompany(state, id) {
      state.company = id;
    },
    setHeaderLocation(state,location) {
      state.header.headers['Location'] = location;
    },
    setToken(state,token) {
      state.header.headers['Authorization'] = `Bearer ${token}`
    },
    setStockError(state, error) {
      state.stockError = error;
    },
    setDeliveryError(state, error) {
      state.deliveryError = error;
    },
    setBatchError(state, error) {
      state.batchError = error;
    },
    setClientError(state,error){
      state.clientError=error;
    },
    setTransferError(state, error) {
      state.transferError = error;
    },
    setDestructionError(state, error) {
      state.destructionError = error;
    },
    setRegisterError(state, error) {
      state.registerError = error;
    },
    setReportError(state,error) {
      state.reportError = error;
    },
    setDisplayTimeRunnerModal(state,value){
      state.displayTimeRunnerModal = value;
    },
    setDisplayNotifications(state,set){
      state.displayNotifications = set;
    },
    setDisplayAlerts(state,set){
      state.displayAlerts = set;
    },
    setTimeToLogOut(state,value){
      state.timeToLogOut = value
    },
    setInactive(state,value){
      state.includeInactive = value;
    },
    setFacilityIntegration(state, value){
      state.facility_integration = value;
    },
    setIntegrationDetails(state, value){
      state.integration_details = value;
    },
    setIntegrationPrompt(state, value){
      state.prompt_sync = value;
    },
    setInvoicesLastSynced(state, value) {
      // Store count, time, and last synced UUID data in local storage under 'LastSynced' for invoicesLastSynced sync data
      const lastSynced = JSON.parse(localStorage.getItem('LastSynced')) || {};
      lastSynced.invoicesLastSynced = { time: value.time, count: value.count, uuid: value.uuid };
      localStorage.setItem('LastSynced', JSON.stringify(lastSynced));
    },
    
    setOrdersLastSynced(state, value) {
      // Store count, time, and last synced UUID data in local storage under 'LastSynced' for ordersLastSynced sync data
      const lastSynced = JSON.parse(localStorage.getItem('LastSynced')) || {};
      lastSynced.ordersLastSynced = { time: value.time, count: value.count, uuid: value.uuid };
      localStorage.setItem('LastSynced', JSON.stringify(lastSynced));
    },
    
    setClientsLastSynced(state, value) {
      // Store count, time, and last synced UUID data in local storage under 'LastSynced' for clientsLastSynced sync data
      const lastSynced = JSON.parse(localStorage.getItem('LastSynced')) || {};
      lastSynced.clientsLastSynced = { time: value.time, count: value.count, uuid: value.uuid };
      localStorage.setItem('LastSynced', JSON.stringify(lastSynced));
    },
    
    setSuppliersLastSynced(state, value) {
      // Store count, time, and last synced UUID data in local storage under 'LastSynced' for suppliersLastSynced sync data
      const lastSynced = JSON.parse(localStorage.getItem('LastSynced')) || {};
      lastSynced.suppliersLastSynced = { time: value.time, count: value.count, uuid: value.uuid };
      localStorage.setItem('LastSynced', JSON.stringify(lastSynced));
    },
    
    setProductItemsLastSynced(state, value) {
      // Store count, time, and last synced UUID data in local storage under 'LastSynced' for productItemsLastSynced sync data
      const lastSynced = JSON.parse(localStorage.getItem('LastSynced')) || {};
      lastSynced.productItemsLastSynced = { time: value.time, count: value.count, uuid: value.uuid };
      localStorage.setItem('LastSynced', JSON.stringify(lastSynced));
    },
    setSyncStatus(state, {key, syncError, syncUUIDSucces, syncLoader, noData }) {
      // Set state variables based on last sync response:
      // - error => syncError
      // - syncUUIDSuccess => show refresh button
      // - syncLoader => show loader
      // - noData => bcp has no data for order/invoice, then set this to true
      const syncStatus = state.syncStatus
      syncStatus[key] = {syncError,syncUUIDSucces,syncLoader, noData};
    },
    setLatestDrugEntriesMutationRequest(state, value){
      state.latestDrugEntriesMutationRequest = value;
    },
    setInvoiceCCFlag(state, value) {
      state.invoiceCCFlag = value;
      localStorage.setItem('invoiceCCFlag', value);
    },
    setOrderCCFlag(state, value) {
      state.orderCCFlag = value;
      localStorage.setItem('orderCCFlag', value);
    }
  },
  actions: {
    getPatientSearchResults(state, quickSearch) {
      state.commit('setAuthorization');
      state.commit('setQuickSearch', quickSearch);
      state.commit('setLoading', { state: true, type: 'Patients' });
      state.commit('setPatients', []);
      axios.post('/search-patients', { 
          page: 1,
          name: quickSearch,
          location: this.state.currentLocation.uuid,
          include_inactive: this.state.includeInactive
        },
          this.state.header
        ).then(res => {
          state.commit('setPatients', res.data.patients);
          state.commit('setLoading', { state: false, type: 'Patients' });
          this.$store.dispatch('getRefreshToken');
        }).catch(err => {
          state.commit('setLoading', { state: false, type: 'Patients' });
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    getAlerts(state){
      state.commit('setLoadingAlerts', true);
      axios.post('/get-alerts', {page: 1, read: false}, this.state.header).then(res=>{
          let alerts = res.data.data;
          
          let now = new Date()
          state.commit('setLastAlertRefresh', now.toLocaleTimeString('en-AU'));
          alerts.forEach((alert, index)=>{
            alert.read = false;
            alert.index= index
          })
          state.commit('setAlerts', alerts);
          if(res.data.data.length){
            state.commit('setDisplayAlerts', true);
          }
          state.commit('setLoadingAlerts', false);
      })
    },
    readAlert(state, alert){
      store.commit('setAlertAsRead', alert.index);
      axios.post('/read-alerts', {alert_ids: [alert.id]}, this.state.header).then(res=>{
        
        console.log('this alert has been read', res);
      })
    },
    getRefreshToken(state) {
      if (this.refreshTimeOut){
        clearTimeout(this.refreshTimeOut);
      }
      this.refreshTimeOut = setTimeout(() => {
        state.commit('setAuthorization');
        let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
        let currentTime = new Date();
        currentTime = currentTime.getTime();
        let timeDiff = (currentTime - storedTime)/ (1000 * 60) % 60;
  
        console.log('this is the time since last refresh', timeDiff);
        if (timeDiff > 5) {
          axios.post('/refresh', {}, this.state.header).then(res => {
            state.commit('setToken', res.data.access_token);
            let cookie = "auth="+res.data.access_token+"; max-age="+3600+"; path=/";
            document.cookie = cookie;
            
            console.log('set the localstorage last refresh to current time', currentTime);
            localStorage.removeItem('lastRefresh');
            localStorage.setItem('lastRefresh', currentTime);
          }).catch(err=>{
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired');
                localStorage.removeItem('lastRefresh');
                state.commit('setAlerts', null)
                router.push('/login');
            }
          })
        } else{
          console.log('do not need to get refresh token');
        }
      }, 1500); // delay
      
    },
    timeRunner(state){
      
      let interval = setInterval(() => {
        // get local storage time
        let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
        // calculate 15mins ahead
        let expiryTime = storedTime + 900000;
        // get current time
        let currentTime = new Date();
        currentTime = currentTime.getTime();
        // calc time to expiry
        let timeToExpiry = expiryTime - currentTime;
        // run timer using setInterval

        let timer = timeToExpiry/1000;
          timer--;
          this.loadingDelay = true;
          state.commit('setTimeToLogOut', timer)
          // once timer gets to 0, direct to login page and stop running
          if(!window.location.pathname.includes('/onboarding/') && !window.location.pathname.includes('reset-password')){
            if (timer < 1) {
              console.log('TIME TO RETURN TO LOGIN');
              localStorage.removeItem('lastRefresh');
              state.commit('setDisplayTimeRunnerModal', false);
              clearInterval(interval);
              router.push('/login')
            } else{
              // run check to see if time diff is at or below 2mins
              // if condition met, display modal
              if(timer <= 120 ){
                console.log('DEPLOYING TIMER MODAL');
                state.commit('setDisplayTimeRunnerModal', true);
              } else{
                state.commit('setDisplayTimeRunnerModal', false);
              }
              // console.log('THIS IS THE TIME TO EXPIRY', timer);
            }
          }
          
      }, 1000)
          // if user clicks "extend session" on modal, refreshtoken is retrieved, local storage time is reset (DONE IN MODAL)
    },
    checkSyncResident(state){
      // checks to see if residents are in sync, to be run for RACF users upon login
      if(this.state.userType == 'aged care' && this.state.user.integration_id){
        console.log('running check sync resident');
        let payload = {
          integration_id: this.state.user.integration_id,
          facility_id: this.state.currentLocation.facility.uuid,
          integration_facility_id: this.state.user.integration_facility_id
        }
        axios.post('/check-sync',payload, this.state.header).then(res=>{
          console.log('have run check-sync', res.data);
          // if response from rust is false, then sync is required, if not then SR and CMS are in sync
          state.commit('setIntegrationPrompt', res.data ? false : true);
        })
        
      }
    },
    submitForm(state,form) {
      state.commit('setAuthorization');
      console.log('this is what gets submitted', this.state.verify);
      if (form.type == 'stock-take-submit') {
        axios.post(`/${form.type}`, form.submit, this.state.header).then(res => {
          console.log('form has been submitted', res);
          state.commit('updateCount', []);
          state.dispatch('getRefreshToken');
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      } else {
        axios.post(`/location/${this.state.uuid}/${form.type}`,  form.submit, this.state.header).then(res => {
          console.log('form has been submitted', res);
          state.dispatch('getRefreshToken');
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      }
    },
    getDrugRegister(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getDrugRegister');
      state.commit('setResponsiblePersonError',  {state: false, errorMessage: ''});
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Drug Register'});
      form.filters.imprest_only = this.state.drugRegImprestToggle;
      form.filters.destruction_only = this.state.drugRegDestructionToggle;
      axios.post('/drug-register-entries', form, this.state.header).then(res => {
        console.log('this is the state function to get drug register', res.data);
        if (res.data?.data?.length > 0) {
          if(this.state.latestDrugEntriesMutationRequest == 'getDrugRegister'){
            state.commit('setDrugEntries', res.data);
          }
          state.commit('setLoading', {state: false, type: 'Drug Register'});
          state.dispatch('getRefreshToken');
        }else{
          state.commit('setResponsiblePersonError',  {state: true, errorMessage: "No DrugEntries."});
        }
      }).catch(err => {
        console.log(err.response);
        if(err.response.status == 403){
          state.commit('setResponsiblePersonError',  {state: true, errorMessage: err.response.data});
        }
        if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
            console.log('yes, it does include expired');
            state.commit('setLoading', {state: false, type: 'Drug Register'});
            router.push('/login');
        } 
      })
    },
    async getLocationUser(state, uuid) {
      state.commit('setLoading', {state: true, type: 'client'});
      try {
         const response = axios.post(`/warehouse/location/${uuid}`, { drug: "" }).then((response)=>{
           console.log("response>>",response);
          if (response.status === 200) {
            state.commit('setLocationData', response.data);
            state.commit('setLoading', {state: false, type: 'client'});
          return response.data;
          }
          return response.data;
        });
       
      } catch (error) {
        console.error("Error:", error);
        state.commit('setLoading', {state: false, type: 'client'});
      }
    },
    getReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setReportError', '');
      
      console.log('this is the drug reg form after imprest only change', form);
      state.commit('setLoading', {state: true, type: 'Report'});
      console.log(form);
      axios.post('/transaction-report-entries', form, this.state.header).then(res=>{
        console.log('this is the state function to get transactions reports', res.data);
        state.commit('setReports', res.data);
        state.commit('setLoading', {state: false, type: 'Report'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getStockReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Stock Take'});
      state.commit('setReportError', '');
      axios.post('/stock-report-entries', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setStockReports', res.data);
        state.commit('setLoading', {state: false, type: 'Stock Take'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getAuditReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Audit'});
      state.commit('setReportError', '');
      axios.post('/get-logs', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setAuditReports', res.data);
        state.commit('setLoading', {state: false, type: 'Audit'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getIHIReportEntries(state, form) {
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'IHI'});
      state.commit('setReportError', '');
      axios.post('/audit-ihi', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setIHIReports', res.data);
        state.commit('setLoading', {state: false, type: 'IHI'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError', "Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getEPrescribingReportEntries(state, form) {
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'EPrescribing'});
      state.commit('setReportError', '');
      axios.post('/get-eprescribing-logs', form, this.state.header).then(res => {
        console.log('this is the state function to get reports', res.data);
        state.commit('setEPrescribingReports', res.data);
        state.commit('setLoading', {state: false, type: 'EPrescribing'});
        state.dispatch('getRefreshToken');
      }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError', "Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getFacilityPrescriptions(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Prescriptions'});
      state.commit('setReportError', '');
      axios.post('/search-prescriptions', form, this.state.header).then(res => {
        console.log('this is the state function to get reports', res.data);
        state.commit('setPrescriptionsReport', res.data);
        state.commit('setLoading', {state: false, type: 'Prescriptions'});
        state.dispatch('getRefreshToken');
      }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError', "Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    searchPatients(state, form){
      state.commit('setAuthorization');
      axios.post('/search-patients', form, this.state.header).then(res=>{
        console.log('this is the state function to search patients', res.data);
        state.commit('setPatients', res.data.patients);
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    getStockTake(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getStockTake');
      state.commit('setAuthorization');
      state.commit('setStockError', '');
      state.commit('setLoading', {state: true, type: 'Stock Take'});
        axios.post('/stock-take-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get stock entries', res.data);
          if(this.state.latestDrugEntriesMutationRequest == 'getStockTake'){
            state.commit('setDrugEntries', res.data);
          }
          state.dispatch('getRefreshToken');
          return res;
        }).then(data=>{
          if(data){
            this.state.drugEntries.data.forEach(item=>{
              this.state.stockTakeCount.forEach(stockItem=>{
                if(item.uuid == stockItem.uuid){
                  item.count = stockItem.count;
                }
              })
            });  
          }
          state.commit('setLoading', {state: false, type: 'Stock Take'});
        }).catch(err=>{
            if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }else{
              console.log('THIS IS ANOTHER ERROR', err.response.data);
              state.commit('setStockError', err.response.data);
            }
        })
    },
    getTransfers(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getTransfers');
      state.commit('setAuthorization');
      state.commit('setTransferError', '');
      state.commit('setLoading', {state: true, type: 'Transfers'});
      axios.post('/transfer-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get transfer entries', res.data);
          if (res.data.data.length > 0) {
            if(this.state.latestDrugEntriesMutationRequest == 'getTransfers'){
              state.commit('setDrugEntries', res.data);
            }
            state.commit("setLoading", { state: false, type: "Transfers" });
            state.dispatch("getRefreshToken");
          } else {
            state.commit("setTransferError", "No Transfer data");
          }
          return res;
        }).catch(err=>{
          state.commit('setTransferError', err.response.data);
          console.log(err.response.data)
            if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    getDeliveries(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getDeliveries');
      state.commit('setDeliveryError', '');
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Deliveries'});
      axios.post('/delivery-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get delivery entries', res.data);
          if(this.state.latestDrugEntriesMutationRequest == 'getDeliveries'){
            state.commit('setDrugEntries', res.data);
          }
          state.commit('setLoading', {state: false, type: 'Deliveries'});
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
          state.commit('setDeliveryError', err.response.data);
          console.log('this is the err', err.response.data)
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    getOrders(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getOrders');
      console.log('hitting get orders');
      state.commit('setDeliveryError', '');
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Deliveries'});
      axios.post('/warehouse/retrieve-orders', form, this.state.header).then(res=>{
          console.log('this is the state function to get orders', res.data);
          let entries = res.data.data;
          if(entries?.length > 0)
          {
            if(this.state.latestDrugEntriesMutationRequest == 'getOrders'){
              state.commit('setDrugEntries', res.data);
            }
            state.commit('setLoading', {state: false, type: 'Deliveries'});
            state.dispatch('getRefreshToken');
          }
          else{
            state.commit('setDeliveryError', "No Orders data");  
          }
          // let entries = res.data.data;
          // if (entries && entries?.length > 0) {
          //   entries[0].status = 'pending';
          //   entries[3].status = 'pending';
          //   entries[4].status = 'pending';
          //   // entries.forEach(entry=>{
          //   //   entry.client = entry.supplier;
          //   //   entry.total_products = Math.ceil(Math.random() * 20);
          //   //   let min = Math.ceil(111111);
          //   //   let max = Math.floor(999999);
          //   //   entry.receipt_number =  Math.floor(Math.random() * (max - min) + min);
          //   //   let minr = Math.ceil(111111);
          //   //   let maxr = Math.floor(999999);
          //   //   entry.invoice_number =  Math.floor(Math.random() * (maxr - minr) + minr);
          //   // })
            
          // }
       
          return res;
        }).catch(err=>{
          state.commit('setDeliveryError', err.response.data);
          console.log('this is the err', err.response.data)
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },

    getPurchaseInvoices(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getPurchaseInvoices');
      state.commit('setDeliveryError', '');
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Deliveries'});
      axios.post('/warehouse/retrieve-invoices',form,this.state.header).then(res=>{
        let entries = res.data.data;
        // why get one invoices here for ? - inside WarehouseInvoice has method showContent 
        if (entries?.length > 0) {
          if(this.state.latestDrugEntriesMutationRequest == 'getPurchaseInvoices'){
            state.commit('setDrugEntries', res.data);
          }
          state.commit('setLoading', {state: false, type: 'Deliveries'});
          state.dispatch('getRefreshToken');
        }else{
          state.commit('setDeliveryError', "No warehouse invoices data");  
        }
        return res;
        }).catch(err=>{
          state.commit('setDeliveryError', err.response.data);
          console.log('this is the err', err.response.data)
        })
    },

    getBatches(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getBatches');
      state.commit('setBatchError', '');
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Batch'});
      axios.post('/warehouse/retrieve-batches', form, this.state.header).then(res=>{
        let entries = res.data.data;
        if (entries?.length > 0) {
          if(this.state.latestDrugEntriesMutationRequest == 'getBatches'){
            state.commit('setDrugEntries', res.data);
          }
          state.commit('setLoading', {state: false, type: 'Batch'});
          state.dispatch('getRefreshToken');
        }else {
          state.commit('setBatchError', "No Batches data");  
        }
          return res;
        }).catch(err=>{
          state.commit('setBatchError', err.response.data);
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    getClients(state, form) {
      state.commit('setLatestDrugEntriesMutationRequest', 'getClients');
      state.commit('setClientError', '');
      state.commit('setAuthorization');
      state.commit('setLoading', { state: true, type: 'client' });
      axios.post('/warehouse/retrieve-locations', form, this.state.header)
          .then(res => {
            let entries = res.data.data;
            if (entries?.length > 0) {
              console.log('this is the state function to get client entries', res.data);
              if(this.state.latestDrugEntriesMutationRequest == 'getClients'){
                state.commit('setDrugEntries', res.data);
              }
              state.commit('setLoading', { state: false, type: 'client' });
              state.dispatch('getRefreshToken');
              console.log(res.data, "res form client ")
            }else{
              state.commit('setClientError',"No Data found")
            }
              return res;
          })
          .catch(err => {
              state.commit('setClientError', err.response.data);

          });
  },
  
    getDestructionItems(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getDestructionItems');
      state.commit('setAuthorization');
      state.commit('setDestructionError', '');
      state.commit('setLoading', {state: true, type: 'Destructions'});
      axios.post('/destruction-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get destruction entries', res.data);
          if (res.data?.data.length > 0) {
            if(this.state.latestDrugEntriesMutationRequest == 'getDestructionItems'){
              state.commit('setDrugEntries', res.data);
            }
            state.commit('setLoading', {state: false, type: 'Destructions'});
            state.dispatch('getRefreshToken');
          }else{
            state.commit('setDestructionError', `No ${this.state.company.type == 'warehouse' ?  'Destruction' : 'Disposals'} Data`);
          }
          return res;
        }).catch(err=>{
          state.commit('setDestructionError', err.response.data);
            if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    submitDelivery(state, form){
      state.commit('setAuthorization');
      if(state) console.log('yes, state exists');
      axios.post('/new-delivery', form.submit, this.state.header).then(res=>{
        console.log('this is the state function to get transfer entries', res.data);
        state.dispatch('getRefreshToken');
        return res;
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    emptyStockCache(state){
      state.commit('updateCount', []);
    },
    getDrugList(state, form){
      state.commit('setLatestDrugEntriesMutationRequest', 'getDrugList');
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Drug List'});
      
      axios.post(form.companyList ? '/company-drugs' :'/non-company-drugs', {filter:form.filter, page: form.page}, this.state.header).then(res=>{
        console.log('this is the state function to get transfer entries', res.data);
        if(this.state.latestDrugEntriesMutationRequest == 'getDrugList'){
          state.commit('setDrugEntries', res.data);
        }
        state.commit('setLoading', {state: false, type: 'Drug List'});
        state.dispatch('getRefreshToken');
        return res;
      }).catch(err=>{
        // console.log(err.response.data)
          if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },

    // Sync Hourly Entries 

    // Get the current date in the Australia/Sydney timezone
   getLocaleTime() {
      let s = new Date();
      let d = new Date(s);
      // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      let sydneyTime = new Date(
        s.toLocaleString("en-US", { timeZone: "Australia/Sydney" })
      );
    return sydneyTime.getTime()
   },

  // Convert the date to the Australia/Sydney timezone
    async convertLocaleTime(state, data) {
      // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (data) {
        let dateString = await data;
        let parts = dateString.split(/[\s/:]/);
        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10) - 1;
        let year = parseInt(parts[2], 10);
        let hours = parseInt(parts[3], 10);
        let minutes = parseInt(parts[4], 10);
        let localDate = new Date(year, month, day, hours, minutes);
        let sydneyTime = new Date(localDate.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
        return localDate.getTime();
      }
    },

    // Every 5 seconds, call the API to get new data for invoice, order, client, supplier, and stocktake
    async lastSyncEntries(state, data) {
      // Added isSyncPending state to handle continuous Polling, timeout and manage slow network issues
      if (this.state.isSyncPending) return;
        const getLastSynced = JSON.parse(localStorage.getItem('LastSynced'))
        this.state.isSyncPending = true
        const res = await axios.get(`/integration/sync-status/${data?.uuid}`, this.state?.header).then(async (res)=> {
        if (res?.status === 200) {
          const response = res.data.data[0]
          this.state.isSyncPending = false;
             state.commit('setSyncStatus',{key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false});
          // If response status is 'processing', call the API up to 12 times
          if (response.status == "processing") {
            state.commit('setSyncStatus',{key: data?.data.sync,  syncError: '', syncUUIDSucces: false, syncLoader:true });
            if (data?.data.sync == 'invoices') {
              state.commit('setSyncStatus', { key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader:data?.single ? true : getLastSynced?.invoicesLastSynced?.count >= 12 ? false : true });
            }else if(data?.data.sync == 'orders'){
              state.commit('setSyncStatus', { key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader:data?.single ? true : getLastSynced?.ordersLastSynced?.count >= 12 ? false : true });
            }else if(data?.data.sync == 'clients'){
              state.commit('setSyncStatus', { key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader:data?.single ? true : getLastSynced?.clientsLastSynced?.count >= 12 ? false : true });
            }else if(data?.data.sync == 'suppliers'){
              state.commit('setSyncStatus', { key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader:data?.single ? true : getLastSynced?.suppliersLastSynced?.count >= 12 ? false : true });
            }else if(data?.data.sync == 'stocktakes'){
              state.commit('setSyncStatus', { key: data?.data.sync, syncError: '', syncUUIDSucces: false, syncLoader:data?.single ? true : getLastSynced?.productItemsLastSynced?.count >= 12 ? false : true });
            }
            return response
           // If response status is 'failed', clear the interval and stop further API calls 
          }else if(response.status == "failed"){
             state.commit('setSyncStatus',{key: data?.data.sync, syncError: response.message, syncUUIDSucces: false, syncLoader: false });

            clearInterval(this.state[data?.data?.syncInterval])

            if (data?.data.sync == 'invoices') {
              clearInterval(this.state.syncInvoiceHourlyInterval)
            }else if(data?.data.sync == 'orders'){
              clearInterval(this.state.syncOrdersHourlyInterval)
            }else if(data?.data.sync == 'clients'){
              clearInterval(this.state.syncClientsHourlyInterval)
            }else if(data?.data.sync == 'suppliers'){
              clearInterval(this.state.syncSuppliersHourlyInterval)
            }else if(data?.data.sync == 'stocktakes'){
              clearInterval(this.state.syncStockTakeHourlyInterval)
            }
            return response
          // If response status is 'completed', show the refresh button and retrieve the new data for invoice, order, client, supplier, and stocktake  
          }else if (response.status == "completed") {
            state.commit('setSyncStatus',{key: data?.data.sync, syncError: '', syncUUIDSucces: true, syncLoader: false  });
            clearInterval(this.state[data?.data?.syncInterval])
            const currTime = await state.dispatch('getLocaleTime')
            if (data?.data.sync == 'invoices') {
              await state.commit('setInvoicesLastSynced', { time: currTime, count: getLastSynced ? getLastSynced?.invoicesLastSynced?.count > 12 ? 0 : getLastSynced?.invoicesLastSynced?.count +  1 : 0, uuid: getLastSynced?.invoicesLastSynced?.uuid })
              clearInterval(this.state.syncInvoiceHourlyInterval)
            }else if(data?.data.sync == 'orders'){
              await state.commit('setOrdersLastSynced', { time: currTime, count: getLastSynced ? getLastSynced?.ordersLastSynced?.count > 12 ? 0 : getLastSynced?.ordersLastSynced?.count +  1 : 0, uuid: getLastSynced?.ordersLastSynced?.uuid })
              clearInterval(this.state.syncOrdersHourlyInterval)
            }else if(data?.data.sync == 'clients'){
              await state.commit('setClientsLastSynced', { time: currTime, count: getLastSynced ? getLastSynced?.clientsLastSynced?.count > 12 ? 0 : getLastSynced?.clientsLastSynced?.count +  1 : 0, uuid: getLastSynced?.clientsLastSynced?.uuid })
              clearInterval(this.state.syncClientsHourlyInterval)
            }else if(data?.data.sync == 'suppliers'){
              await state.commit('setSuppliersLastSynced', { time: currTime, count: getLastSynced ? getLastSynced?.suppliersLastSynced?.count > 12 ? 0 : getLastSynced?.suppliersLastSynced?.count +  1 : 0, uuid: getLastSynced?.suppliersLastSynced?.uuid })
              clearInterval(this.state.syncSuppliersHourlyInterval)
            }else if(data?.data.sync == 'stocktakes'){
              await state.commit('setProductItemsLastSynced', { time: currTime, count: getLastSynced ? getLastSynced?.productItemsLastSynced?.count > 12 ? 0 : getLastSynced?.productItemsLastSynced?.count +  1 : 0, uuid: getLastSynced?.productItemsLastSynced?.uuid })
              clearInterval(this.state.syncStockTakeHourlyInterval)
            }
            this.state.LastSync = await JSON.parse(localStorage.getItem('LastSynced'))
            return response
          }
        }
      }).catch((error) => {
        state.commit('setSyncStatus',{key: data?.data.sync, syncError: error, syncUUIDSucces: false, syncLoader: false });
        if (data?.data.sync == 'invoices') {
          clearInterval(this.state.syncInvoiceHourlyInterval)
        }else if(data?.data.sync == 'orders'){
          clearInterval(this.state.syncOrdersHourlyInterval)
        }else if(data?.data.sync == 'clients'){
          clearInterval(this.state.syncClientsHourlyInterval)
        }else if(data?.data.sync == 'suppliers'){
          clearInterval(this.state.syncSuppliersHourlyInterval)
        }else if(data?.data.sync == 'stocktakes'){
          clearInterval(this.state.syncStockTakeHourlyInterval)
        }
        clearInterval(this.state[data?.data?.syncInterval])
      })
    },

    // This function handles polling API calls every 5 seconds
    pollingEntries(state, data) {
            this.state[data?.syncInterval] = setInterval(async () => {
        const getUser = JSON.parse(localStorage.getItem('user'))
        if(getUser){
        const getLastSynced = JSON.parse(localStorage.getItem('LastSynced'))
          if (data.sync == 'invoices') {
            if (getLastSynced && getLastSynced?.invoicesLastSynced?.count >= 12) {
              clearInterval(this.state[data?.syncInterval])
              state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false });
            }else {
              if (!this.state.isSyncPending){
                await state.commit('setInvoicesLastSynced', { time: getLastSynced?.invoicesLastSynced?.time, count: getLastSynced ? getLastSynced?.invoicesLastSynced?.count > 12 ? 1 : getLastSynced?.invoicesLastSynced?.count +  1 : 1, uuid: getLastSynced?.invoicesLastSynced?.uuid })
                state.dispatch('lastSyncEntries', {uuid: data.bcpStatus.uuid,data:data});
              }
            }
          }else if (data.sync == 'orders') {
            if (getLastSynced && getLastSynced?.ordersLastSynced?.count >= 12) {
              clearInterval(this.state[data?.syncInterval])
              state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false });
            }else {
              if (!this.state.isSyncPending) {
                await state.commit('setOrdersLastSynced', { time: getLastSynced?.ordersLastSynced?.time, count: getLastSynced ? getLastSynced?.ordersLastSynced?.count > 12 ? 1 : getLastSynced?.ordersLastSynced?.count +  1 : 1, uuid: getLastSynced?.ordersLastSynced?.uuid })
                state.dispatch('lastSyncEntries', {uuid: data.bcpStatus.uuid,data:data});
              }
            }
          }else if (data.sync == 'clients') {
            if (getLastSynced && getLastSynced?.clientsLastSynced?.count >= 12) {
              clearInterval(this.state[data?.syncInterval])
               state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false });
            }else {
              if (!this.state.isSyncPending) {
                await state.commit('setClientsLastSynced', { time: getLastSynced?.clientsLastSynced?.time, count: getLastSynced ? getLastSynced?.clientsLastSynced?.count > 12 ? 1 : getLastSynced?.clientsLastSynced?.count +  1 : 1, uuid: getLastSynced?.clientsLastSynced?.uuid })
                state.dispatch('lastSyncEntries', {uuid: data.bcpStatus.uuid,data:data});
              }
            }
          }else if (data.sync == 'suppliers') {
            if (getLastSynced && getLastSynced?.suppliersLastSynced?.count >= 12) {
              clearInterval(this.state[data?.syncInterval])
              state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false });
            }else {
              if (!this.state.isSyncPending){
                await state.commit('setSuppliersLastSynced', { time: getLastSynced?.suppliersLastSynced?.time, count: getLastSynced ? getLastSynced?.suppliersLastSynced?.count > 12 ? 1 : getLastSynced?.suppliersLastSynced?.count +  1 : 1, uuid: getLastSynced?.suppliersLastSynced?.uuid })
                state.dispatch('lastSyncEntries', {uuid: data.bcpStatus.uuid,data:data});
              }
            }
          }else if (data.sync == 'stocktakes') {
            if (getLastSynced && getLastSynced?.productItemsLastSynced?.count >= 12) {
              clearInterval(this.state[data?.syncInterval])
              state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false });
            }else {
              if (!this.state.isSyncPending) {
                await state.commit('setProductItemsLastSynced', { time: getLastSynced?.productItemsLastSynced?.time, count: getLastSynced ? getLastSynced?.productItemsLastSynced?.count > 12 ? 1 : getLastSynced?.productItemsLastSynced?.count +  1 : 1, uuid: data.bcpStatus.uuid })
                state.dispatch('lastSyncEntries', {uuid: getLastSynced?.productItemsLastSynced?.uuid,data:data});
              }
            }
          }
          this.state.LastSync = await JSON.parse(localStorage.getItem('LastSynced'))
        }else{
          clearInterval(this.state[data?.syncInterval])
          clearInterval(this.state.syncInvoiceHourlyInterval)
          clearInterval(this.state.syncOrdersHourlyInterval)
          clearInterval(this.state.syncClientsHourlyInterval)
          clearInterval(this.state.syncSuppliersHourlyInterval)
          clearInterval(this.state.syncStockTakeHourlyInterval)
        }
      }, 5000)
    },

    // Get the last sync status
    async checkLastSyncStatus(state, data) {
      try {
        state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: true, noData: false });
          const res = await axios.post('/bcp/documents', {
            type: [data.type],
            location_id: this.state?.currentLocation?.uuid
          }, this.state?.header);

          if (res?.status === 200) {
              if (res?.data?.data?.length > 0) {
                const storedTime = data?.lastSynced?.time
                const currentTime = new Date().getTime()
                const diffTime1 = ( currentTime - storedTime ) / (1000 * 60 * 5) 
                const localetimestamp =  await state.dispatch('convertLocaleTime', res?.data?.data[0]?.created_at)
                const BcpUUID = res?.data?.data[0]?.uuid
                // data?.lastSynced retrieves how many times the API was called
                // If it's less than 12 times, continue calling the API up to 12 times before forcing a call
                // If the last synced status is still 'processing' and the API has not been forcefully called,we can call the API only one more time
                if (data?.lastSynced && data?.lastSynced?.count >= 12) {
                  if(data.sync == 'orders' && !data?.value){
                      if (res?.data?.data[0]?.status == "processing" ) {
                        state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: true });
                        state.commit('setOrdersLastSynced', {
                         time: localetimestamp,
                         count: 12,
                         uuid: BcpUUID
                        });
                        return res?.data?.data[0];
                      }else{
                        // If the last synced UUID and the response UUID match, and the last synced time is within the past 5 minutes then show the refresh button
                        if (data?.lastSynced?.uuid == res?.data?.data[0]?.uuid ) {
                          data.ordersFrom = ''
                          data.ordersTo = ''
                           state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: true, syncLoader: false });
                          state.commit('setOrdersLastSynced', {
                           time: localetimestamp,
                           count: 0,
                           uuid: BcpUUID
                          });
                         return { status: "limitout" };
                         }else{
                           state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false  });
                          state.commit('setOrdersLastSynced', {
                           time: localetimestamp,
                           count: 0,
                           uuid: BcpUUID
                          });
                          return res?.data?.data[0];
                         }
                      }
                  }else{
                    if (res?.data?.data[0]?.status == "completed" || res?.data?.data[0]?.status == "failed") {
                      if (data.sync == 'invoices') {
                        state.commit('setInvoicesLastSynced', {
                         time: localetimestamp,
                         count: 0,
                         uuid: BcpUUID
                       });
                       }else if (data.sync == 'orders') {
                        if (data?.lastSynced?.uuid == res?.data?.data[0]?.uuid ) {
                          data.ordersFrom = ''
                          data.ordersTo = ''
                           state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: true, syncLoader: false  });
                          state.commit('setOrdersLastSynced', {
                           time: localetimestamp,
                           count: 0,
                           uuid: BcpUUID
                          });
                          return { status: "limitout" };
                         }else{
                           state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false   });
                          state.commit('setOrdersLastSynced', {
                           time: localetimestamp,
                           count: 0,
                           uuid: BcpUUID
                          });
                          return res?.data?.data[0];
                         }
                       }else if (data.sync == 'clients') {
                        state.commit('setClientsLastSynced', {
                          time: localetimestamp,
                          count: 0,
                          uuid: BcpUUID
                        });
                       }else if (data.sync == 'suppliers') {
                        state.commit('setSuppliersLastSynced', {
                          time: localetimestamp,
                          count: 0,
                          uuid: BcpUUID
                        });
                       }else if (data.sync == 'stocktakes') {
                        state.commit('setProductItemsLastSynced', {
                          time: localetimestamp,
                          count: 0,
                          uuid: BcpUUID
                        });
                      }
                      return res?.data?.data[0];
                    }else{
                        const result = await state.dispatch('lastSyncEntries', { uuid: res?.data?.data[0].uuid, data: data, single: true});
                          if (result?.status == 'completed' && data?.lastSynced?.uuid == result?.uuid) {
                          state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: true, syncLoader: false   });
                          }else{
                            return {...res?.data?.data[0], status2: result?.status}
                          }
                    }
                  }
               }else{
                if(data.sync == 'orders'){
                  if (res?.data?.data[0]?.status == "processing" ) {
                     state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: true   });
                    state.commit('setOrdersLastSynced', {
                     time: localetimestamp,
                     count: data?.lastSynced?.count,
                     uuid: BcpUUID
                    });
                    return res?.data?.data[0];  
                  }else{
                    if (data?.lastSynced?.uuid == res?.data?.data[0]?.uuid ) {
                     state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: true, syncLoader: false   });
                      state.commit('setOrdersLastSynced', {
                       time: localetimestamp,
                       count: 0,
                       uuid: BcpUUID
                      });
                      return { status: "limitout" };
                     }else{
                       state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false    });
                      state.commit('setOrdersLastSynced', {
                       time: localetimestamp,
                       count: 0,
                       uuid: BcpUUID
                      });
                      return res?.data?.data[0];
                     }
                  }
              }else{
                if (data.sync == 'invoices') {
                  await state.commit('setInvoicesLastSynced', {
                   time: localetimestamp,
                   count: res?.data?.data[0]?.status == "processing" ? 12 : 0,
                   uuid: BcpUUID
                 });
                 }else if (data.sync == 'clients') {
                  await state.commit('setClientsLastSynced', {
                    time: localetimestamp,
                    count: res?.data?.data[0]?.status == "processing" ? 12 : 0,
                    uuid: BcpUUID
                  });
                 }else if (data.sync == 'suppliers') {
                  await state.commit('setSuppliersLastSynced', {
                    time: localetimestamp,
                    count: res?.data?.data[0]?.status == "processing" ? 12 : 0,
                    uuid: BcpUUID
                  });
                 }else if (data.sync == 'stocktakes') {
                  await state.commit('setProductItemsLastSynced', {
                    time: localetimestamp,
                    count: res?.data?.data[0]?.status == "processing" ? 12 : 0,
                    uuid: BcpUUID
                  });
                }
                  return res?.data?.data[0];
                }
               }
              }else{
                // If the last synced data does not exist:
                  // - For Invoice, `syncFrom(Date)` is required
                  // - For Order, `ordersFrom(Number)` is required
                if (data.sync == 'invoices' && !data.syncFrom) {
                  await state.commit('setInvoicesLastSynced', {
                   time: data?.lastSynced?.time,
                   count: 0,
                   uuid: ''
                 });
                   return { status: "blank" };
                 }else if (data.sync == 'orders' && (!data.ordersFrom)) {
                  await state.commit('setOrdersLastSynced', {
                    time: data?.lastSynced?.time,
                    count: 0,
                    uuid: ''
                  });
                   return { status: "blank" };
                 }else if (data.sync == 'clients') {
                  await state.commit('setClientsLastSynced', {
                    time: data?.lastSynced?.time,
                    count: 0,
                    uuid: ''
                  });
                  return { status: "" }
                 }else if (data.sync == 'suppliers') {
                  await state.commit('setSuppliersLastSynced', {
                    time: data?.lastSynced?.time,
                    count: 0,
                    uuid: ''
                  });
                  return { status: "" }
                 }else if (data.sync == 'stocktakes') {
                  await state.commit('setProductItemsLastSynced', {
                    time: data?.lastSynced?.time,
                    count: 0,
                    uuid: ''
                  });
                  return { status: "" }
                 }else{
                   return { status: "" }
                }
           }
          } 
        } catch (error) {
          clearInterval(this.state[data?.syncInterval])
          return { error: error?.message };
        }
    },
    
   async syncEntries(state, data) {
      // check flags
      if (data.sync == 'invoices' && !state.getters.getInvoiceCCFlag) return;
      if (data.sync == 'orders' && !state.getters.getOrderCCFlag) return;
      // Checking Last Sync Status
      data.syncInterval = `sync${data.sync}`
      const getUser = JSON.parse(localStorage.getItem('user'))
      this.state.isSyncPending = false
      if (getUser) {
      const getLastSynced = await JSON.parse(localStorage.getItem('LastSynced'));
      // Pass the 'lastSynced' data from local storage and 'bcpStatus' data from bcp response
      data.lastSynced = await getLastSynced?.[`${data.sync == 'stocktakes' ? 'productItems' : data.sync}LastSynced`];
      const bcpStatus = await state.dispatch('checkLastSyncStatus', data)
      data.bcpStatus = bcpStatus
      this.state.LastSync = await JSON.parse(localStorage.getItem('LastSynced'))
      const storedDocTime = await state.dispatch('convertLocaleTime', bcpStatus?.created_at)
      // Get Time Based on Australia 
      const currentTime = await state.dispatch('getLocaleTime');
      let Timedifference 
      
      // Handle API calling based on the last bcpStatus
      if (bcpStatus?.status == "" || bcpStatus?.status == "failed") {
        Timedifference = 1
      }else{
        if (bcpStatus?.status == 'processing') {
          Timedifference = 0
        }else{
        // Add a 'limitout' response in bcp for cases where the last sync for order/Invoice was less than 5 minutes ago, Show the refresh button in this case
          if (bcpStatus?.status !== "limitout") {
          state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false, noData: false    });
          }
          if(data.sync == 'orders'){
            if (!data?.value) {
              if (bcpStatus?.status !== "limitout") {
                // Check the time difference variable to determine if the last sync was within the past 5 minutes
                // If the time difference is more than 5 minutes, initiate a new sync
                Timedifference = (currentTime - storedDocTime) / (1000 * 60 * 5);
              }else{
                Timedifference = 0
              }
            }else{
              if (bcpStatus?.status !== "limitout") {
                Timedifference = 1
              }else{
                Timedifference = 0
              }
            }
          }else{
            if (!data?.value) {
              Timedifference = (currentTime - storedDocTime) / (1000 * 60 * 5);
            }else{
              Timedifference = 1
            }
          }
        }
      }

      let manageTime;
      let from_date = '';
      let to_date = ''
      if (data.syncFrom?.length) {
        from_date = new Date(data.syncFrom[0]).toLocaleDateString("en-CA");
        to_date = data.syncFrom[1] ? new Date(data.syncFrom[1]).toLocaleDateString("en-CA") : new Date(data.syncFrom[0]).toLocaleDateString("en-CA");
      } else {
        from_date = new Date().toLocaleDateString("en-CA");
        // to_date = new Date().toLocaleDateString("en-CA");
        to_date = null;
        manageTime = new Date().toLocaleDateString('en-CA')
      }
      // this may be problematic as it sends the original back by one day
      // If the last bcp entries' status is 'pending' and the API has not been called 12 times, continue calling the sync API up to 12 times by using pollingEntries function
      if (bcpStatus && bcpStatus?.status === "processing") {
        // if (!data.syncFrom && !getLastSynced) return;
       if (data.sync == 'invoices') {
          state.commit('setInvoicesLastSynced', {
           time: getLastSynced?.invoicesLastSynced?.time,
           count: getLastSynced?.invoicesLastSynced?.count ? bcpStatus?.status2 == "completed" ? 1 : getLastSynced?.invoicesLastSynced?.count +  1 : 1,
           uuid: bcpStatus?.uuid
         });
       }else if (data.sync == 'orders') {
          state.commit('setOrdersLastSynced', {
          time: getLastSynced?.ordersLastSynced?.time,
          count: getLastSynced?.ordersLastSynced?.count ? bcpStatus?.status2 == "completed" ? 1 : getLastSynced?.ordersLastSynced?.count +  1 : 1,
          uuid: bcpStatus?.uuid
        });
       }else if (data.sync == 'clients') {
         state.commit('setClientsLastSynced', {
          time: getLastSynced?.clientsLastSynced?.time,
          count: getLastSynced?.clientsLastSynced?.count ? bcpStatus?.status2 == "completed" ? 1 : getLastSynced?.clientsLastSynced?.count +  1 : 1,
          uuid: bcpStatus?.uuid
        });
       }else if (data.sync == 'suppliers') {
         state.commit('setSuppliersLastSynced', {
          time: getLastSynced?.suppliersLastSynced?.time,
          count: getLastSynced?.suppliersLastSynced?.count ? bcpStatus?.status2 == "completed" ? 1 : getLastSynced?.suppliersLastSynced?.count +  1 : 1,
          uuid: bcpStatus?.uuid
        });
       }else if (data.sync == 'stocktakes') {
         state.commit('setProductItemsLastSynced', {
          time: getLastSynced?.productItemsLastSynced?.time,
          count: getLastSynced?.productItemsLastSynced?.count ? bcpStatus?.status2 == "completed" ? 1 : getLastSynced?.productItemsLastSynced?.count +  1 : 1,
          uuid: bcpStatus?.uuid
        });
       }
         state.dispatch('pollingEntries', data);
        // If the last synced data does not exist in Invoice and Order only
          // - For Invoice, `syncFrom(Date)` is required
          // - For Order, `ordersFrom(Number)` is required 
      }else if(bcpStatus && bcpStatus?.status === "blank"){
        if (data.value) {
        state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader: false, noData: true  });
        }

        return ;
      // If any API call fails, show an error in a popup
      }else if (bcpStatus && bcpStatus?.error) {
        state.commit('setSyncStatus',{key: data.sync, syncError: bcpStatus?.error, syncUUIDSucces: false, syncLoader:false });
        clearInterval(this.state[data?.syncInterval])
      } else if(Timedifference >= 1) {
        // Getting new sync data for Invoice, Orders, Clients, Supplier, and Stocktake
        state.commit('setSyncStatus',{key: data.sync, syncError: '', syncUUIDSucces: false, syncLoader:true });
        axios.post(data.endpoint, {
          facility_id: this.state?.uuid,
          integration_id: this.state?.user?.integration_id,
          ...(data.sync == 'invoices' && from_date && {from_date: from_date}),
          ...(data.sync == 'invoices' && to_date && {to_date: to_date}),
          ...(data.ordersFrom) && { number_from: data.ordersFrom },
          ...(data.ordersTo) && { number_to: data.ordersTo }
        }, this.state?.header).then(async res => {
          if (res?.status === 200) {
            let currentTime = new Date();
            currentTime = currentTime.getTime();
            
            if (data.sync == 'invoices') {
              state.commit('setInvoicesLastSynced', { time: getLastSynced?.invoicesLastSynced?.time , count: 0, uuid: res?.data.uuid })
            }else if (data.sync == 'orders') {
              state.commit('setOrdersLastSynced', { time: getLastSynced?.ordersLastSynced?.time, count: 0, uuid: res?.data.uuid })
            }else if (data.sync == 'clients') {
              state.commit('setClientsLastSynced', { time: getLastSynced?.clientsLastSynced?.time, count: 0, uuid: res?.data.uuid })
            }else if (data.sync == 'suppliers') {
              state.commit('setSuppliersLastSynced', { time: getLastSynced?.suppliersLastSynced?.time, count: 0, uuid: res?.data.uuid })
            }else if (data.sync == 'stocktakes') {
              state.commit('setProductItemsLastSynced', { time: getLastSynced?.productItemsLastSynced?.time, count: 0, uuid: res?.data.uuid })
            }
            data.bcpStatus.uuid = res?.data.uuid
            state.dispatch('pollingEntries', data);
            return res;
          } else {
             state.commit('setSyncStatus',{key: data.sync, syncError: "Something went wrong!", syncUUIDSucces: false, syncLoader:false });
          }
        }).catch(err => {
            state.commit('setSyncStatus',{key: data.sync, syncError: err, syncUUIDSucces: false, syncLoader:false });
          clearInterval(this.state[data?.syncInterval])
        })
      }
    }else{
      clearInterval(this.state.syncInterval)
      clearInterval(this.state.syncInvoiceHourlyInterval)
      clearInterval(this.state.syncOrdersHourlyInterval)
      clearInterval(this.state.syncClientsHourlyInterval)
      clearInterval(this.state.syncSuppliersHourlyInterval)
      clearInterval(this.state.syncStockTakeHourlyInterval)
      }
    },

    // Refresh the Invoice, Order, Client, Supplier, and Stocktake retrieval list
    // Clear count and UUID in local storage
    async refreshSyncList(state, data){
      state.commit('setSyncStatus',{key: data, syncError: '', syncUUIDSucces: false, syncLoader:false });
      const getLastSynced = JSON.parse(localStorage.getItem('LastSynced'));
      if (data == 'invoices') {
        state.commit('setInvoicesLastSynced', { time: getLastSynced?.invoicesLastSynced?.time , count: 0, uuid: '' })
        clearInterval(this.state.syncinvoices)
      }else if (data == 'orders') {
        state.commit('setOrdersLastSynced', { time: getLastSynced?.ordersLastSynced?.time, count: 0, uuid: '' })
        clearInterval(this.state.syncorders)
      }else if (data == 'clients') {
        state.commit('setClientsLastSynced', { time: getLastSynced?.clientsLastSynced?.time, count: 0, uuid: '' })
        clearInterval(this.state.syncclients)
      }else if (data == 'suppliers') {
        state.commit('setSuppliersLastSynced', { time: getLastSynced?.suppliersLastSynced?.time, count: 0, uuid: '' })
        clearInterval(this.state.syncsuppliers)
      }else if (data == 'stocktakes') {
        state.commit('setProductItemsLastSynced', { time: getLastSynced?.productItemsLastSynced?.time, count: 0, uuid: '' })
        clearInterval(this.state.syncstocktakes)
      }
      let newLocal = await JSON.parse(localStorage.getItem('LastSynced'));
      this.state.LastSync = newLocal
    },

    syncHourly(state) {
      const getUser = JSON.parse(localStorage.getItem('user'))
      if (getUser) {
      this.state.syncInvoiceHourlyInterval = setInterval(async () => {
        const lasttime = JSON.parse(localStorage.getItem('LastSynced'))?.invoicesLastSynced?.time
        const storedtime = lasttime
        const currenttime = new Date().getTime()
        const hoursDifference = (currenttime - storedtime) / (1000 * 60 * 60);
        if (hoursDifference >= 1) {
          state.dispatch('syncEntries', { endpoint: '/integration/sync-invoices', type: 'import_invoices', value: true, sync: 'invoices' });
        }
      }, 1000)

      this.state.syncOrdersHourlyInterval = setInterval(async () => {
        const lasttime = JSON.parse(localStorage.getItem('LastSynced'))?.ordersLastSynced?.time
        const storedtime = lasttime
        const currenttime = new Date().getTime()
        const hoursDifference = (currenttime - storedtime) / (1000 * 60 * 60);
        if (hoursDifference >= 1) {
          state.dispatch('syncEntries',  {endpoint: '/integration/sync-orders', type: 'import_orders',value: true, sync: 'orders'});
        }
      }, 1000)

      this.state.syncClientsHourlyInterval = setInterval(async () => {
        const lasttime = JSON.parse(localStorage.getItem('LastSynced'))?.clientsLastSynced?.time
        const storedtime = lasttime
        const currenttime = new Date().getTime()
        const hoursDifference = (currenttime - storedtime) / (1000 * 60 * 60);
        if (hoursDifference >= 1) {
          state.dispatch('syncEntries',  {endpoint: '/integration/sync-clients', type: 'import_clients',value: true, sync: 'clients'});
        }
      }, 1000)

      this.state.syncSuppliersHourlyInterval = setInterval(async () => {
        const lasttime = JSON.parse(localStorage.getItem('LastSynced'))?.suppliersLastSynced?.time
        const storedtime = lasttime
        const currenttime = new Date().getTime()
        const hoursDifference = (currenttime - storedtime) / (1000 * 60 * 60);
        if (hoursDifference >= 1) {
          state.dispatch('syncEntries', {endpoint: '/integration/sync-suppliers', type: 'import_suppliers',value: true, sync: 'suppliers'});
        }
      }, 1000)

      this.state.syncStockTakeHourlyInterval = setInterval(async () => {
        const lasttime = JSON.parse(localStorage.getItem('LastSynced'))?.productItemsLastSynced?.time
        const storedtime = lasttime
        const currenttime = new Date().getTime()
        const hoursDifference = (currenttime - storedtime) / (1000 * 60 * 60);
        if (hoursDifference >= 1) {
          state.dispatch('syncEntries', {endpoint: '/integration/sync-items', type: 'import_drug_items',value: true, sync: 'stocktakes'});
        }
      }, 1000)
    }else{
      clearInterval(this.state.syncInterval)
      clearInterval(this.state.syncInvoiceHourlyInterval)
      clearInterval(this.state.syncOrdersHourlyInterval)
      clearInterval(this.state.syncClientsHourlyInterval)
      clearInterval(this.state.syncSuppliersHourlyInterval)
      clearInterval(this.state.syncStockTakeHourlyInterval)
      return ;
     }
    },
    
    // update Gleap with user info

    updateGleapUser(store){
      if(store.state.user){
        Gleap.identify(store.state.user.uuid, {
          name: store.state.user.first_name + " " + store.state.user.last_name,
          email: store.state.user.email,
          plan: store.state.company.type,
          companyId: store.state.company.uuid,
          companyName: store.state.company.name,
          createdAt: new Date()
        });
      }
    },
  }
})