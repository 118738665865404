<template>
    <div>
        <div class="actions-menu" style="display: flex; flex-wrap: wrap">
            <button class="actions-menu-item" @click="action('receive', 'Receive')">
                Transfer In
            </button>
            <button class="actions-menu-item" @click="action('send', 'Send')">
                Transfer Out
            </button>
            <button v-if="cartonCloudRestriction || $store.state.company.type == 'aged care'" class="actions-menu-item" @click="action('destroy')">
               {{ $store.state.company.type == 'warehouse' ?  'New Destruction' : 'Dispose' }}
            </button>
            <button v-if="cartonCloudRestriction || $store.state.company.type == 'aged care'" class="actions-menu-item" @click="action('reverse')">
                Outgoing
            </button>
            <button class="actions-menu-item" @click="action('adjustment')">
                Adjustment
            </button>
            <button class="actions-menu-item" @click="action('administration')" v-if="$store.state.user.ecdr_active && !$store.state.user.patient_admin_active">
                Administration
            </button>
            <button class="actions-menu-item" @click="action('delivery')" v-if="cartonCloudRestriction">
                New Delivery
            </button>
            <button class="actions-menu-item" @click="action('order')" v-if="cartonCloudRestriction">
                New Order
            </button>
            <!-- <button class="actions-menu-item" @click="action('return')" v-if="$store.state.company.type == 'warehouse'">
                Returns
            </button> -->
            <button class="actions-menu-item" @click="action('batch')" v-if="$store.state.company.type == 'warehouse'">
                New Batch
            </button>
        </div>
        <!-- <Destroy v-show="displayForm=='destroy'" />
        <Move v-show="displayForm=='move'" />
        <Receive v-show="displayForm=='receive'" />
        <Reverse v-show="displayForm=='reverse'" /> -->
        <NewTransferModal v-if="(displayForm == 'send') || (displayForm == 'receive')" @close="close" :transferType="transferType" />
        <DestructionModal v-if="displayForm == 'destroy'" @close="close" :transferType="transferType" />
        <LostDamagedModal v-if="displayForm == 'reverse'" @close="close" :transferType="transferType" />
        <AdjustmentModal v-if="displayForm == 'adjustment'" @close="close" :transferType="transferType" />
        <AdministrationModal v-if="displayForm == 'administration'" @close="close" :transferType="transferType" />
        <DeliveryModal v-if="displayForm == 'delivery'" @close="close" transferType="Deliveries" buttonName="New Deliveries"/>
        <DeliveryModal v-if="displayForm == 'order'" @close="close" transferType="Deliveries" 
        buttonName="New Orders"  />
        <DeliveryModal v-if="displayForm == 'return'"  @close="close" transferType="Deliveries" buttonName="New Returns"  />
        <BatchModal v-if="displayForm == 'batch'" @close="close"/>
    </div>
</template>


<script>

// import Destroy from '@/components/actions/Destroy.vue'
// import Move from '@/components/actions/Move.vue'
// import Receive from '@/components/actions/Receive.vue'
// import Reverse from '@/components/actions/Reverse.vue'
import NewTransferModal from '@/components/modals/NewTransferModal.vue';
import DestructionModal from '@/components/modals/DestructionModal.vue';
import BatchModal from '../modals/BatchModal.vue';
import LostDamagedModal from '@/components/modals/LostDamagedModal.vue';
import AdjustmentModal from '@/components/modals/AdjustmentModal.vue';
import AdministrationModal from '@/components/modals/AdministrationModal.vue';
import DeliveryModal from '@/components/modals/DeliveryModal.vue';
// import AdministrationModal from '@/components/modals/AdministrationModal.vue';


export default{
    data(){
        return{
            transferType: '',
            displayForm:'',
            view:'',
        }
    },
    components:{
        NewTransferModal,
        DestructionModal,
        LostDamagedModal,
        AdjustmentModal,
        AdministrationModal,
        DeliveryModal,
        BatchModal
        // Destroy,
        // Move,
        // Receive,
        // Reverse
    },
    methods:{
        close(value, value2) {
            if (value) {
                this.displayForm = '';
                if (this.$route.name == 'Drug Register' && value2 == 'success') {
                    console.log('loading the drug register stuff');
                    this.$store.dispatch('getDrugRegister', {
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: false
                        }
                    });
                }
            }
        },
        action(form, type){
            console.log('this is the selected form to display', form, localStorage.getItem('user_id'));
            this.displayForm = form;
            let formtype = form;
            this.transferType = type;
            let firebaseForm = JSON.parse(localStorage.getItem('savedform'));
            console.log('this is the saved form', firebaseForm);

            if (form == 'send' || form == 'receive') {
                formtype = 'transfer'
            }
            console.log('form does exist', firebaseForm, this.displayForm);
            if (firebaseForm && firebaseForm.endpoint) {
                console.log('this is the endpoint for the firebase form', firebaseForm.endpoint, firebaseForm.endpoint.includes(formtype));
            }
        }
    },
    computed: {
        cartonCloudRestriction() {
            // this is a special carton cloud integration restriction for warehouse
            // that restricts manual new purchase or sales
            // orders creation as per DNH requirements
            // to limit creation of extraneous invoices
            if (this.$store.state.user.integration_type === 'carton-cloud') {
                return (this.$store.state.user.integration_type === 'carton-cloud' && this.$store.state.user.role.permissions.includes('can_do_admin_roles'));
            } else {
                return (this.$store.state.company.type === 'warehouse');
            }
        }
    },
    mounted(){
        console.log('this is the window view', window.location.pathname);
        this.view = window.location.pathname.slice(1);
    }
}

</script>