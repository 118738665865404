<template>
  <div class="patient-search search-container">
    <input type="text" v-model="search" placeholder="Search Resident..." v-on:keyup.enter="searchPatient" 
    style="border-radius: 12px; width: 12em; height: 37.5px">
    <!-- <PatientCamera /> -->
  </div>
</template>

<script>
// import PatientCamera from '@/components/camera/PatientCamera.vue'
export default {
  name: 'Patient-search',
  components:{
    // PatientCamera
  },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    searchPatient() {
      this.$store.dispatch('getPatientSearchResults', this.search);
      this.search = '';
      this.$router.push({
        name: "Patients"
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
