<template>
    <div class="single-user-verify-form verification-form"  style="display: flex; justify-content: flex-end; width: 100%;">
        <button v-if="!success && !textRemoveButton" :style="checkIcon ? {padding: '0'}:{}" style="font-weight: bold; margin: 0;" @click="openSignature = true">
            <i  v-if="drugSafeRemoval && !textRemoveButton" class="pi pi-eject" style="padding: 0;background-color: transparent"></i>
            <p  v-if="drugSafeRemoval && textRemoveButton" style="padding: 0;background-color: transparent">Remove from Register</p>
            <!-- <p v-if="!drugSafeRemoval && !checkIcon" style="margin: 0" >SIGN</p> -->
            <i v-if="checkIcon" :id="elemId" class="pi pi-check"></i>
        </button>
        <button v-if="textRemoveButton" class="blue-button" @click="openSignature = true">
            Remove from Register
        </button>

        
        <!-- <i v-if="success" class="pi pi-check-square" style="font-weight: bold; font-size: 1.3em !important;"
        :style="status == 'good'? {color: '#00AD50'}: status == 'warning' ? {color: '#EF9600'}:{color: '#E04F39'}"></i> -->
        <!-- :warning="warning"  -->
        <SignatureModal :notes="notes" :loop="false" :optional="optional" @close="close" :discardAvailable="discardAvailable" v-if="openSignature" 
        @isSuccessful="isSuccessful" :dual="dual" :endpoint='endpoint' :payload="payload" @drugSafeRetrievalId="drugSafeRetrievalId" :bulkAdminister="bulkAdminister" :stock_items="moddedStockItems" />
    </div>
</template>

<script>
import SignatureModal from '@/components/modals/SignatureModal.vue';

export default {
    props:['endpoint', 'dual', 'status', 'payload', 'warning', 'isCompleted', 'indices', 'discardAvailable',
    'notes', 'optional', 'drugSafeRemoval', 'checkIcon', 'elemId', 'loop', 'bulkItemsCompleted', 'id', 'isPRN', 'textRemoveButton', 'bulkAdminister', 'stock_items'],
    components:{
        SignatureModal
    },
    data(){
        return{
            openSignature: false,
            success:false,
            numSubmitted: 0,
        }
    },
    methods:{
        close(value){
            if(value){
                this.openSignature = false;
            }
        },
        isSuccessful(value){
            console.log('this is the IS SUCCESSFUL VALUE EMITTED', value);
            if(value){
                if(this.loop){
                    console.log('receiving as a loop')
                    this.$emit('submitIsCompleted', value);
                } else{
                    this.$emit('submitIsCompleted', { item: value.data, response: value.response });
                    console.log('this is what is being submitted from single Verify to selectpatadmin', { item: value.data })
                    this.$store.dispatch('getRefreshToken');
                    this.numSubmitted+=1;
                    this.$emit('close', true);
                }
            }
        },
        drugSafeRetrievalId(value){
            console.log('HIT THE DRUG SAFE RETRIEVAL ID IN SINGLEVERIFY', value);
            // SAVING DRUG SAFE DETAILS IN LOCAL STORAGE
            let save = {
                patient_id: this.payload.patient_id,
                stock_item_id: this.payload.stock_item_id,
                drug_safe_id: value
            }
            localStorage.setItem('DRET'+this.payload.stock_item_id, JSON.stringify(save));

            this.$store.dispatch('getRefreshToken');
            let emitLoad = {
                id: this.id,
                drug_safe_retrieval: value
            }
            if(value){
                this.$emit('getDrugId', emitLoad);
                this.success = true;
            }
        }
    },
    computed:{
        moddedStockItems(){
            if(this.stock_items && this.stock_items.length){
                let modded = [...this.stock_items]
                modded.forEach(item=>{
                    item.name = item.alias.name
                })
                return modded
            } else{
                return null
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.pi-check, .pi-times, .pi-sign-out, .pi-lock-open, .pi-eject{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    color: grey;
    cursor: pointer;
}
</style>