<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 99%; max-height: 95vh">
                <div class="modal-header">
                    <h3 class="form-header">{{ phoneOrder ? 'Phone Order' : 'Prescription Renewal' }}<span style="color: red">{{ prescription?.authority_prescription_number? '(Authority Prescription Number Required)' :'' }}</span></h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div style="display: flex; gap: 20px;">
                                <div class="general-patient" v-if="frequencyOptions.length"
                            style="grid-area: initial; padding: 0; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: flex;">
                            <div style="width: 150px;">
                                <h2 style="padding-top: 25px; padding-bottom: 12px; margin: 0; color: #0870DC; font-size: 1.2em; text-align: left">
                                    {{ prescription.patient.personal_information.first_name }} {{ prescription.patient.personal_information.last_name }}</h2>
                                <div style="display: grid;">
                                    <img class="img-square" style="height:140px; width: 140px;" :src="patient.image_information.url" alt="Resident Image" v-if="patient.image_information && patient.image_information.created_at" onerror="this.onerror=null; this.className='img-square resident-image-default'"/>
                                    <img v-else style="height:140px"  class="img-square resident-image-default" id="residentImage" alt="Resident Image">
                                    <div v-if="patient.image_information && patient.image_information.created_at" style="margin: 5px; font-size: 0.8em">
                                        Updated:{{ patient.image_information.created_at }}
                                    </div>
                                    <p style="margin: 0 auto; width: 150px; padding: 4.7px 0; margin-bottom: 12px; border-radius: 4px; font-weight: bold; color: white" v-if="patient.status" :style="patient.status == 'Inactive' ? {backgroundColor: '#E04F39', border: '1px solid #E04F39'}: patient.status == 'Onboarding' ? {backgroundColor: '#EF9600', border: '1px solid #EF9600'} : {backgroundColor: '#00AD50', border: '1px solid #00AD50'}">{{ patient.status[0].toUpperCase() + patient.status.slice(1).toLowerCase() }}</p>
                                </div>
                                <div style="width: 120px; margin-right: 10px;">
                                
                                                                
                                    <div>
                                        <div class="general-patient-info" style="background-color: transparent !important">
                                            <p class="general-patient-info-key" style="text-align: left;">Sex:</p>
                                            <p class="general-patient-info-value"  style="text-align: left;">{{ prescription.patient.personal_information.sex }}</p>
                                        </div>
                                        <div class="general-patient-info" style="background-color: transparent !important">
                                            <p class="general-patient-info-key" style="text-align: left;">Preferred Name:</p>
                                            <p class="general-patient-info-value"  style="text-align: left;">
                                                {{ prescription.patient.personal_information.preferred_name }}</p>
                                        </div>
                                        <div class="general-patient-info" style="background-color: transparent !important">
                                            <p class="general-patient-info-key" style="text-align: left;">DOB:</p>
                                            <p class="general-patient-info-value"  style="text-align: left;">{{ prescription.patient.personal_information.dob }}</p>
                                        </div>
                                        <div class="general-patient-info" style="background-color: transparent !important">
                                            <p class="general-patient-info-key" style="text-align: left;">Location:</p>
                                            <p class="general-patient-info-value"  style="text-align: left;">{{prescription.patient.physical_location.location.facility?.name ? prescription.patient.physical_location.location.facility?.name : ''}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style='background-color:white !important;'>
                                    <div :class="infoADRTab == 'INFO' ? 'info-tab-border': 'adr-tab-border'">
                                       
                                        <!-- PATIENT STICKERS -->
                                        <div style="height: 200px; overflow: scroll;" v-if="infoADRTab == 'ADR'">
                                            <table style="padding: 0 10px; border-collapse: collapse;" v-if="patient.adr && patient.adr.length">
                                                <tr>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">ADR</th>
                                                    <!-- <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">Year</th> -->
                                                </tr>
                                                <tr v-for="(item, index) in patient.adr" :key="index">
                                                    <td style="padding: 0 10px;">{{ item.drug ? item.drug.toUpperCase() : item.custom ? item.custom.toUpperCase() : 'Non Spec.'}}</td>
                                                    <!-- <td style="padding: 0 10px;">{{ item.date ? item.date.split(',')[1] : '----'}}</td> -->
                                                </tr>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <div style="margin-bottom: 12px;" v-if="spec_cons && $store.state.user.patient_admin_active">
                                    <div :class="infoADRTab == 'INFO' ? 'info-tab-border': 'adr-tab-border'" v-if="spec_cons.special_considerations && spec_cons.special_considerations.length">
                                       
                                       <!-- PATIENT STICKERS -->
                                       <div style="height: 200px; overflow: scroll;" v-if="infoADRTab == 'ADR'">
                                           <table style="padding: 0 10px; border-collapse: collapse;">
                                               <tr>
                                                   <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">SPEC CONS.</th>
                                                   <!-- <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">Year</th> -->
                                               </tr>
                                               <tr v-for="(item, index) in spec_cons.special_considerations" :key="index" class="field-value" 
                                               style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 0px; margin-right: 5px;">
                                                   <td style="padding: 0px">{{ item.name ? item.name : 'Non Spec.'}}</td>
                                                   <!-- <td style="padding: 0 10px;">{{ item.date ? item.date.split(',')[1] : '----'}}</td> -->
                                               </tr>
                                               <tr style="text-align: left;">Notes: {{ spec_cons && spec_cons.comments ? spec_cons.comments : '' }}</tr>
                                           </table>
                                       </div>

                                   </div>
                                    <!-- <div >
                                        <div
                                            
                                            style="display: flex; width: 170px; gap:3px;  flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 200px;"
                                        >
                                            <p
                                                class="field-value"
                                                v-for="(item, index) in uniqueSpecCons" :key="index"
                                                style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 20px; margin-top: 0px; margin-right: 5px;"
                                                :v-tooltip="' '+item.name+' '"
                                            >
                                                {{item.name.length > 15 ? item.name.slice(0,15).toUpperCase()+'...' : item.name.toUpperCase() }}
                                            </p>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            
                            
                        </div>
                            <PrescriptionChart
                                    v-if="frequencyOptions.length && prescription.activePresChart && prescription.activePresChart.prescription_payload"
                                    :dashboardRenew="renewScript"
                                    :activePresChart="prescription.activePresChart"
                                    :patientId="prescription.patient.uuid"
                                    :displayDropDown="false"
                                    :disableAll="(phoneOrder === false) || (phoneOrder === null)"
                                    :phoneOrder="phoneOrder ? true : false"
                                    :signNow="true"
                                    @isSuccessful="isSuccessful"
                                    :prescriptionPayload="prescriptionPayload"
                                    :draftDrug="prescription.alias ? prescription.alias : ''" 
                                    :activeSelectedChart="prescription.selectedChart"
                                    :isDraft="false"
                                    :frequencyOptions="frequencyOptions"
                                    :renewingCancelledScript="renewingCancelledScript"
                            />

                            </div>
                            
                            <!-- :administration_history="[]" -->
                        </form>
                    </slot>
                </div>

                <!-- <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;"> -->
                        
                        <!-- <button class="green-button"  @click="signAction()">
                           Save Rx
                        </button> -->
                        <!-- <div v-else>
                            <button v-if="(first_spec_line == 'Successfully submitted report.' && second_spec_line != 'Click the Complete button to sign off on prescription.') " @click="closeModal()">Close</button>
                            <button v-if="second_spec_line == 'Click the Complete button to sign off on prescription.'" class="blue-button" @click="completeAction()">
                                Complete
                            </button>
                            <button v-if="syncChanges" class="green-button" style="margin-right: 10px;" @click="displayResidentSyncModal = true">
                                Proceed to Sync
                            </button>
                        </div>
                        
                        <button class="red-button" @click="closeModal()" v-if="(numActions || !action) || (first_spec_line == 'Successfully submitted report.' && second_spec_line != 'Click the Complete button to sign off on prescription.') ">{{ syncChanges ? 'Cancel' : 'Close' }}</button> -->
                    <!-- </slot> -->
                <!-- </div> -->

            </div>

        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './SignatureModal.vue';
import PrescriptionChart from '../PrescriptionChart.vue';

export default {
    props: ['prescription', 'renewingCancelledScript', 'hideClientPanel', 'renewScript', 'phoneOrder'],
    components: {
        SignatureModal,
        PrescriptionChart
        
    },
    data() {
        return {
            infoADRTab: 'ADR',
            noAuthRequired: false,
            signature_endpoint: '/prescription/sign',
            displaySignatureModal: false,
            displayResidentSyncModal: false,
            submitting: false,
            errorMessage: null,
            frequencyOptions: [],
            patient: '',
            spec_cons: null
        }
    },
    methods: {
        prescriptionPayload(value){
            console.log('this is the prescription payload prescriptionrenewal', value);
        },
        cancelAction() {
            console.log('hitting cancelAction prenewal')
            this.$emit('stopAction', true);
            // this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displayResidentSyncModal = false;
            }
        },
        completeAction(){
            this.submitting = true;
            this.errorMessage = null;
            axios.post(this.signature_endpoint, this.payload, this.$store.state.header).then(res=>{
                console.log('successfully submitted', res.data);
                this.$emit('successfullySubmitted', true);
                this.submitting = false;
                if(this.signature_endpoint == '/sync-integration' && res.data.length){
                    this.displayResidentSyncModal = true;
                }
            }).catch(err=>{
                console.log(err?.response?.data?.message);
                this.submitting = false;
                this.errorMessage = err;
            })
        },
        closeSync(value){
            window.alert('hitting close sync');
            if(value == 'reload'){
                this.displayResidentSyncModal = false;
                window.location.reload();
            }
        },
        getPatient(){
            axios.post('/retrieve-patient', {patient_id: this.prescription?.patient?.uuid}, this.$store.state.header).then(res=>{
                this.patient = res.data;
                if(this.patient.considerations && this.patient.considerations.length){
                    this.spec_cons = this.patient.considerations[this.patient.considerations.length -1]
                }
            })
        },
        closeModal() {
            console.log('hitting this closemodal function prenewal');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('successfullySubmitted', true);
                this.$emit('close', true);
            }
        },
        getFacilities(){
            console.log('getting facilities now');
            axios.post(`/prescription/frequencies`, {facility_id: this.prescription?.patient?.physical_location?.location?.facility?.uuid}, this.$store.state.header).then(res=>{
                this.frequencyOptions = res.data;
            })
        }
    },
    mounted() {
        if(this.prescription?.patient?.physical_location?.location?.facility?.uuid){
            this.getFacilities();
            this.getPatient()
        }
    },
    computed:{
        uniqueSpecCons(){
            let b = this.spec_cons &&  this.spec_cons.special_considerations ?  this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
