

<template>
    <div>
        <div>
            <h3 style="text-align: left;margin: 0; margin-top: 2em;">Enter Facilities Responsible Person</h3>
            <p style="text-align: left; font-size: 1em; ">Any user can be assigned as the responsible person to a facility
            </p>
            <div style="display: flex; flex-direction: column; min-width: 50em; width: 53vh;">
                <p
                    style="font-size: 1em; text-align: left; margin: 0; margin-top: 1em; margin-bottom: 10px; font-weight: 500">
                    Select Facility:</p>
                <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                    @change="retrieveCurrentResponsiblePerson()"
                    :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'"
                    style="width: 20em; text-align: left;" />
            </div>
        </div>
        <div style="margin-top: 2em;" v-if="selectedFacility">
            <div v-if="loadingResponsiblePersonData">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <div v-if="!loadingResponsiblePersonData">
                <h3 style="text-align: left">Link A Responsible Person</h3>
                <p style="text-align: left; font-size: 1em; ">{{ searchResponsible ? 'No Linked Person, Please Select Below' :
                    'Current Linked Person' }}</p>

                <div will-change v-if="!searchResponsible" style="display: flex; width: fit-content; align-items: center">
                    <div class="form-field wide-text-field" style="width: 80%;">
                        <label for="first_name">First Name:
                        </label>
                        <input type="text" disabled v-model="getFirstName" placeholder="">
                    </div>
                    <div class="form-field wide-text-field" style="width: 80%;">
                        <label for="first_name">Last Name:
                        </label>
                        <input type="text" disabled v-model="getLastName" placeholder="">
                    </div>
                    <div class="form-field wide-text-field" style="width: 80%;">
                        <label for="first_name">Username:
                        </label>
                        <input type="text" disabled v-model="getUserName" placeholder="">
                    </div>


                    <div style="position: relative; margin-top:5px">
                        <p class="red-button" style="position: absolute; margin-top:auto; font-size: 1em;"
                            @click="searchResponsible = true, unsetResponsiblePerson()"
                            v-if="$store.state.user.role.permissions.includes('can_do_admin_company') && selectedFacility">
                            Change
                        </p>

                    </div>
                </div>


                <div will-change v-else class="text-search-container search-container"
                    style="display: flex; align-items: center; max-width: 25em">
                    <!-- <input v-model="searchEntry" v-on:keyup.enter="searchUser(searchEntry)"
                        placeholder="Search by username." style="position: relative; width: 100%;" /> -->
                    <Dropdown v-model="searchResponsibleUsername" ref="resp"
                        @change="responsibleSelect(searchResponsibleUsername)"
                        @keyup="debounceSearchResponsible(searchResponsibleUsername), show()" :loading="loadingUsername"
                        :options="userList" optionLabel="user" :editable="true" style="width: 25em; display: flex"
                        placeholder="Search Username" />

                    <div v-if="searchResponsibleUsername" style="cursor: pointer; margin-left: 10px;padding: 7px;"
                        @click="setResponsiblePerson()">
                        <p class="blue-button">Link</p>
                    </div>
                    <a style="cursor: pointer;">
                        <i @click="resetResponsibleFlow()" class="pi pi-times-circle"
                            style="font-size: 1.2rem !important"></i>
                    </a>
                </div>
            </div>

        </div>
        <p v-if="errorMessage" style="font-size: 1em; font-weight: bold; margin-top: 3em; color: red">
            {{ errorMessage }}
        </p>

        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props: ['facilities'],
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage: '',
            errorMessage: '',
            userList: [],
            selectedUser: {},
            // search is paginated, what if there are > 15 john's?
            searchPage: 1,
            pageCount: 1,
            loadingUsername: false,
            loadingResponsiblePersonData: false,
            searchResponsibleUsername: '',
            searchResponsible: false,
            responsiblePerson: '',
            selectedFacility: '',
            timeout: 0
        }

    },
    methods: {
        close(value) {
            if (value) {
                this.resetResponsibleFlow();
            }
        },
        resetResponsibleFlow() {
            this.displaySuccessModal = false;
            this.searchResponsible = false;
            this.searchResponsibleUsername = '';
            this.retrieveCurrentResponsiblePerson();
        },
        unsetResponsiblePerson() {
            axios.post(`/facility/${this.selectedFacility.value}/remove-responsible`, this.selectedUser, this.$store.state.header).then(res => {
                console.log(res.data);
                // this.loadingResponsiblePersonData = false;
                // this.responsiblePerson = res.data;
                // this.successMessage = 'Success! Unset person ' + this.selectedUser.first_name + ' ' + this.selectedUser.last_name + ' (' + this.selectedUser.name + ') ' + ' from the facility.'
                // this.displaySuccessModal = true;
            }).catch(err => {
                this.loadingResponsiblePersonData = false;
                console.log('get-round-times error', err.response.data);
            })
        },
        setResponsiblePerson() {
            axios.post(`/facility/${this.selectedFacility.value}/add-responsible`, this.selectedUser, this.$store.state.header).then(res => {
                console.log(res.data);
                this.loadingResponsiblePersonData = false;
                // this.responsiblePerson = res.data;
                this.successMessage = 'Success! Responsible person ' + this.selectedUser.first_name + ' ' + this.selectedUser.last_name + ' (' + this.selectedUser.name + ') ' + ' is linked to the facility.'
                this.displaySuccessModal = true;
            }).catch(err => {
                this.loadingResponsiblePersonData = false;
                console.log('get-round-times error', err.response.data);
            })
        },
        searchUser() {
            this.searchUserResults = '';
            this.loadingUsername = true;
            axios.post('/user/search', { name: this.searchResponsibleUsername, page: this.searchPage }, this.$store.state.header).then(res => {
                this.searchUserResults = res.data.results;
                this.searchPage = res.data.current_page;
                this.pageCount = res.data.page_count;
                this.searchUserResults.forEach(item => {
                    item.user = `(${item.name}) ${item.person?.personal_information?.first_name || ''} ${item.person?.personal_information?.last_name || ''}`
                })
                this.userList = this.searchUserResults;
                this.loadingUsername = false;
            }).catch(err => {
                this.loadingUsername = false;
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        responsibleSelect(user) {
            this.selectedUser.login_id = user.uuid;
            this.selectedUser.name = user.name;
            this.selectedUser.first_name = user.person?.personal_information?.first_name;
            this.selectedUser.last_name = user.person?.personal_information?.last_name;
            return true;
        },
        show() {
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingUsername && this.userList) {
                    clearInterval(interval);
                    this.$refs.resp.show();
                }
            }, 100)
        },
        debounceSearchResponsible() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchUser()
            }, 400); // delay
        },
        retrieveCurrentResponsiblePerson() {
            this.$store.dispatch('getRefreshToken');
            this.loadingResponsiblePersonData = true;
            axios.post(`/facility/${this.selectedFacility.value}/get-responsible`, {}, this.$store.state.header).then(res => {
                console.log(res.data);
                if (!res.data) {
                    console.log("empty resp person");
                    this.loadingResponsiblePersonData = false;
                    this.searchResponsible = true;

                } else {
                    console.log("resp person present");
                    this.loadingResponsiblePersonData = false;
                    this.searchResponsible = false;
                    this.responsiblePerson = res.data;
                }
            }).catch(err => {
                this.loadingResponsiblePersonData = false;
                console.log('loadingResponsiblePersonData error', err.response.data);
            })
        }

    },
    mounted() {
    },
    computed: {
        getFirstName() {
            return this.responsiblePerson?.person?.personal_information?.first_name || '';
        },
        getLastName() {
            return this.responsiblePerson?.person?.personal_information?.last_name || '';
        },
        getUserName() {
            return this.responsiblePerson?.name || '';
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
