
export const sortMedicationList = (medList) => {
    // sort the response hits
    return medList.sort((hitA, hitB) => {
      const hitNameA = hitA.name.toLowerCase();
      const hitNameB = hitB.name.toLowerCase();

      // check for special characters, 
      // alphabets will appear first before special characters
      const hitSpecialCharA = /[+]/.test(hitNameA);
      const hitSpecialCharB = /[+]/.test(hitNameB);

      // Compare based on presence of + sign
      if (hitSpecialCharA && !hitSpecialCharB) return 1;
      if (!hitSpecialCharA && hitSpecialCharB) return -1;

      // extract alpha-numeric parts
      const { alphaPart: alphaA, numPart: numA, restPart: restA } = extractAlphanumericParts(hitNameA);
      const { alphaPart: alphaB, numPart: numB, restPart: restB } = extractAlphanumericParts(hitNameB);

      // Compare alphabetical parts first
      if (alphaA.toLowerCase() < alphaB.toLowerCase()) return -1;
      if (alphaA.toLowerCase() > alphaB.toLowerCase()) return 1;
      
      // If alphabetical parts are the same, compare numeric parts
      if (numA < numB) return -1;
      if (numA > numB) return 1;

      // If both alphabetical and numeric parts are the same, compare remaining text
      if (restA < restB) return -1;
      if (restA > restB) return 1;

      // If both alphabetical and numeric parts are the same, consider them equal
      return 0;
  })
}

const extractAlphanumericParts = (str) => {
  // find initial part which is non digital characters
  const alphaMatch = str.match(/^[^\d]+/);
  // find sequence of digital characters
  const numMatch = str.match(/\d+/);
  // Extract remaining text after numeric characters
  const restMatch = str.match(/\d+\s*(.*)$/);
  
  // Extract the alphabetical part
  const alphaPart = alphaMatch ? alphaMatch[0].trim() : '';
  // Extract the numeric part
  const numPart = numMatch ? parseInt(numMatch[0], 10) : null;
  // Extract the remaining text
  const restPart = restMatch ? restMatch[1].trim() : '';

  return { alphaPart, numPart, restPart };
}