import Gleap from 'gleap';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import PrimeVue from 'primevue/config';
import VirtualScroller from 'primevue/virtualscroller';
import Calendar from 'primevue/calendar';
import VCalendar from 'v-calendar';
import Chip from 'primevue/chip';
import MultiSelect from 'primevue/multiselect';
import Tooltip from 'primevue/tooltip';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import MegaMenu from 'primevue/megamenu';
import PanelMenu from 'primevue/panelmenu';
import ContextMenu from 'primevue/contextmenu';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import ProgressBar from 'primevue/progressbar';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueApexCharts from "vue3-apexcharts";
import Sidebar from 'primevue/sidebar';
import './assets/css/main.scss'
// import VueCal from 'vue-cal'
// import VueCookies from 'vue-cookies'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"                           //icons
// import 'vue-cal/dist/vuecal.css'
import tooltip from "./directives/tooltip.js";
import clickoutside from "./directives/clicktodismiss.js"


// SENTRY
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// .use(VueCal)

createApp(App).use(router).directive("tooltip", tooltip).use(clickoutside).use(VueAxios, axios).use(VueApexCharts).use(PrimeVue).use(store).use(VCalendar, {}).component('Calendar', Calendar)
  .component('RadioButton', RadioButton).component('Button', Button).component('Menu', Menu).component('TieredMenu', TieredMenu).component('MegaMenu', MegaMenu).component('ContextMenu', ContextMenu).component('PanelMenu', PanelMenu)
  .component('Password', Password).component('ToggleButton', ToggleButton).component('SelectButton', SelectButton).component('VirtualScroller', VirtualScroller).component('Checkbox', Checkbox).component('Dropdown', Dropdown).component('InputSwitch', InputSwitch)
  .component('MultiSelect', MultiSelect).component('InputText', InputText).component('InputNumber', InputNumber).component('InputMask', InputMask).component('TreeSelect', TreeSelect).component('TreeTable', TreeTable)
  .component('DataTable', DataTable).component('Column', Column).component('TabView', TabView).component('TabPanel', TabPanel)
  .component('Textarea', Textarea).component('VueGoogleAutocomplete', VueGoogleAutocomplete).component('Sidebar', Sidebar).component('Chip', Chip).component('ProgressBar', ProgressBar).mount('#app')

Gleap.initialize('1lcvpeNsGWuVRJFuNkKkjYQulpYEqi9b');

  
Sentry.init({
  App,
  dsn: "https://bb0d75e1063e4f00bac76315325b83ba@o981937.ingest.sentry.io/5967154",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "http://frontend-load-balancer-933704377.ap-southeast-2.elb.amazonaws.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});