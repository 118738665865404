<template>
  <div>
    <div class="half-background"  v-if="$route.path != '/login' && !$route.path.includes('/msauth') && !$route.path.includes('/onboarding/') && !$route.path.includes('reset-password') && !$route.path.includes('/feature-flags')"></div>
    <Navbar style='z-index: 1' v-if="$route.path != '/login' && !$route.path.includes('/msauth') && !$route.path.includes('/onboarding/') && !$route.path.includes('reset-password') && !$route.path.includes('/feature-flags')" />
    <SaveCacheModal v-if="$route.path != '/login' && !$route.path.includes('/msauth') && $route.path != '/onboarding'  && !$route.path.includes('reset-password') && !$route.path.includes('/feature-flags') &&  $store.state.checkedFirebase" @close="close" />
    <TimeRunnerModal v-if="$store.state.displayTimeRunnerModal" />
    
    <router-view></router-view>
    <AlertsPanel v-click-outside="() => hideAlerts()" v-if="$route.path != '/login' && !$route.path.includes('/msauth') && !$route.path.includes('/feature-flags') && $route.name != 'Prescriber Onboarding' && $store.state.displayAlerts" />
  </div>
</template>

<script>
import Navbar from './components/navigation/Navbar.vue';
import SaveCacheModal from '@/components/modals/SaveCacheModal.vue';
import TimeRunnerModal from '@/components/modals/TimeRunnerModal.vue';
import AlertsPanel from './components/notifications/AlertsPanel.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    SaveCacheModal,
    TimeRunnerModal,
    AlertsPanel
  },
  data(){
    return{
      displaySaveCacheModal: false,
      checkedCache: false,
      loggedIn: false,
    }
  },
  created(){
    },
  methods:{
    hideAlerts() {
      this.$store.commit('setDisplayAlerts', false);
    },
    close(value){
      if(value){
        console.log('app vue trying to close', this.displaySaveCacheModal);
        this.displaySaveCacheModal = false;
        this.$store.commit('setDisplayTimeRunnerModal', false);
        localStorage.removeItem('savedform');
        this.$store.commit('setCheckedFirebase', false);
      }
    }
  },
  mounted(){
    localStorage.removeItem('reg');
    this.$store.dispatch('timeRunner');
    console.log('mounting the app');
    this.$store.commit('setAlerts', null);
    this.$store.dispatch('getAlerts');
    //  Auto Sync of Invoice, Orders, Clients, Suppliers, StockTake 
    // this.$store.dispatch('syncHourly');
  },
  computed:{
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.half-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: -1;
    background-image: linear-gradient(to right, #0ddcf3 , #0973dd);
}
</style>
