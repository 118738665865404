const config = [{
  name: "carton-cloud",
  orders: "Sales Orders",
  warehouse_invoices: "Purchase Orders",
}, 
{
  featureFlags: {
    cc_poll_feature: false
  }
}];
export default config