<template>
    <div class="view dashboard-view warehouse-dashboard">
        <!--  Pending Purchase Orders -->
        <div class="panel purchase-panel">
            <!-- Show a plain panel header if feature flag is set to false -->
            <h3 v-if="$store.getters.getInvoiceCCFlag === false" class="panel-header">
                {{ getDynamicName('invoices') }}
            </h3>
            <h3 v-if="$store.getters.getInvoiceCCFlag" class="panel-header">
                {{ getDynamicName('invoices') }}
                <i v-if="$store.state.syncStatus?.invoices?.syncLoader && !$store.state.syncStatus?.invoices?.syncError"
                    class="pi pi-spin pi-spinner"></i>
                <button
                    v-if="!$store.state.syncStatus?.invoices?.syncUUIDSucces && ($store.state.LastSync?.invoicesLastSynced?.count >= 12 || $store.state.syncStatus?.invoices?.syncError) && !$store.state.syncStatus?.invoices?.syncLoader"
                    class="button submit-button submitpurchase"
                    style="background-color: #b5a248 ;margin-left: 10px; min-width: 56px"
                    @click="$store.dispatch('syncEntries', {endpoint: '/integration/sync-invoices', type: 'import_invoices',value: true, sync: 'invoices', syncFrom})"
                    :disabled="$store.state.syncStatus?.invoices?.syncLoader || syncButtonDisable">
                    <span>Check Again</span>
                </button>

                <p class="lastsyncedtime">Last synced: {{ $store.state.LastSync?.invoicesLastSynced?.count >= 12 ?
                    $store.state.syncStatus?.invoices?.syncLoader ? '--' : "The request is still in progress." :
                    $store.getters.getInvoicesLastSynced }}</p>
            </h3>
            <div class="dashboard-container">
                <div style="display: flex; align-items: center; padding: 20px; padding-bottom:0px">
                    <div class="select-filters-container">
                        <p @click="handleTabsClick('invoice', ['pending'])"
                            v-bind:class="[purchaseform.filters.status.includes('pending') ? 'active-select-filter' : '']"
                            class="select-filter-item">Pending Invoices</p>
                        <p @click="handleTabsClick('invoice', ['partial'])"
                            v-bind:class="[purchaseform.filters.status.includes('partial') ? 'active-select-filter' : '']"
                            class="select-filter-item">Countersign Invoices</p>
                    </div>
                </div>
                <div class="dashboard-handle" v-if="!invoicesLoading">
                    <div class="drug-register-table" style="padding-top: 10em;height: 100%"
                        v-if='!$store.state.user.role.permissions.includes("can_view_destruction")'>
                        <h3
                            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                            You do not have permission to access this feature. Please talk to your admin if you require
                            access.</h3>
                    </div>
                    <table v-else class="data-table" cellspacing="0" style="overflow: hidden;padding: 0px;">
                        <tr class="table-headers">
                            <th style="padding-left: 20px;">Suppliers</th>
                            <th style="width: 150px;">Date Created</th>
                            <th style="width: 125px;padding-right: 20px;">Action</th>
                        </tr>
                        <tbody class="table-data tableData" v-for="(item, index) in invoicesEntries?.data" :key="index">
                            <tr @click="showPurchaseContent(item, index)"
                                :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']">
                                <td style="padding-left: 20px;">{{ item.supplier_name || item.ship_to_name || '--' }}</td>
                                <td class="wrapping-content">
                                    {{ item.delivery_date || '--' }}
                                    <span v-if="item?.deprecated" style="margin-right: 5px;" class="s8-tag">Deprecated
                                        items</span>
                                </td>
                                <td style="padding-right: 20px;">
                                    <button class="button safe-button">Review</button>
                                </td>
                            </tr>
                            <tr :ref="'expand-purchase-' + index" :id="'expand-purchase-' + index"
                                class="expanded-content">
                                <td colspan="3">
                                    <div class="expanded-transfer-container">
                                        <div v-if="!item.note && !item.medications" style="margin: 0 auto">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                                        </div>
                                        <div v-else class="main-content">
                                            <div class="transfer-notes-container">
                                                <div style="padding-bottom: 1em;">
                                                    <p class="field-title">Reference Number</p>
                                                    <p class="field-value">{{ item.reference_number || 'N/A' }}</p>
                                                </div>
                                                <div class="transfer-notes-container">
                                                    <p class="field-title">Notes</p>
                                                    <p class="field-value">{{ item.note || 'N/A' }}</p>
                                                </div>

                                            </div>
                                            <div class="transfer-drugs-container">

                                                <p class="field-title">Drugs</p>
                                                <div class="drug-field-tables drugfieldtables">
                                                    <table>
                                                        <tr class="drugstablehead">
                                                            <th>Name</th>
                                                            <th style="width: 100px">Count</th>
                                                        </tr>
                                                    </table>
                                                    <div class="drugtablediv">
                                                        <table style="padding: 0;">
                                                            <tr class="drugtablerow" style="height: 25px"
                                                                v-for="(value, key) in item.medications" :key="key">
                                                                <td
                                                                    style="padding: 0px 20px;display: flex;align-items: center">
                                                                    {{ value.medication }}
                                                                    <span v-if="value?.deprecated"
                                                                        class="s8-tag">Deprecated</span>
                                                                </td>
                                                                <td style="width: 115px">{{ value.count }}</td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ACTIONS -->
                                    <div class="transfer-actions-container action-footer">
                                        <div class="div-action-footer">
                                            <button :disabled="isDeprecatedDrug(item) || item?.deprecated || signRestrict(item).status"
                                                :class="isDeprecatedDrug(item) || item?.deprecated || signRestrict(item).status && 'disabledButton'"
                                                v-if="item.status !== 'completed' && item.status !== 'reversed' && purchaseform.filters.show_active" 
                                                class="transfer-action-button completed-button"
                                                @click="completedDeliveryDilog(item)"
                                                :style="{ minWidth: 'max-content', backgroundColor: item.medications?.length < 0 ? '#b8b8b8' : '#00AD50', cursor: item.medications?.length < 0 ? 'auto' : 'pointer' }"
                                                v-tooltip.left="tooltipMessage(item, 'invoice')">Sign
                                                Off</button>
                                            <button @click="showPurchaseContent(item, index)" class="crossbutton"><i
                                                    class="pi pi-times"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="loader-align-center">
                    <h3 v-if="invoicesError">{{ invoicesError }}</h3>
                    <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important">
                    </i>
                </div>
                <div class="pagination-links" v-if="invoicesEntries && invoicesEntries?.meta?.current_page">
                    <p class="pagination-arrow" v-show="invoicesEntries?.meta?.current_page > 1"
                        @click="displayPage(purchaseform.page - 1, 'purchase')"> &larr; </p>
                    <p>Page {{ invoicesEntries?.meta?.current_page }} of
                        {{ invoicesEntries?.meta?.last_page }}</p>
                    <p class="pagination-arrow"
                        v-show="invoicesEntries?.meta?.current_page < invoicesEntries?.meta?.last_page"
                        @click="displayPage(invoicesEntries?.meta?.current_page + 1, 'purchase')"> &rarr; </p>
                </div>
            </div>
        </div>

        <!-- Pending Sales Order -->
        <div class="panel order-panel">
            <!-- Show a plain panel header if feature flag is set to false -->
            <h3 v-if="$store.getters.getOrderCCFlag === false" class="panel-header">
                {{ getDynamicName('orders') }}
            </h3>
            <h3 v-if="$store.getters.getOrderCCFlag" class="panel-header">
                {{ getDynamicName('orders') }}
                <i v-if="$store.state.syncStatus?.orders?.syncLoader && !$store.state.syncStatus?.orders?.syncError"
                    class="pi pi-spin pi-spinner"></i>
                <button
                    v-if="!$store.state.syncStatus?.orders?.syncUUIDSucces && ($store.state.LastSync?.ordersLastSynced?.count >= 12 || $store.state.syncStatus?.orders?.syncError) && !$store.state.syncStatus?.orders?.syncLoader"
                    class="button submit-button submitorder"
                    style="background-color: #b5a248;margin-left: 10px; min-width: 56px"
                    @click="$store.dispatch('syncEntries', {endpoint: '/integration/sync-orders', type: 'import_orders',value: true, sync: 'orders', ordersFrom: this.ordersFrom, ordersTo: this.ordersTo})"
                    :disabled="$store.state.syncStatus?.orders?.syncLoader || syncButtonDisable">
                    <span>Check Again</span></button>

                <p class="lastsyncedtime">Last synced: {{ $store.state.LastSync?.ordersLastSynced?.count >= 12 ?
                    $store.state.syncStatus?.orders?.syncLoader ? '--' : "The request is still in progress." :
                    $store.getters.getOrdersLastSynced }}</p>
            </h3>
            <div class="dashboard-container">
                <div style="display: flex; align-items: center; padding: 20px; padding-bottom:0px">
                    <div class="select-filters-container">
                        <p @click="handleTabsClick('order', ['pending'])"
                            v-bind:class="[ordersform.filters.status.includes('pending') ? 'active-select-filter' : '']"
                            class="select-filter-item">Pending Orders</p>
                        <p @click="handleTabsClick('order', ['partial'])"
                            v-bind:class="[ordersform.filters.status.includes('partial') ? 'active-select-filter' : '']"
                            class="select-filter-item">Countersign Orders</p>
                    </div>
                </div>
                <div class="dashboard-handle" v-if="!ordersLoading">
                    <div class="drug-register-table" style="padding-top: 10em;height: 100%"
                        v-if='!$store.state.user.role.permissions.includes("can_view_destruction")'>
                        <h3
                            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                            You do not have permission to access this feature. Please talk to your admin if you require
                            access.</h3>
                    </div>
                    
                    <table v-else class="data-table" cellspacing="0" style="overflow: hidden;padding: 0px;">
                        <tr class="table-headers">
                            <th style="padding-left: 20px;">Client</th>
                            <th style="width: 150px;">Order Number</th>
                            <th style="width: 125px;padding-right: 20px;">Actions</th>
                        </tr>
                        <tbody class="table-data tableData" v-for="(item, index) in ordersEntries?.data" :key="index">
                            <tr @click="showOrderContent(item, index)"
                                :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']">
                                <td style="padding-left: 20px;">{{ item.ship_to_name || '--' }}</td>
                                <td class="wrapping-content">
                                    {{ item.invoice_number || '--' }}
                                    <span v-if="item?.deprecated" style="margin-right: 5px;" class="s8-tag">Deprecated
                                        items</span>
                                </td>
                                <td style="padding-right: 20px;">
                                    <button class="button safe-button">Review</button>
                                </td>
                            </tr>

                            <tr :ref="'expand-orders-' + index" :id="'expand-orders-' + index" class="expanded-content">
                                <td colspan="3">
                                    <div class="expanded-transfer-container">
                                        <div v-if="!item.note && !item.medications" style="margin: 0 auto">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                                        </div>
                                        <div v-else class="main-content">
                                            <div class="transfer-notes-container">
                                                <div style="padding-bottom: 1em;">
                                                    <p class="field-title">Reference Number</p>
                                                    <p class="field-value">{{ item.reference_number || 'N/A' }}</p>
                                                </div>
                                                <div class="transfer-notes-container">
                                                    <p class="field-title">Notes</p>
                                                    <p class="field-value">{{ item.note || 'N/A' }}</p>
                                                </div>

                                            </div>
                                            <div class="transfer-drugs-container">

                                                <p class="field-title">Drug</p>
                                                <div class="drug-field-tables drugfieldtables">
                                                    <table>
                                                        <tr class="drugstablehead">
                                                            <th>Name</th>
                                                            <th style="width: 100px">Count</th>
                                                        </tr>
                                                    </table>
                                                    <div class="drugtablediv">
                                                        <table style="padding: 0;">
                                                            <tr class="drugtablerow" style="height: 25px"
                                                                v-for="(value, key) in item.medications" :key="key">
                                                                <td
                                                                    style="padding: 0px 20px;display: flex;align-items: center">
                                                                    {{ value.medication }}
                                                                    <span v-if="value?.deprecated"
                                                                        class="s8-tag">Deprecated</span>
                                                                </td>
                                                                <td style="width: 115px">{{ value.count }}</td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ACTIONS -->
                                    <div class="transfer-actions-container action-footer">
                                        <div class="div-action-footer">
                                            <button :disabled="isDeprecatedDrug(item) || item?.deprecated || signRestrict(item).status"
                                                :class="isDeprecatedDrug(item) || item?.deprecated || signRestrict(item).status && 'disabledButton'"
                                                v-if="item.status !== 'completed' && item.status !== 'reversed' && ordersform.filters.show_active"
                                                class="transfer-action-button completed-button"
                                                @click="completedOrderDialog(item)"
                                                :style="{ minWidth: 'max-content', backgroundColor: item.medications?.length < 0 ? '#b8b8b8' : '#00AD50', cursor: item.medications?.length < 0 ? 'auto' : 'pointer' }"
                                                v-tooltip.left="tooltipMessage(item, 'order')">Sign
                                                Off</button>
                                            <button @click="showOrderContent(item, index)" class="crossbutton"><i
                                                    class="pi pi-times"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="loader-align-center">
                    <h3 v-if="ordersError">{{ ordersError }}</h3>
                    <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important">
                    </i>
                </div>
                <div class="pagination-links" v-if="ordersEntries && ordersEntries?.meta?.current_page">
                    <p class="pagination-arrow" v-show="ordersEntries?.meta?.current_page > 1"
                        @click="displayPage(ordersform.page - 1, 'orders')"> &larr; </p>
                    <p>Page {{ ordersEntries?.meta?.current_page }} of
                        {{ ordersEntries?.meta?.last_page }}</p>
                    <p class="pagination-arrow"
                        v-show="ordersEntries?.meta?.current_page < ordersEntries?.meta?.last_page"
                        @click="displayPage(ordersEntries?.meta?.current_page + 1, 'orders')"> &rarr; </p>
                </div>
            </div>
        </div>

        <!-- Batches -->
        <div class="panel batch-panel" style="height: 91%;">
            <h3 class="panel-header">Expiring Batches</h3>
            <div class="dashboard-container">
                <div class="gap-with-flex dashboard-handle">
                    <div class="text-search-container search-container calenderdatelabel">
                        <label for="medication">Batch Number</label>
                        <div class="calendar-input-container">
                            <i class="pi pi-search"></i>
                            <input id="medication" placeholder="Search Batch Number..."
                                v-model="batchform.filters.batch_name" style="position: relative"
                                @input="debouncedBatchData" />
                        </div>
                    </div>
                    <div class="calenderdatelabel">
                        <label for="multistatusselect">Stock Type</label>
                        <MultiSelect class="multiselectstatus" id="multistatusselect" v-model="selectedStatuses"
                            :options="statusSelect" @change="debouncedBatchData" optionLabel="status"
                            placeholder="Select Stock Type..." />
                    </div>
                    <div class="drug-register-table" style="padding-top: 10em;height: 100%"
                        v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")'>
                        <h3
                            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                            You do not have permission to access this feature. Please talk to your admin if you require
                            access.</h3>
                    </div>
                    <div v-else-if="!batchLoading" class="batchItems">
                        <div class="batchItem" v-for="(item, index) in batchEntries?.data" :key="index"
                            :class="[item.status == 'Good' ? 'completed-border' : (item.status == 'Expiring Soon' ? 'expiring-border' : 'expired-border')]"
                            @click="goToBatch(item, index)">
                            <p>{{ item.batch_name }}</p>
                            <div class="batchItemFooter">
                                <p>{{ item.batch_id }}</p>
                                <p>{{ item.expiry_date }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="loader-align-center">
                        <h3 v-if="batchError">{{ batchError }}</h3>
                        <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                    </div>
                </div>
                <div class="pagination-links" v-if="batchEntries && batchEntries.meta?.current_page">
                    <p class="pagination-arrow" v-show="batchEntries.meta?.current_page > 1"
                        @click="displayPage(batchform.page - 1, 'batches')"> &larr; </p>
                    <p>Page {{ batchEntries.meta?.current_page }} of
                        {{ batchEntries.meta?.last_page }}</p>
                    <p class="pagination-arrow" v-show="batchEntries.meta?.current_page < batchEntries.meta?.last_page"
                        @click="displayPage(batchEntries.meta?.current_page + 1, 'batches')"> &rarr; </p>
                </div>
            </div>
        </div>

        <!-- Pending Destruction Entries -->
        <div class="panel destruction-panel">
            <h3 class="panel-header">Pending Destructions</h3>
            <div class="dashboard-container">
                <div class="dashboard-handle" v-if="!destructionLoading">
                    <div class="drug-register-table" style="padding-top: 10em;height: 100%"
                        v-if='!$store.state.user.role.permissions.includes("can_view_destruction")'>
                        <h3
                            style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                            You do not have permission to access this feature. Please talk to your admin if you require
                            access.</h3>
                    </div>
                    <table v-else class="data-table" cellspacing="0" style="overflow: hidden;padding: 0px;">
                        <tr class="table-headers">
                            <th style="padding-left: 20px;">Location</th>
                            <th style="width: 150px;">Date Recieved</th>
                            <th style="width: 125px;padding-right: 20px;">Action</th>
                        </tr>
                        <tbody class="table-data tableData" v-for="(item, index) in destructionDrugEntries?.data"
                            :key="index">
                            <tr @click="showDestroyContent(item, index)"
                                :class="[item.expand && (item.expand == true) ? 'open-transfer-row' : '']">
                                <td style="padding-left: 20px;">{{ this.$store.state.currentLocation.name || '--' }}
                                </td>
                                <td>{{ item?.date || '--' }}</td>
                                <td style="padding-right: 20px;"><button @click.stop="showDestroyContent(item, index)"
                                        class="button safe-button">Review</button></td>
                            </tr>
                            <tr :ref="'expand-destroy-' + index" :id="'expand-destroy-' + index"
                                class="expanded-content">
                                <td colspan="3">
                                    <div class="expanded-transfer-container">
                                        <div v-if="!item.expandedInfo" style="margin: 0 auto">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                                        </div>
                                        <div v-else class="main-content">
                                            <div class="transfer-specs-container" v-if="item.expandedInfo">
                                                <div class="status-field">
                                                    <p class="field-title">Type</p>
                                                    <p v-if="item.expandedInfo && item.expandedInfo.status"
                                                        class="field-value" style='text-align: center'
                                                        :class="[item.expandedInfo.status == 'completed' ? 'correct-status-color statuswidth' : (item.expandedInfo.status == 'rejected' || item.expandedInfo.status == 'reversed' ? 'mismatch-status-color statuswidth' : 'mismatch-status-color statuswidth')]">
                                                        {{ $store.state.company.type == 'warehouse' ? 'Destruction' :
                                                        'Disposal' }}</p>
                                                </div>
                                                <!-- <div class="date-received-field">
                                                <p class="field-title">Method</p>
                                                <p class="field-value">{{ item.expandedInfo.method ?
                                                    item.expandedInfo.method : 'N/A' }}</p>
                                            </div> -->
                                                <div class="ordered-from-field" v-if="item.expandedInfo.location">
                                                    <p class="field-title">Location</p>
                                                    <p class="field-value">{{ item.expandedInfo.location.name }}</p>
                                                </div>
                                                <div class="ordered-by-field">
                                                    <p class="field-title">User</p>
                                                    <p class="field-value"
                                                        v-if="item.user && item.user.personal_information">
                                                        {{ item.user.personal_information.first_name }} {{
                                                        item.user.personal_information.last_name }}
                                                    </p>
                                                </div>
                                                <!-- <div class="fulfilled-by-field">
                                                <p class="field-title">Courier Notes</p>
                                                <p class="field-value">{{ item.expandedInfo.courier_notes ?
                                                    item.expandedInfo.courier_notes :
                                                    'N/A'
                                                    }}</p>
                                            </div> -->
                                                <!-- <div class="fulfilled-time-field">
                                                <p class="field-title">Courier Name</p>
                                                <p class="field-value">{{ item.expandedInfo.courier_name ?
                                                    item.expandedInfo.courier_name :
                                                    'N/A'
                                                    }}</p>
                                            </div> -->
                                            </div>
                                            <!-- <div class="transfer-notes-container" style="padding-bottom: 1em;">
                                            <p class="field-title">Notes</p>
                                            <div class="field-notes">
                                                <p>{{ item.notes || 'N/A' }}</p>
                                            </div>
                                        </div> -->
                                            <div class="transfer-drugs-container" v-if="item.expandedInfo">
                                                <p class="field-title">Drugs</p>
                                                <div class="drug-field-tables drugfieldtables">
                                                    <table>
                                                        <tr class="drugstablehead">
                                                            <th>Name</th>
                                                            <th style="width: 100px">Qty</th>
                                                            <th v-if="$store.state.company.type != 'warehouse'">Resident
                                                            </th>
                                                        </tr>
                                                    </table>
                                                    <div class="drugtablediv">
                                                        <table>
                                                            <tr class="drugtablerow"
                                                                v-for="(drug, key) in item.expandedInfo.drugs"
                                                                :key="key" style="height: 25px">
                                                                <td style="padding: 0px 20px;">{{ drug.alias.name }}
                                                                </td>
                                                                <td style="width: 115px">{{ drug.quantity + ' ' +
                                                                    drug.alias.form || '-' }}/s</td>
                                                                <td v-if="$store.state.company.type != 'warehouse'">{{
                                                                    item.expandedInfo.resident || '-' }}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ACTIONS -->
                                    <div class="transfer-actions-container action-footer">
                                        <div class="div-action-footer">
                                            <button :disabled="item.expandedInfo?.drugs?.length < 0"
                                                v-if="item.expandedInfo?.drugs?.length > 0"
                                                class="button reversed-button"
                                                :style="{ minWidth: 'max-content', backgroundColor: item.expandedInfo?.drugs?.length < 0 ? '#b8b8b8' : '#E04F39', cursor: item.expandedInfo?.drugs?.length < 0 ? 'auto' : 'pointer'}"
                                                @click="destroyItems(item.expandedInfo?.drugs)">Destroy</button>

                                            <button @click="showDestroyContent(item, index)" class="crossbutton"><i
                                                    class="pi pi-times"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="loader-align-center">
                    <h3 v-if="destructionError">{{ destructionError }}</h3>
                    <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                </div>
                <div class="pagination-links" v-if="destructionDrugEntries && destructionDrugEntries.current_page">
                    <p class="pagination-arrow" v-show="destructionDrugEntries.current_page > 1"
                        @click="displayPage(destructionForm.page - 1, 'destruction')"> &larr; </p>
                    <p>Page {{ destructionDrugEntries.current_page }} of {{ destructionDrugEntries.page_count }}</p>
                    <p class="pagination-arrow"
                        v-show="destructionDrugEntries.current_page < destructionDrugEntries.page_count"
                        @click="displayPage(destructionDrugEntries.current_page + 1, 'destruction')"> &rarr; </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Pending Destruction Move to Destruct -->
    <PendingDestructionModal v-if="displayPendingDestructionModal" @success="isSuccessful" :payload="destructionpayload"
        @close="close" :filterStatus="destructionForm.filters.status" />

    <!-- Sign Off Process for Invoice / Orders -->
    <compDeliveryModal v-if="completedDelivery" :form="noteName == 'Send Order' ? ordersform : '' "
        :endpoint="getEndpoint()" :openInvoice="openInvoice" :isStatus="isStatus" @close="close" :noteName="noteName"
        notePlaceholder="Enter notes here..."
        :successName="noteName == 'Send Order' ? 'Order Sent!' : 'Delivery Received!'" :payload2="payload2"
        :isEdit="false" @success="success" :istrigger="false" />
</template>
<script>
import axios from 'axios';
import router from '@/router';
import PendingDestructionModal from '@/components/modals/PendingDestructionModal.vue';
import compDeliveryModal from '@/components/modals/compDeliveryModal.vue';
import debounce from "debounce"
import config  from "../../config.js"
import { signRestrictionStatus, tooltipMessagesDisplay, checkPermissionAndPassAsProps } from "../../views/warehouse/utils/verifyCountersignPermissions.js"

export default {
    components:{
        PendingDestructionModal,
        compDeliveryModal
    },
    data() {
        return {
            destructionError: '',
            destructionLoading: false,
            destructionForm: {
                sort_direction: '',
                col: '',
                page: 1,
                location: this.$store.state.uuid,
                filters: {
                    status: 'pending',
                    drug: '',
                    patient: '',
                    date: {
                        start: '',
                        end: ''
                    },
                }
            },
            destructionDrugEntries: [],
            displayPendingDestructionModal: false,
            destructionpayload: {
                items: []
            },

            batchform: {
                sort_direction: 'desc',
                col: 'created_at',
                page: 1,
                filters: {
                    date: {
                        start: '',
                        end: ''
                    },
                    batch_name: "",
                    drug: "",
                    status: [],
                    show_active: true
                },
            },
            batchError: '',
            batchLoading: false,
            batchEntries: [],
            searchbatchNumber: "",
            selectedStatuses: [
                { status: 'Expired' },
                { status: 'Expiring Soon' }],
            statusSelect: [
                { status: 'Expired' },
                { status: 'Expiring Soon' },
                { status: 'Good' },
            ],

            dynamicName: config,
            completedDelivery:false,
            openInvoice :null,
            isStatus: "",
            noteName: '',
            payload2:{},

            ordersform: {
                sort_direction: 'desc',
                col: 'created_at',
                facility_id: this.$store.state.currentLocation.uuid,
                page: 1,
                filters: {
                    tags: [],
                    status: ["pending"],
                    party: '',
                    drug: '',
                    date: {
                        start: '',
                        end: ''
                    },
                    show_active: true,
                },
            },
            ordersEntries: [],
            ordersLoading: false,
            ordersError: "",

            purchaseform: {
                sort_direction: 'desc',
                col: 'created_at',
                facility_id: this.$store.state.currentLocation.uuid,
                page: 1,
                filters: {
                    tags:[],
                    status: ["pending"],
                    party: '',
                    drug: '',
                    date: {
                        start: '',
                        end: ''
                    },
                    invoice_number: '',
                    show_active: true,
                },
            },
            invoicesEntries: [],
            invoicesLoading: false,
            invoicesError: "",

            syncButtonDisable: false,
        }
    },
    methods: {
        // Get Pending Destruct Entries
        getDestructionEntry() {
            this.$store.commit('setAuthorization');
            this.destructionError = ''
            this.destructionLoading = true
            axios.post('/destruction-entries', this.destructionForm, this.$store.state.header).then(res => {
                if (res.data?.data.length > 0) {
                        this.destructionDrugEntries = res.data
                        console.log("destructionDrugEntries", this.destructionDrugEntries, res);
                    this.destructionLoading = false
                    this.$store.dispatch('getRefreshToken');
                } else {
                    this.destructionError = `No ${this.$store.state.company.type == 'warehouse' ? 'Destruction' : 'Disposals'} Data`
                }
                return res;
            }).catch(err => {
                console.log('this is the getDestructionEntry err', err.response.data)
                this.destructionError = err.response.data.errors ? err.response.data.message + ' ' + Object.values(err.response.data.errors).flat()[0] : err.response.data.message
            })
        },
        // Expand Pending Destruction Entry
        showDestroyContent(destruction, index) {
            if (destruction.expand && (destruction.expand === true)) {
                document.getElementById(`expand-destroy-${index}`).style.display = 'none';
                destruction.expand = !destruction.expand;
            } else {
                destruction.expand = true;
                console.log('expanding range for this object');
                document.getElementById(`expand-destroy-${index}`).style.display = 'table-row';
                axios.post('/single-destruction', { uuid: destruction.uuid }, this.$store.state.header).then(res => {
                    console.log('this is the state function to get a single destruction', res.data);
                    destruction.expandedInfo = res.data.destruction;
                    destruction.expandedInfo.drugs = res.data.destruction.units;
                })
            }
        },
        // Action function for Move to Destuct
        destroyItems(drugs) {
            const destroyDrug = [];
            drugs.forEach(item => {
                destroyDrug.push(item)
            });
            this.destructionpayload.items = destroyDrug;
            this.displayPendingDestructionModal = true;
        },

        // Get Batches Data
        getBatches() {
            this.batchform.filters.status = [];
             this.selectedStatuses.forEach(statusObject => {
                 for (const [key, value] of Object.entries(statusObject)) {
                     this.batchform.filters.status.push(value);
                 }
             });
            this.$store.commit('setAuthorization');
            this.batchError = ''
            this.batchLoading = true
            axios.post('/warehouse/retrieve-batches', this.batchform, this.$store.state.header).then(res => {
                let entries = res.data.data;
                if (entries?.length > 0) {
                    this.batchEntries = res.data
                    this.batchLoading = false
                    this.$store.dispatch('getRefreshToken');
                } else {
                    this.batchError = "No Batches data"
                }
                return res;
            }).catch(err => {
                 this.batchError = err.response.data.errors ? err.response.data.message + ' ' + Object.values(err.response.data.errors).flat()[0] : err.response.data.message
                if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    router.push('/login');
                }
            })
        },

        debouncedBatchData: debounce(function () {
            this.getBatches();
        }, 500),

        // Get Orders Entries
        getOrders() {
            this.$store.commit('setAuthorization');
            this.ordersError = ''
            this.ordersLoading = true
            axios.post('/warehouse/retrieve-orders', this.ordersform, this.$store.state.header).then(res => {
                let entries = res.data.data;
                if (entries?.length > 0) {
                    this.ordersEntries = res.data
                    this.ordersLoading = false
                    this.$store.dispatch('getRefreshToken');
                } else {
                     this.ordersError = "No Orders data"
                }
                return res;
            }).catch(err => {
                this.ordersError = err.response.data.errors ? err.response.data.message + ' ' + Object.values(err.response.data.errors).flat()[0] : err.response.data.message
                if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')) {
                    router.push('/login');
                }
            })
        },

        showOrderContent(delivery, index) {
            if (delivery.display && (delivery.display === true)) {
                document.getElementById(`expand-orders-${index}`).style.display = 'none';
                delivery.display = !delivery.display;
            } else {
                delivery.display = true;
                console.log('expanding range for this object');
                document.getElementById(`expand-orders-${index}`).style.display = 'table-row';
                axios.post('/warehouse/orders-detail', {
                    invoice_id: delivery.invoice_id
                }, this.$store.state.header).then(res => {
                    let entries = res.data.data[0];
                    delivery.note = entries?.note
                    delivery.reference_number = entries?.reference_number
                    delivery.medications = entries?.medications
                }).catch(err => {
                    this.ordersError = err;
                })
            }
        },
        
        passAsProps(item){
           return checkPermissionAndPassAsProps(item)
        },

        completedOrderDialog(item) {
            this.completedDelivery = true
            this.openInvoice = {
                invoice_id: [item?.invoice_id],
                has_scheduled_items: item?.has_scheduled_items
            }
            this.payload2 = { orders: { orders: [] }, OrderDetails: [{ ordernumber: item.invoice_number, uuid: item.invoice_id }] };
            const medicationPromises = {
                order_id: item?.invoice_id,
                medications: item?.medications
            };
            if (item.has_scheduled_items) {
                this.payload2.orders.has_scheduled_items = true;
            }
            this.payload2?.orders.orders.push(medicationPromises);
            this.isStatus = this.passAsProps(item)
            this.noteName = 'Send Order'
        },

       // Get Purchase Entries 
        getPurchaseInvoices() {
            this.$store.commit('setAuthorization');
            this.invoicesError = ''
            this.invoicesLoading = true
            axios.post('/warehouse/retrieve-invoices', this.purchaseform, this.$store.state.header).then(res => {
                let entries = res.data.data;
                if (entries?.length > 0) {
                    this.invoicesEntries = res.data
                    this.invoicesLoading = false
                    this.$store.dispatch('getRefreshToken');
                } else {
                    this.invoicesError = "No warehouse invoices data"
                }
                return res;
            }).catch(error => {
                this.invoicesError =  error.response.data.errors ? error.response.data.message + ' ' + Object.values(error.response.data.errors).flat()[0] : error.response.data.message;
            })
        },
        
        showPurchaseContent(delivery, index) {
            if (delivery.display && (delivery.display === true)) {
                document.getElementById(`expand-purchase-${index}`).style.display = 'none';
                delivery.display = !delivery.display;
            } else {
                delivery.display = true;
                document.getElementById(`expand-purchase-${index}`).style.display = 'table-row';
                axios.post('/warehouse/retrieve-invoice-details', {
                    invoice_id: delivery.invoice_id
                }, this.$store.state.header).then(res => {
                    let entries = res.data.data[0];
                    delivery.note = entries?.note
                    delivery.reference_number = entries?.reference_number
                    delivery.medications = entries?.medications
                }).catch(err => {
                    this.invoicesError = err;
                })
            }
        },
        async completedDeliveryDilog(item) {
            this.completedDelivery = true
            this.openInvoice = {
                invoice_id: [item?.invoice_id],
                has_scheduled_items: item?.has_scheduled_items
            }
            item.medications = await item.medications.map(item => ({
                ...item,
                lost: false,
                destroy: false,
                qty: item.count
            }));
            this.payload2 = item
            this.isStatus = this.passAsProps(item)
            this.noteName = 'Complete Delivery';
        },
        //  Go to Batch Module
        goToBatch(item, index){
          router.push('/stock/batches')
          this.$store.state.dashboardBatchExpend = {form:this.batchform, item: item, index: index} 
        },

        // Intergrate Pagination
        displayPage(page, type) {
            if (type == 'destruction') {
                this.destructionForm.page = page;
                this.getDestructionEntry()
            }else if(type == 'batches'){
                this.batchform.page = page
                this.getBatches()
            }else if(type == 'orders'){
                this.ordersform.page = page
                this.getOrders()
            }else if(type == 'purchase'){
                this.purchaseform.page = page
                this.getPurchaseInvoices()
            }
        },

        getEndpoint(){
          if (this.noteName == 'Send Order') {
            return "/warehouse/sign-off-orders"
          }else{
            return "/warehouse/sign-off-invoices"
          }
        },

        isSuccessful(value) {
            if (value) {
                this.displayPendingDestructionModal = false
                this.getDestructionEntry()
            }
        },

        success(){
          if (this.noteName == 'Send Order') {
            this.getOrders()
          }else{
            this.getPurchaseInvoices()
          }
        },

        close(value, value2) {
            if (value) {
                this.displayPendingDestructionModal = false;
                this.completedDelivery = false;
                this.payload = {};
                this.payload2 = {}
                this.isEdit = false
            }
            if (value2) {
                this.form.filters.status = value2 ? "pending" : "completed"
            }
        },
        
        getDynamicName(type) {
            const matchedItem = this.dynamicName.find(item => item.name === this.$store.state.user.integration_type)
            if (type == 'invoices') {
                return matchedItem ? matchedItem.warehouse_invoices : "Warehouse Invoices"
            } else if (type == 'orders') {
                return matchedItem ? matchedItem.orders : "Orders"
            } else {
                return matchedItem ? `${matchedItem.orders} / ${matchedItem.warehouse_invoices}` : "Orders / Warehouse Invoices"
            }
        },
        // Calling every order and checking their status for signature restrictions
        signRestrict(item) {
            return signRestrictionStatus(item)
        },
        isDeprecatedDrug(item) {
            return item?.medications?.some(item => item?.deprecated)
        },
        // Handles tab click to update the status filter for displaying invoices or orders.
        handleTabsClick(type, status) {
            if (type == 'invoice') {
             this.purchaseform.filters.status = status
             this.getPurchaseInvoices()
            }else if(type == 'order'){
             this.ordersform.filters.status = status
             this.getOrders()
            }
        }
    },
    mounted() {
        // Integrate and Get Pending Destruct Entries
        this.getDestructionEntry()

        // Integrate and Get Batches Data
        this.getBatches()

        // Integrate and Get Orders Data
        this.getOrders()

        // Integrate and Get Purchase Data
        this.getPurchaseInvoices()

        //Sync Entries After 2 Seconds
        if (this.$store.state.user.integration_type === 'carton-cloud' || this.$store.state.user.integration_type === 'microsoft') {
            this.syncButtonDisable = true
            this.syncTimeoutId = setTimeout(async () => {
                //  Sync Invoice Entries
                this.$store.dispatch('syncEntries', { endpoint: '/integration/sync-invoices', type: 'import_invoices', value: false, sync: 'invoices', syncFrom: this.syncFrom })
                //  Sync Orders Entries
                this.$store.dispatch('syncEntries', { endpoint: '/integration/sync-orders', type: 'import_orders', value: false, sync: 'orders', ordersFrom: this.ordersFrom, ordersTo: this.ordersTo })
                
                this.syncButtonDisable = false
            }, 2000)
        }

        // Auto refresh like other when Refresh button 
        this.syncTimeoutId = setTimeout(async () => {
            this.refreshStatusInterval =  setInterval(async () => {
                 let invoicecount = 0
                 let orderscount = 0
                 if (this.$store.state.syncStatus?.invoices?.syncUUIDSucces && invoicecount == 0) {
                     await this.$store.dispatch('refreshSyncList', 'invoices')
                     this.getPurchaseInvoices()
                     invoicecount++
                 }
                 if (this.$store.state.syncStatus?.orders?.syncUUIDSucces && orderscount == 0) {
                     await this.$store.dispatch('refreshSyncList', 'orders')
                     this.getOrders()
                     orderscount++
                 }
                 if (orderscount == 1 && invoicecount == 1) {
                     clearInterval(this.refreshStatusInterval)
                 }
             }, 1000);
        },[2000])
    },
    computed: {
        tooltipMessage() {
            return (item, type) => {
                return tooltipMessagesDisplay(item, type == 'invoice' ? this.invoicesEntries?.data : this.ordersEntries?.data, type );
            };
        },
    },
    beforeUnmount(){
      clearInterval(this.$store.state.syncinvoices)
      clearInterval(this.$store.state.syncorders)
      clearInterval(this.refreshStatusInterval)
      this.$store.commit('setSyncStatus', {key:'invoices', syncError: '', syncUUIDSucces: false, syncLoader:false });
      this.$store.commit('setSyncStatus', {key:'orders', syncError: '', syncUUIDSucces: false, syncLoader:false });
      if (this.syncTimeoutId) {
        clearTimeout(this.syncTimeoutId);
      }
  },
}
</script>
<style lang="scss" scoped>
.dashboard-view {
    .panel {
        h4 {
            margin: 0;
            text-align: left;
            padding-left: 20px;
        }

        .dashboard-container {
            border-radius: 12px;
            background-color: white;
            height: 92%;
            box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
            display: flex;
            flex-direction: column;
        }
    }
}

.dashboard-handle {
    padding: 20px;
    overflow: auto;
}

.warehouse-dashboard {
    width: 100%;
    margin-right: 40px;
    display: grid !important;
    grid-gap: 20px;
    padding-top: 10px;
    border-radius: 12px;
    box-shadow: none;
    height: 86vh;

    .panel {
        margin: 0;
        border-radius: 12px;
    }

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.calenderdatelabel {
    .calendar-input-container {
        border: 1px solid #ced4da;

        input {
            height: 100%;
        }
    }

    label {
        font-weight: 500;
    }

    .multiselectstatus>.p-multiselect-label-container>.p-multiselect-label .p-placeholder {
        color: darkgrey !important;
        font-weight: normal !important;
    }

    .multiselectstatus {
        text-align: start;
    }

    div {
        width: 100%;
        max-width: 100%;
    }

}

.batchItems {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.batchItem {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 12px;
    border-radius: 6px;
    cursor: pointer;

    p {
        margin: 0px;
        font-size: 15px;
        font-weight: 600;
    }
}

.batchItemFooter {
    p {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
    }

    display: flex;
    justify-content: space-between;
}

.tableData {
    max-height: 350px !important;
    overflow: auto !important;
    cursor: pointer;
}

.table-headers {
    background-color: #f3f2f2;

    th {
        font-size: 14px
    }
}

.table-data:nth-child(odd) {
    tr {
        background-color: #f3f2f2;
    }

    td {
        input {
            background-color: white;
        }
    }

}

.table-data:nth-child(even) {
    tr {
        background-color: transparent;
    }

    td {
        input {
            background-color: transparent;
        }
    }

}

.destroy-button {
    color: white;
    background-color: #E04F39;
    font-weight: bold;
    min-width: 100px;
}

.safe-button {
    color: white;
    background-color: #0870DC;
    font-weight: bold;
    min-width: 100px;
}

.data-table th,
.data-table td {
    height: 60px;
    max-height: 60px;
}

.data-table {
    overflow: auto !important;
}

.panel-header {
    font-size: 17px;
    color: #282828;
}

.expanded-content{
    margin: 0px;
    td {
        padding: 0px;
    }
}
.main-content {
   width: 100%;
   padding: 20px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;
}
.expanded-transfer-container{
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
}
.transfer-specs-container {
    width: 100%;
    grid-template-rows: 50px 50px !important;
    grid-template-columns: 75px 75px 75px !important;
    grid-template-areas: "status status status type type type"
        "ordered-from  ordered-from ordered-from ordered-by ordered-by ordered-by"
}
.field-notes{
    width: 100% !important;
    height: max-content;
}
.drugfieldtables{
    height: 100%;
}
.transfer-notes-container{
    width: 100%;
    margin: 0px;
}
.action-footer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;

    .div-action-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        margin-left: 18px;
        align-items: center;
    }
}
.transfer-drugs-container{
    min-width: 100%;
    width: 100%
}

.gap-with-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.disabledButton{
  background-color: #b8b8b8 !important;
  cursor: auto ;
}  

.completed-border {
    border: 1px solid #127a4394;
    background-color: #00ad5075;
}

.expiring-border {
    border: 1px solid #ff8c009e;
    background-color: #ffbc008a;
}

.expired-border {
    border: 1px solid #ff000042;
    background-color: #ff000040;
}

.loader-align-center {
    overflow: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}
.field-value{
    margin: 0px;
}

.open-transfer-row {
    background-color: #0870dc17 !important;
    border-radius: 0px;
    cursor: pointer;
}
.open-transfer-row>td {
  border-top: 1.5px solid #0870DC;
  border-bottom: 1.5px solid #0870DC;
  position: relative;
}
.open-transfer-row>td:first-child {
  border-top: 1.5px solid #0870DC;
  border-left: 1.5px solid #0870DC;
}
.open-transfer-row>td:last-child {
  border-top: 1.5px solid #0870DC;
  border-right: 1.5px solid #0870DC;
}

.purchase-panel {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    position: relative;
}

.order-panel {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    position: relative;
}

.destruction-panel {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.batch-panel {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

.submitpurchase {
    position: absolute;
    left: 162px;
    top: -13px;
}

.submitorder {
    position: absolute;
    left: 130px;
    top: -13px;
}

.lastsyncedtime{
    margin: 0px;
    float: inline-end;
    font-weight: normal
}

@media (max-width: 1550px) {
    .warehouse-dashboard {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    .panel {
        min-height: 600px;
    }

    .purchase-panel {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .order-panel {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    .destruction-panel {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .batch-panel {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
}

@media (max-width: 1100px) {
    .warehouse-dashboard {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .panel {
        min-height: 800px;
    }

    .destruction-panel,
    .batch-panel,
    .order-panel,
    .purchase-panel {
        grid-column: auto;
        grid-row: auto;
    }
}

.wrapping-content{
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}
.data-v-tooltip {
    --v-tooltip-right: 5% !important;

    &::after {
        max-width: 300px !important;
    }
}

</style>