<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Are you sure?</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style="padding: 0;"></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <i
                            v-if="loading"
                            class="pi pi-spin pi-spinner"
                            style="font-size: 2rem !important; margin-top: 10px; margin-bottom: 10px;">
                        </i>
                        <p
                            v-else-if="!loading && !errorMessage"
                            class="action-description">
                            {{ actionDescription }}
                        </p>
                        <p style="color: red;" v-else>
                            {{ errorMessage }}
                        </p>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal()">
                            {{ rejectText ? rejectText : 'Cancel' }}
                        </p>
                        <p class="regular-button error-button" @click="acceptAction()">
                            {{ acceptText ? acceptText : 'Confirm' }}
                        </p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['actionDescription', 'rejectText', 'acceptText', 'loading', 'errorMessage'],
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        acceptAction() {
            this.$emit('accepted');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.action-description {
    white-space: pre-wrap;
    text-align: left;
    font-size: 16px;
}

.modal-body {
    padding-left: 20px;
}

.modal-container {
    min-width: 480px;
    width: 20%;
}

</style>
