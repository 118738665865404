<template>
    <div class="modal-mask" v-if="openstatus === 1">
        <div class="modal-wrapper">
            <div class="modal-container"
                style="min-width: 50rem; width: 50rem;border: 4px solid #e08639;border-radius: 12px;max-height: 45vh;">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3 class="form-header" style="color: #282828;">Remove Item from Batch</h3>
                        </div>
                        <button @click="closeModal(0)" class="red-close-button" style="width: 30px !important">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>
                </div>
                <div class="calenderdatelabel selectAllSwitch" style="display: flex;flex-direction: row;align-items: center;gap: 10px;">
                    <label for="selectAllBatches" style="margin: 0px;">Select All</label>
                    <!-- <InputSwitch aria-labelledby="selectAllBatches" v-model="checked" @change="selectAll" /> -->
                    <button :class="batchtoDelete > 0 ? 'red-button' : 'blue-button'" @click="selectAll(batchtoDelete > 0 ? false : true)">{{ batchtoDelete > 0 ? 'Unselect All' : 'Select All'}}</button>
                </div>
                <div class="modal-body" style="padding: 10px 20px 0px 20px; text-align: left">
                    <table style="display: flex;flex-direction: column;gap: 5px;padding: 0px;">
                        <tr class="bathesdata" v-for="(item, index) in bathesItems" :key="index">
                            <td style="width: 100%;background-color: #F3F4F4;border-radius: 8px;padding: 10px 18px;">
                                <p @click.stop="() => { selectBatch(item, index) }" style="cursor: pointer;">{{
                                    item.medication }}</p>
                                <Checkbox v-model="item.is_true" :binary="true"
                                    @click.stop="() => { selectBatch(item, index) }" style="cursor: pointer;" />
                                <!-- <div> -->
                                <!-- <p style="margin: 0px;" @click="removeDrug(index)"
                                        class="red-close-button small-button">
                                        <i class="pi pi-times" style='padding: 0; padding-top: 3.5px;'></i>
                                    </p> -->
                                <!-- </div> -->
                            </td>
                            <!-- <td>
                                <div class="calendar-input-container medication">
                                    <input id="medication" placeholder="Enter Qty..."
                                        style="position: relative;" :value="item?.quantity"/>
                                </div>
                            </td> -->
                        </tr>
                    </table>
                    <slot name="body">
                        <div class="left-form-section-panel">
                            <p class="note-header">Note</p>
                            <textarea v-model="note" name="note" id="note-modal" rows="10" placeholder="write something"
                                style="width: 100%;border-top-right-radius: 0px;border-top-left-radius: 0px;"></textarea>
                        </div>
                    </slot>
                </div>
                <p style="color: red" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer"
                    style="display: flex; justify-content: flex-end; padding: 10px 20px;margin: 5px 0px;">
                    <div style="display: flex; gap: 10px">
                        <button class="button clear-button" style="margin: 0px;min-width: 175px;height:35px"
                            @click="closeModal(0)">Cancel</button>
                        <button :disabled="batchtoDelete <= 0" class="button pending-button"
                            :style="{minWidth: 'max-content',background:batchtoDelete <= 0 ?  '#b8b8b8' : '#E04F39 ',padding: '0px 55px',cursor: batchtoDelete <= 0 ? 'default' : 'pointer'}"
                            @click="gotosign()">Remove Item from Batch</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Batches Deleted -->
    <div class="modal-mask" v-if="openstatus === 2">
        <div class="modal-wrapper">
            <div class="modal-container"
                style="min-width: 62rem; width: 62rem;border-radius: 12px;border: 4px solid #00AD50;max-height: 86vh;">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                        </div>
                        <button @click="closeModal(0)" class="red-close-button" style="width: 30px !important">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body" style="padding: 20px 20px 0px 20px; text-align: left">
                    <slot name="body">
                        <div class="deliverysuccess">
                            <h1 style="width: 40%;">{{ successName }}</h1>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';

export default {
    props: ['openstatus', 'openInvoice', 'successName'],
    data() {
        return {
            checked:false,
            note: '',
            bathesItems:[],
            errorMessage: ""
        };
    },

    methods: {
        closeModal(value) {
            this.$emit("close", value);
        },
        changestatus(value) {
            this.$emit("changestatus", value);
        },
       async gotosign() {
            let bathesUuid = []
            await this.bathesItems.forEach((item)=>{
                if (item.is_true && item.is_true === true) {
                   return bathesUuid.push(item.batch_item_uuid)
                }
            })
            axios.post("/warehouse/delete-batch-items",{
                batch_uuid:this.openInvoice?.uuid,
                batch_item_uuid:bathesUuid,
                notes:this.note
            },this.$store.state.header).then((res)=>{
                if (res?.data === 200 || res?.status === 200) {
                    this.changestatus(2)
                }else{
                    this.errorMessage = res
                }
            }).catch((err)=>{
                this.errorMessage = err.message
            })
        },
        selectBatch(item, index){
            if(this.bathesItems[index].is_true) {
                this.bathesItems[index].is_true = false
            }else {
                this.bathesItems[index].is_true = true
            }
        },
        selectAll(value){
            this.checked = !this.checked
            if (value) {
                this.bathesItems.forEach((item)=>{
                    return item.is_true = true
                })
            }else {
                this.bathesItems.forEach((item)=>{
                    return item.is_true = false
                })
            }
        }
    },
    mounted() {
        this.bathesItems = this.openInvoice?.medications
    },
    computed:{
        batchtoDelete () {
            let num = 0
            if (this.bathesItems) {
                this.bathesItems.forEach((item)=>{
                    if (item.is_true) {
                        num += 1
                    }
                })
            }
            return num
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

#Sign {
    margin-top: 8px !important;
}

.calendar-input-container {
    background: none !important;
}

.calendar-input-container>input {
    background: none !important;
}

.signinputSuccessfully {
    border: 1px solid #00AD50 !important;
}

.signbtnSuccessfully {
    background-color: #00AD50 !important;
    color: white !important;
}

.deliverysuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    width: 100%;
    text-align: center;
    color: #00AD50;

    h1 {
        margin: 0px;
        margin-top: -66px;
    }
}

.errorabs {
    color: red;
    position: absolute;
    top: 102px;
    margin: 0px;
}

.bathesdata {
    height: max-content;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.bathesdata>td>p {
    margin: 0px;
}

.bathesdata>td {
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center !important
}
.selectAllSwitch{
    float: inline-end;
    margin-right: 20px
}
.medication{
    background: #4b9ce538 !important;
    border: 2px solid #7bb9f1 !important;
}
input {
    height: 100% !important
  }
</style>
