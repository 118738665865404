<template>
    <div class="modal-mask">
        <div class="modal-wrapper">

            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Adjustment Required</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr">
                                <div>

                                    <div >
                                        <p style="font-size: 1em;" >A discrepancy has been found for this item:</p>
                                        <p style="font-size: 1em;">{{ medicationName }} - [EXPECTED - <b>{{ expected }}</b>] -> [COUNTED - <b style="color: red">{{ counted }}</b>]</p>
                                        
                                        <p style="font-size: 1em;">Click Confirm to <span style="font-weight: bold; color: red">proceed to adjust stock level</span> for this balance check.</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" @click="submitCheckAndAdjustment()">Confirm</p>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal
            :endpoint="'/stock-take-submit'" :endpoint2="'/adjustments'"
            v-if="displaySignatureModal" dual="true" @isSuccessful="isSuccessful" @close="closeSigModal"
            :payload="payload" :payload2="form.submit" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
// import axios from 'axios';

export default {
    props: ['display', 'drugs', 'medicationName', 'expected', 'counted', 'payload'],
    components: {
        // DualVerify
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            form: {
                submit: {
                    notes: '',
                    stock_items:[]
                }
            },
            reference_number: '',
            date_received: '',
        }
    },
    methods: {
        closeSigModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        isSuccessful(value) {
                console.log('successful submission', value);
                this.closeModal();
                this.$emit('isSuccessful', true);
            
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        submitCheckAndAdjustment(){
            this.form.submit.stock_items = [{
                stock_item: this.payload.stocktake[0].uuid,
                qty: this.payload.stocktake[0].count 
            }]
            console.log('submitting the order', this.form.submit);
            this.displaySignatureModal = true;
        }
    },
    mounted() {
        console.log('this is the display from action modal', this.display);
        if (this.toAction && this.toAction.action != 'Reverse') {
            console.log('this is the toaction', this.toAction.note);
            this.form.submit.notes = this.toAction.note
        }
    },
    computed: {
        getNotes() {
            return this.toAction.note

        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
