<template>
  <transition name="component-fade" mode="out-in">
  <div class="menu sub-menu">
    <router-link 
    @click="passViewToState('stock/stocktake')" v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_view_stock_take')"    
    class="menu-item" id="stock-take-item" v-bind:class="[ $route.name == 'Stocktake' ? 'active-sub-menu-item' : '']" to="/stock/stocktake"
     >
      <p>Stock Take</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/transfers')" v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_view_transfers')"   
    class="menu-item" id="transfers-item" v-bind:class="[ $route.name == 'Transfers' ? 'active-sub-menu-item' : '']" to="/stock/transfers" >
      <p>Transfers</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/destructions')" v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_view_destruction')"   
    class="menu-item" id="destructions-item" v-bind:class="[$route.name == 'Destructions' ? 'active-sub-menu-item' : '']" to="/stock/destructions" >
      <p>{{$store.state.company.type == 'warehouse' ?  'Destructions' : 'Disposals'}}</p>
    </router-link>
    <router-link
    @click="passViewToState('stock/deliveries')" v-if="$store.state.company.type != 'warehouse' &&$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_deliveries')"
    class="menu-item" id="deliveries-item" v-bind:class="[ $route.name == 'Deliveries' ? 'active-sub-menu-item' : '']" to="/stock/deliveries" >
      <p>Deliveries</p>
    </router-link>
     <!-- <router-link 
    @click="passViewToState('stock/returns')" v-if="$store.state.company.type == 'warehouse'"   
    class="menu-item" style="width: fit-content" id="invoices-item" v-bind:class="[ $route.name == 'Returns' ? 'active-sub-menu-item' : '']" to="/stock/returns" >
      <p>Returns</p>
    </router-link> -->
    <router-link 
    @click="passViewToState('stock/batches')" v-if="$store.state.company.type == 'warehouse'"   
    class="menu-item" style="width: fit-content" id="invoices-item" v-bind:class="[ $route.name == 'Batches' ? 'active-sub-menu-item' : '']" to="/stock/batches" >
      <p>Batches</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/invoices')" v-if="$store.state.company.type == 'warehouse'"   
    class="menu-item" style="width: max-content;min-width: max-content" id="invoices-item" v-bind:class="[ $route.name == 'Invoices' ? 'active-sub-menu-item' : '']" to="/stock/invoices" >
      <p>{{ getDynamicName() }}</p>
    </router-link>
    <!-- <router-link 
    @click="passViewToState('stock/outgoing')"    
    class="menu-item sub-menu-item" id="outgoing-item" v-bind:class="[$store.state.view == 'stock/outgoing' ? 'active-sub-menu-item' : '']" to="/stock/outgoing" >
      <p>Outgoing</p>
    </router-link> -->
    
  </div>
  </transition>
</template>

<script>
import config  from "../../config.js"
// requisitions, stock take, transfers, destructions, deliveries, outgoing
export default {
  name: 'Menu',
  data: function(){
    return{
      selectedView:'',
      dynamicName: config
    }
  },
  methods:{
    passViewToState(view){
      console.log('this is the current view subsub', view);
      this.$store.commit('changeView', view);
      this.selectedView = view;
    },
    getDynamicName(){
      const matchedItem = this.dynamicName.find(item => item.name === this.$store.state.user.integration_type)
      return matchedItem ? matchedItem.warehouse_invoices : "Warehouse Invoices"
    },
  },
  mounted(){
    this.selectedView = window.location.pathname.slice(1);
    this.$store.commit('changeView', this.selectedView);
    console.log('this is the value in stock submenu', this.selectedView);
  },
  computed(){
       console.log('this is the computed value from here in sub', this.selectedView);
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.active-sub-menu-item{
    background-color: white;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    p{
        padding: 8px;
        margin: 0 !important;
    }
}

.sub-menu-item:first-child{
  border-top-right-radius: 0 !important;
}

.sub-menu-item:last-child{
  border-top-left-radius: 0 !important;
}
</style>
