<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="min-width: 42em; width: 52em; max-height: 65vh;">
                <div class="modal-header" style="padding:15px 25px !important; padding-bottom: 0 !important;">
                    <h3 class="form-header" style="margin-left: 10px;">Dispense Information</h3>
                    <button @click.stop="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body" style="padding: 0 25px;">
                    <slot name="body">
                        <div class="action-modal" 
                            style="display: grid; grid-template-columns: 220px 1fr; grid-gap: 10px; padding: 0;">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->

                                <div style="margin-left: 10px;">

                                    <div style="display: grid; grid-template-columns: 1fr; grid-gap: 15px; width: 37.8em;">
                                        <div style="margin-top: 20px;">
                                            <h3 style="text-align: left; margin: 0; margin-bottom: 10px; font-size: 1em;">Status: <span :style="reviewedPrescription.dispensed_items && reviewedPrescription.dispensed_items.length ? {color: 'green'}:{ color: 'red'}">{{ reviewedPrescription.dispensed_items && reviewedPrescription.dispensed_items.length ? 'Dispensed' : 'Not Dispensed'  }}</span> </h3>
                                            <p style="text-align: left; font-size: 1em;" v-if="reviewedPrescription.dispensed_items && reviewedPrescription.dispensed_items.length"><b>Dispensed Item/s:</b> <span v-for="(item,index) in reviewedPrescription.dispensed_items" :key="index">{{ item.name }}<b>{{ index+1 !=  reviewedPrescription.dispensed_items.length ? ' | ':''}}</b></span></p>
                                            <div style="display: flex; flex-direction: column; gap: 5px;">
                                                
                                                <div style="display: flex; align-items: center; gap: 10px;">
                                                    <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock)" @keyup="debounceSearchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                                    style="width: 100%; display: flex" placeholder="Select Medication" /> -->
                                                    <!-- <Dropdown v-if="this.$store.state.user.role.permissions.includes('can_edit_chart')" style="width: 50%;max-width: 21em; display: flex" id="pharmacy-med-list" placeholder="Select Medication" v-model="stock" optionValue="id" :options="formattedDrugList" optionLabel="full_name" :loading="loadingDrugs" /> -->
                                                    <div style="display: flex; align-items: center; gap: 10px; max-width: 46.5em">
                                                        <!-- <Dropdown :loading="loadingGroups" style="width: 10em; display: flex"
                                                        v-model="selectedPackSize" :options="packGroups" @change="getAMTMeds(true)" placeholder="Select Pack Size"  /> -->
                                                        <!-- v-if="drugList.length" -->
                                                        <Dropdown v-if="this.$store.state.user.role.permissions.includes('can_edit_chart') && !reviewedPrescription.expired"  v-model="selectedCTPP" ref="med" @change="drugSelect(selectedCTPP)" 
                                                        @keyup="debounceSearchDrugs(selectedCTPP), show()" :options="formattedDrugList"  optionLabel="name"
                                                        :loading="loadingDrugs"  :editable="true"
                                                        style="min-width: 18em; display: flex" placeholder="Select Pack" />
                                                        
                                                        <Dropdown v-if="selectedCTPP && selectedCTPP.drugs && selectedCTPP.drugs.length > 1 && (this.$store.state.user.role.permissions.includes('can_edit_chart'))" 
                                                            style="min-width: 24em; max-width: 24em; display: flex" placeholder="Select dispensed item" v-model="stock"
                                                            :options="selectedCTPP.drugs" optionLabel="name" :disabled="reviewedPrescription.expired" />
                                                        <p v-if="selectedCTPP && selectedCTPP.drugs && selectedCTPP.drugs.length == 1 && (this.$store.state.user.role.permissions.includes('can_edit_chart'))" style="font-size: 1em;"><b>Dispensing:</b> {{ stock.name }}</p>
                                                    </div>
                                                    <p style="text-align: left; font-size: 1em; margin: 0; margin-bottom: 10px;" v-if="!this.$store.state.user.role.permissions.includes('can_edit_chart')"><b>Packed/Non-Packed:</b> {{ packStatus ? packStatus : 'N/A' }}</p>
                                                </div>
                                                <div style="display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 10px; max-width: 50em">
                                                    <Dropdown v-if="this.$store.state.user.role.permissions.includes('can_edit_chart')" @disabled="reviewedPrescription.expired" name="packed-status"  placeholder="Select Pack Type" :options="actionTypes" v-model="packStatus" />
                                                    <ToggleButton :disabled="!this.$store.state.user.role.permissions.includes('can_edit_chart') || reviewedPrescription.expired" v-model="do_not_crush" aria-label="DO NOT CRUSH" onLabel="DO NOT CRUSH" offLabel="DO NOT CRUSH" style="width:100%"/>
                                                    <ToggleButton :disabled="!this.$store.state.user.role.permissions.includes('can_edit_chart') || reviewedPrescription.expired" v-model="unusual_dose" aria-label="UNUSUAL DOSE" onLabel="UNUSUAL DOSE" offLabel="UNUSUAL DOSE" style="width:100%"/>
                                                    <ToggleButton :disabled="!this.$store.state.user.role.permissions.includes('can_edit_chart') || reviewedPrescription.expired" v-model="unusual_qty" aria-label="UNUSUAL QTY" onLabel="UNUSUAL QTY" offLabel="UNUSUAL QTY" style="width:100%"/>
                                                </div>
                                                <div style="background-color: rgb(229, 241, 254);border-radius: 6px; margin-top: 10px;">
                                                    <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                                    <textarea name="notes" id="note-modal" rows="5"
                                                    :placeholder="notes ? notes : 'No notes available.'" :disabled="!this.$store.state.user.role.permissions.includes('can_edit_chart') || reviewedPrescription.expired"
                                                    style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                                    v-model="notes"></textarea>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                        </div>
                        <!-- <div v-else>
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 10px;"></i>
                        </div> -->
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 0 25px !important; height: 3.5em;">
                    <slot name="footer" style="display: flex;">
                        <button class="green-button" @click="submitReview()" v-if="this.$store.state.user.role.permissions.includes('can_edit_chart') && !reviewedPrescription.expired" style="font-weight: bold">Update</button>
                        <button class="black-button" style="margin-left: 10px; font-weight: bold;" @click="closeModal()">Close</button>
                    </slot>
                </div>
            </div>
        </div>
        <SuccessModal v-if="displaySuccessModal" @close="closeSuccessModal" :message="successMessage.length ? successMessage : ''" :numActions="numActions" :actionsCompleted="actionsCompleted" :action="false" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from './SuccessModal.vue';

export default {
    props: ['patientId', 'close', 'viewOnly', 'reviewedPrescription'],
    components: {
        SuccessModal
    },
    data() {
        return {
            infoPrescriberTab : 'INFO',
            doseTimes: [],
            successMessage: [],
            displaySuccessModal: false,
            notes:'',
            do_not_crush:false,
            unusual_dose: false,
            unusual_qty: false,
            administration_options:[
                {value:'DO NOT CRUSH'},
                {value:'UNUSUAL DOSE'},
                {value:'UNUSUAL QTY'}
            ],
            drugList:[],
            stock:'',
            selectedDrug: {},
            drugSearchFilter: 0,
            numActions:0,
            actionsCompleted:0,
            packStatus: '',
            loadingCharts: false,
            patient: '',
            charts: '',
            activePresChart: '',
            draftDrug: '',
            isDraft: false,
            actionTypes: [
                'Packed',
                'Non-packed'
            ],
            wordNumber:'',
            loadingGroups: false,
            loadingDrugs: false,
            packGroups:null,
            selectedCTPP: null,
            selectedPackSize: null,
            // flatPrescription: {}
        }
    },
    methods: {
        closeSuccessModal(value){
            if(value){
                this.numActions = 0;
                this.actionsCompleted = 0;
                this.displaySuccessModal = false;
                this.$emit('close', true);
                // this.$emit('close', true);
            }
        },  
        submitReview(){
            this.$store.dispatch('getRefreshToken');
            this.numActions = 0;
            // SUBMIT CHART TAGS (PACKED/NON-PACKED/DNC);
            if(this.do_not_crush || this.unusual_dose || this.unusual_qty || this.packStatus){
                this.numActions += 1;
            }
            this.addChartTags();
            // SUBMIT AMT MED
            if(this.stock){
                this.numActions +=1;
            }
            this.submitAMT();
            // SUBMIT NOTES 
            if(this.notes){
                this.numActions += 1;
            }
            this.submitNote();
            
        },
        isSuccessful(value) {
            if (value) {
                // this.retrieveAllCharts();
                // this.$emit('markAsRead', this.notification_id);
            }
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delayif (this.selectedCTPP) {
    
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchDrugs() {
            this.$store.dispatch('getRefreshToken');
            this.getAMTMeds();
        },
        drugSelect(item) {
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            // this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            if(item.drugs.length == 1){
                this.stock = item.drugs[0];
            }
            console.log('this is the selected drug item', this.selectedDrug);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        getCTPGroups(){
            this.loadingGroups = true;
            if (this.reviewedPrescription) {
                axios.post(`/get-pack-size-groups`, { prescription_id: this.reviewedPrescription.uuid }, this.$store.state.header).then(res => {
                    console.log('this is the amt data', res.data);
                    this.packGroups = res.data;
                    this.loadingGroups = false;
                })
            }
        },
        getAMTMeds(changingPackSize) {
            this.loadingDrugs = true;
            if (this.reviewedPrescription) {
                axios.post(`/get-dispensing`, { prescription_id: this.reviewedPrescription.uuid, name: this.selectedCTPP && !changingPackSize ? this.selectedCTPP.name ? this.selectedCTPP.name : this.selectedCTPP : '', pack_size: '' }, this.$store.state.header).then(res => {
                    console.log('this is the amt data', res.data);
                    res.data.forEach(drugGroup=>{
                        drugGroup.drugs.forEach(drug=>{
                            drug.pack_size_id = drugGroup.uuid;
                        })
                    })
                    
                    this.drugList = res.data;
                    this.loadingDrugs = false;
                })
            }
        },
        submitAMT(){
            if(this.stock){
                let payload = {
                    prescription_id: this.reviewedPrescription.uuid,
                    alias_id: this.stock.alias_id,
                    dispensed_item_id: this.stock.pack_size_id,
                    pack_size_id: this.stock.pack_size_id
                }
                this.displaySuccessModal = true;
                axios.post('/prescription/dispense', payload, this.$store.state.header).then(res=>{
                    console.log('updated amt data', res.data);
                    this.actionsCompleted +=1;
                }).catch(err=>{
                    console.log("couldn't submit notes", err.response.data);
                    this.successMessage.push('Failed to update dispense med data.');
                })
            }
            
        },
        submitNote(){
            if(this.notes){
                this.displaySuccessModal = true;
                axios.post(`/chart/${this.reviewedPrescription.chart_id}/add-note`, {note: this.notes}, this.$store.state.header).then(res=>{
                    console.log('submitted notes', res.data);
                    this.actionsCompleted += 1;
                }).catch(err=>{
                    console.log("couldn't submit notes", err.response.data);
                    this.successMessage.push('Failed to add chart notes.');
                })
            }
            
        },
        addChartTags(){
            if(this.do_not_crush || this.unusual_dose || this.unusual_qty || this.packStatus){
                let payloadArray = [];
                if(this.do_not_crush){
                    payloadArray.push('do not crush')
                }
                if(this.unusual_dose){
                    payloadArray.push('unusual dose')
                }
                if(this.unusual_qty){
                    payloadArray.push('unusual quantity')
                }
                if(this.packStatus){
                    payloadArray.push(this.packStatus)
                }
                this.displaySuccessModal = true;
                axios.post(`/chart/${this.reviewedPrescription.chart_id}/add-tags`, {tags:payloadArray}, this.$store.state.header).then(res=>{
                    console.log('this is the chart tag response', res.data);
                    this.actionsCompleted += 1;
                }).catch(err=>{
                    console.log("couldn't submit notes", err.response.data);
                    this.successMessage.push('Could not add chart tags.');
                })
            }
        },

    },
    mounted() {
        // this.getCTPGroups();
        
        if(this.reviewedPrescription && this.reviewedPrescription.chart_tags && this.reviewedPrescription.chart_tags.length){
            
            this.reviewedPrescription.chart_tags.forEach(tag=>{
                if(tag.name == 'do not crush'){
                    this.do_not_crush = true;
                }
                if(tag.name == 'unusual dose'){
                    this.unusual_dose = true;
                }
                if(tag.name == 'unusual quantity'){
                    this.unusual_qty = true;
                }
                if(tag.name == 'packed'){
                    this.packStatus = 'Packed';
                    console.log('found packed tag', this.packStatus);
                }
                if(tag.name == 'non-packed'){
                    this.packStatus = 'Non-packed';
                    console.log('found non-packed tag', this.packStatus);
                }
            });       
            if(this.reviewedPrescription.chart_note && this.reviewedPrescription.chart_note.length){
                console.log('reviewed prescription has notes')
                this.notes = this.reviewedPrescription.chart_note[this.reviewedPrescription.chart_note.length -1].text
            }   
        }
        
        if(this.reviewedPrescription){
            this.getAMTMeds();
            this.reviewedPrescription.dose_times.forEach(time=>{
                this.doseTimes.push({"time": time.time, "day": time.day}); 
            })
        }

    },
    computed: {
        formattedDrugList() {
            let formattedList = [...this.drugList];
            formattedList.forEach(drug => {
                drug.full_name = "(" + drug.mp_term + ") "
                    + drug.ctpp_term
            });
            return formattedList;
        },
        getPrimary() {
            return this.patient.prescribers.filter(item => item.primary_gp)
        },
        chart_tags(){
            return this.reviewedPrescription.chart_tags.filter(item=> !item.name.toLowerCase().startsWith('s4') && !item.name.toLowerCase().startsWith('s8') )
        },
        flatPrescription(){
            if(this.reviewedPrescription){
                return {
                    chart_type: this.reviewedPrescription.chart_type.split('_').join(' '),
                    generic: this.reviewedPrescription.alias_generic,
                    // strength: this.reviewedPrescription.alias.strength,
                    frequency: this.reviewedPrescription.frequency,
                    created_date: this.reviewedPrescription.created_date,
                    route: this.reviewedPrescription.route,
                    start_date: this.reviewedPrescription.start_date,
                    stop_date: this.reviewedPrescription.stop_date,
                    dose: this.reviewedPrescription.dose,
                    interval: this.reviewedPrescription.interval,
                    repeats: this.reviewedPrescription.repeats,
                    // chart_uuid: this.reviewedPrescription.chart.uuid,
                    max_dose: this.reviewedPrescription.max_dose,
                    // prescription_id: this.reviewedPrescription.prescription_id,
                    // alias_tags: this.reviewedPrescription.alias_tags,
                    prescription_type: this.reviewedPrescription.pbs_code ? 'PBS': 'Private',
                    brand_sub: this.reviewedPrescription.brand_sub,
                    authority_prescription_no: this.reviewedPrescription.authority_prescription_number,
                    prescribed_quantity: this.reviewedPrescription.prescribed_quantity
                }
            } else{
                return null
            }
            
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: auto;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.patient-summary-block {
    .field-value {
        background-color: #f3f4f4;
    }
}

.chart-row-item {
    p {
        margin: 0;
        margin-top: 10px;
    }
}

#pharmacy-med-list_list{
    
    .p-dropdown-item{
        max-width: 45em;
        white-space: pre-wrap;
    }
}
</style>
