<template>
    <div>
        <div class="panel" style="margin-left: 1em">
            <h3 class="panel-header">Create New Resident</h3>
            <div class="panel-content-container">
                <div  class="patient-form"
                    v-if="$store.state.user.role.permissions.includes('can_do_resident')">
                    <div class="patient-form-navigation">
                        <div v-if="!creatingPatient || newPatientFinished" class="patient-form-image-container">
                            <img :src="previewImage" class="uploading-image" />
                            <div v-if="!previewImage"
                                style="height: 250px; display: flex; border-radius: 8px;
                            flex-direction: column; align-items: center; justify-content: space-around;  background-color: #F3F4F4;">
                                <i class="pi pi-user" style="font-size: 5em !important; color: #D8DCDC"></i>
                                <p>Resident photo to display here</p>
                            </div>
                            <label for="patient-image-upload" class="image-file-upload"
                                style="width: 100%; margin: 0 auto;  padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white; background-color: #0870DC"
                                v-html="previewImage ? 'Change Image' : 'Upload Resident Image'">
                            </label>
                            <input type="file" id="patient-image-upload" accept="image/jpeg" @change=uploadImage
                                style=" display: none; ">
                        </div>
                        <!-- <div v-else class="patient-form-image-container">
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 10px;"></i>
                        </div> -->
                    </div>
                    <div v-if="!creatingPatient || newPatientFinished" class="patient-form-content">
                        <div class="patient-form-content-section">
                            <div class="personal-info-form-content">
                                <h3>Personal Information</h3>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">First Name <span style="color: red">*</span></label>
                                        <input type="text" name="first_name" v-model="personalInformation.first_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Last Name <span style="color: red">*</span></label>
                                        <input type="text" name="first_name" v-model="personalInformation.last_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Preferred Name </label>
                                        <input type="text" name="first_name" v-model="personalInformation.preferred_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Sex <span style="color: red">*</span></label>
                                        <!-- <input type="text" name="first_name" v-model="personalInformation.sex"> -->
                                        <Dropdown v-model="personalInformation.sex" :options="sex" optionLabel="sex"
                                            optionValue="sex"
                                            :placeholder="personalInformation.sex ? personalInformation.sex : 'Select sex'" />
                                    </div>
                                    <div class="form-field date-field">
                                        <label for="first_name">Date of Birth<span style="color: red">*</span></label>
                                        <input type="date" name="first_name" v-model="personalInformation.dob">
                                        <!-- <InputMask style="background-color: #f3f4f4" mask="99/19/9999" v-model="personalInformation.dob" placeholder="01/01/1900" slotChar="dd/mm/yyyy" /> -->
                                    </div>


                                    <div class="form-field text-field">
                                        <label for="first_name">Medicare Number <span style="color: red">*</span></label>
                                        <InputMask style="background-color: #f3f4f4" mask="9999 99999 9 9"
                                            v-model="regulatory_numbers.medicare_number.number"
                                            placeholder="9999 99999 9 / 9(IRN)" />
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Medicare Expiry <span style="color: red">*</span></label>
                                        <Calendar id="monthpicker" v-model="regulatory_numbers.medicare_number.expiry"
                                            view="month" dateFormat="mm/yy" :yearNavigator="true" yearRange="2020:2050"
                                            style="background-color: #f3f4f4" />
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">DVA Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.dva_number"
                                            v-model="regulatory_numbers.dva_number.number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">DVA Status </label>
                                        <!-- <input type="text" name="first_name" v-model="personalInformation.sex"> -->
                                        <Dropdown v-model="regulatory_numbers.dva_number.regulatory_type"
                                            :options="dva_statuses" optionLabel="status" optionValue="value"
                                            placeholder="Select DVA Status" />
                                    </div>
                                </div>
                            </div>
                            <!-- Location -->
                            <div class="personal-info-form-content">
                                <h3
                                    :style="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex ? { color: 'grey' } : {}">
                                    Resident Location</h3>
                                <div class="form-fields" v-if="!facilities || !facilities.length || !nodes.length">
                                    <div class="form-field text-field">
                                        <p style="font-size: 1em; margin-left: 1em;">Loading location data...</p>
                                    </div>

                                </div>
                                <div v-else class="form-fields" style="align-items: flex-end">
                                    <div class="form-field text-field" v-if="facilities && facilities.length">
                                        <label for="first_name">Facility <span style="color: red">*</span></label>
                                        <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                                            @change="retrieveSelectedFacilityLocations()"
                                            :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" />
                                    </div>
                                    <div class="form-field text-field" v-if="nodes && nodes.length">
                                        <label for="first_name">Location <span style="color: red">*</span></label>
                                        <div v-if="loadingLocations">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 2rem !important;margin-top: 10px;"></i>
                                        </div>
                                        <TreeSelect v-else v-model="selectedNodeKey" :options="nodes" ariaLabelledBy="name"
                                            placeholder="Select Item" />
                                    </div>

                                </div>
                            </div>
                            <!-- Create button -->
                            <div>
                                <div class="field-checkbox" style="display: flex; margin-top: 2em;">
                                    <Checkbox :binary="true" v-model="aslr_site_consent" />
                                    <label style="font-size: 1em; padding-left: 10px; font-weight: bold"
                                        for="binary">Resident consents to healthcare provider view access of their Active
                                        Script List (if they are registered).</label>
                                </div>
                                <p :class="requiredDetails ? 'blue-button' : 'grey-button'"
                                    @click="requiredDetails && createPatient()"
                                    style="margin: 1em 1em 1em 0em; padding: 8px 20px; width:fit-content; font-weight: bold;"> Create Resident</p>
                            </div>

                        </div>
                    </div>
                    <div v-else class="patient-form-image-container">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 10px;"></i>
                    </div>
                </div>

                <div v-else style="margin-top: 10em;">
                    <h3
                        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                        You do not have permission to access this feature. Please talk to your admin if you require access.
                    </h3>
                </div>
            </div>
        </div>
        <NewResdidentModal @close="resetView" @proceed="proceed" v-if="newPatientFinished" :stages="stages" />
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="errMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import NewResdidentModal from '@/components/modals/NewResidentModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    components: {
        NewResdidentModal,
        SuccessModal
    },
    data() {
        return {
            creatingPatient: false,
            bundleSuccess: false,
            loadingLocations: false,
            patientStatusFinished: false,
            regulatoryFinished: false,
            imageUploadFinished: false,
            errMessage: "",
            displaySuccessModal: false,
            patientUUID: "",
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                tag: [],
                sex: '',
                dob: '',
            },
            aslr_site_consent: false,
            regulatory_numbers: {  
                medicare_number: {
                    number: '',
                    regulatory_type: 'Medicare Number',
                    expiry: ''
                },
                dva_number: {
                    number: '',
                    regulatory_type: 'DVA Number',
                    expiry: ''
                },
            },
            sex: [
                { sex: 'Male' },
                { sex: 'Female' },
                { sex: 'Intersex' }
            ],
            dva_statuses: [
                { status: 'Gold', value: 'DVA Gold' },
                { status: 'White', value: 'DVA White' },
                { status: 'Orange', value: 'DVA Orange' },
            ],
            facility_location: {
                room_uuid: '',
            },
            // LOCATION DETAILS
            parentLocation: '',
            nodes: [],
            selectedNodeKey: "",
            facilities: [],
            locationName: '',
            childLocations: [],
            previewImage: null,
            pharmacies: '',
            selectedPharmacy: '',
            prescribers: '',
            selectedPrescriber: '',
            ihiDetails: '',
            stages: []
        }
    },
    methods: {
        createPatient() {
            this.creatingPatient = true
            let personalPayload = { ...this.personalInformation, location: Object.keys(this.selectedNodeKey)[0] }
            personalPayload.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            if (this.aslr_site_consent) {
                console.log('HITTING THE ASLR SITE CONSENT CONDITION', personalPayload);
                personalPayload.tag.push('aslr_site_consent');
            }
            if (!this.patientUUID) {
                return axios.post('/person/bundle', personalPayload, this.$store.state.header).then(res => {
                    this.bundleSuccess = true
                    this.stages.push("General Patient Infomation saved")
                    console.log('patient registered.');
                    this.patientUUID = res.data.uuid;
                    axios.post(`/person/${this.patientUUID}/status`, { status: 'onboarding' }, this.$store.state.header).then(res => {
                        this.patientStatusFinished = true
                        // this.stages.push(" Patient status saved")
                    }).catch(err => {
                        this.patientStatusFinished = true
                        // this.stages.push(" Patient Status save failed: " + err.response.data)
                        this.successMessage = `Error: ${err.response.data}`;
                    })
                    this.submitRegulatoryInfo();
                    this.submitImage();
                }).catch(err => {
                    // Display failure because bundle is the only required endpoint for generation. The rest can be added on later. 
                    this.displaySuccessModal = true
                    this.creatingPatient = false
                    this.errMessage = "General Patient Infomation save failed: " + err.response.data
                });
            }
        },
        retrieveSelectedFacilityLocations() {
            this.loadingLocations = true;
            this.locationDirectory = '';
            console.log('this is the selected facility', this.selectedFacility);
            axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
                console.log('these are the facilities locations', res.data);
                this.nodes = res.data;
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
                this.loadingLocations = false;
            })
        },
        getFacilities() {
            this.$store.dispatch('getRefreshToken');
            axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
                this.facilities = res.data;
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        resetView() {
            location.reload();
        },
        close() {
            this.displaySuccessModal = false
        },
        proceed() {
            this.$router.push('/patient/' + this.patientUUID + '/patient_info')
            this.displaySuccessModal = false;
        },
        // UPLOAD IMAGE
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        getLocations() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        // SUBMIT IMAGE
        submitImage() {
            if (this.previewImage) {
                this.uploadingImage = true
                axios.post(`/aws/patient/${this.patientUUID}/image`, {
                    image: this.previewImage,
                    item: ['profile', 'thumbnail']
                }, this.$store.state.header).then(res => {
                    this.uploadingImage = false
                    this.imageUploadFinished = true
                    this.stages.push("Resident image saved")
                    console.log('uploaded patient image', res);
                }).catch(err => {
                    this.imageUploadFinished = true
                    this.stages.push("Resident Image save failed: " + err.response.data)
                    console.log('this is the image upload error', err, err.response.data);
                })
            } else {
                this.imageUploadFinished = true
                console.log('No image has been uploaded');
            }
        },
        // REGULATORY INFO
        submitRegulatoryInfo() {
            let regulatory_numbers = [];
            for (let key in this.regulatory_numbers) {
                console.log('this is the key', key);
                if (this.regulatory_numbers[key].number && this.regulatory_numbers[key].regulatory_type != 'IHI Number') {
                    regulatory_numbers.push({
                        data: this.regulatory_numbers[key].number.split(' ').join(''),
                        regulatory_type: this.regulatory_numbers[key].regulatory_type,
                        expiry: this.regulatory_numbers[key].expiry ? new Date(this.regulatory_numbers[key].expiry).toLocaleDateString() : ''
                    })
                }
            }
            if (regulatory_numbers.length) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/regulatory`, { 'regulatory_numbers': regulatory_numbers }, this.$store.state.header).then(res => {
                    this.regulatoryFinished = true;
                    this.stages.push("Regulatory infomation saved")
                    console.log('regulatory information submitted.', res)
                }).catch(err => {
                    this.regulatoryFinished = true;
                    this.stages.push("Regulatory information save failed: " + err.response.data)
                    console.log('this is the reg info error', err, err.response.data);
                })
            } else {
                console.log('no regulatory numbers filled in or submitted.')
            }
        },


        selectChildLocation() {
            this.facility_location.room_uuid = Object.keys(this.selectedNodeKey)[0];
        },
        // ON PAGE LOAD
        getLocationTree() {
            // clear out old error messages
            this.second_spec_line = '';
            this.errActions = '';

            axios.post(`/location/${this.$store.state.uuid}/simple-tree`, {}, this.$store.state.header).then(res => {
                this.parentLocation = res.data.parent_location_name;
                this.locationName = res.data.name;
                this.childLocations = res.data.child_locations;
            })
        }
    },
    mounted() {
        this.getLocationTree();
        this.getLocations();
        this.getFacilities();
    },
    computed: {
        requiredDetails() {
            return this.personalInformation.first_name && this.personalInformation.last_name && this.personalInformation.sex && this.personalInformation.dob && this.regulatory_numbers.medicare_number.number && this.regulatory_numbers.medicare_number.expiry && this.selectedNodeKey ? true : false
        },
        newPatientFinished() {
            return this.bundleSuccess && this.patientStatusFinished && this.regulatoryFinished && this.imageUploadFinished
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


#monthpicker {
    border-radius: 12px;

    input.p-inputtext {
        background-color: #f3f4f4 !important;

    }
}

.selected-navigation-item {
    width: 5px;
    height: 45px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    background-color: $strongblue;
}


.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.text-field {
    width: 20em;
}

.date-field {
    width: 20em;
}

.address-field {
    width: 12em;
}

.special-considerations-form {
    background-color: $grey;
    padding: 10px;
    border-radius: 8px;
}

.spec-cons-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spec-cons-item {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.vac-weight-table {
    max-height: 300px;
    overflow-y: scroll;
}

// .form-control{
//     width: 20em;
// }

.prescriber-info-form-content {
    h3 {
        margin-top: 12px;
    }
}

#monthpicker {
    background-color: #F3F4F4 !important
}
</style>