<template>
    <div>
        <div>
            <h4 style="text-align: left;margin: 0; ">Integration Credentials</h4>
            <p style="text-align: left; font-size: 1em; ">Credentials may be provided here to connect to 
                {{ $store.state.company.type === 'warehouse' ? 'an integration platform' : 'a CMS platform' }}
                currently used in a facility/facilities within the company.</p>
            <!-- <p style="text-align: left; font-size: 1em; ">Unlinking a prescriber removes their access to the selected facility's resident data.</p> -->
            <div v-if="loadingCredentials">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display: flex; align-items: flex-end; margin-bottom: 1em;">
                <div style="width: fit-content; margin-right: 10px;"
                    v-if="!displayNewCredentialForm && credentialsList.length">
                    <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Select
                        Existing Credentials: </p>
                    <Dropdown v-model="selectedCredential" @change="getCompanyIntegratedFacilities()"
                        :options="credentialsList" optionLabel="name" style="width: 20em; height: fit-content" />
                </div>
                <div>
                    <button class="blue-button" v-if="!displayNewCredentialForm"
                        @click="displayNewCredentialForm = true">Add New Credentials</button>
                    <button class="grey-button" v-if="displayNewCredentialForm"
                        @click="displayNewCredentialForm = false, clearNewCredentials()">Cancel New Credentials</button>
                </div>
            </div>
            <div v-if="selectedCredential && !displayNewCredentialForm">
                <div class="form-field wide-text-field" style="width: 30%; margin-bottom: 1em;">
                    <label for="address" style="padding: 0;">Type <span style="color: red;">*</span></label>
                    <SelectButton disabled style="display: flex; width: 90%" class="dashboard-type"
                        v-model="selectedCredential.type" :options="companyFacilityToggle" optionLabel="label"
                        optionValue="value" />
                </div>
                <div style="margin-bottom: 1em; width: fit-content;">
                    <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">Platform:
                    </p>
                    <Dropdown disabled v-model="selectedCredential.integration" :options="integrationOptions"
                        style="width: 20em; height: fit-content" />
                </div>
                <div style="display: grid; grid-template-columns: 1fr; grid-gap: 1em;">
                    <div class="form-field wide-text-field" style="width: 80%;">
                        <label for="contact_name" style="padding: 0;">Name <span style="color: red;">*</span></label>
                        <div style="display: flex; gap: 10px; align-items: center;">
                            <input type="text" style="width: 80%;"
                                :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="selectedCredential.name" placeholder="Enter Integration Name">
                            <p class="green-button" @click="saveName()">Save Name</p>
                        </div>

                    </div>
                    <div class="form-field wide-text-field" style="width: 80%;">
                        <label for="phone_num" style="padding: 0;">Authentication</label>
                        <!-- <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                            v-model="selectedCredential.authentication.key" placeholder="Enter Key"> -->
                        <p class="black-button" style="cursor: inherit; width: fit-content">{{
                            selectedCredential.selectedOption }} has been provided.</p>
                    </div>
                    <!-- <div class="form-field wide-text-field" style="width: 80%;" v-if="selectedCredential.selectedOption == 'key'">
                        <label for="phone_num" style="padding: 0;">Key</label>
                        <p class="black-button" style="cursor: inherit; width: fit-content">Key has been provided.</p>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em; width: 80%" v-if="selectedCredential.selectedOption == 'login'">
                        <div class="form-field wide-text-field" >
                            <label for="phone_num">Username</label>
                            <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="selectedCredential.authentication.username" placeholder="Enter Username">
                        </div>
                        <div class="form-field wide-text-field" >
                            <label for="phone_num">Password</label>
                            <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="selectedCredential.authentication.password" placeholder="Enter Password">
                        </div>
                    </div> -->
                    <!-- <p style="font-weight: bold; font-size: 1em; text-decoration: underline; text-align: left; padding-left: 10px; cursor: pointer" @click="selectedCredential.selectedOption = 'login'" v-if="selectedCredential.selectedOption == 'key'">Remove key and enter login username and password credentials</p>
                    <p style="font-weight: bold; font-size: 1em; text-decoration: underline; text-align: left; padding-left: 10px; cursor: pointer" @click="selectedCredential.selectedOption = 'key'" v-else>Remove login and password and enter key credentials</p> -->
                    <div>
                        <div v-if="loadingCredentialsFacilities">
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                        </div>
                        <table v-if="!loadingCredentialsFacilities" class="data-table"
                            style=" width: 80%; border-collapse: collapse;">
                            <tr class="table-headers">
                                <th>Current Facility</th>
                                <!-- <th style="padding: 0 10px;">Change Facility</th> -->
                                <th style="padding: 0 10px;">External ID</th>
                                <th style="padding: 0 10px;">Integration Label</th>
                                <th style="width: 2em"></th>
                            </tr>
                            <tr class="table-data" v-for="(item, index) in companyFacilities" :key="index"> 

                                
                                <td>
                                    <input style="width: 100%;" type="text" disabled v-model="item.facility.name"
                                        placeholder="Enter Integration Name" v-if="item.facility">
                                    <Dropdown v-if="facilities && facilities.length && !item.facility"
                                        v-model="item.facility_change" :options="facilities" optionLabel="label"
                                        optionValue="value" style="width: 100%; height: fit-content" />
                                </td>
                                <!-- <td style="padding: 0 10px;">
                                    
                                </td> -->
                                <td style="padding: 0 10px;">
                                    <input style="width: 100%;" type="text" disabled v-model="item.external_id"
                                        placeholder="Enter Integration Name">
                                </td>
                                <td style="padding: 0 10px;">
                                    <input style="width: 100%;" type="text" disabled v-model="item.integration_label"
                                        placeholder="Enter Integration Name">
                                </td>
                                <td>
                                    <i class="pi pi-undo"
                                        style="font-size: 1.2em !important; color: #0870DC; cursor: pointer"
                                        v-if="item.facility_change" @click="item.facility_change = null"></i>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
                <div style="display: flex; gap: 10px;">
                    <p class="green-button"
                        style="text-align: left; width: fit-content;margin-top: 10px; padding: 5px 25px; font-size: 1em;"
                        @click="saveFacilityIds()"
                        v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
                        Save List
                    </p>
                    <p class="red-button"
                        style="text-align: left; width: fit-content;margin-top: 10px; padding: 5px 25px; font-size: 1em;"
                        @click="deleteCredentials()"
                        v-if="selectedCredential && $store.state.user.role.permissions.includes('can_do_admin_company')">
                        Delete Credentials
                    </p>
                </div>

            </div>
            <div v-if="displayNewCredentialForm">
                <div>
                    <div class="form-field wide-text-field" style="width: 30%; margin-bottom: 1em;">
                        <label for="address">Type <span style="color: red;">*</span></label>
                        <SelectButton style="display: flex; width: 90%" class="dashboard-type"
                            v-model="newCredentialForm.type" :options="companyFacilityToggle" optionLabel="label"
                            optionValue="value" />
                    </div>
                    <div style="margin-bottom: 1em; width: fit-content;">
                        <p style="padding-right: 1em; font-size: 1em; text-align: left; margin: 0; font-weight: 500;">
                            Platform: </p>
                        <Dropdown v-model="newCredentialForm.integration" :options="integrationOptions"
                            style="width: 20em; height: fit-content" />
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr; grid-gap: 1em;">

                        <div v-if="!authenticatedNewCredentials">
                            <p style="font-weight: bold; width: fit-content; font-size: 1em; text-decoration: underline; text-align: left; padding-left: 10px; cursor: pointer" @click="newCredentialForm.selectedOptionUrl = 'url'" v-if="newCredentialForm.selectedOptionUrl == ''">Enter URL Manually</p>
                        </div>

                        <div class="form-field wide-text-field" style="width: 80%;" v-if="newCredentialForm.selectedOptionUrl == 'url'"> 
                            <label for="contact_name">URL </label>
                            <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="newCredentialForm.url" placeholder="Enter Integration URL">
                        </div>

                        <div class="form-field wide-text-field" style="width: 80%;">
                            <label for="contact_name">Name <span style="color: red;">*</span></label>
                            <input type="text"
                                :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="newCredentialForm.name" placeholder="Enter Integration Name">
                        </div>

                        <div class="form-field wide-text-field" style="width: 80%;" v-if="newCredentialForm.selectedOption == 'key'">
                            <label for="phone_num">Key <span style="color: red;">*</span></label>
                            <input type="text"
                                :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                v-model="newCredentialForm.key" placeholder="Enter Key">
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em; width: 80%"
                            v-if="newCredentialForm.selectedOption == 'login'">
                            <div class="form-field wide-text-field">
                                <label for="phone_num">{{$store.state.company.type === 'warehouse' ? 'Client Id' : 'Username'}} <span style="color: red;">*</span></label>
                                <input type="text"
                                    :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                    v-model="newCredentialForm.username" placeholder="Enter Username">
                            </div>
                            <div class="form-field wide-text-field">
                                <label for="phone_num">{{$store.state.company.type === 'warehouse' ? 'Client Secret' : 'Password'}} <span style="color: red;">*</span></label>
                                <input type="text"
                                    :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                                    v-model="newCredentialForm.password" placeholder="Enter Password">
                            </div>
                        </div>
                        <div v-if="!authenticatedNewCredentials">
                            <p style="font-weight: bold; width: fit-content; font-size: 1em; text-decoration: underline; text-align: left; padding-left: 10px; cursor: pointer"
                                @click="newCredentialForm.selectedOption = 'login'"
                                v-if="newCredentialForm.selectedOption == 'key'">Use login 
                                {{$store.state.company.type === 'warehouse' ? 'client id' : 'username'}} 
                                and 
                                {{$store.state.company.type === 'warehouse' ? 'client secret' : 'password'}} 
                                credentials
                            </p>
                            <p style="font-weight: bold; width: fit-content; font-size: 1em; text-decoration: underline; text-align: left; padding-left: 10px; cursor: pointer"
                                @click="newCredentialForm.selectedOption = 'key'" v-else>Enter API key credentials</p>
                        </div>
                    </div>
                </div>

                <div v-if="authenticatedNewCredentials" style="margin-top: 20px;">
                    <table v-if="!loadingIntegrationFacilities" class="data-table"
                        style=" width: 80%; border-collapse: collapse;">
                        <tr class="table-headers">
                            <th>Select Facility</th>
                            <th style="padding: 0 10px;">External ID</th>
                            <th style="padding: 0 10px;">Integration Label</th>
                            <th style="width: 2em"></th>
                        </tr>
                        <tr class="table-data" v-for="(item, index) in companyFacilities" :key="index">
                            <td>
                                <Dropdown v-if="facilities && facilities.length" v-model="item.facility_change"
                                    :options="facilities" optionLabel="label" optionValue="value"
                                    style="width: 100% ; height: fit-content" />
                            </td>
                            <td style="padding: 0 10px;">
                                <input style="width: 100%; " type="text" disabled v-model="item.external_id"
                                    placeholder="Enter Integration Name">
                            </td>
                            <td style="padding: 0 10px;">
                                <input style="width: 100%; " type="text" disabled v-model="item.integration_label"
                                    placeholder="Enter Integration Name">
                            </td>
                            <td>
                                <i v-if="item.facility_change" class="pi pi-undo"
                                    style="font-size: 1.2em !important; color: #0870DC; cursor: pointer"
                                    @click="item.facility_change = null"></i>
                            </td>
                        </tr>
                    </table>
                    <div v-else>
                        <i class="pi pi-spinner"></i>
                    </div>
                </div>
                <div style="display: flex;">
                    <p class="green-button"
                        style="text-align: left; width: fit-content;margin-top: 10px; padding: 5px 25px; font-size: 1em;"
                        @click="!authenticatedNewCredentials ? saveNewCredentials() : saveFacilityIds()"
                        v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
                        Save {{ authenticatedNewCredentials ? 'Configuration' : 'Credentials' }}
                    </p>
                    <!-- Visible and clickable only if authenticated and has permission and -->
                    <p v-if="$store.state.user.role.permissions.includes('can_do_admin_company') && isMsAuthenticated"
                        class="blue-button"
                        style="text-align: left; width: fit-content; margin-top: 10px; margin-left: 10px; padding: 5px 25px; font-size: 1em; cursor: pointer;"
                        @click="retrieveBCLocations">
                        Retrieve BC Location
                    </p>
                    <!-- Visible but not clickable if not authenticated but has permission -->
                    <!-- <p v-else-if="$store.state.user.role.permissions.includes('can_do_admin_company')" class="blue-button"
                        style="text-align: left; width: fit-content; margin-top: 10px; margin-left: 10px; padding: 5px 25px; font-size: 1em; cursor: not-allowed; opacity: 0.5;">
                        Retrieve BC Location
                    </p> -->
                </div>

                <p v-if="errorMessage" style="color: red; font-size: 1em; font-weight: bold;">{{ errorMessage }}</p>
            </div>

        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
        <SuccessModal @close="close" v-if="displayDeleteCredentialModal && selectedCredential" :action="true"
            :actionType="'sign'" :signature_endpoint="'/remove-company-integration'"
            :message="'Are you sure you wish to delete these credentials?'"
            :second_spec_line="'Signing off on this action means you are aware of the potential risks in deleting an active credential. Please talk to your IT department or Strong Care support if you are unsure.'"
            :payload="{ 'integration_id': selectedCredential.uuid }" @successfullySubmitted="successfullySubmitted" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props: ['facilities'],
    components: {
        SuccessModal
    },
    data() {
        return {
            isMsAuthenticated: false,
            selectedIntegration: null,
            displayNewCredentialForm: false,
            displaySuccessModal: false,
            authenticatedNewCredentials: false,
            displayDeleteCredentialModal: false,
            loadingIntegrationFacilities: false,
            loadingCredentials: false,
            loadingCredentialsFacilities: false,
            integrationOptions: [
                'LeeCare',
                'eCase'
            ],
            companyFacilityToggle: [
                {
                    label: 'Company',
                    value: 'company'
                },
                {
                    label: 'Facility',
                    value: 'facility'
                }
            ],
            selectedCredential: null,
            credentialsList: [
                // {
                //     integration: 'LeeCare',
                //     name: '(LeeCare) Brunswick Facility',
                //     type :'facility',
                //     authentication:{
                //         key: '929342-239292-229292f',
                //         username: '',
                //         password:''
                //     }
                // },
                // {
                //     integration: 'eCase',
                //     name: '(eCase) Western Suburbs',
                //     type :'company',
                //     authentication:{
                //         key: '',
                //         username: 'hopeLogin',
                //         password:'hopePassword'
                //     }

                // }
            ],
            companyFacilities: [
                // {
                //     facility_change: null,
                //     facility: null,
                //     external_id: '9293292-232022',
                //     integration_label: 'Bruns. Fac.'
                // },
                // {
                //     facility_change: null,
                //     facility: {
                //         name: 'Sunshine Facility',
                //         uuid: '2934923-234234'
                //     },
                //     external_id: '9293292-222221',
                //     integration_label: 'Sun. Fac.',
                //     uuid: ""
                // }
            ],
            linkedFacility: [
                // {
                //     facility_change: null,
                //     facility_name: 'Sunshine Facility',
                //     external_id: '9293292-222221',
                //     integration_label: 'Sun. Fac.'
                // }
            ],
            newCredentialForm: {
                selectedOptionUrl: '',
                selectedOption: 'login',
                type: null,
                integration: null,
                key: '',
                name: '',
                username: '',
                password:'',
                url: ''
            },
            newCredentialId: '',

            successMessage: '',
            errorMessage: '',
            selectedFacility: '',
            loadingConfig: false,
            loadingFacilities: false,
            prescribers: []
        }
    },
    methods: {
        checkAuthentication() {
            const msAuth = localStorage.getItem('ms_auth');
            if (msAuth) {
                const authObj = JSON.parse(msAuth);
                this.isMsAuthenticated = !!authObj.auth_token;
            } else {
                this.isMsAuthenticated = false;
            }
        },
        deleteCredentials() {
            console.log('deleting credentials');
            this.displayDeleteCredentialModal = true;
        },
        successfullySubmitted(value) {
            if (value) {
                this.displayDeleteCredentialModal = false;
                this.selectedCredential = null;
                this.getCompanyCredentials();

            }
        },
        saveName() {
            let payload = {
                integration_id: this.selectedCredential.uuid,
                name: this.selectedCredential.name
            }
            axios.post('/edit-company-integration', payload, this.$store.state.header).then(res => {
                console.log('updated company name');
                this.successMessage = 'Successfully updated integration name!';
                this.displaySuccessModal = true;

            })
        },
        clearNewCredentials() {
            this.newCredentialForm = {
                selectedOption: 'login',
                type: null,
                integration: null,
                key: '',
                name: '',
                username: '',
                password: ''
            }
        },
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
                this.displayDeleteCredentialModal = false;
            }
        },
        getIntegrationOptions() {
            axios.post(`/get-integration-options`, {}, this.$store.state.header).then(res => {
                if (this.$store.state.company.type === 'warehouse') {
                    const filterdata = res.data.response.filter(item=> item == 'microsoft' || item == 'carton-cloud')
                    this.integrationOptions = filterdata;
                }else{
                    this.integrationOptions = res.data.response;
                }
            }).catch(err => {
                this.displaySuccessModal = true;
                this.successMessage = err.response.data?.message ?? err.response.data;
            })
        },
        getCompanyCredentials() {
            this.loadingCredentials = true;
            axios.post('/get-company-integrations', { type: ['facility', 'company'] }, this.$store.state.header).then(res => {
                this.credentialsList = res.data.response.data;
                console.log('this is the list of company integrations', res.data);
                this.loadingCredentials = false;
                this.credentialsList.forEach(item => {
                    if (item.authentication.key) {
                        item.selectedOption = 'key'
                    } else {
                        item.selectedOption = 'login'
                    }
                })
            }).catch(err => {
                this.displaySuccessModal = true;
                this.successMessage = err.response.data?.message ?? err.response.data;
            })
        },
        getCompanyIntegratedFacilities() {
            this.loadingCredentialsFacilities = true;
            axios.post('/retrieve-integration-facilities', { integration_id: this.selectedCredential.uuid }, this.$store.state.header).then(res => {
                console.log("/retrieve-integration-facilities: ", res)
                this.companyFacilities = res.data.response;
                this.loadingCredentialsFacilities = false;
                console.log('these are the integration facilities', res.data);
            }).catch(err => {
                this.loadingCredentialsFacilities = false;
                this.displaySuccessModal = true;
                this.successMessage = err.response.data?.message ?? err.response.data;
            })
        },
        saveNewCredentials() {
            if (this.newCredentialForm.type && this.newCredentialForm.name && this.newCredentialForm.integration && (this.newCredentialForm.key || (this.newCredentialForm.username && this.newCredentialForm.password))) {
                axios.post(`/save-integration-credentials`, this.newCredentialForm, this.$store.state.header).then(res => {
                    if (res && !res.data.url) {
                        this.newCredentialId = res.data.response.data.uuid;
                        this.loadingIntegrationFacilities = true;
                        axios.post('/retrieve-integration-facilities', { integration_id: this.newCredentialId }, this.$store.state.header).then(res1 => {
                            this.companyFacilities = res1.data.response;
                            this.loadingIntegrationFacilities = false;
                        }).catch(err => {
                            this.loadingIntegrationFacilities = false;
                            this.displaySuccessModal = true;
                            this.successMessage = err.response?.data?.message ?? err.response?.data ?? "Failed to retrieve the integration facilities.";
                        });
                        this.authenticatedNewCredentials = true;
                    } else {
                        /**
                         * This is the begining of the microsoft authentication workflow
                         */
                        const msAuthObject = {
                            integration_id: res.data.response.data.uuid,
                            auth_token: null
                        };
                        localStorage.setItem('ms_auth', JSON.stringify(msAuthObject));
                        window.name = "tab1";
                        window.open(res.data.url, '_blank');
                    }
                }).catch(err => {
                    this.loadingIntegrationFacilities = false;
                    this.displaySuccessModal = true;
                    this.successMessage = err.response?.data?.message ?? err.data?.response?.message ?? err.response?.data ?? "Failed to save the integration credentials.";
                })

                this.errorMessage = '';
            } else {
                this.errorMessage = 'You need to enter all fields above to properly save credentials.'
            }

        },
        saveFacilityIds() {
            let facilities = [];
            this.companyFacilities.forEach(facility => {
                if (facility.facility_change) {
                    facilities.push({
                        facility_id: facility.facility_change,
                        external_id: facility.uuid
                    })
                }
            })
            let payload = {
                facilities: facilities,
                integration_id: !this.selectedCredential ? this.newCredentialId : this.selectedCredential.uuid,
            }
            axios.post('/save-facility-integration', payload, this.$store.state.header).then(res => {
                console.log('saved facility integration', res);
                this.successMessage = 'Successfully saved facility mapping.';
                this.displaySuccessModal = true;
                this.getCompanyIntegratedFacilities();
            }).catch(err => {
                this.displaySuccessModal = true;
                this.successMessage = err.response.data?.error.error ?? err.response.data;
            })
        },
        retrieveBCLocations() {
            const msAuth = localStorage.getItem('ms_auth');
            const msAuthObject = msAuth ? JSON.parse(msAuth) : null;
            const payload = {
                integration_id: msAuthObject ? msAuthObject.integration_id : ""
            };
            if (payload.integration_id) {
                this.displayNewCredentialForm = false;
                this.selectedCredential = {
                    uuid: payload.integration_id
                };
                this.getCompanyIntegratedFacilities();
                // axios.post('/ms/retrieve-bc-locations', payload, this.$store.state.header).then(res => {
                //     console.log('this is the response from /ms/retrieve-bc-locations', res.data.response);
                //     this.displayNewCredentialForm = false;
                //     this.selectedCredential= {
                //         uuid:localStorage.getItem('ms_integration_id')
                //     };
                //     // DEBUG 
                //     // should call /ms/retrieve-bc-location instead of getCompanyIntegratedFacilities()
                //    this.getCompanyIntegratedFacilities();

                // }).catch(err => {
                //     this.displaySuccessModal = true;
                //     this.successMessage = err;
                // });
            }
        },
    },
    mounted() {
        this.checkAuthentication(); // Initial check
        // Only set up the interval if ms_auth does not exist or auth_token is null
        const msAuth = localStorage.getItem('ms_auth');
        if (!msAuth) {
            // ms_auth does not exist, set interval
            setInterval(this.checkAuthentication, 1000); // Check every 1000 milliseconds (1 second)
        } else {
            // ms_auth exists, check if auth_token is not null
            const authObj = JSON.parse(msAuth);
            if (!authObj.auth_token) {
                // auth_token is null, set interval
                setInterval(this.checkAuthentication, 1000);
            }
        }
        this.getIntegrationOptions();
        this.getCompanyCredentials();

    },
    computed: {
    },
    beforeUnmount() {
        // need to add windown listener when close tab remove all auth_token
        localStorage.removeItem('ms_auth');
        localStorage.removeItem('ms_access_token');
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
