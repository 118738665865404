<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div
                class="modal-container"
                style="width: 80%; max-width: 1200px; height: 80%; display: flex; flex-direction: column;">
                <div class="modal-header">
                    <h3 class="form-header">Medication Information</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0;"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="med-details-row">
                        <img
                            :src="mimsImage ? `data:image/png;base64,${mimsImage}` : require('@/assets/icons/medIcon.png')"
                            alt="Medication Image"
                            class="scaled-image"
                        />
                        <h4 class="sub-header">{{ drug.name }}</h4>
                        <img
                            :src="require('@/assets/logos/MIMS_96dpi.png')"
                            alt="MIMS Logo"
                            class="scaled-image"
                            style="margin-left: auto;"
                        />
                    </div>
                    <slot name="body">
                        <div class="contents-container">
                            <!-- TABS TO SELECT CMI/PI -->
                            <div class="tabs-container">
                                <h3
                                    :class="selectedTab == 'cmi' ? 'current-tab' : 'non-current-tab'"
                                    @click="selectTab('cmi')">
                                    CMI
                                </h3>
                                <h3
                                    :class="selectedTab == 'pi' ? 'current-tab' : 'non-current-tab'"
                                    @click="selectTab('pi')">
                                    PI
                                </h3>
                            </div>
                            <div class="data-container">
                                <!-- CONTENT OF CMI -->
                                <div v-if="selectedTab == 'cmi'" style="width: 100%; height: 100%;">
                                    <!-- IF IT IS LOADING -->
                                    <i
                                        v-if="loading_cmi"
                                        class="pi pi-spin pi-spinner"
                                        style="font-size: 2rem !important; margin-top: 10px; ">
                                    </i>
                                    <!-- IF THERE IS AN ERROR -->
                                    <div v-else-if="cmi_error_message" style="width: 100%; overflow: scroll">
                                        <p style="margin: 0 auto; font-size: 1em; ">
                                            Error message: {{ cmi_error_message }}
                                        </p>
                                    </div>
                                    <!-- IF THERE IS DATA -->
                                    <!-- TABS FOR EACH CMI -->
                                    <TabView v-else-if="cmi_data.length">
                                        <TabPanel v-for="(cmi, index) in cmi_data" :key="cmi.cmi_name" :header="cmi.cmi_name">
                                            <div class="pdf-container">
                                                <vue-pdf-embed
                                                    :ref="'cmipdf-' + index"
                                                    :source="cmi.pdf ? `data:application/pdf;base64,${cmi.pdf}` : ''"
                                                    :width="800"
                                                    style="display: inline-block; padding-top: 10px;"
                                                />
                                                <div class="floating-button-div">
                                                    <button
                                                        v-tooltip="'Print'"
                                                        class="button clear-button"
                                                        @click="printCmi(index)"
                                                        style="margin-right: 1em; background-color: transparent; color: white"
                                                    >
                                                        <i
                                                            class="pi pi-print"
                                                            style="font-size: 1.1em !important; border-radius: 50%; padding: 8px; background-color: #0870DC; margin-top: -4px;"
                                                        >
                                                        </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                    <!-- IF THERE IS NO DATA -->
                                    <div v-else>
                                        <p style="margin: 0 auto; font-size: 1em; ">
                                            No consumer medication information found for this medication in MIMS
                                        </p>
                                    </div>
                                </div>
                                <!-- CONTENT OF PI -->
                                <div v-else-if="selectedTab == 'pi'" style="width: 100%; height: 100%;">
                                    <!-- IF IT IS LOADING -->
                                    <i
                                        v-if="loading_pi"
                                        class="pi pi-spin pi-spinner"
                                        style="font-size: 2rem !important; margin-top: 10px; ">
                                    </i>
                                    <!-- IF THERE IS AN ERROR -->
                                    <div v-else-if="pi_error_message" style="width: 100%; overflow: scroll">
                                        <p style="margin: 0 auto; font-size: 1em; ">
                                            Error message: {{ pi_error_message }}
                                        </p>
                                    </div>
                                    <!-- IF THERE IS DATA -->
                                    <div v-else-if="pi_data" id="pidocument" class="cmi-container">
                                        <div class="cmi-document" v-if="pi_data.content">
                                            <!-- PI NAME -->
                                            <div class="title-section" v-for="brand in pi_data.brands" :key="brand">
                                                <h1 v-if="brand.brandName" v-html="brand.brandName"></h1>
                                            </div>
                                            <!-- CONTENTS SECTION -->
                                            <div v-for="section in pi_data.content" :key="section">
                                                <!-- IF THE CONTENT IS A BOXED WARNING -->
                                                <div class="boxed-warnings" v-if="section.header && section.header.includes('Boxed Warnings') && section.text && section.text !== ''">
                                                    <!-- HEADING -->
                                                    <h2 class="heading">
                                                        Warnings
                                                    </h2>
                                                    <!-- BODY TEXT -->
                                                    <p class="body" v-if="section.text" v-html="section.text">
                                                    </p>
                                                    <!-- SUB CONTENT -->
                                                    <div v-for="subsection in section.subContent" :key="subsection">
                                                        <!-- HEADING TEXT -->
                                                        <h3 class="subheading" v-if="subsection.header" v-html="subsection.header">
                                                        </h3>
                                                        <!-- BODY TEXT -->
                                                        <p class="body" v-if="subsection.text" v-html="subsection.text">
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- IF THE CONTENT IS NOT A BOXED WARNING -->
                                                <div v-else-if="(section.text && section.text !== '') || (section.subContent && section.subContent != null)">
                                                    <!-- HEADING TEXT -->
                                                    <h2 class="heading" v-if="section.header" v-html="section.header">
                                                    </h2>
                                                    <!-- BODY TEXT -->
                                                    <p class="body" v-if="section.text" v-html="getText(section.text)">
                                                    </p>
                                                    <!-- SUB CONTENT -->
                                                    <div v-for="subsection in section.subContent" :key="subsection">
                                                        <!-- HEADING TEXT -->
                                                        <h3 class="subheading" v-if="subsection.header" v-html="subsection.header">
                                                        </h3>
                                                        <!-- BODY TEXT -->
                                                        <p class="body" v-if="subsection.text" v-html="getText(subsection.text)">
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="floating-button-div">
                                                <button
                                                    v-tooltip="'Print'"
                                                    class="button clear-button"
                                                    @click="printPi"
                                                    style="margin-right: 1em; background-color: transparent; color: white"
                                                >
                                                    <i
                                                        class="pi pi-print"
                                                        style="font-size: 1.1em !important; border-radius: 50%; padding: 8px; background-color: #0870DC; margin-top: -4px;"
                                                    >
                                                    </i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- IF THERE IS NO DATA -->
                                    <div v-else>
                                        <p style="margin: 0 auto; font-size: 1em; ">
                                            No product information found for this medication in MIMS
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    props: [
        'close',
        'drug',
        'off_market',
        'mimsImage'
    ],
    components: {
        VuePdfEmbed
    },
    data() {
        return {
            loading_cmi: false,
            cmi_error_message: '',
            cmi_data: [],
            loading_pi: false,
            pi_error_message: '',
            pi_data: '',
            selectedTab: 'cmi',
        }
    },
    methods: {
        cancelAction() {
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        closeModal() {
            this.$emit('close', true);
        },
        selectTab(tab) {
            this.selectedTab = tab;
        },
        getText(html) {
            // modify url's in the text and add img tags to them
            const regex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;
            var mod_html = html.replace(regex, '<img src="$&">');
            return mod_html;
        },
        printCmi(index) {
            var cmiName = this.drug ? this.drug.name + ' CMI' : 'Medication CMI';
            if (this.$refs['cmipdf-' + index] && this.$refs['cmipdf-' + index][0]) {
                this.$refs['cmipdf-' + index][0].print(undefined, cmiName, undefined);
            } else {
                console.log('Unable to grab cmipdf at', index);
            }
        },
        printPi() {
            // Set the document name and get print data
            var piName = this.drug ? this.drug.name + ' PI' : 'Medication PI';
            var contents = document.getElementById('pidocument').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Create the print iframe - position it off the screen
            var frame = document.createElement('iframe');
            frame.name = "printframe";
            frame.style.position = "absolute";
            frame.style.top = "-1000000px";
            document.body.appendChild(frame);

            // Write data to the frame document
            var frameDoc = frame.contentWindow
                ? frame.contentWindow
                : frame.contentDocument.document
                    ? frame.contentDocument.document
                    : frame.contentDocument;
            frameDoc.document.open();
            frameDoc.document.write(`
                <html>
                    <head>
                        ${stylesHtml}
                        <title>${piName}</title>
                    </head>
                    <body>
                        ${contents}
                    </body>
                </html>
            `);
            frameDoc.document.close();

            // Print the document and remove the frame
            setTimeout(function () {
                window.frames["printframe"].focus();
                window.frames["printframe"].print();
                document.body.removeChild(frame);
            }, 1000);

            return false;
        },
        async getMIMSCMI() {
            const apiKey = localStorage.getItem('mimsApiKey');
            // If a drug is selected and there isn't already cmi data
            if (this.drug.alias_id && this.off_market && !this.cmi_data.length && apiKey !== null) {
                this.cmi_error_message = '';
                this.loading_cmi = true;
                axios.post(`/mims/cmi_search`, { alias_id: this.drug.alias_id, off_market: this.off_market, api_key: apiKey }, this.$store.state.header)
                .then(res => {
                    this.cmi_data = res.data;
                    this.loading_cmi = false;
                }).catch(err => {
                    this.loading_cmi = false;
                    // If the token has expired
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    // If another error
                    else {
                        this.cmi_error_message = err.response.data.message;
                    }
                });
            }
        },
        async getMIMSPI() {
            const apiKey = localStorage.getItem('mimsApiKey');
            // If a drug is selected and there isn't already pi data
            if (this.drug.alias_id && this.off_market && !this.pi_data && apiKey !== null) {
                this.pi_error_message = '';
                this.loading_pi = true;
                axios.post(`/mims/pi_search`, { alias_id: this.drug.alias_id, off_market: this.off_market, monograph_type: 'full', api_key: apiKey }, this.$store.state.header)
                .then(res => {
                    this.pi_data = res.data;
                    this.loading_pi = false;
                }).catch(err => {
                    this.loading_pi = false;
                    // If the token has expired
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    // If another error
                    else {
                        this.pi_error_message = err.response.data.message;
                    }
                });
            }
        }
    },
    mounted() {
        this.getMIMSCMI();
        this.getMIMSPI();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
.modal-body {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.med-details-row {
    display: flex;
    border: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    justify-content: flex-start;
    gap: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.pdf-container {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    overflow-y: auto;
    position: relative;
}

.cmi-container {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    overflow-y: auto;
    padding-top: 10px;
    position: relative;
}

.cmi-document {
    display: inline-block;
    text-align: left;
    padding: 20pt;
    background-color: white;
    width: 800px;
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.title-section {
    border-bottom: 1px solid black;
}

.boxed-warnings {
    border: 1px solid black;
    padding: 5px;
}

.heading {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14pt;
}

.subheading {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
}

.body {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
}

hd2 {
    font-weight: bold;
    font-size: 11pt;
}

hd2:after {
    content:"\a\a";
    white-space: pre;
}

hd2:before {
    content:"\a";
    white-space: pre;
}

img {
    display: block;
}

.contents-container {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.floating-button-div {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.tabs-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: auto;
    h3 {
        cursor: pointer;
        font-size: 1em;
        margin: 0;
        background-color: #0870DC; //$strongblue;
        padding: 3px 20px;
        border-radius: 10px 10px 0 0;
        color: white;
    }
}

.current-tab {
    background-color: $strongblue !important;
    color: white !important;
}

.non-current-tab {
    background-color: $babyblue !important;
    color: $black !important;
}

.data-container {
    width: 100%;
    flex: 1;
    min-height: 0;
}

// Tabview styling
.p-tabview .p-tabview-nav {
    border: none !important;
    // background: #ccc !important;
}
.p-tabview > .p-tabview-panels {
    padding: 0px !important;
}
</style>