<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <h3 class="form-header">Medication/s Recently Changed</h3>
                    <button @click="cancelAction()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <div style="display: flex;margin: 20px;padding: 0px 15px 0px 15px;flex-direction: column;text-align: left;">
                            <h4 v-if="resident.personal_information.first_name">This profile {{ resident?.personal_information?.first_name }} {{ resident?.personal_information?.last_name }} has had a recent change to medications within the last 24 hours. Please check and proceed with administration carefully.</h4>
                            <h4 v-else>This profile has had a recent change to medications within the last 24 hours. Please check and proceed with administration carefully.</h4>
                            <div style="display: flex;margin-bottom: 10px;gap: 10%;">
                                <!-- chart -->
                                <table style="padding: 0 10px; border-collapse: collapse;">
                                <tr>
                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                        Medication
                                    </th>
                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                        Time Updated 
                                    </th>
                                </tr>
                                <tr  v-for="(chart, index) in recentlyChangedCharts" :key="index">
                                    <td style="padding: 0 10px;">
                                        {{chart.alias.name  }}
                                    </td>
                                    <td style="padding: 0 10px;">
                                        {{ hoursAgoFromDate(chart.created_at) == 0 ? "Less than an hour ago" :  hoursAgoFromDate(chart.created_at) + ' hour(s) ago'}} 
                                        <!-- {{ item.date ? item.date.split(',')[1] : '----' }} -->
                                    </td>
                                </tr>
                            </table>
                            </div>
                            <p style="font-size: 1em; color: red;">This list does not include new or deleted medication charts.</p>
                        </div>
                    </slot>
                </div>



                <div class="modal-footer" style="display: flex; justify-content:flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button class='regular-button complete-button' @click="cancelAction()">
                            Continue
                        </button>
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    props: ['resident','recentlyChangedCharts', 'notifiedChartAlerts'],
    methods: {
        cancelAction() {
            const newNotifiedChartAlerts = this.notifiedChartAlerts;
            localStorage.setItem('notifiedChartAlerts',  JSON.stringify(newNotifiedChartAlerts));
            this.$emit('close', true);
        },
        hoursAgoFromDate(inputDate) {
        const timeDifference = new Date() - new Date(inputDate);
        return Math.floor(timeDifference / (1000 * 60 * 60));
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



.p-inputgroup {
    display: grid;
    grid-template-columns: 50% 50%;
}





.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}



.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
