import firebase from "../firebase";
//import firebase from "firebase/app";
const db = firebase.collection("/forms");
class FirebaseService {
  getForm(id) {
    this.auth();
    return db.doc(id).get();
  }
  create(form, id) {
    console.log('THIS IS THE FIREBASE ID ARG', id);
    return db.doc(id).set(form);
    // return db.add(form);
  }
  update(value, id) {
    return db.doc(id).update(value);
  }
  delete(id) {
    return db.doc(id).delete();
  }

  auth() {
    if (firebase.auth().currentUser !== null) 
        console.log("user id: " + firebase.auth().currentUser.uid);
    else {
      console.log("There is NO USER");
    }
  }
}
export default new FirebaseService();