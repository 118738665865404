

<template>
    <div>
        <div>
            <h3 style="text-align: left;margin: 0; margin-top: 2em;">Enter administration round times</h3>
            <p style="text-align: left; font-size: 1em; ">The times for each window will be available during administration.</p>
            <div style="display: flex; flex-direction: column; min-width: 50em; width: 53vh;">
                <p style="font-size: 1em; text-align: left; margin: 0; margin-top: 1em; margin-bottom: 10px; font-weight: 500">Select Facility:</p>
                <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                @change="retrieveSelectedFacilityRoundTimes()"
                :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" style="width: 20em; text-align: left" />
            </div>
        </div>
        <div style="margin-top: 2em;" v-if="selectedFacility">
            <h3 style="text-align: left">Administration Round Windows (2400 time format)</h3>
            <div v-for="(item, index) in round_times" :key="index" style="display: flex; align-items: center;"> 
                <p style="font-size: 1em; font-weight: bold; margin-right: 20px; width: 16em; text-align: left">
                    {{item.display_name}}{{item.start_time && item.start_time.length == 4 ? ` - ${item.start_time.slice(0,2)}:${item.start_time.slice(2,4)} -`:''}}{{item.stop_time && item.stop_time.length == 4 ? ` ${item.stop_time.slice(0,2)}:${item.stop_time.slice(2,4)}`:''}} : 
                </p>
                <inputText
                    placeholder="Start time e.g. 0800"
                    @input="checkTime(item, 'start_time')"
                    :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="item.start_time"
                    style="width: 12em; border: 1px solid #cdcdcd; background-color: #f3f4f4; margin-right: 20px"
                />
                <inputText
                    placeholder="Stop time e.g. 1159"
                    @input="checkTime(item, 'stop_time')"
                    :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="item.stop_time"
                    style="width: 12em; border: 1px solid #cdcdcd; background-color: #f3f4f4; margin-right: 20px"
                />
            </div>
        </div>
        <p v-if="errorMessage" style="font-size: 1em; font-weight: bold; margin-top: 3em; color: red">
            {{errorMessage}}
        </p>
        <p  class="green-button" style="text-align: left; width: fit-content;margin-top: 10px; padding: 5px 25px; font-size: 1em;" @click="saveTemplate()" v-if="$store.state.user.role.permissions.includes('can_do_admin_company') && selectedFacility">
            Save
        </p>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['facilities'],
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            loadingConfig: false,
            selectedFacility:'',
            selectedFacilityRounds:[],
            round_times: [
                // {
                // name: 'Current - (-1hr) - (+3hrs)',
                // start_time:'',
                // stop_time:''
                // },
                {
                display_name: 'Overnight',
                start_time:'',
                stop_time:''
                
                },
                {
                display_name: 'Morning',
                start_time:'',
                stop_time:''
                },
                {
                display_name: 'Noon',
                start_time:'',
                stop_time:''
                },
                {
                display_name: 'Early Afternoon',
                start_time:'',
                stop_time:''
                },
                {
                display_name: 'Late Afternoon',
                start_time:'',
                stop_time:''
                },
                {
                display_name: 'Bedtime',
                start_time:'',
                stop_time:''
                },
                {
                display_name: 'Night',
                start_time:'',
                stop_time:''
                },
            ]
        }
        
    },
    methods: {
        checkTime(round, stop_or_start){
            this.$store.dispatch('getRefreshToken');
            console.log('checking time');
            if(round[stop_or_start].length > 4 || parseInt(round[stop_or_start].slice(0,2)) > 24 || !(parseInt(round[stop_or_start]) >=0) ){
                round[stop_or_start] = ''
            } else if(stop_or_start == 'stop_time'){
                if(round[stop_or_start].length == 4 && round[stop_or_start].slice(2,4) == '00'){
                    console.log('checking stop time in if condition', parseInt(round[stop_or_start].slice(0,2))-1 < 10);
                    round[stop_or_start] = `${ parseInt(round[stop_or_start].slice(0,2))-1 < 0 ? '23' : parseInt(round[stop_or_start].slice(0,2))-1 < 10 ? '0'+(parseInt(round[stop_or_start].slice(0,2))-1).toString() : parseInt(round[stop_or_start].slice(0,2))-1}59`
                } else if(round[stop_or_start].length == 4 && round[stop_or_start].slice(2,4) == '30'){
                    console.log('checking stop time in if condition');
                    round[stop_or_start] = `${parseInt(round[stop_or_start].slice(0,2))-1 < 10 ? '0'+parseInt(round[stop_or_start].slice(0,2)) : parseInt(round[stop_or_start].slice(0,2))}29`
                }
            }
            
        },
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        saveTemplate(){
            this.$store.dispatch('getRefreshToken');
            this.round_times.forEach(round=>{
                round.name = `${round.display_name} - ${round.start_time} - ${round.stop_time}`;
            })

            let payload = {
                facility_id: this.selectedFacility.value,
                round_times: this.round_times
            }
            axios.post('/add-round-times', payload, this.$store.state.header).then(res=>{
                console.log('saved successfully', res.data);
                this.displaySuccessModal = true;
                this.successMessage = 'Successfully saved frequency template';

            }).catch(err=>{
                this.displaySuccessModal = true;
                this.successMessage = err.response.data;
            })
        },
        retrieveSelectedFacilityRoundTimes(){
            this.$store.dispatch('getRefreshToken');
            axios.post('/get-round-times', {facility_id: this.selectedFacility.value}, this.$store.state.header).then(res=>{
                
                if(res.data && res.data.length){
                    this.round_times.forEach(templateRound=>{
                        res.data.forEach(round=>{
                            if(round.name.includes(templateRound.display_name)){
                                templateRound.start_time = round.start_time;
                                templateRound.stop_time = round.stop_time;
                            }
                        })
                    })
                }
            }).catch(err=>{
                console.log('get-round-times error', err.response.data);
            })
        }
        
    },
    mounted() {
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
