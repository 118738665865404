<template>
  <div class="navbar" v-if="$store.state.user">
    <div style="width: fit-content; display: flex;">
      <router-link :to="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' ? '/drug-register' : '/' "
      >
      <img src="@/assets/logos/short_logo.png" style="height: 40px; margin-right: 1em;" alt="logo">
      </router-link>
      
    <PatientSearch v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.company.type != 'warehouse'" />
    </div>
    <Menu />
    <NavFunctions />
  </div>
</template>

<script>
import Menu from '@/components/navigation/Menu.vue'
import PatientSearch from '@/components/navigation/PatientSearch.vue'
import NavFunctions from '@/components/navigation/NavFunctions.vue'

export default {
  props:["page"],
  name: 'Navbar',
  components:{
    Menu,
    PatientSearch,
    NavFunctions
  },
  methods:{
  },
  mounted(){
    if(localStorage.getItem('currentLocation')){
      console.log('this is the uuid', JSON.parse(localStorage.getItem('currentLocation')).uuid);
      this.$store.commit('setHeaderLocation', JSON.parse(localStorage.getItem('currentLocation')).uuid);
      this.$store.commit('setAuthorization');
    }
    if(window.location.host.split('.')[0] == 'staging'){
      this.$store.commit('setSubdomain', 'staging')
    } else if(window.location.host.split('.')[0] == 'care'){
      this.$store.commit('setSubdomain', 'prod')
    } else{
      this.$store.commit('setSubdomain', 'local')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar{
  display:flex;
  justify-content: space-between;
  align-items: top;
  height: 80px;
  padding: 40px;
  padding-bottom: 74px;
}
</style>
