<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 85%;">
                <div class="modal-header">
                    <h3 class="form-header">IHI Number Conflict(s) Found </h3>
                    <button @click="cancelAction()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div>
                                    <p style="font-size: 1em;">IHI Number has been found in <span style="font-weight: bold; color: red">{{conflicts.length}}</span> other resident/s.</p>
                                    <p style="font-size: 1em;">Please re-validate the IHI Number of any resident/s that do not match any other details of the current resident. You may edit their information before re-validating.</p>
                                    <p style="font-size: 1em;">Select Merge for a resident that needs to be merged with the current resident.</p>
                                    <table class="data-table" cellspacing="0" style="max-height: 50vh; overflow: scroll">
                                        <tr class="table-headers">
                                            <th style="width: 80px">
                                                <h3 >First Name</h3>
                                            </th>
                                            <th style="width: 80px">
                                                <h3 >Last Name</h3>
                                            </th>
                                            <th style="width: 80px">
                                                <h3 >Preferred Name</h3>
                                            </th>
                                            <th style="width: 80px">
                                                <h3 >DOB</h3>
                                            </th>
                                            <th style="width: 100px">
                                                 <h3 >Sex</h3>
                                            </th>
                                            <th style="width: 140px">
                                                <h3 >IHI Number</h3>
                                            </th>
                                            <!-- <th style="width: 140px">
                                                <h3 >DVA Number</h3>
                                            </th> -->
                                            <th style="width: 100px">
                                            </th>
                                            <th style="width: 80px">
                                            </th>
                                        </tr>
                                        <tbody class="table-data"  v-for="(item, index) in conflicts" :key="index">
                                            <tr >
                                                <td><inputText style="width: 120px" class="grey-input-color" type="text" name="first_name" :value="item.person.personal_information.first_name" /></td>
                                                <td><inputText style="width: 120px" class="grey-input-color" type="text" name="first_name" :value="item.person.personal_information.last_name" /></td>
                                                <td><inputText style="width: 120px" class="grey-input-color" type="text" name="first_name" :value="item.person.personal_information.preferred_name" /></td>
                                                <td><inputText style="width: 120px" class="grey-input-color" type="text" name="first_name" :value="item.person.personal_information.dob" /></td>
                                                <td><Dropdown style="width: 120px" class="grey-input-color" type="text" optionLabel="label" optionValue="value" name="first_name" :options="sex" v-model="item.person.personal_information.sex" /></td>
                                                <td><inputText style="width: 200px" class="grey-input-color" type="number" name="first_name" :value="item.number" /></td>
                                                <!-- <td><inputText style="width: 200px" class="grey-input-color" type="number" name="first_name" :value="item.number" /></td> -->
                                                <td><Button class="blue-button" label="Re-validate" @click="validateIHI(item)" /></td>
                                                <td><Button class="blue-button" @click="mergePatient(item)" label="Merge" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>
<!-- 
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;"
                    v-if="!loading">
                    <slot name="footer" style="display: flex;">
                        <button class="blue-button" v-if="action && actionType == 'sign'" @click="signAction()">
                            Sign
                        </button>
                    </slot>
                </div>
                <div class="modal-footer" style="display: flex; justify-content:center; padding: 5px 20px;"
                    v-if="loadingDelay">
                    <slot name="footer" style="display: flex;">
                        <button class="grey-button" @click="cancelAction()">
                            Cancel Process
                        </button>
                    </slot>
                </div> -->
            </div>

        </div>
    </div>
    <MergeConfirmationModal v-if="displayMergeConfirmationModal" :personalInformation="personalInformation" :mergeSummary="selectedPatient" @close="closeMergeConfirmationModal" />
</template>

<script>
import MergeConfirmationModal from '@/components/modals/MergeConfirmationModal.vue'
import axios from 'axios'

export default {
    props: [ 'conflictPatients', 'personalInformation'],
    emits:['close'],
    components: {
        MergeConfirmationModal
    },
    data() {
        return {
            displayMergeConfirmationModal: false,
            sex:[
                {label:'Female', value: 'female'},
                {label:'Male', value: 'male'},
                {label:'Intersex', value: 'intersex'},
            ],
            conflicts:[],
            selectedPatient:'',
        }
    },
    methods: {
        validateIHI(patient){
            console.log('validating this patient', patient);
            axios.post(`/person/${patient.person.uuid}/edit`, patient.person.personal_information, this.$store.state.header).then(res=>{
                console.log('editing patient basic info before validating', res.data);
                // axios.post('/')
            })
        },
        mergePatient(patient){
            this.selectedPatient = patient;
            this.displayMergeConfirmationModal = true
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        closeMergeConfirmationModal(value) {
            if (value) {
                this.displayMergeConfirmationModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.displayMergeConfirmationModal = false;
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
        this.conflictPatients.forEach(conflict=>{
            if(conflict.person.personal_information == null){
                conflict.person.personal_information = {
                    first_name: '',
                    last_name: '',
                    dob:'',
                    sex:'',
                    preferred_name:''
                };
                this.conflicts.push(conflict);
            } else{
                this.conflicts.push(conflict);
            }
        })
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
