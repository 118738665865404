<template>
    <div style="border-bottom: 1px solid #a4a4a4;padding-bottom: 10px;padding-bottom: 14px;">
        <div style="display: inline-block;display:flex ;justify-content: start; padding-left: 10px;">
            <h4 style="margin-bottom: 0px;margin-top: 20px;">{{
                arrayOfAlertKind.AlertType }}</h4>
            <span style="margin-top: 20px;" class="alert-count">
                <p style="display: inline-block;">{{ total }}</p>
            </span>
        </div>
        <!-- each cluster (for missed admin it's person) -->
        <div style="padding: 10px;" v-if="alerts && alerts.length">
            <div style="margin-top: 0px; margin-bottom: 6px;padding-left: 10px;" v-for="(item, index) in alerts" :key="item.uuid+index">
                <h5
                    style="font-weight: normal;text-align: left;font-size: 15px;margin: 0;">
                    <!-- Patient name and message -->
                    <b> {{  item.patientInvolved  }} </b> {{ messageBuilder(item) }}
                    <a style="font-style: italic;color: #0870DC; cursor: pointer" 
                        @click="displayCluster(item)" :id="item.uuid">{{ item.open ? 'Hide' : 'See' }} All</a>
                </h5>
                
                <div v-if="item.open" style="background-color: #f3f3f3; padding: 10px 5px; display: grid; grid-gap: 10px;">
                    <div v-for="singleAlert in item.alerts" :key="singleAlert.id">
                        <p style="font-size: 0.9em; margin: 0; text-align: left"> {{ singleAlert.body.message.split('||')[0][0].toUpperCase()+singleAlert.body.message.split('||')[0].slice(1) }} was <b>missed at {{
                                singleAlert.body.message.split('||')[3] }}</b></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="card flex justify-content-center">
            <Sidebar style="width: 40%;" v-model:visible="visible" position="right">
                <h2>{{ selectedCluster.patientInvolved }}'s Missed Adminstrations</h2>
                <table>
                    <tr v-for="singlalert in selectedCluster.alerts" :key="singlalert.message">
                        <div
                            style="display:grid ;justify-content: start;align-items: center; height: 40px;grid-template-columns: 95% 5%;">
                            <p> {{ singlalert.body.message.split('||')[0] }} was <b>missed at {{
                                singlalert.body.message.split('||')[3] }}</b></p>
                        </div>
                    </tr>
                </table>
            </Sidebar>
        </div> -->

    </div>
</template>

<script>
import { computed, toRaw } from 'vue';

export default {
    props: ['arrayOfAlertKind', 'total'],
    data() {
        return {
            opened: false,
            visible: false,
            selectedCluster: {},
            alerts:null,
        }
    },
    methods: {
        displayCluster(item){
            if(item.open){
                item.open = false;
            } else{
                item.open = true;
            }
            
            console.log('opening or closing content', item.open);
            // if(item.open){
            //     item.open = false;
            // } else{
            //     item.open = true;
            // }
        },
        numberOfClusteredAlerts(clust) {
            return Object.keys(clust.alerts).length
        },
        // firstSingleClusteredAlert(singlecluster) {
        //     console.log("CLUSTERRRRR")
        //     const firstValue = Object.values(singlecluster.alerts)[0];
        //     console.log(firstValue)
        //     return firstValue;
        // },
        // handlesidebar(event) {
        //     this.visible = true;
        //     const buttonclusterkey = event.currentTarget.id
        //     this.selectedCluster = this.arrayOfAlertKind.alerts[buttonclusterkey]
        // },
        // dismissAlert(id, index) {
        //     console.log(id)
        //     this.$store.dispatch('readAlert', { 'id': id, 'index': index });
        // },
        messageBuilder(singlecluster) {
            let message = ""
            let alertCount = this.numberOfClusteredAlerts(singlecluster)
            message = "missed " + this.numberOfClusteredAlerts(singlecluster) + ` administration${this.numberOfClusteredAlerts(singlecluster) > 1 ? 's':''} for > 2hrs`
            return message
        },
        displayViewAll(singlecluster) {
            return Object.keys(singlecluster.alerts).length != 1
        }
    },
    mounted(){
        console.log('mounting', this.arrayOfAlertKind);
        this.alerts = this.computedClusters;
        
    },  
    computed:{
        computedClusters(){
            let b = {...this.arrayOfAlertKind};
            b.arrAlerts=[]
            for(let key in b.alerts){
                b.arrAlerts.push(b.alerts[key])
            }
            return b.arrAlerts;
        },
        computedTotal(){
            let total = 0;
            for(let key in this.arrayOfAlertKind.alerts){
                for (let k in this.arrayOfAlertKind.alerts[key]) if (Object.prototype.hasOwnProperty.call(this.arrayOfAlertKind.alerts[key], k)) ++total;
            }
            return total;
        }
    }
}




</script>

<style lang="scss" scoped>
.alert-count {
    color: white;
    background-color: #E04F39;
    border-radius: 8px;
    font-size: 13px;
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;

    p {
        margin: 0;
    }
}




.alert-items {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.alert-item-content {
    text-align: left;
}

.small-red-close-button {
    background-color: #E04F39;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;

    .pi {
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}

.spacer {
    flex-grow: 1;
}
</style>



