<template>
    <div>
        <div v-if="loadingConfig">
            <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
        </div>
        <div v-if="configArray.length">
            <h4 style="text-align: left;margin: 0; margin-top: 2em;">Choose configuration settings</h4>
            <p style="text-align: left; font-size: 1em; ">Be mindful that additional settings may add difficulty to users being able to remember their password</p>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 53vh;">
                <div class="p-field-checkbox" v-for="(item,index) in configArray" :key="index">
                    <Checkbox :id="item.name + index" :binary="true" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')" v-model="item.value" @click="displayArray()" />
                    <label style="padding-left: 10px; width: fit-content" :for="item.value + index">{{ item.name }}</label>
                </div>
            </div>
        </div>
        <div  v-if="relevantPasswordConfigOptions.length">
            <h4 style="text-align: left;margin: 0; margin-top: 2em;">Password configurable parameters</h4>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
                <div class="p-field-checkbox" v-for="(item,index) in relevantPasswordConfigOptions" :key="index">
                    <label :for="item.name + index">{{ item.name }}:</label>
                    <InputText type="number" :id="item.name + index" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')" v-model="item.value" style="background-color: #f8f8f8"/>
                </div>
            </div>
        </div>
        <p v-if="errorMessage" style="font-size: 1em; font-weight: bold; margin-top: 3em; color: red">
            {{errorMessage}}
        </p>
        <p class="green-button" style="width: fit-content; margin: 0 auto; padding: 5px 25px; font-size: 1em;" @click="savePasswordConfig()" v-if="this.configValuedOptions.length && $store.state.user.role.permissions.includes('can_do_admin_company')">
            Save Settings
        </p>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            loadingConfig: false,
            prePayloadArray:{},
            configArray:[
                {name: "use_pwd_refresh_interval",
                value: false},
                {name: "use_pwd_reuse_interval",
                value: false},
                {name: "at_least_1_num_pwd",
                value: false},
                {name: "at_least_1_special_char_pwd",
                value: false},
                {name: "use_passphrase",
                value: false},
                {name: "no_dictionary_words_pwd",
                value: false},
                {name: "breach_pwd_check",
                value: false},
                {name: "no_context_words_pwd",
                value: false},
                {name: "no_context_derivative_words_pwd",
                value: false},
                {name: "no_repetitive_chars_pwd",
                value: false},
                {name: "pwd_has_letters",
                value: false},
                {name: "pwd_is_mixed_case",
                value: false}
            ],
            configValuedOptions:[
                {name: "min_pwd_length",
                value: 8,},
                {name: "pwd_retry_limit",
                value: 5,},
                {name: "pwd_refresh_interval",
                value: 90,},
                {name: "pwd_reuse_interval",
                value: 180,}
            ],
            configOptions:{
                // "stocktake_hours": 4380,
                // "stocktake_hours_s8": 8,
                // "company_mfa": 0,
                // "min_pwd_length": 8,
                // "pwd_retry_limit": 5,
                // "use_pwd_refresh_interval": true,
                // "pwd_refresh_interval": 90,
                // "use_pwd_reuse_interval": true,
                // "pwd_reuse_interval": 180,
                // "at_least_1_num_pwd": true,
                // "at_least_1_special_char_pwd": true,
                // "use_passphrase": true,
                // "no_dictionary_words_pwd": true,
                // "breach_pwd_check": true,
                // "no_context_words_pwd": true,
                // "no_context_derivative_words_pwd": true,
                // "no_repetitive_chars_pwd": true,
                // "pwd_has_letters": true,
                // "pwd_is_mixed_case"   : true
            }
        }
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        retrieveConfigOptions(){
            this.loadingConfig = true;
            this.errorMessage = '';
            axios.post('/get-company-settings',{}, this.$store.state.header).then(res=>{
                this.loadingConfig = false;
                this.configOptions = res.data;
                this.configArray = [];
                this.configValuedOptions = [];
                console.log('received settings data', res.data, this.configOptions);
                for(const key in this.configOptions){
                    console.log('looping through settings data', res.data);
                    if(typeof this.configOptions[key] === 'boolean'){
                        console.log('THIS IS A BOOLEAN TYPE', key);
                        this.configArray.push({
                            name: key,
                            label: key.split('_').join(' '),
                            value: this.configOptions[key]
                        })
                    } else{
                        console.log('this is not a boolean', key);
                        this.configValuedOptions.push({
                            name: key,
                            value: this.configOptions[key]
                        })
                    }
                }
            }).catch(err=>{
                this.loadingConfig = false;
                this.errorMessage = err.response.data;
                console.log('this is the err', err, err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        displayArray(){
            console.log('this is the password config', this.prePayloadArray);
        },
        savePasswordConfig(){
            this.displaySuccessModal = true;
            this.successMessage = 'Updating password settings...'
            this.configValuedOptions.forEach(item=>{
                item.value = parseInt(item.value);
            })
            let concatedConfig = this.configValuedOptions.concat(this.configArray);
            concatedConfig.forEach(item=>{
                this.prePayloadArray[item.name] = item.value;
            })
            
            console.log(this.prePayloadArray);
            axios.post('/password-settings', this.prePayloadArray, this.$store.state.header).then(res=>{
                console.log('this is the password settings saved response', res.data);
                this.retrieveConfigOptions();
                this.successMessage = 'Password settings have been successfully updated!';
            }).catch(err=>{
                console.log('this is the err', err);
                this.successMessage = err;
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        
    },
    mounted() {
        this.retrieveConfigOptions();
    },
    computed:{
        relevantPasswordConfigOptions(){
            return this.configValuedOptions.filter(option=> option.name != 'stocktake_hours_s8')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
