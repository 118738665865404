<template>
  <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.reporting_active'>
    <h3
      style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
      You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
  </div>
  <div v-else class="drug-register-table table-container" style="width: 95.5vw; height: 83vh; border-top-right-radius: 0;"
    :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
    <div class="filters" style="align-items: flex-end">
      <div>
        <p style="font-size: 1em; text-align: left; margin: 0; padding-left: 15px;">Select a report type</p>
        <div>
          <button
            @click="() => { form.filters.antimicrobial = !form.filters.antimicrobial; if (form.filters.antimicrobial) { form.filters.psychotropics = false }; this.selectedQuarter = null; this.selectedYear = null; getQUMReport() }"
            v-bind:class="[form.filters.antimicrobial == true ? 'blue-button' : 'outline-button']" class="regular-button"
            style="margin-right: 10px; margin-top: 0; padding: 0 30px;">Antimicrobial</button>

          <button
            @click="() => { form.filters.psychotropics = !form.filters.psychotropics; if (form.filters.psychotropics) { form.filters.antimicrobial = false }; this.selectedQuarter = null; this.selectedYear = null; getQUMReport() }"
            v-bind:class="[form.filters.psychotropics == true ? 'blue-button' : 'outline-button']" class="regular-button"
            style="margin-top: 0;     padding: 0 30px;">Psychotropics</button>
        </div>

      </div>
      <div class="date-search-container search-container">
        <p style="text-align: left; margin: 0; font-size: 1em; padding-left: 15px">Select a quarter</p>
        <Dropdown :options="quarters" optionValue="value" optionLabel="label" v-model="selectedQuarter"
          @change="getQUMReport()" class="qi-input" placeholder="Select a Quarter" />
      </div>
      <div class="date-search-container search-container">
        <p style="text-align: left; margin: 0; font-size: 1em; padding-left: 15px">Select a year</p>
        <Calendar v-model="selectedYear" class="grey-calendar" view="year" dateFormat="yy" :maxDate="maxDate"
          placeholder="Select a year" @date-select="getQUMReport()" />
      </div>
    </div>
    
        <div class="report-container">
          <div class="left-report-column" style="width: 50%; border: 1px solid #BDC4C4; border-radius: 6px; height: 100%; overflow: scroll" v-if="(form.filters.antimicrobial || form.filters.psychotropics) && selectedYear && selectedQuarter">
              
              <table v-if='logs?.length && !loadingLog' class="data-table qi-data-report-table qi-report-table" style="height: fit-content; border: none; padding: 0;" cellspacing="0" >
                <tr>
                  <th style="width: 5em"><span @click="expandAll()">
                    <div style="width: 3em">
                      <div v-tooltip.right="expandedLogs ? 'Close All' : 'Open All'">
                        <i v-if="expandedLogs" class="pi pi-folder" ></i>
                        <i v-else class="pi pi-folder-open" style="font-weight: bold"></i>
                      </div>
                    </div>
                    
                    
                  </span></th>
                  <th style="width: 4em">Chart</th>
                  <th style="width: 9em">Resident</th>
                  <th style="width: 7em">Prescriber</th>
                  <th>{{ form.filters.antimicrobial ? 'Antimicrobial' : 'Psychotropics' }} Prescribed</th>
                  <th style="width: 7em">Start Date</th>
                  <th style="width: 7em">Stop Date</th>
                </tr>
                <tbody  v-for="(item, index) in logs" :key="index" class="table-data" style="max-height: 400px; overflow: scroll">
                  <tr style="height: 40px" :class="[ (item.expandedInfo && (item.expandedInfo == true)) || expandedLogs ? 'open-transfer-row': '']" @click="item.expandedInfo = !item.expandedInfo ">
                    <td>
                      <i v-if="(item.expandedInfo && (item.expandedInfo == true)) || expandedLogs" class="pi pi-angle-down"></i>
                      <i v-else class="pi pi-angle-right"></i>
                    </td>
                    <td>
                        <div v-tooltip.right="item.chart_name" style="height: 20px; width: 20px; border-radius: 3px;"
                            :style="{ backgroundColor: item.color + ' !important' }"></div>
                    </td>
                    <td>{{ item.patient.personal_information.first_name }} {{ item.patient.personal_information.last_name }}</td>
                    <td>{{ item.prescriber ? item.prescriber?.personal_information?.first_name.slice(0,1) + ". " + item.prescriber?.personal_information?.last_name : "N/A"}} </td>
                    
                    <td>{{ item.alias.name }}</td>
                    <td style="width: 8em">{{ item.stadate }}</td>
                    <td style="width: 8em">{{ item.stodate }}</td>
                  </tr>
                  <!-- expanded view -->
                  <tr v-if="item.expandedInfo || expandedLogs" >
                    <td colspan="7" >
                      <div>
                       <div>
                        <p style="margin: 0; font-weight: bold; font-size: 1em;">Indication: <span style="font-weight: normal">{{ item.indication ? item.indication : 'N/A' }}</span></p>
                       </div>
                      <div v-if="item.prescription_question_answers.length == 0">
                        <div style="display: flex;flex: 1; justify-content: center;">
                          <p style="font-weight: 500;">No question data for this chart</p>
                        </div>
                      </div>
                      <div v-else>
                        <div style="display: grid; grid-template-columns: 75% 15%;margin-bottom: 10px;gap: 10%;">
                          <p style="font-weight: bold">QUESTION</p>       
                          <p style="font-weight: bold; padding-left: 10px;">RESPONSE</p>
                          </div>
                        <div v-for="(question, index) in item.prescription_question_answers" :key="index"
                             style="display: grid; grid-template-columns: 75% 15%;margin-bottom: 10px;gap: 10%;">
                             <!-- question -->
                             <div style="display: flex; align-items: start; gap: 10px;">
                                    <label style="font-weight: bold;text-align: left;">{{ index + 1 }}. </label>
                                    <label style="text-align: left;">{{ question.question }}</label>
                                </div>
                                <!-- answer -->
                                
                                  <div class="p-inputgroup">
                                    <Button 
                                        :disabled="true"
                                        style="margin-left: 10px; height: 35px;border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                                        label='No'
                                        :class="question.answer == 'No' || question.answer == '0' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />

                                    <Button 
                                        style="border-left: none;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;height: 35px;"
                                        label="Yes"
                                        :disabled="true"
                                        :class="question.answer == 'Yes' || question.answer == '1' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />
                                </div>


                        </div>

                      </div>
                            

                      </div>

                    </td>

            
                </tr> 
                </tbody>
                
              </table>
              <h4 v-if="logs && !logs.length && !loadingLog">No residents found.</h4>
              <div v-if="loadingLog">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
              </div>

              <!-- the pagination for QUM log -->
              <div v-if="!hide_pagination" class="pagination-links">
                <p class="pagination-arrow" v-show="current_page > 1" @click="nextPage(start_page - 1)"> &larr; </p>
                <p>Page {{ current_page }} of {{ page_count }}</p>
                <p class="pagination-arrow" v-show="current_page < page_count" @click="nextPage(current_page + 1)"> &rarr; </p>
            </div>

          </div>
          <div class="right-report-column" style="max-height: 68.5vh; overflow-y: scroll; border-radius: 8px; display: flex; flex-direction: column; justify-content: space-between" v-if="(form.filters.antimicrobial || form.filters.psychotropics) && selectedYear && selectedQuarter">
            
           
            <!-- antimicrobial/Psychotropics stats -->
            <!-- v-if="form.filters.antimicrobial" -->
            <div class="qum-facility-section">
              <div class="qum-facility-container" v-if="quarterData.current_facility && !loadingTotals">
                <div class="qum-facility-header">
                  <div>
                    <h4 style="color: #0870DC; font-weight: bold; margin: 0; text-align: left">Current & All Facilities</h4>
                    <p>Active {{ form.filters.antimicrobial ? 'Antimicrobial' : 'Psychotropics' }}</p>
                  </div>
                  <div>
                    <div style="display:flex; justify-content: space-between; padding-bottom: 5px">
                      <p>Current Facility </p>
                      <div style="margin-left:10px; min-width:40px; height: 22px; border: 2px solid hsl(204, 73%, 73%); border-radius: 5px; background-color: hsl(214deg, 92%, 95%)"></div>
                    </div>
                    <div style="display:flex; justify-content: space-between;">
                      <p>All Facilities </p>
                      <div style="margin-left:10px; min-width:40px; height: 22px; border: 2px solid hsl(269, 55%, 42%); border-radius: 5px;background-color: hsl(323deg, 53%, 93%)"></div>
                  
                </div>
              </div>
            </div>
            <div class="qum-facility-comparator">
              <div class="qum-facility-comparator-content-current">
                <div class="qum-top-data-current">
                  <h2 style="margin: 0;">{{ quarterData.current_facility.current_quarter }}</h2>
                  <p>This Quarter</p>
                </div>
                <div class="qum-bottom-data">
                  <h2 style="margin: 0;">{{ quarterData.current_facility.previous_quarter }}</h2>
                  <p>Previous Quarter</p>
                </div>
              </div>
              <div class="qum-facility-comparator-content-all">
                <div class="qum-top-data-all">
                  <h2 style="margin: 0;">{{ quarterData.all_facilities.current_quarter }}</h2>
                  <p>This Quarter</p>
                </div>
                <div class="qum-bottom-data">
                  <h2 style="margin: 0;">{{ quarterData.all_facilities.previous_quarter }}</h2>
                  <p>Previous Quarter</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loadingTotals">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
          </div>
          <!-- Question stats -->
          <div class="qum-facility-container" v-if="!loadingTotals && !form.filters.psychotropics">
            <div class="qum-facility-header">
              <div style="width: 100%">
                <div style="display: flex; justify-content: space-between">
                      <h4 style="color: #0870DC; font-weight: bold; margin: 0; text-align: left">Questionaire Results</h4>
                      
                    </div>
                <div v-if="QUMSelectedFacilityQuestion != null" style="display:flex">
                  <Dropdown style="width:90%" 
                  v-model="QUMSelectedFacilityQuestion" :options="QUMQuestionaireResults.facility_data"
                  optionLabel="short_question" />
                </div>
              </div>
                
              <div>
              </div>
            </div>

            <div class="qum-facility-comparator-questionnaire" v-if="QUMQuestionaireResults != []" >
                        <!-- CURRENT --> 
                        <div v-if="this.QUMQuestionaireResults.facility_data.length > 0" class="qum-question-box">
                          <h4>Current Facility</h4>
                          <h2 style="color: #0870DC">{{ getFacilityAffirmative(QUMSelectedFacilityQuestion) }}</h2>
                          <p> Answered <b>Yes</b></p>
                          <h4 style="color: #0870DC">{{calculateFacilityPercentage}}%</h4>
                          <ProgressBar :value="calculateFacilityPercentage" :showValue="false" style="height: 10px;"></ProgressBar>
                          <p style="padding-top: 5px;"> Total <b>Yes</b></p>
                      </div>
                      <div v-else class="qum-question-box">
                        <h4>No facility questionnaire data to show for this period.</h4>
                      </div>
                      <!-- ALL -->
                      <div v-if="this.QUMQuestionaireResults.organisation_data.length > 0" class="qum-question-box">
                        <h4 >All Facilities</h4>
                        <h2 style="color: hsl(269, 55%, 42%)">{{ getOrganisationAffirmative(QUMSelectedFacilityQuestion) }}</h2>
                        <p> Answered <b>Yes</b></p>
                        <h4 style="color: #0870DC">{{calculateAllOrganisationPercentage}}%</h4>
                        <ProgressBar :value="calculateAllOrganisationPercentage" :showValue="false" style="height: 10px;color: hsl(269, 55%, 42%)"></ProgressBar>
                        <p style="padding-top: 5px;"> Total <b>Yes</b></p>
                      </div>
                      <div v-else class="qum-question-box">
                        <h4>No organisation questionnaire data to show for this period.</h4>
                      </div>
            </div>
            <div v-else>
              <p> No questionnaire data. </p>
            </div>
          </div>
          <div v-if="loadingTotals">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
          </div>
        </div>
        <div :style="loadingGraph ? { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } : {}">
          <QUMGraph :graphData="finalised_data" :y_axis_label="y_axis_label" v-if="!loadingGraph"
            :graph_title="graph_title" />
          <div v-if="loadingGraph">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
          </div>
          <!-- <DemoGraphs /> -->
        </div>

      </div>


    </div>

  </div>
</template>


<script>
// @ is an alias to /src

import axios from 'axios';
import QUMGraph from '../../components/graphs/QUMGraph.vue';
import PrescriptionChart from '../PrescriptionChart.vue';
// import DemoGraphs from '../../components/graphs/DemoGraphs.vue';

export default {
  components: {
    QUMGraph,
    // DemoGraphs,
  },
  data() {
    return {
      expandedLogs: true,
      statuses: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        }
      ],
      quarters: [
        {
          label: '1st Quarter (Jan-Mar)',
          value: {
            quarter: 1,
            start: '01/01',
            end: '31/03'
          },
        },
        {
          label: '2nd Quarter (Apr-Jun)',
          value: {
            quarter: 2,
            start: '01/04',
            end: '30/06'
          },
        },
        {
          label: '3rd Quarter (Jul-Sep)',
          value: {
            quarter: 3,
            start: '01/07',
            end: '30/09'
          },
        },
        {
          label: '4th Quarter (Oct-Dec)',
          value: {
            quarter: 4,
            start: '01/10',
            end: '31/12'
          }
        }
      ],
      selectedQuarter: null,
      selectedYear: null,
      anti_patients: 0,
      assessed_anti_patients: 0,
      drugNames: [],
      registerData: [],
      date: [],
      loading: false,
      loadingTotals: false,
      loadingGraph: false,
      loadingLog: false,
      logs: null,
      toggleGraph: false,
      graphType: 'Quantity In Stock',
      lineOrBar: 'area',
      filters: {
        s8: true,
      },
      quarterData: {
      },
      graph_title: '',
      graphData: [],
      clearForm: {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters: {
          tags: ['s8'],
          drug: '',
          psychotropics: false,
          antimicrobial: false,
          date: {
            start: '',
            end: ''
          },
        }
      },
      form: {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        filters: {
          psychotropics: false,
          antimicrobial: false,
          date: ''
        }
      },
      finalised_data: null,
      y_axis_label: "",
      current_page: 0,
      page_count: 0,
      start_page: 0,
      hide_pagination: true,
      maxDate: new Date(),
      QUMQuestionaireResults: null,
      QUMSelectedFacilityQuestion: null
    }
  },
  methods: {
    expandAll(){
      this.expandedLogs = !this.expandedLogs;
    },
    showContent(log, index) {
      console.log('this the selected log ', log)
      // let refid=`expand-${index}`;

      if (log.display && (log.display === true)) {
        document.getElementById(`expand-${index}`).style.display = 'none';
        log.display = !log.display;
      } else {
        log.display = true;
        document.getElementById(`expand-${index}`).style.display = 'table-row';
      }
    },
    loadQUMLog(page = 1) {
      this.hide_pagination = true;
      let date = {
        start: this.selectedQuarter.start + '/' + new Date(this.selectedYear).getFullYear(),
        end: this.selectedQuarter.end + '/' + new Date(this.selectedYear).getFullYear(),
      }
      let payload = {
        report_type: this.form.filters.psychotropics ? 'psychotropic' : 'antimicrobial',
        date: date,
        drug_ingredients: null,
        page: page
      }
      this.loadingLog = true;
      axios.post('/get-qum-log', payload, this.$store.state.header).then(res => {
        this.loadingLog = false;
        console.log('this is the qum res', res.data);
        this.logs = res.data.data;
        // add expanded
        this.logs = this.logs.map(log => { 
          this.setChartType(log);
          return { ...log, 
                  expandedInfo: false, 
                  stadate: new Date(log.start_date).toLocaleDateString('en-AU'),
                  stodate: new Date(log.stop_date).toLocaleDateString('en-AU'), };
        });
        this.current_page = res.data.header.current_page;
        this.page_count = res.data.header.page_count;
        this.hide_pagination = false;
        if (!res.data.data.length) {
          this.hide_pagination = true;
        }
        this.$store.dispatch('getRefreshToken');
      }).catch(err=>{
        console.log('this is the qum err', err?.response?.data);
        this.loadingLog = false;
        this.hide_pagination = false;
      })
    },
    loadQUMQuestionaire() {
      if(!this.form.filters.psychotropics){
        this.QUMQuestionaireResults = null
        this.QUMSelectedFacilityQuestion = null
        let date = {
          start: this.selectedQuarter.start + '/' + new Date(this.selectedYear).getFullYear(),
          end: this.selectedQuarter.end + '/' + new Date(this.selectedYear).getFullYear(),
        }
        const payload = {
          report_type: "antimicrobial",
          date: date,
          drug_ingredients: null
        }

        axios.post('/get-qum-quarterly-questionaire', payload, this.$store.state.header).then(res => {
          this.QUMQuestionaireResults = res.data
          if (this.QUMQuestionaireResults.facility_data.length != 0) {
            this.QUMSelectedFacilityQuestion = this.QUMQuestionaireResults.facility_data[0]
          }
          // if(this.QUMQuestionaireResults.organisation_data.length != 0) {

          // }
        }).catch(err => {
          console.log('this is the qum questionnaire err', err?.response?.data);
        })
      }
      
    },
    setChartType(value){
        let chartTypes = this.$store.getters.getChartTypes;
        chartTypes.forEach(chartType => {
            if ((value.chart_type == chartType.chart_name) || (value.chart_type == chartType.name)) {
                console.log('LOOPING THROUGH THE CHART ITEMS', value.chart_type, chartType.chart_name);
                value.chart_type = chartType.chart_name;
                value.type = chartType.chart_name;
                value.color = chartType.color;
                value.chart_name = chartType.name;
                value.selectedChart = chartType;
                console.log('this is the color now', value.color);
            }
        })
    },
    getQUMReport(page = 1){      
      if(this.selectedQuarter && this.selectedYear && (this.form.filters.psychotropics || this.form.filters.antimicrobial)){
        this.loading = true;
        console.log('retrieving QUM data');
        let date = {
          start: this.selectedQuarter.start + '/' + new Date(this.selectedYear).getFullYear(),
          end: this.selectedQuarter.end + '/' + new Date(this.selectedYear).getFullYear(),
        }
        let payload = {
          report_type: this.form.filters.psychotropics ? 'psychotropic' : 'antimicrobial',
          date: date,
          drug_ingredients: null,
          page: page
        }
        this.loadQUMQuestionaire()
        this.loadQUMLog(page = 1);

        this.getQUMQuarterTotals(payload);
        this.getQUMGraphData();
      }
    },
    nextPage(page) {
      this.start_page = page;
      this.loadQUMLog(page);
    },
    getQUMQuarterTotals(payload) {
      this.loadingTotals = true;
      console.log('getQUMQuarterTotals', this.selectedQuarter)
      let previousQuarter = this.selectedQuarter.quarter >= 2 ? this.selectedQuarter.quarter - 1 : 4;
      let previousQuarterRange = this.quarters.filter(quarter => quarter.value.quarter == previousQuarter);
      let previousQuarterValue = {
        start: previousQuarterRange[0].value.start + '/' + new Date(previousQuarter == 4 ? this.selectedYear - 1 : this.selectedYear).getFullYear(),
        end: previousQuarterRange[0].value.end + '/' + new Date(previousQuarter == 4 ? this.selectedYear - 1 : this.selectedYear).getFullYear(),
      }
      delete payload.page;

      payload.previous_quarter = previousQuarterValue;
      console.log('this is the previous quarter', previousQuarterValue);
      axios.post('/get-qum-quarter-totals', payload, this.$store.state.header).then(res => {
        this.loadingTotals = false;
        this.quarterData = res.data;
      }).catch(err => {
        console.log('this is the qum report data error', err, err?.response?.data?.message);
        this.quarterData = {
          current_facility: {
            current_quarter: 3,
            previous_quarter: 5
          },
          all_facilities: {
            current_quarter: 3,
            previous_quarter: 5
          }
        }
      })
    },
    getQUMGraphData() {
      
      this.loadingGraph = true;
      let payload = {
        report_type: this.form.filters.psychotropics ? 'psychotropic' : 'antimicrobial',
        drug_ingredients: null,
        date: {
          start: '01/01/' + new Date(this.selectedYear).getFullYear(),
          end: '31/12/' + new Date(this.selectedYear).getFullYear()
        }
      }
      axios.post('/get-qum-quarterly-graph', payload, this.$store.state.header).then(res => {
        console.log('this is the qum graph data', res.data);
        this.graphData = {};
        this.graphData.years = res.data;
        this.parseGraphData()
        // this.graphData.years.forEach(year=>{
        //   year.data.forEach(quarter=>{
        //     quarter.value = quarter.script_count
        //   })
        // })
      }).catch(err => {
        console.log('thisis the qum graph data err', err, err?.response?.data?.message);
        this.graphData = {
          years: [
            {
              year: 2023,
              data: [
                {
                  quarter: 1,
                  value: '10.5'
                },
                {
                  quarter: 2,
                  value: '2.5'
                }
              ]
            }
          ]
        };
        setTimeout(this.parseGraphData(), 500);
      })
      // if(this.graphData.years){
      //   console.log('running conditional to parse graph data')
      //   setTimeout(this.parseGraphData(), 500);
      // }

    },
    parseGraphData() {
      console.log('parse graph data function is running')
      let lines = [];
      this.graphData.years.forEach(year => {
        let data = [];
        year.data.forEach(point => {
          let b = [
            // point.quarter == 1 ? 3 : point.quarter == 2 ? 6 : point.quarter == 3 ? 9 : 12,
            point.quarter,
            point.script_count
          ];
          data.push(b);
        });

        let yearLine = {
          'name': year.year,
          data: data
        }
        lines.push(yearLine);
      });
      this.finalised_data = lines;
      this.y_axis_label = "Number of " + (this.form.filters.antimicrobial ? 'Antimicrobial' : 'Psychotropics') + " prescribed";
      this.graph_title = `Commenced ${this.form.filters.antimicrobial ? 'Antimicrobial' : 'Psychotropic'}s per Quarter`;
      this.loadingGraph = false;
    },
    autoAll() {
      this.form.filters.tags = [];
      for (const [key, value] of Object.entries(this.filters)) {
        if (value == true) {
          this.form.filters.tags.push(key)
        }
      }
      console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
    },
    selectDrugName(name) {
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    aliasSearch(alias) {
      this.form.filters.drug = alias;
    },
    updateTransactionList() {
      this.form.filters.transactions = [];
      console.log('updating transaction list')
      this.selectedTransactions.forEach(transactionObject => {
        for (const [key, value] of Object.entries(transactionObject)) {
          console.log('this is the key', key);
          this.form.filters.transactions.push(value.toLowerCase());
        }
      })
    },
    clearFilteredDrugRegister() {
      this.form = {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters: {
          tags: ['s8'],
          drug: '',
          psychotropics: false,
          antimicrobial: false,
          date: {
            start: '',
            end: ''
          },
        }
      };
      this.date = [];
    },
    printElem(elem) {
      console.log('this is the eleme', elem);
      window.print();
    },
    getAllOrganisationEquivalentQuestion(QUMSelectedFacilityQuestion) {
      const foundQ = this.QUMQuestionaireResults.organisation_data.find((q) => q.question == QUMSelectedFacilityQuestion.question)
        return foundQ
    },
    getFacilityAffirmative(QUMSelectedFacilityQuestion){
      const foundQ = this.QUMQuestionaireResults.facility_data.find((q) => q.question == QUMSelectedFacilityQuestion.question);
      let totalAffirmative = 0;
      foundQ.answers.forEach(response=>{
        if(response.answer == 'Yes' || response.answer == '1'){
          totalAffirmative += response.count;
        }
      })
      return totalAffirmative;
    },
    getOrganisationAffirmative(QUMSelectedFacilityQuestion){
      const foundQ = this.QUMQuestionaireResults.organisation_data.find((q) => q.question == QUMSelectedFacilityQuestion.question);
      let totalAffirmative = 0;
      foundQ.answers.forEach(response=>{
        if(response.answer == 'Yes' || response.answer == '1'){
          totalAffirmative += response.count;
        }
      })
      return totalAffirmative;
    }
  },
  mounted() {
  },
  computed: {
    calculateFacilityPercentage() {
      return Math.round((this.getFacilityAffirmative(this.QUMSelectedFacilityQuestion) / this.QUMQuestionaireResults.facility_total)*100)
    },
    calculateAllOrganisationPercentage() {
      return Math.round((this.getOrganisationAffirmative(this.QUMSelectedFacilityQuestion) / this.QUMQuestionaireResults.organisation_total)*100)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.instructions-container {
  border: 1px solid $strongblue;
  width: 650px;
  height: 208px;
  border-radius: 8px;

  p {
    text-align: left;
    margin: 0;
  }
}

.instructions-header {
  background-color: $strongblue;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 5px 25px;
  height: 46px;

  p {
    font-size: 1.2em;

  }
}

.instructions-body {
  padding: 25px;

  p {
    font-size: 1em;
  }
}

.instructions-content {
  margin-bottom: 20px;
}

  .qi-data-report-table{
   
    th{
      font-size: 15px;
      padding: 16.5px 0;
    }
    td{
      padding: 25px 0;
      padding-right: 10px;
      border-bottom: 1px solid #BDC4C4;
    }
    tr{
      td:first-of-type{
        padding-left: 4.5px;
      }
    }
    th:first-of-type{
      padding-left: 15px;
    }
    th:last-of-type{
      padding-right: 26px;
    }
    // td:first-of-type{
    //   padding-left: 26px;
    // }
    // td:last-of-type{
    //   padding-right: 26px;
    // }
    tr:last-of-type{
      td{
        border-bottom: none;
      }
    }
    
  }

.left-report-column {
  width: 650px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.right-report-column {
  max-width: 950px;
  min-width: 400px;
  flex: 1 1 0px;
}

.qi-data-report-table {

  th {
    font-size: 15px;
    padding: 16.5px 0;
  }

  td {
    padding: 25px 0;
    padding-right: 10px;
    border-bottom: 1px solid #BDC4C4;
  }

  tr {
    td:first-of-type {
      padding-left: 16.5px;
    }
  }

  th:first-of-type {
    padding-left: 15px;
  }

  th:last-of-type {
    padding-right: 26px;
  }

  // td:first-of-type{
  //   padding-left: 26px;
  // }
  // td:last-of-type{
  //   padding-right: 26px;
  // }
  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }

}

.report-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
}

.grey-calendar {
  border-radius: 8px;
  border: 1px solid #BDC4C4;

  .p-inputtext {
    background-color: #f3f4f4;
  }
}

.qi-input {
  width: 13em;
}

.qum-facility-section {
  display: grid;
  grid-template-columns: 47% 50%;
  grid-gap: 3%;
}

.qum-facility-container {
  padding: 20px;
  border: 2px solid #BDC4C4;
  border-radius: 8px;
}



.qum-facility-header-overview p{
  text-align: left;
  margin: 0; 
  font-weight: bold;
  font-size: 1em;
}
.qum-facility-header-overview {
display: flex;
justify-content: space-between;
}

.qum-facility-header-overview h4{
  text-align: left;
  margin: 0; 
  font-weight: bold;
  font-size: 1.2em;
}

.qum-facility-header p {
  width:100%;
  text-align: left;
  margin: 0;
  font-weight: bold;
  font-size: 1em;
}

.qum-facility-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.qum-facility-header h4 {
  text-align: left;
  margin: 0;
  font-weight: bold;
  font-size: 1.2em;
}

.qum-facility-comparator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 40px;
}

.qum-facility-comparator-questionnaire {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}


.qum-facility-comparator-content-current {
  display: flex;
  flex-direction: column;
  border: 2px solid hsl(204, 73%, 73%);
  border-radius: 8px;

  p {
    margin: 0;
  }
}

.qum-facility-comparator-content-all {
  display: flex;
  flex-direction: column;
  border: 2px solid hsl(269, 55%, 42%);
  border-radius: 8px;

  p {
    margin: 0;
  }
}

.qum-question-box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
}

.qum-question-box h2,
h4,
p {
  margin: 10px 0px 0px 0px;
  text-align: left;
}


.qum-top-data-current {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: start;
  padding-left: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: hsl(214, 92%, 95%);
}

.qum-top-data-all {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: start;
  padding-left: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: hsl(323, 53%, 93%);
}

.qum-bottom-data {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: start;
  padding-left: 20px;
}

.qi-report-table {
  tr:nth-child(even) {
    background-color: #f3f4f4;
  }
}</style>