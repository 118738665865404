<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <h3 class="form-header">NIM Administration</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                                <div class="form-section-container" style="grid-template-columns: 3fr 4fr 4fr; grid-gap: 24px; width: 100%; padding: 0 25px;">
                                <div class="left-form-section-panel" style="padding: 0;">
                                    <div class="icon-container">
                                        <div class="icon-circle">
                                            <img class="icon-circle-item" src="../../assets/icons/medPlaceholder.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-form-section-panel" style="padding: 0;">
                                    <div class="grey-container">
                                        <p><b>Route: </b>{{selectedNIM.route}}</p>
                                        <p>{{selectedNIM.instructions}}</p>
                                        <p><b>Indication:</b> {{selectedNIM.indication ? selectedNIM.indication : 'Not provided.'}}</p>
                                        <p><b>Max dose 24hrs:</b> <span v-if="selectedNIM.max_dose">{{selectedNIM.max_dose}} {{selectedNIM.alias.form}}</span> <span v-else>Not provided.</span></p>
                                        <p><b>Last Dose:</b> <span v-if="selectedNIM.last_chart_administrations.length">{{selectedNIM.last_chart_administrations[0].administered_at}}</span>
                                            <span v-else>Never administered.</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="right-form-section-panel" style="padding: 0;">
                                    <div>
                                        <h3 class="form-header">{{selectedNIM.alias.name}}</h3>

                                        <div v-tooltip="'Prescription Quantity'" class="small-input" style="margin: 10x; margin-left: 0px; margin-bottom: 15px; width: fit-content ">
                                            <p style="text-align: left; margin: 0; width: fit-content;">
                                                Enter Quantity ({{selectedNIM.dose}} {{selectedNIM.alias.form}}{{selectedNIM.dose > 1 ? 's':''}})</p>
                                            <InputText :minFractionDigits="0" :maxFractionDigits="2" mode="decimal" style="border: 1px solid grey; border-radius: 4px; width: 10em;" v-model="enteredDose" :max="40" />
                                        </div>
                                        
                                    </div>
                                    <!-- <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="11"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="notes"></textarea>
                                    </div> -->
                                    
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button class="green-button" @click="signAction()">
                           Sign
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :dual="false" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" :loop="loop" :displayReason="true" />
        <SuccessModal v-if="displaySuccessModal" :first_spec_line="displayMessage" @close="closeSuccessModal" />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';
// import SingleVerify from '@/components/verification/SingleVerify.vue';
import SuccessModal from './SuccessModal.vue';
// import axios from 'axios';

export default {
    props: ['message', 'close', 'loading', 'statusMessage', 'action', 'actionType', 'noAuthRequired', 'adminSubmission', 'loop', 'selectedNIM', 'patientId'],
    components: {
        SignatureModal,
        // SingleVerify,
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            displayMessage:'',
            payload:'',
            enteredDose:0,
            signature_endpoint:'/administer-chart',
            displaySignatureModal: false,
            selectedCode:'',
            notes:'',
        }
    },
    methods: {
        closeSuccessModal(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                console.log('this is the value from administering nim', value);
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.$emit('completeNIM', {administered_at: value.administered_at, id: this.selectedNIM.uuid});
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            let date = new Date();
            // not technically this.adminSubmission.calendar_item_id but is being passed via prop as such, it is indeed the chart_id
            let notes = `NIM dose administered.

Date: ${date.toLocaleDateString('en-AU')}
Time of administration: ${date.toLocaleTimeString('en-AU')}
Drug name: ${this.selectedNIM.alias.name}
Qty: ${this.enteredDose} ${this.selectedNIM.alias.form}/s
Administration Route: ${this.selectedNIM.route}
`
            this.payload = {
                chart_id:this.adminSubmission.calendar_item_id,
                doc:'',
                notes:notes,
                dose:parseFloat(this.enteredDose).toFixed(2),
                drug_safe_retrieval: this.drug_safe_retrieval,
                reading: null

            }
            console.log('SIGN ACTION CONDITION', this.payload);
            console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('close', true);
            
        },
    },
    mounted() {
        if(!this.adminSubmission.length){
            console.log('HITTING THE IF ON MOUNT')
            this.payload = {...this.adminSubmission};
        } else{
            console.log('HITTING THE ELSE ON MOUNT')
            this.payload = this.adminSubmission;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.icon-container{
    width: 282px;
    height: 240px;
    left: 25px;
    background: #94D6D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-circle{
    border-radius: 50%;
    height: 131px;
    width: 131px;
    background: #00B2A2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-input{
    span{
        width: 50%;
        input{
            width:50%;
        }
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.icon-circle-item{
    width: 75px;
}

.doc-grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.grey-container{
    background-color: #f3f4f4;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    p{
        margin-bottom: 10px !important;
        font-size: 15px;
    }
}

.doc-item{
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}

.selected-doc-item{
    background-color: $error;
    color: white;
}

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
