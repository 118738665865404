<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 90%; max-height: 90vh;">
                <div class="modal-header">
                    <h3 class="form-header" style="margin-left: 1em">Medication History - Expired Chart</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <div class="action-modal"
                            style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                            <!-- NEW PRESCRIPTION -->
                            <PrescriptionChart v-if="activePresChart" 
                                :viewOnly="true" 
                                @isSuccessful="isSuccessful" 
                                :patientId="patientId"
                                :frequencyOptions="frequencyOptions"
                                :activePresChart="activePresChart" 
                                :displayDropDown="false" />
                            <div>
                                <div style="max-height: 20em; overflow: auto; margin-right: 1em;">
                                    <h4 style="text-align: left; margin: 0; margin-bottom: 1em;">CHARTS NOTES</h4>

                                    <div v-if="chart.chart_notes && chart.chart_notes.length">
                                        <div v-for="(item, index) in chart.chart_notes" :key="index">
                                            <p>{{ item }}</p>
                                        </div>
                                    </div>
                                    <p v-else
                                        style="background-color: powderblue; font-size: 1em; padding: 1em; border-radius: 4px;">
                                        No chart notes available.</p>
                                </div>
                                <div style="max-height: 20em; overflow: auto; margin-right: 1em;">
                                    <h4 style="text-align: left; margin: 0; margin-bottom: 1em;">ADMINISTRATIONS</h4>
                                    <div v-if="chart.administrations && chart.administrations.length">
                                        <div v-for="(item, index) in chart.administrations" :key="index">
                                            <p>DOC: {{ item.doc }}</p>
                                            <p>Administered by: {{ item.administered_by }}</p>
                                            <p>Administered at: {{ item.administered_at }}</p>
                                            <p>Note: {{ item.note }}</p>
                                        </div>
                                    </div>
                                    <p v-else
                                        style="background-color: powderblue; font-size: 1em; padding: 1em; border-radius: 4px;">
                                        No administrations.</p>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import PrescriptionChart from '../PrescriptionChart.vue';

export default {
    props: ['patientId', 'close', 'viewOnly', 'chart', 'frequencyOptions'],
    components: {
        PrescriptionChart,
    },
    data() {
        return {
            patient: '',
            charts: '',
            activePresChart: '',
            draftDrug: '',
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.retrieveAllCharts();
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        retrievePresChart(value) {
            console.log('received this from the table', value);

            // explicitly mapping all datapoints in case of any object changes from the response value
            this.activePresChart = {
                chart_payload: {
                    prescription_id: value.uuid,
                    chart_form: {
                        type: value.chart_type,
                        route: value.route,
                        indication: value.indication,
                        tags: []
                    }
                },
                prescription_payload: {
                    patient_id: this.$route.params.id,
                    prescriber: value.doctor.uuid,
                    drug_uuid: value.alias.alias_id,
                    substitution_not_permitted: value.substitution_not_permitted ? true : false,
                    chart_id: value.chart_id,
                    authority_prescription_number: value.authority_prescription_number ? value.authority_prescription_number  : '',
                    pbs_code: value.pbs_code ? value.pbs_code  : '',
                    pbs_manufacturer_code: value.pbs_manufacturer_code ? value.pbs_manufacturer_code  : '',
                    pbs_authority_code: value.pbs_authority_code ? value.pbs_authority_code  : '',
                    days_of_week: value.days_of_week,
                    interval: value.interval,
                    frequency: value.frequency,
                    frequency_shorthand: value.frequency_shorthand,
                    times: value.times,
                    dose: value.dose,
                    dose_unit: value.dose_unit,
                    route: value.route,
                    sliding_scale: value.sliding_scale,
                    max_admin_dose: value.max_admin_dose,
                    max_daily_admin: value.max_daily_admin,
                    is_paper: value.is_paper ? true : false,
                    paper_prescription_number : value.paper_prescription_number ? value.paper_prescription_number : value.is_paper ? '1' : '',
                    reason: value.reason,
                    max_dose: value.max_dose,
                    start_date: new Date(value.start_date).toLocaleDateString('en-AU'),
                    stop_date: new Date(value.stop_date).toLocaleDateString('en-AU'),
                    additional_regulatory_checks: '',
                    administrations: value.administrations,
                    created_date: new Date(value.created_at).toLocaleDateString('en-AU'),
                    dose_times : value.dose_times,
                    last_administration: value.last_administration ? value.last_administration  :'',
                    prescribed_quantity: value.prescribed_quantity,
                    notes: value.instructions && value.instructions.text ? value.instructions.text : '',
                    chart_tags: value.chart_tags,
                    signed: value.signatures.length ? true : false,
                }
            };
            this.draftDrug = value.alias;
        },
    },
    mounted() {
        this.retrievePresChart(this.chart);
    },
    computed: {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

// .notes-modal-section {
//     // background-color: white;
// }

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: auto;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.patient-summary-block {
    .field-value {
        background-color: #f3f4f4;
    }
}

.chart-row-item {
    p {
        margin: 0;
        margin-top: 10px;
    }
}
</style>
