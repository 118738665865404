<template>
    <div
        class="image-container"
        :style="[width ? {width: width} : {width:'120px'}, 
                height ? {height: height} : {height: '100px'}]">
        <i
            v-if="loading"
            class="pi pi-spin pi-spinner"
            style="font-size: 2rem !important; margin-top: 10px; ">
        </i>
        <img
            v-else
            :src="mimsImage ? `data:image/png;base64,${mimsImage}` : require('@/assets/icons/medIcon.png')"
            alt="Medication Image"
            class="scaled-image"
        />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: [
        'alias_id',
        'off_market',
        'width',
        'height'
    ],
    data() {
        return {
            loading: false,
            mimsImage: '',
            header: ''
        }
    },
    created() {
        this.getMIMSImage();
    },
    methods: {
        async getMIMSImage() {
            console.log("calling mims images");
            const apiKey = localStorage.getItem('mimsApiKey');
            // If a drug is selected
            if (this.alias_id && this.off_market && apiKey) {
                this.loading = true;
                axios.post(`/mims/image_search`,
                    // headers: this.$store.state.header.headers,
                    {
                        alias_id: this.alias_id,
                        off_market: this.off_market,
                        api_key: apiKey
                    },
                    this.$store.state.header
                )
                .then(res => {
                    this.mimsImage = res.data;
                    this.loading = false;
                }).catch(err => {
                    console.log('this is the err', err);
                    this.loading = false;
                    if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
            }
        }
    },
    watch: {
        mimsImage() {
            this.$emit('mimsImageChanged', this.mimsImage);
        }
    },
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>