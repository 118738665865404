<template>
  <div class="drug-register-view view">
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_resident')" style="width: 95.5vw; ">
      <div style="display: flex;  justify-content: flex-end;">
        <!-- END RESIDENTIAL INFORMATION TITLE AND EDIT BUTTON -->
        <!-- MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
        <div class="parent-tabs-container" style="display: flex; align-items: flex-end">
          <h3 class="tab" :class="selectedTab == 'ADMIN' ? 'selected-tab' : ''"
            @click="selectedTab = 'ADMIN', clearCharts()">Admin Round</h3>
          <h3 class="tab" :class="selectedTab == 'PRN' ? 'selected-tab' : ''"
            @click="selectedTab = 'PRN', clearCharts()">PRN</h3>
          <h3 class="tab" :class="selectedTab == 'NIM' ? 'selected-tab' : ''"
            @click="selectedTab = 'NIM', clearCharts()">NIM</h3>
          <h3 class="tab" :class="selectedTab == 'PATCH' ? 'selected-tab' : ''"
            @click="selectedTab = 'PATCH', clearCharts()">Reapply Patch</h3>
        </div>
        <!-- END MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
      </div>
      <div
        style="background-color: white; padding: 25px; padding-top: 0px; height: 85vh; box-shadow: 0px 0 5px -2px rgb(155, 155, 155); border-radius: 12px; border-top-right-radius: 0px;">
        <!-- HEADER ANF FILTER -->
        <div style="display: flex; flex-direction: column;" v-if="locations && locations.length && !loadingRounds">

          <!-- PATCH WARNING INFO BANNER -->
          <div v-if="selectedTab == 'PATCH'" style="width: fit-content; color: #E04F39; height: fit-content; border-color: #E04F39; border-style: solid; border-width: 2px; border-radius: 8px; padding: 8px; margin-top: 15px; margin-bottom: -15px;">
            <div style="display: flex; align-items: flex-start; text-align: left;">
              <i class="pi pi-info-circle" style="color: inherit; cursor: inherit;"></i>
              <span style="font-size: 16.5px; margin-left: 8px;">
                Administer from here if a patch needs to be reapplied. 
                For administration round patches, please administer from the Admin Round tab.
              </span>
            </div>
          </div>

          <div class="filters" style="width: 100%; margin-top: 0; padding-top: 10px;padding-top: 25px;padding-bottom: 20px;">
            <div v-if="selectedTab == 'ADMIN'">
              <h5 class="filter-admin-title" v-if="selectedTab == 'ADMIN'">
                Administration Round:
              </h5>
              <div class="select-filters-container" style="height: 35px; " v-if="selectedTab == 'ADMIN'">
                <!-- <p style="margin-right: 0; padding: 8px 15px;" class="select-filter-item active-select-filter">
                  Administration Round</p> -->
                <Dropdown @change="selectRound()" class="admin-rooms-dropdown" v-model="selectedRound" :options="rounds"
                  optionLabel="name" placeholder="Select Administration Round" style="position: relative; min-width: 15em;margin-right: 0px !important" />
              </div>
            </div>
            <!-- <div style="display: flex; align-items: center;display: none;" v-if="selectedTab == 'ADMIN'">
              <div class="select-filters-container" style="height: 35px;display: none;">

                <p style="display: none;margin-right: 0; padding: 8px 15px;" @click="() => { form.packed = !form.packed; }"
                  v-bind:class="[form.packed ? 'active-select-filter' : '']" class="select-filter-item">Packed</p>

                <p style="display: none;margin-right: 0; padding: 8px 15px;" @click="() => { form.s8 = !form.s8; }"
                  v-bind:class="[form.s8 ? 'active-select-filter' : '']" class="select-filter-item">S8/S4D</p>
              </div>
            </div> -->


            <div style="display: flex; align-items: center" v-if="selectedTab == 'ADMIN'">
              <div>
                <h5 class="filter-admin-title">
                  Location:
                </h5>
                <div class="select-filters-container" style="height: 35px;">
                  <TreeSelect @change="retrieveChartRound()" v-model="selectedLocations" :options="locations"
                    display="chip" selectionMode="checkbox" placeholder="Select location/s"
                    style="background: transparent; border: 0 none; width: 15em; margin-right: 0px !important;"></TreeSelect>
                </div>
              </div>
            </div>


            <div style="display: flex; align-items: center"
              v-if="selectedTab == 'PRN' || selectedTab == 'NIM' || selectedTab == 'PATCH'">
              <div class="filter-admin-block">
                  <h5 class="filter-admin-title">
                    Location:
                  </h5>
                  <div class="select-filters-container" style="height: 35px;">
                     <TreeSelect v-model="selectedLocations" :options="locations" placeholder="Select Item"
                      style="text-align: left;background: transparent; border: 0 none; width: 15em;margin-right: 0px !important"></TreeSelect>
                  </div>
              </div>
            </div>
            <div style="display: flex; align-items: center"
              v-if="selectedTab == 'PRN' || selectedTab == 'NIM' || selectedTab == 'PATCH'">
              <div>
                  <h5 class="filter-admin-title">
                    Resident:
                  </h5>
                  <div style="width: 20em">
                    <input v-model="searchTerm"
                      v-on:keyup.enter="() => { if (!searchTerm || searchTerm) { searchForPatient() } }"
                      placeholder="Enter Resident name" style="position: relative; width:100%" />
                  </div>
              </div>
              <button class="regular-button submit-button filter-button" style="margin-left: 0px; margin-right: 10px;"
                @click="() => { if (searchTerm) { searchForPatient() } }">Search</button>

              <button class="button clear-button filter-button" @click="clearAdministrationSearch()">Clear</button>
            </div>


            <!-- <button class="button submit-button" @click="searchActiveCharts()">Search</button> -->
            <!-- <button class="button clear-button" style="display: flex; align-items: center" 
                        v-bind:class="[searchFilter.prn ? 'active-select-filter' : '']"
                        @click="searchFilter.prn = !searchFilter.prn"><p style="font-weight: bold">PRN</p></button> -->

          </div>

        </div>
        <div v-else style="padding-top: 2em;">
          <h3>Loading Administration...</h3>
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div>
        <!-- END HEADER AND FILTER -->
        <div style="display: grid; grid-template-columns: 334px 5fr; margin-top: 10px; height: 90%"
          v-if="locations && locations.length && !loadingRounds">
          <AdministrationRoundList :prnMode="selectedTab == 'PRN'" :nimMode="selectedTab == 'NIM'"
            :patchMode="selectedTab == 'PATCH'" :patients="adminCharts" @selectPatient="selectPatient" :loadingResidents="loadingAdministration"
            @displaySelection="displaySelection" :loadingAdministration="loadingAdministration" :page_count="page_count" :currentPage="currentPage"
             @requestNextChartRoundPage="requestNextChartRoundPage" @requestCharts="requestCharts" />
          <SelectedPatientAdministration :expiredCharts="selectedPatientExpiredCharts" :spec_cons="spec_cons" :hiddenTags="hiddenTags" @togglePRN="togglePRN" @toggleNIM="toggleNIM"

            @togglePatch="togglePatch" @completePRN="completePRN" @updatePatientReading="updatePatientReading" :loadingResidentDrugs="loadingResidentDrugs"
            v-if="selectedTab == 'ADMIN'" :noCharts="noCharts" @retrievePRNs="retrievePRNs" :loadingPRNs="loadingPRNs"
            @retrieveNIMs="retrieveNIMs" :loadingNIMs="loadingNIMs" @retrievePatches="retrievePatches"
            :loadingPatches="loadingPatches" @submitEffective="submitEffective" :prns="selectedPatientPRNs"
            :nims="selectedPatientNIMs" :patches="selectedPatientPatches" :adminChart="selectedPatientCharts"
            :loadingAdministration="loadingAdministration" :patient="selectedPatient" @primeSignature="primeSignature"
            @isCompleted="isCompleted" :selectedPatientUUID="selectedPatientUUID" :loadingPatient="loadingPatient"
            :emptyList="selectedLocations && selectedLocations.length && adminCharts && adminCharts.length ? true : false" :loadMims="loadMims" />
          <SelectedPatientPRN :expiredCharts="selectedPatientExpiredCharts" :spec_cons="spec_cons" :hiddenTags="hiddenTags" v-if="selectedTab == 'PRN' && selectedPatient && selectedPatient.prns && selectedPatient.stock_items && !notReady"
            @completePRN="completePRN" @retrievePRNs="retrievePRNs" :loadingPRNs="loadingPRNs"
            @submitEffective="submitEffective" :patient="selectedPatient" @primeSignature="primeSignature"
            @isCompleted="isCompleted" :selectedPatientUUID="selectedPatientUUID" :loadingPatient="loadingPatient" />
          <SelectedPatientPatch :spec_cons="spec_cons" :adminChart="selectedPatientPatches" :hiddenTags="hiddenTags"
            v-if="selectedTab == 'PATCH' && !notReady" @completePatch="completePatch" @retrievePatches="retrievePatches"
            :loadingPatches="loadingPatches" @submitEffective="submitEffective" :patient="selectedPatient"
            @primeSignature="primeSignature" @isCompleted="isCompleted" :selectedPatientUUID="selectedPatientUUID"
            :loadingPatient="loadingPatient" />
          <SelectedPatientNIM :spec_cons="spec_cons" :hiddenTags="hiddenTags" v-if="selectedTab == 'NIM' && !notReady"
            @retrieveNIMs="retrieveNIMs" :nims="selectedPatientNIMs" :loadingNIMs="loadingNIMs"
            :patient="selectedPatient" @primeSignature="primeSignature" @isCompleted="isCompleted"
            :selectedPatientUUID="selectedPatientUUID" :loadingPatient="loadingPatient" />
          <SignatureModal
              v-if="displaySignatureModal"
              :payload="payload"
              @close="close"
              :endpoint="signature_endpoint"
              :reason="true"
          />
          <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
          <ChartChangeAlert @close="close" v-if="displayRecentlyChangedCharts" :resident="selectedPatient" :recentlyChangedCharts="recentlyChangedCharts" :notifiedChartAlerts="notifiedChartAlerts" />
        </div>
      </div>
    </div>
    <div v-else class="panel panel-content-container" style="margin-left: 3em; width: 95%;">
      <div style="margin-top: 10em;">
        <h3
          style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
          You do not have permission to access this feature. Please talk to your admin if you require access.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const axiosCancelToken = axios.CancelToken
const source = axiosCancelToken.source();

import SuccessModal from '@/components/modals/SuccessModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import AdministrationRoundList from '@/components/administration/AdministrationRoundList.vue';
import SelectedPatientAdministration from '@/components/administration/SelectedPatientAdministration.vue';
import SelectedPatientPRN from '@/components/administration/SelectedPatientPRN.vue';
import SelectedPatientPatch from '@/components/administration/SelectedPatientPatch.vue';
import SelectedPatientNIM from '@/components/administration/SelectedPatientNIM.vue';
import ChartChangeAlert from '@/components/modals/ChartChangeAlert.vue';
// import NodeService from '../../service/NodeService';

export default {
  nodeService: null,
  components: {
    SignatureModal,
    SuccessModal,
    AdministrationRoundList,
    SelectedPatientAdministration,
    SelectedPatientPRN,
    SelectedPatientPatch,
    SelectedPatientNIM,
    ChartChangeAlert
  },
  data() {
    return {
      displaySignatureModal: false,
      displaySuccessModal: false,
      displayRecentlyChangedCharts: false,
      successMessage: '',
      nodes: null,
      searched: false,
      searchError: '',
      searchTerm: '',
      selectedTab: 'ADMIN',
      displayResults: false,
      selectedNodeKey: null,
      selectedNodeKey2: null,
      loadingPatient: false,
      loadingPRNs: false,
      loadingNIMs: false,
      loadingPatches: false,
      notReady: false,
      noCharts: false,
      toSign: '',
      selectedPatient: '',
      selectedPatientUUID: '',
      selectedPatientCharts: '',
      selectedPatientPRNs: '',
      selectedPatientNIMs: '',
      selectedPatientPatches: '',
      selectedRound: '',
      initialSelectedLocation: {},
      selectedLocations: null,
      expandModal: false,
      locations: null,
      currentPage: 1,
      page_count: null,
      form: {
        locations: [],
        patient:'',
        start_date: '',
        end_date: '',
        page: 1,
      },
      loadingAdministration: false,
      adminCharts: [],
      hiddenTags: ["S11", "S4", "S4ACT", "S4D", "S4NSW", "S4QLD", "S4SA", 'S4TAS', 'S4VIC', 'S8','S4NT'],
      rounds: [
        // {
        //   name: 'Current - (-1hr) - (+3hrs)',
        //   value: ''
        // },
        {
          name: 'Overnight - 00:00 - 06:29',
          window: 23340000,
          value: ' 00:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Morning - 06:30 - 09:59',
          window: 12540000,
          value: ' 06:30:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Noon - 10:00 - 12:59',
          window: 10740000,
          value: ' 10:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Early Afternoon - 13:00 - 14:59',
          window: 7140000,
          value: ' 13:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Late Afternoon 15:00 - 18:59',
          window: 14340000,
          value: ' 15:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Bedtime 19:00 - 20:59',
          window: 7140000,
          value: ' 19:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Night 21:00 - 23:59',
          window: 10740000,
          value: ' 21:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
      ],
      loadingRounds: false,
      alphabeticMap: {
        "A": "1",
        "B": "2",
        "C": "3",
        "D": "4",
        "E": "5",
        "F": "6",
        "G": "7",
        "H": "8",
        "I": "9",
        "J": "10",
        "K": "11",
        "L": "12",
        "M": "13",
        "N": "14",
        "O": "15",
        "P": "16",
        "Q": "17",
        "R": "18",
        "S": "19",
        "T": "20",
        "U": "21",
        "V": "22",
        "W": "23",
        "X": "24",
        "Y": "25",
        "Z": "26",

      },
      spec_cons: [],
      loadingResidentDrugs: false,
      recentlyChangedCharts: [],
      notifiedChartAlerts: [],
      AxiosController: null,
      loadMims: false,
      requestedMeds:[],
      selectedPatientExpiredCharts: null
    }
  },
  methods: {
    clearAdministrationSearch() {
      this.searchTerm = '',
      this.selectedLocations = null;
      this.selectedPatient = {};
      this.adminCharts = [];
      this.loadingPatient = false;
    },
    toggleNIM() {
      this.$store.dispatch('getRefreshToken');
      console.log('hitting show NIM');
      this.selectedPatient.displayNIM = !this.selectedPatient.displayNIM;
      this.selectedPatient.displayPRN = false;
      this.selectedPatient.displayPatch = false;
    },
    togglePRN() {
      this.$store.dispatch('getRefreshToken');
      console.log('hitting show PRN');
      this.selectedPatient.displayPRN = !this.selectedPatient.displayPRN;
      if(!this.selectedPatient.displayPRN){
        this.retrieveExpiredCharts();
      }
      this.selectedPatient.displayNIM = false;
      this.selectedPatient.displayPatch = false;
    },
    togglePatch() {
      this.$store.dispatch('getRefreshToken');
      console.log('hitting show PRN');
      this.selectedPatient.displayPatch = !this.selectedPatient.displayPatch;
      this.selectedPatient.displayNIM = false;
      this.selectedPatient.displayPRN = false;
    },
    clearCharts() {
      this.$store.dispatch('getRefreshToken');
      // clear admin round details and selected patient so new prn details can fill in
      this.selectedPatient = {};
      this.adminCharts = [];
      this.selectedLocations = null;
      this.loadingPatient = false;
    },
    searchForPatient() {
      this.$store.dispatch('getRefreshToken');
      this.searched = false;
      this.searchError = '';
      this.displayResults = true;
      this.adminCharts = [];
      let locationKey = '';
      for (let key in this.selectedLocations) {
        console.log('this is the key', key);
        locationKey = key;
      }
      this.notReady = true;
      // Call the getPatientSearchResults store function
      this.$store.dispatch('getRefreshToken');
      axios.post('/search-patients-administration', { name: this.searchTerm, location: locationKey, page: 1 }, this.$store.state.header).then(res => {
        console.log('this is the state function to search patients', res.data);
        // Set sort the patients
        this.adminCharts = res.data.patients;
        console.log("THESE ARE THE NEW ADMIN CHARTS", this.adminCharts.data);
        // this.adminCharts = this.sortRooms(this.adminCharts);

        //this.adminCharts.sort((a, b) => a.physical_location.location.name.localeCompare(b.physical_location.location.name));
        // Loop through the patients
        this.adminCharts.forEach((pat, index) => {
          if (this.selectedTab == 'PRN') {
            axios.post('/get-prns', { patient_id: pat.uuid }, this.$store.state.header).then(res1 => {
              pat.name = `${pat.personal_information.first_name} ${pat.personal_information.last_name}`
              pat.prns = res1.data;
              // this.retrievePRNExpiredCharts(pat.uuid);
              
              if (pat.prns.length) {
                if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
                  axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res1=>{
                    console.log('this is the pending list', res1.data);
                    this.requestedMeds = res1.data;
                    pat.requestedMeds = this.requestedMeds;
                  })
                }
                pat.prns.forEach(prn => {
                  if (prn.last_chart_administrations && prn.last_chart_administrations.length) {
                    if (prn.last_chart_administrations[0].effectiveness == null) {
                      prn.completed = true;
                      prn.administration_id = prn.last_chart_administrations[0].uuid
                    }
                    console.log('looping through prn meds for patient');
                    
                  }
                  prn.amount_administered = 0;
                  if (prn.last_chart_administrations.length) {
                    console.log('tally up the prn administrations in the last 24 hours');
                    prn.last_chart_administrations.forEach(admin => {
                      prn.amount_administered += parseFloat(admin.quantity);
                      console.log('this is the next tally for amount administered', prn.amount_administered, admin);
                    })
                  }
                })
              }
            }).catch(err => {
              console.log('this is the prns retrieve err', err.response.data);
              this.loadingPRNs = false;
            })
          }
          if (this.selectedTab == 'NIM') {
            axios.post(`/person/${pat.uuid}/nim-charts`, {}, this.$store.state.header).then(res1 => {
              pat.name = `${pat.personal_information.first_name} ${pat.personal_information.last_name}`
              pat.nims = res1.data;
              if (pat.nims.length) {
                pat.nims.forEach(nim => {
                  nim.amount_administered = 0;
                  if (nim.last_chart_administrations.length) {
                    console.log('tally up the nim administrations in the last 24 hours');
                    nim.last_chart_administrations.forEach(admin => {
                      nim.amount_administered += parseFloat(admin.quantity);
                    })
                  }
                })
              }
            }).catch(err => {
              console.log('this is the error', err.response.data);
              pat.name = `${pat.personal_information.first_name} ${pat.personal_information.last_name}`
              pat.nims = []

            })
          }
          if (this.selectedTab == 'PATCH') {
            axios.post(`/get-patch-charts`, { patient_id: pat.uuid }, this.$store.state.header).then(res1 => {
              pat.name = `${pat.personal_information.first_name} ${pat.personal_information.last_name}`
              pat.patches = res1.data;
              if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
                axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res => {
                  this.requestedMeds = res.data;
                  pat.patches.forEach(patch => {
                    if (this.requestedMeds.includes(patch.prescription_id)) {
                      patch.requestedSupply = true;
                    }
                  })
                })
              }
              
              if (pat.patches.length) {
                
                pat.patches.forEach(patch => {
                  patch.amount_administered = 0;
                  if (patch.last_chart_administrations.length) {
                    console.log('tally up the patch administrations in the last 24 hours');
                    patch.last_chart_administrations.forEach(admin => {
                      patch.amount_administered += parseFloat(admin.quantity);
                    })
                  }
                })
                // this.selected
              }
            }).catch(err => {
              console.log('this is the error', err.response.data);
              pat.name = `${pat.personal_information.first_name} ${pat.personal_information.last_name}`
              pat.patches = []

            })
          }

          if ((this.adminCharts.length - 1) == index) {
            this.notReady = false;
          }
        })
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        } else {
          this.displaySuccessModal = true;
          this.successMessage = err.response && err.response.data ? err.response.data : err.response ? err.response : err;
        }
      })
    },
    retrievePRNs(value) {
      this.$store.dispatch('getRefreshToken');
      this.loadingPRNs = true;
      if (value) {
        this.$store.dispatch('getRefreshToken');
        axios.post('/get-prns', { patient_id: this.selectedPatientUUID }, this.$store.state.header).then(res => {
          console.log('this is the prn list retrieved', res.data);
          this.selectedPatientPRNs = res.data;
          this.selectedPatient.prns = this.selectedPatientPRNs;
          
          // NORMAL PRN ADMIN recent changed charts check
          this.recentlyChangedCharts = this.checkMedicationChange(this.selectedPatientPRNs);
          this.displayRecentlyChangedCharts = this.recentlyChangedCharts.length > 0;

          this.getStockRegister(this.selectedPatientUUID, 'prn');
          if (this.$store.state.user?.role?.permissions?.includes('can_do_resupply')) {
            axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res1 => {
              console.log('this is the pending list', res1.data);
              this.requestedMeds = res1.data;
              this.adminCharts.forEach(patient => {
                patient.prns.forEach(prn => {
                  if (this.requestedMeds.includes(prn.prescription_id)) {
                    console.log('this DOES match the requested meds', prn.prescription_id, this.requestedMeds)
                    prn.requestedSupply = true;
                  } else {
                    console.log('this DOES NOT match the requested meds', prn.prescription_id, this.requestedMeds)
                  }
                })
              })
            })
          }
          this.loadingPRNs = false;
          this.retrievePRNExpiredCharts(this.selectedPatientUUID);
          this.adminCharts.forEach(patient => {
            if (patient.uuid == this.selectedPatientUUID) {
              patient.prns = res.data;
              if (patient.prns && patient.prns.length) {
                patient.prns.forEach(prn => {
                  if (prn.last_chart_administrations && prn.last_chart_administrations.length) {
                    if (prn.last_chart_administrations[0].effectiveness == null) {
                      prn.completed = true;
                      prn.administration_id = prn.last_chart_administrations[0].uuid
                    }
                    
                  }
                  if (prn.instructions) {
                    console.log('this is instructions  before split', prn.instructions);
                    let instructions = prn.instructions.split('\n');
                    console.log('this is instructions split', instructions);
                    let d = instructions.slice(0, -2) + instructions.slice(-1)
                    console.log('this is d', d);
                    prn.instructions = d;
                    console.log('this is new instructions', prn.instructions);
                  }
                  prn.amount_administered = 0;
                  if (prn.last_chart_administrations.length) {
                    console.log('tally up the prn administrations in the last 24 hours');
                    prn.last_chart_administrations.forEach(admin => {
                      prn.amount_administered += parseFloat(admin.quantity);
                    })
                  }
                })
              }
            }
          })
        }).catch(err => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the error', err);
        })
      }
    },
    retrieveNIMs(value) {
      this.$store.dispatch('getRefreshToken');
      this.loadingNIMs = true;
      if (value) {
        console.log('retrieving nims', value);
        axios.post(`/person/${this.selectedPatientUUID}/nim-charts`, {}, this.$store.state.header).then(res => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the nim list retrieved', res.data);
          this.selectedPatientNIMs = res.data;
          this.loadingNIMs = false;
          this.adminCharts.forEach(patient => {
            if (patient.uuid == this.selectedPatientUUID) {
              patient.nims = res.data;
              if (patient.nims && patient.nims.length) {
                patient.nims.forEach(nim => {
                  if (nim.instructions) {
                    console.log('this is instructions  before split', nim.instructions);
                    let instructions = nim.instructions.split('\n');
                    console.log('this is instructions split', instructions);
                    let d = instructions.slice(0, -2) + instructions.slice(-1)
                    console.log('this is d', d);
                    nim.instructions = d;
                    console.log('this is new instructions', nim.instructions);
                  }

                  nim.amount_administered = 0;
                  if (nim.last_chart_administrations.length) {
                    console.log('tally up the nim administrations in the last 24 hours');
                    nim.last_chart_administrations.forEach(admin => {
                      nim.amount_administered += parseFloat(admin.quantity);
                    })
                  }
                })
              }
            }
          })
        }).catch(err => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the error', err);
        })
      }
    },
    retrievePatches(value) {

      this.loadingPatches = false;
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('retrieving patches', value);
        axios.post(`/get-patch-charts`, { patient_id: this.selectedPatientUUID }, this.$store.state.header).then(res => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the patch list retrieved', res.data);
          this.selectedPatientPatches = res.data;
          this.getStockRegister(this.selectedPatientUUID, 'patch');
          this.loadingPatches = false;
          
          this.adminCharts.forEach(patient => {
            if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
              axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res=>{
                  this.requestedMeds = res.data;
                  this.adminCharts.forEach(patient => {
                    patient.patches.forEach(patch=>{
                        if(this.requestedMeds.includes(patch.prescription_id)){
                            patch.requestedSupply = true;
                        }
                    })
                  })
                })
            }
            if (patient.uuid == this.selectedPatientUUID) {
              patient.patches = res.data;

              
              if (patient.patches && patient.patches.length) {
                patient.patches.forEach(patch => {
                  if (patch.instructions) {
                    console.log('this is instructions  before split', patch.instructions);
                    let instructions = patch.instructions.split('\n');
                    console.log('this is instructions split', instructions);
                    let d = instructions.slice(0, -2) + instructions.slice(-1)
                    console.log('this is d', d);
                    patch.instructions = d;
                    console.log('this is new instructions', patch.instructions);
                  }

                  patch.amount_administered = 0;
                  if (patch.last_chart_administrations.length) {
                    console.log('tally up the patch administrations in the last 24 hours');
                    patch.last_chart_administrations.forEach(admin => {
                      patch.amount_administered += parseFloat(admin.quantity);
                    })
                  }
                })
              }
            }
          })
        }).catch(err => {
          console.log('this is the error', err);
        })
        this.adminCharts.forEach(patient => {
          if (patient.uuid == this.selectedPatientUUID) {
            patient.patches = this.selectedPatientPatches
            if (patient.patches && patient.patches.length) {
              patient.patches.forEach(patch => {
                if (patch.instructions) {
                  console.log('this is instructions  before split', patch.instructions);
                  let instructions = patch.instructions.split('\n');
                  console.log('this is instructions split', instructions);
                  let d = instructions.slice(0, -2) + instructions.slice(-1)
                  console.log('this is d', d);
                  patch.instructions = d;
                  console.log('this is new instructions', patch.instructions);
                }

                patch.amount_administered = 0;
                if (patch.last_chart_administrations.length) {
                  console.log('tally up the patch administrations in the last 24 hours');
                  patch.last_chart_administrations.forEach(admin => {
                    patch.amount_administered += parseFloat(admin.quantity);
                  })
                }
              })
            }
          }
        })
        this.loadingPatches = false;
      }
    },
    completePRN(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('this is the prn completePRN administration', value);
        this.retrievePRNs(true);
        this.selectedPatientPRNs.forEach(prn=>{
          if(prn.uuid == value.id){
            if(prn.drug_safe_retrieval){
              prn.stock_items.forEach(stock_item=>{
                  localStorage.removeItem('DRET'+stock_item.uuid)
              })
            }
          }
        })
      }
    },
    completePatch(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('this is the prn complete patch administration', value);
        this.retrievePatches(true);
      }
    },
    completedNIM(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('this is the nim completeNIM administration', value);
        if (value) {
          this.selectedPatient.nims.forEach(chart => {
            if (chart.uuid == value.id) {
              chart.administered_at = value.administered_at;
              chart.completed = true;
            }
          })
        }
      }
    },
    completedPatch(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('this is the nim completeNIM administration', value);
        if (value) {
          this.selectedPatient.patches.forEach(chart => {
            if (chart.uuid == value.id) {
              chart.administered_at = value.administered_at;
              chart.completed = true;
            }
          })
        }
      }
    },
    updatePatientReading(value) {
      this.$store.dispatch('getRefreshToken');
      console.log('getting the value and shifing it', value);
      if (value) {
        console.log('getting the value and shifing it', value);
        this.selectedPatient.readings.unshift(value);
      }
    },
    submitEffective(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        console.log('this is the effective value in administration', value);
        this.signature_endpoint = `/administration/${value.prn_id}/effectiveness`;
        this.payload = { effectiveness: value.effective, notes: value.note };
        this.displaySignatureModal = true;
        
      }
    },
    log() {
      console.log('hitting hte node-selecct');
      this.findLocation()
    },
    findLocation() {
      this.$store.dispatch('getRefreshToken');
      this.form.locations = [];
      let locationKey = '';
      for (let key in this.initialSelectedLocation) {
        console.log('this is the key', key);
        locationKey = key;
      }
      this.locations.every(loc => {
        if (loc.key == locationKey) {
          console.log('found location by uuid', loc);
          this.form.locations.push(loc.key);
          if (loc.children.length) {
            console.log('founded location has kids', loc);
            this.getChildrenOfSelectedLocation(loc);
          } else {
            console.log('found location has no kids, so retrieivng now', loc, this.form.locations);
            this.retrieveChartRound();
          }
          console.log('match found, should stop looping findLocations');
          return false;
        } else {
          console.log('no match found, recursing now on findLocations');
          this.searchChildLocations(loc, locationKey)
          return true;
        }
      })
    },
    // THIS SEARCHES FOR THE KEY IN THE CHILD LOCATIONS OF NON-SELECTED LOCATIONS
    searchChildLocations(loc, id) {
      this.$store.dispatch('getRefreshToken');
      if (loc.key == id) {
        console.log('FOUND LOCATION DIRECTLY', loc);
        this.form.locations.push(loc.key);
        this.getChildrenOfSelectedLocation(loc);
      } else {
        if (loc.children.length) {
          loc.children.every(child => {
            if (child.key == id) {
              console.log('found through search child locations function', child);
              this.form.locations.push(child.key);
              this.getChildrenOfSelectedLocation(child);
              return false;
            } else {
              console.log('not found, digging deeper', child);
              setTimeout(() => {
                this.searchChildLocations(child, id)
              }, 100);
              return true;
            }
          })
        }
      }
    },
    // THIS PUSHES THE KEYS OF CHILD LOCATIONS OF THE SELECTED LOCATION INTO THE FORM LOCATIONS ARRAY
    getChildrenOfSelectedLocation(loc) {
      this.$store.dispatch('getRefreshToken');
      if (loc.children.length) {
        console.log('hitting if of getChidrenOfSelectedLocation, so retrieving kids');
        loc.children.forEach(child => {
          this.form.locations.push(child.key);
          if (child.children.length) {
            this.getChildrenOfSelectedLocation(child);
          }
          // else{
          //   console.log('hitting if else of getChidrenOfSelectedLocation, so retrieving chart');
          //   this.retrieveChartRound();
          // }
        })
      } else {
        console.log('hitting else of getChidrenOfSelectedLocation, so retrieving chart');
        this.retrieveChartRound()
      }

    },
    requestNextChartRoundPage(value){
      if(value){
        this.form.page = value.page;
        this.form.patient = value.patient ? value.patient : '';
        this.retrieveChartRound(value);
      }
    },
    requestCharts(value){
      if(value){
        this.adminCharts = []
        this.form.page = value.page;
        this.form.patient = value.patient ? value.patient : '';
        this.retrieveChartRound(value);
      }
    },
    retrieveChartRound(requestPage) {
      this.$store.dispatch('getRefreshToken');
      if(!requestPage){
        this.form.page = 1;
        this.noCharts = false;
        this.adminCharts = [];
        this.form.locations = [];
        this.selectedPatient = {};
        this.selectedPatientUUID = '';
        this.selectedPatientCharts = '';
        this.selectedPatientPRNs = '';
        console.log('retrieveChartRound form contains', this.form);
      }
      //  else if(requestPage && requestPage.patient){
      //   this.adminCharts = [];
      // }
      
      // if (!this.form.start_date) {
      //   let miltime = Date.now();
      //   let endtime = miltime + 10800000;

      //   this.form.start_date = new Date(miltime - 3600000).toISOString();
      //   this.form.start_date = this.form.start_date.slice(0, -10).concat('00:00.000Z');
      //   this.form.end_date = new Date(endtime).toISOString();
      //   this.form.end_date = this.form.end_date.slice(0, -10).concat('00:00.000Z');
      // }
      
      for (let key in this.selectedLocations) {
        this.form.locations.push(key);
      }
      
      if(this.form.locations.length && this.selectedRound){

        if (this.timeout)
                clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
        this.loadingAdministration = true;
        
        axios.post('/get-admin-round-residents', this.form, this.$store.state.header).then(res => {
          console.log('THESE ARE THE ROUND OF CHARTS', res);
          // Set and sort the patients
          let loadingResidentList = [];
          this.page_count = res.data.page_count;
          this.currentPage = res.data.current_page;
          if(requestPage){
            if(this.currentPage < this.page_count){
              console.log('hitting the if condition on 897')
              loadingResidentList = res.data.data;
            } else{
              console.log('hitting the else condition on 897')
              this.adminCharts = this.adminCharts.concat(res.data.data);
            }
            console.log('has request page variable', res.data);
           
          } else{
            // console.log('does not have request page variable')
            // this.adminCharts = res.data.data;
            if(this.currentPage < this.page_count){
              console.log('hitting the if condition on 897')
              loadingResidentList = res.data.data;
            } else{
              console.log('hitting the else condition on 897', this.currentPage, this.page_count);
              this.adminCharts = this.adminCharts.concat(res.data.data);
            }
          }
          
          
          
          if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
            axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res1=>{
              console.log('this is the pending list', res1.data);
              this.requestedMeds = res1.data;
            })
          }

          //this.adminCharts = this.sortRooms(this.adminCharts);
          this.adminCharts.sort(function(a,b) {
            console.log(a, b);
            return a.location.localeCompare(b.location, undefined, {

              numeric: true,
              sensitivity: 'base'
            });
          });

          //this.adminCharts.sort((a, b) => a.location.name.localeCompare(b.location.name));
          if (!res.data.length) {
            this.noCharts = true;
          }
          
          this.$store.dispatch('getRefreshToken');
          // if (this.adminCharts.length) {
          //   this.selectPatient(this.adminCharts[0].uuid);
          //   this.adminCharts[0].selected = true;
          //   this.selectedPatient = this.adminCharts[0];
          // }
          if(this.currentPage < this.page_count){
            this.retrieveAdditionalAdminResidents(this.currentPage+1, loadingResidentList);
          } else{
            this.loadingAdministration = false;
          }

        }).catch(err => {
          console.log('this is the err', err);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
        }, 600); // delay
      }
      
    },
    retrieveAdditionalAdminResidents(pageNum, loadingResidentList){
      this.form.page = pageNum;
      this.loadingAdministration = true;
      axios.post('/get-admin-round-residents', this.form, this.$store.state.header).then(res => {
          console.log('THESE ARE THE ROUND OF CHARTS', res);
          // Set and sort the patients
          this.page_count = res.data.page_count;
          this.currentPage = res.data.current_page;
          if(pageNum){
            if(this.currentPage < this.page_count){
              console.log('this is the loadingresidentlist and res data IF', loadingResidentList, res.data.data)
              loadingResidentList = [...loadingResidentList, ...res.data.data];
              console.log('this is the loadingresidentlist, if condition', loadingResidentList);
            } else{
              console.log('this is the loadingresidentlist and res data ELSE', loadingResidentList, res.data.data)
              loadingResidentList = [...loadingResidentList, ...res.data.data];
              console.log('this is the loadingresidentlist, else condition', loadingResidentList);
              this.adminCharts = loadingResidentList;
              
            }
          } 
          
          
          if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
            axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res1=>{
              console.log('this is the pending list', res1.data);
              this.requestedMeds = res1.data;
            })
          }

          //this.adminCharts = this.sortRooms(this.adminCharts);
          this.adminCharts.sort(function(a,b) {
            console.log(a, b);
            return a.location.localeCompare(b.location, undefined, {

              numeric: true,
              sensitivity: 'base'
            });
          });

          //this.adminCharts.sort((a, b) => a.location.name.localeCompare(b.location.name));
          if (!res.data.length) {
            this.noCharts = true;
          }
          
          this.$store.dispatch('getRefreshToken');
          // if (this.adminCharts.length) {
          //   this.selectPatient(this.adminCharts[0].uuid);
          //   this.adminCharts[0].selected = true;
          //   this.selectedPatient = this.adminCharts[0];
          // }
          if(this.form.page < res.data.page_count){
            this.retrieveAdditionalAdminResidents(this.form.page + 1, loadingResidentList);
          } else{
            this.loadingAdministration = false;
          }

        }).catch(err => {
          console.log('this is the err', err);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
    },
    getAge(dateString) {

      dateString = dateString.split('/')[1] + '/' + dateString.split('/')[0] + '/' + dateString.split('/')[2];
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      console.log('THIS IS THE AGE AND DATESTRING', age, dateString, new Date(dateString));
      console.log('THIS IS THE birthdate', birthDate);
      console.log('THIS IS THE birthdate', birthDate.getFullYear(), birthDate.getMonth());
      console.log('THIS IS THE today', today);
      console.log('THIS IS THE today', today.getFullYear(), today.getMonth());
      return age;
    },
    selectRound() {
      this.$store.dispatch('getRefreshToken');
      // let miltime = '';
      // let endtime = '';
      // let nowTime = new Date().toDateString();

      // if (this.selectedRound.name == 'Current - (-1hr) - (+3hrs)') {
      //   miltime = Date.now();
      //   endtime = miltime + 10800000;
      //   this.form.start_date = new Date(miltime - 3600000).toISOString();
      //   this.form.end_date = new Date(endtime).toISOString();
      //   this.retrieveChartRound();
      // } else {
        // miltime = nowTime.concat(this.selectedRound.value);
        // miltime = new Date(miltime);
        // miltime = miltime.getTime();
        // endtime = miltime + this.selectedRound.window;
        // this.form.start_date = new Date(miltime).toISOString();
        // this.form.end_date = new Date(endtime).toISOString();

        this.form.start_date = this.selectedRound.start_window;
        this.form.end_date = this.selectedRound.stop_window;
        this.retrieveChartRound();
      // }
    },
    selectRoomRange() {
      this.$store.dispatch('getRefreshToken');
      console.log('will send updated room range query', this.searchFilter.location);
    },

    displaySelection(value) {
      this.adminCharts.forEach(patient => {
        if (patient.uuid == value) {
          patient.selected = true;
        } else {
          patient.selected = false;
        }

      })
    },
    getStockRegister(patientId, PatchOrPRN){
      axios.post(`/person/${patientId}/stock-register`, {}, {headers: this.$store.state.header.headers, signal: this.AxiosController?.signal}).then(res=>{
        this.selectedPatient.stock_items = res.data;
        if(!PatchOrPRN){
          this.selectedPatientCharts.forEach(chart => {
            chart.stock_items = [];

              this.selectedPatient.stock_items.forEach(item=>{
                  if((chart.alias.alias_id == item.alias.alias_id) && item.quantity > 0){
                    this.addUniqueStock(chart, item)
                    chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                    console.log('found a matching dispensed and stock itemee', chart, item);
                    if(localStorage.getItem('DRET'+item.uuid)){
                        chart.removed = true;
                        console.log('found a match in local storage', chart);
                        item.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                        chart.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                    }
                  }
                  if(chart.dispensed_items && chart.dispensed_items.length){
                    chart.dispensed_items.forEach(ditem=>{
                      
                      if((item.alias.alias_id == ditem.alias_id) && item.quantity > 0){
                        this.addUniqueStock(chart, item)
                        chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                        console.log('found a matching dispensed and stock item', ditem, item);
                        
                        if(localStorage.getItem('DRET'+item.uuid)){
                            chart.removed = true;
                            console.log('found a match in local storage', chart);
                            item.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                            chart.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                        }
                      }
                      
                    })
                    console.log('going through patch and stock items', chart);
                      if(chart.alias.alias_id == item.alias.alias_id){
                        console.log('FOUND A MATCH through patch and stock items', chart);
                        this.addUniqueStock(chart, item)
                        
                      }
                  }
              })
              this.loadMims = true
              chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
          })
        } else if(PatchOrPRN == 'prn'){
          console.log('checking that prn is going through getStockRegister prn else if', this.selectedPatient, this.selectedPatientPRNs);
          if(this.selectedPatientPRNs && this.selectedPatientPRNs.length){
            this.selectedPatientPRNs.forEach(chart => {
              chart.stock_items = [];
              this.selectedPatient.stock_items.forEach(item=>{
                  if((chart.alias.alias_id == item.alias.alias_id) && item.quantity > 0){
                      this.addUniqueStock(chart, item)
                      chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                      console.log('found a matching dispensed and stock item', chart, item);
                      if(localStorage.getItem('DRET'+item.uuid)){
                          chart.removed = true;
                          console.log('found a match in local storage', chart);
                          item.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                          chart.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                      }
                    }
                  if(chart.dispensed_items && chart.dispensed_items.length){
                    this.selectedPatient.stock_items.forEach(item=>{
                        if(chart.dispensed_items && chart.dispensed_items.length){
                          chart.dispensed_items.forEach(ditem=>{
                            console.log('this is a dispensed item being looped', ditem.alias_id, item.alias.alias_id);
                            if((item.alias.alias_id == ditem.alias_id) && item.quantity > 0){
                              this.addUniqueStock(chart, item)
                              console.log('PRN found a matching dispensed and stock item', ditem, item);
                            }
                          })
                        } 
                        if(chart.alias.alias_id == item.alias.alias_id){
                          console.log('FOUND A MATCH through patch and stock items', chart);
                          this.addUniqueStock(chart, item)
                        }
                    })

                  } 
                })
            })
          }
          
        } else if(PatchOrPRN == 'patch'){
          console.log('running get stock register as PATCH');
          
          this.selectedPatientPatches.forEach(chart => {
            chart.stock_items = [];

              this.selectedPatient.stock_items.forEach(item=>{
                  if((chart.alias.alias_id == item.alias.alias_id) && item.quantity > 0){
                    this.addUniqueStock(chart, item)
                    chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                    console.log('found a matching dispensed and stock itemee', chart, item);
                    if(localStorage.getItem('DRET'+item.uuid)){
                        chart.removed = true;
                        console.log('found a match in local storage', chart);
                        item.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                        chart.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                    }
                  }
                  if(chart.dispensed_items && chart.dispensed_items.length){
                    chart.dispensed_items.forEach(ditem=>{
                      
                      if((item.alias.alias_id == ditem.alias_id) && item.quantity > 0){
                        this.addUniqueStock(chart, item)
                        chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                        console.log('found a matching dispensed and stock item', ditem, item);
                        
                        if(localStorage.getItem('DRET'+item.uuid)){
                            chart.removed = true;
                            console.log('found a match in local storage', chart);
                            item.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                            chart.drug_safe_retrieval = JSON.parse(localStorage.getItem('DRET'+item.uuid)).drug_safe_id;
                        }
                      }
                      
                    })
                    console.log('going through patch and stock items', chart);
                      if(chart.alias.alias_id == item.alias.alias_id){
                        console.log('FOUND A MATCH through patch and stock items', chart);
                        this.addUniqueStock(chart, item)
                        
                      }
                      
                  } 
              })
              this.loadMims = true
              chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
          })
        } 
      })
    },
    addUniqueStock(chart, stock_item){
      let stock_ids = [];
      chart.stock_items.forEach(stock=>{
        if(stock.quantity > 0){
          stock_ids.push(stock.alias.alias_id)
        }
      })
      if(!stock_ids.includes(stock_item.alias.alias_id)){
        if(stock_item.quantity > 0){
          chart.stock_items.push(stock_item)
        }
      }
      console.log('these are the full stock ids for this chartStcok', stock_ids, stock_item.alias.alias_id)
     },
    checkMedicationChange(charts){
      // retrieve stored list of chart uuids (uuids are unique so it's fine to combine all patients in 1 list, gets cleared during logout)
      // validate that the charts retrieved within the round are not part of the stored list
      // remove the charts from the workable list when they are present in the stored list
      // validate that the workable list has charts that have changed <= 24 hours
      // present the charts to the user
      // save the list of presented charts to the stored list and save

      console.log("Running check med change function")
      let unstoredChartAlerts = [];

      if (localStorage.getItem('notifiedChartAlerts')) {
        this.notifiedChartAlerts = JSON.parse(localStorage.getItem('notifiedChartAlerts'))
      }

      charts.forEach(chart => {
        if (!this.notifiedChartAlerts.includes(chart.uuid)) {
          //check if prescription is an updated prescription
          if (chart.has_prescription_change) { 
            const chartDate = new Date(chart.created_at)
            const timeDifference = Date.now() - chartDate.getTime();
            // check if changed in last 24hours (miliseconds)
            if (timeDifference < 86400000) {
              unstoredChartAlerts.push(chart);
              this.notifiedChartAlerts.push(chart.uuid);
            }
          }
        }
      });
      console.log("New changed charts: ", unstoredChartAlerts)
      return unstoredChartAlerts;
    },
    retrieveExpiredCharts(){
      this.selectedPatientExpiredCharts = null;
      let payload = {
        patient_id: this.selectedPatientUUID, 
        start_date : this.selectedRound.start_window,
        end_date : this.selectedRound.stop_window,
        chart_types:null
      }
      axios.post('/retrieve-expired-charts', payload, this.$store.state.header).then(res=>{
        this.selectedPatientExpiredCharts = res.data;
        if(this.selectedPatientExpiredCharts.length){
          this.selectedPatientExpiredCharts.forEach(chart => {
              chart.stock_items = [];
              this.selectedPatient.stock_items.forEach(item=>{
                  if((chart.alias.alias_id == item.alias.alias_id) && item.quantity > 0){
                      this.addUniqueStock(chart, item)
                      chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                      console.log('found a matching dispensed and stock item', chart, item);
                    }
                  if(chart.dispensed_items && chart.dispensed_items.length){
                    this.selectedPatient.stock_items.forEach(item=>{
                        if(chart.dispensed_items && chart.dispensed_items.length){
                          chart.dispensed_items.forEach(ditem=>{
                            console.log('this is a dispensed item being looped', ditem.alias_id, item.alias.alias_id);
                            if((item.alias.alias_id == ditem.alias_id) && item.quantity > 0){
                              this.addUniqueStock(chart, item)
                              console.log('PRN found a matching dispensed and stock item', ditem, item);
                            }
                          })
                        } 
                        if(chart.alias.alias_id == item.alias.alias_id){
                          console.log('FOUND A MATCH through patch and stock items', chart);
                          this.addUniqueStock(chart, item)
                        }
                    })

                  } 
                })
            })
        }
      }).catch(err=>{
      })
    },
    retrievePRNExpiredCharts(uuid){
      console.log('retrieving expired PRN charts', uuid);
      this.selectedPatientExpiredCharts = null;
      axios.post('/retrieve-expired-charts', {patient_id: uuid, chart_types: ['prn'], start_date: null, stop_date: null}, this.$store.state.header).then(res=>{
        this.selectedPatientExpiredCharts = res.data;
        if(this.selectedPatientExpiredCharts.length){
          this.selectedPatientExpiredCharts.forEach(chart => {
              chart.stock_items = [];
              this.selectedPatient.stock_items.forEach(item=>{
                  if((chart.alias.alias_id == item.alias.alias_id) && item.quantity > 0){
                      this.addUniqueStock(chart, item)
                      chart.stock_items = chart.stock_items.filter((item, index) => chart.stock_items.indexOf(item) === index);
                      console.log('found a matching dispensed and stock item', chart, item);
                    }
                  if(chart.dispensed_items && chart.dispensed_items.length){
                    this.selectedPatient.stock_items.forEach(item=>{
                        if(chart.dispensed_items && chart.dispensed_items.length){
                          chart.dispensed_items.forEach(ditem=>{
                            console.log('this is a dispensed item being looped', ditem.alias_id, item.alias.alias_id);
                            if((item.alias.alias_id == ditem.alias_id) && item.quantity > 0){
                              this.addUniqueStock(chart, item)
                              console.log('PRN found a matching dispensed and stock item', ditem, item);
                            }
                          })
                        } 
                        if(chart.alias.alias_id == item.alias.alias_id){
                          console.log('FOUND A MATCH through patch and stock items', chart);
                          this.addUniqueStock(chart, item)
                        }
                    })

                  } 
                })
            })
        } 
      }).catch(err=>{
        
      })
    },
    selectPatient(value) {
      // abort any previous patient data from being loaded
      if (this.AxiosController != null) {
        this.AxiosController.abort()
      }
      this.$store.dispatch('getRefreshToken');
      this.recentlyChangedCharts = []
      this.selectedPatientCharts = null;
      this.loadingResidentDrugs = true;
      this.AxiosController = new AbortController()
      if (value && this.selectedTab != 'NIM' && this.selectedTab != 'PATCH') {
        console.log("METHOD 1 (PRN)")
        this.selectedPatient = {};
        this.loadingPatient = true;
        this.selectedPatientUUID = value.uuid ? value.uuid : value;
        
        console.log('this is the selected patient', value);
        let prns = value.prns ? value.prns : '';
        axios.post('/retrieve-patient',
        {
            patient_id: this.selectedPatientUUID,
            location: this.$store.state.uuid,
        },
        {
          headers: this.$store.state.header.headers,
          signal: this.AxiosController?.signal
        })
        .then(res => {
            this.$store.dispatch('getRefreshToken');  

            
            this.selectedPatient = res.data;
            

            // storing special considerations
            this.spec_cons = [];
            if(this.selectedPatient.considerations && this.selectedPatient.considerations.length){
                this.spec_cons = this.selectedPatient.considerations[this.selectedPatient.considerations.length -1];
            }

            this.selectedPatient.displayPRN = false;
            this.selectedPatient.displayNIM = false;
            this.selectedPatient.displayPatch = false;
            this.selectedPatient.personal_information.age = this.getAge(this.selectedPatient.personal_information.dob);

            this.selectedPatient.prns = prns;
            this.selectedPatientPRNs = prns;
            this.getStockRegister(this.selectedPatientUUID, 'prn');
            
            this.selectedPatient.readings = res.data.readings;
            this.loadingPatient = false;
            let payload = {
              patient_id: this.selectedPatientUUID,
              start_date: this.form.start_date,
              end_date: this.form.end_date,
            }

            // PRN recent changed charts check
            if(this.selectedPatientPRNs && this.selectedTab == 'PRN') {
              console.log("recentlyChangedCharts PRN")
              this.recentlyChangedCharts = this.checkMedicationChange(this.selectedPatientPRNs);
              this.displayRecentlyChangedCharts = this.recentlyChangedCharts.length > 0;
              this.retrievePRNExpiredCharts(this.selectedPatientUUID);
            }

            if(this.selectedTab != 'PRN'){
              console.log("METHOD 2 (ADMIN ROUND)")
              axios.post(`/get-admin-round-charts`, payload, {headers: this.$store.state.header.headers, signal: this.AxiosController?.signal})
              .then(res=>{
                console.log('this is the res for resident charts', res.data);
                if(this.$store.state.user?.role?.permissions?.includes('can_do_resupply')){
                  axios.post('/resupply/pending-list', {}, this.$store.state.header).then(res1=>{
                    console.log('this is the pending list', res1.data);
                    this.requestedMeds = res1.data;
                  })
                }
                this.loadingResidentDrugs = false;
                this.getStockRegister(this.selectedPatientUUID);
                
                this.selectedPatientCharts = res.data;
                console.log('this is the sleected patient stock items', this.selectedPatient.stock_items);
                // NORMAL ADMIN recent changed charts check
                this.recentlyChangedCharts = this.checkMedicationChange(res.data);
                console.log('this is the recently changed charts', this.recentlyChangedCharts);
                this.displayRecentlyChangedCharts = this.recentlyChangedCharts.length > 0;
                this.selectedPatientCharts.forEach(chart => {
                  if (chart.latest_administration) {
                    chart.completed = true; // chart completed whether admin DOC'd or DONE
                    chart.doc = chart.latest_administration.doc;
                  } else chart.completed = false; // otherwise assume no chart completed
                  // NORMAL ADMIN recent changed charts check
                  this.recentlyChangedCharts = this.checkMedicationChange(res.data);
                  this.displayRecentlyChangedCharts = this.recentlyChangedCharts.length > 0;


                  if(this.requestedMeds.includes(chart.prescription_id)){
                    console.log('this DOES match the requested meds', chart.prescription_id, this.requestedMeds)
                    chart.requestedSupply = true;
                  } else{
                    console.log('this DOES NOT match the requested meds', chart.prescription_id, this.requestedMeds)
                  }
                  
                })
                this.retrieveExpiredCharts()
                // this.loadingResidentDrugs = false;
              }).catch(err=>{
                console.log('this is the error for get admin round charts', err.response);
              })
            } 
            console.log('this is the res data for retrieve patient', res.data, this.selectedPatient);
          }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
            }
          })
      } else if (value && this.selectedTab == 'NIM') {
        console.log("METHOD 3 (NIM)")
        // this.selectedPatient = value;
        console.log('DOING IT THIS WAY');
        this.selectedPatient = {};
        this.loadingPatient = true;
        this.selectedPatientUUID = value.uuid ? value.uuid : value;

        let nims = value.nims ? value.nims : '';
        console.log('this is the selected patient', value, nims);
        axios.post('/retrieve-patient',
        {
            patient_id: this.selectedPatientUUID,
            location: this.$store.state.uuid,
        },
        {
          headers: this.$store.state.header.headers,
          signal: this.AxiosController?.signal
        })
        .then(res => {
            this.$store.dispatch('getRefreshToken');
            this.selectedPatient = res.data;
            this.selectedPatient.personal_information.age = this.getAge(this.selectedPatient.personal_information.dob);

            // storing special considerations
            this.spec_cons = [];
            if(this.selectedPatient.considerations && this.selectedPatient.considerations.length){
                this.spec_cons = this.selectedPatient.considerations[this.selectedPatient.considerations.length -1];
            }

            this.selectedPatient.nims = nims;
            this.getStockRegister(this.selectedPatientUUID);
            this.selectedPatientNIMs = nims;


            this.loadingPatient = false;
            console.log('this is the res data for retrieve patient', res.data, this.selectedPatient);
          }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
            }
          })
      } else if (value && this.selectedTab == 'PATCH') {
        console.log("METHOD 4 (PATCH)")
        // this.selectedPatient = value;
        console.log('DOING IT THIS WAY');
        this.selectedPatient = {};
        this.loadingPatient = true;
        this.selectedPatientUUID = value.uuid ? value.uuid : value;

        let patches = value.patches ? value.patches : '';
        console.log('this is the selected patient', value, patches);
        axios.post('/retrieve-patient', {
          patient_id: this.selectedPatientUUID,
          location: this.$store.state.uuid,
        }, {headers: this.$store.state.header.headers, signal: this.AxiosController?.signal}).then(res => {
            this.$store.dispatch('getRefreshToken');
            this.selectedPatient = res.data;
            
            this.selectedPatient.personal_information.age = this.getAge(this.selectedPatient.personal_information.dob);

            // storing special considerations
            this.spec_cons = [];
            if(this.selectedPatient.considerations && this.selectedPatient.considerations.length){
                this.spec_cons = this.selectedPatient.considerations[this.selectedPatient.considerations.length -1];
            }
            
            this.selectedPatient.patches = patches;
            this.selectedPatientPatches = patches;
            this.getStockRegister(this.selectedPatientUUID, 'patch');

            // PATCH ADMIN recent changed charts check
            this.recentlyChangedCharts = this.checkMedicationChange(this.selectedPatientPatches);
            this.displayRecentlyChangedCharts = this.recentlyChangedCharts.length > 0;
            
            this.loadingPatient = false;
            console.log('this is the res data for retrieve patient', res.data, this.selectedPatient);
          }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
            }
          })
      }
    },
    openSummary(id) {
      this.$store.dispatch('getRefreshToken');
      this.expandModal = true;
      this.patientId = id;
    },
    close(value) {
      this.$store.dispatch('getRefreshToken');
      if (value) {
        this.expandModal = false;
        this.displaySuccessModal = false;
        this.displaySignatureModal = false;
        this.displayRecentlyChangedCharts = false;
        this.retrievePRNs(true);
      }
    },
    isCompleted(value) {
      this.$store.dispatch('getRefreshToken');
      if (value.item != -1) {
        console.log('this is the value from isCompleted', value);

        let allCharts = this.selectedPatientCharts.length;
        let chartProgress = 0;
        this.selectedPatientCharts.forEach(chart => {
          if (chart.uuid == value.item) {
            chart.completed = true;
          }
          if (chart.completed) {
            chartProgress += 1;
          }
          console.log('all charts vs chartprogress', allCharts, chartProgress, allCharts == chartProgress);
          setTimeout(() => {
            if (allCharts == chartProgress) {
              console.log('MATCHING COMPLETE STATE', this.selectedPatient);
              this.selectedPatient.completed = true;
              this.adminCharts.forEach(person => {
                if (person.selected) {
                  person.completed = true;
                }
              })
            }
          }, 500)

        })
        this.selectedPatient.error_messages = this.selectedPatientCharts.filter((chart, index, self) =>
          index === self.findIndex((t) => (
            t.status === chart.status
          ))
        )
      }
    },
    selectDOC(drug) {
      this.$store.dispatch('getRefreshToken');
      drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
      console.log('this is the drug doc and object', drug.status, drug);
    },
    changeLabel(loc) {
      this.$store.dispatch('getRefreshToken');
      if (loc.children.length) {
        loc.children.forEach(child => {
          if (!child.label) {
            child.data = child.name;
          }

          if (child.children.length) {
            setTimeout(() => {
              this.changeLabel(child);
            }, 100);
          }
        })
      }
    },
    getLocations() {
      this.$store.dispatch('getRefreshToken');
      if (this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid) {
        axios.post(`/facility/${this.$store.state.currentLocation.facility.uuid}/locations`, {}, this.$store.state.header).then(res => {
          this.locations = res.data;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
      } else {
        axios.post(`/facility/${this.$store.state.currentLocation.uuid}/locations`, {}, this.$store.state.header).then(res => {
          this.locations = res.data;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
      }
    },
    primeSignature(drug, signStat) {
      this.$store.dispatch('getRefreshToken');
      if (signStat == 'reject') {
        drug.sign = true;
        drug.status = '';
        document.getElementById(drug.chart_id + '-' + signStat).style.backgroundColor = '#E04F39';
        document.getElementById(drug.chart_id + '-' + signStat).style.color = 'white';
      }
      if (signStat == "approve") {
        drug.sign = true;
        drug.status = 'approve';
        document.getElementById(drug.chart_id + '-' + signStat).style.backgroundColor = '#00AD50';
        document.getElementById(drug.chart_id + '-' + signStat).style.color = 'white';
      }

    },
    clearChartFilter() {
      this.$store.dispatch('getRefreshToken');
      this.searchedDrug = '';
      this.searchFilter = {
        location: this.$store.state.uuid,
        chart_type: '',
        sign_filter: 'all',
        drug_alias: ''
      }
      document.getElementById('chart-type').value = null
    },
    searchActiveCharts() {
    },
    getAdminRoundTimes() {
      setTimeout(() => {
        console.log('this is the dynamic stuff', this.$store.state.currentLocation.facility.uuid);
        this.loadingRounds = true
        axios.post('/get-round-times', { facility_id: this.$store.state.currentLocation.facility.uuid }, this.$store.state.header).then(res => {
          console.log('these are the round times', res.data);
          this.rounds = res.data;
          this.loadingRounds = false
          // this.rounds.unshift({
          //   name: 'Current - (-1hr) - (+3hrs)',
          //   value: ''
          // })
          // this.selectedRound = this.rounds[0];
        })
        .catch(err => {
          this.loadingRounds = false
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
      }, 1000)
    },
    sortRooms(rooms){
      //takes the room name and splits at first non-numeric value
      //the result is an array of [N, K], where N is the number and K is the string. Either can be empty
      //runs a merge sort on the array of split arrays, using for comparison the value of N + K where K is an integer mapping of the string
      //in accordance to the alphabeticMap object

      //leave commented stuff just in case, but if works properly (tested on a bunch of different variations/combos),
      //then can be deleted
      // let split_rooms_array = [];
      // for (let i = 0; i < rooms.length; i++) {
      //   const [, ...split_room] = rooms[i].location.name.match(/(\d*)([\s\S]*)/);
      //   //const [, ...split_room] = rooms[i].match(/(\d*)([\s\S]*)/);

      //   split_rooms_array.push(split_room);

      // }

      const merge = (left, right) => {
      let sortedArr = []; // the sorted elements will go here

      while (left.length && right.length) {
        // insert the smallest element to the sortedArr
        //temp values are for cases where there is a letter next to a number

        let temp_left = JSON.parse(JSON.stringify(split_value(left[0].location.name)));
        let temp_right = JSON.parse(JSON.stringify(split_value(right[0].location.name)));

        //temp left

        if (temp_left[0][0] != '') {
          temp_left[0][0] = parseInt(temp_left[0][0], 10);
        }

        if (temp_left[0][1] != '') {
          let sec_as_hexa = parseInt(this.alphabeticMap[temp_left[0][1][0]]); //first char

          temp_left[0][0] = parseInt(temp_left[0][0] += sec_as_hexa)
        }
        if (temp_left[0][0] == '' && temp_left[0][1] == '') {
          temp_left[0][0] = 54
        }

        //temp right

        if (temp_right[0][0] != '') {
          temp_right[0][0] = parseInt(temp_right, 10);
        }

        if (temp_right[0][1] != '') {
          let sec_as_hexa = parseInt(this.alphabeticMap[temp_right[0][1][0]]); //first char

          temp_right[0][0] = parseInt(temp_right[0][0] += sec_as_hexa)
        }
        if (temp_right[0][0] == '' && temp_right[0][1] == '') {
          temp_right[0][0] = 54
        }


        if (parseInt(temp_left[0][0]) < parseInt(temp_right[0][0])) {
          sortedArr.push(left.shift());
        } else {
          sortedArr.push(right.shift());
        }
      }

      // use spread operator to combine the three arrays as one
      return [...sortedArr, ...left, ...right];
    }

    const mergeSort = (array) => {
      const half = array.length / 2;

      // the base case is array length <=1
      if (array.length <= 1) {
        return array;
      }

      const left = array.splice(0, half); // the first half of the array
      const right = array;
      return merge(mergeSort(left), mergeSort(right));
    }

    const split_value = (value) => {
      //const [, ...split_room] = rooms[i].location.name.match(/(\d*)([\s\S]*)/);
      const [, ...split_room] = value.match(/(\d*)([\s\S]*)/);
      //the array is because of a change in merge(), it was simpler to change this one line rather than
      //changing about 20 lines in that function to accomodate for the number of dimensions in the array returned
      //due to the fact that it is now sorting the entire adminCharts array vs just the rooms array
      let split_room_array = []
      split_room_array.push(split_room);

      return split_room_array;

    }

      let sorted_rooms = mergeSort(rooms)

      return sorted_rooms;

    }
  },
  mounted() {
    this.getLocations();
    this.getAdminRoundTimes();
  },
  computed: {
    getPatients() {
      return this.$store.getters.getPatients;
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.select-filters-container {
  p {
    font-size: 15px;
  }
}

.p-dropdown.admin-rooms-dropdown {
  background: #F3F4F4;
  border: none;
}

.admin-chart-form {
  background-color: white;
  border-radius: 12px;

}

.admin-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  h4 {
    color: $strongblue;
  }

  border-bottom: 2px solid $bordergrey;
  width: 100%;
}

.admin-chart-drug-list-table {
  padding: 0 20px;

  td {
    padding: 0;
  }
}

.pi-info-circle {
  color: $strongblue;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 1.3em !important;
  font-weight: bold;
  cursor: pointer;
}

.admin-chart-signature-button {
  background-color: $strongblue;
  color: white;
  height: 27px;
  font-weight: bold;
  margin-left: 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.doc-select-container {
  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  }
}

.completed-chart-text {
  color: white;
  font-weight: 500;
  margin: 0;
  font-size: 14.5px;
  padding: 0 10px;
  text-align: left
}

.completed-chart-text:first-of-type {
  margin-top: 10px;
}

.completed-chart-text:last-of-type {
  margin-bottom: 10px;
}
</style>