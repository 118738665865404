<template>
    <div class="modal-mask">
        <div class="modal-wrapper" v-if="$store.state.company.type != 'warehouse'">
        <!-- <div class="modal-container" style="width: 30%;" :class="[toAction.action == 'Approve' ? 'approve-container': 'reject-container']">

            <div class="modal-body">
              <slot name="body">
                <form   class="action-modal">

                    <div class="section-container">
                        <div class="notes-modal-section">
                            <p style="font-weight: 500; text-align: left; margin: 0; padding-left: 10px; color: black">Notes</p>
                            <textarea name="notes" id="note-modal" cols="95" :rows="[delivery ? 6 : 8]" placeholder="Enter notes here" v-model="form.submit.notes"></textarea>
                            
                        </div>
                    </div>



                </form>
              </slot>
            </div>

            <div class="modal-footer" style="grid-gap: 50px; grid-template-columns: 1fr;text-align: center;">
              <slot name="footer" style="display: flex;">
                  <p style="cursor: pointer; margin: 10px" @click="submitTransferAction()">Proceed to {{toAction.action}} {{delivery ? 'Delivery' : 'Transfer'}}</p>
                  <button @click="closeModal" 
                  style="background-color: white;
                        padding: 0px;
                        position: absolute;
                        margin-top: -30px;
                        margin-left: 34em;"><i class="pi pi-times"></i></button>
              </slot>
            </div>
          </div> -->
        <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{ toAction.action }} Entry</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

        <!-- BASIC INFO -->
        <!-- BASIC INFO -->
        <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p class="note-header">Notes</p>
                                        <textarea name="notes" id="note-modal"
                                            style="border-top-right-radius: 0px; border-top-left-radius: 0px;"
                                            cols="110" rows="16" placeholder="Enter notes here..."
                                            v-model="form.submit.notes"></textarea>
                                    </div>
                                </div>

        <!-- RIGHT SIDE PANEL -->
        <!-- <div class="right-form-section-panel" style="display: flex; flex-direction: column; justify-content: space-between;">
                            
                            <div class="right-form-section-drug-container" >
                                <table style="border-collapse: collapse">
                                    
                                    <tr v-for="(item, index) in payload.items" :key="index" class="drug-entry-transfer">
                                        <td style="width: 50%;"><p>{{item.alias.name}} {{Number(item.alias.strength).toFixed(1)}}{{item.alias.form_description}}</p></td>
                                        <td><p>{{item.quantity}} {{item.alias.form}}{{item.quantity > 1 ? 's':''}}</p></td>
                                    </tr>
                                </table>
                            </div>
                            
                        </div> -->
        </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" @click="submitTransferAction()">Submit</p>
                    </slot>
                </div>
            </div>
        </div>
          <div class="modal-wrapper" v-if="$store.state.company.type == 'warehouse'">
                <div class="modal-container"
                    style="min-width: 43.25rem; width: 43.25rem;border: 4px solid #00AD50;border-radius: 12px;">
                    <div class="modal-header">
                        <slot name="header">
                            <div>
                                <h3 class="form-header" style="color: #282828;">{{ toAction.action }} Entry</h3>
                            </div>
                            <button @click="closeModal(0)" class="red-close-button" style="width: 30px !important">
                                <i class="pi pi-times" style="padding: 0"></i>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body" style="padding: 20px 20px 0px 20px; text-align: left">
                        <slot name="body">
                            <div class="left-form-section-panel">
                                <p class="note-header">Note</p>
                                <textarea placeholder="Enter notes here..." v-model="form.submit.notes" id="note-modal"
                                    rows="16"
                                    style="width: 100%;border-top-right-radius: 0px;border-top-left-radius: 0px;"></textarea>
                            </div>
                        </slot>
                    </div>
                    <p style="color: red" v-if="errorMessage">{{ errorMessage }}</p>
                    <div class="modal-footer"
                        style="display: flex; justify-content: flex-end; padding: 10px 20px;margin: 5px 0px;">
                        <div style="display: flex; gap: 10px">
                            <button class="button pending-button" @click="closeModal(0)">Cancel</button>
                            <button class="button completed-button" style="min-width: max-content;padding: 0px 55px"
                                @click="submitTransferAction()">Submit</button>
                        </div>
                    </div>
                </div>
            </div> 
        <SignatureModal
            :endpoint="endpoint ? endpoint : delivery ? '/single-delivery/action' : toAction.action == 'Approve' ? '/single-transfer/approve' : '/single-transfer/reverse'"
            v-if="displaySignatureModal" dual="true" @isSuccessful="isSuccessful" @close="closeSigModal"
            :payload="form.submit" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
// import axios from 'axios';

export default {
    props: ["toAction", 'display', 'delivery', 'drugs', 'endpoint', 'drugReg'],
    components: {
        // DualVerify
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            form: {
                submit: {
                    notes: '',
                    uuid: ''
                }
            },
            reference_number: '',
            date_received: '',
        }
    },
    methods: {
        closeSigModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        isSuccessful(value) {
                console.log('successful submission', value);
                this.closeModal();
                this.$emit('isSuccessful', true);
            
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        submitTransferAction() {
            if (this.toAction.action == 'Reverse') {
                if(this.toAction.unit_uuids){
                    this.form.submit.unit_uuids = this.toAction.unit_uuids;
                }else{
                    this.form.submit.uuid = this.toAction.uuid
                }
                
            }
            console.log('is this a delivery', this.delivery);
            this.form.submit.uuid = this.toAction.uuid;
            if (this.delivery) {
                this.form.submit.action = 'reverse';
            }
            this.displaySignatureModal = true;

        }
    },
    mounted() {
        console.log('this is the display from action modal', this.display);
        if (this.toAction && this.toAction.action != 'Reverse') {
            console.log('this is the toaction', this.toAction.note);
            this.form.submit.notes = this.toAction.note
        }
    },
    computed: {
        getNotes() {
            return this.toAction.note

        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
