<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <h3 class="form-header">Required Action</h3>
                    <button @click="cancelAction()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <div v-if="questions.length == 0" style="style: flex;flex-direction: column; margin: 0 auto; gap: 5px, align-items: center;">
                            <i class="pi pi-spin pi-spinner"
                                style="font-size: 2rem !important;margin-top: 2em; margin-bottom: 10px;"></i>
                        </div>
                        <div v-else style="display: flex;margin: 20px;padding: 0px 15px 0px 15px;flex-direction: column;">
                            <div style="display: grid; grid-template-columns: 75% 15%;margin-bottom: 10px;gap: 10%;"
                                v-for="(question, index) in questions" :key="question.uuid">
                                <!-- question -->
                                <div style="display: flex; align-items: start; gap: 10px;">
                                    <label style="font-weight: bold;text-align: left;">{{ index + 1 }}. </label>
                                    <label style="text-align: left;">{{ question.question }}</label>
                                </div>
                                <!-- answer -->
                                <div class="p-inputgroup">
                                    <Button @click="fillQuestion('0', index)"
                                        style="margin-left: 10px; height: 35px;border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                                        label='No'
                                        :class="question.answer == '0' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />

                                    <Button @click="fillQuestion('1', index)"
                                        style="border-left: none;border-top-left-radius: 0px !important;border-bottom-left-radius: 0px !important;height: 35px;"
                                        label="Yes"
                                        :class="question.answer == '1' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>



                <div class="modal-footer" style="display: flex; justify-content:flex-end; padding: 5px 20px;">
                    <slot v-if="questions.length != 0"  name="footer" style="display: flex;">
                        <button style="margin-right: 10px;" class="grey-button" @click="cancelAction()">
                            Cancel
                        </button>
                        <button :disabled="!allComplete"
                            :class="{ 'regular-button complete-button': allComplete, 'grey-button': !allComplete }"
                            @click="completeQuestionnaire()">
                            Continue
                        </button>
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            questions: []
        }
    },
    methods: {
        cancelAction() {
            this.$emit('close', true);
        },
        completeQuestionnaire() {
            // format the response 
            const questionsSubmission = this.questions.map(question => ({question_id : question.uuid, answer: question.answer}))
            console.log(questionsSubmission)
            this.$emit('bubbleQestions', questionsSubmission)
            this.$emit('continue', '')
        },
        fillQuestion(response, index) {
            this.questions[index].answer = response
        }
    },
    computed: {
        allComplete() {
            const incomplete = this.questions.filter(question => question.answer == null)
            return incomplete.length > 0 ? false : true
        }
    },
    beforeMount() {
        axios.post(`/get-prescription-questions`, { tag_name: "Anti-infective agent" }, this.$store.state.header).then(res => {
            this.questions = res.data
        }).catch(err => {
           console.log(`Error: ${err.response.data}`);
        })
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";





.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}



.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
