<template>
  <div class="nav-functions">
    <!-- <p style="margin-right: 5px; color: white">V0.9.13</p> -->

    <router-link to="/patient/new" v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacist' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_do_resident') && !$store.state.user.integration_id && $store.state.company.type != 'warehouse'" class="new-patient-button" v-tooltip.top="'Add Resident'" >
      <!-- <p><span style="font-size: 1.3em; font-weight: bold">+</span> Add Resident</p> -->
      <i class="pi pi-user-plus" style="font-size: 1.2em !important; padding: 5px" ></i>
    </router-link>
    <!-- <div v-tooltip.bottom="'View Notifications'" :style="$store.state.displayNotifications ? {backgroundColor: '#2c3e50'}:{backgroundColor: 'white'}"  class="new-patient-button" style="margin-left: 10px; cursor:pointer" @click="toggleNotificationPanel()">
      <i class="pi pi-bell" :style="!$store.state.displayNotifications ? {color: '#2c3e50'}:{color: 'white'}" style="font-size: 1.2em !important; padding: 5px"  ></i>
      <span style="color: white; background-color: #0870DC; border-radius: 8px; padding: 3px 5px; font-size: 13px" v-if="$store.state.numOfNotifications">{{ $store.state.numOfNotifications }}</span>
    </div> -->
    <a v-if="$store.state.company.type == 'warehouse'" href="https://help.strongroom.ai/knowledge-base-strongshed" target="_blank" style="text-decoration: none">
      <div v-tooltip.top="'Visit Knowledge Hub'" class="new-patient-button" style="margin-left: 10px; cursor:pointer;" >
        
          <!-- <i class="pi pi-question" style="font-size: 1.2em !important; padding: 5px;"  ></i> -->
          <p style="font-size: 1.2em !important; padding: 5px; font-weight: normal">?</p>
      </div>
    </a>
    <a v-else href="https://help.strongroom.ai/knowledge-base-strongcare" target="_blank" style="text-decoration: none">
      <div v-tooltip.top="'Visit Knowledge Hub'" class="new-patient-button" style="margin-left: 10px; cursor:pointer;" >
        
          <!-- <i class="pi pi-question" style="font-size: 1.2em !important; padding: 5px;"  ></i> -->
          <p style="font-size: 1.2em !important; padding: 5px; font-weight: normal">?</p>
      </div>
    </a>
    <div v-tooltip.top="'View Alerts'" v-if="$store.state.userType != 'pharmacist' && $store.state.userType != 'pharmacy'" :style="$store.state.displayAlerts ? {backgroundColor: '#2c3e50'}:{backgroundColor: 'white'}"  class="new-patient-button" style="margin-left: 10px; cursor:pointer" @click="toggleAlertsPanel()">
      <i class="pi pi-exclamation-circle" v-if="!$store.state.loadingAlerts" :style="!$store.state.displayAlerts ? {color: '#2c3e50'}:{color: 'white'}" style="font-size: 1.2em !important; padding: 5px"  ></i>
      <i class="pi pi-spin pi-refresh" v-else :style="$store.state.displayAlerts ? {color: 'white'}:{color: '#2c3e50'}"></i>
      <span style="color: white; background-color: #E04F39; border-radius: 8px; padding: 3px 5px; font-size: 13px" v-if="numAlerts && numAlerts.length && alertsOnly.length && !$store.state.loadingAlerts">{{alertsOnly.length}}</span>
    </div>
    <!-- <div  @click="swapLocations()">
      <p v-tooltip.top="'Change Location'" class="new-patient-button"  style="margin: 0; margin-left: 10px; justify-content: center; cursor:pointer" :style="[displayLocations ? {borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px'}:{}]">{{$store.state.currentLocation ? $store.state.currentLocation.name : ''}}
      <i class="pi pi-sort-alt" style="transform: rotate(90deg); cursor:pointer"></i>
      </p>
      <div v-if="displayLocations" 
      style="background-color: white;margin-left: 10px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; box-shadow: 0 0 3px #c9c9c9; position: relative; z-index: 10">
        <p style='margin: 0;  padding: 5px; cursor:pointer; text-align: left' @click="changeLocation(item)" v-for="(item, index) in $store.state.availableLocations" :key="index">{{item ? item.facility.name + '- ' + item.name : ''}}</p>
      </div>
    </div> -->
    <!-- https://help.strongroom.ai/knowledge-base-strongcare -->
    
    <div v-tooltip.top="'User Menu'"  @click.stop.prevent="toggleMenu" class="new-patient-button" style="margin-left: 10px; cursor:pointer">
      <!-- <Button type="button" label="Toggle" aria-haspopup="true" aria-controls="overlay_tmenu" /> -->
      <p style="font-weight: 900" id="actions"><i class="pi pi-bars" style="font-size: 1.2em !important; padding: 5px" ></i></p>
      <!-- <TieredMenu ref="menu" id="overlay_tmenu" :model="items" popup /> -->
      <ContextMenu
        ref="menu"
        :model="items"
    />
    </div>
    
    
    
    <!-- <router-link to="/login" @click="eraseLoginDetails()" v-tooltip.bottom="'Log out'" style="text-decoration: none">
    <i class="pi pi-sign-out new-patient-button" style="background-color: #E04F39; color: white;margin: 0;margin-left: 10px; padding: 10px 15px; padding-top:12px"></i>
    </router-link> -->
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'nav-functions',
  data() {
    return {
      displayLocations: false,
      items: [
        {
          label: this.$store.state.currentLocation? this.$store.state.currentLocation?.name : 'N/A',
          icon: 'pi pi-arrow-right-arrow-left',
          title: 'Change Location',
          items:[

          ],
          // icon: 'pi pi-fw pi-power-off',
          command: () => {
          }
        },
        
      ]
    }
  },
  methods:{
    hello(){
      console.log('hello');
    },
    toggleMenu(){
      const el = document.querySelector('#actions');
      const coords = el.getBoundingClientRect();

      const event = new PointerEvent('click', {
          clientX: (coords.x + coords.width+10),
          clientY: (coords.y + coords.height+12),
      });

      this.$refs.menu.toggle(event);
    },
    // toggle(event) {
    //   this.$refs.menu.toggle(event);
    // },
    toggleNotificationPanel() {
      this.$store.commit('setDisplayNotifications', !this.$store.state.displayNotifications)
    },
    toggleAlertsPanel() {
      this.$store.commit('setDisplayAlerts', !this.$store.state.displayAlerts);
      // if(this.$store.state.displayAlerts){
      //   this.$store.dispatch('getAlerts');
      // }
      
    },
    swapLocations() {
      this.displayLocations = !this.displayLocations;
    },
    changeLocation(location) {
      localStorage.setItem('currentLocation', JSON.stringify(location));
      window.location.reload();
      this.$store.commit('setLocation', JSON.parse(localStorage.getItem('currentLocation')));
      
      this.displayLocations = false;
    },
    eraseLoginDetails() {
      axios.post('/logout', {}, this.$store.state.header).then(res => {
        console.log('this is the logout res', res.data);
      }).catch(err => {
        console.log('error is', err.response.data);
      })

      // Remove the username and company if rememberUsername is not set
      if (!localStorage.getItem('rememberUsername') || localStorage.getItem('rememberUsername') !== 'true') {
        localStorage.removeItem('username');
        localStorage.removeItem('company');
      }

      localStorage.removeItem('locations');
      localStorage.removeItem('currentLocation');
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      localStorage.removeItem('lastRefresh');
      localStorage.removeItem('direct_to_mfa_settings');
      localStorage.removeItem('company_mfa');
      localStorage.removeItem('savedform');
      localStorage.removeItem('notifiedChartAlerts');
      // Remove the MIMS API Key from storage
      localStorage.removeItem('mimsApiKey');
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$store.commit('setAuthorization');

      this.$router.push('/login');
    }
  },
  mounted(){
    if(this.$store.state.availableLocations.length){
      console.log('there are multiple locations');
      this.$store.state.availableLocations.forEach(location=>{
        let loc = {uuid: location.uuid, label: location.label, facility: location.facility, command: () => this.changeLocation(location)}
        
        this.items[0].items.push(loc)
      });
      
    }
    if(this.$store.state.user?.role?.permissions?.includes('can_view_resupply')){
      if (this.$store.state.company.type == 'warehouse') {
        this.items.push({
            label: 'Log Out',
            icon: 'pi pi-power-off',
            command: () => {
              this.eraseLoginDetails()
            }
          },
          { separator: true },
          {
            label: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
            disabled: true
          })
      } else {
        this.items.push({
          label: 'Resupply List',
          icon: 'pi pi-list',
          command: () => this.$router.push('/resupply')
        },
          {
            label: 'Log Out',
            icon: 'pi pi-power-off',
            command: () => {
              this.eraseLoginDetails()
            }
          },
          { separator: true },
          {
            label: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
            disabled: true
          })
      }
    } else{
      this.items.push({
          label: 'Log Out',
          icon: 'pi pi-power-off',
          command: () => {
            this.eraseLoginDetails()
          }
        },
        {separator: true},
        {
          label: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
          disabled: true
        })
    }
  },
  computed:{
    numAlerts(){
      if(this.$store.state.alerts){
        return this.$store.state.alerts.filter(item=> !item.read);
      } else{
        return null
      }
      
    },
    alertsOnly(){
        if(this.$store.state.alerts){
            return this.$store.state.alerts.filter(item=> item.channel != 'alert_intermittent_order' && !item.read)
        } else{
            return []
        }
        
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .nav-functions {
    display: flex;
    align-items:flex-start;
  }
  .p-menu {
    top: 85px !important;
  }
</style>
