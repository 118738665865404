<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Indications and Streamline Codes</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr;padding-right:20px">
                                <div class="left-form-section-panel">

                                            <table class="data-table" cellspacing="0" style="max-height: 50vh; overflow: scroll">
                                                <tr class="table-headers">
                                                    <th style="width: 50px;"></th>
                                                    <th style="width: 60px;">Select</th>
                                                    <th style="width: 140px;">Prescribers</th>
                                                    <th style="width: 80px;">PBS Item Code</th>
                                                    <th style="width: 100px">
                                                        <h3 style="padding-right: 10px;">Approval Code</h3>
                                                    </th>
                                                    <th style="width: 80px">
                                                        <h3 >Max Qty Packs</h3>
                                                    </th>
                                                    <th style="width: 80px">
                                                        <h3 >Max Qty Units</h3>
                                                    </th>
                                                    <th style="width: 80px"> 
                                                        <h3>No of Repeats</h3>
                                                    </th>
                                                    <th >
                                                        <h3>Clinical Criteria</h3>
                                                    </th>
                                                </tr>
                                                <tbody class="table-data"  v-for="(item, index) in streamlineOptions" :key="index+'stream'">
                                                    <tr @click.stop="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']" >
                                                        <td style="min-width: 50px;">
                                                            <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                                                            <i v-else class="pi pi-angle-right"></i>
                                                        </td>
                                                        <!-- Select -->
                                                        <td><RadioButton :value="item" v-model="selectedCode" /></td>
                                                        <!-- Prescribers -->
                                                        <td style="display:flex; align-items:center">
                                                            <div v-for="(item,index) in item.prescribers" :key="index">
                                                                <div v-tooltip.bottom="prescriberToDescription(item)" class="prescriberCircle" :class="item">{{item}}</div>
                                                            </div>
                                                        </td>
                                                        <!-- PBS Item Code -->
                                                        <td>{{item.pbs_code}}</td>
                                                        <!-- Approval Code -->
                                                        <td style="padding-right: 10px; width: 150px;">{{item.restriction_flag != 'A' ? item.indication_id : 'Authority Required'}}</td>
                                                        <!-- Max Qty Packs -->
                                                        <td>{{item.max_quantity}}</td>
                                                        <!-- Max Qty Units -->
                                                        <td>{{item.pack_size}}</td>
                                                        <!-- No of repeats -->
                                                        <td>{{item.restriction_flag == 'A' ? item.max_repeats : "--"}}</td>
                                                        <!-- Clinical Criteria -->
                                                        <td>{{item.summaryNote}}</td>
                                                    </tr>
                                                    <tr :ref="'expand-pbs-'+index" :id="'expand-pbs-'+index" class="expanded-content">
                                                        <div style="width: 50vw; padding: 10px; padding-left: 20px;" v-if="item.expandNote">
                                                            <p style=" text-align: left; font-size: 1em;" v-for="(i, e) in item.expandNote" :key="e" >{{`${i}`}}</p>
                                                        </div>
                                                        
                                                        <div style="width: 50vw; padding: 10px; padding-left: 20px;" v-else>
                                                            <p style=" text-align: left; font-size: 1em;" >{{`${item.note}`}}</p>
                                                        </div>
                                                    </tr> 
                                                </tbody>
                                            </table>
                              
                                    <!-- legend -->
                                    <div>
                                        <p style="text-align: left;font-weight: bolder;">LEGEND</p>
                                        <div style="display: flex; padding-right: 10px; padding-top: 10px; flex: 1; justify-content:space-around; width: 70%">
                                            <div style="display:flex; gap:5px; align-items: center;">
                                                <div class="prescriberCircle M">M</div>
                                                <p>Medical Practitioner</p>
                                            </div>
                                            <div style="display:flex; gap:5px; align-items: center;">
                                                <div class="prescriberCircle W">W</div>
                                                <p>Midwife</p>
                                            </div>
                                            <div style="display:flex; gap:5px; align-items: center;">
                                                <div class="prescriberCircle D">D</div>
                                                Dentist
                                            </div>
                                            <div style="display:flex; gap:5px; align-items: center;">
                                                <div class="prescriberCircle O">O</div>
                                                Optometrist
                                            </div>
                                            <div style="display:flex; gap:5px; align-items: center;">
                                                <div class="prescriberCircle N">N</div>
                                                Nurse Practitioner
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" @click="confirmSelectionCode()">Confirm</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
// import axios from 'axios';

export default {
    props: [ "close", 'streamlineOptions'],
    components: {
        // DualVerify
    },
    data() {
        return {
            displaySignatureModal: false,
            selectedCode:'',
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.closeModal();
                this.$emit('isSuccessful', true);
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        confirmSelectionCode(){
            this.$emit('confirmCode', this.selectedCode);
        },
        showContent(item, index){
            // console.log('this the selected log', item)
            // let refid=`expand-pbs-${index}`;
            // item.note  = item.note.filter(word=> word != '\\n' );
            // item.note = item.note.replace('\\n', '<br>');
            console.log('THIS IS THE ITEM NOTE', item.expandNote);
            if(item.display && (item.display === true)){
                document.getElementById(`expand-pbs-${index}`).style.display = 'none';
                item.display = !item.display;
            } else{
                item.display = true;
                console.log('expanding range for this object');
                document.getElementById(`expand-pbs-${index}`).style.display = 'table-row';
            }
        },
        prescriberToDescription(letter) {
            let desc = ""
            switch (letter) {
            case 'M':
                desc = "Medical Practitioner"
                break;
            case 'W':
                desc = "Midwife"
                break;
            case 'D':
                desc = "Dentist"
                break
            case 'O':
                desc = "Optometrist"
                break
            case 'N':
                desc = "Nurse Practitioner"
                break;
            default:
                desc = "";
        }
        return desc
    }
    },
    mounted() {
        console.log('THIS IS THE PBS AUTH CODE SELECTION', this.streamlineOptions);
        this.streamlineOptions.forEach(item => {
            item.summaryNote = item.note.split('\\n').join('').slice(0, 50).concat('... (Click to Expand)');
            item.expandNote = item.note.split('\\n');
        })
    },
    computed: {

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}

.prescriberCircle {
    display: flex;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    margin-right: 2px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: small;
}
.M {
    border: 2px solid #e05a55;
    color: #e05a55;
}

.W {
    border: 2px solid #7b98c9;
    color: #7b98c9;
}

.D {
    border: 2px solid #e4ab61;
    color: #e4ab61;
}

.O {
    border: 2px solid #ae4474;
    color: #ae4474;
}

.N {
    border: 2px solid #94c8c6;
    color: #94c8c6;
}


</style>
