<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <h3 class="form-header">Insulin Administration</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                                <div class="form-section-container" style="grid-gap: 24px; width: 100%; padding: 0 25px;" :style="selectedInsulin.sliding_scale.length ? {gridTemplateColumns: '2fr 4fr 3fr'} :  {gridTemplateColumns: '4fr 3fr'}">
                                    <div v-if="selectedInsulin.sliding_scale.length" class="left-form-section-panel" style="padding: 0;" >
                                        <h4 style="text-align: left; margin: 0; margin-bottom: 10px;">
                                            Sliding Scale
                                        </h4>
                                        <div style="width: fit-content">
                                            <div v-for="(item, index) in selectedInsulin.sliding_scale" :key="index" style="display: flex; padding: 5px; align-items: center; justify-content: space-between">
                                                <p style="font-size: 1em">
                                                    {{item.min}} {{item.max ? '-' : '+'}} {{item.max}}: 
                                                </p>
                                                <!-- :style="selectedIndex == index ? {backgroundColor: '#00AD50', color: 'white'}:{}" -->
                                                <p
                                                    style="font-size: 1em; font-weight: bold; margin-left: 20px; background-color: #f3f4f4; border-radius: 4px; padding: 5px 10px; width: 50px;"
                                                    
                                                >
                                                    {{item.value}}
                                                </p>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="left-form-section-panel" style="padding: 0;">
                                        <div class="grey-container">
                                            <p><b>Route: </b>{{selectedInsulin.route}}</p>
                                            <p>{{selectedInsulin.instructions.slice(0,-64)}}</p>
                                            <p><b>Indication:</b> {{selectedInsulin.indication ? selectedInsulin.indication : 'Not provided.'}}</p>
                                            <!-- <p><b>Max dose 24hrs:</b> <span v-if="selectedInsulin.max_dose">{{selectedInsulin.max_dose}} {{selectedInsulin.alias.form}}</span> <span v-else>Not provided.</span></p> -->
                                            <p><b>Last Quantity:</b> <span v-if="selectedInsulin.latest_administration">{{selectedInsulin.latest_administration.quantity}} International Units (IU)</span>
                                                                     <span v-else>Never administered.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="right-form-section-panel" style="padding: 0;">
                                        <div>
                                            <h3 class="form-header" style="margin-bottom: 10px;">{{selectedInsulin.alias.name}}</h3>
                                            <div v-if="!bglComplete" class="small-input" style="margin: 10x; margin-left: 0px; margin-bottom: 15px; ">
                                                <p style="text-align: left; margin: 0; font-size: 1em; width: 10em;">
                                                    Enter BGL (mmols/L)
                                                </p>
                                                <div style="display: flex; gap:5px;align-items: center;">
                                                    <InputNumber
                                                        :minFractionDigits="0"
                                                        :maxFractionDigits="1"
                                                        v-tooltip.top="'BGL Reading'"
                                                        mode="decimal"
                                                        style="border: 1px solid grey; border-radius: 4px; width: 10em;"
                                                        v-model="bglReading"
                                                    />
                                                    <div class="green-button"
                                                        @click="addReading()">
                                                            Confirm
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- QTY -->
                                            <div v-else class="small-input" style="margin: 10x; margin-left: 0px; margin-bottom: 15px; ">
                                                <p style="text-align: left; margin: 0; font-size: 1em; width: 10em;">
                                                    Enter Quantity (IU or International Units)
                                                </p>
                                                <InputNumber
                                                    :minFractionDigits="0"
                                                    :maxFractionDigits="2"
                                                    @focus="$event.target.select()"
                                                    v-tooltip.right="'Prescription Quantity'"
                                                    mode="decimal"
                                                    style="border: 1px solid grey; border-radius: 4px; width: 10em;"
                                                    v-model="enteredDose"
                                                />
                                            </div>
                                            <p style="font-size: 1em; text-align: left">Last BGL reading: <b>{{mutatedReadings.length ? mutatedReadings[0].value : 'No reading taken'}} mmols/L</b></p>
                                            <p style="font-size: 1em; text-align: left">Time of last BGL reading: {{mutatedReadings.length ? mutatedReadings[0].created_at : 'N/A'}}</p>
                                        </div>
                                        <!-- <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                            <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                            <textarea name="notes" id="note-modal" rows="11"
                                            placeholder=" Enter notes here..."
                                            style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                            v-model="notes"></textarea>
                                        </div> -->
                                    </div>

                                </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button v-if="!bglComplete" class="grey-button">
                           Administer
                        </button>
                        <button v-else class="blue-button" @click="signAction()">
                           Administer
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" :loop="false" :dual="true" />
        <SuccessModal v-if="displaySuccessModal" :first_spec_line="displayMessage" @close="closeSuccessModal" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './SignatureModal.vue';
import SuccessModal from './SuccessModal.vue';
// import SingleVerify from '@/components/verification/SingleVerify.vue';

export default {
    props: ['message', 'close', 'loading', 'statusMessage', 'action', 'actionType', 'noAuthRequired', 'adminSubmission','selectedInsulin', 'patientId', 'readings'],
    components: {
        SignatureModal,
        SuccessModal
    },
    data() {
        return {
            payload:'',
            enteredReading:0,
            bglReading: 0,
            bglComplete: false,
            bglReadingUuid: "",
            mutatedReadings: [],
            enteredDose: this.selectedInsulin && this.selectedInsulin.sliding_scale && !this.selectedInsulin.sliding_scale.length ? parseFloat(this.selectedInsulin.dose) : 0,
            selectedIndex: null,
            signature_endpoint:'/administer',
            displaySignatureModal: false,
            displaySuccessModal: false,
            removeFromRegisterPayload: [],
            removedFromRegister: false,
            selectedCode:'',
            notes:'',
            displayMessage: ''
        }
    },
    methods: {
        closeSuccessModal(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        recommendedSlidingDose(){
            if(this.readings.length){
                let lastReading = this.readings[0].value;
                this.selectedInsulin.sliding_scale.forEach((scale, index)=>{
                    if(lastReading >= scale.min){
                        if((lastReading <= scale.max) || !scale.max){
                            console.log('this is the scale', scale);
                            this.selectedIndex = index;
                        }
                    }
                })
            }
        },
        addReading(){
            // BGL READING
            // this.$store.dispatch('getRefreshToken');
            let payload = {
                patient_id:this.patientId,
                unit: 'BGL',
                type: 'insulin',
                value: this.bglReading.toString()
            }
            axios.post('/create-reading', payload, this.$store.state.header).then(res=>{
                console.log(res);
                this.bglComplete = true
                res.data.value = this.bglReading
                this.bglReadingUuid = res.data.uuid
                this.mutatedReadings.unshift(res.data)
            }).catch(err=>{
                this.displayMessage = err.response.data
            })
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.$emit('completeInsulin', {uuid: this.selectedInsulin.uuid, quantity:this.enteredDose, ...value});
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            // not technically this.adminSubmission.calendar_item_id but is being passed via prop as such, it is indeed the chart_id
            this.payload = {
                chart_id:this.adminSubmission.chart_id,
                calendar_item_id: this.adminSubmission.calendar_item_id, 
                doc:'',
                notes:this.notes,
                dose: this.enteredDose.toString(),
                drug_safe_retrieval: '',
                reading: this.mutatedReadings.length ? this.mutatedReadings[0].uuid : ''

            }
            console.log('SIGN ACTION CONDITION', this.payload);
            console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('close', true);
        },
    },
    beforeMount() {
        if(!this.adminSubmission.length){
            console.log('HITTING THE IF ON MOUNT')
            this.payload = {...this.adminSubmission};
        } else{
            console.log('HITTING THE ELSE ON MOUNT')
            this.payload = this.adminSubmission;
        }
        if(this.readings.length){
            this.mutatedReadings = this.readings
        } 
        this.recommendedSlidingDose()
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.icon-container{
    width: 282px;
    height: 240px;
    left: 25px;
    background: #94D6D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-circle{
    border-radius: 50%;
    height: 131px;
    width: 131px;
    background: #00B2A2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-input{
    span{
        width: 50%;
        input{
            width:50%;
        }
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.icon-circle-item{
    width: 75px;
}

.doc-grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.grey-container{
    background-color: #f3f4f4;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    p{
        margin-bottom: 10px !important;
        font-size: 15px;
    }
}

.doc-item{
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}

.selected-doc-item{
    background-color: $error;
    color: white;
}

.form-section-container {
    display: grid;
    grid-gap: 10px;
}

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
