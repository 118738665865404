


<template>
    <!-- Select Report Type -->
    <div style="display:flex; flex-direction: column;justify-items: start; gap:10px">
        <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select Report Type:</p>
        <Dropdown id="report-type" style="width: fit-content;" placeholder="Select Report" :options="reports"
            optionLabel="label" v-model="selectedReport" @change="retrieveCompanyTree" />
    </div>

    <!-- Generate Audit Report -->
    <div v-if="selectedReport" style="display: grid; grid-gap: 10px; min-width: 50em;">
        <div style="display: grid; grid-gap:1em; margin-top: 1em;">
            <p style='font-size: 1em; text-align: left; margin: 0;font-weight: bold'>Select a location and report type to
                generate a Audit Report</p>
            <div style="display:flex; flex-direction: column;justify-items: start; gap:10px">
                <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select Location:</p>
                <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Location"
                    :options="locationDirectory" optionLabel="name" v-model="selectedLocation"
                    @change="retrieveCompanyTree" />
            </div>
            <div v-if="selectedLocation" style="display: flex; flex-direction: column; gap: 20px;">
                <div style="display:flex; flex-direction: column;justify-items: start; gap:10px">
                    <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select a year:</p>
                    <Calendar v-model="selectedYear" class="grey-calendar" view="year" dateFormat="yy" :maxDate="maxDate"
                        placeholder="Select a year" style="width: auto; max-width: 200px;" />
                </div>
                <div style="display:flex; flex-direction: column;justify-items: start; gap:10px" v-if="selectedYear">
                    <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select a quarter:</p>
                    <Dropdown :options="quarters" optionValue="value" optionLabel="label" v-model="selectedQuarter"
                        class="qi-input" placeholder="Select a Quarter" />
                </div>
            </div>
            <div v-if='selectedLocation && selectedYear && selectedQuarter'>
                <p class="green-button" style="width: 15em;" @click="generateAuditReport()">Generate Report</p>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" :first_spec_line="first_spec_line"
            statusMessage="Audit Generation" />
    </div>
    <!-- END OF Generate Audit Report -->
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            quarters: [
                {
                    label: '1st Quarter (Jan-Mar)',
                    value: {
                        quarter: 1,
                        start: '01/01',
                        end: '31/03'
                    },
                },
                {
                    label: '2nd Quarter (Apr-Jun)',
                    value: {
                        quarter: 2,
                        start: '01/04',
                        end: '30/06'
                    },
                },
                {
                    label: '3rd Quarter (Jul-Sep)',
                    value: {
                        quarter: 3,
                        start: '01/07',
                        end: '30/09'
                    },
                },
                {
                    label: '4th Quarter (Oct-Dec)',
                    value: {
                        quarter: 4,
                        start: '01/10',
                        end: '31/12'
                    }
                }
            ],
            selectedQuarter: null,
            selectedYear: null,
            locationDirectory: "",
            loadingLocation: false,
            selectedLocation: undefined,
            noResults: false,
            states: "",
            selectedReport: undefined,
            reports: [{ label: 'Drug Entries', value: 'drug_entries' }],
            displaySuccessModal: false,
            successMessage: "",
            first_spec_line: ""
        }
    },
    methods: {
        retrieveStates() {
            axios.post('/get-states', {}, this.$store.state.header).then(res => {
                this.states = res.data;
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    this.$router.push('/login');
                }
                console.log('this is the stocktake error', err.response.data);
            })
        },
        retrieveCompanyTree() {
            this.loadingLocation = true
            this.selectedLocations = [];
            let payload = { "drug_register": true };
            axios.post('/get-locations', payload, this.$store.state.header).then(res => {
                console.log('these are the facility locations', res.data);
                this.locationDirectory = res.data.locations;
                this.loadingLocation = false;
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                console.log('err issss', typeof err.response);
                if (err.response && err.response && err.response.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                this.loginCreateError = err.response
                console.log('err is', err.response, err.response);
                this.$store.dispatch('getRefreshToken');
            })
        },
        generateAuditReport() {
            let currentYear = new Date(this.selectedYear).getFullYear();

            // Convert "day/month" to "YYYY-MM-DD"
            let startDateFormat = this.selectedQuarter.start.split('/').reverse().join('-');
            let startDateString = `${currentYear}-${startDateFormat}`;
            let startDate = new Date(startDateString);

            let currentDate = new Date();

            // Check if the start date is later than the current date
            if (startDate > currentDate) {
                this.displaySuccessModal = true;
                this.successMessage = "There's no data in the selected time frame ...";
                return; // Exit the function if the condition is met
            }
            let date = {
                start: this.selectedQuarter.start + '/' + new Date(this.selectedYear).getFullYear(),
                end: this.selectedQuarter.end + '/' + new Date(this.selectedYear).getFullYear(),
            }
            let payload = {
                location_id: this.selectedLocation.uuid,
                date: date
            };
            console.log('Generating Audit Report for this location:', payload);
            this.displaySuccessModal = true;
            this.successMessage = 'Generating Audit Report...';
            let endpoint;
            if (this.selectedReport.value == 'drug_entries') {
                endpoint = "/generate/drug-register-csv"
            }
            console.log("this.selectedReport", this.selectedReport)
            axios.post(endpoint, payload, this.$store.state.header).then(res => {
                this.successMessage = 'We are now generating the latest Audit Report, you can safely close this window.';
                this.first_spec_line = 'Please visit "View & Download Audit Reports" subsection to check the status of this report.'
                this.displaySuccessModal = true

            }).catch(err => {
                this.successMessage = 'Failed to generated Audit Report.';
                console.log("this is the Audit gen error:", err);
                this.first_spec_line = err?.response?.data?.errors?.location_id ?? "Internal Error. Please try again.";
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data);
            })
        },
        close() {
            this.displaySuccessModal = false
            this.successMessage = ""
            this.first_spec_line = ""
        }
    },
    mounted() {
        this.noResults = false;
        this.retrieveStates();
        this.retrieveCompanyTree();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
