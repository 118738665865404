<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" :style="integrationResidents.length ? {width: '98%'}:{width: '50%'}">
                <div class="modal-header">
                    <h3 class="form-header">Resident Syncing </h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr;">
                                <!-- RESIDENT SYNCING WITH EXISTING RESIDENTS -->
                                
                                <p v-if="retrieveResidentsError" style="color: red; font-size: 1em; font-weight: bold">WARNING: Residents have not been retrieved from Strong Care. Please try again later.</p>
                                <div style="margin-right: 20px;" v-if="existingResidents.length">
                                    <h3 style="text-align: left; margin: 0; margin-bottom: 10px;">Map Strong Care Residents to CMS Residents</h3>
                                    <p style="font-size: 1em; font-weight: bold; text-align: left; margin: 0;">Note:</p>
                                    <ul>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">Below is a list of residents within this facility that need to be linked to the residents in the CMS system.</p></li>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">For each resident, please select from the dropdown list which CMS resident to link them to.</p></li>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">Once selected, click on Sync to complete the process for that resident.</p></li>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">Strong Care has matched a few residents, please confirm these.</p></li>
                                    </ul>
                                    
                                    

                                    <table class="data-table" style="border-collapse: collapse;">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th style="width: 7em">DOB</th>
                                            <th style="width: 8em">Sex</th>
                                            <th>Integrated Resident</th>
                                            <th></th>
                                        </tr>
                                        <tbody class="table-data" v-for="(item, index) in existingResidents" :key="index">
                                            <tr>
                                                <td>{{item.first_name}}</td>
                                                <td>{{ item.last_name }}</td>
                                                <td style="width: 7em">{{ item.dob }}</td>
                                                <td style="width: 8em">{{ item.sex.slice(0,1).toUpperCase() }}{{ item.sex.slice(1) }}</td>
                                                <td>
                                                    <Dropdown v-model="item.linked_resident" :options="filteredResidents" showClear @change="updateResidentList()" filter optionLabel="full_name" placeholder="Select resident" class="w-full md:w-14rem" style="width: 20em">
                                                        <template #value="slotProps">
                                                            <div v-if="slotProps.value" class="flex align-items-center">
                                                                <div><p style="margin: 0;">[{{ slotProps.value.external_id }}] {{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</p></div>
                                                            </div>
                                                            <span v-else>
                                                                {{ slotProps.placeholder }}
                                                            </span>
                                                        </template>
                                                        <template #option="slotProps">
                                                            <div class="flex align-items-center" style="display: flex; gap: 10px; align-items: center">
                                                                <div class="patient-result-image" >
                                                                    <img v-if="slotProps.option.profile_pic" onerror="this.onerror=null; this.className='resident-image-default resident-image small'" class="resident-image small" 
                                                                    :src="slotProps.option.profile_pic" alt="ss" style="max-height: 100px; max-width: 100px;">
                                                                    <img v-else class="resident-image-default resident-image small" alt="">
                                                                </div>
                                                                <!-- <img :alt="slotProps.option.label" :src="slotProps.option.profile_pic" :class="`mr-2`" style="width: 7em" /> -->
                                                                <div style="display: grid">
                                                                    <p style="margin: 0; font-size: 1em; margin-bottom: 5px"><b>Name:</b> {{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</p>
                                                                    <p style="margin: 0; font-size: 1em; margin-bottom: 5px"><b>DOB:</b> {{ slotProps.option.dob }}</p>
                                                                    <p style="margin: 0; font-size: 1em; margin-bottom: 5px"><b>ID:</b> {{ slotProps.option.external_id }}</p>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </Dropdown>
                                                </td>
                                                <td style="display: flex; align-items: center; justify-content: center"><p class="green-button" style="width: fit-content" v-if="item.linked_resident" @click="syncResident(item, index)"><i class="pi pi-sync" style="font-weight: bold"></i>Sync{{ linkingResident ? 'ing' : '' }}</p></td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>

                                <!-- CREATING NEW RESIDENTS FROM INTEGRATED RESIDENTS -->
                                <div style="margin-right: 20px;" v-if="integrationResidents.length && !existingResidents.length && !retrieveResidentsError">
                                    <h3 style="text-align: left; margin: 0; margin-bottom: 10px;">Create StrongCare Residents from CMS Residents</h3>
                                    <p style="font-size: 1em; font-weight: bold; text-align: left; margin: 0;">Note:</p>
                                    <ul>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">Below is a list of residents that have been retrieved from the CMS platform.</p></li>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">For each resident, please provide the additional information to create their profiles in StrongCare.</p></li>
                                        <li><p style="font-size: 1em; text-align:left; margin: 3px 0;">Once all information has been provided, please click Add to confirm.</p></li>
                                    </ul>
                                     
                                    <p v-if="linkError" style="color: orange; font-size: 1em; font-weight: bold">Resident/s have been created successfully, however linking the resident was unsuccessful. Please try to link the resident later.</p>
                                   
                                    <table class="data-table" style="border-collapse: collapse;">
                                        <tr>
                                            <th style="width: 5em">ID</th>
                                            <th style="width: 7em">First Name</th>
                                            <th style="width: 7em">Last Name</th>
                                            <th style="width: 4em">DOB</th>
                                            <th style="width: 5em">Sex</th>
                                            <th style="width: 5em">Medicare/DVA</th>
                                            <th style="width: 8em">Medicare/DVA No.</th>
                                            <th style="width: 8em" v-if="$store.state.user.eprescribing_active">Prescriber</th>
                                            <th style="width: 8em" v-if="$store.state.user.eprescribing_active">Pharmacy</th>
                                            <th style="width: 8em">Location</th>
                                            <th style="width: 3em"></th>
                                        </tr>
                                        <tbody class="table-data" v-for="(item, index) in integrationResidents" :key="index">
                                            <tr>
                                                <td>{{ item.external_id }}</td>
                                                <td>{{item.first_name}}</td>
                                                <td>{{ item.last_name }}</td>
                                                <td style="width: 7em">{{ item.dob }}</td>
                                                <td >
                                                    <Dropdown v-model="item.sex" :options="sex" optionLabel="sex" optionValue="sex"
                                                    :placeholder="item.sex ? item.sex : 'Select'" style="width: 100%;" />
                                                </td>
                                                <td>
                                                    <Dropdown :options="regulationOptions" v-model="item.reg_option" optionLabel="option" optionValue="option"
                                                    :placeholder="'Select'" style="width: 100%;"  />
                                                </td>
                                                <td>
                                                    <InputMask mask="9999 99999 9 9" v-if="item.reg_option == 'Medicare'"
                                                        placeholder="9999 99999 9 / 9(IRN)" v-model="item.reg_value" style="border: 1px solid lightgray; width: 100%" />
                                                    <input v-if="item.reg_option == 'DVA'" v-model="item.reg_value"  type="text" :placeholder="'Enter DVA No.'" style="border: 1px solid lightgray; width: 100%" >
                                                    <p v-if="!item.reg_option">Select if Medicare/DVA first</p>
                                                </td>
                                                <td v-if="$store.state.user.eprescribing_active">
                                                    <Dropdown :options="prescribers" v-model="item.prescriber" optionLabel="name"
                                                    :placeholder="'Select Prescriber'" style="width: 100%;"  />
                                                </td>
                                                <td v-if="$store.state.user.eprescribing_active">
                                                    <Dropdown :options="pharmacies" v-model="item.pharmacy" optionLabel="name"
                                                    :placeholder="'Select Pharmacy'" style="width: 100%;"  />
                                                </td>
                                                <td>
                                                    <div v-if="nodes && nodes.length">
                                                        <div v-if="loadingLocations">
                                                            <i class="pi pi-spin pi-spinner"
                                                                style="font-size: 2rem !important;margin-top: 10px;"></i>
                                                        </div>
                                                        <TreeSelect v-else v-model="item.selectedNodeKey" :options="nodes" ariaLabelledBy="name"
                                                            placeholder="Select Location" style="width: 100%;"  />
                                                        <!-- <p class="blue-button" @click="confirmFacilityLocation()">Click</p> -->
                                                    </div>
                                                </td>
                                                <td style="display: flex; align-items: center; justify-content: center; margin-top: 5px" v-if="$store.state.user.eprescribing_active"><p class="green-button" style="width: fit-content"
                                                v-if="item.selectedNodeKey && item.pharmacy && item.prescriber && item.reg_value && item.sex"
                                                     @click="createResident(item, index)"><span v-if="!item.saving">Add</span><i class="pi pi-spin pi-spinner" v-if="item.saving" style="font-size: 2rem"></i></p>
                                                     
                                                </td>
                                                <td style="display: flex; align-items: center; justify-content: center; margin-top: 5px" v-if="!$store.state.user.eprescribing_active"><p class="green-button" style="width: fit-content"
                                                v-if="item.selectedNodeKey && item.reg_value && item.sex"
                                                     @click="createResident(item, index)"><span v-if="!item.saving">Add</span><i class="pi pi-spin pi-spinner" v-if="item.saving" style="font-size: 2rem"></i></p>
                                                     
                                                </td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                                <div v-if="!loading && !integrationResidents.length && !existingResidents.length">
                                    <p style="font-size: 1em;">No new CMS residents found.</p>
                                    <p style="font-size: 1em;">Sync completed!</p>
                                </div>
                                <div v-if="!loading && !integrationResidents.length && existingResidents.length">
                                    <p style="font-size: 1em;">No new CMS residents found.</p>
                                    <p style="font-size: 1em;">Please visit your CMS platform and check to see if the residents still in this list exist in the platform.</p>
                                    <p style="font-size: 1em;">Once they are created, log back in to Strong Care and you will be able to link the resident profiles.</p>
                                </div>
                                <div v-if="loading">
                                    <p style="font-size: 1em;">Syncing residents, please wait...</p>
                                    <i class="pi pi-spin pi-spinner"
                                                            style="font-size: 2rem !important;margin-top: 10px;"></i>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;"
                    v-if="!submitting && !errorMessage">
                    <slot name="footer" style="display: flex;">
                        
                        <button class="red-button" @click="closeModal()" >Close</button>
                    </slot>
                </div>
            </div>

        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './SignatureModal.vue';

export default {
    props: ['message', 'close', 'signature_endpoint',
         'payload', 'action', 'actionType', 'loadingDelay', 'noAuthRequired', 'selectedFacility'],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            submitting: false,
            errorMessage: null,
            loading: false,
            loadingLocations: false,
            selectedResidents:[],
            existingResidents:[
                // {
                //     first_name: 'John',
                //     last_name: 'Smithsonian',
                //     dob:'10/12/1920',
                //     sex: 'male',
                //     uuid: '9239423223023-23423923-234',
                //     linked_resident: null
                // },
                // {
                //     first_name: 'Sally',
                //     last_name: 'Robertson',
                //     dob:'14/10/1920',
                //     sex: 'female',
                //     uuid: '9239423223023-23423923-234',
                //     linked_resident: null
                // }
            ],
            sex:[
                {sex:'Male'},
                {sex: 'Female'},
                {sex:'Intersex'}
            ],
            regulationOptions:[
                {option:'DVA'},
                {option: 'Medicare'},
            ],
            integrationResidents: [],
            pharmacies:null,
            prescribers:null,
            nodes:[],
            hashId: '',
            linkingResident: false,
            linkError: false,
            retrieveResidentsError: false,
        }
    },
    methods: {
        updateResidentList(){
            this.selectedResidents = [];
            this.existingResidents.forEach(resident=>{
                if(resident.linked_resident){
                    this.selectedResidents.push(resident.linked_resident);
                }
            })
            console.log('updating computed list', this.selectedResidents);
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        syncResident(resident, index){
            console.log('this is the mapped residents before', resident, this.existingResidents)
            
            console.log('this is the resident payload we are syncing', this.integrationResidents.indexOf(resident.linked_resident));
            this.linkResident(resident, index);
            
            this.updateResidentList();
            
        },
        signAction() {
            this.displaySignatureModal = true;
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        completeAction(){
            this.submitting = true;
            this.errorMessage = null;
            axios.post(this.signature_endpoint, this.payload, this.$store.state.header).then(res=>{
                console.log('successfully submitted', res.data);
                this.$emit('successfullySubmitted', true);
                this.submitting = false;
            }).catch(err=>{
                console.log(err?.response?.data?.message);
                this.submitting = false;
                this.errorMessage = err;
            })
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('close', 'reload');
        },
        getPharmacies() {
            this.$store.dispatch('getRefreshToken');
            axios.post(`/facility/${this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}/pharmacies`, {}, this.$store.state.header).then(res => {
                console.log('these are the pharmacies', res.data);
                this.pharmacies = res.data;
            })
        },
        getPrescribers() {
            this.$store.dispatch('getRefreshToken');
            axios.post(`/facility/${this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = res.data;
            })
        },
        getLocations(){
            this.loadingLocations  =true
            axios.post(`/facility/${this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.loadingLocations = false;
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        createResident(resident, index){
            let payload = {
                "first_name": resident.first_name,
                "last_name": resident.last_name,
                "dob": resident.dob, 
                "sex": resident.sex
            }
            resident.saving = true;
            axios.post('/person/bundle', payload, this.$store.state.header).then(res => {
                console.log('patient registered.');
                resident.uuid = res.data.uuid;
                this.addRegulatoryNumber(resident);
                // this.linkResident(resident);
                if(this.$store.state.user.eprescribing_active){
                    this.linkPrescriber(resident);
                    this.linkPharmacy(resident);
                }
                
                this.linkLocation(resident);
                this.submitImage(resident);
                axios.post(`/person/${resident.uuid}/status`, {status: 'onboarding'}, this.$store.state.header).then(res=>{
                    console.log('updated status', res);
                    this.integrationResidents.splice(index,1);
                    // if there are no more integration residents after this, save the hash
                }).catch(err=>{
                    this.loading = false;
                    this.successMessage = `Error: ${err.response.data}`;
                })
            })
        },
        linkResident(resident, index){
            console.log('link resident', resident);
            let payload = {
                facility_id: this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid,
                integration_id: this.selectedFacility ? this.selectedFacility.integration_id : this.$store.state.user.integration_id,
                residents:[
                    {
                        external_id: resident.linked_resident ? resident.linked_resident.external_id : resident.external_id,
                        resident_id: resident.uuid
                    }
                ]
            }
            this.linkingResident = true;
            this.linkError = false;
            console.log('this is what is being sent to link', payload);
            if(payload.residents.length){
                axios.post('/link-integration-residents', payload, this.$store.state.header).then(res=>{
                    console.log('successfully linked resident', res);
                    console.log("this is the resident", resident);
                    
                    if(this.existingResidents.length){
                        let updatePayload = {
                            first_name: resident.linked_resident?.first_name ?? resident.first_name,
                            last_name: resident.linked_resident?.last_name ?? resident.first_name,
                            sex: resident.linked_resident?.sex ?? resident.sex,
                            dob: resident.linked_resident?.dob ?? resident.dob,
                            preferred_name: '',
                            aslr_site_consent: true
                        }
                        axios.post(`/person/${resident.uuid}/edit`, updatePayload, this.$store.state.header).then(res1=>{
                            console.log('updating the resident information with edit', res1);
                            this.linkingResident = false;
                            
                                this.existingResidents.splice(index,1);
                                if(!this.existingResidents.length){
                                    let payload = {
                                        facility_id:this.$store.state.currentLocation.facility.uuid, 
                                        integration_id: this.$store.state.user.integration_id, 
                                        integration_facility_id :  this.$store.state.user.integration_facility_id
                                    }
                                    axios.post('/get-residents', this.selectedFacility ? this.selectedFacility : payload, this.$store.state.header).then(res=>{
                                        res.response.forEach(resident=>{
                                            this.formatMappedResident(resident);
                                        })
                                    })
                                }
                            
                            
                            this.integrationResidents.splice(this.integrationResidents.indexOf(resident.linked_resident), 1);
                            if(!this.integrationResidents.length){
                                axios.post('/save-sync-hash', {hash: this.hashId, facility_id: this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}, this.$store.state.header).then(res=>{
                                    console.log('saved hash', res);
                                }).catch(err=>{
                                    console.log('error when saving hash', err, err.response);
                                })
                            }
                        })
                    } else{
                        if(!this.integrationResidents.length){
                            axios.post('/save-sync-hash', {hash: this.hashId, facility_id: this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}, this.$store.state.header).then(res=>{
                                console.log('saved hash', res);
                            }).catch(err=>{
                                console.log('error when saving hash', err, err.response);
                            })
                        }
                    }
                }).catch(err=>{
                    console.log('could not link', err, err.response);
                    this.linkingResident = false;
                    this.linkError = true;
                })
            }
        },
        linkPrescriber(resident){
            axios.post(`/person/${resident.uuid}/link-prescriber`, { prescriber_id: resident.prescriber.uuid, primary: true }, this.$store.state.header).then(res => {
                console.log('patient prescriber submitted.', res);
                this.first_spec_line = 'Prescriber linked to resident.';
                this.successMessage = '';
                this.displaySuccessModal = true;
                this.second_spec_line = '';
            })
        },
        linkPharmacy(resident){
            axios.post(`/person/${resident.uuid}/link-pharmacy`, { pharmacy_id: resident.pharmacy.uuid }, this.$store.state.header).then(res => {
                console.log('patient pharmacy submitted.', res);
                this.displaySuccessModal = true;
            })
        },
        addRegulatoryNumber(resident){
            this.$store.dispatch('getRefreshToken');
            let payload =[
                {
                    data: resident.reg_value,
                    regulaotry_type: resident.reg_option,
                    expiry: ''
                }
            ]

            axios.post(`/person/${resident.uuid}/regulatory`, { 'regulatory_numbers': payload }, this.$store.state.header).then(res => {
                this.loading = false;
                console.log('regulatory information submitted.', res)
            }).catch(err => {
                this.errActions += ' Error submitting regulatory information.'
                console.log('this is the reg info error', err, err.response.data);
            })
        },
        linkLocation(resident){
            axios.post(`/person/${resident.uuid}/location`, { status: 'resident', location: Object.keys(resident.selectedNodeKey)[0] }, this.$store.state.header).then(res => {
                console.log('facility location submitted.', res);
                this.locationSaveStatus = 'Location saved!';
                this.linkResident(resident);
            })
        },
        addProfileImage(resident){

        },
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        submitImage(resident) {
            if (resident.linked_resident?.profile_pic || resident.profile_pic) {
                axios.post(`/aws/patient/${resident.uuid}/image`, {
                    image: resident.linked_resident?.profile_pic ? resident.linked_resident.profile_pic : resident.profile_pic,
                    item:['profile', 'thumbnail']
                }, this.$store.state.header).then(res => {
                    console.log('uploaded patient image', res);
                }).catch(err=>{
                    this.displaySuccessModal = true;
                    this.second_spec_line = '';
                    this.first_spec_line = err.response && err.response.data ? err.response.data : err;
                })
            } else {
                console.log('No image has been uploaded');
            }

        },
        formatCMSResident(resident){
            console.log('going through format cms resident process', resident);
            let res = {...resident};
            console.log('this is the res copy of resident', res);
            let transit = { 
                    first_name: res.first_name,
                    last_name: res.lastname,
                    full_name: res.first_name +' '+res.lastname,
                    reg_option: null,
                    reg_value: null,
                    sex: null, //adding this to resident objects
                    prescriber:null,
                    pharmacy: null,
                    selectedNodeKey: '',
                    dob: res.dob,
                    profile_pic: 'data:image/jpeg;base64,'+res.profile_image,
                    external_id: res.external_id 
                }
            console.log('this is the transit copy of resident', transit);
            resident = transit;
            this.integrationResidents.push(resident);
        },
        formatMappedResident(resident){
            let res = {...resident};
            let transit = {
                    first_name: res.firstname,
                    last_name: res.lastname,
                    dob: res.dob,
                    sex: res.sex,
                    uuid: res.uuid,
                    linked_resident: null
            }
            resident = transit;
            this.existingResidents.push(resident);
            
        },
        getCMSResidents(){
            this.loading = true;
            let payload = {
                facility_id:this.$store.state.currentLocation.facility.uuid, 
                integration_id: this.$store.state.user.integration_id, 
                integration_facility_id : this.$store.state.user.integration_facility_id
            }
            axios.post('/sync-integration', this.selectedFacility ? this.selectedFacility : payload, this.$store.state.header).then(res=>{
                // this.integrationResidents = res.data.residents;
                this.hashId = res.data.response.hash ? res.data.response.hash : '';
                this.loading = false;
                // if there are residents then create an array for integration Residents, if no cms residents are returned, then save the hash to update the timestamp since the last check
                if(res.data.response.residents.length){
                    this.integrationResidents = [];
                } else{
                    axios.post('/save-sync-hash', {hash: this.hashId, facility_id: this.selectedFacility ? this.selectedFacility.facility_id : this.$store.state.currentLocation.facility.uuid}, this.$store.state.header).then(res=>{
                        console.log('saved hash', res);
                    }).catch(err=>{
                        console.log('error when saving hash', err, err.response);
                    })
                }
                res.data.response.residents.forEach(res=>{
                    this.formatCMSResident(res);
                })
                if(res.data.response.residents.length){
                    axios.post('/get-residents', this.selectedFacility ? this.selectedFacility : payload, this.$store.state.header).then(res=>{
                        res.data.response.forEach(resident=>{
                            this.formatMappedResident(resident);
                        })
                    }).catch(err=>{
                        console.log('thi is the get-residents error', err, err.data?.response);
                        this.retrieveResidentsError = true;
                    })
                }
            }).catch(err=>{
                console.log('getcms resident error is', err.data?.response, err.response?.data);
                this.errorMessage = err.reponse;
                this.loading = false;
            })
        }
    },
    computed:{
        filteredResidents(){
            return this.integrationResidents.filter(x => !this.selectedResidents.includes(x))
        }
    },
    mounted() {
        this.getPharmacies();
        this.getPrescribers();
        this.getLocations();
        this.getCMSResidents();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



</style>
