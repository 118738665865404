<template>
            <div class="active-chart-table-container" style="display: flex; flex-wrap: wrap; margin-left: 0; background-color: white; margin-left: 1em; border-radius: 8px; overflow-y: auto">
                <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-left: 0; width: 100%; border-radius: 8px;" :style="loadingPatient || !patient ? {backgroundColor: '#f3f4f4'}:{}">
                 <div v-if="!loadingPatient && patient && patient.personal_information && patient.regulatory_information" style="width: 100%">
                   <div class="admin-chart-form-container">
                   <div class="admin-chart-form" >
                        <!-- PATIENT SUMMARY HEADER -->
                        <!-- GENERAL INFO -->
                    
                        <div class="general-patient"
                            style="grid-area: initial; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: grid; grid-template-columns: 1fr 4fr; grid-gap: 10px;">
                            <div>
                                <router-link :to="'/patient/' + patient.uuid" style="text-decoration: none; color: #0870DC">
                                    <h2 class="selected-resident-name" style="padding-top: 0px; margin: 0; color: #0870DC; font-size: 1.2em;">
                                        {{ patient.personal_information.first_name }} {{ patient.personal_information.last_name }}
                                    </h2>
                                </router-link>
                                <div>
                                </div>
                                <!-- <img v-if="patient.image_information && patient.image_information.url" :src="patient.image_information.url" alt="ss" style="border-radius: 8px; max-width: 250px; margin: 0 auto; margin-bottom: 10px;"> -->
                                <img class="resident-image large admin" onerror="this.onerror=null; this.className='resident-image-default resident-image large admin'" v-if="patient.image_information && patient.image_information.item" :src="patient.image_information.url" alt="ss" style="margin-bottom: 10px !important">
                                <img v-else class="resident-image-default resident-image large admin" alt="ss" style="margin-bottom: 10px !important">
                                <div>
                                    <div style="border: 2px solid #E04F39; border-radius: 8px;">
                                        <!-- PATIENT INFO/ADR TAB SELECT -->
                                        <!-- border-bottom: 2px solid  #E04F39 -->
                                        <div style="display: flex; justify-content: space-around; align-items: center; border-bottom: 2px solid #E04F39;">
                                            <h4
                                                style="cursor: pointer; width: 100%; margin: 0; padding: 5px 0; width: 50%;"
                                                @click="infoADRTab = 'INFO'"
                                                :style="infoADRTab == 'INFO' ? { backgroundColor: '#E04F39', color: 'white', borderTopLeftRadius: '4px' } : {}"
                                            >
                                                INFO
                                            </h4>
                                            <h4
                                                style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0;"
                                                @click="infoADRTab = 'ADR'"
                                                :style="infoADRTab == 'ADR' ? { backgroundColor: '#E04F39', color: 'white', borderTopRightRadius: '4px' } : {}"
                                            >
                                                ADR
                                            </h4>
                                        </div>
                                        <!-- PATIENT STICKERS -->
                                        <!-- PATIENT STICKERS -->
                                        <div style="height: 200px; overflow: auto;" v-if="infoADRTab == 'ADR'">
                                            <table style="padding: 0 10px; border-collapse: collapse;" v-if="patient.adr && patient.adr.length">
                                                <tr>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Drug
                                                    </th>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Year
                                                    </th>
                                                </tr>
                                                <tr v-for="(item, index) in patient.adr" :key="index">
                                                    <td style="padding: 0 10px;">
                                                        {{ item.drug ? item.drug.toUpperCase() : item.custom ? item.custom.toUpperCase() : 'Non Spec.'}}
                                                    </td>
                                                    <td style="padding: 0 10px;">
                                                        {{ item.date ? item.date.split(',')[1] : '----' }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- PATIENT INFO -->
                                        <div style="height: 200px; text-align: left; overflow-x: auto; background-color: white !important;border-radius: 8px"
                                            v-if="infoADRTab == 'INFO'">
                                            <div>
                                                 <div class="general-patient-info">
                                                    <p class="general-patient-info-key">DOB</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.dob }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Age</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.age }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Sex</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.sex }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Preferred Name</p>
                                                    <p class="general-patient-info-value">
                                                        {{ patient.personal_information.preferred_name }}</p>
                                                </div>
                                                <div class="general-patient-info" v-for="(item, index) in patient.contact_methods"
                                                    :key="index">
                                                    <p class="general-patient-info-key">{{ item.type }}</p>
                                                    <p class="general-patient-info-value">{{ item.data }}</p>
                                                </div>
                                                <div v-if="patient.regulatory_information && patient.regulatory_information.length">
                                                    <div class="general-patient-info"
                                                        v-for="(item, index) in patient.regulatory_information" :key="index">
                                                        <p class="general-patient-info-key">{{ item.regulatory_type }}</p>
                                                        <p class="general-patient-info-value">{{ item.data }}</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SPECIAL CONSID.-->
                                    <div>
                                        <div v-if="spec_cons && $store.state.user.patient_admin_active">
                                            <div
                                                v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                                style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 200px;"
                                            >
                                                <p
                                                    class="field-value"
                                                    v-for="(item, index) in uniqueSpecCons" :key="index"
                                                    style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 0px; margin-right: 5px;"
                                                >
                                                    {{item.name.toUpperCase() }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END SPECIAL CONSID.  -->
                                </div>
                                <!-- <div>
                                    <div v-if="patient.adr.length"
                                        style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 10px; overflow: scroll; max-height: 200px;">
                                        <p class="field-value" v-for="(item, index) in patient.adr" :key="index"
                                            style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 5px; margin-right: 5px;">
                                            {{ item.drug.toUpperCase() }}
                                        </p>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <hr style="border:none; border-left:2px solid #e8e8e8; height:90%; width:2px; align-self: center"> -->
                            <!-- PATIENT ADMINISTRATION COMPONENTS -->
                        <div>

                            <!-- NIM MEDICATIONS -->
                            <div class="medication-group" >
                                <div>
                                    <div v-for="(drug, i) in patient.nims" :key="i" style="margin-bottom: 1em;">
                                        <div class="medication-group-header"  
                                        style="display: flex; justify-content: flex-start; background-color: #274188; padding: 10px 30px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                            <h2 style="margin: 0;">NIM</h2>
                                            <span style="font-weight: 700; font-size: 18px; line-height: 22px; margin-left: 15em; color: white;">
                                                {{parseFloat(drug.amount_administered).toFixed(2)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.alias.form}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours
                                            </span>
                                        </div>
                                        <div>
                                            <table style="border-collapse: collapse;" >
                                                <tr class="nim-medication-row">
                                                <td style="width: 10em"><div class="drug-image"><img src="../../assets/icons/medPlaceholder.png" alt=""></div></td>
                                                <td style="paddng: 0 10px">
                                                    <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p>{{drug.instructions}}</p>
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag)" >{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="width: 5em; font-weight: bold;">x {{drug.dose}}</td>
                                                <td style="width: 10em; font-weight: bold;">{{drug.route}}</td>
                                                <td style="width: 1em"></td>
                                                <td v-if="!drug.completed" @click="displayNIMAdminModal = true, selectNIM(drug)"  style="width: 1em" >
                                                    <div class="administer-button">ADMINISTER</div>
                                                </td>
                                                <td v-else  style="width: 1em" >
                                                    <div class="administer-button">COMPLETED</div>
                                                </td>
                                                <td style="width: 0em"></td>
                                                </tr>
                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <div v-if="loadingNIMs">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                                    <h4>Loading NIMs...</h4>
                                </div>
                                <div v-if="(nims && !nims.length) && !loadingNIMs">
                                    <h3>No NIMs available for this resident.</h3>
                                </div>
                            </div>
                            
                        </div>
                        </div>
                      
                   </div>
                   
               </div>
                    
               </div>
               <div v-else style="margin: 0 auto; padding-top: 5em;display: flex; align-items: center;">
                    <i class="pi pi-spin pi-spinner" v-if="loadingPatient" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    <!-- <div v-else style="text-align: center;">
                        <h2 style="color: black; margin: 0;" v-if="!loadingAdministration">{{noCharts ? 'No residents are due for administration at this location.' : 'No Location Selected'}}</h2>
                        <p style="font-size: 1.2em; margin: 0;" v-if="!loadingAdministration">Please select {{noCharts ? 'another':'a'}} location to view residents due for administration.</p>
                    </div> -->
                    <div v-if="loadingAdministration">
                        <h3 style="color: black; margin: 0;" >Waiting for patients to be retrieved from location/s.</h3>
                        <p style="font-size: 1.2em; margin: 0;">Please wait...</p>
                    </div>
                    
               </div>
              </div>
              <DOCModal v-if="displayDOCModal" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" :loop="loop" />
              <NIMAdminModal v-if="displayNIMAdminModal" @completeNIM="completeNIM" :selectedNIM="selectedNIM" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" />
            </div>

</template>

<script>
import DOCModal from '@/components/modals/DOCModal.vue';
import NIMAdminModal from '@/components/modals/NIMAdminModal.vue';


export default{
    props:['spec_cons', 'adminChart', 'patient', 'loadingPatient', 'loadingAdministration', 'nims', 'loadingNIMs', 'nimMode', 'hiddenTags'],
    components:{
        DOCModal,
        NIMAdminModal
    },
    data(){
        return{
            displayNIMAdminModal: false,
            loop:false,
            displayDOCModal: false,
            infoADRTab: 'INFO',
            removeFromSafe:[],
            selectedNIM:'',
            // removeFromRegisterPayload is the MAPPED payload of items within the removeFromSafe
            removeFromRegisterPayload:[],
            doseOmittedCode:[
              'A',
              'F',
              'H',
              'L',
              'S',
              'S/A',
              'V',
              'A/T',
              'C',
              'N',
              'N/R',
              'O',
              'R',
              'W',
              'W/R'
            ],
            packedPayload:[],
            submitPayload: {}
        }
    },
    methods:{
        addEffective(effective, drug){
            this.$emit('submitEffective', {
                effective: effective,
                prn_id: drug.uuid
            })
        },
        close(value){
            if(value){
                this.displayDOCModal = false;
                this.displayNIMAdminModal = false;
            }
        },
        retrieveNIM(){
            this.$emit('retrieveNIMs', true);
        },
        selectNIM(drug){
            this.selectedNIM = drug;
            this.selectMedLoad(drug, false);
        },
        getDrugId(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.adminChart.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                }
            }
        },
        completeNIM(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value){
                    this.nims.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.completed = true;
                            chart.administered_at = value.administered_at;
                            this.$emit('completeNIM', value);
                        }
                    })
                } 
            }
        },
        receiveDOC(value){
            console.log('THIS IS THE DOC RECEIVED', value);
            if(value){
                this.adminChart.forEach(chart=>{
                    if(chart.uuid == value.id){
                        chart.doc = value.doc.value;
                        chart.completed = true;
                    }
                })
            }
        },
        removeDrugFromRegister(drug){
            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id:drug.stock_items[0].uuid,
                    quantity:drug.dose 
                }
            
        },
        selectDOC(drug){
            drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },
        
        submitPrimeSignature(drug, status){
            this.$emit('primeSignature', drug, status);
        },
        submitIsCompleted(value){
            console.log('RECEIVED THIS FROM SUBMIT IS COMPLETED', value);
            this.$emit('isCompleted', value);
            if(value){
                this.adminChart.forEach(chart=>{
                    console.log('this is the chart uuid', chart, chart.calendar_item_id, value.item);
                    if(chart.uuid == value.item){
                        console.log('match this');
                        chart.completed = true;
                    }
                })
            }
            this.removeFromSafe = [];
        },
        selectMedLoad(drug, administered){
            this.loop = false;
            this.submitPayload = {
                administered: administered,
                drug_safe_retrieval: drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                calendar_item_id: drug.uuid,
                chart_id: drug.chart_id ? drug.chart_id : drug.uuid,
                doc:'',
                dose: parseFloat(drug.dose).toFixed(2),
                notes:'',
                reading: '',
            }
        }
    },
    mounted(){
    },
    computed:{
        uniqueSpecCons(){
            let b = this.spec_cons &&  this.spec_cons.special_considerations ?  this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

.dummy-icon{
    width: 50px !important;
}
.administered{
    background-color: #00AD50 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}

.not-administered{
    background-color: #E04F39 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}
.medication-group-header{
    h2{
        font-size: 18px;
    }
    span{
        font-size: 18px;
    }
}

.medication-group{
    table{
        td{
            font-size: 16px;
            p{
                margin: 0;
            }
        }
    }
}
.admin-chart-header{
    display: flex;
    padding: 1em;
    padding-top: 0;
}

.drug-image{
    // height: 75px;
    // width: 75px;
    // background-color: white;
    padding-left: 15px;
    img{
        max-height: 100px;
        max-width:120px;
        width: auto;
        height: auto;
    }
}

.administer-button{
    box-sizing: border-box;
    width: 187px;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 6px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.grey-field{
    background-color: #f3f4f4 !important;
    border: none !important;
}

.four-table-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.four-column-entry{
    width: 22%;
    min-width: 120px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.two-column-row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.two-column-entry{
    width: 45%;
    min-width: 200px;
    margin-right: 10px;
}

.medication-group{
    margin-bottom: 10px;
    h2{
        color: white;
        width: fit-content;
    }
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.packed-medication-row{
     background-color: rgba(8, 112, 220, 0.2);  
     td{
         padding: 10px;
     }   
}

.scheduled-medication-row{
     background-color: rgba(224, 79, 57, 0.2);
     td{
         padding: 10px;
     }   
}

.non-packed-medication-row{
     background-color: #F3F4F4;
     td{
         padding: 10px;
     }   
}

.prn-medication-row{
    background-color: #94D6D0;
    td{
        padding: 10px;
    }
}

.nim-medication-row{
    background-color: rgba(39, 65, 136, 0.35);
    td{
        padding: 10px;
    }
}



.pi-check, .pi-times, .pi-sign-out, .pi-lock-open, .pi-eject{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    color: grey;
    cursor: pointer;
}


</style>