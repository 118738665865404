<template>
    <div  class="stock-graph-container">
        <div class="graph-view" v-if="graphData?.length">
            <div>
              <apexchart
                class="card card-third"
                type="line"
                height="440"
                :options="qumData"
                :series="graphData"
              />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['data', 'toggleGraph', 'graphData', 'lineOrBar', 'y_axis_label', 'graph_title'],
    data(){
        return{
         qumData: {
          chart: {
            height: 500,
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            }
          },
          colors: ["#FF1654", "#247BA0"],
          series: [
            // {
            //   name: '2022',
            //   data: [[0,0], [3, 4], [5, 3], [8, 10], [12,12]]
            // },
            // {
            //   name: '2023',
            //   data: [2,12]
            // }
          ],
          legend: {
            onItemClick: {
              toggleDataSeries: false
            }
          },
          stroke: {
            width: 5,
            curve: 'smooth'
          },
          xaxis: {
            // type: 'datetime',
            categories: ['Q1','Q2','Q3','Q4'],
            // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
            tickAmount: 3,
            // labels: {
            //   formatter: function(value, timestamp, opts) {
            //     return opts.dateFormatter(new Date(timestamp), 'dd MMM')
            //   }
            // }
            title: {
                text:'Quarters',
                // rotate: -90,
                offsetX: 0,
                offsetY: 90,
                style: {
                    color: '#666',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
          },
          yaxis: {
            min: 0,
            title: {
                text: this.y_axis_label,
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#666',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            
          },
          title: {
            text: this.graph_title,
            align: 'left',
            style: {
              fontSize: "16px",
              color: '#666'
            }
          },
          markers: {
              size: 6,
              
          }
        },
        }
    },
  methods:{
    parseGraphData(){
      if(this.graphData){
        this.graphData.forEach(year=>{
          let data = [];
          year.data.forEach(point=>{
            let b = [
              point.quarter,
              point.script_count
            ];
            data.push(b);
          });

          let yearLine = {
            'name': year.year,
            data: data
          }
          this.qumData.series.push(yearLine);
        })
      }
    }
  },
  mounted(){
  }
}
</script>