<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">IHI Number History</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr;">
                                <div class="left-form-section-panel" v-if="!loading">

                                    <div v-if="ihi_numbers.length" >
                                            <table class="data-table" cellspacing="0" style="max-height: 50vh; overflow: scroll">
                                                <tr class="table-headers">
                                                    <th style="width: 80px;">IHI Number</th>
                                                    <th style="width: 120px">
                                                        <h3 style="padding-right: 10px;">IHI Number Status</h3>
                                                    </th>
                                                    <th style="width: 80px">
                                                        <h3 >IHI Record Status</h3>
                                                    </th>
                                                </tr>
                                                <tbody class="table-data"  v-for="(item, index) in ihi_numbers" :key="index">
                                                    <tr >
                                                        <td>{{item.number}}</td>
                                                        <td>{{item.ihi_status}}</td>
                                                        <td>{{item.ihi_record_status}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <div v-else>
                                        <p style="font-size: 1em">No ihi history record found.</p>
                                    </div>
                                </div>
                                <div class="left-form-section-panel" v-else>
                                    <p style="font-size: 1em">Loading...</p>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <!-- <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                    </slot>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import axios from 'axios';

export default {
    props: [ "close", 'streamlineOptions', 'patientID'],
    components: {
        // DualVerify
    },
    data() {
        return {
            loading: false,
            ihi_numbers:[
                // {
                //     number:'9393 9393 1100 9999',
                //     ihi_status: 'Active',
                //     ihi_record_status:'Verified'
                // }
            ]
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.closeModal();
                this.$emit('isSuccessful', true);
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        retrieveIHIHistory(){
            this.loading = true;
            axios.post(`/person/${this.patientID}/ihi-history`, {}, this.$store.state.header).then(res=>{
                this.ihi_numbers  = res.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.retrieveIHIHistory();
    },
    computed: {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
