// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import firebase from 'firebase/app';
// import 'firebase/firestore';

import firebase from "firebase/app";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const config = firebase.initializeApp({
  apiKey: "AIzaSyBCGL34U3l9VYgUCyZ9wO60Ip-aCay_o0o",
  authDomain: "strongroom-care-form-saving.firebaseapp.com",
  projectId: "strongroom-care-form-saving",
  storageBucket: "strongroom-care-form-saving.appspot.com",
  messagingSenderId: "528837112142",
  appId: "1:528837112142:web:25f48b089ae2678915ae1f"
})


// Initialize Firebase
// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
 }else {
    firebase.app(); // if already initialized, use that one
 }
export default firebase.firestore();