<template>
    <div class="action-form lateral-form">
        <textarea name="action-text" class="notes-area" style="min-width: 200px" rows="3" placeholder='Notes' v-model="notes"></textarea>
        <!-- <DualVerify @user="getUsername" @pass="getPassword" :password="form.submit.password"  :username="form.submit.username"
        @user2="getUsername2" @pass2="getPassword2" :password2="form.submit.password2"  :username2="form.submit.username2" lateral=true /> -->
        <button :class="notes ? 'submit-button':'grey-button'" @click="notes ? submitForm():''" style="margin-top: 0; margin: 0 auto; width: 95%; align-self: end;" >Balance Check</button> 
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" @close="close" endpoint="/stock-take-submit" :payload="form.submit" />       
        <ConfirmAdjustmentModal v-if="displayConfirmAdjustmentModal" @isSuccessful="isSuccessful" :medicationName="toCache[0].name" :counted="toCache[0].count" :expected="toCache[0].expected"
        endpoint="/stock-take-submit" :payload="form.submit" @close="close" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import ConfirmAdjustmentModal from '@/components/modals/ConfirmAdjustmentModal.vue';


export default {
    props:['toCache', 'clear', 'adjustmentNeeded'],
    components:{
        ConfirmAdjustmentModal,
        SignatureModal
    },
    data(){
        return{
            displayConfirmAdjustmentModal: false,
            displaySignatureModal: false,
            notes:'',
            form:{
                submit:{
                    notes:'',
                    stocktake:[]
                }
            }
        }
    },
    methods:{
        isSuccessful(value){
            if(value){
                this.$emit('reload', true);
                this.form.submit.stocktake = [];
                this.form.submit.notes = '';
            }
        },
        submitForm(){
            // console.log('this is the verify from the destroy form', this.store.verify)
            // this.$store.dispatch('submitForm',this.form);
            if(this.adjustmentFound){
                this.displayConfirmAdjustmentModal = true;
                this.form.submit.notes = this.notes;
                this.form.submit.stocktake = this.$store.state.stockTakeCount;
                this.form.submit.stocktake.forEach(item=>{
                    let action = this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Dispose';
                    if(item.type == 'pending destruction'){
                        this.form.submit.notes = this.form.submit.notes + (item.expected != item.count ? `-- DISCREPANCY FOUND: [EXPECTED: ${item.expected}] - [COUNTED: ${item.count}]` : '') + `[EXPECTED: ${item.expected}] - [COUNTED: ${item.count}]` +`\n --[Pending ${action}]${item.name} ${item.expected == item.count ? `has been accounted for and has been counted`: ``}`
                    }
                })
            } else{
                this.form.submit.notes = this.notes;
                this.form.submit.stocktake = this.$store.state.stockTakeCount;
                this.form.submit.stocktake.forEach(item=>{
                    let action = this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Dispose';
                    if(item.type == 'pending destruction'){
                        this.form.submit.notes = this.form.submit.notes + (item.expected != item.count ? `-- DISCREPANCY FOUND: [EXPECTED: ${item.expected}] - [COUNTED: ${item.count}]` : '') +`\n --[Pending ${action}]${item.name} ${item.expected == item.count ? `has been accounted for and has been counted`: ``} `
                    }
                })
                this.displaySignatureModal = true;
            }
            
            // this.form = {
            //     // type:'stock-take-submit',
            //     submit:{
            //         location:'',
            //         notes:'',
            //         stocktake:this.toCache
            //     }
            // };
            // this.$emit('clear', true);
        },
        close(value){
            if(value){
                this.displaySignatureModal = false;
                this.displayConfirmAdjustmentModal = false;
            }
        }
    },
    computed:{
        adjustmentFound(){
            if(this.toCache && this.toCache.length && this.$store.state.currentLocation.state == 'WA'){
                let adjustment = false;
                this.toCache.forEach(item=>{
                    if((item.count != item.expected) && item.type != 'pending destruction'){
                        adjustment =  true;
                    }
                })
                return adjustment;
            } else{
                return false;
            }
             
        }
    }
    
}
</script>

<style type="scss">
  .action-text{
    background-color: #E5F1FE;
  }
</style>