<template>
  <div class="drug-register-table table-container" style="width: 95.5vw; border-top-right-radius: 0;"
    :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
    <!-- FILTERS -->
    <div class="filters">
      <div class="date-search-container search-container">
        <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
      </div>
      <div class="select-filters-container">
        <p @click="() => { filters['200'] = !filters['200']; autoAll() }"
          v-bind:class="[filters['200'] == true ? 'active-select-filter' : '']" class="select-filter-item">200</p>
        <p @click="() => { filters['400'] = !filters['400']; autoAll() }"
          v-bind:class="[filters['400'] == true ? 'active-select-filter' : '']" class="select-filter-item">400</p>
        <p @click="() => { filters['404'] = !filters['404']; autoAll() }"
          v-bind:class="[filters['404'] == true ? 'active-select-filter' : '']" class="select-filter-item">404</p>
        <p @click="() => { filters['500'] = !filters['500']; autoAll() }"
          v-bind:class="[filters['500'] == true ? 'active-select-filter' : '']" class="select-filter-item">500</p>
      </div>
      <button class="button submit-button" @click="loadReport()">Search</button>
      <button class="button clear-button" @click="clearFilteredIHI()" style="margin-right: 2em">Clear</button>
      <button class="button clear-button" @click="downloadReport()" v-if='$store.state.user.role.permissions.includes("can_export_ihi_report")  && $store.state.currentLocation.state != "WA"'
        style="margin-right: 2em; background-color: #0870DC; color: white">Download CSV</button>
      <button class="button clear-button" @click="printElem('transaction-table')" v-if='$store.state.user.role.permissions.includes("can_export_ihi_report")'
        style="margin-right: 2em; background-color: #0870DC; color: white">Print</button>
      <div style='margin-right: 20em'>
      </div>
    </div>
    <div style='overflow-y: scroll'>
      <div class="drug-register-table table-container" style="padding-top: 10em;"
        v-if='!$store.state.user.role.permissions.includes("can_view_ihi_report")'>
        <h3
          style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
          You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
      </div>
      <div v-else>
        <div v-if="$store.state.loadingIHIData">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 2em;"></i>
        </div>
        <table v-else class="data-table ihi-table" id="transaction-table" cellspacing="0">
          <!-- LOADING SPINNER -->

          <tr class="table-headers">
            <th style="width: 10em">
              <h3 @click="sortBy('recorded', 'desc')">Date</h3>
              <div v-if="form.col == 'recorded'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort recorded-sort" v-if="form.col == 'recorded' && form.sort_direction == 'asc'"
                  @click="sortBy('recorded', 'desc')"></i>
                <i class="pi pi-sort recorded-sort" v-else @click="sortBy('recorded', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3 @click="sortBy('uuid', 'desc')">Req ID</h3>
              <div v-if="form.col == 'uuid'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort uuid-sort" v-if="form.col == 'uuid' && form.sort_direction == 'asc'"
                  @click="sortBy('uuid', 'desc')"></i>
                <i class="pi pi-sort uuid-sort" v-else @click="sortBy('uuid', 'asc')"></i>
              </div>
            </th>
            <th style="width: 10em">
              <h3>HI Name</h3>
            </th>
            <th style="width: 5em">
              <h3>HI Version</h3>
            </th>
            <th style="width: 4em">
              <h3 @click="sortBy('status_code', 'desc')">Status</h3>
              <div v-if="form.col == 'status_code'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort status_code-sort" v-if="form.col == 'status_code' && form.sort_direction == 'asc'"
                  @click="sortBy('status_code', 'desc')"></i>
                <i class="pi pi-sort status_code-sort" v-else @click="sortBy('status_code', 'asc')"></i>
              </div>
            </th>
            <!-- FIX THIS SORT - UNABLE TO SORT ON NAME AS NOT IN QUERY -->
            <th style="width: 10em">
              <h3>User</h3>
            </th>
            <!-- FIX THIS SORT - UNABLE TO SORT ON HPI-I AS NOT IN QUERY -->
            <th style="width: 7em">
              <h3>HPI-I</h3>
            </th>
            <!-- FIX THIS SORT - UNABLE TO SORT ON HPI-I AS NOT IN QUERY -->
            <th style="width: 10em">
              <h3>HPI-O</h3>
            </th>
            <!-- FIX THIS SORT - UNABLE TO SORT ON IHI AS NOT IN QUERY -->
            <th style="width: 10em">
              <h3>IHI</h3>
            </th>
            <!-- FIX THIS SORT - UNABLE TO SORT ON CODE AS NOT IN QUERY -->
            <th style="width: 7em">
              <h3>HI Msg ID</h3>
            </th>
          </tr>

          <tbody class="table-data" v-for="(item, index) in getDrugEntries.data" :key="index">
            <tr>
              <td>{{ item.recorded }}</td>
              <td>{{ item.uuid }}</td>
              <td>{{ item.hi_name }}</td>
              <td>{{ item.hi_version }}</td>
              <td style="font-weight: bold" :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">
                {{ item.status_code }}
              </td>
              <td v-if="item.user && item.user.personal_information">
                {{ item.user.personal_information.first_name }} {{ item.user.personal_information.last_name }}
              </td>
              <td v-else></td>
              <td v-if="item.user && item.user.hpi_i && item.user.hpi_i.number">
                {{ item.user.hpi_i.number }}
              </td>
              <td v-else></td>
              <td v-if="item.company_hpio">{{ item.company_hpio.data }}</td>
              <td v-else></td>
              <td v-if="item.request_response && item.request_response.response">
                {{ item.request_response.response.ihi_number }}
              </td>
              <td v-else></td>
              <td v-if="item.request_response && item.request_response.response">
                {{ item.request_response.response.uuid }}
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>

        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <div style="width: 100vw">
          <table class="data-table print-tx-table tx-table" id="transaction-table" cellspacing="0">
            <tr>
              <th style="width: 10em">Date</th>
              <th style="width: 10em">Req ID</th>
              <th style="width: 10em">HI Name</th>
              <th style="width: 5em">HI Version</th>
              <th style="width: 4em">Status</th>
              <th style="width: 10em">User</th>
              <th style="width: 7em">HPI-I</th>
              <th style="width: 10em">HPI-O</th>
              <th style="width: 10em">IHI</th>
              <th style="width: 7em">HI Msg ID</th>
            </tr>
            <tr v-for="(item, index) in getDrugEntries.data" :key="index">
              <td>{{ item.recorded }}</td>
              <td>{{ item.uuid }}</td>
              <td>{{ item.hi_name }}</td>
              <td>{{ item.hi_version }}</td>
              <td>V#</td>
              <td style="font-weight: bold" :style="item.status_code == '200' ? { fontColor: 'green' } : { fontColor: 'red' }">
                {{ item.status_code }}
              </td>
              <td v-if="item.user && item.user.personal_information">
                {{ item.user.personal_information.first_name }} {{ item.user.personal_information.last_name }}
              </td>
              <td v-else></td>
              <td v-if="item.user && item.user.hpi_i && item.user.hpi_i.number">
                {{ item.user.hpi_i.number }}
              </td>
              <td v-else></td>
              <td v-if="item.company_hpio">{{ item.company_hpio.data }}</td>
              <td v-else></td>
              <td v-if="item.request_response && item.request_response.response">
                {{ item.request_response.response.ihi_number }}
              </td>
              <td v-else></td>
              <td v-if="item.request_response && item.request_response.response">
                {{ item.request_response.response.uuid }}
              </td>
              <td v-else></td>
            </tr>
          </table>
        </div>
        <!-- PRINT IHI TABLE -->
        <!-- PRINT IHI TABLE -->
        <!-- PRINT IHI TABLE -->

        <!-- IF NO DATA DISPLAYED -->
        <div v-if="!$store.state.loadingIHIData && (getDrugEntries.data && !getDrugEntries.data.length)">
          <h4>No log entries to display.</h4>
        </div>
      </div>
      <div v-if="$store.state.reportError">
        <h3 style="color: #E04F39">{{ $store.state.reportError }}</h3>
      </div>
    </div>

    <div class="pagination-links">
      <p class="pagination-arrow" v-show="getDrugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr;
      </p>
      <p>Page {{ getDrugEntries.current_page }} of {{ getDrugEntries.page_count }}</p>
      <p class="pagination-arrow" v-show="getDrugEntries.current_page < getDrugEntries.page_count"
        @click="displayPage(getDrugEntries.current_page + 1)"> &rarr; </p>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  data() {
    return {
      date: [],
      filters: {
        '200': true,
        '400': true,
        '500': true,
        '404': true,
      },
      clearForm: {
        sort_direction: 'desc',
        col: '',
        page: 1,
        filters: {
          status_code: [],
          date: {
            start: '',
            end: '',
          }
        }
      },
      form: {
        sort_direction: 'desc',
        col: '',
        page: 1,
        filters: {
          status_code: [],
          date: {
            start: '',
            end: '',
          }
        }
      }
    }
  },
  methods: {
    autoAll() {
      this.form.filters.status_code = [];
      for (const [key, value] of Object.entries(this.filters)) {
        if (value == true) {
          this.form.filters.status_code.push(key)
        }
      }
      console.log('this is what is inside the form tags now', this.form.filters.status_code);
    },
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getIHIReportEntries', this.form)
    },
    loadReport() {
      console.log('this is the date', this.date);
      if (this.form.filters.status_code.length) {
        let codes = [];
         this.form.filters.status_code.forEach(code => {
          code = parseInt(code);
          console.log('THIS IS THE STATUS CODE', code);
          codes.push(code);
        });
        this.form.filters.status_code = codes;
        console.log('THIS IS TOTAL CODES', this.form.filters.status_code);
      }
      if (this.date.length) {
        if (typeof this.date == "string") {
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        this.$store.dispatch('getIHIReportEntries', this.form);
      } else {
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getIHIReportEntries', this.form);
      }
      console.log('this is the report', this.form);
    },
    downloadReport() {
      let csv;

      axios.post('/download-ihi-report-entries', this.form, this.$store.state.header).then((res) => {
          csv = res.data;
      }).then(() => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = `ihi_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
          anchor.click();
      })
    },
    displayPage(page) {
      this.form.page = page;
      this.$store.dispatch('getIHIReportEntries', this.form);
    },
    clearFilteredIHI() {
      this.date = [],
      this.filters = {
        '200': true,
        '400': true,
        '500': true,
        '404': true,
      },
      this.form = {
        sort_direction: 'desc',
        col: '',
        page: 1,
        filters: {
          status_code: [],
          date: {
            start: '',
            end: '',
          }
        }
      };
      this.$store.dispatch('getIHIReportEntries', this.form);
    },
    printElem(elem) {
      console.log('this is the eleme', elem);
      window.print()
    }
  },
  mounted() {
    this.loadReport();
  },
  computed: {
    getDrugEntries() {
      // getStockReport
      console.log('this is the getIHI report stuff', this.$store.getters.getIHIReport);
      return this.$store.getters.getIHIReport;
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>