<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <h3 class="form-header">Resident created</h3>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <div style="display: flex;margin: 20px;padding: 0px 15px 0px 15px;flex-direction: column;text-align: center;">
                            <ul>
                                <li v-for="(stage,index) in stages" :key="index">
                                    {{ stage }}
                                </li>
                            </ul>
                        </div>
                    </slot>
                </div>



                <div class="modal-footer" style="display: flex; justify-content:flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <button class='red-button' style="margin-right: 10px;" @click="cancelAction()">
                            Cancel
                        </button>
                        <button class='regular-button complete-button' @click="proceedAction()">
                            Continue to Patient Information
                        </button>
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    props: ['stages'],
    methods: {
        cancelAction() {
            this.$emit('close', true);
        },
        proceedAction() {
            this.$emit('proceed', true);
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



.p-inputgroup {
    display: grid;
    grid-template-columns: 50% 50%;
}





.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}



.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

ul {
    list-style-type: none;
  }


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
