<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="displayDeliveryModal = true" class="actions-menu-item">Returns</button>
    </div>
    
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
      <h3 class="panel-header">Returns</h3>
      <div class="drug-register-table table-container" style="display: flex;flex-direction: column;">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: end; height: max-content; justify-content: space-between;margin-bottom: 20px;">
          <div style="display: flex; align-items: end;gap: 12px;flex-wrap: wrap">
          <!-- SEARCH SECTION -->

            <div class="date-search-container search-container calenderdatelabel">
              <label for="calenderDate"> Date</label>
                <div class="calendar-input-container" id="calenderDate">
                 <i class="pi pi-calendar"></i>
                  <Calendar v-model="date"  selectionMode="range" placeholder="Select a Date..." dateFormat="dd/mm/yy" />
                 </div>
            </div>

            <div class="text-search-container search-container calenderdatelabel">
              <label for="medication">Medication</label>
              <div class="calendar-input-container">
                 <i class="pi pi-search"></i>
             <input id="medication" v-model="form.filters.drug" @input="debouncedLoadEntries" placeholder="Search a Medication..." style="position: relative" />
            </div>
            </div>

            <div class="text-search-container search-container calenderdatelabel">
              <label for="supplier">Client</label>  
              <div class="calendar-input-container">
                 <i class="pi pi-search"></i>
             <input v-model="form.filters.party" @input="debouncedLoadEntries" id="supplier" placeholder="Search a Client..." style="position: relative" />
            </div>
            </div>

          <!-- SELECT FILTER SECTION -->
            <div class="calenderdatelabel">
              <label for="multistatusselect">Status</label>
               <MultiSelect class="multiselectstatus" id="multistatusselect" v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Select Status..." />
            </div>

            <!-- <div class="text-search-container search-container">
             <input v-model="form.filters.client" placeholder="Client..." style="position: relative" />
            </div> -->

            <!-- <div class="calenderdatelabel">
              <label for="allTypes">Stock Type</label>
              <button class="button types-button" id="allTypes">All Types</button>
            </div> -->

          
            <!-- <div style="display: flex; align-items: center;"> -->
              <!-- <div class="select-filters-container">
                <button @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter s8-button' : 'outline-button s8-button']">S8 Only</button>
            </div> -->
            <!-- </div> -->
            <button class="button clear-button" @click="clearEntriesFilters()">Clear</button>
            <button class="button submit-button">Search</button>

            <!-- Sync -->
            <button class="button submit-button" style="background-color: #b5a248;" @click="syncOrders()">Sync</button>
            <button style="height: 37px;" :class="ordersToSign > 0 ? 'red-button' : 'blue-button'" @click="toggleSelectAll(ordersToSign > 0 ? false : true)">{{ ordersToSign > 0 ? 'Unselect All' : 'Select All'}}</button>
            <button style="height: 37px;" class="blue-button" :class="ordersToSign > 0 ? 'blue-button' : 'grey-button'" @click="signOrders()">Sign</button>
            <div class="calenderdatelabel" style="display: flex;flex-direction: row;align-items: center;gap: 10px;height: 37px;">
            <label for="attentionRequired" style="margin: 0px;">Attention Required</label>
              <InputSwitch id="attentionRequired" v-model="checked" @change="updateListing"/>
            </div>
            <!-- <div class="text-search-container search-container">
             <input v-model="form.filters.drug" placeholder="Drug..." style="position: relative" />
            </div> -->

         
          <!-- <div style="display: flex; align-items: center">
              <div class="select-filters-container">

              <p @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter' : 'outline-button']" :style="tags.s8 ? { paddingTop: '8px'}:{paddingTop: '6px'}" style="height: 33px;">S8 Only</p>

            </div>
          </div> -->
          <!-- <div style="display: flex; gap: 10px; align-items: center">
            <button  :class="ordersToSign > 0 ? 'red-button' : 'blue-button'" @click="toggleSelectAll(ordersToSign > 0 ? false : true)">{{ ordersToSign > 0 ? 'Unselect All' : 'Select All'}}</button>
            <button style="margin-left: 10px;" class="blue-button" :class="ordersToSign > 0 ? 'blue-button' : 'grey-button'" @click="signOrders()">Sign</button>
            
          </div> -->
          
          </div>
          
        </div>
        <div v-if="!$store.state.loadingDeliveriesData" style="display: flex; align-items: start; overflow-x: hidden">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else class="data-table" cellspacing="0">
          <tr class="table-headers tableHeader" >
            <th style="width: 20px"></th>
             <!-- <th style="width: 60px;">
              <h3 style=" padding-right: 20px;">Select</h3>
            </th> -->
            <th>
              <h3 @click="sortBy('created_at', 'desc')">Delivery Date</h3>
              <!-- <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('created_at', 'desc')">Medication</h3>
              <div v-if="form.col == 'client'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  client-sort" v-if="form.col =='client' && form.sort_direction =='asc'" @click="sortBy('client', 'desc')"></i>
              <i class="pi pi-sort client-sort" v-else @click="sortBy('client', 'asc')"></i>
              </div>
            </th>
            <th>
              <!-- <h3 >Products</h3> -->
              <!-- <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3>Invoice Number</h3>
              <!-- <div v-if="form.col == 'delivery_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  delivery_date-sort" v-if="form.col =='delivery_date' && form.sort_direction =='asc'" @click="sortBy('delivery_date', 'desc')"></i>
              <i class="pi pi-sort delivery_date-sort" v-else @click="sortBy('delivery_date', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3>Completed By</h3>
              <!-- <div v-if="form.col == 'delivery_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  delivery_date-sort" v-if="form.col =='delivery_date' && form.sort_direction =='asc'" @click="sortBy('delivery_date', 'desc')"></i>
              <i class="pi pi-sort delivery_date-sort" v-else @click="sortBy('delivery_date', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('created_at', 'desc')">Completed Date</h3>
              <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  date-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
              <i class="pi pi-sort date-sort" v-else @click="sortBy('created_at', 'asc')"></i>
              </div>
            </th>
            <th style="padding-left: 20px; width: 200px">
              <h3>Status</h3>
              <!-- <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
              <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i> -->
            </th>
            <!-- <th style="width: 60px;">
              <h3 style=" padding-right: 20px;">Select</h3>
            </th> -->
            

          </tr>
          <tbody class="table-data">
            <tr @click="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                <td style="width: 20px;">
                    <!-- <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                    <i v-else class="pi pi-angle-right"></i> -->
                </td>
                <!-- <td>
                  <div @click.stop="() => { selectItem(item, index) }" style="cursor: pointer" v-if="checkboxCondition(item)">
                    <div v-if="item.to_sign && (item.to_sign == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>              
                  </div>
                </td> -->
                <td>{{item.delivery_date}}</td>
                <td>{{item.client || '-'}}</td>
                <!-- <td>{{ item.medication_count || '-' }}</td> -->
                <td></td>
                <td>{{ item.invoice_number || '-' }}</td>
                <td>{{ item.reference_number || '-' }}</td>
                <td>{{item.completed_date || '-'}}</td>
                 <td><button style="max-width: 175px;" :class="[item.status == 'completed' ? 'completed-button' : (item.status == 'pending' || item.status == 'Partial' ? 'reversed-button' : 'button pending-button')]">{{ item.status ? item.status.slice(0,1).toUpperCase()+item.status.slice(1) + ' Return' :'' }}</button></td>
                <!-- <td>
                  <div @click.stop="() => { selectItem(item, index) }" style="cursor: pointer" v-if="item.status == 'pending'">
                    <div v-if="item.to_sign && (item.to_sign == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>
                  </div>
                </td> -->
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr;
               border-width: 0px 1px 0px 1px; border-style: solid; border-color: #d5d7d7;width: 83vw;">
                  <div v-if="!item.note && item.medications" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                  <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;
                  display: flex;flex-direction: column;gap: 30px;width: 100%;margin: 0px">
                   <div class="transfer-notes-container" style="width: 47%; min-width: 30em;">
                     <div>
                       <h4 class="field-title">Reference Number</h4>
                       <div class="field-notes" style="width: 40%; height: fit-content;border: none;padding: 5px;">
                           <p >{{item.reference_number}}</p>
                       </div>
                     </div>
                       
                   </div>
                   <div class="transfer-drugs-container" style="display: flex;max-width: 100%;width:100%  ">
                    <div style="width: 100%;">
                       <h4 class="field-title">Note</h4>
                       <div class="field-notes" style="width: 95%; height: 245px;padding: 8px;">
                           <p >{{item.expandedInfo.notes}}</p>
                       </div>
                     </div>
                     <!-- <div style="display: flex;flex-direction: column;width: 100%;">
                       <h4 class="field-title">Medications</h4>
                       <div class="drug-field-tables" style="width: 100%;
                                                                border-radius: 15px;
                                                                height: 245px;">
                        <table style="padding: 0px 15px; border-bottom: 3px solid #E7E9E8;
                        border-radius:15px 15px 0px 0px;background-color: #F3F4F4;">
                            <tr style="height: 50px;">
                              <th style="background-color: #F3F4F4;width: 68%;">Medications</th>
                                <th style="width: 100px;background-color: #F3F4F4">Count</th>

                            </tr>
                        </table>
                        <div class="drugtablediv" style="height: 185px; overflow-y: auto; background-color: white;border-radius:0px 0px 15px 15px">
                            <table style="padding: 0;">
                                <tr  style="height: 50px;" class="drugtablerow" v-for="(value,key) in item.expandedInfo.drugs" :key="key">
                                    <td style="width: 70%;padding:0px;padding-left: 20px;">{{value.alias.name.drugs}}</td>
                                    <td style="width: 100px;
                                    padding:0px;">{{value.count}}</td>
                                </tr>
                            </table>
                        </div>
                       </div>
                   </div> -->
                  </div>
                  </div>
               </div>
                <div class="transfer-actions-container" style="width: 83vw;display: flex; align-items: center;gap: 10px;border-width: 0px 1px 1px 1px; border-style: solid; border-color: #d5d7d7;border-radius: 0px 0px 8px 8px;">
                  <div style="display:flex;justify-content:end;gap:10px;width: 97.5%;margin-left: 18px;">
                    <!-- <button class="button pending-button" style="min-width: 200px;">Reverse Delivery</button> -->
                    <button  v-if="item.status !== 'completed'" class="button completed-button" style="min-width: 200px;" @click="completedDeliveryDilog(item)">Send Return</button>
                    <!-- <button @click="signSingleOrder(item.uuid)" class="green-button" v-show="item.status == 'pending'">Sign Invoice</button>
                    <button @click="transferAction('Reject', item.uuid, item.expandedInfo.drugs)" class="transfer-action-button error-button" v-show="item.status == 'Pending'">Reject Delivery</button>
                    <button @click="transferAction('Reverse', item)" class="transfer-action-button error-button" v-show="item.status == 'completed'">Reverse Delivery</button> -->
                    <button @click="showContent(item, index)" class="crossbutton"><i class="pi pi-times"></i></button>
                  </div>
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>
       <div v-else>
          <h3 v-if="$store.state.deliveryError">{{$store.state.deliveryError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries && $store.state?.drugEntries?.meta?.current_page">
            <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state?.drugEntries?.meta?.current_page}} of {{$store.state?.drugEntries?.meta?.last_page}}</p>
            <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page < $store.state?.drugEntries?.meta?.last_page" @click="displayPage( $store.state?.drugEntries?.meta?.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       <!-- <ActionModal :toAction="toAction" /> -->
       <!-- <SignatureModal v-if="displayActionModal" @close="close" @isSuccessful="isSuccessful" :payload=payload  dual="true" :notes=requireNotes /> -->
       <DeliveryModal transferType="Returns" v-if="displayDeliveryModal" @close="close" buttonName="New Returns"  />
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <compDeliveryModal :form="form"   :openInvoice="openInvoice" :isStatus="isStatus" :openstatus=completedDelivery @close="closeDelivery" @changestatus="changestatus" noteName="Return" notePlaceholder="Enter notes here..." :successName="successName"/>

  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
// import SignatureModal from '@/components/modals/SignatureModal.vue';
import DeliveryModal from '@/components/modals/DeliveryModal.vue';
import axios from 'axios';
import compDeliveryModal from '@/components/modals/compDeliveryModal.vue';
import debounce from "debounce"


export default {
  components: { 
      // SignatureModal,
      DeliveryModal,
      compDeliveryModal
  },
  data(){
    return{
        checked:false,
        isStatus:"",
        successName:'Order Sent!',
        facility_id:null,
        completedDelivery:0,
        openInvoice :null,
        requireNotes: false,
        endpoint: '',
        loadingError:'',
        displayNewTransferModal: false,
        displayActionModal: false,
        displayDeliveryModal: false,
        toAction:{
            uuid:'',
            action: 'Approve',
            drugs:[]
        },
        selectedStatuses: [

		],
		statusSelect: [
            {status:'Pending Return'},
            // {status:'Partial'},
            {status:'Completed Return'}
		],
        tags:{
            s8:false,
            s4: false,
            antimicrobial: false,
        },
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true
      },
      toCache:[],
      date:[],
         clearForm:{
            sort_direction: 'desc',
            col: 'created_at',
            page:1,
            filters:{
                tags:[],
                status:[],
                party:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                }
            },
            
        },
      form:{
            sort_direction: 'desc',
            col: 'created_at',
            facility_id: this.$store.state.currentLocation.uuid,
            page:1,
            filters:{
                // tags:[],
                status:[],
                party:'',
                drug:'',
                // date:{
                //   start:'',
                //   end:''
                // }
                delivery_date:''
            },
            
        },
      testData:[
      ],
      payload:{}
      
    }
  },
  methods:{
    toggleSelectAll(select){
      this.getDrugEntries.forEach(entry=>{
        if(entry.status == 'pending' || entry.status == 'partial'){
          if(select){
          entry.to_sign = true;
        } else{
          entry.to_sign = false;
        }
        }
        
      })
    },
    // signOrders(){
    //   if (this.ordersToSign > 0) {
    //   this.payload = {
    //     invoice_id:[]
    //   }
    //   this.endpoint = '/warehouse/sign-off-orders'
    //   this.getDrugEntries.forEach(order=>{
    //     if(order.to_sign){
    //       this.isStatus = "bulk"
    //       this.payload.invoice_id.push(order.invoice_id);
    //     }
    //   })
    //   // this.displayActionModal = true;
    //   this.completedDelivery = 1,
    //   this.openInvoice = this.payload
    // }
    // },
    signSingleOrder(id){
      this.payload = {
        orders:[id]
      }
      this.endpoint = '/warehouse/orders/sign'
      this.displayActionModal = true;
    },
    // close(value){
    //     console.log('delivery modal should be closing here, outside if');
    //     if(value){
    //         console.log('delivery modal should be closing here');
    //         this.displayActionModal = false;
    //         this.displayDeliveryModal = false;            
    //     }
    // },
    close(value,modalname){
        console.log('delivery modal should be closing here, outside if');
        if (value === 3) {
          if (modalname) {
            this.successName = modalname
          }
          this.$store.dispatch('getOrders', this.form);
        }
        if(value){
            console.log('delivery modal should be closing here');
            // this.displayActionModal = false;
            this.closeDelivery(value)
            this.displayDeliveryModal = false;       
        }
    },
    selectItem(item, index) {
     console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].to_sign) {
        this.getDrugEntries[index].to_sign = false;
      } else {
        this.getDrugEntries[index].to_sign = true;
      }

    },
    checkboxCondition(item) {
      if (item.status === 'pending' || item.status === 'partial') {
      if (this.$store.state.user.role.permissions.includes('signature_primary')) {
          return true
      } else {
          if(this.$store.state.user.role.permissions.includes('signature_witness') === true){
            if (item.status === 'pending'  || item.status === 'partial') {
              return true
            }else{
              return false
            }
          }else if(this.$store.state.user.role.permissions.includes('single_signature_primary')){
              return true
          }
      }
    }
    },
    toggleNewTransferModal(type){
        this.transferType = type;
        this.displayNewTransferModal = true;
    },
    transferAction(selectedAction, item, drugArray){
        console.log('this is the action id', item.uuid, selectedAction);
        console.log('TRANSFER ACTION FUNCTION', drugArray);
        this.toAction = {
            uuid: item.uuid,
            notes: item.expandedInfo.note
        }
        this.displayActionModal = true;
    },
    isSuccessful(value){
      console.log('this has been', value);
      this.$store.dispatch('getOrders', this.form);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      // this.loadEntries();
    },
    showContent(delivery, index){
        console.log('this the selected log', delivery)
        // let refid=`expand-${index}`;

        if(delivery.display && (delivery.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            delivery.display = !delivery.display;
        } else{
            delivery.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
          // axios.post('/warehouse/orders-detail', {
          //   invoice_id: delivery.invoice_id}, this.$store.state.header).then(res => {
          //   let entries = res.data.data[0];
          //       delivery.note = entries?.note
          //       delivery.reference_number = entries?.reference_number
          //       delivery.medications = entries?.medications
          // }).catch(err => {
          //   this.loadingError = err;
          //   console.log('this is the error, err');
          // })
        }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadEntries(){
      console.log('this is the date', this.date);
      if(this.date && this.date.length > 0){
          const date = new Date(this.date[0]);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
        if(typeof this.date == "string"){
          // this.form.filters.date.start = this.date.split("-")[0]
          // this.form.filters.date.end = this.date.split("-")[1]
          this.form.filters.delivery_date = this.date?.length > 0 ? formattedDate : '';
        } else{
          // this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          // this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
          this.form.filters.delivery_date = this.date?.length > 0 ?  formattedDate : '';
        }
        // this.$store.dispatch('getOrders', this.form);
      } else{
        // this.form.filters.date.start ='';
        // this.form.filters.date.end = '';
        this.form.filters.delivery_date = ''
        // this.$store.dispatch('getOrders', this.form);
      }
    },

    debouncedLoadEntries: debounce(function() {
      // this.loadEntries();
    }, 500),

    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getOrders', this.form);
    },
    updateStatusList(){
      this.checked = false;
        this.form.filters.status = [];
        console.log('updating status list')
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                console.log('this is the key', key);
                this.form.filters.status.push(value.toLowerCase());
             }
        });
        this.debouncedLoadEntries();
    },
    // updateListing(){
    //   this.selectedStatuses = [{0:'pending'},{1:'partial'}]
    //   this.updateStatusList()
    // },
     updateListing(){
      this.form.filters.status = [];
      if (this.checked) {
        this.selectedStatuses = [
            new Proxy({ status: 'Pending' }, {}),
            new Proxy({ status: 'Partial' }, {})
        ];
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                this.form.filters.status.push(value.toLowerCase());
             }
        });
      }else {
        this.selectedStatuses =[]
      }
      // this.loadEntries();
    },
    updateType(){
        this.form.filters.type = [];
        for (const [key, value] of Object.entries(this.types)) {
            if(value == true){
                this.form.filters.type.push(key)
            }
        }
    },
    clearEntriesFilters(){
      this.form = {
            sort_direction: 'desc',
            facility_id: this.$store.state.currentLocation.uuid,
            col: 'created_at',
            page:1,
            filters:{
                // tags:[],
                status:[],
                party:'',
                drug:'',
                // date:{
                //   start:'',
                //   end:''
                // }
                 delivery_date: '',
            },
            
        },
         this.selectedStatuses = []
      this.date = [];
        this.checked = false
      this.$store.dispatch('getOrders', this.form);
    },
    // completedDeliveryDilog(item){
    //   this.completedDelivery = 1
    //   this.openInvoice = item
    // },
      completedDeliveryDilog(item){
      this.completedDelivery = 1
      this.openInvoice = {
         invoice_id: [ item?.invoice_id ]
      }
      this.isStatus = item?.status

        //  this.isStatus = order?.status
    },
    
    closeDelivery(value){
      this.completedDelivery = value   
    },
    changestatus(value){
      this.completedDelivery = value
    },
    // syncOrders() {
    //   axios.post('/integration/sync-orders',{facility_id: this.facility_id}, this.$store.state.header).then(res=>{
    //     if (res?.data === 200) {
    //       this.$store.dispatch('getOrders',this.form)
    //       return res;
    //     }else{
    //       this.loadingError = "Something went wrong!";
    //     }
    //   }).catch(err=>{
    //       this.loadingError = err;
    //       console.log("syncerror>>>", err)
    //   })
    // }
  },
   mounted(){
    // this.loadEntries();
    this.toCache = [];
    this.$store.state.availableLocations?.forEach(location=>{
        this.facility_id = location?.uuid
      });
  },
  computed:{
    getDrugEntries (){
      console.log("drugEntries>>>>",this.$store.state?.drugEntries.data); 
      return this.$store.getters.getDrugEntries
    },
    ordersToSign(){
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.to_sign) {
            num += 1;
          }
        })

      }
      return num;
    },


  },
  watch:{
    date:{
     handler(newValue){
      if (newValue?.length > 0) {
        this.debouncedLoadEntries()
      }
     },
     deep: false
    }
  }
}
</script>

<style lang="scss" scoped>
.p-checkbox-icon{
  line-height: normal !important;
}
input.p-inputtext::placeholder {
    color: #282828 !important;
    font-weight: normal !important;
    font-size: 15px !important;
  }
 .filters {
   div {
     margin-right: 0px !important;
   }
 }
 .submit-button {
   margin-right: 0px !important;
 }
 .clear-button {
   margin-right: 0px !important;
 }
</style>