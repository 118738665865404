<template>
    <div>
        <div style="display: inline-block;display:flex ;justify-content: start;align-items: center; padding-left: 10px;">
        <h4 style="margin-bottom: 0px;margin-top: 20px;">{{ arrayOfAlertKind.AlertType }}</h4>
        <span style="margin-top: 20px;" class="alert-count">
            <p style="display: inline-block;">{{ numberOfAlerts }}</p>
        </span>
    </div>
        <div class="alerts-content-container" v-for="singlealert in arrayOfAlertKind.alerts" :key="singlealert.id">
            <div class="alert-item">
                <div style="display: grid; grid-template-columns: 1fr 0.2fr">
                    <p class="alert-item-content" style="padding-left: 10px;font-size: 15px;">{{ singlealert.body.message.split('||')[1] }}</p>
                    <p v-if="singlealert.body.message.split('||').length && singlealert.body.message.split('||')[0]"
                        style="font-weight: bold;">{{ singlealert.body.message.split('||')[0] }}</p>
                </div>
            </div>
            <div>
                <button class="small-red-close-button" v-if="arrayOfAlertKind.AlertType !== 'Missed Stocktake'" >
                    <i class="pi pi-times" style="padding: 0" @click="dismissAlert(singlealert.id, singlealert.index, $event)"></i>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['arrayOfAlertKind'],
    methods: {
        dismissAlert(id, index, event){
            event.stopPropagation(); 
            this.$store.dispatch('readAlert', {'id': id, 'index': index});
        }
    },
    computed: {
        numberOfAlerts() {
            return Object.keys(this.arrayOfAlertKind.alerts).length
        }
    }




}
</script>

<style lang="scss" scoped>

.alert-count {
    color: white;
    background-color: #E04F39;
    border-radius: 8px;
    font-size: 13px;
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;

    p {
        margin: 0;
    }
}
.alerts-content-container {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #a4a4a4;
    // background-color: rgba(224,79,57, 0.5);
    // background-color: #f3f4f4;
    gap: 10px;
    justify-content: space-between;
}

.alert-items {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.alert-item-content {
    text-align: left;
    margin-top: 0px;
}

.small-red-close-button {
    background-color: #E04F39;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;

    .pi {
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}
</style>