<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <h3 class="form-header">{{ statusMessage }} </h3>
                    <button @click="loadingDelay ? cancelAction() : closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div>
                                    <h3>Chart Review Summary</h3>
                                    <!-- <p class="grey-button" style="width: fit-content; margin: 0 auto;">{{ numCancelled }} Prescriptions cancelled</p> -->
                                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                        <!-- <p style="font-size: 1em;"><b>{{payload.prescriptions.length}}</b> Prescriptions in Chart Review</p> -->
                                        <div style="display: grid; grid-gap: 1em">
                                            
                                            <div>
                                                <p style="font-size: 1em"><b>{{numSigned}}</b> Signed Prescriptions</p>
                                                <p class="outline-button no-signature-required"
                                                    style="margin: 0 auto;display: flex; align-items: center; font-weight: bold; width: 6em; justify-content: center;">SIGNED</p>
                                                <p style="font-size: 1em">Signed (completed) prescriptions <b>[except Stat, Short-term, Phone Orders & S8 prescriptions]</b> will be cancelled and recreated with their end date set to the next chart review period, 12 weeks from now.</p>
                                                <p style="font-size: 1em"><b>Stat, Short-term, Phone Orders & S8 prescriptions</b> will continue until their original stop date or administration time.</p>
                                                
                                            </div>
                                            <div>
                                                <p style="font-size: 1em"><b>{{numDraft}}</b> Draft Prescriptions</p>
                                                <p class="outline-button signature-required"
                                                    style="margin: 0 auto;display: flex; align-items: center; font-weight: bold; width: 6em;justify-content: center;">DRAFT</p>
                                                <p style="font-size: 1em">Draft prescriptions will be signed and have their end dates set to the next chart review period, 12 weeks from now. </p>
                                                <div style="border: 1px solid red; border-radius: 4px; padding: 0 15px; width: fit-content; margin: 0 auto">
                                                    <p style="font-size: 1em; color: red;"> <b>Phone Orders</b> requiring a signature (REQ. SIG.) must be signed outside of a chart review.</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;"
                    v-if="!loading">
                    <slot name="footer" style="display: flex;">
                        <button class="green-button" v-if="action && actionType == 'sign'" @click="signAction()">
                           {{!noAuthRequired ? 'Sign': 'Complete'}}
                        </button>
                    </slot>
                </div>
                <div class="modal-footer" style="display: flex; justify-content:center; padding: 5px 20px;"
                    v-if="loadingDelay">
                    <slot name="footer" style="display: flex;">
                        <button class="grey-button" @click="cancelAction()">
                            Cancel Process
                        </button>
                    </slot>
                </div>
            </div>

        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" :noAuthRequired="noAuthRequired" />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';

export default {
    props: ['message', 'close', 'first_spec_line', 'second_spec_line', 'signature_endpoint', 'reloadPageOnClose', 'loading',
        'statusMessage', 'payload', 'action', 'actionType', 'loadingDelay', 'noAuthRequired', 'numSigned', 'numDraft', 'numCancelled'],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
        }
    },
    methods: {
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.$emit('successfulReview', true);
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
