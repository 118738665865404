<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 40%; min-width: 500px">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{ buttonName === 'New Deliveries' ? 'Add Supplier' : buttonName === 'New Orders' ? 'Add Client' : 'Add Warehouse'}}</h3>
                        <button @click="closeModal"  class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body" style="padding: 0px 20px;">
                    <slot name="body">
                        <form class="action-modal">
                            <div class="form-section-container" style="display:flex;padding: 0px;gap: 45px;">
                                        <div style="flex: 1">
                                            <div class="right-form-section-drug-entry">
                                                <label for="name">{{ buttonName === 'New Deliveries' ? 'Supplier Name' : buttonName === 'New Orders' ? 'Client Name' : 'Warehouse Name'}}<span style="color:red">*</span></label>
                                                <input v-model="suppliers[0].name" placeholder="Enter name..." id="name"
                                                    style="position:relative;width: 100%;" />
                                            </div>

                                            <div class="right-form-section-drug-entry">
                                                   <label for="contactmap">Address<span style="color:red">*</span></label>
                                                    <vue-google-autocomplete id="contactmap" classname="form-control"
                                                        v-model="suppliers[0].address"
                                                        :placeholder="registerCompanyAddress.address ? registerCompanyAddress.address.street_address + ', ' + registerCompanyAddress.address.suburb + ', ' + registerCompanyAddress.address.country : 'Address...'"
                                                        v-on:placechanged="getPersonalAddressData"
                                                        :country="$store.state.country"></vue-google-autocomplete>
                                            </div>

                                            <!-- <div class="right-form-section-drug-entry">
                                                <label for="city">City<span style="color:red">*</span></label>
                                                <input  v-model="suppliers[0].city" placeholder="City..." id="city"
                                                    style="position: relative;width: 100%;" />
                                            </div> -->
                                            <div class="right-form-section-drug-entry">
                                                <label for="external_id">External Id<span style="color:red">*</span></label>
                                                <input type="text" v-model="suppliers[0].odata_id" placeholder="External Id..." id="external_id"
                                                    style="position: relative; width: 100%;" />
                                            </div>
                                        </div>

                                        <div style="flex: 1">
                                            <!-- <div class="right-form-section-drug-entry">
                                                <label for="post_code">Post Code<span style="color:red">*</span></label>
                                                <input v-model="suppliers[0].post_code" placeholder="Post Code..." id="post_code"
                                                    style="position: relative; width: 100%;" />

                                            </div> -->

                                            <div class="right-form-section-drug-entry">
                                                <label for="emailaddress">Email Address<span style="color:red">*</span></label>
                                                <input v-model="suppliers[0].email" placeholder="Email Address..."       id="emailaddress"
                                                    style="position: relative; width: 100%;" />
                                            </div>
                                            <div class="right-form-section-drug-entry">
                                                <label for="phone_number">Mobile Phone<span style="color:red">*</span></label>
                                                <input v-model="suppliers[0].phone_number" placeholder="Mobile Phone..." id="phone_number"
                                                    style="position: relative; width: 100%;" />
                                            </div>
                                            <div class="right-form-section-drug-entry">
                                                <label for="fax_number">Fax Number</label>
                                                <input v-model="suppliers[0].fax_number" placeholder="Fax Number..." id="fax_number"
                                                    style="position: relative; width: 100%;" />
                                            </div>
                                </div>



                            </div>
                        </form>
                        <p v-if="errorMessage" style="font-size: 1em; color: red;margin: 0px;">Error: {{ errorMessage }}</p>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p @click="closeModal" class="regular-button red-button " style="padding: 9px 25px;border-radius: 8px;color: white; margin-right: 10px;">
                            Cancel
                        </p>
                        <p v-if="!loading" style="padding: 9px 25px;margin: 0px;font-size: 14px;border-radius: 8px;cursor: pointer;" :class="isSuccessCall() === true ? 'regular-button green-button' : 'regular-button grey-button'" @click="addNewSupplier()">
                            {{ buttonName === 'New Deliveries' ? 'Add Supplier' : buttonName === 'New Orders' ? 'Add Client' : 'Add Warehouse'}}
                        </p>
                        <!-- Spinner when saving -->
                        <p v-if="loading" style="padding: 9px 25px;margin: 0px;font-size: 14px;border-radius: 8px;cursor: not-allowed;" class="regular-button grey-button">
                            <i class="pi pi-spin pi-spinner" style="font-size: 1rem !important;"></i>
                        </p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['buttonName'],
    components: {
    },
    data() {
        return {
            registerCompanyAddress: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            suppliers: [
                {
                    name: "",
                    phone_number: "",
                    email: "",
                    address: "",
                    post_code: "",
                    city: "",
                    county: "",
                    fax_number: "",
                    country_region_code: "",
                    odata_id: ""
                }
            ],
            errorMessage:'',
            loading: false
        }
    },
    computed: {

    },
    methods: {
        getPersonalAddressData(addressData, propertyData, id) {
            this.registerCompanyAddress.state = addressData.administrative_area_level_1;
            this.registerCompanyAddress.city = addressData.administrative_area_level_2;
            this.registerCompanyAddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.registerCompanyAddress.country = addressData.country;
            this.registerCompanyAddress.postcode = addressData.postal_code;
            this.registerCompanyAddress.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.registerCompanyAddress);

            this.suppliers[0].address = this.registerCompanyAddress.street_address;
            this.suppliers[0].city = this.registerCompanyAddress.city
            this.suppliers[0].county = this.registerCompanyAddress.country
            this.suppliers[0].country_region_code = this.registerCompanyAddress?.country
            this.suppliers[0].post_code = this.registerCompanyAddress?.postcode
        },
        closeModal() {
            this.$emit('closeLocationModal', true);
        },
        isSuccessCall(){
            return this.suppliers[0].name !== "" && this.suppliers[0].email !== "" && this.suppliers[0].phone_number !== "" && this.suppliers[0].address !== "" && this.suppliers[0].city !== "" && this.suppliers[0].city !== undefined && this.suppliers[0].odata_id !== ""
        },
        addNewSupplier(){
            this.loading = true;
            let payload;
            if (this.isSuccessCall() === true) {
                this.errorMessage = ''
                if (this.buttonName === 'New Orders') {
                    payload = { clients: this.suppliers };
                } else if (this.buttonName === 'New Deliveries') {
                    payload = { suppliers: this.suppliers };
                } else {
                    payload = { warehouses: this.suppliers }
                }
                axios.post('/integration/save-locations',payload,this.$store.state.header).then((res) => {
                    if (res.data === 200 || res.status === 200) {
                        this.loading = false;
                        this.closeModal()
                    }
                }).catch((error)=>{
                    this.loading = false;
                    if (error.response && error.response.data && error.response.data.message) {
                            this.errorMessage = error.response.data.errors ?  error.response.data.message + ' ' + Object.values(error.response.data.errors).flat()[0] : error.response.data.message;
                        } else {
                            this.errorMessage = error.response.data;
                        }
                });
            }else{
                this.loading = false;
                this.errorMessage = 'Name, Email, Address, PhoneNumber and External Id is required.'
            }
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";
</style>
<style scoped>
.right-form-section-drug-entry input {
    border: 1.5px solid #2196F3 !important;
    margin-top: 10px;
}

.right-form-section-drug-entry {
    display: block;
    text-align: left;
    margin-block: 20px;

}

.green-button {
    padding: 5px 20px;
    border-radius: 6px;
    cursor: inherit;
    margin: 10px 0;
    color: white;
    font-weight: bold;
    text-align: center;
    background-color: #00AD50;

}

.right-form-section-drug-entry label {
    margin-left: 20px;
    color: black;
    font-size: 15px;
}
#contactmap{
    width: 100%;
}
</style>