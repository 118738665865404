<template>
    <div class="drug-register-table table-container" style="padding-top: 10em; width: 95.5vw; border-top-right-radius: 0;" v-if='!$store.state.user.reporting_active' >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-else class="drug-register-table table-container" style="width: 95.5vw; height: 83vh; border-top-right-radius: 0;" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters" style="align-items: flex-end">
          <div>
            <p style="font-size: 1em; text-align: left; margin: 0; padding-left: 15px;">Select a report type</p>
            <div>
              <button @click="()=>{form.filters.all = true; form.filters.polypharmacy = false; form.filters.antipsychotic = false; getPreviousReports() }" 
                v-bind:class="[form.filters.all == true ? 'blue-button' : 'outline-button']" class="regular-button" style=" margin: 0; margin-right: 10px; padding: 0 30px; height: 38px;">All</button>

              <button @click="()=>{form.filters.antipsychotic = true; form.filters.polypharmacy = false; form.filters.all = false; getPreviousReports() }" 
                v-bind:class="[form.filters.antipsychotic == true ? 'blue-button' : 'outline-button']" class="regular-button" style=" margin: 0; margin-right: 10px; padding: 0 30px; height: 38px;">Antipsychotic</button>

                <button @click="()=>{form.filters.polypharmacy = true; form.filters.antipsychotic = false; form.filters.all = false; getPreviousReports() }" 
                v-bind:class="[form.filters.polypharmacy == true ? 'blue-button' : 'outline-button']" class="regular-button" style=" margin: 0; margin-right: 10px; padding: 0 30px; height: 38px;">Polypharmacy</button>
            </div>
            <!-- <div class="select-filters-container">
              <p @click="() => { form.filters.all = true; form.filters.antipsychotic = false;  form.filters.polypharmacy = false;  getPreviousReports() }"
                v-bind:class="[form.filters.all == true ? 'active-select-filter' : '']" class="select-filter-item">All</p>
              <p @click="() => {form.filters.all = false;form.filters.antipsychotic = true;  form.filters.polypharmacy = false; getPreviousReports() }"
                v-bind:class="[form.filters.antipsychotic == true ? 'active-select-filter' : '']" class="select-filter-item">antipsychotic</p>
              <p @click="() => { form.filters.all = false;form.filters.antipsychotic = false;  form.filters.polypharmacy = true; getPreviousReports() }"
                v-bind:class="[form.filters.polypharmacy  == true ? 'active-select-filter' : '']" class="select-filter-item">Pychotropic</p>
            </div> -->
            
          </div>
          <div class="date-search-container search-container">
            <p style="text-align: left; margin: 0; font-size: 1em; padding-left: 15px">Select a year</p>
            <Calendar v-model="selectedYear" class="grey-calendar" view="year" dateFormat="yy" placeholder="Select a year" @date-select="getPreviousReports()" />
          </div>
        </div>
        <div class="report-container" style="height: fit-content">
          <div v-if="loading" style="margin: 0 auto; margin-top: 10em;">
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
          </div>
          <table class="data-table audit-table" id="transaction-table" cellspacing="0" v-if="!loading && logs &&  logs.length">
          <!-- LOADING SPINNER -->

            <tr class="table-headers">
                <th style="width: 3em" class="expand-column"></th>
                <th style="width: 10em">
                  <h3>Start Date</h3>
                </th>
                <th style="width: 10em">
                  <h3>End Date</h3>
                </th>
                <th style="width: 10em">
                  <h3>Report Type</h3>
                </th>
                <th style="width: 10em">
                  <h3>Completed By</h3>
                </th>
            </tr>
            <tbody class="table-data" v-for="(item, index) in logs" :key="index">
              <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
                @click="showContent(item, index)">
                <td style="min-width: 30px;" class="expand-column">
                  <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                  <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>{{ item.start_date }} </td>
                <td>{{ item.end_date }} </td>
                <td>{{ item.report_type.slice(0,1).toUpperCase()+item.report_type.slice(1) }} </td>
                <td>{{ item.completed_by?.personal_information?.first_name ? item.completed_by.personal_information.first_name : ''}} {{ item.completed_by?.personal_information?.last_name ? item.completed_by.personal_information.last_name : ''}} </td>
              </tr>
              <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content">
                  <div class="expanded-transfer-container" style="width: 93vw; padding: 25px;">
                    <div v-if="!item.expandedInfo" style="margin: 0 auto; margin-bottom: 1em;">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                    </div>
                  <!-- v-if="form.filters.antipsychotics" -->
                  <div v-else style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; max-height: 330px;">
                    <div>
                      <table class="qi-report-table" style="padding-bottom: 0; height: 100%;" v-if="item.report_type == 'antipsychotic'" >
                        <tr>
                          <th style="padding: 10px;">QI Program provider portal reporting requirements</th>
                          <th></th>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">The collection date for the quarter</td>
                          <!-- <td><Calendar class="grey-input-color grey-calendar" disabled  v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" /></td> -->
                          <td>{{ item.start_date }} - {{ item.end_date }}</td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">Total number of care recipients assessed for antipsychotic medications</td>
                          <td style="padding: 10px;"><inputText class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.total_patients" /></td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">Total number of care recipients excluded because they were admitted to hospital for the entire seven-day assessment period</td>
                          <td style="padding: 10px;"><inputText class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.patients_excluded" /></td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">Total number of care recipients who received an antipsychotic medication</td>
                          <td style="padding: 10px;"><inputText class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.patients_received_antipsychotics" /></td>
                        </tr>
                        <tr>
                          <td style="padding: 10px; border-bottom: 0; border-bottom-left-radius: 8px;">Total number of care recipients who received an antipsychotic medication for a medically diagnosed condition of psychosis</td>
                          <td style="padding: 10px; border-bottom: 0; border-bottom-right-radius: 8px;"><inputText class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.patients_received_anti_during_assessement" /></td>
                        </tr>
                    </table>
                    
                    <table class="qi-report-table" style="padding-bottom: 0; height: 100%;" v-if="item.report_type == 'polypharmacy'">
                        <tr>
                          <th style="padding: 10px;">QI Program provider portal reporting requirements</th>
                          <th></th>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">The collection date for the quarter</td>
                          <td style="padding: 10px;">{{ item.start_date }} - {{ item.end_date }}</td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">Total number of care recipients assessed for polypharmacy</td>
                          <td style="padding: 10px;"><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.total_patients" disabled /></td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">Total number of care recipients excluded because they were admitted to hospital on the collection date</td>
                          <td style="padding: 10px;"><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.patients_excluded" disabled  /></td>
                        </tr>
                        <tr>
                          <td style="padding: 10px; border-bottom: 0; border-bottom-left-radius: 8px;">Total number of care recipients prescribed nine or more medication based on a review of their medication charts and/or administration records as they are on the collection date</td>
                          <td style="padding: 10px; border-bottom: 0; border-bottom-right-radius: 8px;"><input class="grey-input-color" style="border: 1px solid #BDC4C4" :value="item.expandedInfo.poly_patients" disabled /></td>
                        </tr>
                    </table>
                    </div>
                    <div style="overflow: scroll; max-height: 330px; border: 1px solid #dadada; border-radius: 8px;">
                      <table class="qi-report-table qi-data-report-table" v-if="item.report_type == 'polypharmacy'" style="border: none;"  >
                        <tr>
                          <th style="width: 4em">Ref #</th>
                          <th>Care Recipient Name</th>
                          <th>Care Recipient Admitted to Hospital?</th>
                          <th>Care Recipient Prescribed 9+ Meds on Collection Date</th>
                        </tr>
                        <tbody style="max-height: 400px; overflow-y: scroll; background-color: white">
                          <tr v-for="(pat, index) in item.expandedInfo.patients" :key="index">
                            <td style="width: 4em">{{ index+1 }}</td>
                            <!-- :placeholder="item.person.first_name ? item.person.first_name+' '+item.person.last_name : 'Enter name'" -->
                            <td>{{ pat.first_name }} {{ pat.last_name }}</td>
                            <td>{{ pat.admitted_to_hospital ? 'Yes' : 'No' }}</td>
                            <td>{{ pat.polypharmacy ? 'Yes' : 'No' }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="qi-report-table qi-data-report-table" v-if="item.report_type == 'antipsychotic'" >
                        <tr>
                          <th style="width: 4em">Ref #</th>
                          <th>Care Recipient Name</th>
                          <th>Care Recipient Admitted to Hospital?</th>
                          <th>Care Recipient Received an Antipsychotic during 7 Day Assessment Period</th>
                          <th>Care Recipient Received an Antipsychotic during 7 Day Assessment Period for a Medically Diagnosed Psychosis</th>
                        </tr>
                        <tbody style="max-height: 400px; overflow-y: scroll; background-color: white">
                          <tr v-for="(pat, index) in item.expandedInfo.patients" :key="index">
                            <td style="width: 4em">{{ index+1 }}</td>
                            <td>{{ pat.first_name }} {{ pat.last_name }}</td>
                            <td>{{ pat.admitted_to_hospital ? 'Yes' : 'No' }}</td>
                            <td>{{ pat.received_antipsychotic_during_date_period ? 'Yes' : 'No' }}</td>
                            <td>{{ pat.received_antipsychotic_during_assessement ? 'Yes' : 'No' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                  </div>
              </tr>
            </tbody>
          
          </table>
          <div v-if="logs && !logs.length && !loading" style="margin: 0 auto; margin-top: 10em;">
            <h3>No reports are available for this period.</h3>
          </div>

        </div>
        
    </div>
</template>


<script>
// @ is an alias to /src

import axios from 'axios';
// import DemoGraphs from '../../components/graphs/DemoGraphs.vue';

export default {
  components: {
    // DemoGraphs,
  },
  data(){
    return{
      selectedQuarter:null,
      selectedYear: null,
      drugNames: [],
      registerData: [],
      date:[],
      loading: false,
      logs:null,
      filters:{
        s8:true,
      },
      clearForm:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          all: true,
          polypharmacy: false,
          antipsychotic: false,
          date:{
            start:'',
            end:''
          },
        }
      },
      form:{
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page:1,
        filters:{
          all: true,
          polypharmacy: false,
          antipsychotic: false,
          date:''
        }
      }
    }
  },
  methods:{
    getPreviousReports(){
      if(this.selectedYear && (this.form.filters.polypharmacy || this.form.filters.antipsychotic || this.form.filters.all)){
        this.loading = true;
        console.log('retrieving QUM data');
        let payload = {
          report_type: this.form.filters.all ? 'all' : this.form.filters.polypharmacy ? 'polypharmacy' : 'antipsychotic',
          year: new Date(this.selectedYear).getFullYear(),
          page: 1
        }
       axios.post('/get-previous-reports', payload, this.$store.state.header).then(res=>{
        console.log('these are the previous reports', res.data);
        this.logs = res.data.data;
        this.loading = false;
       }).catch(err=>{
        console.log('this is the previous report err', err, err?.response?.data?.message);
       })
      }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    displayPage(page){
      this.form.page = page;
    },
    showContent(item, index) {
      console.log('this the selected log', item)
      // let refid=`expand-${index}`;

      if (item.display && (item.display === true)) {
        document.getElementById(`expand-${index}`).style.display = 'none';
        item.display = !item.display;
      } else {
        item.display = true;
        console.log('expanding range for this object');
        document.getElementById(`expand-${index}`).style.display = 'table-row';
        
        axios.post(`/retrieve-qi-report`, {report_id: item.uuid}, this.$store.state.header).then(res => {
          console.log('this is the state function to get a single drug register item', res.data);
          item.expandedInfo = res.data.data;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
            
          }
        })


      }
    },
    clearFilteredDrugRegister(){
      this.form = {
        location_name: this.$store.state.currentLocation.name,
        sort_direction: 'asc',
        col: 'created_at',
        page: 1,
        filters:{
          tags:['s8'],
          drug:'',
          polypharmacy: false,
          antimicrobial: false,
          date:{
            start:'',
            end:''
          },
        }
      };
      this.date = [];
    },
    printElem(elem){
        console.log('this is the eleme', elem);
        window.print();
    }
  },
   mounted(){
  },
  computed:{
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";


.qi-report-table{
  padding: 0;
  tr{
    background-color: white !important;
  }
  tr:last-child{
    td{
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
  .instructions-container{
    border: 1px solid $strongblue;
    width: 650px;
    height: 208px;
    border-radius: 8px;

    p{
      text-align: left;
      margin: 0;
    }
  }

  .instructions-header{
    background-color: $strongblue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 5px 25px;
    height: 46px;
    p{
      font-size: 1.2em;
      
    }
  }

  .instructions-body{
    padding: 25px;
    p{
      font-size: 1em;
    }
  }

  .instructions-content{
    margin-bottom: 20px;
  }

  .instruction-content-header{
    font-weight: bold;
    padding-bottom: 5px;
  }

  .left-report-column{
    width: 650px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .right-report-column{
    max-width: 950px;
    min-width: 400px;
    flex: 1 1 0px;
  }

  .qi-data-report-table{
   
    th{
      font-size: 15px;
      padding: 16.5px 0;
    }
    td{
      padding: 25px 0;
      padding-right: 10px;
      border-bottom: 1px solid #BDC4C4;
    }
    tr{
      td:first-of-type{
        padding-left: 16.5px;
      }
    }
    th:first-of-type{
      padding-left: 15px;
    }
    th:last-of-type{
      padding-right: 26px;
    }
    // td:first-of-type{
    //   padding-left: 26px;
    // }
    // td:last-of-type{
    //   padding-right: 26px;
    // }
    tr:last-of-type{
      td{
        border-bottom: none;
      }
    }
    
  }

  .report-container{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  .grey-calendar{
    border-radius: 8px;
    border: 1px solid #BDC4C4;
    .p-inputtext{
      background-color: #f3f4f4;
    }
  }

  .qi-input{
    width: 13em;
  }

  .qum-facility-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .qum-facility-container{
    padding: 20px;
    border: 2px solid #BDC4C4;
    border-radius: 8px;
  }

  .qum-facility-header p{
      text-align: left;
      margin: 0; 
      font-weight: bold;
      font-size: 1em;
  }

  .qum-facility-header h4{
      text-align: left;
      margin: 0; 
      font-weight: bold;
      font-size: 1.2em;
  }

  .qum-facility-comparator{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 40px;
  }

  .qum-facility-comparator-content{
    p{
      margin: 0;
    }
  }
</style>