<template>
    <div class="dashboard-container" style="display: grid; overflow: auto; padding: 25px;">
        <div class="stock-table-wrapper" style="max-height: none; padding: 0; ">
            <div style="display: flex; align-items: center; gap: 10px;">
                <div class="alerts-toggle" >
                    <div class="alert-toggle-item" @click="displayAlertType = 0"
                        :style="displayAlertType == 0 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }">
                        <h4 :style="displayAlertType == 0 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">Phone Orders</h4>
                    </div>
                    <div class="alert-toggle-item" @click="displayAlertType = 1"
                        :style="displayAlertType == 1 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }">
                        <h4 :style="displayAlertType == 1 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">S8/Auth. Reviews</h4>
                    </div>
                    <div class="alert-toggle-item" @click="displayAlertType = 2"
                        :style="displayAlertType == 2 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }">
                        <h4 :style="displayAlertType == 2 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">Chart Reviews</h4>
                    </div>
                </div>
            </div>
            <div v-if="displayAlertType == 2">
                <table class="stock-table ">
                    <tr style="font-weight: bold">
                        <th style="text-align: end; font-weight: bold;">Name</th>
                        <th style="text-align: right; padding-left: 63px; font-weight: bold;">Last Review</th>
                        <th style="text-align: center; padding-left: 23px; font-weight: bold;">Next Review</th>
                    </tr>
                </table>
                <table
                    class="chart-review-table"
                    style="border-collapse:collapse; border-spacing:0 5px; border-radius: 10px;"
                >
                    <!-- <tr style="font-weight: bold">
                            <th style="text-align: end; font-weight: bold; ">Name</th>
                            <th style="text-align: right;  font-weight: bold;">Last Review</th>
                            <th style="text-align: center; font-weight: bold;">Next Review</th>
                        </tr> -->
                    <tr
                        style="padding: 0px; margin-bottom: 5px; "
                        v-for="(item, index) in sortedItems" :key="index"
                    >
                        <router-link
                            style="text-decoration: none; margin:0; color: black; cursor: pointer; display: flex; justify-content: space-around; padding-right:20px;"
                            :to="'/patient/'+item.uuid"
                        >
                            <td style="cursor: pointer; width: 10em; text-align: left;">
                                
                                {{item.personal_information.first_name}} {{item.personal_information.last_name}}
                            </td>
                            <td style="cursor: pointer; text-align: left">
                                {{item.chart_review && item.chart_review.reviewed_on ? item.chart_review.reviewed_on.slice(0,12) : 'Never Reviewed'}}
                            </td>
                            <td style="cursor: pointer">
                                {{item.chart_review && item.chart_review.next_review_date ? item.chart_review.next_review_date.slice(0,12) : 'Review Now'}}
                            </td>
                        </router-link>
                    </tr>
                </table>
            </div>
            <div v-if="displayAlertType == 1">
                <table class="reviewtable"
                    style="border-collapse:collapse; border-spacing:0 5px; border-radius: 10px;"
                >
                    <tr style="font-weight: bold">
                        <th style="text-align: left; font-weight: bold; width: 10em; padding-left: 5px;">Name</th>
                        <th style="text-align: left;  font-weight: bold;">Medication</th>
                        <th style="text-align: left; font-weight: bold;">Expiry</th>
                        <th style="text-align: left; font-weight: bold; padding: 0 10px;">Prescriber</th>
                    </tr>
                    <tr
                        style="padding: 0px; margin-bottom: 5px; "
                        v-for="(item, index) in s8charts" :key="index" :style="index % 2 ? {}:{backgroundColor: '#f3f2f2'}"
                        @click="renewScript= true; phoneOrder=false; displayPrescriptionRenewModal = true; mappingChart(item); setChartType(item)"
                    >
                        <td style="cursor: pointer; text-align: left; padding-left: 5px;">
                            
                            {{item.patient.personal_information.first_name}} {{item.patient.personal_information.last_name}}
                        </td>
                        <td style="cursor: pointer; text-align: left">
                            {{ item.alias.name }}
                        </td>
                        <td style="cursor: pointer">
                            {{ new Date(item.stop_date).getDate() }}/{{ new Date(item.stop_date).getMonth()+1 }}/{{ item.stop_date.slice(-9,-7) }}
                        </td>
                        <td style="cursor: pointer; padding: 0 10px;">
                            {{ item.doctor.personal_information.first_name.split('')[0] }}. {{ item.doctor.personal_information.last_name }}
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="displayAlertType == 0">
                <table class="reviewtable"
                    style="border-collapse:collapse; border-spacing:0 5px; border-radius: 10px;"
                >
                    <tr style="font-weight: bold">
                        <th style="text-align: left; font-weight: bold; width: 10em; padding-left: 5px;">Name</th>
                        <th style="text-align: left;  font-weight: bold;">Medication</th>
                        <th style="text-align: left; font-weight: bold;">Expiry</th>
                    </tr>
                    <tr
                        style="padding: 0px; margin-bottom: 5px; "
                        v-for="(item, index) in phoneOrders" :key="index" :style="index % 2 ? {}:{backgroundColor: '#f3f2f2'}"
                        @click="renewScript = false; phoneOrder=true; displayPrescriptionRenewModal = true; mappingChart(item); setChartType(item)"
                    >
                        <td style="cursor: pointer; text-align: left; padding-left: 5px;">
                            
                            {{item.patient.personal_information.first_name}} {{item.patient.personal_information.last_name}}
                        </td>
                        <td style="cursor: pointer; text-align: left">
                            {{ item.alias.name }}
                        </td>
                        <td style="cursor: pointer">
                            {{ new Date(item.stop_date).getDate() }}/{{ new Date(item.stop_date).getMonth()+1 }}/{{ item.stop_date.slice(-9,-7) }}
                        </td>
                    </tr>
                </table>
            </div>
            
        <div  v-if="loading && displayAlertType == 2" >
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div> 
        <div  v-if="loadingS8charts && displayAlertType == 1" >
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div>
        <div  v-if="loadingPhoneOrders && displayAlertType == 0" >
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div> 
        <div  v-if="!loadingS8charts && displayAlertType == 2 && page_count && !s8charts.length" >
            <h4>No upcoming S8/APN charts found.</h4>
        </div> 
        <div  v-if="!loadingPhoneOrders && displayAlertType == 0 && page_count && !phoneOrders.length" >
            <h4>No phone orders found.</h4>
        </div> 
    </div>
    <div class="pagination-links">
        <p class="pagination-arrow" v-show="displayAlertType == 1 && current_page > 1 " @click=" displayPage(current_page - 1) "> &larr;
        </p>
        <p class="pagination-arrow" v-show="displayAlertType == 0 && phone_current_page > 1 " @click=" displayPage(phone_current_page - 1) "> &larr;
        </p>
        <p>Page {{ displayAlertType == 1 ? current_page : displayAlertType == 0 ? phone_current_page : ''}} of {{ displayAlertType == 1 ? page_count : displayAlertType == 0 ? phone_page_count : '' }}</p>
        <p class="pagination-arrow" v-show="displayAlertType == 1 && (current_page < page_count)"
            @click=" displayPage(current_page + 1) "> &rarr; </p>
        <p class="pagination-arrow" v-show="displayAlertType == 0 && (phone_current_page < phone_page_count)"
            @click=" displayPage(phone_current_page + 1) "> &rarr; </p>
    </div>
    <PrescriptionRenewModal v-if="displayPrescriptionRenewModal" @close="close" :renewScript="renewScript" :phoneOrder="phoneOrder"
    :prescription="mappedPrescription" @successfullySubmitted="successfullySubmitted" />
  </div>
</template>

<script>
import axios from 'axios';
import PrescriptionRenewModal from '../modals/PrescriptionRenewModal.vue';

export default {
    props:['patients', 'loading'],
    components:{
        PrescriptionRenewModal
    },
    data(){
        return{
            displayPrescriptionRenewModal: false,
            displayAlertType: 0,
            mappedPrescription: null,
            s8charts:[],
            phoneOrders:[],
            current_page: 1,
            page_count: null,
            phone_current_page: 1,
            phone_page_count: null,
            loadingS8charts: false,
            loadingPhoneOrders: false,
            renewScript: false,
            phoneOrder: false,
        }
    },
    methods:{
        displayPage(page){
            console.log('this is the current page requested', page);
            if(this.displayAlertType == 0){
                this.getPhoneOrders(page);
            } else if(this.displayAlertType == 1){
                this.getPrescriptions(page);
            } 
            
        },
        close(value){
            if(value){
                this.displayPrescriptionRenewModal = false;
            }
        },
        successfullySubmitted(value){
            if(value){
                if(this.displayAlertType == 0){
                    this.getPhoneOrders();
                } else if(this.displayAlertType == 1){
                    this.getPrescriptions();
                } 
            }
        },
        mappingChart(value){
            console.log('mapping chart', value);
            value.activePresChart = {
                chart_payload: {
                    prescription_id: value.uuid,
                    chart_form: {
                        type: value.chart_type,
                        route: value.route,
                        indication: value.indication,
                        tags: []
                    }
                },
                prescription_payload: {
                    scheduled: value.alias.tags.includes('S8') ? true : false,
                    patient_id: value.patient.uuid,
                    prescriber: value.doctor?.uuid ? value.doctor?.uuid : '',
                    drug_uuid: value.alias.alias_id,
                    substitution_not_permitted: value.substitution_not_permitted ? true : false,
                    chart_id: value.chart_id,
                    authority_prescription_number: value.authority_prescription_number ? value.authority_prescription_number  : '',
                    pbs_code: value.pbs_code ? value.pbs_code  : '',
                    pbs_manufacturer_code: value.pbs_manufacturer_code ? value.pbs_manufacturer_code  : '',
                    pbs_authority_code: value.pbs_authority_code ? value.pbs_authority_code  : '',
                    days_of_week: value.days_of_week,
                    interval: value.interval,
                    num_repeats_authorised: value.num_repeats_authorised,
                    repeat_interval: value.repeat_interval,
                    frequency: value.frequency,
                    frequency_shorthand: value.frequency_shorthand,
                    times: value.times,
                    dose: value.dose,
                    dose_unit: value.dose_unit,
                    route: value.route,
                    sliding_scale: value.sliding_scale,
                    max_admin_dose: value.max_admin_dose,
                    max_daily_admin: value.max_daily_admin,
                    is_paper: value.is_paper ? true : false,
                    paper_prescription_number : value.paper_prescription_number ? value.paper_prescription_number : value.is_paper ? '1' : '',
                    reason: value.reason,
                    max_dose: value.max_dose,
                    start_date: new Date(value.start_date).toLocaleDateString('en-AU'),
                    stop_date: new Date(value.stop_date).toLocaleDateString('en-AU'),
                    additional_regulatory_checks: '',
                    administrations: value.administrations,
                    // created_date: !isNaN(Date.parse(value.created_at)) ? new Date(value.created_at).toLocaleDateString('en-AU') : '' ,
                    created_date: new Date(value.created_at).toLocaleDateString('en-AU'),
                    dose_times : value.dose_times,
                    last_administration: value.last_administration ? value.last_administration  :'',
                    administration_history: value.administration ? value.administration : '',
                    prescribed_quantity: value.prescribed_quantity,
                    notes: value.instructions && value.instructions.text ? value.instructions.text : '',
                    chart_tags: value.chart_tags,
                    signed: true
                }
            };
            console.log('mapped chart', value);
            this.mappedPrescription = value;
        },
        setChartType(value){
            let chartTypes = this.$store.getters.getChartTypes;

            chartTypes.forEach(chartType => {
                if ((value.chart_type == chartType.chart_name) || (value.chart_type == chartType.name)) {
                    console.log('LOOPING THROUGH THE CHART ITEMS', value.chart_type, chartType.chart_name);
                    value.chart_type = chartType.chart_name;
                    value.type = chartType.chart_name;
                    value.color = chartType.color;
                    value.chart_name = chartType.name;
                    value.selectedChart = chartType;
                    console.log('this is the color now', value.color);
                }
            })
        },
        getPrescriptions(page){
            this.loadingS8charts = true;
            this.noS8charts = false;
            this.s8charts = [];
            axios.post('/retrieve-s8-charts', {page: page ? page : 1}, this.$store.state.header).then(res=>{
                console.log('these are all the prescriptions', res.data);
                this.s8charts = res.data.prescriptions;
                this.s8charts.forEach(prescription=>{
                    prescription.chart_type = prescription.chart.type;
                })
                this.current_page = res.data.header.current_page;
                this.page_count = res.data.header.page_count;
                this.loadingS8charts = false;
            })
        },
        getPhoneOrders(page){
            this.loadingPhoneOrders = true;
            this.noPhoneOrders = false;
            this.phoneOrders = [];
            axios.post('/retrieve-phone-orders', {page: page ? page: 1}, this.$store.state.header).then(res=>{
                console.log('these are all the prescriptions', res.data);
                this.phoneOrders = res.data.prescriptions;
                if(!res.data.prescriptions.length){
                    this.displayAlertType = 1
                }
                this.phoneOrders.forEach(prescription=>{
                    prescription.chart_type = prescription?.chart?.type;
                })
                this.phone_current_page = res.data.header.current_page;
                this.phone_page_count = res.data.header.page_count;
                this.loadingPhoneOrders = false;
            })
        }
    },
    computed:{
        sortedItems: function() {
            let rawData = [...this.patients];
            let sortedDated = [];
            let unsorted = [];
            rawData.forEach(item=>{
                if(item.chart_review){
                    sortedDated.push(item);
                } else{
                    unsorted.push(item);
                }
            })
            sortedDated.sort( ( a, b) => {
                return new Date(a.chart_review.next_review_date) - new Date(b.chart_review.next_review_date);
            });
            let compiled = unsorted.concat(sortedDated);
            return compiled;
        }
    },
    mounted(){
        this.getPhoneOrders()
        this.getPrescriptions()
    }
}
</script>

<style lang="scss" scoped>
    .chart-review-table{
        td{
            height: 60px !important;
            background-color: transparent;
            display: flex; 
            align-items: center;
        }
        tr:nth-child(odd){
            background-color: #f3f4f4;
        }
    }

    .alerts-toggle {
        border-radius: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        justify-content: flex-start !important;
        div{
            border-radius: 0 !important;
            h4{
                padding: 0 20px !important;
            }
        }
    }


</style>