<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Add Reference Prescriber</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <div class="form-section-container" style="grid-template-columns: 1fr">
                                <div class="right-form-section-panel">
                                    <p v-if="!error" style="font-size: 1em;">Before proceeding, please make sure to properly check that prescriber does not come up in the search prescriber list via their name or prescriber number.</p>
                                    <p v-else style="font-size: 1em;">{{ error }}</p>
                                    <div class="right-form-section-drug-entry" v-if="!loading">
                                        <input v-model="form.submit.first_name" placeholder="Enter first name"
                                            style="position: relative; width: 50%; margin-right: 10px" />
                                        <input v-model="form.submit.last_name" placeholder="Enter last name"
                                            style="position: relative; width: 50%;" />
                                        
                                    </div>
                                    <div v-else style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding reference prescriber...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                    </slot>
                </div>

                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">          
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="background-color: green" v-if="!loading"
                            @click="addReferencePrescriber()">Add Reference Prescriber</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    components: {
    },
    data() {
        return {
            errorMessage: '',
            error: null,
            loading: false,
            form: {
                submit: {
                    first_name: '',
                    last_name: '',
                    // regulatory_numbers:[
                    //         {
                    //             data:'',
                    //             regulatory_type:'Prescriber Number',
                    //             expiry:''
                    //         }
                    // ]
                }
            }
        }
    },
    methods: {

        isSuccessful(value) {
            if (value) {
                this.$emit('close', true);
            }
        },
        addReferencePrescriber(){
            if((this.form.submit.first_name == '') || (this.form.submit.last_name == '')){
                this.errorMessage = 'Please provide both names for the reference prescriber.'
            }else{
                this.errorMessage = '';
                let payload = {...this.form.submit};
                this.loading = true;
                payload.company_id = this.$store.state.company.uuid;
                axios.post(`/person/create/reference-prescriber`,payload, this.$store.state.header).then(res=>{
                    this.$emit('prescriber', {
                        first_name: payload.first_name,
                        last_name:  payload.last_name,
                        uuid: res.data.uuid
                    });
                    this.loading = false;
                }).catch(err=>{
                    console.log('this is th err', err);
                    this.error = err.response.data;
                })
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
