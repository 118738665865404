

<template>
    <!-- Assign BCP Notification Channels -->
    <div style="display: grid; grid-gap: 10px; min-width: 50em;">
        <div v-if="loadingRoles">
            <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
        </div>
        <div v-else style="display: grid; grid-gap:1em; margin-top: 1em;">
            <p style='font-size: 1em; text-align: left; margin: 0; font-weight: bold;'>Assign BCP notification channels to roles. Multiple
                channels may be assigned to a role.</p>
            <p style='font-size: 1em; text-align: left; margin: 0; margin-bottom: 1em;'>Selecting a
                channel
                will enable the channel's site BCP notifications by default.</p>
            <p style="margin: 0; margin-bottom: -10px; font-size: 1em; text-align: left; font-weight: 500">Select Role:</p>
            <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Role" :options="roles"
                optionLabel="name" optionValue="uuid" v-model="assignNotificationChannelPayload.role_id"
                @change="getBCPRoleChannels(assignNotificationChannelPayload.role_id)" />
            <div v-if='assignNotificationChannelPayload.role_id'
                style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
                <div v-for="(channel, i) in notificationChannels" :key="i"
                    style="border-radius: 8px; box-shadow: 0 0 5px #d9d9d9; padding 10px; padding-bottom: 0px; height: fit-content">
                    <div class="p-field-checkbox" style="display: flex">
                        <Checkbox :id="i + '-channel'" :binary="true" v-model="channel.selected" />
                        <label style="padding: 10px;" :for="i + '-channel'">{{ channel.name }}</label>
                    </div>
                    <div style="display: grid; background-color: #f3f4f4" v-if="channel.selected">
                        <div class="p-field-checkbox">
                            <Checkbox :id="i + 'sms-channel'" :binary="true" v-model="channel.sms" />
                            <label style="padding: 10px;" :for="i + 'sms-channel'">SMS</label>
                        </div>
                        <div class="p-field-checkbox">
                            <Checkbox :id="i + 'email-channel'" :binary="true" v-model="channel.email" />
                            <label style="padding: 10px;" :for="i + 'email-channel'">Email</label>
                        </div>
                    </div>
                </div>
            </div>
            <div style='margin-top: 20px; display: flex;' v-if='assignNotificationChannelPayload.role_id'>
                <p class="green-button" style="width: 15em;" @click="updateRoleChannels()">Update Role Channels</p>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            notificationChannels: [],
            roles: [],
            loadingRoles: false,
            assignNotificationChannelPayload: {
                channels: [],
                role_id: ''
            },
            displaySuccessModal: false,
            successMessage: ""
        }
    },
    methods: {
        close() {
            this.displaySuccessModal = false
        },
        getRoles() {
            this.loadingRoles = true;
            axios.post(`/get-roles`, {}, this.$store.state.header).then(res => {
                this.$store.dispatch('getRefreshToken');
                this.roles = res.roles;
                this.loadingRoles = false;
            }).catch(err => {
                if (err.response && err.response && err.response.split(' ').includes('expired')) {
                    this.$router.push('/login');
                }
                this.loadingRoles = false;
                console.log('this is the stocktake error', err.response);
            });

        },
        getBCPRoleChannels(role_id) {
            this.getBcpChannel()
            this.displaySuccessModal = true;
            this.successMessage = 'Getting role channels';
            setTimeout(() => {
                axios.post(`/role/${role_id}/channels`, {}, this.$store.state.header).then(res => {
                    console.log('these are the role channels', res.data.data);
                    let roleChannels = res.data.data;
                    if (roleChannels.length) {
                        roleChannels.forEach(item => {
                            this.notificationChannels.forEach(channel => {
                                if (item.name == channel.name) {
                                    channel.selected = true;
                                    if (item.type == 'email') {
                                        channel.email = true;
                                    }
                                    if (item.type == 'sms') {
                                        channel.sms = true;
                                    }
                                }
                            })
                        })
                    }
                    this.displaySuccessModal = false;
                }, 500)

            })
        },
        updateRoleChannels() {
            this.displaySuccessModal = true;
            this.successMessage = 'Submitting...';
            this.assignNotificationChannelPayload.channels = [];
            this.notificationChannels.forEach(channel => {
                if (channel.selected) {
                    let submitChannel = { ...channel }
                    delete submitChannel.selected;
                    this.assignNotificationChannelPayload.channels.push(submitChannel);
                }
            })
            console.log('THESE ARE THE SELECTED CHANNELS', this.assignNotificationChannelPayload.channels);
            axios.post('/set-role-channels', this.assignNotificationChannelPayload, this.$store.state.header).then(res => {
                this.$store.dispatch('getRefreshToken');
                console.log('CHANNELS HAVE BEEN SUBMITED', res.data);
                this.successMessage = 'Successfully submitted.';
            }).catch(err => {
                // err.response.data.message
                this.successMessage = err.message;
            })
        },
        getBCPChannels() {
            axios.post(`/get-bcp-channels`, {}, this.$store.state.header).then(res => {
                this.$store.dispatch('getRefreshToken');
                this.bcpSubscriptionType = res.data; // to be removed soon !
                console.log('THESE ARE THE CHANNELS', res.data);
                this.notificationChannels = [];
                res.data.forEach(channel => {
                    this.notificationChannels.push({
                        name: channel,
                        selected: false,
                        sms: false,
                        email: false,
                    })
                    this.notificationChannels = this.notificationChannels.filter(notificationChannel => !this.filterAgedCareNotifications.includes(notificationChannel.name))
                })
            })
        },

    },
    mounted() {
        this.getRoles();
        this.getBCPChannels();

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
