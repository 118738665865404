<template>
<div class="dashboard-container" style="display: flex; flex-direction: column; overflow: auto; padding: 25px ">
      <div style="display: flex; align-items: flex-end; margin-bottom: 25px;">
        <div >
            <p style="margin: 0; text-align: left; margin-left: 10px; font-weight: 500; font-size: 0.95em"> Resident Search </p>
            <inputText class="grey-input" style="border: 1px solid #dadada; width: 20em;" @keyup="filterPatients" placeholder="Resident Name" />
        </div>
        <div>
            <p style="margin: 0; text-align: left; margin-left: 20px; font-weight: 500; font-size: 0.95em"> Select a Facility </p>
            <Dropdown @change="changeFacility()"
            placeholder="Select Facility" :options="facilities" v-model="selectedFacility"
            style="text-align: left; margin-left: 10px; width: 20em;" />
        </div>
          
            
            <p class="black-button" style="display: flex; margin-left: 10px; padding-top: 7px; padding-bottom: 7px; margin-bottom: 4px; font-size: 1em;" @click="selectedFacility = '', filteredResults=[]">Clear</p>
      </div>
      <!-- @click.stop="displayPatientSummary(item.uuid)" -->
        <button @click="handlePatientClicked(item.uuid)" style="background-color: #F3F4F4; padding: 0px; margin-bottom: 15px; border-radius: 8px; height: fit-content; text-decoration: none; color: black;" 
        v-for="(item, index) in filteredResults.length ? filteredResults:patients" :key="index+2"  :id="item.personal_information.first_name+item.personal_information.last_name" class="prescriber-list-item" >
            <div style="display: flex; margin: 10px; align-items: center">
                <div style="height: 150px !important; width: 150px !important; margin: 5px;">
                    <img class="resident-image large admin" onerror="this.onerror=null; this.className='resident-image-default'" style="height: 150px !important; width: 150px !important;" v-if="item.image_information && item.image_information.item" :src="item.image_information.url">
                    <img v-else class="resident-image-default resident-image large admin" style="height: 150px !important; width: 150px !important;" >
                </div>
                
                <div style="width: 100%;">
                    <div style="display: flex; flex-wrap: nowrap;align-items: flex-end; justify-content: space-between" >
                        <div style="display: flex; flex-wrap: wrap; flex-direction: column; padding-bottom: 0px; width: 35vw; justify-content: space-between">
                            <h4 style="padding-left: 10px;">{{item.personal_information.first_name}} {{item.personal_information.last_name}}</h4>
                            <div style="display: flex; flex-wrap: nowrap; padding: 3px 0; gap: 10px; justify-content: space-between;">
                                <div>
                                    <p class="field-title">Location</p>
                                    <p class="field-value" style="width: 11vw; overflow-x: auto">{{item.facility? item.facility : 'N/A'}}</p>
                                </div>
                                <div>
                                    <p class="field-title">Sex</p>
                                    <p class="field-value" style='width: 10vw'>{{item.personal_information.sex}}</p>
                                </div>
                                <div >
                                    <p class="field-title">Date of birth</p>
                                    <p class="field-value" style='width: 11vw'>{{item.personal_information.dob}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <p class="field-title">Last Review Date</p>
                            <div class="field-value-container" style="overflow: auto; min-width: 11vw">
                                <p class="field-value">{{item.chart_review ? item.chart_review.reviewed_on : 'Not Reviewed'}}</p>
                                <p class="field-value">{{item.chart_review ? 'Dr'+' '+ item.chart_review.reviewed_by.personal_information.first_name + ' '+  item.chart_review.reviewed_by.personal_information.last_name: ''}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div style="padding-left: 10px; width: 100%;">
                        <p class="field-title">Alerts</p>
                        <!-- v-if="!item.alerts.length" -->
                        <p  class="field-value">N/A</p>
                        <!-- <div v-else style="display: flex;; border-radius: 4px; padding: 5px;" class="field-value-container">
                            <p class="field-value"  v-for="(item, index) in item.alerts" :key="index" style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; padding: 3px 10px;  margin-right: 3px;">
                            {{item.toUpperCase()}}
                            </p>
                        </div> -->
                        
                    </div>
                </div>
                

            </div>
        </button>
        <div  v-if="loading" >
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div>
</div>
</template>

<script>
// import PatientSummaryModal from '../modals/PatientSummaryModal.vue';
import axios from 'axios';

export default {
    props:['patients', 'facilities', 'loading'],
    components:{
        // PatientSummaryModal
    },
    data(){
        return{
            expandPatientSummaryModal:false,
            patientId: '',
            selectedFacility:'',
            filteredResults:[],
        }
    },
    methods:{
        displayPatientSummary(id){
            console.log('DISPLAY IS BEING CLICKED ON');
            this.expandPatientSummaryModal = true;
            this.patientId = id;
        },
        filterPatients(e){
            let filter,li;
            console.log('this is the search value', e.target.value);
            filter = e.target.value.toUpperCase();
            li = document.getElementsByClassName("prescriber-list-item");
            console.log('this is the list and filter', li, filter);
            for (let i = 0; i < li.length; i++) {
                if(li[i].getAttribute('id')){
                    let id = li[i].getAttribute('id').toUpperCase();

                
                    console.log('this is the id', id);
                    if(id && id.includes(filter)){
                        console.log('found the match', id);
                        li[i].style.display = "block";
                    }  else{
                        li[i].style.display = "none";
                    }
                }

                
            }
        },
        close(value){
            console.log('close is being clicked on');
            if(value){
                this.expandPatientSummaryModal = false;
            }
        },
        changeFacility(){
            this.filteredResults = []
            this.patients.forEach(patient=>{
                if(patient.facility == this.selectedFacility){
                    this.filteredResults.push(patient);
                    console.log('its a match', this.selectedFacility)
                } else{
                    console.log('not a match', patient.facility, this.selectedFacility);
                }
            })
        },
        handlePatientClicked(patientUUID) {
            this.$store.dispatch('getRefreshToken');
            this.$emit('loadPatientProfile', true);
            axios.post(`/person/${patientUUID}/create-chart-review`, {}, this.$store.state.header).then(res => {
                this.$emit('loadPatientProfile', false);
                this.$router.push(`/patient/${patientUUID}`);
            })
        }

    },
    mounted(){
        this.$emit('loadPrescriberPatients', true);
    }
}
</script>

<style lang="scss">
    .prescriber-dashboard div{
        padding: 0;
    }
</style>