<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" style="width: 30%">
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">Scheduled Dose Times</h3>
            <button @click="closeModal()" class="red-close-button">
              <i class="pi pi-times" style="padding: 0"></i>
            </button>
          </slot>
        </div>
        <div class="modal-body" style="padding: 0px 25px 25px 25px">
          <table
            class="data-table"
            style="border-collapse: collapse"
            v-if="admin_schedule"
          >
            <tr class="table-headers schedule-cells">
              <th>Date</th>
              <th>Time</th>
              <th>Quantity</th>
              <th>Administered</th>
            </tr>
            <tr
              v-for="(dose, index) in admin_schedule"
              :key="index"
              class="schedule-cells"
            >
              <td>{{ dose.date }}</td>
              <td>{{ dose.custom_fields.time }}</td>
              <td>{{ dose.custom_fields.dose }} {{ dose_unit }}</td>
              <td>
                {{
                  !dose.custom_fields?.administration?.length
                    ? "--"
                    : dose.custom_fields?.administration[
                        dose.custom_fields?.administration.length - 1
                      ]?.doc
                    ? dose.custom_fields?.administration[
                        dose.custom_fields?.administration.length - 1
                      ]?.doc
                    : "Y"
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["dose_times", "dose_unit", "uuid"],
  data() {
    return {
      displaySignatureModal: false,
      displayResidentSyncModal: false,
      cms_residents: null,
      sr_residents: null,
      submitting: false,
      errorMessage: null,
      admin_schedule: null,
    };
  },
  methods: {
    cancelAction() {
      this.$emit("close", true);
    },
    formatDate(date) {
      return new Date(date).toISOString().split('T')[0];
    },
    closeModal() {
      console.log("hitting this closemodal function");
      if (this.reloadPageOnClose == true) {
        this.$emit("close", "reload");
      } else {
        this.$emit("close", true);
      }
    },
    arrayedTimes(dose_times) {
      let formattedArray = [];
      for (const time of dose_times) {
        for (const indivtime of time.times) {
          formattedArray.push(indivtime);
        }
      }
      return formattedArray;
    },
    getDoseSchedule() {
      axios
        .post(
          `/prescription/${this.uuid}/admin-schedule`,
          {},
          this.$store.state.header,
        )
        .then((res) => {
          console.log("getting admin schedule", res.data);
          this.admin_schedule = res.data;
        });
    },
  },
  mounted() {
    this.getDoseSchedule();
  },
};
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.schedule-cells {
  background-color: white !important;
}

.schedule-cells:nth-child(even) {
  background-color: #f2f3f3 !important;
}

.right-form-section-drug-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  input {
    background-color: $babyblue;
  }
}

.drug-entry-transfer {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  border-collapse: collapse;

  p {
    margin: 0;
    text-align: left;
    padding: 0 15px;
  }

  background-color: $grey;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-bottom: 5px solid white;
  border-radius: 4px;
}

.blue-button {
  background-color: $strongblue !important;
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  color: white;
}

.right-form-section-panel {
  padding: 0 20px;
}

.full-width-verify.verification-form {
  grid-template-columns: 1fr 1fr;

  div {
    width: 100%;

    input {
      width: 100% !important;
    }
  }
}

.selected-location {
  background-color: $complete;
  color: white;
}

.selected-hollow-button {
  background-color: $strongblue;
  color: white;
}
</style>
