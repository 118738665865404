<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div
                class="modal-container" 
                style="width: 80%; height: 80%; display: flex; flex-direction: column; position: relative;"
            >
                <button
                    @click="closeModal()"
                    class="floating-close-button"
                >
                    <i class="pi pi-times" style="padding: 0;"></i>
                </button>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <div v-if="this.loading_interaction" class="loading-analysis">
                                Loading Analysis... 
                                <i
                                    class="pi pi-spin pi-spinner"
                                    style="font-size: 2rem !important; margin: 1rem;"
                                ></i>
                            </div>
                            <iframe
                                v-else-if="this.qualitypharma_html"
                                :srcdoc="this.qualitypharma_html"
                                width="100%"
                                top="0px"
                                bottom="0px"
                                style="border:none;"
                            ></iframe>
                            <div style="width: 100%; overflow: scroll" v-if="error_message">
                                <p style="margin: 0 auto; font-size: 1em; ">
                                    Error message: {{ error_message }}
                                </p>
                            </div>
                        </form>
                    </slot>
                    <div v-if="!(this.loading_interaction || this.update_questions)" class="bottom-right">
                        <button
                            class="blue-button"
                            @click="loadVisualAnalysis(true)"
                        >
                            Update Patient Symptoms
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        patientId: {
            type: String,
            default: ''
        },
        drugUuid: {
            type: String,
            default: ''
        }
    },
    emits: [
        'close',
        'successfullySubmitted'
    ],
    data() {
        return {
            loading_interaction: false,
            qualitypharma_html: '',
            error_message: '',
            update_questions: false
        }
    },
    methods: {
        cancelAction() {
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');
            } else {
                this.$emit('close', true);
            }
        },
        async loadVisualAnalysis(show_questions = false) {
            this.loading_interaction = true;
            this.$store.dispatch('getRefreshToken');
            if (show_questions == true) {
                this.update_questions = true;
            }
            // Get the QualityPharma interaction analysis
            await axios.post(`/qualitypharma/patient-visual/${this.patientId}`, {
                drug_alias: this.drugUuid,
                interaction: true,
                doubleusage: true,
                show_questions: show_questions,
                callback_proxy_url: '/qualitypharma/callback'
            }, this.$store.state.header).then(res => {
                this.qualitypharma_html = res.data;
                this.loading_interaction = false;
            }).catch(err => {
                this.loading_interaction = false;
                // If incorrect size of medications
                if (err.response
                    && err.response.data
                    && err.response.data.message
                    && err.response.data.message.includes('size must be between')) {
                    this.error_message = "Unable to generate analysis. Between 2 and 200 medications are required.";
                }
                // If the token has expired
                else if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                // If another error
                else {
                    this.error_message = err.response.data.message;
                }
            })
        }
    },
    async mounted() {
        await this.loadVisualAnalysis();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.modal-body {
    gap: 0px;
}

.action-modal {
    padding-bottom: 0px !important;
}

.bottom-right {
    align-self: flex-end;
    width: 50%;
    padding-left: 10px;
    text-align: left;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.loading-analysis {
    display: flex;
    align-items: center;
}

.floating-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: $error;
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
    padding: 7px;
    cursor: pointer;
    i {
        padding: 0;
        color: white;
        font-weight: 800;
    }
}

.action-modal {
    background-color: white;
    display: flex;
    padding: 15px 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.modal-body {
    height: 100%;
    width: 100%;
}

.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.drug-entry-transfer {
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
