<template>
    <div>
        <div>
            <div style="display: flex; align-items: center; justify-content: space-between; padding-right: 25px; margin-top: 1.5em;">
                <h4 style="text-align: left;margin: 0;">{{selectedTab != 'view_nim' ? 'Facility NIM Charts' : 'Resident NIM Charts'}}</h4>


                <div style="display: flex;">
                        <p class="blue-button" style="font-size: 1em;" v-if="selectedTab != 'view_nim'" @click="selectedTab = 'view_nim', getPatientNIMs()" >View NIM Charts</p>
                    
                        <p class="blue-button" style="font-size: 1em;" v-if="selectedTab == 'view_nim'"  @click="selectedTab = 'update_nim', getNIMList()">Update resident's NIM Chart List</p>
                
                </div>
            </div>
            

            <!-- STILL NEED TO CREATE PATIENT EXISTING NIM LIST AND ALSO ADMINISTRATION -->

            <div>
                <div v-if="selectedTab =='update_nim'">
                    <p style="text-align: left; font-size: 1em; ">Nurse Initiated Medications need to be approved per resident.</p>
                    <p style="text-align: left; font-size: 1em; "><b>NOTE</b>: Expand each item to view additional information.</p>
                </div>

                <div v-else style="margin-bottom:3em;">
                    <p style="text-align: left; font-size: 1em; ">Current Nurse Initiated Medications available to resident.</p>
                    <p style="text-align: left; font-size: 1em; "><b>NOTE</b>: Expand each item to view additional information and select which charts to proceed with.</p>
                    <p style="text-align: left; font-size: 1em; color: red;">To review/change medications, re-select the NIMs from the "Update resident's NIM Chart List" tab.</p>
                </div>
                
            
            
            
                <div style="width: 98%; display: flex; flex-direction: column; align-items: flex-end;">
                  
                <!-- v-else  -->
                <div v-if="selectedTab =='view_nim' && $store.state.user.type == 'prescriber' && nimItems?.length" style="display: flex; justify-content: flex-start; align-items: center; margin-bottom: 10px;">
                    <p class="red-button" style="width: fit-content; padding: 4px 18px; margin: 0; margin-left: 10px; font-size: 1em" @click="removeNimList()">Remove all charts from resident</p>
                </div>
                

                <div v-if="selectedTab =='update_nim'" style="display: flex; justify-content: flex-start; align-items: center; margin-bottom: 10px;">
                    <p v-if="selectedNIMs.length" class="green-button" style="width: fit-content; padding: 4px 18px; margin: 0; font-size: 1em" @click="saveNIMList()">Add to Resident List</p>
                    <!-- <p class="red-button" style="width: fit-content; padding: 4px 18px; margin: 0; margin-left: 10px; font-size: 1em" @click="emptyNIMList()">Remove all charts from resident</p> -->
                </div>
                <table class="data-table" style="width: 100%; border-collapse: collapse;" v-if="nimItems && nimItems.length">
                        <tr>
                            <!-- <th style="width: 10em;">Clinical Indication</th> -->
                            <th style="width: 2em;"></th>
                            <th style="min-width: 30em;">Medication</th>
                            <th style="width: 4em;">Dosage</th>
                            <th style="width: 6em;">Dose Unit</th>
                            <th style="width: 4em;">Route</th>
                            <th style="width: 20em;">Frequency</th>
                            <th style="width: 6em;">Max Dose</th>
                            <!-- <th style="width: 4em;">Select</th> -->
                            <!-- <th>Contraindications/Warnings</th> -->
                        </tr>
                        <tbody class="table-data" v-for="(item, index) in nimItems" :key="index">
                            <tr @click="showContent(item, index)" :class="[item.selected ? 'green-row' : item.display && (item.display == true) ? 'open-transfer-row': '']">
                                <td  >
                                    <div  style="height: 100%; display: flex; align-items: center">
                                        <i  v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                                        <i v-else class="pi pi-angle-right"></i>
                                    </div>
                                    
                                </td>
                                <td style="min-width: 30em;">
                                    <div style="display: flex; align-items: center;">
                                        <p>{{item.alias.name}}</p>
                                    </div>
                                </td>
                                <td style="width: 4em;">{{item.dose}}</td>
                                <td style="width: 4em;">{{item.dose_unit}}</td>
                                
                                <td style="width: 4em;">{{item.route}}</td>
                                <td style="width: 15em;">{{item.frequency}}</td>
                                <td style="width: 6em;">{{item.max_dose}}</td>
                                <!-- <td style="width: 2em;"><i class="pi pi-check" style="cursor: pointer; border: 1px solid green; padding: 10px; border-radius: 6px; font-weight: bold"></i></td> -->
                            </tr>
                            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
                                    <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; width: 77vw">
                                        <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                                        <div class="transfer-notes-container" style="width: 50vw">
                                            <div>
                                                <h4 class="field-title">Indication</h4>
                                                <div class="field-notes" style="width: 100%; height: fit-content;">
                                                    <InputText disabled v-model="item.indication" style="width: 100%" />
                                                </div>
                                            </div>
                                            <div style="margin-top: 10px;">
                                                <h4 class="field-title">Contraindication/Instructions</h4>
                                                <Textarea disabled v-tooltip="'Notes/Directions'" style="min-width: 400px; width: 100%; min-height: 15em; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                                                    placeholder="Notes/Directions" v-model="item.instructions" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <!-- TRANSFER ACTIONS -->
                                    <div class="transfer-actions-container" style="width: 77vw">
                                        <button class="transfer-action-button error-button" v-if="$store.state.user.type == 'prescriber' && selectedTab =='view_nim'" @click="removeChart([item.uuid])" >Remove Chart</button>
                                        <button class="transfer-action-button complete-button" v-if="!item.selected && selectedTab =='update_nim'" @click="addNIMToChart(item, index)" style="font-weight: bold">Select Chart</button>
                                        <button class="transfer-action-button error-button" v-if="item.selected && selectedTab =='update_nim'" @click="removeNIMFromChart(item, index)" >Unselect Chart</button>
                                        <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                                    </div>
                                
                            </tr>    
                        </tbody>
                        
                    </table>
                </div>
                <div v-if="!loadingConfig && nimItems && !nimItems?.length && selectedTab == 'view_nim'" style="width: 100%; display: grid;">
                        <p style="font-weight: bold; font-size: 1em;">No resident NIM items available.</p>
                        <p style="font-size: 1em;" v-if="$store.state.user.type == 'prescriber'">Click <span class="blue-button" @click="selectedTab = 'update_nim', getNIMList()">here</span> to add NIM items.</p>
                        <p style="font-size: 1em;" v-if="$store.state.user.type != 'prescriber'">Please request prescriber to select NIM items for resident.</p>
                </div>
                <div v-if="loadingConfig">
                    <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
                </div> 
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    props:['patient'],
    components: {
        SuccessModal
    },
    data() {
        return {
            selectedTab: 'view_nim',
            nimItems:null,
            residentNimIds:[],
            selectedNIMs:[],
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            selectedFacility:'',
            facilities:[],
            loadingConfig: false,
            loadingFacilities: false,
            prescribers:[],
            loadingDrugs: false,
            drugList:[],
            stock:'',
            selectedDrug: {},
            drugSearchFilter: 0,
            frequencyTypes:[],
            administrationTypes: [
                'PO',
                'PR',
                'Topical',
                'Subcut',
                'Subling',
                'NG',
                'PEG',
                'IM',
                'IV',
                'Epidural',
                'Inhale/Inhalation',
                'Intraarticular (joint)',
                'Intrathecal (spine)',
                'Intranasal (nose)',
                'Irrigation',
                'NEB',
                'PV',
                'PICC'
            ],
            doseTypes:[]
        }
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        saveNIMList(){
            this.displaySuccessModal = true;
            this.successMessage = 'Saving NIM list...';
            let payload = [];

            this.nimItems.forEach(nim=>{
                console.log('looping through payload', nim);
                if(nim.selected){

                    payload.push(nim.uuid);
                }
            })
 
            console.log('this is the payload', payload);
            setTimeout(()=>{
                axios.post(`/person/${this.$route.params.id}/nim-charts/save`, {nim_items: payload}, this.$store.state.header).then(res=>{
                    console.log('saved nim list', res.data);
                    this.successMessage = 'Saved NIM List';
                    this.$store.dispatch('getRefreshToken');
                    this.nimItems.forEach(nim=>{
                        nim.selected = false;
                    })

                }).catch(err=>{
                    this.successMessage = err.response.data;
                    this.$store.dispatch('getRefreshToken');
                })
            },1000)
            
        },
        emptyNIMList(){
            setTimeout(()=>{
                axios.post(`/person/${this.$route.params.id}/nim-charts/save`, {nim_items: []}, this.$store.state.header).then(res=>{
                    console.log('saved nim list', res.data);
                    this.successMessage = 'Removed all NIM charts from resident.';
                    this.$store.dispatch('getRefreshToken');
                    this.nimItems.forEach(nim=>{
                        nim.selected = false;
                    })

                }).catch(err=>{
                    this.successMessage = err.response.data;
                    this.$store.dispatch('getRefreshToken');
                })
            },1000)
        },
        showContent(nimItem, index){

            if(nimItem.display && (nimItem.display === true)){
                document.getElementById(`expand-${index}`).style.display = 'none';
                nimItem.display = !nimItem.display;
            } else{
                nimItem.display = true;
                console.log('expanding range for this object');
                document.getElementById(`expand-${index}`).style.display = 'table-row';
            }
        },
        addNIMToChart(item, index){
            item.selected = true;
            this.selectedNIMs.push(item);
            document.getElementById(`expand-${index}`).style.display = 'none';
            item.display = false;
        },
        removeNimList(){
            const chartIds = [];
            this.nimItems.forEach(nimItem =>{
                chartIds.push(nimItem.uuid);
            });

            this.removeChart(chartIds);
            //return chartIds;
        },

        removeChart(payload){
            this.displaySuccessModal = true;
            this.successMessage = 'Removing NIM Chart...';
            //let payload = [item.uuid];
 
            console.log('this is the payload', payload);
            setTimeout(()=>{
                axios.post(`/person/${this.$route.params.id}/nim-charts/remove`, {chart_items: payload}, this.$store.state.header).then(res=>{
                    console.log('saved nim list', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.getPatientNIMs();
                }).catch(err=>{
                    this.successMessage = err.response.data;
                    this.$store.dispatch('getRefreshToken');
                })
            },1000)

            //this.getPatientNIMs();
        },

        removeNIMFromChart(item, index){
            item.selected = false;

            item.display = false;
            document.getElementById(`expand-${index}`).style.display = 'none';
        },
        getNIMList() {
            this.nimItems = null;
            this.selectedNIMs = [];
            this.loadingConfig = true;
            this.displaySuccessModal = true;
            // GET FACILITY NIM LIST
            if(this.patient.physical_location && this.patient.physical_location.location &&  this.patient.physical_location.location.facility && this.patient.physical_location.location.facility.uuid){

                this.successMessage = 'Retrieving NIM List if available...';
                axios.post(`/facility/${this.patient.physical_location.location.facility.uuid}/nim-list`, {}, this.$store.state.header).then(res=>{
                    console.log('this is the res', res.data);
                    this.displaySuccessModal = false;
                    this.loadingConfig = false;
                    this.nimItems = res.data.data.list_items;
                    console.log('these are the nim items');
                    if(!this.nimItems.length){
                        this.createNIMItem();
                    } else{
                        let b = [];
                        b.concat(this.nimItems);
                        console.log('this b is', b);
                        console.log('this nimitem is', typeof this.nimItems);
                        for(let key in this.nimItems){
                            console.log('this is the item loop in objects', this.nimItems[key]);
                            b.push(this.nimItems[key]);
                            console.log('this is the item loop with b', b, b[0]);
                        }
                        b.forEach(item=>{
                            console.log('looping through bforeach', item);
                            item.drug = item.alias;
                            item.drug.full_name = `(${item.alias.generic_name}) ${item.alias.name}`;
                            item.drugList = [item.alias];
                            item.search_term = item.alias;
                            item.doseTypes = [item.dose_unit];
                            console.log('looping through bforeach', item);
                            this.frequencyTypes.forEach(frequency=>{
                                if(frequency.frequency_name == item.frequency){
                                    item.frequency = frequency;
                                    this.parseDoseTimes(item);
                                }
                            })
                            this.nimItems = b;
                        })
                    }
                    if(res.data.uuid){
                        this.nim_list_id = res.data.data.uuid;
                    }
                }).catch(err=>{
                    this.successMessage = err.response.data;
                    this.$store.dispatch('getRefreshToken');
                })
            }else {
                    this.successMessage = 'Unable to find facility that the patient is under.'
            }
            
            
        },

        isResidentNIM(uuid) {
            return this.residentNimIds.some(item => item.uuid === uuid);
        },

        getPatientNIMs(){
            this.nimItems = null;
            this.selectedNIMs = [];
            this.residentNimIds = [];
            this.loadingConfig = true;
            this.displaySuccessModal = true;
            this.successMessage = 'Retrieving resident NIM charts.'
            
            this.$store.dispatch('refreshToken');
            axios.post(`/person/${this.$route.params.id}/nim-charts`, {}, this.$store.state.header).then(res=>{
                this.nimItems = res.data;
                this.loadingConfig = false;
                if(!this.nimItems.length){
                    this.successMessage = 'No NIM charts found for this resident.'
                } else{
                    let b = [];
                    b.concat(this.nimItems);
                    console.log('this b is', b);
                    console.log('this nimitem is', typeof this.nimItems);
                    for(let key in this.nimItems){
                        console.log('this is the item loop in objects', this.nimItems[key]);
                        b.push(this.nimItems[key]);
                        console.log('this is the item loop with b', b, b[0]);
                    }
                    b.forEach(item=>{
                        console.log('looping through bforeach', item);
                        item.drug = item.alias;
                        item.drug.full_name = `(${item.alias.generic_name}) ${item.alias.name}`;
                        item.drugList = [item.alias];
                        item.search_term = item.alias;
                        item.doseTypes = [item.dose_unit];
                        console.log('looping through bforeach', item);
                        this.residentNimIds.push(item.uuid);
                        this.frequencyTypes.forEach(frequency=>{
                            if(frequency.frequency_name == item.frequency){
                                item.frequency = frequency;
                                this.parseDoseTimes(item);
                            }
                        })
                        this.nimItems = b;
                    })
                    this.displaySuccessModal = false;
                }
                
                
            }).catch(err=>{
                console.log('this is the error', err.response.data);
                this.displaySuccessModal = true;
                this.successMessage = err.response.data;
                this.loadingConfig = false;

            })
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
    },
    mounted() {
        this.getPatientNIMs()
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.green-row{
    background-color: $correctbackground !important;
}


.grey-input{
    background-color: #f3f4f4 !important;
    border-radius: 4px;
    input{
        background-color: #f3f4f4;
    }
    
}

.small-input{
    width: 4em;
    input{
        width: 100%;
    }
}

.medium-input{
    width: 6em;
    input{
        width: 100%;
    }
}

.long-input{
    width: 10em;
    input{
        width: 100%;
    }
}
.border{
    border: 1px solid #dadada;
}
</style>
