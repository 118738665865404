<template>
    <h2>You're being redirected back to StrongRoom</h2>
    <br>
    <h3>Please wait and do not close this page...</h3>
</template>

<script>
import axios from 'axios';

export default {
    methods: {
        extractAuthParams() {
            const url = new URL(window.location.href);
            const msAuth = localStorage.getItem('ms_auth');
            const msAuthObject = msAuth ? JSON.parse(msAuth) : null;
            let authObject = {
                integration_id: msAuthObject ? msAuthObject.integration_id : "",
                auth_token: url.searchParams.get('code')
            };
            return authObject;
        },
        saveToken(authObject) {
            return axios.post('/integration/save-auth-token', authObject, this.$store.state.header).then(res => {
                // save auth_code into localStorage as well
                localStorage.setItem('ms_auth', JSON.stringify(authObject));
                // save access token just for testing
                localStorage.setItem('ms_access_token', JSON.stringify(res.data));
            }).then(() => {
                window.close();
            }).catch(err => {
                console.error(err);
            });
        }
    },
    mounted() {
        const startAuth = new Promise((resolve, reject) => {
            try {
                resolve(this.extractAuthParams());
            } catch (err) {
                return reject(err);
            }
        });
        startAuth.then((authObject) => {
            this.saveToken(authObject);
        }).catch(err => {
            console.error('Redirect error', err);
        });
    }
}
</script>

<style></style>