<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <h3 class="form-header">{{(selectedPatch.alias.tags.includes('s8') || selectedPatch.alias.tags.includes('S8')) && !selectedPatch.removed ? 'Drug Safe Retrieval' : 'Patch Administration'}}</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style="padding: 0"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                                <div class="form-section-container" style="grid-template-columns: 2fr 1.5fr 2fr; grid-gap: 24px; width: 100%; padding: 0 25px;">

                                <div class="left-form-section-panel" style="padding: 0; width: fit-content; display: flex;">
                                    <div style="display: flex; flex-direction: column; align-items: center">
                                        <p style="font-size: 1em; font-weight: bold">Front</p>
                                        <img src="../../assets/images/FrontBodyNumbered.png" alt="" style="width: 13em" />
                                    </div>
                                    <div style="display: flex; flex-direction: column; align-items: center">
                                        <p style="font-size: 1em; font-weight: bold">Back</p>
                                        <img src="../../assets/images/BackBodyNumbered.png" alt="" style="width: 13em" />
                                    </div>
                                    
                                    
                                </div>
                                <div class="left-form-section-panel" style="padding: 0; max-height: 29em; overflow-y: scroll">
                                    <h4>Previous sites</h4>
                                    <table class="data-table" style="border-collapse: collapse" v-if="selectedPatch.patch_sites">
                                        <tr>
                                            <th style="padding-left: 10px">Site</th>
                                            <th style="padding-left: 10px">Date</th>
                                        </tr>
                                        <tr v-for="(item, index) in reversed_site_dates" :key="index">
                                            <td style="border-right: 1px solid black; padding-left: 10px">{{item.patch_site ? item.patch_site : 'N/A'}}</td>
                                            <td style="padding-left: 10px">{{item.date ? item.date : 'N/A'}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="right-form-section-panel" style="padding: 0;">
                                    <div>
                                        <h3 class="form-header">{{selectedPatch.alias.name}}</h3>
                                        <div class="small-input" style="margin: 10x; margin-left: 0px; margin-bottom: 15px; ">
                                            <p style="text-align: left; margin: 0; width: 10em; margin-top: 20px">
                                                Patch Location</p>
                                            <Dropdown :options="patchNumbers" style="border: 1px solid grey; border-radius: 4px; width: 10em" v-model="patch_location" />
                                        </div>
                                    </div>
                                    <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="25"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="notes"></textarea>
                                    </div>
                                    
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">                        
                        <SingleVerify 
                            endpoint="/drug-safe-retrieval" :drugSafeRemoval="true"
                            @click="()=>{removeDrugFromRegister(selectedPatch)}" 
                            v-if="!adminRound && (selectedPatch.alias.tags.includes('s8') || selectedPatch.alias.tags.includes('S8')) && !selectedPatch.removed" 
                            :isPRN="true" @getDrugId="getPRNId" :payload="removeFromRegisterPayload"
                            />
                        <div v-else>
                            <button  class="green-button" v-if="patch_location" @click="adminRound ? signAdmin() : signAction()">
                                Sign
                            </button>
                        </div>
                        
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :dual="adminSubmission && adminSubmission.drug_safe_retrieval ? true : removedFromRegister" :endpoint="adminRound ? admin_round_endpoint: signature_endpoint" 
            @getDrugId="getPRNId" :noAuthRequired="noAuthRequired" :loop="loop" />
        <SuccessModal v-if="displaySuccessModal" :first_spec_line="displayMessage" @close="closeSuccessModal" />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';
import SingleVerify from '@/components/verification/SingleVerify.vue';
import SuccessModal from './SuccessModal.vue';
// import axios from 'axios';

export default {
    props: ['message', 'close', 'loading', 'statusMessage', 'action', 'actionType', 'noAuthRequired', 
    'adminSubmission', 'loop', 'selectedPatch', 'patientId', 'readings','adminRound', 'dual'],
    components: {
        SignatureModal,
        SingleVerify,
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            displayMessage:'',
            payload:'',
            patch_location:null,
            enteredReading:0,
            selectedIndex: 0,
            admin_round_endpoint: '/administer',
            signature_endpoint:'/administer-patch',
            displaySignatureModal: false,
            removeFromRegisterPayload: [],
            removedFromRegister: false,
            drug_safe_retrieval:'',
            selectedCode:'',
            notes:'',
            patchNumbers:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
            doseOmittedCode:[
                {value:  'A',
                label:'Absent'
                },
                {value:  'F',
                label:'Fasting'
                },
                {value:  'H',
                label:'Hospital'
                },
                {value:  'L',
                label:'On Leave'
                },
                {value:  'S',
                label:'Sleeping'
                },
                {value:'S/A',
                    label:'Self Administration'
                    },
                {value:  'V',
                label:'Vomiting'
                },
                {value:'A/T',
                    label:'Adjusted Administration'
                    },
                {value:  'C',
                label:'Contraindicated'
                },
                {value:  'N',
                label:'Not available'
                },
                {value:'N/R',
                    label:'Not Required'
                    },
                {value:  'O',
                label:'Omitted'
                },
                {value:  'R',
                label:'Refused'
                },
                {value:  'W',
                label:'Withheld (clinical)'
                },
                {value:'W/R',
                  label:'Withheld (pending results)'
                },
            ]
        }
    },
    methods: {
        signAdmin(){
            if(this.adminSubmission){
                this.payload = {...this.adminSubmission};
                this.payload.patch_site = this.patch_location;
                this.payload.notes = this.notes;
                this.displaySignatureModal = true;
            } 

        },
        closeSuccessModal(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
        removeDrugFromRegister(drug){
            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id:drug.stock_items[0].uuid,
                    quantity: parseFloat(this.patch_location).toFixed(2),
                    patient_id: this.patientId
                }
            
        },
        getPRNId(value){
            console.log('HIT THE DRUG SAFE RETRIEVAL ID IN SINGLEVERIFY', value);
            this.$store.dispatch('getRefreshToken');
            this.$emit('updatePRN', value);
            this.removedFromRegister = true;
            this.drug_safe_retrieval = value.drug_safe_retrieval;
        },
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                console.log('this is the value from administering patches', value);
                this.displaySignatureModal = false;
                
                if(this.adminRound){
                    console.log('NOT HITTING THE COMPLETE PRN condition', value);
                    this.$emit('submitIsCompleted', { item: value.data });
                    this.$emit('successfullySubmitted', true);
                    this.closeModal();
                } else{
                    console.log('HITTING THE COMPLETE PRN condition', value);
                    this.$emit('done', this.selectedPatch.uuid);
                    this.$emit('completePatch', {administered_at: value.administered_at, id: this.selectedPatch.uuid});
                    this.closeModal();
                }
            }
        },
        signAction() {
            this.displaySignatureModal = true;
            // not technically this.adminSubmission.calendar_item_id but is being passed via prop as such, it is indeed the chart_id
            if(this.adminRound && !this.selectedPatch.expired){
                this.payload = {
                    chart_id:this.adminSubmission.calendar_item_id,
                    doc:'',
                    notes:this.notes,
                    dose:this.adminSubmission.dose,
                    drug_safe_retrieval: this.drug_safe_retrieval ? this.drug_safe_retrieval : this.adminSubmission.drug_safe_retrieval,
                    reading: this.readings && this.readings.length ? this.readings[0].uuid : '',
                    patch_site: this.patch_location
                }
            } else{
                this.payload = {
                    "chart_id": this.selectedPatch.uuid,
                    "doc": "",
                    effectivenesss: null,
                    "drug_safe_retrieval":this.drug_safe_retrieval ? this.drug_safe_retrieval : this.adminSubmission.drug_safe_retrieval,
                    "dose": 1,
                    "notes": this.notes,
                    patch_site: this.patch_location,
                }
            }
            
            console.log('SIGN ACTION CONDITION', this.payload);
            console.log('SIGN ACTION HITTING ELSE THEN ELSE CONDITION');
            
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }
        },
    },
    mounted() {
        // if(!this.adminSubmission.length){
        //     console.log('HITTING THE IF ON MOUNT')
        //     this.payload = {...this.adminSubmission};
        // } else{
        //     console.log('HITTING THE ELSE ON MOUNT')
        //     this.payload = this.adminSubmission;
        // }
    },
    computed:{
        reversed_site_dates(){
            let reversedDates = [];
            for (const [key, value] of Object.entries(this.selectedPatch.patch_sites)) {
                console.log('key', key);
                if(value != null){
                    reversedDates.push(value)
                }
            }
            // let reversedDates = [...this.selectedPatch.patch_sites];
            if(reversedDates.length > 5){
                return reversedDates.slice(Math.max(reversedDates.length - 5, 0))
            } else{
                return reversedDates
            }
            
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.icon-container{
    width: 282px;
    height: 240px;
    left: 25px;
    background: #94D6D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-circle{
    border-radius: 50%;
    height: 131px;
    width: 131px;
    background: #00B2A2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-input{
    span{
        width: 50%;
        input{
            width:50%;
        }
    }
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.icon-circle-item{
    width: 75px;
}

.doc-grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.grey-container{
    background-color: #f3f4f4;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    p{
        margin-bottom: 10px !important;
        font-size: 15px;
    }
}

.doc-item{
    padding: 3px;
    background-color: white;
    color: $error;
    border-radius: 6px;
    border: 2px solid $error;
    cursor: pointer;
    width: 186px;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
}

.selected-doc-item{
    background-color: $error;
    color: white;
}

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
