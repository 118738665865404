<template>
  <div class="login-view">
    <div class="login-form" style="padding-bottom: 2em">
      <div class="login-logo" style="width: fit-content">
        <img src="@/assets/logos/full_logo.png" alt="" style="height:70px;">
      </div>
      <div class="login-form-details" style="margin-top: 1em">
        <h2>Strong Room - Prescriber Registration</h2>
        <div style="display: flex; flex-wrap: wrap">
          <div class="login-form-fields slide-fade">
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">First Name <span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.first_name" placeholder="Enter First Name *">
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Last Name <span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.last_name" placeholder="Enter Last Name *">
            </div>
            
            <div class="form-field wide-text-field" style="width: 95%;">
              <label for="contact_name">Branch State <span style="color: red;">*</span></label>
              <Dropdown v-model="registerLogin.state_id" :options="states" optionValue="id" optionLabel="code"
                :placeholder="'Select state'" />
            </div>
            <div class="form-field wide-text-field" style="width: 95%;">
              <label for="first_name">Prescriber Type <span style="color: red;">*</span></label>
              <Dropdown id="route-type" placeholder="Select Option" optionValue="value" optionLabel="label" :options="prescriberTypes"
                v-model="registerLogin.prescriber_type" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="qualification"> Qualification <span style="color: red;">*</span></label>
              <input type="text" name="qualification" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.qualification"
                placeholder="Enter Qualification (Bachelors, Masters, PhD etc)">
            </div>
            
            <div class="form-field wide-text-field" style="width: 80%;" v-for="(item, index) in registerLogin.regulatory_numbers" :key="index">
              <label for="first_name">{{item.regulatory_type}}<span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="item.data"
                :placeholder="'Enter '+item.regulatory_type">
            </div>
            <!-- <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">HPI-I Number</label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.regulatory_numbers[2].data"
                placeholder="Enter HPI-I Number">
            </div> -->
          </div>
          <div class="login-form-fields slide-fade">
            <!-- <div class="form-field wide-text-field" style="width: 80%;">
              <label for="presNumber"> Prescriber Number <span style="color: red;">*</span></label>
              <input type="text" name="presNumber" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.regulatory_numbers[1].data"
                placeholder="Enter Prescriber Number">
            </div> -->
            <div class="form-field wide-text-field" style="width: 95%;">
              <label for="first_name">Timezone <span style="color: red;">*</span></label>
              <Dropdown id="route-type" placeholder="Select Timezone" :options="timezones"
                v-model="registerLogin.timezone" />
            </div>
            <!-- CONTACT METHODS -->
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">E-mail (this will be your login email) <span style="color: red;">*</span></label>
              <input placeholder="Email" v-model="registerLogin.contact_methods[0].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Password <span style="color: red;">*</span></label>
              <input placeholder="Password" v-model="registerLogin.password" type="password"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Confirm Password <span style="color: red;">*</span></label>
              <input placeholder="Password" v-model="confirmPassword" type="password"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
                <span style="color: red;" v-if="registerLogin.password.length && confirmPassword.length && (registerLogin.password != confirmPassword)">Passwords do not match.</span>
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Mobile Number <span style="color: red;">*</span></label>
              <input placeholder="Phone Number" v-model="registerLogin.contact_methods[4].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Phone Number <span style="color: red;">*</span></label>
              <input placeholder="Phone Number" v-model="registerLogin.contact_methods[1].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">After-hours Phone Number</label>
              <input placeholder="After-hours Phone Number" v-model="registerLogin.contact_methods[2].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Fax Number</label>
              <input placeholder="Fax Number" v-model="registerLogin.contact_methods[3].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <!--  END OF CONTACT METHODS -->
          </div>
        </div>
        <div style="margin-top: 2em; display: grid; justify-items: center; margin-right: 1em;">
          <button :disabled="!registerLogin.regulatory_numbers[0].data && registerLogin.first_name && registerLogin.last_name" @click="confirmPassword == registerLogin.password ? submitRegistration() : ''" class="blue-button"
            style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; "
            :style="confirmPassword == registerLogin.password? {}:{backgroundColor: '#c8c8c8 !important', cursor:'inherit'} ">
            Register</button>
            <span style="color: red;" v-if="registerLogin.password.length && confirmPassword.length && (registerLogin.password != confirmPassword)">Passwords do not match.</span>
        </div>

        <p v-if="registrationError" style="color: red; font-size: 1em;">Error: {{ registrationError }}</p>




      </div>
    </div>
    <div class="login-context">

      <div class="login-logo" style="display: grid; justify-content: flex-end">
        <img src="@/assets/logos/short_logo.png" alt="" style="height:70px;">
      </div>
      <h1 style="margin-top: 3em; ">The AI-Driven Drug Management Platform</h1>

      <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
        specialised treatment plans for patients.</p>
    </div>
      <SuccessModal :loading="loading" @close="closeSuccess"
            v-if="displaySuccessModal" :statusMessage="'Submitting Registration'"
            :first_spec_line="displayMessage" :second_spec_line="displayMessage2" />
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue'


export default {
  components: {
    SuccessModal
  },
  data() {
    return {
      confirmPassword:'',
      displaySuccessModal: false,
      loading: false,
      displayMessage: '',
      displayMessage2: '',
      registrationError: '',
      company_name: '',
      prescriberTypes:[
        {
          label: 'Medical Practitioner',
          value:'M'
        },
        {
          label: 'Eye/Optometrist',
          value:'E'
        },
        {
          label: 'Nurse',
          value:'U'
        },
        {
          label: 'Midwife',
          value:'F'
        },
        {
          label: 'Dentist',
          value:'D'
        },
        {
          label: 'Vetinarian',
          value:'V'
        },
        {
          label: 'Podiatrist',
          value:'T'
        },
        {
          label: 'Pharmacist',
          value:'C'
        }
      ],
      registerLogin: {
        first_name: '',
        last_name: '',
        password: '',
        timezone: '',
        state_id: '',
        qualification:'',
        prescriber_type:'M',
        contact_methods: [
          {
            name: 'Email',
            data: '',
            type: 'email'
          },
          {
            name: 'Phone',
            data: '',
            type: 'phone'
          },
          {
            name: 'After hours',
            data: '',
            type: 'phone'
          },
          {
            name: 'Fax',
            data: '',
            type: 'fax'
          },
          {
            name: 'Mobile',
            data: '',
            type: 'phone'
          },
        ],
        regulatory_numbers: [
          {
            data: '',
            regulatory_type: 'AHPRA Number',
            expiry: ''
          },
          {
            data: '',
            regulatory_type: 'Prescriber Number',
            expiry: ''
          }
        ]
      },
      timezones: [],
      states: []
    }
  },
  methods: {
    close(value){
      if(value){
        this.displaySuccessModal = false;
        this.$router.push('/login');
      }
    },
    closeSuccess(value) {
      if (value) {
        this.displaySuccessModal = false;
      }
    },
    submitRegistration() {
      // let methods=[];
      this.loading = true;
      this.displaySuccessModal = true;
      this.displayMessage = 'Your registration is being submitted...';
      let contactmethods = []
      this.registerLogin.contact_methods.forEach((method) => {
        if (method.data) {
          contactmethods.push(method);
        }
      })

      let b = [];
      this.registerLogin.regulatory_numbers.forEach((num, index)=>{
          if(num.data){
            // this.registerLogin.regulatory_numbers.splice(index, 1);
            console.log('this.registerLogin.regulatory_numbers', this.registerLogin.regulatory_numbers[index]);
            b.push(num);
          } 
      });

      let payLoad = {...this.registerLogin};
      if(this.registerLogin && this.registerLogin.regulatory_numbers){
        console.log('this is b and reg nums', b, this.registerLogin.regulatory_numbers);
        payLoad.regulatory_numbers = b;
        payLoad.contact_methods = contactmethods;
      }
      setTimeout(() => {
        axios.post(`/onboarding/${this.$route.params.id}`, payLoad, this.$store.state.header).then(res => {
          console.log('this is the reg completion', res.data);
          this.displayMessage = 'Your registration is successful!';
            this.loading = false;
          //   this.displayMessage = 'Validating HPI...';
          // axios.post('/validate-hpi', {prescriber_id: res.data.person_id}, this.$store.state.header).then(response=>{
          //   this.displayMessage = 'Your registration is successful!';
          //   this.loading = false;
          //   console.log('validate-hpi response', response);
          // }).catch(err => {
          //   console.log('this is the error with regstration', err);
          //   this.loading = false;
          //   this.displayMessage2 = 'Error:'+ err;
          //   this.displayMessage = 'Sorry that has been an error with your submission. Please try again later.'
          // })
        }).catch(err => {
          console.log('this is the error with regstration', err);
          this.loading = false;
          this.displayMessage2 = 'Error:'+ err;
          this.displayMessage = 'Sorry that has been an error with your submission. Please try again later.'
        })
        
      }, 1000)

    },
    retrieveStates() {
      axios.post('/get-states', {}).then(res => {
        this.states = res.data;
      }).catch(err => {
        console.log('this is the state error', err.response.data);
      })
    },
    getTimezones() {
      axios.post('/get-timezones', {}).then(res => {
        this.timezones = res.data;
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
      })
    },
    eraseLoginDetails() {
      axios.post('/logout', {}, this.$store.state.header).then(res => {
        console.log('this is the logout res', res.data);
      }).catch(err => {
        console.log('error is', err.response.data);
      })

      // Remove the username and company if rememberUsername is not set
      if (!localStorage.getItem('rememberUsername') || localStorage.getItem('rememberUsername') !== 'true') {
        localStorage.removeItem('username');
        localStorage.removeItem('company');
      }

      localStorage.removeItem('locations');
      localStorage.removeItem('currentLocation');
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      localStorage.removeItem('lastRefresh');
      localStorage.removeItem('direct_to_mfa_settings');
      localStorage.removeItem('company_mfa');
      localStorage.removeItem('savedform');
      // Remove the MIMS API Key from storage
      localStorage.removeItem('mimsApiKey');
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$store.commit('setAuthorization');

    }
  },
  mounted() {
    this.getTimezones();
    this.retrieveStates();
    this.eraseLoginDetails();
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login-context {
  background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
  color: white;
  padding-top: 8.125em;
  padding-left: 17em;
  padding-right: 8em;
  text-align: left;

  .login-logo {
    justify-content: flex-end;
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  justify-self: flex-start;
}

.login-form {
  padding: 8.125em 4.375em;
  border-bottom-right-radius: 8.25em;
  background-color: white;
  margin-right: -10em;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.login-form-details {

  width: fit-content;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10em;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;

  h2 {
    text-align: left;
  }
}

.login-form-fields {
  display: grid;
  justify-content: center;
}

.form-error {
  margin: 0;
  margin-bottom: 1em;
  color: $error;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
