

<template>
    <!-- Generate BCP report -->
    <div style="display: grid; grid-gap: 10px; min-width: 50em;">
        <div style="display: grid; grid-gap:1em; margin-top: 1em;">
            <p style='font-size: 1em; text-align: left; margin: 0;font-weight: bold'>Select a location and report type to generate a BCP report</p>
                <div  style="display:flex; flex-direction: column;justify-items: start; gap:10px">
                    <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select Report Type:</p>
                    <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Report" :options="reports"
                        optionLabel="label" v-model="selectedReport" @change="retrieveCompanyTree" />
                </div>
                <div v-if="loadingLocation">
                    <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
                </div>
                <div style="display:flex; flex-direction: column;justify-items: start; gap:10px" v-if="locationDirectory && locationDirectory.length && selectedReport && !loadingLocation">
                    <p style="margin: 0; font-size: 1em; text-align: left; font-weight: 500">Select Location:</p>
                    <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Location"
                        :options="locationDirectory" optionLabel="name" v-model="selectedLocation" />
            </div>
            <div v-if='selectedLocation'>
                    <p class="green-button" style="width: 15em;" @click="generateBCP()">Generate Report</p>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" :first_spec_line="first_spec_line" statusMessage="BCP Generation" />
    </div>
    <!-- END OF Generate BCP report -->
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            locationDirectory: "",
            loadingLocation: false,
            selectedLocation: undefined,
            noResults: false,
            states: "",
            // selectedReport: undefined,
            reports: [{ label: 'Drug Register', value: 'drug-register' }, { label: 'Resident Charts', value: 'residents-charts' }],
            displaySuccessModal: false,
            successMessage: "",
            first_spec_line: ""
        }
    },
    methods: {
        retrieveStates() {
            axios.post('/get-states', {}, this.$store.state.header).then(res => {
                this.states = res.data;
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    this.$router.push('/login');
                }
                console.log('this is the stocktake error', err.response.data);
            })
        },
        retrieveCompanyTree() {
            this.loadingLocation = true
            this.selectedLocations = [];
            let payload = {"drug_register": this.selectedReport.value == "drug-register" ? true : false};
            axios.post('/get-locations', payload, this.$store.state.header).then(res => {
                console.log('these are the facility locations', res.data);
                this.locationDirectory = res.data.locations;
                this.loadingLocation = false;
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                console.log('err issss', typeof err.response);
                if (err.response && err.response && err.response.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                this.loginCreateError = err.response
                console.log('err is', err.response, err.response);
                this.$store.dispatch('getRefreshToken');
            })
        },
        generateBCP() {
            let payload = {
                location_id: this.selectedLocation.uuid
            };
            console.log('Generating BCP report for this location:', payload);
            this.displaySuccessModal = true;
            this.successMessage = 'Generating BCP report...';
            const endpoint = "/generate/bcp/" + this.selectedReport.value
            axios.post(endpoint, payload, this.$store.state.header).then(res => {
                this.successMessage = 'We are now generating the latest BCP report, you can safely close this window.';
                this.first_spec_line = 'Please visit "View & Download BCP Reports" subsection to check the status of this report.'
                this.displaySuccessModal = true

            }).catch(err => {
                this.successMessage = 'Failed to generated BCP report.';
                console.log("this is the BCP gen error:", err);
                this.first_spec_line = err?.response?.data?.errors?.location_id ?? "Internal Error. Please try again.";
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data);
            })
        },
        close() {
            this.displaySuccessModal = false
            this.successMessage = ""
            this.first_spec_line = ""
        }
    },
    mounted() {
        this.noResults = false;
        this.retrieveStates();
        // this.retrieveCompanyTree();
        if (this.$store.state.company.type == 'warehouse') {
            this.reports = [{ label: 'Drug Register', value: 'drug-register' }]
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
