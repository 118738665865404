<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 98%; max-height: 95vh;">
                <div class="modal-header" style="padding:15px 25px !important; padding-bottom: 0 !important;">
                    <h3 class="form-header" style="margin-left: 10px;">Resident Summary</h3>
                    <button @click.stop="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body" style="padding: 0 25px;">
                    <slot name="body">
                        <div class="action-modal" v-if="patient"
                            style="display: grid; grid-template-columns: 220px 1fr; grid-gap: 10px; padding: 0;">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="general-patient" v-if="patient"
                                style="grid-area: initial; border-top-left-radius: 8px; border-bottom-left-radius: 8px; padding: 0; display: flex;">
                                <div style="width: 220px; margin-right: 10px;">
                                    <h2
                                        style=" padding-bottom: 9px; padding-left: 5px; margin: 0; color: #0870DC; font-size: 18px; text-align: left">
                                        {{ patient.personal_information.first_name }} {{
                                            patient.personal_information.last_name }}</h2>

                                    <img v-if="patient.image_information && patient.image_information.created_at"
                                        class="resident-image large" id="residentImage"
                                        onerror="this.onerror=null; this.className='resident-image-default'"
                                        :src="patient.image_information.url" alt="Resident Image"
                                        style="border-radius: 8px; max-width: 210px; max-height: 225px; margin: 0 auto; margin-bottom: 12px">
                                    <img v-else class="resident-image-default resident-image large" id="residentImage"
                                        alt="Resident Image"
                                        style="border-radius: 8px; max-width: 210px; max-height: 225px; margin: 0 auto; margin-bottom: 12px">

                                    <p style="margin: 0 auto; width: 100%; padding: 4.7px 0; margin-bottom: 12px; border-radius: 4px; font-weight: bold; color: white"
                                        :style="patient.status == 'inactive' ? { backgroundColor: '#E04F39', border: '1px solid #E04F39' } : { backgroundColor: '#00AD50', border: '1px solid #00AD50' }">
                                        {{ patient.status[0].toUpperCase() + patient.status.slice(1).toLowerCase() }}</p>
                                    <div style="background-color:white !important;">
                                        <div class="adr-tab-border" style="padding: 0;">
                                            <div style="display: flex; justify-content: space-around; background-color:#E04F39; color: white; border-radius: 6px;
                                         align-items: center; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
                                                class="adr-tab-bottom">
                                                <h4 style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0; text-align: center;"
                                                    class="'adr-tab'">
                                                    ADR</h4>
                                            </div>
                                            <div style="height: 200px; overflow: scroll;">
                                                <table style="padding: 0 10px; border-collapse: collapse;"
                                                    v-if="patient.adr && patient.adr.length">
                                                    <tr>
                                                        <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">Drug
                                                        </th>
                                                        <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">Year
                                                        </th>
                                                    </tr>
                                                    <tr v-for="(item, index) in patient.adr" :key="index">
                                                        <td style="padding: 0 10px;">{{ item.drug ? item.drug.toUpperCase()
                                                            : item.custom ? item.custom.toUpperCase() : 'Non Spec.' }}</td>
                                                        <td style="padding: 0 10px;">{{ item.date ? item.date.split(',')[1]
                                                            : '----' }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="background-color:white !important;">
                                        <div :class="infoPrescriberTab == 'INFO' ? 'info-tab-border' : 'prescriber-tab-border'"
                                            style="padding: 0;">
                                            <div style="display: flex; justify-content: space-around; align-items: center; padding:0; border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
                                                :class="infoPrescriberTab == 'INFO' ? 'info-tab-bottom' : 'prescriber-tab-bottom'">
                                                <h4 style="cursor: pointer; width: 100%; margin: 0; padding: 5px; text-align: center; width: 50%; border-radius: 0; border-top-left-radius: 6px;"
                                                    @click="infoPrescriberTab = 'INFO'"
                                                    :class="infoPrescriberTab == 'INFO' ? 'info-tab' : {}">
                                                    INFO</h4>
                                                <h4 style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0; text-align: center; border-radius: 0; border-top-right-radius: 6px;"
                                                    @click="infoPrescriberTab = 'Prescriber'"
                                                    :class="infoPrescriberTab == 'Prescriber' ? 'prescriber-tab' : {}">
                                                    GP INFO</h4>
                                            </div>
                                            <div style="height: 200px; overflow: scroll;"
                                                v-if="infoPrescriberTab == 'Prescriber'">
                                                <div v-if="doctor">
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important; display: grid; text-align: left">
                                                        <p class="general-patient-info-key">Prescriber Number:</p>
                                                        <p class="general-patient-info-value">{{ doctor?.number }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important; display: grid; text-align: left">
                                                        <p class="general-patient-info-key">Prescriber:</p>
                                                        <p class="general-patient-info-value">Dr {{ doctor.first_name }} {{
                                                            doctor.last_name }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important; display: grid; text-align: left">
                                                        <p class="general-patient-info-key">Address:</p>
                                                        <p class="general-patient-info-value">{{ doctor.address }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important; display: grid; text-align: left">
                                                        <p class="general-patient-info-key">Phone:</p>
                                                        <p class="general-patient-info-value">{{ doctor.phone ? doctor.phone
                                                            : 'N/A' }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important; display: grid; text-align: left">
                                                        <p class="general-patient-info-key">Email:</p>
                                                        <p class="general-patient-info-value">{{ doctor.email ? doctor.email
                                                            : 'N/A' }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="height: 200px; text-align: left; overflow-x: auto; background-color: white !important; border-radius: 8px"
                                                v-if="infoPrescriberTab == 'INFO'">
                                                <div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important">
                                                        <p class="general-patient-info-key">Sex:</p>
                                                        <p class="general-patient-info-value">{{
                                                            patient.personal_information.sex }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important">
                                                        <p class="general-patient-info-key">Preferred Name:</p>
                                                        <p class="general-patient-info-value">
                                                            {{ patient.personal_information.preferred_name }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important">
                                                        <p class="general-patient-info-key">DOB:</p>
                                                        <p class="general-patient-info-value">{{
                                                            patient.personal_information.dob }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important">
                                                        <p class="general-patient-info-key">Age:</p>
                                                        <p class="general-patient-info-value">{{
                                                            patient.personal_information.age }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important"
                                                        v-for="(item, index) in patient.contact_methods" :key="index">
                                                        <p class="general-patient-info-key">{{ item.type }}:</p>
                                                        <p class="general-patient-info-value">{{ item.data }}</p>
                                                    </div>
                                                    <div class="general-patient-info"
                                                        style="background-color: transparent !important"
                                                        v-for="(item, index) in patient.regulatory_information"
                                                        :key="index">
                                                        <p class="general-patient-info-key">{{ item.regulatory_type }}:</p>
                                                        <p class="general-patient-info-value">{{ item.data }}</p>
                                                    </div>
                                                    <div class="general-patient-info" style="background-color: transparent !important">
                                                        <p class="general-patient-info-key">Address:</p>
                                                        <p class="general-patient-info-value" >{{facility?.address ? facility.address.street_address : 'N/A'}}{{facility?.address?.suburb ? ', ' : ''}}{{facility?.address?.suburb}}{{facility?.address?.state ? ', ' : '' }}{{facility?.address?.state}}{{facility?.address?.postcode ? ', ' : '' }}{{facility?.address?.postcode}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div style="margin-bottom: 12px;">
                                    <div v-if="spec_cons">
                                        <div
                                            v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                            style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 130px;"
                                        >
                                            <p
                                                class="field-value"
                                                v-for="(item, index) in uniqueSpecCons" :key="index"
                                                style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 20px; margin-top: 0px; margin-right: 5px;"
                                                :v-tooltip="' '+item.name+' '"
                                            >
                                                {{item.name.length > 15 ? item.name.slice(0,15).toUpperCase()+'...' : item.name.toUpperCase() }}
                                            </p>
                                        </div>
                                    </div>
                                </div> -->
                                </div>
                            </div>

                            <div style="margin-left: 10px;">
                                <div>
                                    <h3 style="text-align: left; margin-top: 0; margin-bottom: 9px;">Prescription Details
                                    </h3>
                                    <div style=" background-color: #f3f4f4; border-radius: 6px; padding: 10px;">
                                        <div class="chart-row prescription-chart" style="flex-wrap: wrap;">

                                            <div class="chart-row-item"
                                                style="width: fit-content; display: flex; gap: 1.5px; flex-wrap: wrap; min-width: 717px; margin-top: 7px;">
                                                <div style="text-align: left; width: 100%; margin: 0 12px; display: flex;">
                                                    <div style="width: 80%">
                                                        <p style="padding-left: 15px;">Medication name</p>
                                                        <div
                                                            style="text-align: left; width: 100%;  padding: 10px; border: 1px solid #dadada; border-radius: 6px; background-color: white;">
                                                            <p>{{ propPrescription.alias_name }} {{ propPrescription?.phone_order ? '(PHONE ORDER)' : '' }}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style="text-align: left; margin-left: 10px; padding-left: 15px;">
                                                            Prescribed Quantity
                                                        </p>
                                                        <div
                                                            style="text-align: left; margin-left: 10px;  border: 1px solid #dadada; border-radius: 6px; height: 40px; display: flex; align-items: center; background-color: white">
                                                            <p :style="{ width: '253px' }"
                                                                style="padding-left: 15px;">
                                                                {{ propPrescription.prescribed_quantity ?
                                                                    propPrescription.alias_tags.includes('S8') ||
                                                                        propPrescription.alias_tags.includes('s8') ?
                                                                        `${propPrescription.prescribed_quantity}
                                                                                                                                (${wordNumber.toUpperCase()})` :
                                                                        propPrescription.prescribed_quantity : '--' }}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div style="display: grid;" v-for="(value, key1) in flatPrescription"
                                                    :key="key1">
                                                    <p style="text-align: left; margin-left: 10px; padding-left: 15px;"
                                                        v-if="key1 != 'prescribed_quantity'">
                                                        {{ key1[0].toUpperCase() + key1.split('_').join(' ').slice(1) }}
                                                    </p>
                                                    <div style="text-align: left; margin-left: 10px;  border: 1px solid #dadada; border-radius: 6px; height: 40px; display: flex; align-items: center; background-color: white"
                                                        v-if="key1 != 'prescribed_quantity'">
                                                        <p :style="key1 == 'generic' ? { width: '530px' } : { width: '253px' }"
                                                            style="padding-left: 15px;">
                                                           {{   prescriptionDetail(key1,value,flatPrescription) }}
                                                            <!-- ? value.toUpperCase()+value.split('_').join(' ').slice(1): '--'  -->
                                                        </p>
                                                        <!-- <p :style=" key == 'generic' ? {width: '530px'}:{width: '253px'}" style="padding-left: 15px;" v-else>{{ key == 'dose' && propPrescription.alias_tags.includes('S8') ? wordNumber.slice(0,1).toUpperCase()+wordNumber.slice(1) : value }}</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="text-align: left; width: 100%; margin: 0 12px;">
                                                <p style="padding-left: 15px; font-size: 1em; font-weight: 500">Instructions
                                                </p>
                                                <div
                                                    style="text-align: left; width: 100%;  padding: 10px; border: 1px solid #dadada; border-radius: 6px; background-color: white;">
                                                    <p style="font-size: 1em; font-weight: 500">{{
                                                        propPrescription.instructions.split('-------Please write any additional notes below this line------- ').join('--')}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style="padding: 0 20px; margin: 0 auto;  display: flex; justify-content: space-between; align-items: flex-end;">
                                            <div>
                                                <p style="text-align: left; font-size: 1em; font-weight: 500;">Chart Tags:
                                                </p>
                                                <div v-if="!chart_tags.length">
                                                    <p style="font-size: 1em; text-align: left; font-weight: 500;">None</p>
                                                </div>
                                                <div v-else
                                                    style="display: flex; background-color: #f3f4f4; border-radius: 6px; padding: 10px; padding-left: 0; flex-wrap: wrap;">
                                                    <div v-for="(item, index) in chart_tags" :key="index"
                                                        style="padding: 0;">
                                                        <p style="text-align: left; background-color: #E04F39; padding: 5px 10px; border-radius: 8px; color: white; font-weight: bold; margin-right: 15px;"
                                                            v-if="!item.name.startsWith('s4') && !item.name.startsWith('s8')">
                                                            {{ item.name.toUpperCase() }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="padding-bottom: 10px;">
                                                <router-link :to="'/patient/' + patientId"
                                                    style="text-decoration: none; color: #0870DC">
                                                    <p class="button blue-button" style="font-size: 1em;">View Resident's
                                                        Charts</p>
                                                </router-link>

                                            </div>
                                        </div>

                                    </div>


                                </div>

                                <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 15px;">
                                    <PatientChartsSummaryTable :charts="charts" :loadingCharts="loadingCharts" />
                                    <div style="margin-top: 20px;">
                                        <h3
                                            style="text-align: left; margin: 0; margin-bottom: 10px; font-size: 1em; padding-left: 10px;">
                                            Dispensing Details</h3>
                                        <div style="display: flex; flex-direction: column; gap: 5px;">
                                            <p v-if="propPrescription.latest_dispensed_item" style="text-align: left; padding-left: 7px; font-size: 1em;"><b>Latest Dispensed Item:</b> {{ propPrescription.latest_dispensed_item.name }}</p>
                                            <div style="display: flex; align-items: center; gap: 10px; max-width: 46.5em">
                                                <!-- <Dropdown :loading="loadingDrugs" style="width: 33%; display: flex"
                                                v-model="selectedPackSize" :options="packGroups" @change="getAMTMeds(true)" placeholder="Select Pack Size"  /> -->
                                                <!-- v-if="drugList.length" -->
                                                <Dropdown  v-model="selectedCTPP" ref="med" @change="drugSelect(selectedCTPP)" 
                                                @keyup="debounceSearchDrugs(selectedCTPP), show()" :options="formattedDrugList"  optionLabel="name"
                                                :loading="loadingDrugs"  :editable="true"
                                                style="width: 40%; display: flex" placeholder="Select Pack" id="pharmacy-med-list"/>
                                                
                                                <Dropdown v-if="selectedCTPP && selectedCTPP.drugs && selectedCTPP.drugs.length > 1" style="width: 50%; display: flex"
                                                    id="pharmacy-med-list" placeholder="Select dispensed item" v-model="stock"
                                                    :options="selectedCTPP.drugs" optionLabel="name"  />
                                                <p v-if="selectedCTPP && selectedCTPP.drugs && selectedCTPP.drugs.length == 1" style="font-size: 1em;"><b>Dispensing:</b> {{ stock.name }}</p>
                                            </div>
                                            <div
                                                style="display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 10px; max-width: 50em">
                                                <Dropdown name="packed-status" style='width: 100%'
                                                    placeholder="Select Pack Type" :options="actionTypes"
                                                    v-model="packStatus" />
                                                <ToggleButton v-model="do_not_crush" aria-label="DO NOT CRUSH"
                                                    onLabel="DO NOT CRUSH" offLabel="DO NOT CRUSH" style="width:90%" />
                                                <ToggleButton v-model="unusual_dose" aria-label="UNUSUAL DOSE"
                                                    onLabel="UNUSUAL DOSE" offLabel="UNUSUAL DOSE" style="width:90%" />
                                                <ToggleButton v-model="unusual_qty" aria-label="UNUSUAL QTY"
                                                    onLabel="UNUSUAL QTY" offLabel="UNUSUAL QTY" style="width:90%" />
                                            </div>
                                            <div
                                                style="background-color: rgb(229, 241, 254);border-radius: 6px; margin-top: 10px;">
                                                <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                                <textarea name="notes" id="note-modal" rows="5"
                                                    placeholder=" Enter notes here..."
                                                    style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                                    v-model="notes"></textarea>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div v-else>
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 10px;"></i>
                        </div>
                    </slot>
                </div>

                <div class="modal-footer"
                    style="display: flex; justify-content: flex-end; padding: 0 38px !important; height: 3.5em; margin-top: -15px">
                    <slot name="footer" style="display: flex;">
                        <button class="green-button" @click="submitReview()" style="font-weight: bold">Update</button>
                        <button class="blue-button" style="margin-left: 10px;" @click="markAsRead()">Mark as Read</button>
                        <button class="black-button" style="margin-left: 10px; font-weight: bold;"
                            @click="closeModal()">Close</button>
                    </slot>
                </div>
            </div>
        </div>
        <SuccessModal v-if="displaySuccessModal" @close="closeSuccessModal"
            :message="successMessage.length ? successMessage.toString() : ''" :numActions="numActions"
            :actionsCompleted="actionsCompleted" :action="false" />
    </div>
</template>

<script>
import { processExpression } from '@vue/compiler-core';
import axios from 'axios';
import PatientChartsSummaryTable from '../tables/PatientChartsSummaryTable.vue';
import SuccessModal from './SuccessModal.vue';

export default {
    props: ['patientId', 'close', 'viewOnly', 'reviewedPrescription', 'notification_id', 'itemIndex'],
    components: {
        PatientChartsSummaryTable,
        SuccessModal
    },
    data() {
        return {
            infoPrescriberTab: 'INFO',
            doseTimes: [],
            successMessage: [],
            packGroups: null,
            selectedPackSize: null,
            displaySuccessModal: false,
            notes: '',
            do_not_crush: false,
            unusual_dose: false,
            unusual_qty: false,
            administration_options: [
                { value: 'DO NOT CRUSH' },
                { value: 'UNUSUAL DOSE' },
                { value: 'UNUSUAL QTY' }
            ],
            loadingDrugs: false,
            drugList: [],
            stock: '',
            selectedDrug: {},
            drugSearchFilter: 0,
            numActions: 0,
            actionsCompleted: 0,
            packStatus: '',
            loadingCharts: false,
            patient: '',
            charts: '',
            activePresChart: '',
            draftDrug: '',
            isDraft: false,
            actionTypes: [
                'Packed',
                'Non-packed'
            ],
            wordNumber: '',
            doctor: null,
            facility_id: '',
            facility: '',
            selectedCTPP: null,
            dispensing_apn: null,

            // flatPrescription: {}
        }
    },
    methods: {

        prescriptionDetail(key, value, flatPrescription) {
            console.log("flat")
            console.log(flatPrescription)
            var intervalString = ""

            // Check 1
            if (key == 'chart_type' || key == 'generic') {
                intervalString = value.slice(0, 1).toUpperCase() + value.slice(1)
            } else {
                if (key != 'interval') {
                    intervalString = value
                } else {
                    if (flatPrescription.chart_type != 'stat') {
                        intervalString = value
                    } else {
                        intervalString = "--"
                    }
                }
            }

            // Check 2
            if (flatPrescription.chart_type != 'stat' && key == 'interval') {
                    if(this.reviewedPrescription.frequency == "daily") {
                        intervalString = flatPrescription.interval == 1 ?   flatPrescription.interval + " Day" : flatPrescription.interval + " Days"
                } else if(this.reviewedPrescription.frequency == "weekly") {
                    intervalString = flatPrescription.interval == 1 ?   flatPrescription.interval + " Week" : flatPrescription.interval + " Weeks"
                } else if(this.reviewedPrescription.frequency == "monthly") {
                    intervalString =  flatPrescription.interval == 1 ?  flatPrescription.interval + " Month" : flatPrescription.interval + " Months"
                }
            }

            if(key == 'max_dose' && flatPrescription.max_dose === null) {
                intervalString = ""
            }

            return intervalString
        },
        convertToWords(num) {
            console.log('running convert to words function', num);
            const ones = [
                "",
                "one",
                "two",
                "three",
                "four",
                "five",
                "six",
                "seven",
                "eight",
                "nine",
                "ten",
                "eleven",
                "twelve",
                "thirteen",
                "fourteen",
                "fifteen",
                "sixteen",
                "seventeen",
                "eighteen",
                "nineteen"
            ];

            const tens = [
                "",
                "",
                "twenty",
                "thirty",
                "forty",
                "fifty",
                "sixty",
                "seventy",
                "eighty",
                "ninety"
            ];

            const numStr = num.toString();

            if (num < 0) return "minus " + this.convertToWords(Math.abs(num));
            if (num === 0) return "zero";

            const parts = numStr.split('.');
            const integerPart = parseInt(parts[0]);
            const decimalPart = parseInt(parts[1]);

            let integerPartStr = "";
            if (integerPart < 20) {
                integerPartStr = ones[integerPart];
            } else if (integerPart.toString().length === 2) {
                integerPartStr = tens[parseInt(integerPart.toString()[0])] + " " + ones[parseInt(integerPart.toString()[1])];
            } else if (integerPart.toString().length === 3) {
                integerPartStr = ones[parseInt(integerPart.toString()[0])] + " hundred " + this.convertToWords(parseInt(integerPart.toString().substring(1)));
            } else if (integerPart.toString().length === 4) {
                integerPartStr = ones[parseInt(integerPart.toString()[0])] + " thousand " + this.convertToWords(parseInt(integerPart.toString().substring(1)));
            }

            let decimalPartStr = "";
            if (decimalPart > 0) {
                decimalPartStr = " point ";
                if (decimalPart < 20) {
                    decimalPartStr += ones[decimalPart];
                } else if (decimalPart.toString().length === 2) {
                    decimalPartStr += tens[parseInt(decimalPart.toString()[0])] + " " + ones[parseInt(decimalPart.toString()[1])];
                }
            }

            return integerPartStr + decimalPartStr;
        },
        markAsRead() {
            this.displaySuccessModal = true;
            this.successMessage = 'Marking as read...'
            this.$emit('markAsRead', { id: this.notification_id, index: this.itemIndex });
            // this.$emit('close', true);
        },
        closeSuccessModal(value) {
            if (value) {
                this.numActions = 0;
                this.actionsCompleted = 0;
                this.displaySuccessModal = false;
                this.successMessage = [];
                // this.$emit('close', true);
            }
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`,
                // params: `query=${prescriber}&hitsPerPage=5`,
                {
                    requests: [
                        { "indexName": `${this.$store.state.subdomain}_prescribers`, "params": `query=${prescriber}` },
                    ]
                },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);

                    res.data.results.forEach(indexResult => {
                        console.log('these are the results for this particular index', indexResult);
                        if (indexResult.hits.length) {
                            this.doctor = indexResult.hits[0];
                            if (this.doctor.contact_methods.length) {
                                this.doctor.contact_methods.forEach(method => {
                                    if (method.name == 'Phone') {
                                        this.doctor.phone = method.data;
                                    }
                                    if (method.name == 'Email') {
                                        this.doctor.email = method.data;
                                    }
                                })
                            }

                        }

                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        submitReview() {
            this.$store.dispatch('getRefreshToken');
            this.numActions = 0;
            // SUBMIT CHART TAGS (PACKED/NON-PACKED/DNC);
            this.numActions += 1;
            this.addChartTags();
            // SUBMIT AMT MED
            if (this.stock) {
                this.numActions += 1;
            }
            this.submitAMT();
            // SUBMIT NOTES 
            if (this.notes) {
                this.numActions += 1;
            }
            this.submitNote();

        },
        isSuccessful(value) {
            if (value) {
                this.retrieveAllCharts();
                this.$emit('markAsRead', this.notification_id);
            }
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        show() {
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchDrugs() {
            if (this.selectedCTPP) {
                this.$store.dispatch('getRefreshToken');
                this.getAMTMeds();
            }
        },
        drugSelect(item) {
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            // this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            if(item.drugs.length == 1){
                this.stock = item.drugs[0];
            }
            
            console.log('this is the selected drug item', this.selectedDrug);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        getAge(dateString) {
            dateString = dateString.split('/')[1] + '/' + dateString.split('/')[0] + '/' + dateString.split('/')[2];
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log('THIS IS THE AGE AND DATESTRING', age, dateString, new Date(dateString));
            console.log('THIS IS THE birthdate', birthDate);
            console.log('THIS IS THE birthdate', birthDate.getFullYear(), birthDate.getMonth());
            console.log('THIS IS THE today', today);
            console.log('THIS IS THE today', today.getFullYear(), today.getMonth());
            return age;
        },
        getFacilityInfo(){
            axios.post('/get-facility', {facility_id: this.facility_id}, this.$store.state.header).then(res => {
                this.facility = res.data;
                this.$store.dispatch('getRefreshToken');
                console.log('this is the facility data for retrieve patient', this.facility);
               
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        },
        retrievePatientSummary() {
            this.$store.dispatch('getRefreshToken');
            axios.post('/retrieve-patient', { location: this.$store.state.uuid, patient_id: this.patientId }, this.$store.state.header).then(res => {
                this.patient = res.data;
                this.patient.personal_information.age = this.getAge(this.patient.personal_information.dob);
                console.log('this is the res data for retrieve patient', res.data, this.patient);
                this.facility_id = this.patient.physical_location?.location?.facility?.uuid;
                this.getFacilityInfo();

                const { street_address, postcode, suburb, city, state, country } = this.patient.primary_prescriber.address;
                const regulatory_information = this.patient.primary_prescriber.regulatory_information;
                let presciber_number = regulatory_information.find(regulatory_item => regulatory_item.regulatory_type === 'Prescriber Number')?.data;
                
                this.doctor = {
                    number: presciber_number,
                    first_name: this.patient.primary_prescriber.personal_information.first_name,
                    last_name: this.patient.primary_prescriber.personal_information.last_name,
                    address: `${street_address}, ${suburb}, ${city}, ${state}, ${postcode}, ${country}`
                };
                this.patient.primary_prescriber.contact_methods.forEach(method => {
                    if (method.name == 'Phone') {
                        this.doctor.phone = method.data;
                    }
                    if (method.name == 'Email') {
                        this.doctor.email = method.data;
                    }
                });

                // this.patient.entitlement_numbers = {};
                // this.patient.regulatory_information.forEach(number=>{
                //     if(number.regulatory_type = 'Medicare Number'){
                //         this.patient.entitlement_numbers.
                //     }
                // })

            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })


        },
        retrieveAllCharts() {
            this.loadingCharts = true;
            this.$store.dispatch('getRefreshToken');
            axios.post(`/person/${this.patientId}/prescriptions`, {
                payload: {
                    drug: '',
                    type: '',
                    frequency: '',
                    route: ''
                }
            }, this.$store.state.header).then(res => {
                console.log('this is the patient summary retrieval', res);
                this.charts = res.data.data;
                this.loadingCharts = false;

            }).then(() => {
                let chartTypes = this.$store.getters.getChartTypes;
                this.charts.forEach(chartItem => {
                    chartTypes.forEach(chartType => {                 
                        if ((chartItem.chart_type == chartType.chart_name) || (chartItem.chart_type == chartType.name)) {
                            console.log('LOOPING THROUGH THE CHART ITEMS', chartItem.chart_type, chartType.chart_name);
                            chartItem.chart_type = chartType.chart_name;
                            chartItem.color = chartType.color;
                            chartItem.chart_name = chartType.name;
                            console.log('this is the color now', chartItem.color);
                        }
                    })
                })
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        getCTPGroups(){
            this.loadingDrugs = true;
            if (this.reviewedPrescription && this.reviewedPrescription.alias_uuid) {
                axios.post(`/get-pack-size-groups`, { prescription_id: this.reviewedPrescription.prescription_id }, this.$store.state.header).then(res => {
                    console.log('this is the amt data', res.data);
                    this.packGroups = res.data;
                    this.loadingDrugs = false;
                })
            }
        },
        getAMTMeds(changingPackSize) {
            this.loadingDrugs = true;
            if (this.reviewedPrescription && this.reviewedPrescription.alias_uuid) {
                axios.post(`/get-dispensing`, { prescription_id: this.reviewedPrescription.prescription_id, name: this.selectedCTPP && !changingPackSize ? this.selectedCTPP.name ? this.selectedCTPP.name : this.selectedCTPP : '', pack_size: '' }, this.$store.state.header).then(res => {
                    console.log('this is the amt data', res.data);
                    this.selectedCTPP = null;
                    res.data.forEach(drugGroup=>{
                        drugGroup.drugs.forEach(drug=>{
                            drug.pack_size_id = drugGroup.uuid;
                        })
                    })
                    this.drugList = res.data;
                    this.loadingDrugs = false;
                })
            }

        },
        submitAMT() {
            if (this.stock) {
                let payload = {
                    prescription_id: this.reviewedPrescription.prescription_id,
                    alias_id: this.stock.alias_id,
                    dispensed_item_id: this.stock.pack_size_id,
                    pack_size_id: this.stock.pack_size_id
                }
                this.displaySuccessModal = true;
                axios.post('/prescription/dispense', payload, this.$store.state.header).then(res => {
                    console.log('updated amt data', res.data);
                    this.successMessage.push('Updated AMT data');
                    this.actionsCompleted += 1;
                }).catch(err => {
                    console.log("couldn't submit notes", err.response.data);
                    this.successMessage.push('Failed to update AMT data');
                })
            }

        },
        submitNote() {
            if (this.notes) {
                this.displaySuccessModal = true;
                axios.post(`/chart/${this.reviewedPrescription.chart_uuid}/add-note`, { note: this.notes }, this.$store.state.header).then(res => {
                    this.successMessage.push('Updated add chart notes');
                    console.log('submitted notes', res.data);
                    this.actionsCompleted += 1;
                }).catch(err => {
                    console.log("couldn't submit notes", err.response.data);
                    this.successMessage.push('Failed to add chart notes');
                })
            }

        },
        addChartTags() {
            let payloadArray = [];
            if (this.do_not_crush || this.unusual_dose || this.unusual_qty || this.packStatus) {
                if (this.do_not_crush) {
                    payloadArray.push('do not crush')
                }
                if (this.unusual_dose) {
                    payloadArray.push('unusual dose')
                }
                if (this.unusual_qty) {
                    payloadArray.push('unusual quantity')
                }
                if (this.packStatus) {
                    payloadArray.push(this.packStatus)
                }
            }
            
            axios.post(`/chart/${this.reviewedPrescription.chart_uuid}/add-tags`, { tags: payloadArray }, this.$store.state.header).then(res => {
                console.log('this is the chart tag response', res.data);
                this.displaySuccessModal = true;
                this.successMessage.push('Updated dispensing details')
                this.actionsCompleted += 1;
            }).catch(err => {
                console.log("couldn't submit notes", err.response.data);
                this.displaySuccessModal = true;
                this.successMessage.push('Failed to update dispensing details. ' + err.response.data)
            })
        },
        generateDispenseAPN(){
            // kraken already sends us this info so there is no need for this
            // if((this.reviewedPrescription?.pbs_code || this.reviewedPrescription?.pbs_authority_code) && !this.reviewedPrescription?.authority_prescription_number){
            //     this.propPrescription.authority_prescription_number = 'Retrieving APN...';
            //     axios.post('/dispensing-apn', {prescription_id: this.reviewedPrescription?.prescription_id}, this.$store.state.header).then(res1=>{
            //         if (res1.data.number) this.propPrescription.authority_prescription_number = res1.data.number;
            //         else this.propPrescription.authority_prescription_number = '';
            //     });
            // }
        }

    },
    mounted() {
        this.retrievePatientSummary();
        this.retrieveAllCharts();
        // this.getCTPGroups();
        this.propPrescription = this.reviewedPrescription;
        if (this.reviewedPrescription.prescribed_quantity) {
            this.wordNumber = this.convertToWords(this.reviewedPrescription.prescribed_quantity);
        }

        if (this.reviewedPrescription.notes != "") {
            this.notes = this.reviewedPrescription.notes;
        }

        if (this.reviewedPrescription && this.reviewedPrescription.chart_tags && this.reviewedPrescription.chart_tags.length) {

            this.reviewedPrescription.chart_tags.forEach(tag => {
                if (tag.name == 'do not crush') {
                    this.do_not_crush = true;
                }
                if (tag.name == 'unusual dose') {
                    this.unusual_dose = true;
                }
                if (tag.name == 'unusual quantity') {
                    this.unusual_qty = true;
                }
                if (tag.name == 'packed') {
                    this.packStatus = 'Packed';
                    console.log('found packed tag', this.packStatus);
                }
                if (tag.name == 'non-packed') {
                    this.packStatus = 'Non-packed';
                    console.log('found non-packed tag', this.packStatus);
                }
            });
        }

        if (this.reviewedPrescription) {
            this.getAMTMeds();
            this.generateDispenseAPN();
            this.reviewedPrescription.dose_times.forEach(time => {
                this.doseTimes.push({ "time": time.time, "day": time.day });
            })
            if(this.reviewedPrescription.doctor){
                this.gpSearch(this.reviewedPrescription.doctor.uuid);
                delete this.reviewedPrescription.doctor;
            }
            
        }
    },
    computed: {

        formattedDrugList() {
            let formattedList = [...this.drugList];
            formattedList.forEach(drugGroup => {
                drugGroup.label = drugGroup.name;
                drugGroup.drugs.forEach(drug=>{
                    drug.label = `( ${drug.generic_name} ) ${drug.name}`
                    drug.strength.forEach(stren=>{
                        drug.label+=` [${stren.name}(${stren.strength}${stren.measurement_unit})]`
                    })
                })
            });
            return formattedList;
        },
        getPrimary() {
            return this.patient.prescribers.filter(item => item.primary_gp)
        },
        chart_tags() {
            return this.reviewedPrescription.chart_tags.filter(item => !item.name.toLowerCase().startsWith('s4') && !item.name.toLowerCase().startsWith('s8'))
        },
        flatPrescription() {
            if (this.reviewedPrescription) {
                return {
                    chart_type: this.reviewedPrescription.chart_type.split('_').join(' '),
                    generic: this.reviewedPrescription.alias_generic,
                    // strength: this.reviewedPrescription.alias.strength,
                    frequency: this.reviewedPrescription.frequency_shorthand,
                    created_date: this.reviewedPrescription.created_date,
                    route: this.reviewedPrescription.route,
                    start_date: this.reviewedPrescription.start_date,
                    stop_date: this.reviewedPrescription.stop_date,
                    dose: this.reviewedPrescription.dose,
                    interval: this.reviewedPrescription.interval,
                    repeats: this.reviewedPrescription.repeats,
                    repeat_interval: this.reviewedPrescription.repeat_interval,
                    // chart_uuid: this.reviewedPrescription.chart.uuid,
                    max_dose: this.reviewedPrescription.max_dose,
                    // prescription_id: this.reviewedPrescription.prescription_id,
                    // alias_tags: this.reviewedPrescription.alias_tags,
                    prescription_type: this.reviewedPrescription.pbs_code ? 'PBS' : 'Private',
                    brand_sub: this.reviewedPrescription.brand_sub,
                    authority_prescription_no: this.reviewedPrescription.authority_prescription_number,
                    prescribed_quantity: this.reviewedPrescription.prescribed_quantity
                }
            } else {
                return null
            }

        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: auto;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.patient-summary-block {
    .field-value {
        background-color: #f3f4f4;
    }
}

.chart-row-item {
    p {
        margin: 0;
        margin-top: 10px;
    }
}

#pharmacy-med-list_list {

    .p-dropdown-item {
        max-width: 45em;
        white-space: pre-wrap;
    }
}
</style>

