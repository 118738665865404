<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Add New {{ supplier ? 'Supplier' : 'Location' }}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr">
                                <!-- <div class="left-form-section-panel">
                            <div class="text-search-container search-container" style="display: flex; align-items: center">
                            </div>

                        </div> -->
                                <div class="right-form-section-panel">
                                    <div class="right-form-section-drug-entry">
                                        <input v-model="form.submit.name" placeholder="Enter location name"
                                            style="position: relative; width: 50%; margin-right: 10px" />

                                        <!-- <input v-model="form.submit.location_type" placeholder="Enter location type" style="position: relative; width: 50%" /> -->
                                        <Dropdown v-model="form.submit.location_type" :options="locationTypes"
                                            placeholder="Enter location type" style="margin-left: 5px; width: 30%;" />
                                        <Dropdown v-model="form.submit.state_id" :options="states" optionLabel="code"
                                            placeholder="Select state" style="margin-left: 5px; width: 30%" />
                                    </div>
                                    <!-- <div style="margin-top: 20px;align-self: initial;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-end;
                                        align-items: flex-end;">
                                <h3 style="margin: 0; text-align: left; margin-bottom: 10px;width: fit-content;
                                            align-self: flex-end;
                                            margin-right: 6em;">Approval</h3>
                                               
                            </div> -->

                                </div>


                            </div>



                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="background-color: green"
                            @click="addLocation()">Add Location</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['supplier'],
    components: {
    },
    data() {
        return {
            location: '',
            states: [],
            locationTypes: [],
            form: {
                submit: {
                    parent_id: '',
                    location_type: '',
                    state_id: '',
                    name: '',
                    has_drug_register: true,
                    is_dummy: true,
                }
            }
        }
    },
    methods: {

        isSuccessful(value) {
            if (value) {
                this.$emit('closeLocationModal', true);
            }
        },
        responseData(value) {
            if (value) {
                this.$emit('addNewLocationToRefined', {
                    name: value.name,
                    uuid: value.uuid
                });
            }
        },
        retrieveStates() {
            this.locationTypes = this.$store.state.locationTypes;
            axios.post('/get-states', {}, this.$store.state.header).then(res => {
                this.states = res.data;
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    this.$router.push('/login');
                }
                console.log('this is the stocktake error', err.response.data);
            })
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('closeLocationModal', true);
        },
        addLocation() {
            this.form.submit.state_id = this.form.submit.state_id.id;
            this.form.submit.location_type = this.form.submit.location_type.toLowerCase();
            console.log('this is the form to submit', this.form);
            axios.post('/location', this.form.submit, this.$store.state.header).then(res => {
                console.log('this was the transfer action response', res.data);
                this.$store.dispatch('getRefreshToken');
                this.$emit('addNewLocationToRefined', {
                    name: res.data.name,
                    uuid: res.data.uuid
                });
                this.$emit('closeLocationModal', true);
            }).catch(err => {
                console.log('Error message: ', err.response.data);
                // if(err.message){
                //     consol.log(err.message);
                // } else 
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
            this.refinedLocations = [];

        },
        getUsername(value) {
            this.form.submit.username = value;
        },
        getPassword(value) {
            this.form.submit.password = value;
        }
    },
    mounted() {
        this.retrieveStates();
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
