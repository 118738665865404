<template>
    <div>
        <transition name="fade" appear>
            <div class="alerts-panel">
                <div style="display: flex; align-items: center; justify-content: space-between">
                    <h3 style="    margin: 0px;
                                    text-align: left;
                                    display: flex;
                                    align-items: center;
                                    padding: 10px;">
                        Alerts</h3>
                    <!-- <p @click="toggleAlertsPanel()"
                    style="margin: 0; font-size: 1em; font-weight: bold; border: 1px solid #2c3e50; padding: 0 5px; border-radius: 4px; color: #2c3e50; cursor: pointer">Hide</p> -->
                </div>
                <div class="alerts-container">
                    <div class="alerts-toggle">
                        <div class="alert-toggle-item" @click="displayAlertType = 0"
                            :style="displayAlertType == 0 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }" v-if="this.$store.state.company.type != 'warehouse'">
                            <h4 :style="displayAlertType == 0 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">Expiring</h4>
                            <span class="alert-count" v-if="expiringChartsOnly.length">
                                <p>{{ expiringChartsOnly.length }}</p>
                            </span>
                        </div>
                        <div class="alert-toggle-item" @click="displayAlertType = 1"
                            :style="displayAlertType == 1 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }">
                            <h4 :style="displayAlertType == 1 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">Alerts</h4>
                            <!-- create this -->
                            <span class="alert-count" v-if="alertCount > 0">
                                <p>{{ alertCount }}</p>
                            </span>
                        </div>
                        <div class="alert-toggle-item" @click="displayAlertType = 2"
                            :style="displayAlertType == 2 ? { borderBottom: '4px solid #E04F39' } : { borderBottom: '4px solid transparent' }" v-if="this.$store.state.company.type != 'warehouse'">
                            <h4 :style="displayAlertType == 2 ? { color: '#2c3e50' } : { color: '#7B7E7E' }">Upcoming</h4>
                            <span class="alert-count" v-if="reorderOnly.length > 0">
                                <p>{{"!"}}</p>
                            </span>
                        </div>
                    </div>
                    <div class="alert-items" v-if="displayAlertType == 1">
                        <div v-if="!$store.state.loadingAlerts">
                            <div v-for="arrayOfAlertKind in alertsOnly" :key="arrayOfAlertKind.AlertType">
                                <div v-if="arrayOfAlertKind.displayType == 'single'">
                                    <AlertItem :arrayOfAlertKind="arrayOfAlertKind"> </AlertItem>
                                </div>
                                <div v-else-if="arrayOfAlertKind.displayType == 'clustered'">
                                    <AlertItemClustered :arrayOfAlertKind="arrayOfAlertKind" v-if="alertsOnly" :total="totalMissed"> </AlertItemClustered>
                                </div>  
                            </div>
                            <!-- <div v-if="!Object.keys(alertsOnly).length && !$store.state.loadingAlerts"
                            style="height: 10em; display: flex; align-items: center; justify-content: center;">
                            <h4>No alerts</h4>
                        </div> -->
                        </div>
                        <div v-if="!alertsOnly.length && !$store.state.loadingAlerts && alertCount < 1"
                            style="height: 10em; display: flex; align-items: center; justify-content: center;">
                            <h4>No alerts</h4>
                        </div>
                        <div v-if="$store.state.loadingAlerts"
                            style="display: flex; align-items: center; height: 10em; justify-content: center">
                            <i class="pi pi-spin pi-refresh" style="font-size: 1.5em !important;"></i>
                        </div>

                    </div>
                    <div class="alert-items" v-if="displayAlertType == 2">
                        <table style="border-collapse:collapse" v-if="reorderOnly.length && !$store.state.loadingAlerts">
                            <tr v-for="(item, index) in reorderOnly" :key="index" style="border-bottom: 1px solid #cdc4c4">
                                <td style="padding-left: 10px; width: 180px;">
                                    <p style="font-size: 1em; font-weight: bold;">{{ item.body.message.split('||')[1] }}</p>
                                    <p style="font-size: 1em;">{{ item.body.message.split('||')[0] }}</p>
                                </td>
                                <!-- <td style="width: 120px; padding-left: 10px;"></td> -->
                                <td style="width: 50px;"><span style="font-weight: bold;">{{
                                    item.body.message.split('||')[2].slice(0, -4) }}</span><span>{{
        item.body.message.split('||')[2].slice(-4) }}</span></td>
                            </tr>
                        </table>
                        <div v-if="!reorderOnly.length && !$store.state.loadingAlerts"
                            style="height: 10em; display: flex; align-items: center; justify-content: center;">
                            <h4>No upcoming intermittent orders</h4>
                        </div>
                        <div v-if="$store.state.loadingAlerts"
                            style="display: flex; align-items: center; height: 10em; justify-content: center">
                            <i class="pi pi-spin pi-refresh" style="font-size: 1.5em !important;"></i>
                        </div>
                    </div>

                    <div class="alert-items" v-if="displayAlertType == 0">
                        <table style="border-collapse:collapse"
                            v-if="expiringChartsOnly.length && !$store.state.loadingAlerts">
                            <tr v-for="(item, index) in expiringChartsOnly" :key="index"
                                style="border-bottom: 1px solid #cdc4c4">

                                <td style="padding-left: 10px; width: 180px;">
                                    <p style="font-size: 1em; font-weight: bold;">{{ item.body.message.split('||')[0] }}</p>
                                    <div v-for="(pres, i) in item.items" :key="i"
                                        style="display: flex; align-items: center;justify-content: space-between; padding-right: 10px;">
                                        <div>
                                            <p style="font-size: 1em; margin: 5px 0"><b>{{ pres.split('||')[0] }}</b>
                                                prescription{{ parseInt(pres.split('||')[0]) > 1 ? 's' : '' }} expiring in <b>
                                                    &lt; {{ pres.split('||')[1] != 1 ? `${pres.split('||')[1]}` : '24hrs' }} {{ pres.split('||')[1] != 1 ? `day` : '' }}{{ parseInt(pres.split('||')[1]) >
                                                        1 ? 's' : '' }}</b></p>
                                        </div>
                                        <!-- <div style="margin-right: 10px">
                                            <span style="font-weight: bold;">&lt;</span>
                                        </div> -->
                                    </div>
                                    <!-- <p style="font-size: 1em;">{{ item.body.message.split('||')[1] }} prescription{{ parseInt(item.body.message.split('||')[1]) > 1 ? 's':'' }} expiring soon</p> -->
                                </td>
                                <!-- <td style="width: 120px; padding-left: 10px;"></td> -->
                                <!-- <td style="width: 50px;"><span style="font-weight: bold;">&lt;{{ `${item.body.message.split('||')[2]}`}} days</span></td> -->
                            </tr>
                        </table>
                        <div v-if="!expiringChartsOnly.length && !$store.state.loadingAlerts"
                            style="height: 10em; display: flex; align-items: center; justify-content: center;">
                            <h4>No upcoming expired prescriptions</h4>
                        </div>
                        <div v-if="$store.state.loadingAlerts"
                            style="display: flex; align-items: center; height: 10em; justify-content: center">
                            <i class="pi pi-spin pi-refresh" style="font-size: 1.5em !important;"></i>
                        </div>
                    </div>

                </div>
                <div class="alerts-footer">
                    <p style="font-weight: 500; font-size: 1em;">Last Refresh: {{ !$store.state.lastAlertRefresh ? 'N/A' :
                        $store.state.lastAlertRefresh.slice(0, -6) + '' + $store.state.lastAlertRefresh.slice(-2) }}</p>
                    <p class="black-button" @click="refreshAlerts()"><span><i class="pi pi-refresh"
                                style="padding: 0"></i></span> Refresh List</p>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
import axios from 'axios';
import {toRaw } from 'vue';
import AlertItem from '@/components/notifications/AlertItem.vue'
import AlertItemClustered from '@/components/notifications/AlertItemClustered.vue'

export default {
    data() {
        return {
            displayAlertType: 0, //0 is expiring, 1 is alerts, 2 is upcoming
            clusteredAlertsTypes: ["alert_missed_administration"],
            displayPendingInvoicesModal: false,
            displayIncomingDispensingModal: false,
            alerts: [],
            transferType: '',
            displayForm: '',
            view: '',
            dismissedAlerts: [],
            totalMissed:0,
            totalAlerts: 0,
            formattedExpiring: []
        }
    },
    components: {
        AlertItem,
        AlertItemClustered
    },
    methods: {
        toggleAlertsPanel() {
            this.$store.commit('setDisplayAlerts', !this.$store.state.displayAlertss);
        },
        close(value) {
            if (value) {
                this.displayForm = '';
                this.displayIncomingDispensingModal = false;
                this.displayPendingInvoicesModal = false;
            }
        },
        action(form, type) {
            console.log('this is the selected form to display', form, localStorage.getItem('user_id'));
            this.displayForm = form;
            this.transferType = type;
        },
        refreshAlerts() {
            console.log('refreshing alerts');
            this.$store.commit('setAlerts', null);
            this.$store.dispatch('getAlerts');

        },
        dismissAlert(id, index) {
            this.$store.dispatch('readAlert', { 'id': id, 'index': index });
        },
        formatExpiring() {
            if (this.expiringChartsOnly.length) {
                this.expiringChartsOnly.forEach(item => {
                    let expiring = { ...item };
                    expiring.name = item.body.message.split('||')[0];
                    expiring.item = expiring.body.message.split('||').slice(1);
                    expiring.items = [];
                })
            }
        },
        missedAdministrationSort(alerts) {
            // filter only missedadmin alerts
            const missedAdminAlerts = alerts.alert_missed_administration.alerts
            // create new alerts for missedadmin
            let clusteredAdminAlerts = {}
            console.log("missedadinalerts")
            console.log(missedAdminAlerts)

            for (const key in missedAdminAlerts) {
                let alert = missedAdminAlerts[key]

                    // Clustering missed admin alerts done by name
                    const [drug,uuid,name,time] = alert.body.message.split('||')
                    const alerttitle = alert.body.title
                    console.log(alert.body.message)
                    const id = alert.id
                    const alertDetails = alert
                    // const alertDetails = {body: alert.body, subject:alert.subject, id: alert.id}
                    // check if missedadminPerson is already there
                    if(!(uuid in clusteredAdminAlerts)) {
                        // if not
                        // assign new clust alert id,clusteredAlertType,patientInvolved
                        const newpatientclust = {
                            AlertType : alerttitle,
                            // displayType: "clustered",
                            'uuid': uuid,
                            patientInvolved: name,
                            open: false,
                            alerts: { 
                                [id] : alertDetails
                            }
                        }
                        clusteredAdminAlerts[uuid] = newpatientclust
                    } else {
                        // if so 
                        clusteredAdminAlerts[uuid].alerts[id] = alertDetails
                    }

                }
       
            

            alerts.alert_missed_administration.alerts = clusteredAdminAlerts

            return alerts

        },
        alertsOnlyPackager() {
            let alerts = {}
            this.totalMissed = 0;
            // populate alerts based on channel type
            this.$store.state.alerts.forEach(
                alert => {
                    alert.open = false;
                    // Only AlertTypes 1
                    if (alert.channel != 'alert_intermittent_order' && alert.channel != 'alert_approaching_chart_expiry' && !alert.read) {
                        // add tag to each alert displaying if it is to be clustered or not
                        const thealertschanneltype = alert.channel
                        const alertTitle = alert.body.title
                        const specificAlertId = alert.id
                        const alertDetails = alert
                        // alertschanneltype doesn't exist in alerts object yet
                        if(!(thealertschanneltype in alerts)) {
                            // check if clustered type or not
                            if(this.clusteredAlertsTypes.includes(thealertschanneltype)) {
                            const newAlertType = {
                                    AlertType : alertTitle,
                                    displayType: "clustered",
                                    alerts: {
                                        [specificAlertId] : alertDetails
                                    }
                               }
                               alerts[thealertschanneltype] = newAlertType
                            } else {
                                const newAlertType = {
                                    AlertType : alertTitle,
                                    displayType: "single",
                                    alerts: {
                                        [specificAlertId] : alertDetails
                                    }
                            }
                            alerts[thealertschanneltype] = newAlertType
                        }
                    } else {
                        console.log('else condition, this is the specificalertid', specificAlertId);
                        alerts[thealertschanneltype].alerts[specificAlertId] = alertDetails
                    }
                }
                if(alert.channel == 'alert_missed_administration'){
                    this.totalMissed += 1;
                }
                }
            );
            
            // use different functions to sort clusteredAlerts of different types.
            // 1. missedAdministrations 
            // 2. ??
            Object.entries(alerts).forEach(entry => {
                const [key, value] = entry;
                if(this.clusteredAlertsTypes.includes(key)) {
                    alerts = this.missedAdministrationSort(alerts)
                    console.log("alerts after missed admin mod")
                    console.log(alerts)
                // 2. future proof for further clustered alerts other than missed administration
                }

            })
            return alerts
    }
},
    mounted() {
        console.log("loadingAlerts>>>", this.$store.state.loadingAlerts);
        console.log('this is the window view', window.location.pathname);
        this.view = window.location.pathname.slice(1);
        if (this.$store.state.company.type == 'warehouse') {
            this.displayAlertType = 1
        }else{
            if (this.expiringChartsOnly.length) {
                this.displayAlertType = 0;
            } else if (this.alertsOnly.length) {
                this.displayAlertType = 1;
            } else {
                this.displayAlertType = 2;
            }
        }
        // this.alertsOnlyPackager();
    },
    computed: {
        alertsOnly() {
            if (this.$store.state.alerts) {
                return this.alertsOnlyPackager()
            } else {
                return []
            }

        },
        expiringChartsOnly() {
            if (this.$store.state.alerts) {
                let expiringCharts = this.$store.state.alerts.filter(item => item.channel == 'alert_approaching_chart_expiry');
                let b = []
                expiringCharts.forEach(item => {
                    let expiring = { ...item };
                    expiring.name = item.body.message.split('||')[0];
                    expiring.group = expiring.body.message.split('||').slice(1);
                    expiring.group = expiring.group.join('||');
                    expiring.items = expiring.group.split('||||');
                    b.push(expiring);
                    // expiring.items = expiring.group.split('||||');
                })
                return b
            } else {
                return []
            }
        },
        reorderOnly() {
            if (this.$store.state.alerts) {
                return this.$store.state.alerts.filter(item => item.channel == 'alert_intermittent_order')
            } else {
                return []
            }
        },
        alertCount(){
            if(this.$store.state.alerts){
                return this.$store.state.alerts.filter(item=> item.channel != 'alert_intermittent_order' && item.channel != 'alert_approaching_chart_expiry' && !item.read).length
            } else{
                return 0
            }
        }
    }
}


</script>

<style lang="scss">
.alerts-panel {
    background-color: white;
    box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
    position: fixed;
    right: 2.5em;
    top: 100px;
    width: 450px;
    min-height: fit-content;
    border-radius: 8px;
    border-bottom-left-radius: 8px;
}

.notification-container {
    display: grid;
    grid-gap: 10px;
}

.alerts-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    border-bottom: 2px solid #BDC4C4;
    ;
}

.alert-toggle-item {
    width: fit-content;
    padding: 0 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;

    h4 {
        margin: 10px 0;
        font-size: 1.1em;
    }
}

.alerts-container {
    height: 70vh;
}


.alerts-content-container {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #a4a4a4;
    // background-color: rgba(224,79,57, 0.5);
    // background-color: #f3f4f4;
    gap: 10px;
    justify-content: space-between;
}


.alert-items {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.alert-count {
    color: white;
    background-color: #E04F39;
    border-radius: 8px;
    font-size: 13px;
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;

    p {
        margin: 0;
    }
}

.alert-item {}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.alert-item-content {
    text-align: left;
}


.small-red-close-button {
    background-color: #E04F39;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;

    .pi {
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}

.alerts-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #f3f4f4;
    font-size: 1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

}</style>

